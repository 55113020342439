import { message } from "antd";
import FuzzySearch from "fuzzy-search";
import { action, computed, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

export class UserStore {
    constructor() {
        this.api = API;
        this.users = [];
        this.userPermissions = [];
        this.searchQuery = "";
        makeAutoObservable(this);
    }

    get results() {
        if (this.searchQuery) {
            const searcher = new FuzzySearch(
                this.users,
                ["email", "name", "alias", "username", "serviceNumber", "mobile"],
                {
                    caseSensitive: false
                }
            );
            return searcher.search(this.searchQuery.trim());
        } else {
            return this.users;
        }
    }

    async getUsers() {
        const response = await this.api.user.viewAllUsers();
        this.users = response;
    }

    async createUser(data) {
        try {
            await this.api.user.createUser(data);
            message.success(`User has been created.`);
            this.getUsers();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async disableUser(record) {
        try {
            await this.api.user.disableUser(record);
            message.success(`User '${record.name}' has been disabled.`);
            this.getUsers();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async resendEmail(record) {
        try {
            await this.api.user.resendEmail(record.id);
            message.success(`We've sent an invitation email to ${record.name}.`);
            this.getUsers();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async deleteUser(record) {
        try {
            await this.api.user.deleteUser(record.id);
            message.success(`User '${record.name}' has been deleted.`);
            this.getUsers();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async updateUser(record) {
        try {
            await this.api.user.updateUser(record);
            message.success(`User '${record.name}' has been updated.`);
            this.getUsers();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async setUserAsApproved(record) {
        try {
            record.status = "APPROVED";
            record.role_id = 1;
            await this.api.user.updateUser(record);
            message.success(`User '${record.name}' has been approved.`);
            this.getUsers();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async setUserAsDenied(record) {
        try {
            record.status = "DENIED";
            await this.api.user.updateUser(record);
            message.success(`User '${record.name}' has been denied.`);
            this.getUsers();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    usersInInvestigation(investigation_id) {
        // each user has a list of investigations
        // we need to filter the users by the investigation_id
        return this.users.filter((user) => {
            if (user.investigation.includes(investigation_id)) {
                return user;
            }
        });
    }

    getUserByID(id) {
        const returnvalue = this.users.find((user) => user.id === id);
        if (returnvalue === undefined) {
            return [];
        }
        return returnvalue;
    }
}

export default createContext(new UserStore());
