import { Collapse, Table, Drawer, Switch, Badge, Tooltip, Divider, message, Space, Card, Button } from "antd";
import React, {useEffect, useState, useContext} from "react";
import { convertSpeed, getGMTDateTime } from "../../util/CommonUtils";
import { contentBox, primaryButton } from "../../styles";
import { AlibabaOutlined, ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import socketHandlerStore from "../../stores/socketHandler.store";
import { Link, withRouter } from "react-router-dom";

import { observer } from "mobx-react-lite";

import API from "../../services/api";
const { Panel } = Collapse;



function NotificationDrawer({open, onClose}) {
    const socketHandler = useContext(socketHandlerStore);
    if (!socketHandler.drawerOpen) {
        return null
    }

    const cards = socketHandler?.webNotificationArray.map((notification) => {
        return (
            <div
                style={contentBox(
                    {background:'smoke'}
                )}
            >
                <Card
                    size="small"
                    title={
                        <div>
                            <h5> {socketHandler.notificationIconDictionary(notification.notificationTitle)} {notification?.notificationTitle}</h5>
                        </div>
                    }
                >

                    <p>{notification?.notificationMessage}</p>
                    <Divider />
                    <button
                        style={primaryButton({ float: 'left' })}
                        onClick={() => socketHandler.markNotificationAsRead(notification)}
                    >
                        Mark as Read
                    </button>
                    <Link to="/live">
                        <button
                            style={primaryButton({ float: 'right', fontColor: 'white', backgroundColor: 'green' })}
                            onClick={() => socketHandler.buttonToLivePage(notification)}
                        >
                            View On live page
                        </button>
                    </Link>
                </Card>
            </div>
        )
    })
    const _onClose = () => {
        socketHandler.drawerOpen = false;
        socketHandler.closeDrawer();
    };
    return (
        <Drawer
            title={
                <div
                    style={{
                        opacity: 1,
                    }}
                >
                    <Badge
                        count={socketHandler?.webNotificationArray?.length}
                        offset={[10, 5]}
                    >
                        <h4>Notifications</h4>

                    </Badge>
                </div>}
            defaultActiveKey={["notifications"]}

            placement="left"
            onClose={_onClose}
            visible={socketHandler.drawerOpen}
            mask={true}
            maskClosable={true}
            maskStyle={{
                opacity: 0.5,
            }}
            bodyStyle={{}}
            headerStyle={{ background: 'white', opacity: 1 }}
            // onChange={onExpand}
            closeIcon={
                <ArrowLeftOutlined />
            }
            extra={
                <div>
                    <Button
                    onClick={() => socketHandler.clearNotifications()}
                        >
                            Clear All
                        </Button>
                </div>
            }

        >
            {cards}
        </Drawer >
    );
}

export default observer(NotificationDrawer);
