import axios from "axios";
import jwt from "jwt-decode";
import BaseAPI from "./utilities/request.lib";

const queryString = require("query-string");

const HTTP = BaseAPI();
var token = localStorage.getItem("token");

//get all Investications
const getAllGroups = async () => {
    if (localStorage.getItem("token")) {
        var decode = jwt(token);
    }

    try {
        const response = await HTTP.get(`chat/group/user/${decode.id}`);
        const data = response.data.data;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

//get all messages from API
const getAllMessages = async (investigationId, pageNumber, pageSize = 5) => {
    try {
        const response = await HTTP.get(
            `chat/group/${investigationId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );

        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

//get all the members from the API
const getAllMembers = async (investigationId) => {
    try {
        const response = await HTTP.get(`chat/members/group/${investigationId}`);

        const data = response.data.data;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
// const getEndpointBasedOnEnvironment = async () => {
//   const hostname = window && window.location && window.location.hostname;

//   if (hostname === "development.v2.atrax.io") {
//     return "https://messages.development.v2.atrax.io";
//   } else if (
//     hostname === "staging.v2.atrax.io" ||
//     hostname === "staging.atrax.io"
//   ) {
//     return "https://messages.staging.v2.atrax.io";
//   } else {
//     const serverLocation = await (await fetch("/portal/config.json")).json();
//     localStorage.setItem(
//       "messagingServerLocation",
//       serverLocation.messagingServerLocation
//     );
//     return serverLocation.messagingServerLocation;
//   }
// };

//here Upload an image through the axios post method
const uploadImage = async (bodyFormData) => {
    axios({
        method: "post",
        url: `${localStorage.getItem("serverLocation")}chat/image`,
        data: bodyFormData,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    })
        .then(function (response) {})
        .catch(function (response) {});
};

const getAllGaleries = async () => {
    try {
        const response = await HTTP.get("/chat/images");
        // const response = await axios.get("http://www.mocky.io/v2/5e05d7943300001100ec5b4e");

        // http://www.mocky.io/v2/5e05d641330000f987ec5b44
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        // const data = response.data.data;

        return Promise.resolve(response.data.data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
const getGaleriesByid = async (id) => {
    try {
        const response = await HTTP.get(`/chat/images/group/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        // const data = response.data.data;

        return Promise.resolve(response.data.data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};
const GetAllMessagesFil = async () => {
    try {
        const response = await HTTP.get(`/chat`);
        //  const response = await axios.get(`http://www.mocky.io/v2/5e039cea31000029c26b2c7c`);

        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        // const data = response.data.data;
        //

        return Promise.resolve(response.data.data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const exportMessageHistory = async (investigationId, to, from) => {
    try {
        let paramObject = {
            investigation_id: investigationId,
            from,
            to
        }
        const response = await HTTP.get(`chat/messaging-history/?${queryString.stringify(paramObject)}`);

        console.log(response, 'response')

        const data = response.data.data;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getAllGroups,
    getAllMessages,
    getAllMembers,
    uploadImage,
    GetAllMessagesFil,
    getGaleriesByid,
    getAllGaleries,
    exportMessageHistory
};
