import { Col, Row, Spin, Switch, Button, message } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import PointForm from "../../components/AnalysisPointsForm";
import PointMaps from "../../components/AnalysisPointsMap";
import PointMapsTest from "../../components/AnalysisPointsMapTest";
import StopsMap from "../../components/AnalysisStopsAtek";
import PointPanel from "../../components/AnalysisPointsPanel";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import API from "../../services/api";
import getPublicURL from "../../utilities/public-url.utility";
import AnalysisExportsStore from "../../stores/analysisExport.store";
import DownloadOptionsModal from "../../components/DownloadOptionsModal";

import Layout from "../AppLayout";
import { primaryButton } from "../../styles";
import "./AnalysisPoints.scss";
import {
    getDefaultAnalysisInvestigation, getDefaultAnalysisTarget,
    getQuickSearchTimeRange,
    setDefaultAnalysisInvestigation,
    setDefaultAnalysisTarget
} from "../../util/CommonUtils";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import AnalysisSiteStore from "../../stores/analysisSuper.store";

const STATES = {
    PLAYING: "playing",
    PAUSED: "paused",
    STOPPED: "stopped",
};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function useDate(startDate, endDate) {
    const [selectedIntervalA, setSelectedIntervalA] = useState([]);

    useEffect(() => {
        const a = [new Date(startDate), new Date(endDate)];
        setSelectedIntervalA(a);
    }, [startDate, endDate]);

    const prevInterval = usePrevious(selectedIntervalA);

    const now = new Date(startDate);
    // now.setHours(0, 0, 0, 0);

    const tomo = new Date(endDate);
    //tomo.setHours(24, 0, 0, 0);

    let selectedInterval = [now, tomo];

    const timelineInterval = [now, tomo];

    const setSelectedInterval = (interval) => {
        selectedInterval = interval;
        setSelectedIntervalA(interval);
    };

    return {
        selectedInterval,
        prevInterval,
        timelineInterval,
        setSelectedInterval,
        selectedIntervalA,
    };
}

const AnalysisPointsPage = (props) => {
    const investigationSiteStore = useContext(AnalysisSiteStore)
    const [points, setPoints] = useState([]);
    const [pointsCopy, setPointsCopy] = useState([]);
    const [pointsInSnapshotView, setPointsInSnapshotView] = useState([]);
    const [pointsReplay, setPointsReplay] = useState(false);
    const [
        {
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            selectedTargetName,
        },
        setSelectedInvestigation,
        setSelectedTarget,
        setSelectedTargetName,
    ] = useInvestigationForUser();
    const [mapPan, setMapPan] = useState(true);
    const [isStreetView, setIsStreetView] = useState(false);
    const [dateRange, setDateRange] = useState([]);

    const [timeRange, setTimeRange] = useState([]);
    const [isQuickSearch, setIsQuickSearch] = useState(false);
    const [pickerDate, setPickerDate] = useState([]);
    const [quickSearchType, setQuickSearchType] = useState(null);
    const [MarkerPlots, setMarkerPlots] = useState([]);
    const [visible, setVisible] = useState(true);
    const [margin, setMargin] = useState('33%');
    const [buttonOpacity, setButtonOpacity] = useState('1.0');
    const [buttonMargin, setButtonMargin] = useState('32%')
    const [displayTo, setDisplayTo] = useState(0);
    const [displayFrom, setDisplayFrom] = useState(0);
    const [playPoints, setPlayPoints] = useState(false);
    const [playProgress, setPlayProgress] = useState(-1);
    const [currentState, setCurrentState] = useState(STATES.STOPPED);
    const [playcopy, setPlaycopy] = useState([]);
    const [speed, setSpeed] = useState(1500);

    const showDrawer = () => {
        setButtonOpacity('1.0');
        setVisible(true);
        setMargin("33%");
        setButtonMargin('32%');

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin('0px');
    };

    const [totalPointsCount, setTotalPointsCount] = useState(0);

    const [showStopsMap, setShowStopsMap] = useState(false);
    const [showSnapshowView, setSnapshotView] = useState(false);
    const [showSnapshowViewDuplicate, setSnapshotViewDuplicate] = useState(null);
    const [switchVisible, setSwitchVisibility] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [speedUnitString, setSpeedUnitString] = useState("");
    const [toSeconds, setToSeconds] = useState(0);
    const [fromSeconds, setFromSeconds] = useState(0);


    const [searchChange, setSearchChange] = useState(true);

    const { timelineInterval, setSelectedInterval, selectedIntervalA } = useDate(
        startDate,
        endDate
    );

    const dlOptions = ['JSON', 'CSV', 'KML', 'PDF']

    const downloadPoints = async (options) => {
        if (exportStore.isSet()) {
            exportStore.verifyAndLog(dlOptions[options]);
        } else {
            message.error('Please select a time range and a target to export points');
        }
    };


    const downloadOptionsModalFunction = () => {
        setIsModalOpen1(true);
    }

    let [col, setCol] = useState('points');
    const exportStore = useContext(AnalysisExportsStore);

    const onChangeCallback = (selectedInterval) => {

        const start = pointsCopy.findIndex((point) => point.device_utc_date_time >= ((selectedInterval[0] * 1800) + fromSeconds));
        const end = pointsCopy.findIndex((point) => point.device_utc_date_time >= ((selectedInterval[1] * 1800) + fromSeconds));
        setDisplayFrom((selectedInterval[0] * 1800) + fromSeconds);
        exportStore.updateStartTime((selectedInterval[0] * 1800) + fromSeconds);

        if (((selectedInterval[1] * 1800) + fromSeconds) > toSeconds) {
            setDisplayTo(toSeconds);
        } else {
            setDisplayTo((selectedInterval[1] * 1800) + fromSeconds);
        }

        setPointsInSnapshotView(pointsCopy.slice(start, end))
    };

    useEffect(() => {
        exportStore.updateStartTime(displayFrom);
    }, [displayFrom]);

    useEffect(() => {
        exportStore.updateEndTime(displayTo);
    }, [displayTo]);

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        if (!isLoading && investigationsForUser.length > 0) {
            const _currentInvestigation =
                getDefaultAnalysisInvestigation();

            setSelectedInvestigation(_currentInvestigation);
            exportStore.updateInvestigationId(_currentInvestigation);
            exportStore.updateTargetId(selectedTarget);
        }
    }, [
        investigationsForUser,
        setSelectedInvestigation,
        isLoading,
        selectedTarget,
        setSelectedTarget]);

    useEffect(() => {
        if (!isLoading && targetsForInvestigation.length > 0) {
            if (!selectedTarget) {
                const _currentTarget =
                    getDefaultAnalysisTarget();

                setSelectedTarget(_currentTarget);
            }
        }
    }, [targetsForInvestigation, setSelectedTarget, isLoading, selectedTarget]);

    useEffect(() => {
        if (!isLoading && selectedTarget && selectedInvestigation) {
            API.analysis.getDeviceLogDateRange({
                investigation_id: selectedInvestigation,
                target_id: selectedTarget
            }).then((records) => {
                setDateRange(records);
            });
        }
    }, [selectedInvestigation, isLoading, selectedTarget]);

    const onPaginationPageChanged = (offset) => {
        setSearchChange(!(searchChange));
        if (selectedTarget && selectedInvestigation) {
            API.analysis
                .getPoints({
                    investigation_id: selectedInvestigation,
                    target_id: [selectedTarget],
                    to: moment(selectedIntervalA[1]).unix(),
                    from: moment(selectedIntervalA[0]).unix(),
                    points: 1,
                    offset: offset,
                })
                .then((points) => {
                    if (points && points.length > 0) {
                        const pointsAfterOffset = points[0].points;
                        const totalPointsCount = points[0].count;

                        setTotalPointsCount(totalPointsCount);

                        setPointsReplay(false);
                        //setPointsInSnapshotView(pointsAfterOffset);
                        //setPointsCopy(pointsAfterOffset);
                        return setPoints(pointsAfterOffset);
                    }
                });
        }
    };

    useEffect(() => {

        if (selectedInvestigation && investigationsForUser.length > 0) {
            const selectedInvestigationData = investigationsForUser.filter(x => Number(x.id) === Number(selectedInvestigation));

            if (selectedInvestigationData.length > 0) {
                const speedUnit = selectedInvestigationData[0].speed_unit;

                setSpeedUnitString(speedUnit);
            }

        }

    }, [investigationsForUser, selectedInvestigation])

    useEffect(() => {
        if (playPoints === true) {

            if (pointsInSnapshotView.length > 10) {
                setPointsInSnapshotView(pointsInSnapshotView.slice(1, 11));
            }
        }
    }, [pointsInSnapshotView])


    useEffect(() => {

        if (playPoints === true) {
            if (playProgress < playcopy.length) {
                setPointsInSnapshotView([...pointsInSnapshotView, playcopy[playProgress]]);
            } else {
                //this is to get this use effect to run again without an overflow if an overflow case is hit
                setPlayProgress(0);
            }
        }
    }, [playProgress]);

    //this has to be a use effect as it uses a setInterval and is attached to an event handler
    useEffect(() => {
        if (playPoints === true) {
            let timer
            if (currentState === STATES.PLAYING) {
                timer = setInterval(() => {
                    if (playProgress < playcopy.length) {
                        setPlayProgress(playProgress => playProgress + 1);
                    } else {
                        setPlayProgress(0);
                    }
                }, speed)
            } else {
                clearInterval(timer);
            }
            return () => clearInterval(timer);
        }
    }, [playPoints, speed, currentState])

    const markerdisplay = (AP) => {

        let listedPoints = MarkerPlots;
        if (listedPoints) {
            if (listedPoints.length > 0) {
                let index = listedPoints.findIndex(point => point.id === AP.id);
                if (index >= 0) {
                    setMarkerPlots(MarkerPlots.filter(item => item.id !== AP.id));
                } else {
                    setMarkerPlots(prevAP => ([...prevAP, AP]));
                }
            } else {
                setMarkerPlots([AP]);
            }
        } else {
            setMarkerPlots([AP]);
        }
    }

    const setHover = () => {
        setButtonOpacity('0.7');
        // setButtonMargin('-5px');
    }

    const unSetHover = () => {
        setButtonOpacity('1.0');
        // setButtonMargin('-3px');
    }

    const play = () => {
        if (currentState === STATES.STOPPED) {
            setCurrentState(STATES.PLAYING);
            if (playPoints === false) {
                setPointsInSnapshotView([])
                setPlayPoints(true);
                setPlaycopy([...pointsInSnapshotView]);
            }
        } else if (currentState === STATES.PAUSED) {
            setSpeed(speed);
            setCurrentState(STATES.PLAYING);
            setPlayPoints(true);
        }
    }

    const pause = () => {
        setCurrentState(STATES.PAUSED);
        setPlayPoints(false);
    }

    const stop = () => {
        setCurrentState(STATES.STOPPED);
        setPlayPoints(false);
        setPlayProgress(-1);
        setPointsInSnapshotView(playcopy);
    }

    const stepForward = () => {
        setPlayPoints(true);
        if (playProgress < playcopy.length) {
            setPlayProgress(playProgress => playProgress + 1);
        } else {
            setPlayProgress(0);
        }
    }

    const stepBackward = () => {
        setPlayPoints(true);

        if (playProgress > 0) {
            setPlayProgress(playProgress => playProgress - 1);
        } else {
            setPlayProgress(playcopy.length - 1);
        }

        if (playProgress > 10) {
            setPointsInSnapshotView(playcopy.slice(playProgress - 10, playProgress))
        } else {
            setPointsInSnapshotView(playcopy.slice(0, playProgress))
        }
    }

    return (
        <Layout showNavigation hideMargins>
            {/* <NotificationDrawer/> */}
            {isLoading && (
                <div
                    style={{
                        position: "fixed",
                        zIndex: 999,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#f2f2f0",
                        opacity: 0.6,
                    }}
                >
                    <Spin className={"loader_on_search"}></Spin>
                </div>
            )}

            {
                showStopsMap === false && (
                    <div
                        style={{
                            textAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20%",
                        }}
                    >
                        <Spin></Spin>
                    </div>
                )
            }
            {
                showStopsMap === true && (
                    <div>
                        <div style={{
                            position: "fixed",
                            zIndex: 2,
                            width: "100%"
                        }}>
                            < Button type="primary" onClick={visible ? onClose : showDrawer}
                                style={primaryButton({
                                    position: "absolute",
                                    top: "40vh",
                                    zIndex: 2,
                                    height: "15vh",
                                    marginTop: "0%",
                                    left: buttonMargin,
                                    marginLeft: "2px",
                                    borderLeftColor: "#0066CC",
                                    marginBottom: "2px",
                                    opacity: buttonOpacity,
                                })}
                                onMouseEnter={() => { setHover() }}
                                onMouseLeave={() => { unSetHover() }}
                            >
                                {
                                    visible
                                        ?
                                        <ArrowLeftOutlined
                                            width={25}
                                            height={25}
                                        />
                                        :
                                        <ArrowRightOutlined
                                            width={25}
                                            height={25}
                                        />
                                }
                            </Button>

                            <PointPanel
                                visible={visible}
                                showDrawer={showDrawer}
                                onClose={onClose}
                                currentState={currentState}
                                currentIndex={playProgress}
                                pointsPauseOnPlaying={() => {
                                    pause();
                                }}
                                downloadModelOpenFunction={() => {downloadOptionsModalFunction()}}
                                points={pointsInSnapshotView}
                                totalPointsCount={totalPointsCount}
                                onPageChange={onPaginationPageChanged}
                                speedUnitString={speedUnitString}
                                onUTCChange={() => {
                                    if (!isLoading && selectedTarget && selectedInvestigation) {
                                        API.analysis.getDeviceLogDateRange({
                                            investigation_id: selectedInvestigation,
                                            target_id: selectedTarget
                                        }).then((records) => {
                                            setDateRange(records);
                                        });
                                    }
                                }}
                            >


                                <DownloadOptionsModal
                                    opened={isModalOpen1}
                                    onSubmit={(data) => {
                                        downloadPoints(data);
                                        setIsModalOpen1(false);
                                    }}
                                    onCancel={() => {
                                        setIsModalOpen1(false);
                                    }}
                                    isPoints={true}
                                ></DownloadOptionsModal>
                                <PointForm
                                    onInvestigationSelect={(investigationID) => {
                                        setSelectedInvestigation(investigationID);
                                        setDefaultAnalysisInvestigation(investigationID);
                                        investigationSiteStore.setInvestigationId(investigationID);
                                    }}
                                    onTargetSelect={(targetID, value) => {
                                        setSelectedTarget(targetID);
                                        setDefaultAnalysisTarget(targetID)
                                        setSelectedTargetName(value.props.children);
                                    }}
                                    points={pointsInSnapshotView}
                                    dateRange={dateRange}
                                    selectedInvestigation={selectedInvestigation}
                                    selectedTarget={selectedTarget}
                                    selectedInterval={selectedIntervalA}
                                    snapShotView={showSnapshowView}
                                    snapShotViewDuplicate={showSnapshowViewDuplicate}
                                    investigations={investigationsForUser}
                                    targets={targetsForInvestigation}
                                    handleSubmit={(values) => {
                                        const [from, to] = values.timeRange;
                                        stop()
                                        setDisplayFrom(from / 1000);
                                        setDisplayTo(to / 1000);
                                        exportStore.updateStartTime(from / 1000);
                                        exportStore.updateEndTime(to / 1000);
                                        setFromSeconds(from.unix());
                                        setToSeconds(to.unix());
                                        if (selectedTarget && selectedInvestigation) {
                                            //loader
                                            setIsLoading(true);

                                            API.analysis
                                                .getAllNewPoints({
                                                    investigation_id: selectedInvestigation,
                                                    target_id: [selectedTarget],
                                                    to: moment(to).unix(),
                                                    from: moment(from).unix(),
                                                    points: 1,
                                                })
                                                .then((allPoints) => {
                                                    setPointsInSnapshotView(allPoints);
                                                    setPointsCopy(allPoints);
                                                    // setSnapshotView(true);

                                                    if (allPoints && allPoints.length > 0) {
                                                        setTimeout(() => {
                                                            setIsLoading(false);
                                                            setSnapshotView(true);
                                                            setSwitchVisibility(true);
                                                        }, 1000);
                                                    } else {
                                                        setIsLoading(false);
                                                    }
                                                });
                                        }
                                    }}
                                    currentState={currentState}
                                    // currentPoint={currentPoint}
                                    mapPan={mapPan}
                                    handlePointsReplayClick={(replayBoolean) => {
                                        play();
                                    }}
                                    handlePointsReplayStop={() => {
                                        stop();
                                    }}
                                    handlePointsReplayPauseClick={(pauseBoolean) => {
                                        pause();
                                    }}
                                    handlePointsReplayTransmissionSpeedCHange={(speed) => {
                                        setSpeed(speed);
                                    }}
                                    // currentSpeed={currentSpeed}
                                    handleBackward={() => {
                                        stepBackward();
                                    }}
                                    handleForward={() => {
                                        stepForward();
                                    }}
                                    handleMapPanCheck={(bool) => {
                                        setMapPan(bool);
                                    }}
                                    streetView={isStreetView}
                                    onStreetView={(status) => {
                                        setIsStreetView(status);
                                    }}
                                    timeRange={timeRange}
                                    isQuickSearch={isQuickSearch}
                                    pickerDate={pickerDate}
                                    quickSearchType={quickSearchType}
                                    onChangeDate={(date) => {
                                        setPickerDate(date ? date : [])
                                    }}
                                    onChangeQuickSearch={(e) => {
                                        let value = e.target.value;
                                        setQuickSearchType(value)
                                        let timeRange = getQuickSearchTimeRange(value)
                                        setTimeRange(timeRange)
                                    }}
                                    toggleSwitchQuickSearch={() => {
                                        setIsQuickSearch(!isQuickSearch);
                                        if (isQuickSearch) {
                                            setTimeRange([])
                                        }
                                    }}
                                    pointsCopy={pointsCopy}
                                    displayFrom={displayFrom}
                                    displayTo={displayTo}
                                    handleSlide={(e) => {
                                        onChangeCallback(e);
                                    }}
                                />
                            </PointPanel>
                        </div>
                        <div style={{ marginLeft: margin }}>
                            <div>
                                <div>
                                    <StopsMap
                                        panBy={[-300, 0]}
                                        data={pointsInSnapshotView}
                                        speedUnitString={speedUnitString}
                                        selectedTarget={pointsInSnapshotView}
                                        cloned_captured_data={[]}
                                        MarkerPlots={MarkerPlots}
                                        markerdisplay={markerdisplay}
                                        searchChange={searchChange}
                                        mapPan={mapPan}
                                        status={currentState}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </Layout >
    );
};

export default observer(AnalysisPointsPage);
