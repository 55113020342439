import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import AuthStore from "../../stores/auth.store";
import getPublicURL from "../../utilities/public-url.utility";
import "./ForgotPassword.scss";

function ForgotPasswordPage(props) {
    const store = useContext(AuthStore);
    return (
        <div className="ForgotPassword">
            <img className="front-logo" alt="logo" src={`${getPublicURL()}/atrax_logo.png`}></img>
            <div className="forgot-password-text">
                <strong>Forgot your password?</strong>
                <div>
                    <p>
            Please enter the email address associated with your account.
                        <br></br> We'll send you a link to a page where you will be able to
            reset your password. Be sure to check your junk or spam folder if
            you don't receive our email soon.
                    </p>
                </div>
            </div>
            <ForgotPasswordForm
                onBack={() => {
                    props.history.push("/login");
                }}
                onSubmit={async ({ emailAddress }) => {
                    await store.forgotPassword(emailAddress);
                    props.history.push("/login");
                }}
            ></ForgotPasswordForm>
        </div>
    );
}

export default observer(ForgotPasswordPage);
