import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Modal, Tabs, Spin, Icon } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import API from '../../services/api';
import CategoryConfigurationForm from '../CategoryConfigurationForm';
import WarningModal from "../WarningModal";
import Status from '../CategoryConfigurationForm/status';
import {
    ExclamationCircleOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';

const { TabPane } = Tabs;
const has = Object.prototype.hasOwnProperty;

function DeviceConfigurationDialog({
    schema,
    device,
    visible,
    onCancel,
    advanced,
}) {
    const [deviceConfig, setDeviceConfig] = useState(undefined);
    const [submitConfig, setSubmitConfig] = useState(null);
    const [warningVisible, setWarningVisible] = useState(false);
    // const [statusI, setStatusI] = useState({});

    const STATUS_COLOR = {
        0: "warning",
        1: "processing",
        2: "success",
        3: "error",
    };

    const STATUS_ICON = {
        0: <ExclamationCircleOutlined style={{color: '#faad14'}} />,
        1: <SyncOutlined spin style={{color: '#1890ff'}} />,
        2: <CheckCircleOutlined style={{color: '#52c41a'}}/>,
        3: <CloseCircleOutlined style={{color: '#ff4d4f'}}/>,
    }

    // Send new device configuration to server
    const postDeviceConfig = () => {
        message.loading({
            content: 'Updating device configuration...',
            key: device.id,
        });

        API.device.postDeviceConfiguration(device.id, submitConfig)
            .then(() => {
                message.success({
                    content: 'Device configuration updated successfully.',
                    key: device.id,
                });

                // Reload the device config from database
                API.device.getDeviceConfiguration(device.id)
                    .then((response) => {
                        setDeviceConfig(response);
                    });
            })
            .catch((error) => {
                message.error({
                    content: `Device configuration update failed.`,
                    key: device.id,
                });
                console.error(error.message);
            });
        setSubmitConfig(null);
    };

    // Load device configuration when dialog is opened
    useEffect(() => {
        if (visible === false) { return; }
        if (device === null) {
            console.error('DeviceConfigurationModal opened without device.');
            return;
        }

        API.device.getDeviceConfiguration(device.id)
            .then((response) => {
                console.log(response);
                setDeviceConfig(response);
            });
    }, [visible]);

    return (
        <React.Fragment>
            <Modal
                destroyOnClose={true}
                width={"1200px"}
                visible={visible}
                title={`${(advanced === true) ? 'Advanced' : 'Basic'} Device Configuration - ${(device !== null ? `${device.model} ${device.serial}` : 'NO DEVICE')}`}
                onCancel={() => onCancel()}
                footer={null}
            >
                {/* Show loading screen whilst data not received */}
                {(deviceConfig === undefined || schema === undefined) ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '65vh',
                    }}>
                        <Spin tip={'Loading...'} indicator={<LoadingOutlined />} size={'large'} />
                    </div>
                    :
                    <Tabs tabPosition={"left"} type={'line'} defaultActiveKey="0">
                        {schema.categories.map((category, index) => {

                            // Ignore advanced fields if configuration is not advanced
                            if (
                                advanced === false
                                && has.call(category, 'advanced')
                                && category.advanced === true) {
                                return [];
                            }

                            // Ignore fields marked as hidden
                            if (has.call(category, 'visible') && category.visible === false) {
                                return [];
                            }
                            // {deviceConfig && setStatusI(deviceConfig !== null ? deviceConfig.find(cat => cat.config_id === category.id) : null)}
                            let statusI = deviceConfig !== null ? deviceConfig.find(cat => cat.config_id === category.id) : null;
                            return (
                                <TabPane tab={<span>
                                    {statusI !== undefined && statusI !== null ? STATUS_ICON[statusI.status] : null}
                                    {category.name}
                                </span>} key={category.id}>
                                    <CategoryConfigurationForm
                                        device={device}
                                        category={category}
                                        deviceConfig={(deviceConfig !== null ? deviceConfig.find(cat => cat.config_id === category.id) : null)}
                                        onSubmit={(data) => {

                                            // Assign function pointer to submitConfig
                                            setSubmitConfig({
                                                config_id: category.id,
                                                device_type: device.device_type,
                                                device_id: device.id,
                                                settings: data
                                            });

                                            setWarningVisible(true);
                                        }}
                                        configurationPermission={"config.advanced"}
                                        onCancel={() => onCancel()}
                                        advanced={advanced}
                                    />
                                </TabPane>
                            )
                        }
                        )}
                    </Tabs>
                }
            </Modal>
            {<WarningModal
                visible={warningVisible}
                title="Editing Advanced Configuration"
                message="Are you sure you want to edit the advanced configuration of this device? This will override any existing configuration."
                onOk={postDeviceConfig}
                close={() => setWarningVisible(false)}
            />}
        </React.Fragment>
    )
}

DeviceConfigurationDialog.propTypes = {
    schema: PropTypes.object,
    device: PropTypes.object,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    advanced: PropTypes.bool,
}

DeviceConfigurationDialog.defaultProps = {
    schema: null,
    device: null,
    advanced: false,
}

export default observer(DeviceConfigurationDialog);
