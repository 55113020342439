
export const primaryButton = (props) => {
    const additions = {
        backgroundColor: '#0066CC',
        color: 'white',
        borderColor: 'grey',
        borderRadius: '5px',
        oveflow: 'hidden',
    }

    return ({
        ...additions,
        ...props,
    })
}

export const greenButton = (props) => {
    const additions = {
        backgroundColor: "green",
        color: "white",
        borderColor: "grey",
        borderRadius: "5px",
    }

    return ({
        ...additions,
        ...props,
    })
}

export const secondaryButton = (props) => {
    const additions = {
        borderRadius: "5px"
    }

    return ({
        ...additions,
        ...props,
    })
}

export const contentBox = (props) => {
    const additions = {
        marginTop: '2%' ,
        background: 'white',
        padding: '1.5rem',
        borderRadius: '0.5rem',
        boxShadow: '5px 5px 5px 5px #E6E6E6',
    }

    return ({
        ...additions,
        ...props,
    })
}

export const topRightMenu = (props) => {
    const additions = {
        marginTop: "2%" ,
        paddingTop: '1%',
        paddingLeft: '5%',
        paddingRight: '5%' ,
        borderRadius: "7px",
    }

    return ({
        ...additions,
        ...props,
    })
}

export const contentCard = (props) => {
    const additions = {
        borderRadius: '8px',
        background: '#dedede',
        boxShadow: '0.3em 0.3em 0.7em #D0D0D0',
        height: '100%'
    }

    return ({
        ...additions,
        ...props,
    })
}

export const color = {
    selected: '#ededed',
}
