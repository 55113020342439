import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Modal, Select } from "antd";
import React from "react";
import API from '../../services/api';

const { Option } = Select;

class NickNameForm extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        values.id = this.props.data.id;
        this.props.onSubmit({ values, mode: this.props.mode });
        this.props.form.resetFields();
      }
    });
  };

  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data, mode } = this.props;
    let extraData = {};
    const colors = [
      {name: 'Red', value: '#C0392B'},
      {name: 'Blue', value: '#0000FF'},
      {name: 'Purple', value: '#6a0dad'},
      {name: 'Green', value: '#008000'},
      {name: 'Orange', value: '#FFA500'}
    ]

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Modal
          title={mode === "add" ? "Add Entity" : "Edit Entity"}
          visible={this.props.opened}
          onOk={this.onUserSubmit}
          confirmLoading={false}
          onCancel={() => {
            this.props.form.resetFields();
            this.props.onCancel();
          }}
          footer={[
            <Button type="dashed" key="back" onClick={this.onCancel}>
              Cancel
            </Button>,
            <Button
              loading={this.state.loading}
              onClick={this.handleSubmit}
              key="submit"
            >
              {mode === "add" ? "Add Enity" : "Edit Entity"}
            </Button>,
          ]}
        >
          <p>Device Mac: {`${data.clonedMac}`}</p>
          <p>SSID: {`${data.ssid}`}</p>
          <Form.Item label="Alias">
            {getFieldDecorator("nickname", {
              rules: [{ required: true, message: "Please input a name!" }],
              initialValue: mode === "edit" && data ? data.nickname : "",
            })(
              <Input
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="nickname"
              />
            )}
          </Form.Item>

          <Form.Item label="Notes">
            {getFieldDecorator("notes", {
              rules: [{ required: true, message: "Enter Notes" }],
              initialValue: mode === "edit" && data ? data.notes : "",
            })(
              <Input
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="textarea"
                height={200}
                placeholder="notes"
              />
            )}
          </Form.Item>
        </Modal>
      </Form>
    );
  }
}

export default Form.create({})(NickNameForm);
