import { Alert, Input, Modal } from "antd";
import React, { useState, useEffect, useContext } from "react";
import PropType from 'prop-types';
import getPublicUrl from "../../utilities/public-url.utility";
import userApi from "../../services/api/user.api";

export default function PasswordResetModal({
    children: Component,
    onClickCancel,
    title,
    userID,
    userDetails,
    content,
    textToType,
}) {
    // const [isDeleteable, setIsDeleteable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [token, RsetToken] = useState("");

    const onChildClick = () => {
    // api call to reset password this api is already set
        setIsVisible(true);
    };
    const BASEURL = localStorage.getItem("baseURL");

    const GetToken = async () => {
        let thing;
        await userApi.getPWTOKEN(userID).then((res) => {
            RsetToken(res[0]);
        });
    };

    const onOkayClick = async (email) => {
        // api call to reset password this api is already set
        await userApi.sendPWRESET(email).then((res) => {
            console.log(res);
            if (res) {
                GetToken();
            }
        });
    }

    const ComponentWithHandler = React.cloneElement(Component, {
        onClick: onChildClick,
    });
    let tokenText = "No password reset token found";
    useEffect(() => {
        GetToken();
    }, []);
    if (token.reset_password_token) {
        tokenText = `${BASEURL}/portal/password-reset/${token.reset_password_token}`;
    }
    if (token.reset_password_expires && token.reset_password_expires <= (Date.now()/1000)) {
        tokenText = "Password reset token has expired, please reset password again";
    }
    return (
        <React.Fragment>
            <Modal
                okText="Reset User password"
                okType="danger"

                title={title}
                visible={isVisible}
                onOk={() => {
                    onOkayClick(userDetails.email);
                }}
                onCancel={() => {
                    setIsVisible(false);
                    return onClickCancel();
                }}
            >
                <p>{"Please copy the below link and send to user"}</p>
                <p>{tokenText}</p>
            </Modal>
            {ComponentWithHandler}
        </React.Fragment>
    );
}

PasswordResetModal.propType = {
    children: PropType.any,
    onClickOkay: PropType.func,
    userID: PropType.string,
    onClickCancel: PropType.func,
    title: PropType.object,
    content: PropType.object,
    textToType: PropType.object
}
