import { LockOutlined, MailOutlined, UserOutlined, PhoneOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Select } from "antd";
import React from "react";
import { secondaryButton, primaryButton } from '../../styles';

const { Option } = Select;

class UserForm extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        values.id = this.props.data.id;
        this.props.onSubmit({ values, mode: this.props.mode });
        this.props.form.resetFields();
      }
    });
  };

  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };

  validateUsername = (rule, value, callback) => {
    const { form } = this.props;
    let letterNumber = /^[0-9a-zA-Z]+$/;
    if (form.getFieldValue("username").match(letterNumber)) {
      callback();
    } else {
      if (form.getFieldValue("username") !== "")
        callback("username must be alpha numeric characters!");
      else callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { data, mode } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Modal
          title={mode === "add" ? "Add User" : "Edit User"}
          visible={this.props.opened}
          onOk={this.onUserSubmit}
          confirmLoading={false}
          onCancel={() => {
            this.props.form.resetFields();
            this.props.onCancel();
          }}
          footer={[
            <Button id={"addusercancelbtn"} style={secondaryButton()} key="back" onClick={this.onCancel}>
              Cancel
            </Button>,
            <Button
                id={"addEditUserBtn"}
              loading={this.state.loading}
              onClick={this.handleSubmit}
              style={primaryButton()}
              key="submit"
            >
              {mode === "add" ? "Add User" : "Edit User"}
            </Button>,
          ]}
        >
          <Form.Item label="Full Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Please input your name!" }],
              initialValue: mode === "edit" && data ? data.name : "",
            })(
              <Input
                  id={"fullname"}
                prefix={
                  <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="Full Name"
              />
            )}
          </Form.Item>
          <Form.Item label="Email Address">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                { required: true, message: "Please input your email address" },
              ],
              initialValue: mode === "edit" && data ? data.email : "",
            })(
              <Input
                  id={"email"}
                prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="email"
                placeholder="Email Address"
              />
            )}
          </Form.Item>
          <Form.Item label="Alias">
            {getFieldDecorator("alias", {
              rules: [
                { required: true, message: "You need to enter an alias" },
              ],
              initialValue:
                mode === "edit" && data && typeof data.alias !== "object"
                  ? data.alias
                  : "",
            })(
              <Input
                  id={"alias"}
                prefix={
                  <UserDeleteOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="Alias"
              />
            )}
          </Form.Item>
          <Form.Item label="Mobile Number: (61412345678)">
            {getFieldDecorator("mobile", {
              rules: [
                {
                  required: true,
                  message: "Please input your mobile number",
                },
              ],
              initialValue: mode === "edit" && data ? data.mobile : "",
            })(
              <Input
                prefix={
                  <PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="email"
                placeholder="Mobile Number"
              />
            )}
          </Form.Item>
          <Form.Item label="Investigation">
            {getFieldDecorator("investigation", {
              rules: [],
              initialValue:
                mode === "edit" && data.investigation
                  ? (() => {
                      return data.investigation.map((i) => i.toString());
                    })()
                  : [],
            })(
              <Select
                showSearch
                allowClear="true"
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Investigations"
              >
                {this.props.investigations.map((investigation) => (
                  <Option key={investigation.id}>{investigation.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Role">
            {getFieldDecorator("role_id", {
              rules: [{ required: true, message: "User should have a role" }],
              initialValue:
                mode === "edit" && data.role_id ? data.role_id.toString() : [],
            })(
              <Select showSearch style={{ width: "100%" }} placeholder="Role" >
                {this.props.roles.map((role) => (
                  <Option id={role.id} key={role.id}>
                    <p>{role.name}</p>
                    <p>{role.description}</p>
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          {mode === "edit" && (
            <Form.Item label="Status">
              {getFieldDecorator("status", {
                // rules: [{ required: true, message: 'User should have a status' }],
                initialValue: mode === "edit" && data ? data.status : [],
              })(
                <Select style={{ width: "100%" }} placeholder="Status">
                  {["APPROVED", "DENIED", "PENDING"].map((status) => (
                    <Option key={status}>{status}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}

          {/* <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="profile-update-form-button"
              block
            >
              Add User
            </Button>
          </Form.Item> */}
        </Modal>
      </Form>
    );
  }
}

export default Form.create({})(UserForm);
