import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();


/**
 * Get all dashboard settings
 * @returns {Promise<unknown>}
 */
const getAllDashboardSettings = async () => {
    try {
        const response = await HTTP.get(`/dashboard-setting`);
        const data = response.data.data;
        // this is to remove messaging from dash board without modifying dash board
        const thing = data.dashboardSetting;
        const index = thing.findIndex(item => item.id === 3);
        if (index !== -1) {
            thing.splice(index, 1);
        }
        const index2 = thing.findIndex(item => item.id === 5);
        if (index !== -1) {
            thing.splice(index2, 1);
        }
        data.userDashboardSetting = thing;
        return Promise.resolve(data);
    } catch (exception) {
        if (exception.response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve({dashboardSetting: []});
        }
        return Promise.reject(exception);
    }
}

/**
 * Update dashboard settings
 * @param payload
 * @returns {Promise<unknown>}
 */
const updateDashboardSettings = async (payload) => {
    try {
        const response = await HTTP.post(`/user-dashboard-setting`, payload);
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

/**
 * Get users dashboard settings
 * @returns {Promise<unknown>}
 */
const getUsersDashboardSettings = async () => {
    try {
        const response = await HTTP.get(`/user-dashboard-setting`);
        const data = response.data.data;
        // this is to remove messaging from dash board without modifying dash board
        const thing = data.userDashboardSetting;
        const index = thing.findIndex(item => item.dash_setting_id === 3);
        if (index !== -1) {
            thing.splice(index, 1);
        }
        data.userDashboardSetting = thing;
        return Promise.resolve(data);
    } catch (exception) {
        if (exception.response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve({userDashboardSetting: []});
        }
        return Promise.reject(exception);
    }
}


export default {
    getAllDashboardSettings,
    updateDashboardSettings,
    getUsersDashboardSettings
};