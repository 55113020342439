import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const createDevice = async ({ serial, device_type_id, license }) => {
    try {
        const response = await HTTP.post("/device", {
            serial,
            device_type_id: Number(device_type_id),
            license,
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
            response.data.data.device[response.data.data.device.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteDevice = async (id) => {
    try {
        const response = await HTTP.delete(`/device/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllDevices = async () => {
    try {
        const response = await HTTP.get(`/device`);

        const data = response.data.data.device;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getByInvestigation = async (id) => {
    try {
        const response = await HTTP.get(`/device/investigation/${id}`);

        const data = response.data.data;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getDashboardGPS = async(id) => {
    try {
        const response = await HTTP.get(`device/GPS/${id}`);
        const data = response.data.attribute[0];

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getAllUnassignedDevices = async () => {
    try {
        const response = await HTTP.get(`/device/unassigned`);

        const data = response.data.data.device;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getDeviceTypes = async () => {
    try {
        const response = await HTTP.get(`/device-type/`);

        const data = response.data.data.deviceManufacturer;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateDeviceConfigurationMode = async ({ id, configuration_mode }) => {
    try {
        const response = await HTTP.put(`/device/update-mode/${id}`,
            {
                configuration_mode: configuration_mode,
            });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getDeviceDetail = async ({ id }) => {
    try {
        const response = await HTTP.get(`/device/${id}`);

        const data = response.data.data.device;

        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

// Get live status of the device
const getRecoveryStatus = async (id) => {
    try {
        const response = await HTTP.get(`/device-configuration/?deviceid=${id}&configid=1`);
        const data = response.data.data.deviceConfiguration;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

// Fetch the configuration dictionary of the given device
const getDeviceConfiguration = async (device_id) => {
    try {
        const res = await HTTP.get(`/device-configuration/?deviceid=${device_id}`);
        if (res.status === 200) {
            return Promise.resolve(res.data.data.deviceConfiguration);
        } else {
            return Promise.resolve(null);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}

// Submit a new configuration for the given device
const postDeviceConfiguration = async (device_id, device_config) => {
    try {
        const res = await HTTP.post(`/device-configuration/?deviceid=${device_id}`, device_config);
        return Promise.resolve(res.data.data.deviceConfiguration);
    } catch (e) {
        return Promise.reject(e);
    }
}

// Fetch the configuration schema of a device type
const getDeviceConfigurationSchema = async (vendor, deviceType) => {
    // if (vendor === "hunt") {
    //     return null;
    // }
    try {
        const res = await HTTP.get(`/device-configuration/schema/${vendor}/${deviceType}`);
        return Promise.resolve(res.data.data.deviceSchema);
    } catch (e) {
        return Promise.reject(e);
    }
}

// Submit a new configuration for the given device
const postDevicePreset = async (device_id, preset) => {
    try {
        const res = await HTTP.post(`/device-configuration/preset/${preset}?deviceid=${device_id}`);
        return Promise.resolve(res.data.data);
    } catch (e) {
        return Promise.reject(e);
    }
}

const FlickDevice = async (deviceId) => {
    try {
        const response = await HTTP.get(`/device-configuration/FlickADevice/${deviceId}`);
        const data = response.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }

}

export default {
    createDevice,
    deleteDevice,
    getAllDevices,
    getByInvestigation,
    getDeviceTypes,
    getAllUnassignedDevices,
    getRecoveryStatus,
    getDashboardGPS,
    updateDeviceConfigurationMode,
    getDeviceDetail,
    getDeviceConfiguration,
    postDeviceConfiguration,
    getDeviceConfigurationSchema,
    postDevicePreset,
    FlickDevice,
};
