import React from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, Input, Modal, Select, Tabs, Radio, Checkbox, Badge} from "antd";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { primaryButton, secondaryButton } from '../../styles';

const { Option } = Select;
const { TabPane } = Tabs;

class DeviceForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = { loading: false, value: 0 };
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                this.props.onSubmit(values);
                this.props.form.resetFields();
            }
        });
    };

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    createTabPane = (permissionList) => {
        // category
        // route
        // permissions = {
        //   "name": [
        //     ItemOBJ
        //   ]
        // }
        let permissions = {};
        permissionList.forEach((item) => {
          if (permissions[item.manufacturer] === undefined) {
            permissions[item.manufacturer] = [];
          }
          permissions[item.manufacturer].push(item);
        });

        return (

            <Tabs
                tabPosition={"bottom"}
                defaultActiveKey="1"
                type="mode"
                style={{ width: "100%" }}
            >
                {Object.keys(permissions).map((key, index) => {
                    return (
                        <TabPane
                            tab={key}
                            key={index + 1}
                        >
                            {permissions[key].map((item, index) => {
                                return (
                                    <Row key={index}>
                                        <Col key={item.id} id={"perm_" + item.id}>
                                            <Radio value={item.id}>{item.name}</Radio>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </TabPane>
                    );
                }
                )}
            </Tabs>
        )
      }
    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Modal
                title={"Add Device"}
                visible={this.props.opened}
                onOk={(e) => this.handleSubmit(e)}
                confirmLoading={false}
                width={1000}
                onCancel={() => {
                    this.props.form.resetFields();
                    this.props.onCancel();
                }}
                footer={[
                    <Button id={"cancelAddDeviceBtn"} style={secondaryButton({ width: "47%", right: "3%", borderRadius: "5px", })} key="back" onClick={() => this.onCancel()}>
                        Cancel
                    </Button>,
                    <Button
                        id={"addDeviceBtnModal"}
                        loading={this.state.loading}
                        onClick={(e) => this.handleSubmit(e)}
                        style={primaryButton({ width: "47%", right: "2%", borderRadius: "5px", })}
                        key="submit"
                    >
                        Add Device
                    </Button>
                ]}
            >
                <Form onSubmit={(e) => this.handleSubmit(e)} className="device-form">
                    <Form.Item label="Type">

                        {getFieldDecorator("device_type_id", {
                            rules: [
                                {
                                    required: true,
                                    message: "Please select the device type."
                                }
                            ]
                        })(
                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                {this.createTabPane(this.props.deviceTypes)}
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item label="Serial Number">
                        {getFieldDecorator("serial", {
                            rules: [
                                {
                                    required: true,
                                    message: "Please input the device serial number."
                                }
                            ]
                        })(
                            <Input
                                type="text"
                                placeholder="Serial Number"
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Allocate a license to this device"
                    >
                        {getFieldDecorator("license", {
                            rules: [
                                {
                                    required: false,
                                    message: "Please input the device license."
                                }
                            ]
                        })(
                            <Select style={{ width: "100%" }}
                                placeholder="Select a license"
                                disabled={this.props.AdvancedPerms}
                            >
                                {this.props.unallocatedLicensesData && this.props.unallocatedLicensesData.dlMapping && this.props.unallocatedLicensesData.dlMapping.map((entry) => (
                                    entry.expiry_date > Date.now() / 1000 ?
                                        <Select.Option value={entry.license_id}>
                                            <p>{entry.alias}</p>

                                            <React.Fragment>
                                                <Badge status={"success"}></Badge>{" "}
                                                <span>Expiry Date {new Date(Number(entry.expiry_date) * 1000).toLocaleDateString()}</span>{" "}

                                            </React.Fragment>
                                        </Select.Option>
                                        :
                                        []
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>

        );
    }
}

DeviceForm.propTypes = {
    AdvancedPerms: PropTypes.bool.isRequired,
    unallocatedLicensesData: PropTypes.array.isRequired,
    deviceTypes: PropTypes.array.isRequired,
    opened: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default Form.create({})(DeviceForm);
