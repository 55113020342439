import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import React, { useState, useEffect } from "react";
import { mapDefault, convertSpeed, getGMTDateTime } from "../../util/CommonUtils";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";

function AnalysisMultiMap({
    visiblePoints,
    speedUnitString
}) {
    const [investigationsForUser, selectedInvestigation] = useInvestigationForUser();

    const [map, setMap] = useState(null);
    const [located, setLocated] = useState(false);
    const [displayed, setDisplayed] = useState([]);

    useEffect(() => {
        if (visiblePoints && visiblePoints.length > 0) {
            let points = [];
            visiblePoints.forEach((point) => {
                if (point) {
                    point.map((p) => {
                        points = [...points, { lat: p.latitude, lng: p.longitude }]
                    })
                }
            });
            const bounds = new window.google.maps.LatLngBounds();
            if (points) {
                points.forEach((point) => {
                    bounds.extend(point);
                });
                if (points.length > 0) {
                    map.fitBounds(bounds);
                }
            }
        }
    }, [visiblePoints]);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;
        if (located === false) {
            if (map) {
                map.panTo({
                    lat: crd.latitude,
                    lng: crd.longitude,
                });
                setLocated(true);
            }
        }
    }

    const fail = (error) => {
        console.log(`Error: ${error.code}`);

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    navigator.geolocation.getCurrentPosition(success, fail, options);

    const vischeck = (id) => {
        return (displayed.includes(id) ? true : false)
    }

    const markerdisplay = (id) => {
        if (displayed.includes(id)) {
            setDisplayed(displayed.filter((item) => item !== id))
        } else {
            setDisplayed([...displayed, id])
        }
    }

    const mainColour = [
        "#71FC27",
        "#fc5353",
        "#54e3ff",
        "#fffc54",
    ]

    const highlightColour = [
        "#01290D",
        "#381313",
        "#152f40",
        "#454016",
    ]

    return (
        <GoogleMap
            defaultCenter={
                mapDefault()
            }
            yesIWantToUseGoogleMapApiInternals
            mapContainerStyle={{
                height: "100vh",
                width: "100%"
            }
            }
            onIdle={() => {

                if (map.getCenter()) {
                    localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                }
            }}
            zoom={15}
            onLoad={map => setMap(map)}
        >
            {visiblePoints.map((sequence, index) => {
                return (
                    sequence.map((point, index2) => {
                        return (
                            <Marker
                                key={index2}
                                position={{
                                    lat: point.latitude,
                                    lng: point.longitude
                                }}
                                icon={
                                    point.speed > 1 ?
                                        {
                                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                            fillColor: mainColour[index],
                                            fillOpacity: 1,
                                            strokeWeight: 1,
                                            strokeColor: highlightColour[index],
                                            rotation: point.heading,
                                            scale: 4,
                                            anchor: new window.google.maps.Point(0, 3)
                                        } : {
                                            path: window.google.maps.SymbolPath.CIRCLE,
                                            fillColor: mainColour[index],
                                            fillOpacity: 1,
                                            strokeWeight: 1,
                                            strokeColor: highlightColour[index],
                                            scale: 5,
                                            anchor: new window.google.maps.Point(0, 0)
                                        }
                                }

                                onClick={() => markerdisplay(point.id)}
                            >
                                <div
                                >
                                    {
                                        vischeck(point.id)
                                            ?
                                            <InfoWindow
                                                onCloseClick={() => markerdisplay(point.id)}
                                                position={{
                                                    lat: point.latitude,
                                                    lng: point.longitude,
                                                }}
                                            >
                                                <div>
                                                    <p>Marker Latitude: {point.latitude}</p>
                                                    <p>Marker Longitude: {point.longitude}</p>
                                                    <p>Time: {getGMTDateTime(point.device_utc_date_time)}</p>
                                                    <p>Speed: {convertSpeed(speedUnitString, point.speed)}</p>
                                                    <p>Target: {point.targetName}</p>
                                                </div>
                                            </InfoWindow>
                                            :
                                            <div></div>
                                    }
                                </div>
                            </Marker>
                        )
                    })
                )
            })}
        </GoogleMap>
    );
}

export default AnalysisMultiMap;