import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

export class TemplateStore {


    constructor() {
        this.api = API;
        this.templates = [];
        this.fields = [];
        makeAutoObservable(this);
    }

    async getAllTemplates() {
        const response = await this.api.template.getAllTemplates();
        this.templates = response;
    }

    async getAllFields() {
        const response = await this.api.template.getAllFields();
        this.fields = response;
    }

    async createTemplate({ name, description, field_id }) {
        try {
            await this.api.template.createTemplate({
                name,
                description,
                field_id
            });
            message.success(`Template  has been created.`);
            this.getAllTemplates();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async updateTemplate({ name, description, field_id, id }) {
        try {
            await this.api.template.updateTemplate({
                name,
                description,
                field_id,
                id
            });
            message.success(`Template  has been updated.`);
            this.getAllTemplates();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async deleteTemplate(record) {
        try {
            await this.api.template.deleteTemplate(record.id);
            message.success(`Template '${record.name}' has been deleted.`);
            this.getAllTemplates();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }
}

export default createContext(new TemplateStore());
