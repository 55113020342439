import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const GeTAllDevices = async (investigation) => {
    try {
        const response = await HTTP.get(`/bluetooth/investigation/${investigation}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.history;
        let len = data.length;

        for (let i = 0; i < len; i += 1) {
            // timestamp adjust
            let time = new Date(0);
            time.setUTCSeconds(data[i].last_seen);
            data[i].last_seen = time.toString();

            // lastlocation geocode
            let latlng = {
                lat: data[i].latitude,
                lng: data[i].longitude
            }

            data[i].location = JSON.stringify(latlng);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const update = async (id, entityID) => {
    try {
        const response = await HTTP.post(`/bluetooth/update/${id}?entityID=${entityID}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        return Promise.resolve(response);
    }
    catch (error) {
        return Promise.reject(error)
    }
}

const getCaptures = async (investigationId, start, end) => {
    try {
        const response = await HTTP.get(`/bluetooth/captures/${investigationId}?start=${start}&end=${end}`)
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const res = response.data.data.devices;
        // res["entity"].alias = response.entity_alias
        return Promise.resolve(res);
    }
    catch (error) {
        return Promise.reject(error);
    }
}

export default {
    GeTAllDevices,
    update,
    getCaptures
}