import { PlusOutlined } from '@ant-design/icons';
import { Button } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import UserPermissionFormModal from "../../components/UserPermissionForm/index";
import UserRoleTable from "../../components/UserRoleTable/index";
import PermissionStore from "../../stores/permission.store";
import RoleStore from "../../stores/role.store";
import Layout from "../AppLayout";
import "./UserPermissionPage.scss";
import { secondaryButton, contentBox } from '../../styles';

function UserPermissionPage(props) {
    const store = useContext(PermissionStore);
    const roleStore = useContext(RoleStore);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await store.getPermissionByRoleId(role);
            store.assignedPermission = permission;
        };

        store.getPermissions();
        roleStore.getAllRoles();
        fetchRolePermission();
    }, [store, roleStore]);

    const onDeleteRecord = record => {
        roleStore.deleteRole(record);
    };

    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});

    const onEditRecord = async record => {
        record.permission = [];

        record.permission = await store.getPermissionByRoleId(record);
        setMode("edit");
        setExistingData(record);
        setIsModalOpen(true);
    };

    return (
        <div className="UserPermissionPage">
            <UserPermissionFormModal
                userRoles={roleStore.roles}
                roles={roleStore.roles}
                mode={mode}
                data={existingData || null}
                opened={isModalOpen}
                permissionList={toJS(store.permission)}
                onSubmit={async data => {
                    if (mode === "edit") {
                        roleStore.updateUseRole(data);
                        setIsModalOpen(false);
                        setExistingData({});
                    } else {
                        roleStore.createUserRole(data);
                        setIsModalOpen(false);
                        setExistingData({});
                    }
                    await roleStore.getAllRoles();
                    await store.getPermissions();
                    store.getPermissionByRoleIdTest(JSON.parse(localStorage.getItem("role")));
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                }}
            ></UserPermissionFormModal>
            <Layout
                showNavigation
                title="User Role Administration"
                description="Manage user roles."
            >
                <div
                    style={contentBox()}
                >
                    <Button
                        style={secondaryButton()}
                        id={"addUserRoleBtn"}
                        disabled={store.can("role.create")}
                        onClick={() => {
                            setMode("add");
                            setIsModalOpen(true);
                        }}
                    >
                        <PlusOutlined></PlusOutlined>
                        Add User Role
                    </Button>
                    <div className="user-table">
                        <UserRoleTable
                            canEdit={store.can("role.update")}
                            canDelete={store.can("role.delete")}
                            onEditRecord={onEditRecord}
                            onDelete={onDeleteRecord}
                            data={!store.can("role.list") ? toJS(roleStore.roles) : []}
                        ></UserRoleTable>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default observer(UserPermissionPage);
