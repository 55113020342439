import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Modal, Row, Select, Space, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { UserOutlined, HistoryOutlined, DownloadOutlined} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from "react";
import PermissionStore from "../../stores/permission.store";
import Layout from "../AppLayout";
import { toJS } from "mobx";
import moment from 'moment';
import { contentBox, primaryButton, secondaryButton } from '../../styles'
import ExportHistory from '../../components/exportHistory';

import UserStore from '../../stores/user.store';
import UserAudits from '../../components/UserAudits';
import DeviceHistoryTab from '../../components/deviceHistoryTab';

const { TabPane } = Tabs;

function UserAuditPage() {

    const userStore = useContext(UserStore);

    useEffect(async () => {
        await userStore.getUsers();
    }, []);


    return (
        <div>
            <Layout
                showNavigation
                title={"User Audit"}
                description="Dive Into what your users are doing."
            >
                <div style={contentBox({ marginTop: "0" })}>
                    <Tabs tabPosition={"top"} defaultActiveKey="1" type="cards">
                        <TabPane tab={<span><UserOutlined /> User Audits</span>} key="1">
                            <UserAudits></UserAudits>
                        </TabPane>
                        <TabPane tab={<span><DownloadOutlined /> Export History</span>} key="2">
                            <ExportHistory></ExportHistory>
                        </TabPane>
                        <TabPane tab={<span><HistoryOutlined /> Target/ Device life span</span>} key="3">
                            <DeviceHistoryTab></DeviceHistoryTab>
                        </TabPane>
                    </Tabs>
                </div>
            </Layout>
        </div >
    );
}

export default observer(UserAuditPage);
