import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, Select, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { formatDate } from "../../util/CommonUtils";
import { primaryButton } from '../../styles';

class WrapperConfigurationForm extends React.Component {
  state = {
    hasSeverMissingFields: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.onSubmit({ values });
      } else {
      }
    });
  };

  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const config = this.props.configData;
    const settings =
      this.props.configData &&
      JSON.parse(this.props.configData.settings) &&
      JSON.parse(this.props.configData.settings).settings;
    //
    //
    return (
      <div style={{ padding: '20px', borderRadius: "15px", background: "white"}}>
        <Form onSubmit={this.handleSubmit} className="tab1-form">
          <div>
            {this.props.configData !== undefined ? (
              <Status
                status={config.status}
                deviceCreatedDate={config.created_at}
                deviceUpdatedDate={config.device_updated_at}
                configurationUpdatedDate={config.updated_at}
              ></Status>
            ) : (
              <Alert
                type="error"
                message="The device has no saved configurations for this tab. Please click update to load the device with the default/updated configuration."
                banner
              />
            )}
          </div>

          {this.props.fieldConstraints.fields.map((field) => {
            if (this.props.deviceConfigMode) {
              let permissibleField = false;

              if (field.preset && field.preset.length > 0) {
                permissibleField =
                  field.preset.filter((x) => x === this.props.deviceConfigMode)
                    .length > 0;
              }

              if (permissibleField) {
                return (
                  <React.Fragment>
                    <Form.Item
                      style={{
                        display: Boolean(field.visible) ? "none" : "",
                        border: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "1px solid #c0392b",
                        marginTop: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "8px",
                        padding: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "8px",
                      }}
                      label={
                        <span>
                          {field.displayName}&nbsp;
                          <Tooltip title={`${field.desc}`}>
                            <QuestionCircleTwoTone twoToneColor="#27ae60" />
                          </Tooltip>
                        </span>
                      }
                    >
                      {!!localStorage.getItem("show-config") === true && (
                        <div>
                          <pre
                            style={{
                              maxHeight: "400px",
                              overflowY: "scroll",
                            }}
                          >
                            {JSON.stringify(field, null, 2)}
                          </pre>
                          <pre>
                            Server Value:{" "}
                            {(settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name]
                              : "N/A"}
                          </pre>
                          <pre>
                            Initial Value:{" "}
                            {settings &&
                              (settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name].toString()
                              : field.default.toString()}
                          </pre>
                        </div>
                      )}

                      {getFieldDecorator(field.name, {
                        rules: [
                          !field.hasOwnProperty("valueListFields") && {
                            validator: (rule, value, callback) => {
                              const min = Number(field.min) || 0;
                              const max = Number(field.max) || Infinity;
                              if (value >= min && value <= max) {
                                callback();
                              } else {
                                callback(
                                  `Range should be between ${min} and ${max}`
                                );
                              }
                            },
                          },
                        ],
                        initialValue:
                          settings && (settings || {}).hasOwnProperty(field.name)
                            ? settings[field.name].toString()
                            : field.default.toString(),
                      })(
                        field.hasOwnProperty("valueListFields") ? (
                          <Select>
                            {field.valueListFields.map((entry) => (
                              <Select.Option value={entry.value.toString()}>
                                {entry.name}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            type="text"
                            placeholder={`${field.displayName}`}
                          />
                        )
                      )}
                    </Form.Item>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Form.Item
                      style={{
                        display: "none",
                        border: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "1px solid #c0392b",
                        marginTop: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "8px",
                        padding: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "8px",
                      }}
                      label={
                        <span>
                          {field.displayName}&nbsp;
                          <Tooltip title={`${field.desc}`}>
                            <QuestionCircleTwoTone twoToneColor="#27ae60" />
                          </Tooltip>
                        </span>
                      }
                    >
                      {!!localStorage.getItem("show-config") === true && (
                        <div>
                          <pre
                            style={{
                              maxHeight: "400px",
                              overflowY: "scroll",
                            }}
                          >
                            {JSON.stringify(field, null, 2)}
                          </pre>
                          <pre>
                            Server Value:{" "}
                            {(settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name]
                              : "N/A"}
                          </pre>
                          <pre>
                            Initial Value:{" "}
                            {settings &&
                              (settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name].toString()
                              : field.default.toString()}
                          </pre>
                        </div>
                      )}

                      {getFieldDecorator(field.name, {
                        rules: [
                          !field.hasOwnProperty("valueListFields") && {
                            validator: (rule, value, callback) => {
                              const min = Number(field.min) || 0;
                              const max = Number(field.max) || Infinity;
                              if (value >= min && value <= max) {
                                callback();
                              } else {
                                callback(
                                  `Range should be between ${min} and ${max}`
                                );
                              }
                            },
                          },
                        ],
                        initialValue:
                          settings && (settings || {}).hasOwnProperty(field.name)
                            ? settings[field.name].toString()
                            : field.default.toString(),
                      })(
                        field.hasOwnProperty("valueListFields") ? (
                          <Select>
                            {field.valueListFields.map((entry) => (
                              <Select.Option value={entry.value.toString()}>
                                {entry.name}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            type="text"
                            placeholder={`${field.displayName}`}
                          />
                        )
                      )}
                    </Form.Item>
                  </React.Fragment>
                );
              }
            } else {
              let permissibleField = false;
              if (field.permissions && field.permissions.length > 0) {
                permissibleField =
                  field.permissions.filter(
                    (x) => x === this.props.configurationPermission
                  ).length > 0;
              }

              if (permissibleField) {
                return (
                  <React.Fragment>
                    <Form.Item
                      style={{
                        display: Boolean(field.visible) ? "none" : "",
                        border: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "1px solid #c0392b",
                        marginTop: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "8px",
                        padding: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : "8px",
                      }}
                      label={
                        <span>
                          {field.displayName}&nbsp;
                          <Tooltip title={`${field.desc}`}>
                            <QuestionCircleTwoTone twoToneColor="#27ae60" />
                          </Tooltip>
                        </span>
                      }
                    >
                      {!!localStorage.getItem("show-config") === true && (
                        <div>
                          <pre
                            style={{
                              maxHeight: "400px",
                              overflowY: "scroll",
                            }}
                          >
                            {JSON.stringify(field, null, 2)}
                          </pre>
                          <pre>
                            Server Value:{" "}
                            {(settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name]
                              : "N/A"}
                          </pre>
                          <pre>
                            Initial Value:{" "}
                            {settings &&
                              (settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name].toString()
                              : field.default.toString()}
                          </pre>
                        </div>
                      )}

                      {getFieldDecorator(field.name, {
                        rules: [
                          !field.hasOwnProperty("valueListFields") && {
                            validator: (rule, value, callback) => {
                              const min = Number(field.min) || 0;
                              const max = Number(field.max) || Infinity;
                              if (value >= min && value <= max) {
                                callback();
                              } else {
                                callback(
                                  `Range should be between ${min} and ${max}`
                                );
                              }
                            },
                          },
                        ],
                        initialValue:
                          settings && (settings || {}).hasOwnProperty(field.name)
                            ? settings[field.name].toString()
                            : field.default.toString(),
                      })(
                        field.hasOwnProperty("valueListFields") ? (
                          <Select>
                            {field.valueListFields.map((entry) => (
                              <Select.Option value={entry.value.toString()}>
                                {entry.name}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            type="text"
                            placeholder={`${field.displayName}`}
                          />
                        )
                      )}
                    </Form.Item>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment>
                    <Form.Item
                      style={{
                        display: this.props.isHuntsman && field.visible === 'true' ? "block" : "none",
                        border: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : this.props.isHuntsman ? "" : "1px solid #c0392b",
                        marginTop: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : this.props.isHuntsman ? "" : "8px",
                        padding: (settings || {}).hasOwnProperty(field.name)
                          ? ""
                          : this.props.isHuntsman ? "" : "8px",
                      }}
                      label={
                        <span>
                          {field.displayName}&nbsp;
                          <Tooltip title={`${field.desc}`}>
                            <QuestionCircleTwoTone twoToneColor="#27ae60" />
                          </Tooltip>
                        </span>
                      }
                    >
                      {!!localStorage.getItem("show-config") === true && (
                        <div>
                          <pre
                            style={{
                              maxHeight: "400px",
                              overflowY: "scroll",
                            }}
                          >
                            {JSON.stringify(field, null, 2)}
                          </pre>
                          <pre>
                            Server Value:{" "}
                            {(settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name]
                              : "N/A"}
                          </pre>
                          <pre>
                            Initial Value:{" "}
                            {settings &&
                              (settings || {}).hasOwnProperty(field.name)
                              ? settings[field.name].toString()
                              : field.default.toString()}
                          </pre>
                        </div>
                      )}

                      {getFieldDecorator(field.name, {
                        rules: [
                          !field.hasOwnProperty("valueListFields") && {
                            validator: (rule, value, callback) => {
                              const min = Number(field.min) || 0;
                              const max = Number(field.max) || Infinity;
                              if (value >= min && value <= max) {
                                callback();
                              } else {
                                callback(
                                  `Range should be between ${min} and ${max}`
                                );
                              }
                            },
                          },
                        ],
                        initialValue:
                          settings && (settings || {}).hasOwnProperty(field.name)
                            ? settings[field.name].toString()
                            : field.default.toString(),
                      })(
                        field.hasOwnProperty("valueListFields") ? (
                          <Select>
                            {field.valueListFields.map((entry) => (
                              <Select.Option value={entry.value.toString()}>
                                {entry.name}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            type="text"
                            placeholder={`${field.displayName}`}
                          />
                        )
                      )}
                    </Form.Item>
                  </React.Fragment>
                );
              }
            }
          })}

          {!this.props.fieldConstraints && (
            <p>This configuration hasn't been made available yet.</p>
          )}

          <div style={{ display: "flex", marginTop: 5 }}>
            <Button
              onClick={this.handleSubmit}
              style={primaryButton({ marginLeft: "auto" })}
              key="submit"
            >
              {" "}
              Update
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(WrapperConfigurationForm);

const Status = ({
  status,
  deviceCreatedDate,
  deviceUpdatedDate,
  configurationUpdatedDate,
}) => {
  const STATUSES = {
    1: "Pending",
    2: "Success",
    3: "Failed",
  };
  const STATUSES_COLOR = {
    1: "#f39c12",
    2: "#27ae60",
    3: "#c0392b",
  };

  const statusInText = STATUSES[status] || "N/A";
  return (
    <Typography.Text>
      <Tag color={STATUSES_COLOR[status]}>{statusInText}</Tag>
      {statusInText === "Pending"
        ? configurationUpdatedDate !== null
          ? `Configuration was updated at ${formatDate(configurationUpdatedDate, 'hh:mm:ss A')}. Device has not received the settings.`
          : `Configuration was updated at ${formatDate(deviceCreatedDate, 'hh:mm:ss A')}. Device has not received the settings.`
        : statusInText === "Success"
          ? `Configuration was pushed to the device on  ${formatDate(deviceUpdatedDate, 'hh:mm:ss A')}. Device has accepted the settings. `
          : `Device has not accepted the current configuration. ${formatDate(deviceUpdatedDate, 'hh:mm:ss A')}.`}
    </Typography.Text>
  );
};