import styled from "styled-components";
import "./LivePinMarker.scss";

const LivePinMarker = styled.div`
      position: relative;
      width: ${props => (props.isMarker ? '20px' : '15px')};
      height: ${props => (props.isMarker ? '20px' : '15px')};
      border-radius: 50%;
      background-color: ${props => (props.colour ? props.colour : 'black')};
      &:before {
        display: block;
        content: "";
        height: ${props => (props.isMarker ? '17px' : '12px')};
        width: 3px;
        background-color: ${props => (props.colour ? props.colour : 'black')};
        border-radius: 25px;
        position: absolute;
        left: 50%;
        bottom:${props => (props.isMarker ? '-16px' : '-11px')};
        transform: translateX(-50%);
      }
`

export default LivePinMarker;
