import { LockOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Tooltip } from "antd";
import React from "react";
import { contentBox, primaryButton, secondaryButton } from '../../styles';

class ProfilePasswordUpdateForm extends React.Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={contentBox()}>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item label="Old Password">
            {getFieldDecorator("oldPassword", {
              rules: [{ required: true, message: "Please fill the field!" }]
            })(
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                New Password&nbsp;
                <Tooltip
                  title={
                    "Password must have at least 8 characters long and contain an uppercase character,a lowercase character, a numeral, a special character."
                  }
                >
                  <QuestionCircleTwoTone twoToneColor="#52c41a" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please fill the field!" }],
              validator: (rule, value, callback) => {
                if (
                  value &&
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&*$])(?=.{8,})/.test(
                    value
                  )
                ) {
                  return callback();
                } else {
                  return callback(
                    "The new password you entered doesn't meet the minimum requirements. Password must have at least 8 characters long and contain an uppercase character,a lowercase character, a numeral, a special character."
                  );
                }
              }
              //{
              //required: true,
              //pattern: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&*$])(?=.{8,})"),
              // message: "The new password you entered doesn't meet the minimum requirements. Password must have at least 8 characters long and contain an uppercase character,a lowercase character, a numeral, a special character."
              //}
              //]
            })(
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              style={primaryButton()}
              htmlType="submit"
              className="profile-password-form-button"
              block
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(ProfilePasswordUpdateForm);
