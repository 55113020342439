import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Tabs } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import UserFormModal from "../../components/UserForm";
import UserTable from "../../components/UserTable";
import InvestigationStore from "../../stores/investigation.store";
import PermissionStore from "../../stores/permission.store";
import RoleStore from "../../stores/role.store";
import UserStore from "../../stores/user.store";
import Layout from "../AppLayout";
import "./UserManagementPage.scss";
import { primaryButton, secondaryButton, contentBox, topRightMenu } from '../../styles'
import UserPermissionFormModal from "../../components/UserPermissionFormATRAX/index";
import UserRoleTable from "../../components/UserRoleTable/index";

const { TabPane } = Tabs;

function UserManagementPage() {
    const store = useContext(UserStore);
    const investigationStore = useContext(InvestigationStore);
    const roleStore = useContext(RoleStore);
    const permissionStore = useContext(PermissionStore);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);

    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await permissionStore.getPermissionByRoleId(role);
            store.assignedPermission = permission;
        };

        permissionStore.getPermissions();
        roleStore.getAllRoles();
        fetchRolePermission();
    }, [store, roleStore]);

    const onDeleteRecord2 = record => {
        roleStore.deleteRole(record);
    };

    const [mode2, setMode2] = useState("add");
    const [existingData2, setExistingData2] = useState({});

    const onEditRecord2 = async record => {
        record.permission = [];

        record.permission = await permissionStore.getPermissionByRoleId(record);
        setMode2("edit");
        setExistingData2(record);
        setIsModal2Open(true);
    };

    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await permissionStore.getPermissionByRoleId(role);
            permissionStore.assignedPermission = permission;
        };

        store.getUsers();
        investigationStore.getInvestigations();
        roleStore.getAllRoles();
        fetchRolePermission();
    }, [investigationStore, store, roleStore, permissionStore]);

    const onResendEmail = (record) => {
        store.resendEmail(record);
    };

    const onDeleteRecord = (record) => {
        store.deleteUser(record);
    };

    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});

    const onEditRecord = (record) => {
        setMode("edit");
        setExistingData(record);
        setIsModalOpen(true);
    };

    const clearSearch = () => {
        store.searchQuery = "";
    };

    // Show resend mail when last_login_date === null && status==="approved"

    return (
        <div className="UserManagementPage">
            <UserFormModal
                investigations={investigationStore.investigations}
                roles={roleStore.roles}
                mode={mode}
                data={existingData || null}
                opened={isModalOpen}
                onSubmit={(data) => {
                    const { values, mode } = data;
                    if (mode === "edit") {
                        store.updateUser(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    } else {
                        store.createUser(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    }
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                }}
            ></UserFormModal>
            <UserPermissionFormModal
                userRoles={roleStore.roles}
                roles={roleStore.roles}
                mode={mode2}
                data={existingData2 || null}
                opened={isModal2Open}
                permissionList={toJS(permissionStore.permission)}
                onSubmit={async data => {
                    if (mode2 === "edit") {
                        roleStore.updateUseRole(data);
                        setIsModal2Open(false);
                        setExistingData2({});
                    } else {
                        roleStore.createUserRole(data);
                        setIsModal2Open(false);
                        setExistingData2({});
                    }
                    await roleStore.getAllRoles();
                    await permissionStore.getPermissions();
                    permissionStore.getPermissionByRoleIdTest(JSON.parse(localStorage.getItem("role")));
                }}
                onCancel={() => {
                    setIsModal2Open(false);
                    setExistingData2({});
                }}
            ></UserPermissionFormModal>
            <Layout
                showNavigation
                title="User Administration"
                description="Manage user access to the ATRAX system"
            >
                <div
                    style={{marginTop: "0"}}>
                    <Tabs tabPosition='top'>
                        <TabPane key="1" tab={<span>Users</span>}>
                            <div
                                style={contentBox({
                                    marginTop: "0",
                                    height: "100%",
                                })}
                            >
                                <div>
                                    <Button
                                        style={secondaryButton()}
                                        id={"adduserbtn"}
                                        disabled={permissionStore.can("user.create")}
                                        onClick={() => {
                                            setMode("add");
                                            setIsModalOpen(true);
                                        }}
                                    >
                                        <PlusOutlined></PlusOutlined>
                                        Add User
                                    </Button>
                                    <Button
                                        style={secondaryButton({
                                            float: "right",
                                            margin: "0 0 10px 5px",
                                        })}
                                        onClick={clearSearch}
                                    >
                                        Clear
                                    </Button>

                                    <Input
                                        id={"searchUserInput"}
                                        style={{ float: "right", width: "40%" }}
                                        placeholder="Search .."
                                        value={store.searchQuery}
                                        onChange={(event) => {
                                            const query = event.target.value;
                                            store.searchQuery = query;
                                        }}
                                    ></Input>
                                </div>
                                <div className="user-table">
                                    <UserTable
                                        canEdit={permissionStore.can("user.update")}
                                        canDelete={permissionStore.can("user.delete")}
                                        canResendEmail={permissionStore.can("user.resend-email")}
                                        onEditRecord={onEditRecord}
                                        onDelete={onDeleteRecord}
                                        onResendEmail={onResendEmail}
                                        data={!permissionStore.can("user.list") ? toJS(store.results) : []}
                                    ></UserTable>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane key="2" tab={<span>Roles</span>}>
                            <div
                                style={contentBox({marginTop: "0"})}
                            >
                                <Button
                                    style={secondaryButton()}
                                    id={"addUserRoleBtn"}
                                    disabled={permissionStore.can("role.create")}
                                    onClick={() => {
                                        setMode2("add");
                                        setIsModal2Open(true);
                                    }}
                                >
                                    <PlusOutlined></PlusOutlined>
                                    Add User Role
                                </Button>
                                <div className="user-table">
                                    <UserRoleTable
                                        canEdit={permissionStore.can("role.update")}
                                        canDelete={permissionStore.can("role.delete")}
                                        onEditRecord={onEditRecord2}
                                        onDelete={onDeleteRecord2}
                                        data={!permissionStore.can("role.list") ? toJS(roleStore.roles) : []}
                                    ></UserRoleTable>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Layout>
        </div>
    );
}

export default observer(UserManagementPage);
