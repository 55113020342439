// import { notification } from "antd";
import { message } from "antd";
import { observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

const configurationIDs = {
    2000: "basicTracking",
    4000: "accelerometerSettings",
    2400: "movementDetection",
    1000: "gpsSettings",
    2600: "recoveryModeSetup",
    4100: "manDown",
    2475: "geofenceMovementDetection",
    2075: "geofenceBasicTracking",
    2800: "scheduledUploads",
    2700: "timezoneAndAfterHours",
    1100: "advancedGPSSettings",
    2500: "uploadTimeouts",
    2450: "afterHoursMovementDetection",
    2050: "afterHoursBasicTracking",
    // OBD Bolt
    5000: "movementTrips",
    // 1100: 'heartbeat', // Duplicate?
    1400: "logging",
    1600: "uploadAdvanced",
};

export class DeviceConfigurationStore {
    constructor() {
        this.basicTracking = [];
        this.movementDetection = [];
        this.accelerometerSettings = [];
        this.gpsSettings = [];
        this.recoveryModeSetup = [];
        this.basicTrackingConfig = [];
        this.movementDetectionConfig = [];
        this.accelerometerConfig = [];
        this.gpsSettingConfig = [];
        this.recoveryModeSetupConfig = [];
        this.afterHoursMovementDetectionConfig = [];
        this.afterHoursBasicTrackingConfig = [];
        this.manDown = [];
        this.geofenceMovementDetection = [];
        this.geofenceBasicTracking = [];
        this.scheduledUploads = [];
        this.timezoneAndAfterHours = [];
        this.advancedGPSSettings = [];
        this.uploadTimeouts = [];
        this.afterHoursMovementDetection = [];
        this.afterHoursBasicTracking = [];
        this.movementTrips = [];
        this.logging = [];
        this.uploadAdvanced = [];
        this.huntsmanConfiguration = [];
        this.api = API;
        makeAutoObservable(this);
    }

    async getDeviceConfigurationSchema(vendor, device_type) {
        return await this.api.device.getDeviceConfigurationSchema(vendor, device_type);
    }

    async getAllConfigSettings(url_prefix, device_type) {
        return await this.api.device.getAllConfigSettings(url_prefix, device_type)
    }

    async sendDeviceSettingsToDevice(device, configID, configData, url_prefix = null) {
        for (var key in configData) {
            if (configData.hasOwnProperty(key)) {
                configData[key] = Number(configData[key]);
            }
        }

        const data = {
            config_id: configID,
            device_id: device.id,
            device_type: device.device_type,
            settings: { ...configData },
        };

        try {
            await this.api.device.sendDeviceSettingsToDevice(data, device.id);
            if(url_prefix && url_prefix === 'hunt'){
                await this.getAllConfigurationValues(device.id).then(response => {
                    this.huntsmanConfiguration = response;
                })
            }
            message.success(
                `Device settings successfully updated for ${device.model}-${device.serial} device.`
            );
        } catch (exception) {
            if (exception.response.status === 422) {
                const errorMessage = exception.response.data.message;
                message.error(`${errorMessage}`);
            } else {
                message.error(
                    `Device settings update failed for ${device.model}-${device.serial} device.`
                );
            }
        }

        try {
            const response = await this.api.device.getAllConfigurationValues(device.id);
            response.forEach((config) => {
                if (config.config_id === configID) {
                    this[configurationIDs[configID]] = config;
                }
            });
        } catch (exception) {}
    }

}

export default createContext(new DeviceConfigurationStore());
