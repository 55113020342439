import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const createTarget = async ({
    name,
    investigation_id,
    device_id,
    assign_at,
    colour
}) => {
    try {
        const response = await HTTP.post("/target", {
            name,
            investigation_id,
            device_id,
            assign_at,
            colour
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data =
            response.data.data.target[response.data.data.target.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateTarget = async ({
    name,
    assign_at,
    device_id,
    id,
    investigation_id,
    colour,
    warrant
}) => {
    try {
        const response = await HTTP.put(`/target/${id}`, {
            name,
            assign_at,
            device_id,
            investigation_id,
            colour,
            warrant,
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data =
            response.data.data.target[response.data.data.target.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteTarget = async id => {
    try {
        const response = await HTTP.delete(`/target/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllTargets = async () => {
    try {
        const response = await HTTP.get(`/target?`);
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.target;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllTargetsByInvestigationAndUserDetails = async () => {
    try {
        const response = await HTTP.get("/target/user/investigations");
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.target;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllTargetsByInvestigation = async investigationID => {
    if (!investigationID) {
        return Promise.reject("No investigation ID provided");
    }
    try {
        const response = await HTTP.get(`/target/investigation/${investigationID}`);
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.target;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const APcapture = async (targetID) => {
    try {
        const response = await HTTP.get(`/wifi/ap/`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.message.wifiAccessPoints;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const ClonedTripCheck = async (targetID, start, finish) => {
    try {
        //const offset = 60 * 30;
        const response = await HTTP.get(`/wifi/${targetID}?start=${start}&end=${finish}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.wifiDevices;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getClonedMacAddressTarget = async (targetID) => {
    try {
        //const offset = 60 * 30;
        const offset = 60 * 4;
        const start = Math.floor(Date.now() / 1000);
        const response = await HTTP.get(`/wifi/${targetID}?start=${start}&offset=${offset}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.wifiDevices;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getClonedMacAddressTargetNoTime = async (targetID) => {
    try {
        //const offset = 60 * 30;
        const endtime = Math.floor(Date.now() / 1000);
        const start = 1638400873;
        const response = await HTTP.get(`/wifi/${targetID}?start=${start}&end=${endtime}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.wifiDevices;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllAccessPointsByTarget = async (opts) => {
    if (typeof opts.target_id !== 'number') {
        return Promise.resolve([]);
    }
    try {
        const response = await HTTP.get(`/wifi/access_points?target_id=${opts.target_id}`);
        return Promise.resolve(response.data.data.wifiAccessPoints);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getClonedMacAddressAllTarget = async (targetID, keyword) => {
    try {
        const start = Math.floor(Date.now() / 1000);
        const endtime = 1638400873;
        const search = keyword || null;
        const response = await HTTP.get(`/wifi/ssid/${targetID}?start=${endtime}&end=${start}&search=${search}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.wifiData;
        // clonearray creation
        let seenssids = [];
        //building in hit count
        for (let i = 0; i < data.length; i += 1) {
            let index = seenssids.map(function (e) { return e.accessPointMAC }).indexOf(data[i].accessPointMAC);
            if (index == -1) {
                seenssids.push({
                    target: targetID,
                    ssid: data[i].ssid,
                    hitcount: 1,
                    accessPointMAC: data[i].accessPointMAC,
                    deviceMAC: data[i].deviceMAC,
                    DeviceLogID: data[i].DeviceLogID,
                    timestamp: data[i].timestamp,
                    latitude: data[i].latitude,
                    longitude: data[i].longitude,
                    nickname: data[i].nickname,
                    latlng: `${JSON.stringify(data[i].latitude)} , ${JSON.stringify(data[i].longitude)}`,
                });
            } else {
                seenssids[index].hitcount += 1;
            }
        }
        return Promise.resolve(seenssids);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getCapturedWifiDevices = async (opts) => {
    let url = `/wifi/investigation/${opts.id}/devices?`;

    let query = new URLSearchParams();
    if ('start' in opts) { query.append('start', opts.start); }
    if ('end' in opts) { query.append('end', opts.start); }
    let response = {};
    try {
        response = await HTTP.get(url.concat(query.toString()));
    } catch (exception) {
        return Promise.reject(exception);
    }
    if (response.data.code === 'RECORD_NOT_FOUND') {
        return Promise.resolve([]);
    }
    return Promise.resolve(response.data.message.wifiDevices);
};

const getInvestigationDashboard = async (id) => {
    try {
        let response = await HTTP.get(`/target/investigationDashboard/${id}`)

        const data = response.data.attribute

        return Promise.resolve(data);
    } catch (exception) {
        console.log(exception)
        return Promise.reject(exception);
    }
}

const detach = async (id) => {
    try {
        let response = await HTTP.post(`/target/detach/${id}`)
        return Promise.resolve(response.data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const uploadFile = async (file, target_id, investigation, device) => {
    try {
        const response = await HTTP.post(`/target/upload/${target_id}/${investigation}/${device}`, file);
        return Promise.resolve(response.data);
    } catch (exception) {
        return Promise.resolve(exception);
    }
};

const updateTargetState = async (target_id, state) => {
};

const getLiveCloneCaptures = async (target_id) => {
    try {
        const response = await HTTP.get(`target/huntsman/cloneCapturesLive/${target_id}`);
        return Promise.resolve(response.data.data.devices);
    } catch (exception) {
        return Promise.resolve(exception);
    }
};

export default {
    createTarget,
    updateTarget,
    deleteTarget,
    getAllTargets,
    getClonedMacAddressAllTarget,
    APcapture,
    getClonedMacAddressTargetNoTime,
    ClonedTripCheck,
    getAllTargetsByInvestigation,
    getAllTargetsByInvestigationAndUserDetails,
    getClonedMacAddressTarget,
    getCapturedWifiDevices,
    getAllAccessPointsByTarget,
    getInvestigationDashboard,
    detach,
    uploadFile,
    getLiveCloneCaptures,
};
