import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const getData = async (id, lastSeen, deviceType) => {
    try {
        const response = await HTTP.get(`/device/dashboard/${id}?lastSeen=${lastSeen}&deviceType=${deviceType}`)

        const data = response.data.attribute;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

export default {
    getData
}