import '@ant-design/compatible/assets/index.css';
import {Button, Form} from "antd";
import React from "react";
import {useForm} from "antd/es/form/Form";
import {Switch} from "antd/es";

function ProfileNotificationsForm({initialValues, fields, onSubmit}) {
    console.log('initialValues', initialValues)
    const [form] = useForm();

    const onFormFinish = (values) => {
        // todo handle form finish
        console.log('values', values)
        onSubmit(values);
    };

    const onFormFinishFailed = (errorInfo) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };

    return (
        <div>

            <Form
                form={form}
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                layout="horizontal"
                initialValues={initialValues}
                onFinish={onFormFinish}
                onFinishFailed={onFormFinishFailed}
            >
                <Form.Item label="Sms" name="sms" valuePropName="checked">
                    <Switch/>
                </Form.Item>
                <Form.Item label="Email" name="email" valuePropName="checked">
                    <Switch/>
                </Form.Item>
                <Form.Item label="Web" name="web" valuePropName="checked">
                    <Switch/>
                </Form.Item>
                <Form.Item style={{flex: 'auto', justifyContent: 'center'}}>
                    <Button type="primary" htmlType="submit" style={{marginRight: '5%', width: '40%'}}>
                        Submit
                    </Button>
                    <Button htmlType="button" onClick={onFormClearClick} style={{width: '40%'}}>
                        Clear
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
export default(ProfileNotificationsForm);
