import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { mapDefault } from "../../util/CommonUtils";
// import { google } from "travel-marker/dist/types/models";
import "./index.css";

const DeviceMap = ((
    {
        deviceCords,
        startCamera,
        speed,
        heading,
        pocket,
        board,
        currentlySelectedDevice,
        devices,
    }
) => {
    const [map, setMap] = useState(null);
    let point = {}
    try {
        point = { lat: startCamera.position.coords.latitude, lng: startCamera.position.coords.longitude };
    } catch (e) {
        if (deviceCords.length === 1) {
            point = deviceCords[0];
        } else {
            point = { lat: -27.469287, lng: 153.094482 }
        }
    }
    useEffect(() => {
        if (!pocket) {
            const points = deviceCords.map((point) => ({
                ...point,
                lng: point.lng,
                lat: point.lat,
            }));
            const bounds = new window.google.maps.LatLngBounds();
            //   console.log(deviceCords)
            points.forEach((point) => {
                bounds.extend(point);
            });
            if (map) {
                map.fitBounds(bounds);
            }
        }
    }, [deviceCords, speed, heading, startCamera, map])

    useEffect(() => {
        if (currentlySelectedDevice) {
            const bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < deviceCords.length; i++) {
                if (deviceCords[i].id === currentlySelectedDevice.id) {
                    bounds.extend(deviceCords[i])
                }
            }

            map.fitBounds(bounds)
        }
    }, [currentlySelectedDevice])

    // let bounds = new window.google.maps.LatLngBounds();

    const marker = () => {
        for (let i = 0; i < speed.length; i++) {
            if (!board) {
                if (speed[i] < 1) {
                    markerType.push({
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: "#71FC27",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "#01290D",
                        rotation: heading[i],
                        scale: 5,
                        anchor: new window.google.maps.Point(0, 0)
                    });
                } else {
                    markerType.push({
                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        fillColor: "#71FC27",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "#01290D",
                        rotation: heading[i],
                        scale: 4,
                        anchor: new window.google.maps.Point(0, 3)
                    });
                }
            } else {
                if (speed[i] < 1) {
                    markerType.push({
                        path: window.google.maps.SymbolPath.CIRCLE,
                        fillColor: "red",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "maroon",
                        rotation: heading[i],
                        scale: 5,
                        anchor: new window.google.maps.Point(0, 0)
                    });
                } else {
                    markerType.push({
                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        fillColor: "red",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "maroon",
                        rotation: heading[i],
                        scale: 4,
                        anchor: new window.google.maps.Point(0, 3)
                    });
                }
            }
        }
    }
    let markerType = [];

    return (
        <GoogleMap
            // defaultZoom={15}
            // center={{lng: 153.094482, lat: -27.469287}}
            center={point}
            // defaultCenter={mapDefault()}
            yesIWantToUseGoogleMapApiInternals

            onIdle={() => {

                if (map && map.getCenter()) {
                    localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                }
            }}
            onLoad={(map) => {setMap(map)}}

            mapContainerStyle={{
                height: pocket ? "100%": "74vh",
                width: "100%",
                borderRadius: '8px',
                overflow: 'hidden'
            }}
            zoom={15}
        >

            {marker()}

            {deviceCords.map((cords, index) => {
                let dindex = null;
                if (board) {
                    for (let i = 0; i < devices.length; i++) {
                        if (devices[i].id === cords.id) {
                            dindex = i;
                        }
                    }
                }
                if (dindex === null && cords && cords.id !== undefined) {
                    return null
                }
                if (cords === undefined) {
                    return null
                }
                return (
                    <Marker
                        icon={markerType[index]}
                        position={cords}
                        key={index}
                    >
                        {
                            (board)
                                ?
                                <InfoWindow
                                    position={cords}
                                    key={index}
                                >
                                    <p>{devices[dindex].serial + " " + devices[dindex].target_name}</p>
                                </InfoWindow>
                                :
                                []
                        }
                    </Marker>
                )
            })}

        </GoogleMap>
    )
});

export default DeviceMap;