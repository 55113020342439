import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Result, Spin, Collapse, message } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import DeviceDetails from "../../components/DeviceDetails";
import LivePanel from "../../components/LivePanel";
import LiveStore from "../../stores/live.store";
import DeviceDetailsCloneCapture from '../../components/DeviceDetailsCloneCapture';
import getPublicURL from "../../utilities/public-url.utility";
import Layout from "../AppLayout";
import LivePinPanel from "../../components/LivePinPanel";
import LivePinStore from "../../stores/live.pin.store";
import GeofenceStore from "../../stores/geofence.store";
import LivePinMarker from "../../components/LivePinMarker";
import LivePinAddForm from "../../components/LivePinAddForm";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import TargetStore from '../../stores/target.store';
import NickNameForm from "../../components/DeviceEditForm";
import EntityAddForm from "../../components/EntityAddForm";
import API from "../../services/api";
import investigationStore from '../../stores/investigation.store';
import { primaryButton, secondaryButton } from "../../styles";
import LiveMapBEN from '../../components/LiveMap';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { browserName, browserVersion } from "react-device-detect";
import GeofenceAdditionalWindow from '../../components/GeofenceAdditionalWindow';
import userStore from '../../stores/user.store';

function LivePage(props) {
    const store = useContext(LiveStore);
    const livePinStore = useContext(LivePinStore);
    const Targetstore = useContext(TargetStore);
    const geofenceStore = useContext(GeofenceStore);
    const UserStore = useContext(userStore);
    const [currentZoom, setCurrentZoom] = useState(15);
    const [currentQueryLocation, setCurrentQueryLocation] = useState({});
    const [isDraggable, setIsDraggable] = useState(true);
    const [
        {
            investigationsForUser,
        }
    ] = useInvestigationForUser();

    const [selectedPanelInvestigation, setSelectedPanelInvestigation] = useState("");
    const [speedUnitString, setSpeedUnitString] = useState("");
    //for clicking
    const [wifiCapture, setWifiCapture] = useState([]);
    //for hovering
    const [hover, setHover] = useState([]);
    const [grab, setGrab] = useState(true)
    function hoverPush(record, out) {
        let listedPoints = hover;
        if (listedPoints.length > 0) {

            if (out) {
                setHover(hover.filter(item => item.target_id !== record.target_id));
            } else {
                setHover(prevAP => ([...prevAP, record]));
            }

        } else {
            setHover([record]);
        }
    }

    // this is only make api call once and stop spinning

    if (grab) {
        livePinStore.getLivePins();
        store.getLiveTargets();

        setGrab(false)
    }

    // moust clicked
    // target id is unique and we can base list off of
    function wifiCapturePush(record) {
        if (wifiCapture.length > 0) {
            let index = wifiCapture.findIndex(element => (element.target_id = record.target_id));
            if (index >= 0) {
                setWifiCapture(wifiCapture.filter(item => item.target_id !== record.target_id));
            } else {
                setWifiCapture(prevAP => ([...prevAP, record]));
            }
        } else {
            setWifiCapture([record]);
        }
    }

    // entity management stuff -------------------------------------------
    const [mode, setMode] = useState("add");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [existingData, setExistingData] = useState({});
    const [update, setupdate] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [mode1, setMode1] = useState("add");
    const [entityList, setEntityList] = useState([]);
    const [existingData1, setExistingData1] = useState({});
    const investigationstore = useContext(investigationStore);
    const [selectedInvestigation, setSelectedInvestigation] = useState();
    const [pinTrigger, setPinTrigger] = useState(false);
    const [newColour, setNewColour] = useState("#ff0000");
    const [newName, setNewName] = useState("");

    const [visible, setVisible] = useState(true);
    const [margin, setMargin] = useState('22%');
    const [buttonOpacity, setButtonOpacity] = useState('1.0');
    const [buttonMargin, setButtonMargin] = useState('22%')
    const [lock, setLock] = useState(false);
    const [geofenceActive, setGeofenceActive] = useState(false);
    const [geofenceDetails, setGeofenceDetails] = useState({})
    const [users, setUsers] = useState([])
    const [lockid, setLockid] = useState(null);
    const [showStopsMap, setShowStopsMap] = useState(false);

    //geofence usestates
    const [geoFenceOpen, setGeoFenceOpen] = useState(false);
    const [geofences, setGeofences] = useState([]);
    const [deletedFence, setDeletedFence] = useState(false);
    const [alwaysActive, setAlwaysActive] = useState(true);
    const [activeShape, setActiveShape] = useState(null);
    const [rectangleBounds, setRectangleBounds] = useState(null);
    const [center, setCenter] = useState(null);
    const [radius, setRadius] = useState(null);
    const [type, setType] = useState(null);
    const [cellTowerData, setCellTowerData] = useState(null);

    const { Panel } = Collapse

    useEffect(() => {

        setSpeedUnitString
        investigationStore.getInvestigationsForUser;
        UserStore.getUsers();

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {

        store.pointsUpdate();

        if (lock) {
            if (lockid) {

                const targets = toJS(store.liveTargets)
                const x = targets.findIndex(item => item.device_id === lockid)


                setCurrentQueryLocation({ lat: targets[x].latitude, lng: targets[x].longitude })
            }
        }
    }, [store.liveTargets]);

    useEffect(() => {
        console.log(geofences)
    }, [geofences])

    // this is for Drawer
    const showDrawer = () => {
        setButtonOpacity('1.0');
        setButtonMargin('22%');
        setVisible(true);
        setMargin("22%");

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin('0px');
        setGeoFenceOpen(false);
    };

    const setHoverBTN = () => {
        setButtonOpacity('0.7');
        // setButtonMargin('-5px');
    }

    const unSetHover = () => {
        setButtonOpacity('1.0');
        // setButtonMargin('-3px');
    }

    const entityEdit = (record, device) => {
        setMode("edit");

        let reformat = {};
        API.entity.GetAllEntities(record.investigation_id).then((res) => {
            reformat.type = device.type;
            reformat.alias = device.alias;
            reformat.id = device.id;
            reformat.mac = device.wifi_device_id;

            if (device === "device") {
                reformat.accessPoint = {
                    id: device.apID,
                    latlng: {
                        lat: device.latitude_adjust || device.latitude,
                        lng: device.longitude_adjust || device.longitude,
                    },
                    mac: device.apMAC.toString(16),
                    metadata: device.apDATA,
                };
            }

            const geocoder = new window.google.maps.Geocoder();
            geocoder
                .geocode({ location: reformat.accessPointLatLng })
                .then((response) => {

                    reformat.address = response.results[0].formatted_address;
                    reformat.metadata = device.metadata;
                    reformat.entity_id = device.entity_id;
                    reformat.ssid = JSON.parse(device.apDATA)?.ssid;

                    setEntityList(res);
                    setExistingData(reformat);
                    setIsModalOpen(true);
                })
                .catch(() => {
                    reformat.address = "Geocoder lookup failed";
                    reformat.metadata = record.entity.content.metadata;
                    reformat.entity_id = record.entity.content.entity_id;
                })
        })
    }

    async function entityCreate(values) {
        await API.entity.createEntity(values.InvestigationID, values.nickname, values.notes).then((res) => {
            if (res.status === 200) {
            }
        })
    }

    const loadview = (record) => {
        const x = geofences.filter((item) => item.id === record.id)

        if (x.length === 0) {
            setGeofences([...geofences, record])
        } else {
            setGeofences(geofences.filter((item) => item.id !== record.id))
        }

        if (JSON.parse(record.fence).type === "Polygon") {
            message.error(`Display of legacy geofence: "${record.name}" not supported`)
            message.info("Please redraw the geofence to view it on the map")
        }
    }

    const clearFence = (record) => {
        setDeletedFence(record)
        setGeofences(geofences.filter((item) => item.id !== record.id))
    }

    const createEntity = () => {

        setMode1("add");
        setExistingData1();
        setIsModalOpen1(true);
    }

    const upDateEntlist = () => {
        API.entity.GetAllEntities(selectedInvestigation).then((res) => {
            setEntityList(res);
        })
    }

    const onPlacesChanged = (places) => {


        if (places) {
            const place = places.length > 0 && places.length <= 1 ? places[0] : null;
            if (place) {

                setCurrentQueryLocation({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                });

                setLock(false)
            }
        }
    };

    const addLivePin = (name, type, lat, lng) => {
        livePinStore.addLivePin(name, type, lat, lng);


        // livePinStore.livePins.push()
        livePinStore.getLivePins()
    };

    const onRemovePin = (id) => {
        livePinStore.removeLivePin(id);
    };

    const geofenceSpawn = async (target) => {


        await API.investigationDashboard.getUsers(target.investigation_id).then((result) => {

            setUsers(result)
        })

        setGeofenceActive(!geofenceActive)
        setGeofenceDetails(target)
    }

    const setCellTowerDisplay = (target) => {
        if ((cellTowerData === null) || ( cellTowerData.device_id !== target.device_id)){
            setCellTowerData(target);
            return true;
        }
        setCellTowerData(null);
        return false;
    }

    let markersVisibleOnMap = store.liveTargets;
    let targetsByInvestigation = store.liveTargetsByInvestigation;
    let livePinsVisibleOnMap = livePinStore.livePins;
    return (
        <Layout showNavigation hideMargins>
            {showStopsMap === false && (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                    }}
                >
                    <Spin></Spin>
                </div>
            )}

            {showStopsMap === true && (
                <div>
                    <div
                        className="devicePanel"
                        style={{
                            position: "fixed",
                            zIndex: 2,
                            width: "100%"
                        }}
                    >
                        < Button type="primary" onClick={visible ? onClose : showDrawer}
                            style={primaryButton({
                                position: "absolute",
                                top: "40vh",
                                zIndex: 2,
                                height: "15vh",
                                marginTop: "0%",
                                marginLeft: "2px",
                                left: buttonMargin,
                                borderLeftColor: "#0066CC",
                                marginBottom: "2px",
                                opacity: buttonOpacity,
                            })}
                            onMouseEnter={() => { setHoverBTN() }}
                            onMouseLeave={() => { unSetHover() }}
                        >
                            {
                                visible
                                    ?
                                    <ArrowLeftOutlined
                                        width={25}
                                        height={25}
                                    />
                                    :
                                    <ArrowRightOutlined
                                        width={25}
                                        height={25}
                                    />
                            }
                        </Button>
                        <LivePanel
                            setCellTowerData={(data) => {setCellTowerDisplay(data)}}
                            onPanelChange={(data) => {
                                setSelectedPanelInvestigation(data);
                                store.sidebarOpenTabs = data;
                            }}
                            openPanels={toJS(store.sidebarOpenTabs)}
                            records={toJS(targetsByInvestigation)}
                            currentlyBoundedTargets={toJS(store.boundMarkers)}
                            currentlyHiddenTargets={toJS(store.hiddenMarkers)}
                            infoWindowSelectedID={
                                store.sidepanelSelectedTarget &&
                                store.sidepanelSelectedTarget.id
                            }
                            selectedTargetID={
                                store.selectedTarget && store.selectedTarget.id
                            }
                            selectedTargets={toJS(store.sidebarSelectedTargets)}
                            PreselectedTargets={toJS(store.sidebarSelectedTargetsPRECONF)}
                            onTargetSelect={(target) => {
                                store.setSelectedTarget(target);
                                livePinStore.selectedLivePin = null;
                                geofenceStore.setActiveGeofence(null);

                                setCurrentQueryLocation({ lat: target.latitude, lng: target.longitude })
                            }}
                            onClose={onClose}
                            visible={visible}
                            onTargetDetails={(target) => {
                                store.setSidebarSelected(target);
                            }}
                            setPreConf={(target) => {
                                store.setSidebarSelectedPRECONF(target);
                            }}
                            onTargetShowHideToggle={(target, force = null) => {
                                store.toggleVisibility(target, force);
                            }}
                            onTargetLock={(target) => {


                                store.setBoundMarkers(target)
                            }}
                            onClickShowPoints={async (target) => {
                                await store.setPointsForATarget(target);
                            }}
                            TargetsClickedForPoints={store.targetsClickedForPoints}
                            speedUnitString={speedUnitString}
                            geofenceSpawn={geofenceSpawn}
                            setGeoFenceOpen={setGeoFenceOpen}
                        />

                        <GeofenceAdditionalWindow
                            opened={geoFenceOpen}
                            investigations={toJS(
                                investigationstore.investigationsForUser)}
                            onCancel={() => {
                                setGeofences([])
                                setActiveShape(null);
                                setType(null);
                                setGeoFenceOpen(false);
                            }}
                            setActiveShape={setActiveShape}
                            setType={setType}
                            margin={"25%"}
                            loadview={loadview}
                            clearFence={clearFence}
                            alwaysActive={alwaysActive}
                            setAlwaysActive={setAlwaysActive}
                            setRadius={setRadius}
                            setCenter={setCenter}
                            setRectangleBounds={setRectangleBounds}
                            type={type}
                            onSubmit={(e) => {
                                let fence = {}
                                if (activeShape) {
                                    if (activeShape === "rectangle") {
                                        fence = {
                                            type: "rectangle",
                                            coordinates: [[
                                                [rectangleBounds.north, rectangleBounds.west],
                                                [rectangleBounds.north, rectangleBounds.east],
                                                [rectangleBounds.south, rectangleBounds.east],
                                                [rectangleBounds.south, rectangleBounds.west],
                                                [rectangleBounds.north, rectangleBounds.west]
                                            ]]
                                        }
                                    }
                                    else if (activeShape === "circle") {
                                        fence = {
                                            type: "circle",
                                            coordinates: [[center, radius]]
                                        }
                                    }
                                }
                                let from = e.activeDurationFrom | undefined;
                                let to = e.activeDurationTo | undefined;

                                let days;

                                try {
                                    days = e.activeDays.map(String)
                                } catch (error) {
                                    days = []
                                }
                                if (!existingData.id) {
                                    API.geofence.createGeofence({
                                        name: e.name,
                                        investigation_id: selectedInvestigation,
                                        fence: fence,
                                        _days: [...new Set(days)],
                                        _from: from,
                                        _to: to,
                                        user_id: e.users,
                                        target_id: e.target.length ? e.target : [e.target],
                                        timezone: e.timezone,
                                        trigger_type: e.trigger,
                                        apply_geofence_datetime: alwaysActive ? 0 : 1,
                                    }).then((res) => {
                                        if (res.status === 200) {
                                            message.success("Geofence created successfully");
                                        }
                                    }).catch((err) => {
                                        message.error("Error creating geofence");
                                    })
                                } else {
                                    API.geofence.updateGeofence({
                                        id: existingData.id,
                                        name: e.name,
                                        investigation_id: selectedInvestigation,
                                        fence: fence,
                                        _days: [...new Set(days)],
                                        _from: from,
                                        _to: to,
                                        user_id: e.users,
                                        target_id: e.target.length ? e.target : [e.target],
                                        timezone: e.timezone,
                                        trigger_type: e.trigger,
                                        apply_geofence_datetime: alwaysActive ? 0 : 1,
                                    }).then(() => {
                                        message.success("Geofence updated successfully");
                                    }).catch((err) => {
                                        message.error("Error updating geofence");
                                    })
                                }
                                setType(null);
                                setActiveShape(null);
                                setAlwaysActive(true);
                                setExistingData({});
                                // store.getAllGeofences();
                            }}
                            shapeSelect={(e) => {
                                console.log(e)
                                setType(e);
                            }}
                            loadedit={(e) => {
                                setGeofences([]);
                                setSelectedInvestigation(e.investigation[0]);
                                setExistingData(e);
                                setType(JSON.parse(e.fence).type);
                                setActiveShape(JSON.parse(e.fence).type);
                                if (JSON.parse(e.fence).type === 'circle') {
                                    setRadius(JSON.parse(e.fence).coordinates[0][1]);
                                    setCenter(JSON.parse(e.fence).coordinates[0][0]);
                                } else if (JSON.parse(e.fence).type === 'rectangle') {
                                    setRectangleBounds({
                                        north: JSON.parse(e.fence).coordinates[0][0][0],
                                        south: JSON.parse(e.fence).coordinates[0][2][0],
                                        east: JSON.parse(e.fence).coordinates[0][1][1],
                                        west: JSON.parse(e.fence).coordinates[0][0][1]
                                    });
                                }
                            }}
                        />


                    </div>


                    <NickNameForm
                        entityadd={createEntity}
                        opened={isModalOpen}
                        mode={mode}
                        targets={entityList}
                        device={existingData || null}
                        onSubmit={(data) => {
                            const { values, mode } = data;
                            if (mode === "edit") {
                                API.wifiDevice.update(values.id, values.nickname, values.name)
                                setIsModalOpen(false);
                                setExistingData({});
                                setupdate(true);

                            }
                        }}
                        onCancel={() => {
                            setIsModalOpen(false);
                            setExistingData({});
                        }}
                    ></NickNameForm>
                    <EntityAddForm
                        investigations={toJS(
                            investigationstore.investigationsForUser)}
                        opened={isModalOpen1}
                        mode={mode1}
                        passthrough={[]}
                        data={existingData1 || null}
                        onSubmit={(data) => {
                            const { values, mode } = data;
                            upDateEntlist();
                            entityCreate(values);
                            if (mode === "add") {
                                setIsModalOpen1(false);
                                setExistingData1({});

                            }
                        }}
                        onCancel={() => {
                            setIsModalOpen1(false);
                            setExistingData1({});
                        }}
                    ></EntityAddForm>
                    <div

                        style={{
                            position: "fixed",
                            zIndex: 2,
                            backgroundColor: "white",
                            marginTop: "70px",
                            // marginLeft: "6px",
                            borderRadius: "5px",
                            marginRight: "0px",
                            alignItems: 'flex-end',
                            alignSelf: "flex-end",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            right: 0,
                        }}
                    >
                        <LivePinPanel
                            onPlacesChanged={onPlacesChanged}
                            livePins={livePinStore.livePinsByInvestigation}
                            currentlyHiddenPins={toJS(livePinStore.hiddenPins)}
                            onLivePinShowHideToggle={(pin) => {
                                livePinStore.toggleVisibility(pin);
                            }}
                            onLivePinSelect={(pin) => {
                                livePinStore.setSelectedLivePin(pin);
                                setCurrentQueryLocation({
                                    lat: Number(pin.latitude),
                                    lng: Number(pin.longitude),
                                });
                                setLock(false)
                                store.selectedTarget = null;
                            }}
                            onRemovePin={(id) => onRemovePin(id)}
                            onSelectInvestigation={(item) => {
                                livePinStore.setSelectedInvestigation(
                                    item,
                                    currentQueryLocation
                                );



                                setIsDraggable(true);
                                setPinTrigger(true);
                                setNewColour(item.colour)
                                setNewName(item.name)
                                setCurrentQueryLocation(currentQueryLocation)
                            }}
                        />
                    </div>
                    <div style={{ marginLeft: margin }}>

                        {toJS(store.liveTargets) && toJS(livePinsVisibleOnMap) && (
                            <LiveMapBEN
                                cellTower={cellTowerData}
                                containerElement={<div style={{ height: '100vh' }} />}
                                mapElement={<div style={{ height: '100vh' }} />}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                                cloneCapturePoint={toJS(store.cloneCaptureMapPoint)}
                                targetsClickedForPoints={toJS(store.pointsForRespectiveTargets)}
                                markersVisibleOnMap={store.liveTargets}
                                hiddenMarkers={toJS(store.hiddenMarkers)}
                                entityEdit={entityEdit}
                                hoverPush={hoverPush}
                                hover={hover}
                                wifiCapturePush={wifiCapturePush}
                                wifiCapture={wifiCapture}
                                currentZoom={currentZoom}
                                investigationsForUser={investigationsForUser}
                                speedUnitString={speedUnitString}
                                livePinsVisibleOnMap={toJS(livePinsVisibleOnMap)}
                                hiddenPins={toJS(livePinStore.hiddenPins)}
                                currentQueryLocation={currentQueryLocation}
                                pinTrigger={pinTrigger}
                                setPinTrigger={setPinTrigger}
                                placedMarker={newColour}
                                addLivePin={addLivePin}
                                newName={newName}
                                lock={lock}
                                geofenceActive={geofenceActive}
                                geofenceDetails={geofenceDetails}
                                users={users}
                                setGeofenceActive={setGeofenceActive}
                                lockid={lockid}
                                lockedTargets={toJS(store.boundMarkers)}
                                geofences={geofences}
                                setActiveShape={setActiveShape}
                                activeShape={activeShape}
                                type={type}
                                rectangleBounds={rectangleBounds}
                                setRectangleBounds={setRectangleBounds}
                                center={center}
                                setCenter={setCenter}
                                radius={radius}
                                setRadius={setRadius}
                            >

                            </LiveMapBEN>
                        )}
                    </div>
                </div>
            )
            }
        </Layout >
    );
}

export default observer(LivePage);
