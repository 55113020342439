import { Button, Row, Table, Tag } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import Layout from "../AppLayout";
import "./SOSHistoryPage.scss";
import SOSStore from "../../stores/sos.store";
import { formatDate } from "../../util/CommonUtils";
import { contentBox, primaryButton, secondaryButton } from '../../styles';

function SOSHistoryPage() {

    const sosStore = useContext(SOSStore);

    useEffect(() => {
        sosStore.getUSerSOS();
        // eslint-disable-next-line
    }, [sosStore])

    /*   const clearSearch = () => {
           //store.searchQuery = "";
       };*/

    const onCancelSOS = async (sosObj) => {
        sosStore.cancelSOS(sosObj.key)
    }


    const columns = [
        {
            title: 'Initiated Time',
            dataIndex: 'initiatedTime',
            key: 'initiatedTime',
            width: '70'
        },
        {
            title: 'Initiated User',
            dataIndex: 'initiator',
            key: 'initiator',
            width: '40'
        },
        {
            title: 'Initiators Latitude',
            dataIndex: 'initiatorsLat',
            key: 'initiatorsLat',
            width: '90'
        },
        {
            title: 'Initiators Longitude',
            dataIndex: 'initiatorsLng',
            key: 'initiatorsLng',
            width: '70'
        },
        {
            title: 'Initiators Address',
            dataIndex: 'initiatorsAddress',
            key: 'initiatorsAddress',
            width: '70'
        },
        /*{
            title: 'Investigation',
            dataIndex: 'investigation',
            key: 'investigation',
            width: '70'
        },*/
        {
            title: 'Accepted Time',
            dataIndex: 'acceptedTime',
            key: 'acceptedTime',
            width: '70'
        },
        {
            title: 'Accepted User',
            dataIndex: 'acceptor',
            key: 'acceptor',
            width: '40'
        },
        {
            title: 'Acceptors Latitude',
            dataIndex: 'acceptorLat',
            key: 'acceptorLat',
            width: '70'
        },
        {
            title: 'Acceptors Longitude',
            dataIndex: 'acceptorLng',
            key: 'acceptorLng',
            width: '70'
        },
        {
            title: 'Acceptors Address',
            dataIndex: 'acceptorsAddress',
            key: 'acceptorsAddress',
            width: '70'
        },
        {
            title: 'Canceled By',
            dataIndex: 'canceledBy',
            key: 'canceledBy',
            width: '40'
        },
        {
            title: 'Canceled Time',
            dataIndex: 'canceledTime',
            key: 'canceledTime',
            width: '60'
        },
        {
            title: 'TTL (Seconds)',
            dataIndex: 'ttl',
            key: 'ttl',
            width: '60'
        },
        {
            title: 'Cancellation TTL (Minutes)',
            dataIndex: 'canceled_ttl',
            key: 'canceled_ttl',
            width: '40'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '60',
            render: (text, record) => {
                if (record.status === 'pending') {
                    return (<Tag color="orange">Pending</Tag>)
                } else if (record.status === 'canceled' || record.status === 'system_canceled') {
                    return (<Tag color="red">Canceled</Tag>)
                } else if (record.status === 'accepted') {
                    return (<Tag color="green">Accepted</Tag>)
                } else if (record.status === 'timeout') {
                    return (<Tag color="purple">Timeout</Tag>)
                }
            }
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                if (record.status === 'pending' || record.status === 'accepted') {
                    return (
                        <Button
                            id={record.key}
                            onClick={() => {
                                onCancelSOS(record)
                            }}>Cancel</Button>
                    )
                }
            }
        },
    ];

    const dataSource = [];

    if (sosStore.usersSOS && sosStore.usersSOS.length > 0) {
        sosStore.usersSOS.forEach((item) => {
            dataSource.push({
                key: item.id,
                initiatedTime: formatDate(item.initiatedAt, 'hh:mm:ss A'),
                initiator: item.initiator_alias,
                acceptedTime: item.acceptedAt ? formatDate(item.acceptedAt, 'hh:mm:ss A') : 'N/A',
                acceptor: item.acceptor_alias ? item.acceptor_alias : 'N/A',
                canceledTime: item.status === 'system_canceled' ? formatDate((item.acceptedAt + (item.canceled_ttl * 60)), 'hh:mm:ss A') : (item.canceledAt ? formatDate(item.canceledAt, 'hh:mm:ss A') : 'N/A'),
                canceledBy: item.status === 'system_canceled' ? 'System' : (item.canceledBy ? item.canceledBy : 'N/A'),
                status: item.status,
                initiatorsLat: item.initiatorsLatitude,
                initiatorsLng: item.initiatorsLongitude,
                acceptorLat: item.acceptorsLatitude ? item.acceptorsLatitude : 'N/A',
                acceptorLng: item.acceptorsLongitude ? item.acceptorsLongitude : 'N/A',
                initiatorsAddress: item.initiatorsAddress ? item.initiatorsAddress : 'N/A',
                acceptorsAddress: item.acceptorsAddress ? item.acceptorsAddress : 'N/A',
                ttl: item.ttl,
                canceled_ttl: item.canceled_ttl
            })
        });
    }

    return (
        <div className="SOSHistoryPage">

            <Layout
                showNavigation
                title="Duress Functionality Administration"
                description="Manage SOS to the ATRAX system"
                extra={[]}
            >
                <Row>
                    {/*<Col span={8}></Col>
                    <Col span={8} offset={8}>
                        <Button
                            style={{
                                float: "right",
                                margin: "0 0 10px 5px",
                            }}
                            onClick={clearSearch}
                        >
                            Clear
                        </Button>

                        <Input
                            style={{float: "right", width: "40%"}}
                            placeholder="Search .."
                            //value={store.searchQuery}
                            onChange={(event) => {

                            }}
                        />
                    </Col>*/}
                </Row>
                <div className="sos-table">
                    <Table
                        style={contentBox()}
                        dataSource={dataSource}
                        columns={columns}
                        scroll={{ x: 1750 }} />
                </div>
            </Layout>
        </div>
    );
}

export default observer(SOSHistoryPage);
