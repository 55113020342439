import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const getAllTemplates = async () => {
    try {
        const response = await HTTP.get(`/template`);

        const data = response.data.data.template;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllFields = async () => {
    try {
        const response = await HTTP.get(`/template/table-field`);

        const data = response.data.data.templateTableField;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const createTemplate = async ({ name, description, field_id }) => {
    try {
        const response = await HTTP.post("/template", {
            name,
            description,
            field_id
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
      response.data.data.template[response.data.data.template.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateTemplate = async ({ name, description, field_id, id }) => {
    try {
        const response = await HTTP.put(`/template/${id}`, {
            name,
            description,
            field_id
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
      response.data.data.template[response.data.data.template.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteTemplate = async id => {
    try {
        const response = await HTTP.delete(`/template/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getAllTemplates,
    getAllFields,
    createTemplate,
    updateTemplate,
    deleteTemplate
};
