import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const viewAllRoles = async () => {
    try {
        const response = await HTTP.get("/user-role");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.userRole;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const createUserRole = async ({ name, description }) => {
    try {
        const response = await HTTP.post("/user-role", {
            name,
            description
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
      response.data.data.userRole[response.data.data.userRole.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateUseRole = async ({ name, description, id }) => {
    try {
        const response = await HTTP.put(`/user-role/${id}`, {
            name,
            description
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
      response.data.data.userRole[response.data.data.userRole.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteRole = async id => {
    try {
        const response = await HTTP.delete(`/user-role/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const mapRoleAndPermission = async ({ role_id, permission }) => {
    try {
        const response = await HTTP.post("/permission", {
            role_id,
            permission
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
      response.data.data.permission_mapping[
          response.data.data.permission_mapping.length - 1
      ];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getPermission = async () => {
    try {
        const response = await HTTP.get("/permission");
        const data = response.data.data.permission;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getPermissionByRoleId = async id => {
    try {
        const response = await HTTP.get(`permission/role/${id}`);
        const data = response.data.data.permission;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    viewAllRoles,
    getPermission,
    getPermissionByRoleId,
    createUserRole,
    updateUseRole,
    deleteRole,
    mapRoleAndPermission
};
