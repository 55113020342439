import { message } from "antd";
import { Parser } from "json2csv";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";
import FuzzySearch from "fuzzy-search";

var fileDownload = require("js-file-download");
const prettyMS = require("pretty-ms");

export class AnalysisStore {

    // dashboardTrips = [];
    // dashboardStops = [];

    // currentSelectLocationPointAnalysisStops = "";
    // currentSelectLocationPointAnalysisTrips = "";

    constructor() {
        this.exportList = [];
        this.trips = [];
        this.stops = [];
        this.searchQuery = "";
        this.api = API;
        this.parser = new Parser();
        makeAutoObservable(this);
    }

    async getTrips({ investigation_id, target_id, from, to }) {
        const response = await this.api.analysis.getTrips({
            investigation_id,
            target_id,
            from,
            to,
        });
        this.trips = response;
    }

    async getStops({ investigation_id, target_id, from, to }) {
        let response = await this.api.analysis.getStops({
            investigation_id,
            target_id,
            from,
            to,
        });
        this.stops = response;
    }

    async getDashboardTrips({ investigation_id, target_id, from, to }) {
        const response = await this.api.analysis.getTrips({
            investigation_id,
            target_id,
            from,
            to,
        });
        this.dashboardTrips = response;
    }

    async getDashboardStops({ investigation_id, target_id, from, to }) {
        let response = await this.api.analysis.getStops({
            investigation_id,
            target_id,
            from,
            to,
        });
        this.dashboardStops = response;
    }

    async createExport(options) {
        try {
            const id = await this.api.analysis.createExport(options);
            this.downloadDump(id);
            message.success(`Export has been created.`);

            this.getExportList();
        } catch (exception) {
            message.error(`We couldn't find any data for the specified date range.`);
        }
    }

    async getExportList() {
        const response = await this.api.analysis.getReports();
        this.exportList = response;
    }

    get exportResults() {
        if (this.searchQuery) {
            const searcher = new FuzzySearch(
                this.exportList,
                ["name", "description"],
                {
                    caseSensitive: false
                }
            );
            return searcher.search(this.searchQuery.trim());
        } else {
            return this.exportList;
        }
    }

    async deleteExport(id) {
        try {
            await this.api.analysis.deleteReport(id);
            message.success(`Export has been deleted.`);
            this.getExportList();
        } catch (exception) {
            message.error(`Export could not be deleted.`);
        }
    }

    async downloadDump(id, type, speed_unit) {
        const dump = await this.api.analysis.downloadDump(id, speed_unit);
        if (type === "csv") {
            const csv = this.parser.parse(dump);
            message.success(`Exported dump to CSV.`);
            fileDownload(csv, `export-csv-${Date.now()}.csv`);
        } else if (type === "kml") {
            this.downloadKML(dump);
        }
    }

    async downloadKML(data) {
        let kmlFile = `<?xml version="1.0" encoding="UTF-8"?>`;

        kmlFile += `<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2">`;
        kmlFile += "<Document>";
        kmlFile += "  <name>Replay</name> <open>1</open>";

        kmlFile += "<gx:Tour>";
        kmlFile += "<name>Replay</name>";
        kmlFile += "<gx:Playlist>";

        data.forEach((record) => {
            //     kmlFile += `<Placemark>
            //    <name>${record.target_name} - ${record.time}</name>
            //    <description>${record.in_trip}</description>
            //    <Point>
            //      <coordinates>${record.longitude},${record.latitude}</coordinates>
            //    </Point>
            //  </Placemark>`;

            kmlFile += `<gx:FlyTo>
        <gx:duration>5.0</gx:duration>
        <!-- bounce is the default flyToMode -->
        <Camera>
          <longitude>${record.longitude}</longitude>
          <latitude>${record.latitude}</latitude>
          <altitude>200</altitude>
          <heading>${record.bearing ? record.bearing : 0}</heading>
        </Camera>
      </gx:FlyTo>

      <gx:Wait>
        <gx:duration>1.0</gx:duration>
      </gx:Wait>`;
        });
        kmlFile += "</gx:Playlist>";
        kmlFile += "</gx:Tour>";
        kmlFile += "<Folder>";
        kmlFile += "<name>Points</name>";
        kmlFile += `
    <styleUrl>
      root://styleMaps#default+nicon=0x307+hicon=0x317
    </styleUrl>
    <Style>
      <IconStyle>
      <heading>300</heading>
        <Icon>
          <href>
            root://icons/palette-2.png
          </href>
          <w>32</w>
          <h>32</h>
        </Icon>
      </IconStyle>
    </Style>`;
        data.forEach((record) => {
            kmlFile += `<Placemark>
             <name>${record.target_name} - ${record.time}</name>
             <description>${record.in_trip}</description>
             <Point>
               <coordinates>${record.longitude},${record.latitude}</coordinates>
             </Point>
           </Placemark>`;
        });
        kmlFile += "</Folder>";
        kmlFile += "</Document>";
        kmlFile += "</kml>";

        kmlFile = kmlFile.replace(
            "</Placemark>,<Placemark>",
            "</Placemark><Placemark>"
        );
        kmlFile = kmlFile.replace("undefined", "");
        fileDownload(kmlFile, `export-kml-${Date.now()}.kml`);
    }

    async downloadStopsCSV(data) {
        const parsedData = data.map((record) => {
            return {
                targetName: record.target_name,
                fromDateType: new Date(
                    Number(record.device_utc_date_time) * 1000
                ).toLocaleString(),
                toDateType: new Date(
                    Number(record.device_utc_date_time + record.stopped) * 1000
                ).toLocaleString(),
                address: record.address || null,
                stoppedFor: prettyMS(record.stopped * 1000),
                coordinates: `${record.latitude}, ${record.longitude}`,
            };
        });
        const csv = this.parser.parse(parsedData);
        message.success(`Exported dump to CSV.`);
        fileDownload(csv, `export-stops-${Date.now()}.csv`);
    }
}

export const store = new AnalysisStore();
export default createContext(new AnalysisStore());
