import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Modal, Select } from "antd";
import React, {useEffect, useState} from "react";
import API from '../../services/api';

const { Option } = Select;

class DeviceUnlink extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.onSubmit({ values: this.props.data, mode: this.props.mode });
      }
    });
  };

  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };


  render() {
    const { getFieldDecorator } = this.props.form;
    const { data, mode } = this.props;
    let extraData = {};
    const colors = [
      {name: 'Red', value: '#C0392B'},
      {name: 'Blue', value: '#0000FF'},
      {name: 'Purple', value: '#6a0dad'},
      {name: 'Green', value: '#008000'},
      {name: 'Orange', value: '#FFA500'}
    ]
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Modal
          title={"UnLink Device"}
          visible={this.props.opened}
          onOk={this.handleSubmit}
          confirmLoading={false}
          onCancel={() => {
            this.props.form.resetFields();
            this.props.onCancel();
          }}
          footer={[
            <Button type="dashed" key="back" onClick={this.onCancel}
            style={{width: "47%", right:"3%", borderRadius: "5px",}}>
              Cancel
            </Button>,
            <Button
            style={{width: "47%", right: "2%", backgroundColor: "red", color: "white", borderRadius: "5px",}}
              loading={this.state.loading}
              onClick={this.handleSubmit}
              key="Delete"
            >
              Unlink
            </Button>,
          ]}
        >
          <p>{`Device: ${JSON.stringify(this.props.data) || ""}`}</p>
        </Modal>
      </Form>
    );
  }
}

export default Form.create({})(DeviceUnlink);
