import {Modal} from "antd";
import React from "react";
import ReactJson from 'react-json-view'
import "./AnalyticsAdditionalInfodialog.scss";

function AnalyticsAdditionalInfoDialog(props) {
    return (
        <React.Fragment>
            <Modal
                title={<div><span>Payload</span><span className="header-subtitle">(Times are in UTC format)</span></div>}
                style={{top: 20}}
                visible={props.modalVisible}
                onCancel={() => props.onCancelClick()}
                footer={null}
                width={'90vw'}
                bodyStyle={{
                    overflowY: 'auto',
                    maxHeight: '90vh'
                }}
            >
                {/*<div>Test</div>*/}
                <ReactJson src={props.data} theme={"monokai"}/>

            </Modal>
        </React.Fragment>
    )
}

export default AnalyticsAdditionalInfoDialog;