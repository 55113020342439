import BaseAPI from "./utilities/request.lib";
import { saveAs } from 'file-saver'
import DownloadAPI from "./utilities/requestDownload";
const download = DownloadAPI();
const HTTP = BaseAPI();

const createUser = async ({
    username,
    name,
    alias,
    email,
    mobile,
    serviceNumber,
    investigation,
    role_id
}) => {
    try {
        const response = await HTTP.post("/user", {
            username,
            name,
            alias,
            email,
            mobile,
            serviceNumber,
            investigation,
            role_id
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user[response.data.data.user.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const viewAllUsers = async () => {
    try {
        const response = await HTTP.get("/user");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const disableUser = async record => {
    try {
        const response = await HTTP.get(`/user/${record.id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteUser = async id => {
    try {
        const response = await HTTP.delete(`/user/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const resendEmail = async id => {
    try {
        const response = await HTTP.get(`/user/resend-mail/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateUser = async record => {
    try {
    // API doesn't accept if the payload still has these values.
        const recordID = record.id;
        delete record.id;
        delete record.clientId;
        delete record.code;
        delete record.client_name;
        delete record.last_login_at;
        const response = await HTTP.put(`/user/${recordID}`, record);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};


async function sendPWRESET(email) {
    try {
        const response = await HTTP.put("/user/forget-password", {
            email,
        });
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.resolve(false);
    }
}

async function getPWTOKEN(UserID) {
    //return UserID * 4;
    try {
        const response = await HTTP.get(`/user/ptok/${UserID}`);
        // const response = await HTTP.get("/sys-admin");
        const data = response.data.data;
        const superusers = data.token;
        return Promise.resolve(superusers);
    } catch (error) {
        return Promise.reject(error);
    }
}

async function manual(name) {
    //return UserID * 4;
    try {
        console.log("hello");
        const response = await download.get(`/user/manual/${name}`);
        // const response = await HTTP.get("/sys-admin");
        console.log(response.data);
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, `${name}_Manual.pdf`)
    } catch (error) {
        return Promise.reject(error);
    }
}

export default {
    createUser,
    viewAllUsers,
    disableUser,
    deleteUser,
    resendEmail,
    updateUser,
    sendPWRESET,
    getPWTOKEN,
    manual,
};
