import { message } from "antd";
import { action, computed, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

export class AuthenticationStore {

    constructor() {
        this.authenticationToken = null;
        this.userProfile = null;
        this.api = API;
        makeAutoObservable(this);
    }

    get isLoggedIn() {
    // Implicitly converts the values to Boolean and checks them
        return !!(this.authenticationToken && this.userProfile);
    }
    async setAsAuthenticated() {
        const token = localStorage.getItem("token");
        const profile = JSON.parse(localStorage.getItem("profile"));
        if (token) {
            this.userProfile = profile;
        }
    }

    async authenticate({ email, password }) {
        let validate = false;
        console.log("here");
        try {
            const response = await this.api.authentication.authenticate(
                email,
                password,
            );
            if (response.isAuthenticated) {
                localStorage.setItem("token", response.profile.accessToken);
                localStorage.setItem("profile", JSON.stringify(response.profile));
                localStorage.setItem("role", JSON.stringify(response.role));
                localStorage.setItem("googleMapsKey", response.googleMapKey);
                localStorage.setItem("utcTime", "false");
                this.authenticationToken = response.profile.accessToken;
                this.userProfile = response.profile;
                return Promise.resolve(true);
            } else {
                message.error(response.errorObj.message);
                return Promise.reject(false);
            }
        } catch (exception) {
            message.error("Login Unsuccessful");
            return Promise.reject(false);
        }
    // }
    }

    async forgotPassword(emailAddress) {
        await this.api.authentication.forgotPassword(emailAddress);
        message.info(
            "If your account exists, you will recieve an email with a recovery link."
        );
    }

    async resetPassword(token, password) {
        try {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("profile");
            localStorage.removeItem("googleMapsKey");
            localStorage.removeItem("role");
            const hasReset = await this.api.authentication.resetPassword(
                token,
                password
            );
            if (hasReset) {
                message.info(
                    "Your password has been reset. Please login with your new credentials."
                );
            }
            return Promise.resolve(hasReset);
        } catch (exception) {
            message.error(exception.response ? exception.response.data.message : "Error updating password.");
        }
    }

    async sendUserRequest({
        alias,
        clientCode,
        emailAddress,
        fullName,
        mobileNumber,
        notes,
        serviceNumber,
        username,
    }) {
        try {
            const data = await this.api.authentication.loginRequest({
                username,
                name: fullName,
                alias,
                email: emailAddress,
                mobile: mobileNumber,
                serviceNumber,
                code: clientCode,
                notes,
            });
            message.info(data.message);
        } catch (exception) {
            message.warn(
                "Something went wrong when sending the request, make sure you're already in the system first?"
            );
        }
    }

    async updateProfile({ username, name, alias, email, mobile, serviceNumber, date_format, utcOffset }) {
        console.log(utcOffset);
        try {
            const profile = await this.api.authentication.updateProfile({
                username,
                name,
                alias,
                email,
                mobile,
                serviceNumber,
                date_format,
                utcOffset
            });
            message.info("Updated your profile");
            localStorage.setItem("profile", JSON.stringify(profile));
        } catch (exception) {
            message.warn(`${exception.response.data.message}`);
        }
    }
    async updatePassword({ oldPassword, password }) {
        try {
            await this.api.authentication.updatePassword({
                oldPassword,
                password,
            });
            message.info("Updated your password.");
        } catch (exception) {
            if (exception.response.status === 422) {
                const errorMessage = exception.response.data.message;
                message.error(errorMessage);
            } else {
                message.error(`${exception.response.data.message}`);
            }
        }
    }

    async contactAdmin({ subject, message: messageToAdmin }) {
        try {
            await this.api.authentication.contactAdmin({
                subject,
                message: messageToAdmin,
            });
            message.info("Your message has been to sent to admin.");
        } catch (exception) {
            message.warn(`${exception.response.data.message}`);
        }
    }

    async DemoRequest(values) {
    // check for valid email
        let email = values.emailAddress;
        let thing = email.split("@");
        let thing2 = thing[1];
        const checkArray = [
            "gmail",
            "yahoo",
            "outlook",
            "hotmail",
            "aol",
            "mail",
            "live",
            "msn",
            "ymail",
            "zoho",
        ]
        let check;
        let fail = 0;
        for (let i = 0; i < checkArray.length; i++) {
            check = thing2.match(checkArray[i]);
            if (check) {
                console.log(check);
                fail += 1;
            }
        }
        if (fail > 0) {
            message.error("Please enter a valid gov email address");
            return false;
        }
        try {
            await this.api.authentication.DemoRequest(values);
            message.info("Your request has been sent to admin.");
        } catch (exception) {
            message.warn(`${exception.response.data.message}`);
        }
    }
}

export default createContext(new AuthenticationStore());
