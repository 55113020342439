import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, DatePicker, Switch, Radio, Space } from "antd";
import moment from "moment";

function WarrantModal({ visible, onCancel, onOk, investigation, data, mode }) {

    const { RangePicker } = DatePicker;

    const [form] = Form.useForm();

    const [manualDate, setManualDate] = useState(false);

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    return (
        <Modal
            title="Warrant"
            visible={visible}
            onCancel={() => {
                onCancel()
                form.resetFields()
            }}
            onOk={() => {
                form.validateFields()
                onOk({
                    ...form.getFieldsValue(), investigationId: investigation, id: data.id,
                    warrantExpiry: manualDate ?
                        form.getFieldsValue().warrantExpiry :
                        mode !== "edit" ?
                            [moment(form.getFieldsValue().warrantExpiry), moment(form.getFieldValue().warrantExpiry + (form.getFieldsValue().length * 86400000)).format("YYYY-MM-DD")]
                            :
                            form.getFieldsValue().length !== undefined ?
                            [moment(data.warrantStart * 1000), moment(data.warrantExpire * 1000 + (form.getFieldsValue().length * 86400000))] :
                            [moment(data.warrantStart * 1000), moment(data.warrantExpire * 1000)]
                })
            }}
        >
            <Form
                form={form}
            >
                <Form.Item
                    name="warrantId"
                    label="Warrant Number/Reference"
                    initialValue={mode === "edit" ? data.warrantNoRef : null}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="warrantNotes"
                    label="Warrant Notes"
                    initialValue={mode === "edit" ? data.warrantNote : null}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Manual Date Selection"
                >
                    <Switch
                        onChange={async (checked) => (
                            await form.setFieldsValue({ warrantExpiry: null }),
                            setManualDate(checked)
                        )}
                    />
                </Form.Item>
                <Form.Item
                    name="warrantExpiry"
                    label={manualDate ? "Warrant Dates" : "Warrant Start Date"}
                    initialValue={mode === "edit" ? moment(data.warrantExpiry) : null}
                >
                    {
                        manualDate ?
                            <RangePicker />
                            :
                            < DatePicker
                                disabled={mode === "edit"}
                            />
                    }
                </Form.Item>
                {!manualDate &&
                    <Form.Item
                        name="length"
                        label={!mode === "edit" ? "Length" : "Extension Length"}
                    >
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value="15">15 Days</Radio>
                                <Radio value="30">30 Days</Radio>
                                <Radio value="60">60 Days</Radio>
                                <Radio value="90">90 Days</Radio>
                                <Radio value="120">120 Days</Radio>
                                <Radio value="180">180 Days</Radio>
                                <Radio value="365">365 Days</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                }
            </Form>
            <p>Warrant Expiry Notifications are automatically generated at 21, 14, 7 and 2 days prior to expiry and on the Warrant expiry date.
                Use the Target Notifications feature to allocate the Warrant expiry alert recipients.</p>
        </Modal>
    );
}

export default WarrantModal;