import {Row, Col} from 'antd';
import React from "react";
import './PrivacyPolicy.scss'

export default function PrivacyPolicyPage(props) {
    return (
        // <p>TEST</p>
        <div className={'privacy-wrapper'}>
            <h2>Privacy Policy</h2>
            <div className={'privacy-content-wrapper'}>
                <Row>
                    <Col span={24}>
                        <h5 style={{textAlign: 'center'}}>Effective Date: January 01, 2018. </h5>
                        <div className={'privacy-content'}>
                            <p>We collect certain information through our online services located at atrax.io (our
                                “Services”).
                                This page (this “Privacy Policy”) sets forth our policies and procedures surrounding the
                                collection
                                and handling of any such information. </p>
                            <p>This Privacy Policy applies only to our Services. It does not apply to any third party
                                site or service
                                linked to our Services or recommended or referred by our Services or by our staff. And
                                it does
                                not apply to any other online services or website operated by our company or to any of
                                our offline
                                activities. </p>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>A. What Data We Collect </h5>
                        <div className={'privacy-content'}>
                            <p>We collect the following personal information from users who register with atrax.io
                                and use
                                our products and services: name, username, password, e-mail address, cell phone number.
                                We
                                also collect user Content (e.g., photos, comments, and other materials) that you post,
                                upload, or
                                distribute on or through the Service.
                            </p>
                            <p>When you visit the Service, we may use cookies and similar technologies like pixels, web
                                beacons, and local storage to collect information about how you use atrax.io and
                                provide
                                features to you. A cookie is a string of data our system sends to your computer or
                                device and
                                then uses it to identify your computer or device when you return to our Services.
                                Cookies give us
                                usage data, like how often you visit, where you go at the site, and what you do. We may
                                ask
                                advertisers or other partners to serve ads or services to your computer or devices,
                                which may
                                use cookies or similar technologies placed by us or the third party.</p>
                            <p>In addition, we may use third party analytic products to help us measure usage of the
                                Service.
                                These tools collect information that assists us in improving the Service. </p>
                            <p>We collect log file information. When you use our Service, our servers automatically
                                record
                                certain log file information, including your web request, Internet Protocol (“IP”)
                                address, browser
                                type, referring / exit pages and URLs, number of clicks and how you interact with links
                                on the
                                Service, domain names, landing pages, pages viewed, and other such information. The
                                information allows for more accurate reporting and improvement of the Service. </p>
                            <p>When you use a mobile device like a tablet or phone to access our Service, we may access,
                                collect, monitor, store on your device, and/or remotely store one or more “device
                                identifiers.”
                                Device identifiers are small data files or similar data structures stored on or
                                associated with your
                                mobile device, which uniquely identify your mobile device. A device identifier may be
                                data stored
                                in connection with the device hardware, data stored in connection with the device’s
                                operating
                                system or other software, or data sent to the device. A device identifier may deliver
                                information to
                                us or to a third party partner about how you use the Service and may help us or others
                                provide
                                reports or personalized content and ads. Some features of the Service may not function
                                properly
                                if use or availability of device identifiers is impaired or disabled. </p>
                            <p>Lastly, we collect Metadata. Metadata is usually technical data that is associated with
                                your
                                Content. Metadata makes your Content more searchable by others and more
                                interactive. </p>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>B. Our Use of Your Data </h5>
                        <div className={'privacy-content'}>
                            <p>We use your personal information to create your account, to communicate with you about
                                products and services you’ve registered for, and to offer you additional products and
                                services.
                                We also use your personal information to the extent necessary to enforce our Terms of
                                Use and
                                to prevent imminent harm to persons or property. </p>
                            <p>We use cookies so that our Services can remember you and provide you with the information
                                you’re most likely to need. For instance, when you return to our Services, cookies
                                identify you
                                and prompt the Service to provide your username, so you can sign in more quickly.
                                Cookies also
                                allow us to compile statistical information about use of our Services, such as the time
                                you spend
                                on atrax.io. </p>
                            <p>
                                In addition to some of the specific uses of information we describe in this Privacy
                                Policy, we may
                                use information that we receive from you to: help you efficiently access your
                                information after you
                                sign in; remember information so you will not have to re-enter it during your visit or
                                the next time
                                you visit the Service; provide personalized content and information to you and others,
                                which
                                could include online ads or other forms of marketing; provide, improve, test, and
                                monitor the
                                effectiveness of our Service; develop and test new products and features; and diagnose
                                or fix
                                technology problems.
                            </p>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>C. Protection of Your Data </h5>
                        <div className={'privacy-content'}>
                            <p>We use commercially reasonable safeguards to help keep the information collected through
                                the
                                Service secure and take reasonable steps (such as requesting a unique password) to
                                verify your
                                identity before granting you access to your account. Unfortunately, even with these
                                measures,
                                we cannot guarantee the security of any information you transmit to atrax.io or
                                guarantee that
                                information on the Service may not be accessed, disclosed, altered, or destroyed. By
                                using our
                                Services, you acknowledge and agree that we make no such guarantee, and that you use our
                                Services at your own risk.</p>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>D. Third Party Access to Your Data and Other Information</h5>
                        <div className={'privacy-content'}>
                            <p>Please be aware that we may share your data, Content, as well as information from tools
                                like
                                cookies, log files, and device identifiers and location data, with third party
                                organisations that help
                                us provide the Service to you (“Service Providers”). Our Service Providers will be given
                                access to
                                your data as is reasonably necessary to provide the Service under reasonable
                                confidentiality
                                terms. We also may disclose your data to attorneys, collection agencies, or law
                                enforcement
                                authorities to address potential acceptable use violations, other contract violations,
                                or illegal
                                behaviour. And we disclose any data or information demanded in a court order or
                                otherwise
                                required by law or to prevent imminent harm to persons or property. </p>
                            <p>As noted above, we compile Service usage statistics, such as data collected through
                                cookies.
                                We may publish those statistics or share them with third parties. For instance, we may
                                share
                                certain information, such as cookie data, with third party advertising partners. This
                                information
                                would allow third party ad networks to, among other things, deliver targeted
                                advertisements that
                                they believe will be of most interest to you. We may remove parts of data that can
                                identify you
                                and share anonymized data with other parties. We may also combine your information with
                                other
                                information in a way that it is no longer associated with you and share that aggregated
                                information.</p>
                            <p>Please be advised that any information or content that you voluntarily disclose for
                                posting,
                                uploading, or distribution on or through the Service, such as your Content, may become
                                available
                                to the public. If you remove information that you posted to the Service, copies may
                                remain
                                viewable in cached and archived pages of the Service, or if other users or third parties
                                using
                                atrax.io have copied or saved that information.</p>
                            <p>
                                If we sell or otherwise transfer part or the whole of atrax.io or our assets to
                                another
                                organization (e.g., in the course of a transaction like a merger, acquisition,
                                bankruptcy,
                                dissolution, liquidation), your information such as your name and email address, your
                                Content,
                                and any other information collected through the Service may be among the items sold or
                                transferred. You will continue to own your Content. The buyer or transferee will have to
                                honor the
                                commitments we have made in this Privacy Policy.
                            </p>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>E. Storage of Your Data </h5>
                        <div className={'privacy-content'}>
                            <p>Your information collected through the Service may be stored and processed in Australia
                                or any
                                other country in which atrax.io maintain facilities. Atrax.io may transfer
                                information that
                                we collect about you, including personal information across borders and from your
                                country or
                                jurisdiction to other countries or jurisdictions around the world. If you are located in
                                the European
                                Union or other regions with laws governing data collection and use that may differ from
                                Australian law, please note that we may transfer information, including personal
                                information, to a
                                country and jurisdiction that does not have the same data protection laws as your
                                jurisdiction. </p>
                            <p>By registering for and using the Service you consent to the transfer of information to
                                Australia. or
                                to any other country in which atrax.io maintains facilities and the use and
                                disclosure of
                                information about you as described in this Privacy Policy</p>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>F. Children’s Privacy </h5>
                        <div className={'privacy-content'}>
                            <p>Atrax.io does not knowingly collect or solicit any information from anyone under the
                                age of 13
                                or knowingly allow such persons to register for the Service. The Service and its content
                                are not
                                directed at children under the age of 13. In the event that we learn that we have
                                collected
                                personal information from a child under age 13 without parental consent, we will delete
                                that
                                information as quickly as possible. If you believe that we might have any information
                                from or
                                about a child under 13, please contact us. </p>
                        </div>


                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>G. Amendment of this Privacy Policy</h5>
                        <div className={'privacy-content'}>
                            <p>We may change this Privacy Policy at any time by posting a new version on this page or on
                                a
                                successor page. The new version will become effective on the date it’s posted, which
                                will be
                                listed at the top of the page as the new effective date.</p>
                        </div>


                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h5>H. How to Contact Us </h5>
                        <div className={'privacy-content'}>
                            <p>If you have any questions about this Privacy Policy or the Service, please contact us at :
                                <a href="mailto:sales@atek.net.au">sales@atek.net.au</a> </p>
                        </div>


                    </Col>
                </Row>
            </div>
        </div>
    );
}
