import React, { useState } from "react";
import { getGMTDateTime } from "../../util/CommonUtils";
import { secondaryButton } from "../../styles";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Collapse, Table, Button, Drawer } from "antd";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { contentBox } from "../../styles";

const { Panel } = Collapse;

export default function GeofenceDrawer({
    visible,
    onClose,
    showDrawer,
    fences,
    loadedit,
    onDelete,
    loadview,
}) {

    const [selectedRowKey, setSelectedRowKey] = useState(null);

    if (!visible) {
        return null
    }

    return (
        <Drawer
            title={
                <div>
                    <h4>Geofence Management </h4>

                </div>}
            defaultActiveKey={["trips"]}
            style={{
                boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                      0 12.5px 10px rgba(0, 0, 0, 0.06),
                      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                      0 100px 80px rgba(0, 0, 0, 0.12)`,

                marginBottom: "2px",
                width: "35%",
                marginLeft: "4rem"
            }}
            placement="left"
            onClose={onClose}
            visible={visible}
            mask={false}

            bodyStyle={{ background: '#f8f4f4' }}
            headerStyle={{ background: '#f8f4f4' }}
            // onChange={onExpand}
            closeIcon={
                <ArrowLeftOutlined />
            }

        >
            <Collapse
                defaultActiveKey={['1']}
                // onChange={onPanelChange}
                ghost
                style={contentBox()}
            >
                {
                    fences
                        .sort((a, b) => (a?.investigation_name ?? '').toLowerCase().localeCompare((b?.investigation_name ?? '').toLowerCase()))
                        .map((investigation) => {
                            return (
                                <Panel
                                    id={investigation.id.toString()}
                                    style={{
                                        maxHeight: "90vh",
                                        overflow: "auto",
                                    }}
                                    header={investigation.investigation_name}
                                    key={investigation.id}
                                >
                                    <Button
                                        style={secondaryButton()}
                                        onClick={() => showDrawer(investigation)}
                                    >
                                        New Geofence
                                    </Button>
                                    {investigation.geofences &&
                                        <Table
                                            dataSource={investigation.geofences}
                                            size={"small"}
                                            pagination={{ pageSize: 7 }}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: (e) => {
                                                        e.stopPropagation()
                                                        loadview(record)
                                                        setSelectedRowKey(record.id)
                                                    },
                                                    style: { background: selectedRowKey === record.id ? '#f8f4f4' : '' }
                                                }
                                            }}
                                        >
                                            <Table.Column title="Name" dataIndex="name" key="name" />
                                            <Table.Column title="Trigger" dataIndex="trigger_type" key="trigger_type" />
                                            <Table.Column title="Created Time" dataIndex="created_at" key="created_at" render={(e) => {
                                                return getGMTDateTime(e)
                                            }} />
                                            <Table.Column title="Action" key="action" render={(e) => {
                                                return (
                                                    <div>
                                                        <EditOutlined id={e.id} style={{ margin: '8px' }} onClick={(f) => { loadedit(e) }} />
                                                        <DeleteOutlined style={{ margin: '8px' }} onClick={(f) => {f.stopPropagation(); onDelete(e)}} />
                                                    </div>
                                                )
                                            }} />
                                        </Table>

                                    }
                                </Panel>
                            );
                        })}
            </Collapse>
        </Drawer>
    );
}
