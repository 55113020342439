import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

export class PermissionStore {

    constructor() {
        this.permission = [];
        this.assignedPermission = [];
        this.api = API;
        makeAutoObservable(this);
    }

    setPermission(permissions) {
        this.permission = permissions;
    }

    async getPermissions() {
        const response = await this.api.role.getPermission();
        this.permission = response;
    }

    async getPermissionByRoleId({ id }) {
        try {
            return await this.api.role.getPermissionByRoleId(id);
        } catch (exception) {
            return Promise.reject(exception);
        }
    }

    async getPermissionByRoleIdTest({ id }) {
        try {
            const rolePermissions = await this.api.role.getPermissionByRoleId(id);
            this.assignedPermission = rolePermissions;
        } catch (exception) {
            console.log(exception)
            return Promise.reject(exception);
        }
    }


    can(requestingPermission) {
        const permissionArray = this.assignedPermission.map(p => p.route_name);
        return !permissionArray.includes(requestingPermission);
    }
}

export default createContext(new PermissionStore());
