import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Select } from "antd";
import React from "react";
import { secondaryButton, primaryButton } from '../../styles';

const { Option } = Select;

class AlertForm extends React.Component {
    state = {
        loading: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                values.id = this.props.data.id;
                values.investigation_id = this.props.investigation;
                values.target_id = this.props.target;
                this.props.onSubmit({ values, mode: this.props.mode });
                this.props.form.resetFields();
            }
        });
    };

    onCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    userList = () => {
        let users = this.props.users;
        let returnList = [];
        users.forEach(user => {
            if (user.investigation.find(inv => inv === this.props.investigation)) {
                returnList.push(user)
            }
        });
        return returnList;
    };

    render() {
        const userListArr = this.userList();
        const { getFieldDecorator } = this.props.form;
        const { data, mode } = this.props;
        const alertTypes = [];
        this.props.alertTypes.forEach(alertType => {
            if(alertType.id !== 2 && alertType.id !== 4) {
                alertTypes.push(alertType);
            }
        });
        return (
            <Form onSubmit={this.handleSubmit}>
                <Modal
                    title={mode === "add" ? `Add Alert: ${this.props.name}` : "Edit Alert"}
                    visible={this.props.opened}
                    onOk={this.onUserSubmit}
                    confirmLoading={false}
                    onCancel={() => {
                        this.props.form.resetFields();
                        this.props.onCancel();
                    }}
                    footer={[
                        <Button style={secondaryButton()} key="back" onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <Button
                            loading={this.props.loading || this.state.loading}
                            onClick={this.handleSubmit}
                            key="submit"
                            style={primaryButton()}
                        >
                            {mode === "add" ? "Add Alert" : "Edit Alert"}
                        </Button>,
                    ]}
                >
                    <Form.Item label="Name">
                        {getFieldDecorator("alert_name", {
                            rules: [{ required: true, message: "Please input a name" }],
                            initialValue: mode === "edit" && data ? data.alert_name : "",
                        })(
                            <Input
                                type="text"
                                placeholder="Name"
                                loading={this.props.loading}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="User">
                        {getFieldDecorator("user_id", {
                            rules: [{ required: true, message: "Please select a user" }],
                            initialValue: mode === "edit" && data ? data.user_id : [],
                        })(
                            <Select
                                mode="multiple"
                                showSearch
                                allowClear="true"
                                style={{ width: "100%" }}
                                loading={this.props.loading}
                                placeholder="User"
                            >
                                {userListArr.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                        {user.username ? user.username : user.name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="Alert Type">
                        {getFieldDecorator("alert_type_id", {
                            rules: [
                                { required: true, message: "Please select an alert type!" },
                            ],
                            initialValue: mode === "edit" && data ? data.alert_type_id : [],
                        })(
                            <Select
                                mode="multiple"
                                showSearch
                                allowClear="true"
                                style={{ width: "100%" }}
                                placeholder="Alert Types"
                                loading={this.props.loading}
                            >
                                {alertTypes.map((alert) => (
                                    <Option key={alert.id.toString()} value={alert.id.toString()}>
                                        {alert.label_name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Modal>
            </Form>
        );
    }
}

export default Form.create({})(AlertForm);
