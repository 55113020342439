import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const getUsers = async (id) => {
    try {
        const response = await HTTP.get(`investigationDashboard/users/${id}`)

        const data = response.data.attribute
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

export default {
    getUsers
}