// import { SearchBox } from "@loadup/react-google-places-autocomplete";
import { Button, Spin, message } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import GeofenceMap from "../../components/GeofenceMap";
// import PlacesSearchBox from "../../components/PlacesSearchBox";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import GeofenceStore from "../../stores/geofence.store";
import TargetStore from "../../stores/target.store";
import UserStore from "../../stores/user.store";
import Layout from "../AppLayout";
import "./GeofenceManagementPage.scss";
import LiveStore from "../../stores/live.store";
import GeofenceDrawer from "../../components/GeofenceDrawer";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { primaryButton } from "../../styles";
import GeofenceCreate from "../../components/GeofenceCreate";
import API from "../../services/api";

// import PointForm from "../../components/AnalysisPointsForm";

// https://github.com/google-map-react/google-map-react/issues/102
// https://gis.stackexchange.com/questions/183248/getting-polygon-boundaries-of-city-in-json-from-google-maps-api
// https://stackoverflow.com/questions/55830414/how-to-read-text-file-in-react


function GeofenceManagementPage() {
    const store = useContext(GeofenceStore);
    const userStore = useContext(UserStore);
    const targetStore = useContext(TargetStore);
    const geofenceStore = useContext(GeofenceStore);
    const liveStore = useContext(LiveStore);
    const [
        { targetsForInvestigation, investigationsForUser, usersForInvestigation, selectedInvestigation, },
        setSelectedInvestigation,
    ] = useInvestigationForUser();

    const [existingData, setExistingData] = useState({});

    const [showStopsMap, setShowStopsMap] = useState(false);

    const [visible, setVisible] = useState(true);
    const [opened, setOpened] = useState(false);
    const [margin, setMargin] = useState('30.25%');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [activeShape, setActiveShape] = useState(null);
    const [rectangleBounds, setRectangleBounds] = useState(null);
    const [radius, setRadius] = useState(0);
    const [center, setCenter] = useState(null);

    const [alwaysActive, setAlwaysActive] = useState(true)

    const [buttonOpacity, setButtonOpacity] = useState('1.0');
    const [buttonMargin, setButtonMargin] = useState('32%')

    useEffect(() => {
        targetStore.getAllTargetsByInvestigationAndUser();
        geofenceStore.getAllGeofences();
        userStore.getUsers();
    }, [geofenceStore, targetStore, userStore]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    // eslint-disable-next-line
  }, []);

    useEffect(() => {
        store.getAllGeofences();
    }, [store, existingData, opened]);

    const showDrawer = () => {
        setButtonOpacity('1.0');
        setButtonMargin('32%');
        setVisible(true);
        setMargin("22%");

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin('0px');
        setOpened(false);
    };

    const setHoverBut = () => {
        setButtonOpacity('0.7');
        // setButtonMargin('-5px');
    }

    const unSetHover = () => {
        setButtonOpacity('1.0');
        // setButtonMargin('-3px');
    }

    return (
        <Layout
            showNavigation
            hideMargins
        >

            {showStopsMap === false && (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                    }}
                >
                    <Spin></Spin>
                </div>
            )}

            {showStopsMap === true && (
                <div>
                    <div style={{
                        position: "fixed",
                        zIndex: 2,
                        width: "100%"
                    }}>
                        < Button type="primary" onClick={visible ? onClose : showDrawer}
                            style={primaryButton({
                                position: "absolute",
                                top: "40vh",
                                zIndex: 2,
                                height: "15vh",
                                marginTop: "0%",
                                left: buttonMargin,
                                marginLeft: "2px",
                                borderLeftColor: "#0066CC",
                                marginBottom: "2px",
                                opacity: buttonOpacity,
                            })}
                            onMouseEnter={() => { setHoverBut() }}
                            onMouseLeave={() => { unSetHover() }}
                        >
                            {
                                visible
                                    ?
                                    <ArrowLeftOutlined
                                        width={25}
                                        height={25}
                                    />
                                    :
                                    <ArrowRightOutlined
                                        width={25}
                                        height={25}
                                    />
                            }
                        </Button>
                    </div>
                    <GeofenceDrawer
                        visible={visible}
                        onClose={onClose}
                        margin={margin}
                        showDrawer={(e) => {
                            setOpened(true);
                            setName(e.investigation_name);
                            setSelectedInvestigation(e.id);
                            setActiveShape(null);
                            setExistingData({});
                        }}
                        loadedit={(e) => {
                            setOpened(true);
                            setSelectedInvestigation(e.investigation[0]);
                            setExistingData(e);
                            setName(e.investigation_name);
                            setType(JSON.parse(e.fence).type);
                            setActiveShape(JSON.parse(e.fence).type);
                            if (JSON.parse(e.fence).type === 'circle') {
                                setRadius(JSON.parse(e.fence).coordinates[0][1]);
                                setCenter(JSON.parse(e.fence).coordinates[0][0]);
                            } else if (JSON.parse(e.fence).type === 'rectangle') {
                                setRectangleBounds({
                                    north: JSON.parse(e.fence).coordinates[0][0][0],
                                    south: JSON.parse(e.fence).coordinates[0][2][0],
                                    east: JSON.parse(e.fence).coordinates[0][1][1],
                                    west: JSON.parse(e.fence).coordinates[0][0][1]
                                });
                            }
                        }}
                        loadview={(e) => {
                            setActiveShape(JSON.parse(e.fence).type);
                            if (JSON.parse(e.fence).type === 'circle') {
                                setRadius(JSON.parse(e.fence).coordinates[0][1]);
                                setCenter(JSON.parse(e.fence).coordinates[0][0]);
                            } else if (JSON.parse(e.fence).type === 'rectangle') {
                                setRectangleBounds({
                                    north: JSON.parse(e.fence).coordinates[0][0][0],
                                    south: JSON.parse(e.fence).coordinates[0][2][0],
                                    east: JSON.parse(e.fence).coordinates[0][1][1],
                                    west: JSON.parse(e.fence).coordinates[0][0][1]
                                });
                            }
                        }}
                        investigationsForUser={investigationsForUser}
                        fences={toJS(store.geofences)}
                        onDelete={(e) => {
                            store.deleteGeofence(e);
                        }}
                    />
                    <GeofenceCreate
                        alwaysActive={alwaysActive}
                        setAlwaysActive={setAlwaysActive}
                        users={usersForInvestigation}
                        opened={opened}
                        data={existingData || null}
                        name={name}
                        onCancel={() => {
                            setOpened(false);
                            setType(null);
                            setAlwaysActive(true)
                            setExistingData({});
                        }}
                        targets={targetsForInvestigation}
                        onSubmit={(e) => {
                            let fence = {}
                            if (activeShape) {
                                if (activeShape === "rectangle") {
                                    fence = {
                                        type: "rectangle",
                                        coordinates: [[
                                            [rectangleBounds.north, rectangleBounds.west],
                                            [rectangleBounds.north, rectangleBounds.east],
                                            [rectangleBounds.south, rectangleBounds.east],
                                            [rectangleBounds.south, rectangleBounds.west],
                                            [rectangleBounds.north, rectangleBounds.west]
                                        ]]
                                    }
                                }
                                else if (activeShape === "circle") {
                                    fence = {
                                        type: "circle",
                                        coordinates: [[center, radius]]
                                    }
                                }
                            }
                            let from = e.activeDurationFrom | undefined;
                            let to = e.activeDurationTo | undefined;

                            let days;

                            try {
                                days = e.activeDays.map(String)
                            } catch (error) {
                                days = []
                            }

                            if (!existingData.id) {
                                API.geofence.createGeofence({
                                    name: e.name,
                                    investigation_id: selectedInvestigation,
                                    fence: fence,
                                    _days: [...new Set(days)],
                                    _from: from,
                                    _to: to,
                                    target_id: e.target.length ? e.target : [e.target],
                                    timezone: e.timezone,
                                    apply_geofence_datetime: alwaysActive ? 0 : 1,
                                }).then((res) => {
                                    if (res.status === 200) {
                                        message.success("Geofence created successfully");
                                    }
                                }).catch((err) => {
                                    message.error("Error creating geofence");
                                })
                            } else {
                                API.geofence.updateGeofence({
                                    id: existingData.id,
                                    name: e.name,
                                    investigation_id: selectedInvestigation,
                                    fence: fence,
                                    _days: [...new Set(days)],
                                    _from: from,
                                    _to: to,
                                    target_id: e.target.length ? e.target : [e.target],
                                    timezone: e.timezone,
                                    apply_geofence_datetime: alwaysActive ? 0 : 1,
                                }).then(() => {
                                    message.success("Geofence updated successfully");
                                }).catch((err) => {
                                    message.error("Error updating geofence");
                                })
                            }
                            setType(null);
                            setOpened(false);
                            setActiveShape(null);
                            setAlwaysActive(true);
                            setExistingData({});
                            store.getAllGeofences();
                        }}
                        shapeSelect={(e) => {
                            setType(e);
                        }}
                        type={type}
                    />

                    <div style={{ marginLeft: margin }}>
                        <div style={{ height: "100vh", width: "100%" }}>
                            <GeofenceMap
                                selectedTargetLocationFromLive={toJS(liveStore.selectedTarget)}
                                panBy={[-150, 0]}
                                defaultZoom={15}
                                apiKey={localStorage.getItem("googleMapsKey") || ""}
                                type={type}
                                activeShape={activeShape}
                                setActiveShape={setActiveShape}
                                rectangleBounds={rectangleBounds}
                                setRectangleBounds={setRectangleBounds}
                                center={center}
                                setCenter={setCenter}
                                radius={radius}
                                setRadius={setRadius}
                            ></GeofenceMap>
                        </div>
                    </div>
                </div>
            )}

        </Layout>
    );
}

export default observer(GeofenceManagementPage);
