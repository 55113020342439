import { Avatar, Button, Divider, Space, Table, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import TimeAgo from "timeago-react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import "./InvestigationTable.scss";
import { customSorter, formatDate } from "../../util/CommonUtils";
import InvestigationExpansion from "../InvestigationExpansion";
import { color } from "../../styles.js";
import { PlusOutlined, MinusOutlined, WarningFilled, RightOutlined, UpOutlined, LockOutlined, CheckOutlined, DeleteFilled, AimOutlined, CheckCircleOutlined } from "@ant-design/icons";

const { Column } = Table;

export default function ({ onDelete, data, onEditRecord, canEdit, canDelete }) {

    const [expandedData, setExpandedData] = useState([]);

    const [expandedContent, setExpandedContent] = useState([]);

    const _onEditRecord = record => {
        onEditRecord(record);
    };

    const _onDeleteRecord = record => {
        onDelete(record);
    };

    const checkOngoingInvestigation = record => {
        let currentDate = new Date();
        let myDate = new Date(record.end_datetime * 1000);
        if (currentDate < myDate) {
            return true;
        } else {
            return false;
        }
    };

    const sortDirections = ['ascend', 'descend'];

    const onExpand = (expanded, record) => {
        const expandedRows = [];
        const expandedCon = [];

        if (expanded) {
            expandedRows.push(record.id)
            expandedCon.push(record)
        }
        setExpandedData(expandedRows)
        setExpandedContent(expandedCon)
    }

    const rowRender = (record, index) => {
        return  {
            style: (expandedData.includes(record.id))
                ? { backgroundColor: "#00ddff", cursor: 'pointer' }
                : {backgroundColor: "#fcf0f0", cursor: 'pointer'}
        }
    }

    const InvestigationStateDictionary = {
        "active": "On Going",
        "inactive": "Closed",
        "prep": "Locked",
        "deleted": "Deleted"
    }

    const tagColourDictionary = {
        "active": "green",
        "inactive": "red",
        "prep": "blue",
        "deleted": "grey"
    }
    const iconDictionary = {
        "active": <CheckCircleOutlined style={{color: "green", marginRight: "3px"}}/>,
        "inactive": <WarningFilled style={{color: "grey", marginRight: "3px"}}/>,
        "prep": <LockOutlined style={{color: "blue", marginRight: "3px"}}/>,
        "deleted": <DeleteFilled style={{color: "red", marginRight: "3px"}}/>
    }

    //:TODO: change from tags to icons

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            //defaultSortOrder: "descend",
            sorter: {
                compare: (a, b) => customSorter(b.name, a.name),
                multiple: 4
            },

            width: '15%',
        },
        {
            title: "Status",
            key: "state",
            dataIndex: "state",
            width: '8%',
            render: (text, record) => (
                <div>
                    { iconDictionary[record.state] }

                    { InvestigationStateDictionary[record.state] }
                </div>

                // <Tooltip title={InvestigationStateDictionary[record.state]}><Tag color={tagColourDictionary[record.state]} icon={iconDictionary[record.state]} style={{minWidth: "100px", textAlign: "center"}}></Tag></Tooltip>
            ),
            filters: [
                {
                    text: "On Going",
                    value: "active",
                },
                {
                    text: "Closed",
                    value: "inactive",
                },
                {
                    text: "Locked",
                    value: "prep",
                },
                {
                    text: "Deleted",
                    value: "deleted",
                },
            ],
            onFilter: (value, record) => {
                return record.state.indexOf(value) === 0
            }
        },
        {
            title: "Start Date",
            dataIndex: "start_datetime",
            key: "start_datetime",
            sorter: {
                compare: (a, b) => customSorter(b.start_datetime, a.start_datetime),
                multiple: 4
            },
            render: (text, record) => (
                record.start_datetime ? (
                    <Tooltip
                        title={formatDate(record.start_datetime, 'hh:mm:ss A')}
                    >
                        <TimeAgo datetime={Number(record.start_datetime) * 1000} />
                    </Tooltip>
                ) : (
                    "N/A"
                )

            ),
            width: '10%',
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Locked Date",
            dataIndex: "end_datetime",
            key: "end_datetime",
            render: (text, record) => {
                if (record.state === "prep") {
                    return (
                        record.end_datetime ? (
                            <Tooltip
                                title={formatDate(record.end_datetime, 'hh:mm:ss A')}
                            >
                                <TimeAgo datetime={Number(record.end_datetime) * 1000} />
                            </Tooltip>
                        ) : (
                            "N/A"
                        )
                    )
                } else {
                    return "N/A"
                }
            }


        }

    ]

    const expandedRowRender = () => {
        return (
            <InvestigationExpansion
                data={expandedContent}
                canEdit={canEdit}
                canDelete={canDelete}
                _onEditRecord={_onEditRecord}
            />
        )
    }
    return (
        <Table
            dataSource={data}
            size="middle"
            rowKey="id"
            expandable={{
                onExpand: (expanded, record) => { onExpand(expanded, record) },
                expandedRowKeys: expandedData,
                expandedRowRender,
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                        <UpOutlined onClick={e => onExpand(record, e)} />
                    ) : (
                        <RightOutlined onClick={e => onExpand(record, e)} />
                    ),
                expandRowByClick: true,
            }}
            pagination={{ pageSize: 10 }}
            onRow={rowRender}
            columns={columns}
        >

        </Table>
    );
}
