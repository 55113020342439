import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const exportPoints = async (pointsPageObj) => {
    try {
        const response = await HTTP.post(`device-log/export/points`, pointsPageObj);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error)
    }
}

const getExportList = async () => {
    try {
        const response = await HTTP.get(`device-log/export/`);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error)
    }
}

const getExportDownload = async (exportID) => {
    try {
        const response = await HTTP.get(`device-log/export/download/${exportID}`);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error)
    }
}

const saveExport = async (exportObj) => {
    try {
        const response = await HTTP.post(`device-log/export/`, exportObj);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error)
    }
}

const getExportHistory = async () => {
    try {
        const response = await HTTP.get(`device-log/export/history`);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error)
    }
}

export default {
    exportPoints,
    getExportList,
    getExportDownload,
    saveExport,
    getExportHistory,
};