import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import ChangePasswordForm from "../../components/ChangePasswordForm";
import AuthStore from "../../stores/auth.store";
import getPublicURL from "../../utilities/public-url.utility";
import "./ChangePasswordPage.scss";

function ChangePasswordPage(props) {
    const token = props.match.params.token;
    const store = useContext(AuthStore);
    return (
        <div className="ChangePassword">
            <img
                className="front-logo"
                alt="logo"
                src={`${getPublicURL()}/atrax_logo.png`}
            ></img>
            <div className="change-password-text">
                <strong>Change your password</strong>
                <div>
                    <p>
            Please enter a new password and confirmation of password. We'll
            update your account details with the new credentials.
                    </p>
                </div>
            </div>
            <ChangePasswordForm
                onSubmit={async (data) => {
                    const isSuccess = await store.resetPassword(token, data.password);
                    if (isSuccess) {
                        window.location.replace("/login");
                    }
                }}
            ></ChangePasswordForm>
        </div>
    );
}

export default observer(ChangePasswordPage);
