import { message } from "antd";
import React, { useEffect, useState } from "react";
import API from "../../services/api";
import "./ProfileCounterTable.scss";

export default function ProfileCounterTable(props) {
    const [profileCounters, setProfileCounters] = useState(null);

    useEffect(() => {
        async function getProfileCounters() {
            await API.profileCounter
                .getProfileCounters(props.deviceId)
                .then((res) => {
                    if (res.profileCounter.length > 0) {
                        setProfileCounters(res.profileCounter[0]);
                    } else {
                        message.error(`No profile counters available`);
                    }
                });
        }

        /*async function getDeviceDetails() {
            const arr = []
            await deviceStore.getAllDevices().then(res => {
                toJS(deviceStore.devices.map((device) => {

                    arr.push(device)
                }));
                const y = arr.filter(x => x.id === props.deviceId);


            });
        }*/

        getProfileCounters();
    //getDeviceDetails();
    // eslint-disable-next-line
  }, []);

    const arrangeTime = (seconds) => {
        let days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        let hrs = Math.floor(seconds / 3600);
        seconds -= hrs * 3600;
        let mnts = Math.floor(seconds / 60);
        seconds -= mnts * 60;

        return `${days > 0 ? days + (days === 1 ? " day + " : " days + ") : ""} ${
            hrs < 10 ? "0" + hrs : hrs
        }:${mnts < 10 ? "0" + mnts : mnts}:${
            seconds < 10 ? "0" + seconds : seconds
        }`;
    };

    const convertTime = (date) => {
        const convertedDate = new Date(date);
        return convertedDate.toLocaleString();
    };

    return (
        <React.Fragment>
            {profileCounters !== null && (
                <div>
                    <h6 className={'profile-counter-header'}>Profile Counter</h6>
                    <div className={"profile-counter-table"}>
                        <table>
                            {profileCounters.successfulWifiScans !== null && (
                                <tbody>
                                    <tr>
                                        <th className={"updated-time"} colSpan="2">
                        Updated At - {convertTime(profileCounters.updatedAt)}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Accelerometer Wakeups</th>
                                        <td>{profileCounters.accelerometerWakeups}</td>
                                    </tr>
                                    <tr>
                                        <th>Battery Voltage</th>
                                        <td>{profileCounters.batteryVoltage}</td>
                                    </tr>
                                    <tr>
                                        <th>Initial Battery Voltage</th>
                                        <td>{profileCounters.initialBatteryVoltage}</td>
                                    </tr>
                                    <tr>
                                        <th>Maximum Temperature</th>
                                        <td>{profileCounters.maximumTemperature}</td>
                                    </tr>
                                    <tr>
                                        <th>Successful Upload Time</th>
                                        <td>{arrangeTime(profileCounters.successfulUploadTime)}</td>
                                    </tr>
                                    <tr>
                                        <th>Successful Uploads</th>
                                        <td>{profileCounters.successfulUploads}</td>
                                    </tr>
                                    <tr>
                                        <th>Successful WiFi Scans</th>
                                        <td>{profileCounters.successfulWifiScans}</td>
                                    </tr>
                                    <tr>
                                        <th>Failed WiFi Scans</th>
                                        <td>{profileCounters.failedWifiScans}</td>
                                    </tr>
                                    <tr>
                                        <th>Trips</th>
                                        <td>{profileCounters.trips}</td>
                                    </tr>
                                    <tr>
                                        <th>Uptime</th>
                                        <td>{arrangeTime(profileCounters.upTime)}</td>
                                    </tr>
                                </tbody>
                            )}

                            {profileCounters.failedGPSFixTime !== null && (
                                <tbody>
                                    <tr>
                                        <th className={"updated-time"} colSpan="2">
                        Updated At - {convertTime(profileCounters.updatedAt)}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Accelerometer Wakeups</th>
                                        <td>{profileCounters.accelerometerWakeups}</td>
                                    </tr>
                                    <tr>
                                        <th>Battery Voltage</th>
                                        <td>{profileCounters.batteryVoltage}</td>
                                    </tr>
                                    <tr>
                                        <th>Failed GPS Fix Time</th>
                                        <td>{arrangeTime(profileCounters.failedGPSFixTime)}</td>
                                    </tr>
                                    <tr>
                                        <th>Failed GPS Fixes</th>
                                        <td>{profileCounters.failedGPSFixes}</td>
                                    </tr>
                                    <tr>
                                        <th>Failed Upload Time</th>
                                        <td>{arrangeTime(profileCounters.failedUploadTime)}</td>
                                    </tr>
                                    <tr>
                                        <th>Failed Uploads</th>
                                        <td>{profileCounters.failedUploads}</td>
                                    </tr>
                                    <tr>
                                        <th>GPS Refresh Attempts</th>
                                        <td>{profileCounters.gpsRefreshAttempts}</td>
                                    </tr>
                                    <tr>
                                        <th>GPS Refresh Time</th>
                                        <td>{arrangeTime(profileCounters.gpsRefreshTime)}</td>
                                    </tr>
                                    <tr>
                                        <th>Initial Battery Voltage</th>
                                        <td>{profileCounters.initialBatteryVoltage}</td>
                                    </tr>
                                    <tr>
                                        <th>Maximum Temperature</th>
                                        <td>{profileCounters.maximumTemperature}</td>
                                    </tr>
                                    <tr>
                                        <th>Successful GPS Fix Time</th>
                                        <td>{arrangeTime(profileCounters.successfulGPSFixTime)}</td>
                                    </tr>
                                    <tr>
                                        <th>Successful GPS Fixes</th>
                                        <td>{profileCounters.successfulGPSFixes}</td>
                                    </tr>
                                    <tr>
                                        <th>Successful Upload Time</th>
                                        <td>{arrangeTime(profileCounters.successfulUploadTime)}</td>
                                    </tr>
                                    <tr>
                                        <th>Successful Uploads</th>
                                        <td>{profileCounters.successfulUploads}</td>
                                    </tr>
                                    <tr>
                                        <th>Trips</th>
                                        <td>{profileCounters.trips}</td>
                                    </tr>
                                    <tr>
                                        <th>Uptime</th>
                                        <td>{arrangeTime(profileCounters.upTime)}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>

                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
