import BaseAPI from "./utilities/request.lib";
import { convertSpeed, has } from "../../util/CommonUtils";
import {message} from "antd";
const queryString = require("query-string");

const HTTP = BaseAPI();

const createExport = async ({
    investigation_id,
    description,
    target_id,
    from,
    to,
    templateId,
    timezone,
    name,
}) => {
    try {
        const targets = target_id.map((x) => Number(x));
        const response = await HTTP.post("/device-log/export", {
            investigation_id: Number(investigation_id),
            name,
            description,
            target_id: targets,
            from: Number(from),
            to: Number(to),
            templateId: Number(templateId),
            timezone: timezone,
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.export;
        return Promise.resolve(data.id);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getReports = async () => {
    try {
        const response = await HTTP.get("/device-log/export");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.export;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteReport = async (id) => {
    try {
        const response = await HTTP.delete(`/device-log/export/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const downloadDump = async (id, speed_unit) => {
    try {
        const response = await HTTP.get(`/device-log/export/download/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.export;
        const updated_data = data.map(x => {
            if (x.speed) {
                x.speed = convertSpeed(speed_unit, x.speed)
            }
            return x;
        })
        return Promise.resolve(updated_data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getStops = async ({ investigation_id, target_id, from, to, isDashboard }) => {
    try {
        if (localStorage.getItem("utcTime") === "true") {
            let timezone = new Date().getTimezoneOffset();
            timezone = -timezone * 60;
            from = from + timezone;
            to = to + timezone;
        }
        const fetchStops = (target) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await HTTP.get(
                        `/device-log/stops?${queryString.stringify({
                            investigation_id,
                            target_id: target,
                            from,
                            to,
                        })}`
                    );
                    // Data comes under the data.user key, and comes as an array for some reason
                    // and it usually contains one item.
                    if (response.data.code === "RECORD_NOT_FOUND") {
                        return resolve([]);
                    }

                    const data = response.data.data.stops;
                    const sortedTrips = data.sort((a, b) =>
                        a.device_utc_date_time > b.device_utc_date_time ? 1 : -1
                    );
                    if (isDashboard) {
                        const sortedStops = sortedTrips.sort((a, b) =>
                            b.device_utc_date_time - a.device_utc_date_time
                        );
                        return resolve(sortedStops);
                    }
                    return resolve(sortedTrips);
                } catch (e) {
                    return reject(e)
                }

            });
        };

        const promises = target_id.map(async (target) => {
            return await fetchStops(target);
        });

        const stops = await Promise.all(promises);
        return Promise.resolve(stops.flat());
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getTrips = async ({ investigation_id, target_id, from, to, isDashboard }) => {
    try {
        if (localStorage.getItem("utcTime") === "true") {
            let timezone = new Date().getTimezoneOffset();
            timezone = -timezone * 60;
            from = from + timezone;
            to = to + timezone;
        }
        const fetchTrips = (target) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await HTTP.get(
                        `/device-log/?${queryString.stringify({
                            investigation_id,
                            target_id: target,
                            from,
                            to,
                        })}`
                    );
                    // Data comes under the data.user key, and comes as an array for some reason
                    // and it usually contains one item.
                    if (response.data.code === "RECORD_NOT_FOUND") {
                        return resolve([]);
                    }
                    const data = response.data.data.trips;
                    data.forEach((record) => {
                        if (!has.call(record, "target_name")) {
                            record.target_name = "Unnamed";
                        }
                    })
                    const sortedTrips = data.sort((a, b) =>
                        a.device_utc_date_time > b.device_utc_date_time ? 1 : -1
                    );
                    if (isDashboard) {
                        const descSortedTrips = sortedTrips.sort((a, b) =>
                            b.device_utc_date_time - a.device_utc_date_time
                        );
                        return resolve(descSortedTrips);
                    }
                    return resolve(sortedTrips);
                } catch (e) {
                    if (e.response.status === 404) {
                        resolve([]);
                    } else {
                        return reject(e)
                    }
                }
            });
        };

        const promises = target_id.map(async (target) => {
            return await fetchTrips(target);
        });

        const response = await Promise.all(promises)
            .then((trips) => {
                return trips.flat();
            })
            .catch((err) => { console.error(err); });

        return Promise.resolve(response);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getPoints = async ({
    investigation_id,
    target_id,
    from,
    to,
    points,
    offset,
}) => {
    try {
        const fetchTrips = (target) => {
            return new Promise(async (resolve, reject) => {
                try {
                    if (localStorage.getItem("utcTime") === "true") {
                        let timezone = new Date().getTimezoneOffset();
                        timezone = -timezone * 60;
                        from = from + timezone;
                        to = to + timezone;
                    }
                    const response = await HTTP.get(
                        `/device-log/points?${queryString.stringify({
                            investigation_id,
                            target_id: target,
                            from,
                            to,
                            points,
                            offset: offset ? offset : 0,
                        })}`
                    );
                    // Data comes under the data.user key, and comes as an array for some reason
                    // and it usually contains one item.
                    if (response.data.code === "RECORD_NOT_FOUND") {
                        return resolve([]);
                    }
                    const data = response.data.data.trips;
                    const dataCount = response.data.data.count;
                    //
                    const sortedTrips = data.sort((a, b) =>
                        a.device_utc_date_time > b.device_utc_date_time ? 1 : -1
                    );
                    return resolve({ points: sortedTrips, count: dataCount });
                } catch (e) {
                    return reject(e)
                }
            });
        };

        const promises = target_id.map(async (target) => {
            return await fetchTrips(target);
        });

        const trips = await Promise.all(promises);

        return Promise.resolve(trips.flat());
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllPoints = async ({
    investigation_id,
    target_id,
    from,
    to,
    points,
    offset,
}) => {
    try {
        if (localStorage.getItem("utcTime") === "true") {
            let timezone = new Date().getTimezoneOffset();
            timezone = -timezone * 60;
            from = from + timezone;
            to = to + timezone;
        }
        const fetchTrips = (target) => {
            return new Promise(async (resolve, reject) => {
                const response = await HTTP.get(
                    `/device-log/?${queryString.stringify({
                        investigation_id,
                        target_id: target,
                        from,
                        to,
                        points,
                    })}`
                );
                // Data comes under the data.user key, and comes as an array for some reason
                // and it usually contains one item.
                if (response.data.code === "RECORD_NOT_FOUND") {
                    return resolve([]);
                }
                const data = response.data.data.trips;
                const sortedTrips = data.sort((a, b) =>
                    a.device_utc_date_time > b.device_utc_date_time ? 1 : -1
                );
                return resolve(sortedTrips);
            });
        };

        const promises = target_id.map(async (target) => {
            return await fetchTrips(target);
        });

        const trips = await Promise.all(promises);

        return Promise.resolve(trips.flat());
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getLastFewPoints = async ({ device_id, limit }) => {
    try {
        const fetchTrips = (target) => {
            return new Promise(async (resolve, reject) => {
                const response = await HTTP.get(
                    `device-log/geo-points/?${queryString.stringify({
                        device_id,
                        limit,
                    })}`
                );
                // Data comes under the data.user key, and comes as an array for some reason
                // and it usually contains one item.
                if (response.data.code === "RECORD_NOT_FOUND") {
                    return resolve([]);
                }
                const data = response.data.data.geoPoints;
                /* const sortedTrips = data.sort((a, b) =>
             a.device_utc_date_time > b.device_utc_date_time ? 1 : -1
         );*/
                return resolve(data);
            });
        };

        /* const promises = target_id.map(async (target) => {
       return await fetchTrips(target);
     });*/

        // const trips = await Promise.all(promises);

        return Promise.resolve(fetchTrips());
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getDeviceLogDateRange = async ({ investigation_id, target_id }) => {
    try {
        let timezone = 0;
        if (localStorage.getItem("utcTime") === "false") {
            timezone = new Date().getTimezoneOffset();
            timezone = -timezone * 60;
        }
        const response = await HTTP.get(
            `device-log/date-range/?${queryString.stringify({
                investigation_id,
                target_id,
                timezone,
            })}`
        );
        const data = response.data.data.deviceLogDateRange;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getWIfiDeviceLastTrip = async (investigation_id, target_id, timestamp) => {
    try {
        const response = await HTTP.get(
            `device-log/capture/trip`,
            {
                params: {
                    investigation_id: investigation_id,
                    target_id: target_id,
                    timestamp: timestamp
                }
            }
        );
        console.log(response)
        const data = response.data.attribute[0];
        if (response.data.code === "RECORD_NOT_FOUND") {
            message.error("No data found");
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        message.error("No data found");
        return Promise.reject(exception);
    }
}

const getAllNewPoints = async ({
    investigation_id,
    target_id,
    from,
    to,
    points,
    offset,
}) => {
    try {
        if (localStorage.getItem("utcTime") === "true") {
            let timezone = new Date().getTimezoneOffset();
            timezone = -timezone * 60;
            from = from + timezone;
            to = to + timezone;
        }
        const fetchPoints = (target) => {
            return new Promise(async (resolve, reject) => {
                const response = await HTTP.get(
                    `/device-log/pointsNew?${queryString.stringify({
                        investigation_id,
                        target_id: target,
                        from,
                        to,
                        points,
                    })}`
                );
                // Data comes under the data.user key, and comes as an array for some reason
                // and it usually contains one item.
                if (response.data.code === "RECORD_NOT_FOUND") {
                    return resolve([]);
                }
                const data = response.data.attribute.points;
                const sortedTrips = data.sort((a, b) =>
                    a.device_utc_date_time > b.device_utc_date_time ? 1 : -1
                );
                return resolve(sortedTrips);
            });
        };

        const promises = target_id.map(async (target) => {
            return await fetchPoints(target);
        });

        const trips = await Promise.all(promises);

        return Promise.resolve(trips.flat());
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const multiTarget = async (target_ids, from, to) => {
    try {
        to = to / 1000;
        from = from / 1000;
        if (localStorage.getItem("utcTime") === "true") {
            let timezone = new Date().getTimezoneOffset();
            timezone = -timezone * 60;
            from = from + timezone;
            to = to + timezone;
        }
        const response = await HTTP.get(`/device-log/analysis/MultiTargetPoints?target_ids=[${target_ids}]&${queryString.stringify({
            from,
            to,
        })}`);
        return Promise.resolve(response.data.attribute);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const fenceData = async (investigation_id) => {
    try {
        const response = await HTTP.get(`/device-log/analysis/Fence/${investigation_id}}`);
        console.log(response);
        return Promise.resolve(response.data.attribute);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    createExport,
    getReports,
    deleteReport,
    downloadDump,
    getStops,
    getTrips,
    getPoints,
    getAllPoints,
    getLastFewPoints,
    getDeviceLogDateRange,
    getWIfiDeviceLastTrip,
    getAllNewPoints,
    multiTarget,
    fenceData,
};
