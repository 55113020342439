import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, Row, Select, Spin, Table } from "antd";
import { toJS } from "mobx";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DeviceStore from "../../stores/device.store";
import UserStore from "../../stores/user.store";
import API from "../../services/api";
import { formatDate } from "../../util/CommonUtils";
import { contentBox, primaryButton, secondaryButton } from '../../styles';

function DeviceTargetHistoryTab(props) {
    const deviceStore = useContext(DeviceStore);
    const userStore = useContext(UserStore);

    const [currentPage, setCurrentPage] = useState(1);
    const [dateTimeRange, setDateTimeRange] = useState(null);
    const [target, setTarget] = useState(null);
    const [deviceSerial, setDeviceSerial] = useState(null);

    useEffect(() => {
        async function getUsers() {
            await userStore.getUsers().then((res) => { });
        }

        getUsers();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = (e) => {
        props.form.validateFields((error, values) => {
            if (!error) {
                const [from, to] = values.timeRange;

                setDateTimeRange(values.timeRange);
                setDeviceSerial(values.device_serial);
                setTarget(values.target);

                props.onFilterDeviceTargetHistory(
                    values.device_serial,
                    values.target,
                    from,
                    to,
                    0
                );
                // props.onFilterClicked("175583", "1584433470860", "1584433471091");
            } else {
                // alert(JSON.stringify(error, null, 2));
            }
        });
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        const offset = (pagination.current - 1) * 50;
        /*  props.onFilterClicked(
      deviceSerial,
      target,
      dateTimeRange[0],
      dateTimeRange[1],
      offset
    );*/
        props.onFilterDeviceTargetHistory(
            deviceSerial,
            target,
            dateTimeRange[0],
            dateTimeRange[1],
            offset
        );
    };

    const exportData = async () => {
        // const [from, to] = dateTimeRange.timeRange;

        // console.log('dateRange', dateTimeRange);

        const fromConverted = new Date(dateTimeRange[0]);
        fromConverted.setHours(0, 0, 0);

        const toConverted = new Date(dateTimeRange[1]);
        toConverted.setHours(23, 59, 59);

        const response = await API.deviceTargetHistory.getAllDeviceTargetHistory(
            target ? target : null,
            deviceSerial ? deviceSerial : null,
            fromConverted, // Convert seconds to milliseconds
            toConverted, // Convert seconds to milliseconds
            0,
            1
        );

        // console.log('response before', response)

        if (response && response.deviceTargetHistory.length > 0) {
            // console.log("RESPONSE", response)

            response.deviceTargetHistory.forEach(function (record) {
                const userName = toJS(userStore.users).filter(
                    (user) => user.id === record.created_by
                );

                record.User = userName[0].alias
                record.DateTime = ` ${formatDate(record.created_at, 'HH:mm:ss')}`;

                if (record.device_serial) {
                    record.Device_Serial = record.device_serial;
                } else {
                    record.Device_Serial = "N/A";
                }

                delete record.created_at;
                delete record.created_by;
                delete record.client_id;
                delete record.id;
                delete record.device_serial;
                // return userName[0].alias;
            })

            // console.log('response after',response);

            await API.analytics.downloadAnalyticsDump(response.deviceTargetHistory);
        }

    };

    const exportDataForCurrentPage = async () => {
        const updatedData = props.deviceTargetHistory.map(x => {
            x.created_at = ` ${formatDate(x.created_at, 'HH:mm:ss')}`
            return x;
        })

        await API.analytics.downloadAnalyticsDump(updatedData);
    }

    const columns = [
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
            width: "70",
        },
        {
            title: "Device Serial",
            dataIndex: "device_serial",
            key: "device_serial",
            width: "60",
            render: (text, record) => {
                return text ? text : "N/A";
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: "90",
        },
        {
            title: "User",
            dataIndex: "created_by",
            key: "created_by",
            width: "70",
            render: (text, record) => {
                const userName = toJS(userStore.users).filter(
                    (user) => user.id === text
                );

                if (userName.length === 0) {
                    return "USER NOT FOUND";
                }

                return userName[0].alias;
            },
        },
        {
            title: "Date/Time",
            dataIndex: "created_at",
            key: "created_at",
            width: "90",
            render: (text, record) => {
                if (record.created_at !== null) {
                    return formatDate(text, 'HH:mm:ss');
                }
            },
        },
    ];

    const { getFieldDecorator } = props.form;
    return (
        <React.Fragment>
            <div style={contentBox()}>
                <Form
                // onSubmit={handleSubmit}
                >
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Date Range{" "}
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("timeRange", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select a date/time-range!",
                                        },
                                    ],
                                })(
                                    <DatePicker.RangePicker
                                        style={{ width: "100%" }}
                                        timePicker={true}
                                        showTime={{
                                            hideDisabledOptions: true,
                                            defaultValue: [
                                                moment("00:00:00", "HH:mm:ss"),
                                                moment("23:59:59", "HH:mm:ss"),
                                            ],
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "white" }}> * </span>Device Serial
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("device_serial", {
                                    // onChange: props.onDateRangeSelect,
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(
                                    <Select
                                        allowClear={true}
                                        placeholder={"Device Serial"}
                                        showSearch
                                        // defaultValue="Device connection"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.indexOf(input) >= 0
                                        }
                                    // disabled={showDropDown !== true}
                                    >
                                        {toJS(
                                            deviceStore.devices.map((device) => {
                                                return (
                                                    <Select.Option
                                                        key={device.id}
                                                        id={device.id}
                                                        value={device.serial}
                                                    >
                                                        {device.serial}
                                                    </Select.Option>
                                                );
                                            })
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "white" }}> * </span>Target
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("target", {
                                    rules: [
                                        {
                                            required: false,
                                        },
                                    ],
                                })(<Input placeholder="Target Name" />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            textAlign: "end",
                        }}
                    >
                        <Button
                            onClick={() => {
                                handleSubmit();
                            }}
                            style={primaryButton({ marginLeft: "auto" })}
                            key="filrer"
                            type="primary"
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                exportData()
                            }}
                            id={"export-analytics-btn"}
                            disabled={props.deviceTargetHistory.length === 0}
                            style={secondaryButton({ marginLeft: "3px" })}>
                            Export day
                        </Button>

                        <Button
                            onClick={() => {
                                exportDataForCurrentPage();
                            }}
                            disabled={props.deviceTargetHistory && props.deviceTargetHistory.length === 0}
                            style={secondaryButton({ marginLeft: "3px" })}
                        >
                            Export current page
                        </Button>

                    </Row>
                    <hr />
                </Form>
                <br />
            </div>

            {props.spinnerVisible === true ? (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5%",
                    }}
                >
                    <Spin size={"large"} />
                </div>
            ) : (
                <Table
                    style={contentBox()}
                    onChange={handleTableChange}
                    dataSource={props.deviceTargetHistory}
                    columns={columns}
                    rowKey={(record) => {
                        return record.id
                    }}
                    pagination={{
                        position: "both",
                        pageSize: 50,
                        current: currentPage,
                        total: props.deviceTargetHistoryCount,
                    }}
                    scroll={{ x: 1271 }}
                />
            )}
        </React.Fragment>
    );
}

export default Form.create({})(DeviceTargetHistoryTab);
