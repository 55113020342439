import React, { Component, useEffect, useState, useContext } from "react";
import { Table, Tabs, Badge, Row, Button, Spin, Segmented, Modal, message, Tooltip, Divider, Select, Progress } from "antd";
import API from "../../services/api";
import { customSorter, formatDate } from "../../util/CommonUtils";
import { secondaryButton } from "../../styles";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import permissionStore from "../../stores/permission.store";
import investigationStore from "../../stores/investigation.store";
import roleStore from "../../stores/role.store";
import userStore from "../../stores/user.store";
import deviceStore from "../../stores/device.store";
import targetStore from "../../stores/target.store";
import entityNotificationStore from "../../stores/entityNotification.store";
import { toJS } from "mobx";
import TargetFormModal from "../../components/TargetForm";
import DeleteConfirmationModal from "../../components/DeleteConfirmationDialog";
import NotificationModal from "../NotificationModal";
import EntityNotificationModal from "../EntityNotificationModal";
import notificationStore from "../../stores/notification.store";
import UploadDataModal from "../../components/UploadDataModal";
import WarrantModal from "../WarrantModal";
import TimeAgo from "timeago-react";

import { CarOutlined, DeploymentUnitOutlined, TeamOutlined, LogoutOutlined, DeleteOutlined, MessageOutlined, UploadOutlined, UnlockOutlined, UserOutlined, BookOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Column } = Table

export default function InvestigationExpansion({ onDelete, data, onEditRecord, canEdit, canDelete, _onEditRecord }) {

    const [users, setUsers] = useState([])
    const [activeTargets, setActiveTargets] = useState([])
    const [deactivatedTargets, setDeactivatedTargets] = useState([])
    const [warrants, setWarrants] = useState([])
    const [entities, setEntities] = useState([])
    const [sortDirections, setSortDirections] = useState(['ascend', 'descend'])
    const [mode, setMode] = useState("add")
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [existingData, setExistingData] = useState({})
    const [dataStore, setDataStore] = useState(false)
    const [notiModal, setNotiModal] = useState(false)
    const [notiName, setNotiName] = useState("")
    const [notiData, setNotiData] = useState({})
    const [uploadModal, setUploadModal] = useState(false)
    const [uploadData, setUploadData] = useState(0)
    const [warrantModalVisible, setWarrantModalVisible] = useState(false)
    const [warrantData, setWarrantData] = useState({})
    const [warrantMode, setWarrantMode] = useState("add")
    const [usersInvestigation, setUsersInvestigation] = useState([])
    const [stateOpen, setStateOpen] = useState(false)
    const [previousSate, setPreviousState] = useState(null);
    const [addUserModal, setAddUserModal] = useState(false);
    const [notiModalEntity, setNotiModalEntity] = useState(false)
    const [notiDataEntity, setNotiDataEntity] = useState({})

    const stateChangetext = {
        "active_inactive": "Warning: Closing the Investigation will stop all data collection.\n Are you sure you want to Close this Investigation?",
        "inactive_active": "Warning: Target data collection will recommence.\n Are you sure you want change the Investigation state to On Going?",
        "active_deleted": "Deleting this Investigation will remove all data associated with this Investigation.\n Are you sure you want to delete this Investigation?",
        "inactive_deleted": "Deleting this Investigation will remove all data associated with this Investigation.\n Are you sure you want to delete this Investigation?",
        "prep_deleted": "Deleting this Investigation will remove all data associated with this Investigation.\n Are you sure you want to delete this Investigation?",
        "inactive_prep": "Warning: Locking the Investigation will stop data collection and lock all current data?\n This action cannot be reversed.\n Are you sure you want to change the Investigation state to Locked?\n The Investigation Locking process can take a moment.\n Please wait for the locking process completion message to appear before proceeding.",
    }
    const InvestigationStateDictionary = {
        "active": "On Going",
        "inactive": "Closed",
        "prep": "Locked",
        "deleted": "Deleted"
    }

    function segmentDisabler(stateToCheck, currentState, previousState) {
        let returnValue = null;
        switch (currentState) {
            case "active": {
                const displayMatrax = {
                    "active": false,
                    "inactive": false,
                    "prep": true,
                    "deleted": false,
                }
                returnValue = displayMatrax[stateToCheck]
            }
                break;
            case "inactive": {
                const displayMatrax = {
                    "active": false,
                    "inactive": false,
                    "prep": false,
                    "deleted": false,
                }
                returnValue = displayMatrax[stateToCheck]
            }
                break;
            case "prep": {
                const displayMatrax = {
                    "active": true,
                    "inactive": true,
                    "prep": false,
                    "deleted": false,
                }
                returnValue = displayMatrax[stateToCheck]
            }
                break;
            case "deleted": {
                console.log(stateToCheck, previousState)
                if (stateToCheck === previousState) {
                    returnValue = false
                }
                else {
                    returnValue = true
                }
            }
                break;
        }
        return returnValue
    }

    const lockCheck = (state) => {
        if (state === "inactive") {
            return false
        };
        return true
    };


    // these display
    const ongoingCloseCheck = (state) => {
        if (state === "prep") {
            return true
        }
        return false
    }

    const inactiveCheck = (state) => {
        if (state === "inactive") {
            return true
        }
        return false
    }

    function isUserCaseOfficer(state, addusers = true) {
        const user = JSON.parse(localStorage.getItem("profile"));


        let StaticDisplay = false;
        if (state === "prep" || state === "inactive") {
            StaticDisplay = true && addusers
        }
        const permission = PermissionStore.can("target.create");
        if (user.id === data[0].caseOfficer) {
            return false && StaticDisplay
        }
        return ((true && permission) || StaticDisplay)
    }


    const PermissionStore = useContext(permissionStore)
    const InvestigationStore = useContext(investigationStore)
    const RoleStore = useContext(roleStore)
    const UserStore = useContext(userStore)
    const DeviceStore = useContext(deviceStore)
    const TargetStore = useContext(targetStore)
    const notificationStreams = useContext(notificationStore);
    const entityNotificationStreams = useContext(entityNotificationStore);
    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await PermissionStore.getPermissionByRoleId(role);
            PermissionStore.setPermission(permission);
            // PermissionStore.assignedPermission = permission;
        };

        TargetStore.getAllTargets();
        DeviceStore.getAllUnassignedDevices();
        InvestigationStore.getInvestigations();
        fetchRolePermission();
    }, [TargetStore, DeviceStore, InvestigationStore, PermissionStore]);

    useEffect(() => {
        setUsers([]);
        setEntities([]);
        setDataStore(false);
        setWarrants([]);
        if (data[0]) {
            API.investigationDashboard.getUsers(data[0].id).then((res) => {
                setUsers(res)
            })

            API.target.getInvestigationDashboard(data[0].id).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    if (res[i].serial) {
                        setActiveTargets(activeTargets => [...activeTargets, res[i]])
                    } else {
                        setDeactivatedTargets(deactivatedTargets => [...deactivatedTargets, res[i]])
                    }
                }
            }).then(() => {
                setDataStore(true)
            })

            API.entity.GetAllEntities(data[0].id).then((res) => {
                setEntities(res)
            })

            API.investigation.getWarrantsForInvestigation(data[0].id).then((res) => {
                console.log(res)
                setWarrants(res)
            })
        }
    }, [])

    const dataRefresh = () => {

        setDataStore(false)
        setActiveTargets([])
        setDeactivatedTargets([])

        API.investigation.getWarrantsForInvestigation(data[0].id).then((res) => {
            setWarrants(res)
        })

        API.target.getInvestigationDashboard(data[0].id).then((res) => {
            for (let i = 0; i < res.length; i++) {
                if (res[i].serial) {
                    setActiveTargets(activeTargets => [...activeTargets, res[i]])
                } else {
                    setDeactivatedTargets(deactivatedTargets => [...deactivatedTargets, res[i]])
                }
            }
        }).then(() => {
            setDataStore(true)
        })
    }

    return (
        <div
        >
            {data[0] &&
                <UploadDataModal
                    visible={uploadModal}
                    onCancel={() => setUploadModal(false)}
                    onOk={() => setUploadModal(false)}
                    uploadData={uploadData}
                    investigation={data[0].id}
                />
            }
            {data[0] &&
                <WarrantModal
                    mode={warrantMode}
                    data={warrantData}
                    visible={warrantModalVisible}
                    investigation={data[0].id}
                    onCancel={() => setWarrantModalVisible(false)}
                    onOk={(e) => {
                        console.log(warrantData)
                        console.log(e)
                        setWarrantModalVisible(false)
                        if (warrantMode === "add") {
                            API.investigation.createWarrant(e).then((res) => {
                                if (res.error) {
                                    message.error(res.error)
                                } else {
                                    message.success("Warrant created successfully")
                                    dataRefresh();
                                }
                            })
                        } else {
                            API.investigation.updateWarrant(e).then((res) => {
                                if (res.error) {
                                    message.error(res.error)
                                } else {
                                    message.success("Warrant updated successfully")
                                    dataRefresh();
                                }
                            })
                        }
                    }}
                />
            }
            <NotificationModal
                opened={notiModal}
                onCancel={() => setNotiModal(false)}
                onSubmit={() => {
                    setNotiModal(false)
                }}
                name={notiName}
                data={notiData}
            />
            <EntityNotificationModal
                opened={notiModalEntity}
                onCancel={() => setNotiModalEntity(false)}
                onSubmit={() => {
                    setNotiModalEntity(false)
                }}
                name={notiName}
                data={notiDataEntity}
            />
            <TargetFormModal
                investigations={InvestigationStore.investigations}
                opened={isModalOpen}
                mode={mode}
                data={existingData || null}
                investigation={data[0] ? data[0].name : null}
                investigationId={data[0] ? data[0].id : null}
                devices={toJS(DeviceStore.unassignedDevices)}
                warrants={warrants}
                onSubmit={(data) => {
                    const { values, mode } = data;
                    if (mode === "edit") {
                        TargetStore.updateTarget(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    } else {
                        TargetStore.createTarget(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    }
                    DeviceStore.getAllUnassignedDevices();
                    TargetStore.getAllTargets().then(() => {
                        dataRefresh();
                    })
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                    DeviceStore.getAllUnassignedDevices();
                }}
            ></TargetFormModal>
            <Modal
                title="State Change confirmation"
                visible={stateOpen}
                onOk={() => {
                    API.investigation.updateState(data[0].id, data[0].state).then(() => {
                        TargetStore.getAllTargets().then(() => {
                            dataRefresh();
                        })
                        message.success("State changed successfully")
                        setStateOpen(false)
                    }).catch((err) => {
                        message.error("Error changing state", err)
                        setStateOpen(false)
                    })
                }}
                onCancel={() => {
                    data[0].state = previousSate;
                    setStateOpen(false)
                }}
            >
                {data[0] && previousSate && (
                    <div>
                        <p>Previous state: {InvestigationStateDictionary[previousSate]}</p>
                        <p>Investigation being moved to: {InvestigationStateDictionary[data[0]?.state]}</p>
                        <p>{stateChangetext[`${previousSate}_${data[0].state}`]}</p>
                    </div>
                )}
            </Modal>
            <Modal
                title="Add Users To Investigation"
                visible={addUserModal}
                onOk={() => {
                    API.investigation.updateUsers(data[0].id, usersInvestigation).then(() => {
                        message.success("Users added successfully")
                        dataRefresh();
                    }).catch((err) => {
                        message.error("Error adding users", err)
                    })
                    setAddUserModal(false)
                }}
                onChange={(value) => {
                }}
                onCancel={() => {
                    setAddUserModal(false)
                }}
            >
                <Select
                    showSearch
                    style={{ width: 400 }}
                    placeholder="Any User"
                    allowClear
                    mode="multiple"
                    onChange={(value) => {
                        setUsersInvestigation(value)
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    defaultValue={data[0] && data[0].user.map((user) => {
                        return {
                            label: UserStore.getUserByID(user).name,
                            value: user
                        }
                    })}
                    options={UserStore.users.map((user) => {
                        return {
                            label: user.name,
                            value: user.id,
                        }
                    })}
                />
            </Modal>


            <Tabs
                tabBarExtraContent={

                    <div
                        style={{ margin: 0 }}
                    >
                        {data[0] && (
                            <div>
                                <Tooltip title="Add Target">
                                    <Button
                                        style={secondaryButton({ marginRight: "10px" })}
                                        id={"addATargetBtn"}
                                        icon={<div><CarOutlined /><PlusOutlined style={{ fontSize: "7px" }} /></div>}
                                        disabled={isUserCaseOfficer(data[0].state)}
                                        onClick={() => {
                                            DeviceStore.getAllUnassignedDevices();
                                            setMode("add");
                                            setIsModalOpen(true);
                                        }}
                                    >

                                    </Button>
                                </Tooltip>
                                <Tooltip title="Add Warrant">
                                    <Button
                                        style={secondaryButton({ marginRight: "10px" })}
                                        id={"addATargetBtn"}
                                        icon={<div><BookOutlined /><PlusOutlined style={{ fontSize: "7px" }} /></div>}
                                        disabled={isUserCaseOfficer(data[0].state)}
                                        onClick={() => {
                                            setWarrantModalVisible(true)
                                            setWarrantMode("add")
                                        }}
                                    >
                                    </Button>
                                </Tooltip>


                                {/* add user button*/}
                                <Tooltip title="Add Team Member">
                                    <Button
                                        style={secondaryButton({ marginRight: "10px" })}
                                        id={"addATargetBtn"}
                                        icon={<div><UserOutlined /><PlusOutlined style={{ fontSize: "7px" }} /></div>}
                                        disabled={isUserCaseOfficer(data[0].state, false)}
                                        onClick={() => {
                                            setAddUserModal(true)
                                        }}
                                    >

                                    </Button>
                                </Tooltip>
                                <Tooltip title="Edit Investigation">
                                    <Button
                                        id={"editInvestigationBtn"}
                                        style={secondaryButton({ marginRight: "10px" })}
                                        icon={<div><SettingOutlined /></div>}
                                        disabled={canEdit || isUserCaseOfficer(data[0].state)}
                                        onClick={() => {
                                            _onEditRecord(data[0]);
                                        }}
                                    />
                                </Tooltip>

                                <Segmented
                                    style={{ float: "right" }}
                                    options={[
                                        { label: "On Going", value: "active", disabled: segmentDisabler("active", data[0].state, data[0].PrevState) },
                                        { label: "Closed", value: "inactive", disabled: segmentDisabler("inactive", data[0].state, data[0].PrevState) },
                                        { label: "Locked", value: "prep", disabled: segmentDisabler("prep", data[0].state, data[0].PrevState) },
                                        { label: "Delete", value: "deleted", disabled: segmentDisabler("deleted", data[0].state, data[0].PrevState) },
                                    ]}
                                    value={data[0].state}
                                    disabled={isUserCaseOfficer(data[0].state, false)}
                                    onChange={(e) => {
                                        setPreviousState(data[0].state);
                                        data[0].state = e;
                                        setStateOpen(true)
                                    }}
                                ></Segmented>
                            </div>
                        )}
                    </div>
                }
            >
                <TabPane tab={<span><CarOutlined />Active Targets</span>} key="1">
                    {dataStore
                        ?
                        <div>

                            <Table
                                rowkey="id"
                                dataSource={activeTargets}
                                pagination={{ pageSize: 10 }}
                            >
                                <Column
                                    title="Target Name"
                                    key="name"
                                    dataIndex="name"
                                    sorter={(a, b) => customSorter(a.name, b.name)}
                                    sortDirections={sortDirections}
                                />
                                <Column
                                    title="Target Device"
                                    key="serial"
                                    dataIndex="serial"
                                    sorter={(a, b) => customSorter(a.name, b.name)}
                                    sortDirections={sortDirections}
                                />
                                <Column
                                    title="Assigned At"
                                    key="assign_at"
                                    dataIndex="assign_at"
                                    sorter={(a, b) => customSorter(a.name, b.name)}
                                    sortDirections={sortDirections}
                                    render={(text, record) => {
                                        return (
                                            <p>{formatDate(text, 'HH:mm:ss')}</p>
                                        )
                                    }}
                                />
                                <Column
                                    key="settings"
                                    render={(text, record) => {
                                        return (
                                            <div>
                                                {PermissionStore.can("target.edit") && data[0] && (
                                                    <div>
                                                        <Tooltip title="Edit Target">
                                                            <Button
                                                                disabled={ongoingCloseCheck(data[0].state) || inactiveCheck(data[0].state)}
                                                                icon={<SettingOutlined />}
                                                                type="link"
                                                                onClick={() => {
                                                                    setMode("edit");
                                                                    setIsModalOpen(true);
                                                                    setExistingData(record);
                                                                }}
                                                            >
                                                            </Button>
                                                        </Tooltip>
                                                        <Divider type="vertical" />
                                                        <DeleteConfirmationModal
                                                            title={`Are you sure you want to delete '${record.name}'?`}
                                                            content={
                                                                "Deletion of target will permanently delete all tracking data associated with this target and not be recoverable."
                                                            }
                                                            onClickCancel={() => { }}
                                                            onClickOkay={() => {
                                                                TargetStore.deleteTarget(record);
                                                                TargetStore.getAllTargets().then(() => {
                                                                    dataRefresh();
                                                                })
                                                            }}
                                                        >
                                                            <Tooltip title="Delete Target">
                                                                <Button
                                                                    disabled={ongoingCloseCheck(data[0].state) || inactiveCheck(data[0].state)}
                                                                    type="link"
                                                                    icon={<DeleteOutlined />}
                                                                >
                                                                </Button>
                                                            </Tooltip>
                                                        </DeleteConfirmationModal>
                                                        <Divider type="vertical" />
                                                        <Tooltip title="Notification Settings">
                                                            <Button
                                                                type="link"
                                                                disabled={ongoingCloseCheck(data[0].state) || inactiveCheck(data[0].state)}
                                                                icon={<MessageOutlined />}
                                                                onClick={() => {
                                                                    setNotiName(record.name);
                                                                    setNotiModal(true);
                                                                    notificationStreams.getNotificationStreams(record.id);
                                                                    setNotiData({ ...record, investigation_id: data[0].id });
                                                                }}
                                                            >

                                                            </Button>
                                                        </Tooltip>
                                                        <Divider type="vertical" />
                                                        <Tooltip title="Upload Data">
                                                            <Button
                                                                disabled={ongoingCloseCheck(data[0].state) || inactiveCheck(data[0].state)}
                                                                type="link"
                                                                icon={<UploadOutlined />}
                                                                onClick={() => {
                                                                    setUploadModal(true);
                                                                    setUploadData(record.id);
                                                                }}
                                                            >

                                                            </Button>
                                                        </Tooltip>
                                                        <Divider type="vertical" />
                                                        <DeleteConfirmationModal
                                                            title={`Are you sure you want to detach the device from the current Target and Investigation?`}
                                                            content={
                                                                ""
                                                            }
                                                            licenseDeallocationModal={false}
                                                            onClickCancel={() => { }}
                                                            onClickOkay={() => {
                                                                API.target.detach(record.device_id).then((res) => {
                                                                    if (res.code === 'SUCCESS') {
                                                                        message.success("Device was detached successfully !");
                                                                    }
                                                                })
                                                                    .catch((e) => {
                                                                        message.error(e.response.data.message);
                                                                    });
                                                            }}
                                                        >
                                                            <Tooltip title="Detach Device">
                                                                <Button
                                                                    type="link"
                                                                    icon={<UnlockOutlined />}
                                                                    onClick={() => {
                                                                    }}
                                                                >
                                                                </Button>
                                                            </Tooltip>
                                                        </DeleteConfirmationModal>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }}
                                />
                            </Table>
                        </div>

                        :
                        <Spin />
                    }
                </TabPane>
                <TabPane tab={<span><LogoutOutlined /> Detatched Targets</span>} key='3'>
                    {dataStore
                        ?
                        <div>

                            <Table
                                rowkey="id"
                                dataSource={deactivatedTargets}
                                pagination={{ pageSize: 10 }}
                            >
                                <Column
                                    title="Target Name"
                                    key="name"
                                    dataIndex="name"
                                    sorter={(a, b) => customSorter(a.name, b.name)}
                                    sortDirections={sortDirections}
                                />
                                <Column
                                    title="Target Device"
                                    key="serial"
                                    dataIndex="serial"
                                    sorter={(a, b) => customSorter(a.name, b.name)}
                                    sortDirections={sortDirections}
                                />
                                <Column
                                    title="Assigned At"
                                    key="assign_at"
                                    dataIndex="assign_at"
                                    sorter={(a, b) => customSorter(a.name, b.name)}
                                    sortDirections={sortDirections}
                                    render={(text, record) => {
                                        return (
                                            <p>{formatDate(text, 'HH:mm:ss')}</p>
                                        )
                                    }}
                                />
                                <Column
                                    key="settings"
                                    render={(text, record) => {
                                        return (
                                            <div>
                                                {PermissionStore.can("target.edit") && data[0] && (
                                                    <div>
                                                        <Tooltip title="Edit Target">
                                                            <Button
                                                                disabled={ongoingCloseCheck(data[0].state) || inactiveCheck(data[0].state)}
                                                                icon={<SettingOutlined />}
                                                                type="link"
                                                                onClick={() => {
                                                                    setMode("edit");
                                                                    setIsModalOpen(true);
                                                                    setExistingData(record);
                                                                }}
                                                            >
                                                            </Button>
                                                        </Tooltip>
                                                        <Divider type="vertical" />
                                                        <DeleteConfirmationModal
                                                            title={`Are you sure you want to delete '${record.name}'?`}
                                                            content={
                                                                "Deletion of target will permanently delete all tracking data associated with this target and not be recoverable."
                                                            }
                                                            onClickCancel={() => { }}
                                                            onClickOkay={() => {
                                                                TargetStore.deleteTarget(record);
                                                                TargetStore.getAllTargets().then(() => {
                                                                    dataRefresh();
                                                                })
                                                            }}
                                                        >
                                                            <Tooltip title="Delete Target">
                                                                <Button
                                                                    disabled={ongoingCloseCheck(data[0].state) || inactiveCheck(data[0].state)}
                                                                    type="link"
                                                                    icon={<DeleteOutlined />}
                                                                >
                                                                </Button>
                                                            </Tooltip>
                                                        </DeleteConfirmationModal>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }}
                                />
                            </Table>
                        </div>

                        :
                        <Spin />
                    }
                </TabPane>
                <TabPane tab={<span><DeploymentUnitOutlined />Investigation Entities</span>} key='2'>
                    <Table
                        rowkey="id"
                        dataSource={entities}
                        pagination={{ pageSize: 10 }}
                    >
                        <Column
                            title="Entity Name"
                            key="alias"
                            dataIndex="alias"
                            sorter={(a, b) => customSorter(a.name, b.name)}
                            sortDirections={sortDirections}
                        />
                        <Column
                            title="Notes"
                            key="notes"
                            dataIndex="notes"
                            sorter={(a, b) => customSorter(a.name, b.name)}
                            sortDirections={sortDirections}
                        />
                        <Column
                            key="settings"
                            render={(text, record) => {
                                return (
                                    <div>
                                        {PermissionStore.can("target.edit") && data[0] && (
                                            <div>
                                                <Tooltip title="Notification Settings">
                                                    <Button
                                                        type="link"
                                                        disabled={ongoingCloseCheck(data[0].state) || inactiveCheck(data[0].state)}
                                                        icon={<MessageOutlined />}
                                                        onClick={() => {
                                                            setNotiName(record.name);
                                                            setNotiModalEntity(true);
                                                            entityNotificationStreams.getNotificationStreams(record.id);
                                                            setNotiDataEntity({ ...record, investigation_id: data[0].id });
                                                        }}
                                                    >

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                )
                            }}
                        />
                    </Table>
                </TabPane>

                <TabPane tab={<span><TeamOutlined />Team</span>} key="4">
                    <Table
                        rowkey="id"
                        dataSource={users}
                        pagination={{ pageSize: 10 }}
                    >
                        <Column
                            title="Member Names"
                            key="name"
                            dataIndex="name"
                            sorter={(a, b) => customSorter(a.name, b.name)}
                            sortDirections={sortDirections}
                            render={(text, record) => {
                                if (data[0]) {
                                    if (record.id === data[0].caseOfficer) {
                                        return (
                                            <div>
                                                <Row>
                                                    <Badge status="success" />
                                                    <p>{text}</p>
                                                </Row>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <Row>
                                                    <p>{text}</p>
                                                </Row>
                                            </div>
                                        )
                                    }
                                }
                            }}
                        />
                        <Column
                            title="User Role"
                            key="role"
                            dataIndex="role"
                            sorter={(a, b) => customSorter(a.name, b.name)}
                            sortDirections={sortDirections}
                            render={(text, record) => {
                                if (data[0]) {
                                    if (record.id === data[0].caseOfficer) {
                                        return (
                                            <div>
                                                <Row>
                                                    <p>{text} (Case Officer)</p>
                                                </Row>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <Row>
                                                    <p>{text}</p>
                                                </Row>
                                            </div>
                                        )
                                    }
                                }
                            }}
                        />
                    </Table>
                </TabPane>
                <TabPane tab={<span><BookOutlined />Warrants</span>} key='5'>
                    <Table
                        rowkey="id"
                        dataSource={warrants}
                        pagination={{ pageSize: 10 }}
                    >
                        <Column
                            title="Warrant Number/Reference"
                            key="number"
                            dataIndex="warrantNoRef"
                            sorter={(a, b) => customSorter(a.name, b.name)}
                            sortDirections={sortDirections}
                        />
                        <Column
                            title="Warrant Notes"
                            key="notes"
                            dataIndex="warrantNote"
                        />
                        <Column
                            title="Warrant Expiry"
                            key="expiry"
                            dataIndex="warrantExpire"
                            render={(text, record) => {
                                let stroke = "#52c41a";
                                let percent = ((record.warrantExpire - (Date.now() / 1000)) / (21 * 24 * 60 * 60)) * 100;
                                if (percent <= 0) {
                                    percent = 100;
                                    stroke = "#c41a1a";
                                }
                                if (percent < 100) {
                                    stroke = "#faad14";
                                }
                                return (
                                    <div>
                                        <p>{formatDate(text)}
                                            {
                                                Date.now() / 1000 > record.warrantExpire ?
                                                    <span color="red">{Math.floor((Date.now() / 1000 - record.warrantExpire) / 86400)} Days ago</span>
                                                    :
                                                    <span color="green">in {Math.floor((record.warrantExpire - Date.now() / 1000)/ 86400)} Days</span>
                                            }
                                        </p>
                                        <Progress
                                            strokeColor={stroke}
                                            percent={percent}
                                            size="small"
                                            showInfo={false}
                                        />
                                    </div>
                                )
                            }}
                        />
                        <Column
                            render={
                                (text, record) => {
                                    return (
                                        <div>
                                            <Button
                                                type="link"
                                                icon={<SettingOutlined />}
                                                onClick={() => {
                                                    // setMode("edit");
                                                    // setIsModalOpen(true);
                                                    // setExistingData(record);
                                                    setWarrantModalVisible(true);
                                                    setWarrantData(record);
                                                    setWarrantMode("edit");
                                                }}
                                            >
                                                <Tooltip title="Edit Warrant" />
                                            </Button>
                                            <DeleteConfirmationModal
                                                title={`Are you sure you want to delete '${record.warrantNoRef}'?`}
                                                content={
                                                    "Deletion of warrant will be permanent and not recoverable."
                                                }
                                                onClickCancel={() => { }}
                                                onClickOkay={() => {
                                                    API.investigation.deleteWarrant(record.id, data[0].id).then(() => {
                                                        dataRefresh();
                                                    })
                                                }}
                                            >
                                                <Button
                                                    type="link"
                                                    icon={<DeleteOutlined />}
                                                >
                                                    <Tooltip title="Delete Warrant" />
                                                </Button>
                                            </DeleteConfirmationModal>
                                        </div>
                                    )
                                }
                            }
                        />
                    </Table>

                </TabPane>

            </Tabs>
        </div >
    )
}
