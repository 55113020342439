import { Button, Result } from "antd";
import React from "react";

export default function LoggedInElseWhere(props) {
    return (
        <Result
            status="403"
            title="Sign-in Detected"
            subTitle="You have been logged in elsewhere."
            extra={
                <Button
                    type="primary"
                    onClick={() => {
                        window.location = "/";
                    }}
                >
          Back Home
                </Button>
            }
        />
    );
}
