import React, {useEffect, useState} from "react";
import "./HuntsmanLiveDataLogTable.scss";
import {formatDate} from "../../util/CommonUtils";

function HuntsmanLiveDataLogTable(props) {

    const [huntsmanLiveData, setHuntsmanLiveData] = useState([]);

    useEffect(() => {
        if (props.data && props.data.length > 0){
            let updatedData = deserializePayload(props.data);
            setHuntsmanLiveData(updatedData);
        }
    }, [props.data]);

    const deserializePayload = (deviceAnalyticLatest) => {
        let newValues = []

        deviceAnalyticLatest.forEach((item) => {
            let jsonObjArr = JSON.parse(item.serializedPayload);
            if (jsonObjArr && Array.isArray(jsonObjArr)) {
                jsonObjArr.forEach((payload) => {
                    let obj = {
                        attributes:[]
                    }

                    console.log(payload, "payload")
                    switch (payload.packetType) {
                        case 'COMMAND_ACKNOWLEDGED':
                            payload['epochDateTime'] = formatDate(payload['epochDateTime'], 'hh:mm:ss A');
                            payload['latitude'] = `Lat: ${payload['latitude']}`;
                            payload['longitude'] = `Long: ${payload['longitude']}`
                            obj.attributes.push(...[payload['epochDateTime'], payload['longitude'],  payload['latitude'], '[COMMAND_ACKNOWLEDGED]'])
                            break;
                        case 'DEVICE_STATUS':
                            payload['epochDateTime'] = formatDate(payload['epochDateTime'], 'hh:mm:ss A');
                            payload['latitude'] = `Lat: ${payload['latitude'] ? payload['latitude'] : 'N/A'}`;
                            payload['longitude'] = `Long: ${payload['longitude'] ? payload['longitude'] : 'N/A'}`
                            payload['batteryLevel'] = `Batt: ${payload['batteryLevel']}%`
                            payload['cpuTemperature'] = `Temp: ${payload['cpuTemperature']}°C`
                            payload['memoryUsed'] = `Memory: ${payload['memoryUsed']}%`
                            obj.attributes.push(...[payload['epochDateTime'], payload['longitude'],  payload['latitude'],
                                payload['batteryLevel'], payload['cpuTemperature'], payload['memoryUsed'], '[DEVICE_STATUS]'])
                            break;
                        case 'CLONING_WIFI_ACCESS_POINT_AND_DEVICE_DETAILS':
                            payload['epochDateTime'] = formatDate(payload['epochDateTime'], 'hh:mm:ss A');
                            payload['latitude'] = `Lat: ${payload['latitude']}`;
                            payload['longitude'] = `Long: ${payload['longitude']}`
                            payload['capturedDeviceMacAddress'] = `Device: ${payload['capturedDeviceMacAddress']}`
                            payload['clonedAccessPointMacAddress'] = `AP: ${payload['clonedAccessPointMacAddress']}`
                            obj.attributes.push(...[payload['epochDateTime'], payload['longitude'],  payload['latitude'],
                                payload['capturedDeviceMacAddress'], payload['clonedAccessPointMacAddress'], '[CLONING_WIFI_ACCESS_POINT_AND_DEVICE_DETAILS]'])
                            break;
                        case 'WIFI_NETWORK_SNIFFING_AP_AND_DEVICE_DETAILS':
                            payload['epochDateTime'] = formatDate(payload['epochDateTime'], 'hh:mm:ss A');
                            payload['latitude'] = `Lat: ${payload['latitude']}`;
                            payload['longitude'] = `Long: ${payload['longitude']}`
                            payload['capturedDeviceMacAddress'] = `Device: ${payload['capturedDeviceMacAddress']}`
                            payload['clonedAccessPointMacAddress'] = `AP: ${payload['clonedAccessPointMacAddress']}`
                            obj.attributes.push(...[payload['epochDateTime'], payload['longitude'],  payload['latitude'],
                                payload['capturedDeviceMacAddress'], payload['clonedAccessPointMacAddress'], '[WIFI_NETWORK_SNIFFING_AP_AND_DEVICE_DETAILS]'])
                            break;
                        case 'WIFI_ROUTER':
                            payload['epochDateTime'] = formatDate(payload['epochDateTime'], 'hh:mm:ss A');
                            payload['latitude'] = `Lat: ${payload['latitude']}`;
                            payload['longitude'] = `Long: ${payload['longitude']}`
                            payload['macAddress'] = `Mac: ${payload['macAddress']}`
                            payload['ssidName'] = `SSID: ${payload['ssidName']}`
                            obj.attributes.push(...[payload['epochDateTime'], payload['longitude'],  payload['latitude'],
                                payload['macAddress'], payload['ssidName'], '[WIFI_ROUTER]'])
                            break;
                        case 'BLUETOOTH_DEVICE_SCAN_CAPTURE':
                            payload['epochDateTime'] = formatDate(payload['epochDateTime'], 'hh:mm:ss A');
                            payload['latitude'] = `Lat: ${payload['latitude']}`;
                            payload['longitude'] = `Long: ${payload['longitude']}`
                            payload['macAddress'] = `Mac: ${payload['macAddress']}`
                            obj.attributes.push(...[payload['epochDateTime'], payload['longitude'],  payload['latitude'],
                                payload['macAddress'], '[BLUETOOTH_DEVICE_SCAN_CAPTURE]'])
                            break;
                        case 'MOVEMENT_LOG':
                            payload['epochDateTime'] = formatDate(payload['epochDateTime'], 'hh:mm:ss A');
                            payload['latitude'] = `Lat: ${payload['latitude']}`;
                            payload['longitude'] = `Long: ${payload['longitude']}`
                            payload['groundSpeed'] = `Speed: ${Number(payload['groundSpeed']) > 0 ? `${Number(payload['groundSpeed'])}km/h` : 0}`
                            payload['headingOfMotion'] = `Heading: ${payload['headingOfMotion']}°`
                            obj.attributes.push(...[payload['epochDateTime'], payload['longitude'],  payload['latitude'],
                                payload['groundSpeed'], payload['headingOfMotion'], '[MOVEMENT_LOG]'])
                            break;
                        default:
                            if (payload.eventType && payload.eventType === 'CONNECTION_MESSAGE'){
                                obj.attributes.push('[CONNECTION_MESSAGE]')
                            }
                    }
                    if (obj.attributes.length > 0) {
                        newValues.push(obj)
                    }
                })
            }

        })

        return newValues;
    }

    useEffect(() => {
        let refreshIntervalId = setInterval( () => {
            props.timeIntervalHuntsmanAnalytics(props.deviceSerial, props.date, props.date)
        }, 10000);
        return () => {
            // Stop the interval when the component unmounts.
            // Otherwise it will keeep going and you will get an error.
            clearInterval(refreshIntervalId)
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className={'wrapper'}>
            {
                huntsmanLiveData.map((data) => {
                    return (
                        <div className={'inner'}>
                            {
                                data.attributes.map((value) => {
                                    return (
                                        <p>
                                            {value}
                                        </p>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default HuntsmanLiveDataLogTable;