import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import ExportHistoryStore from "../../stores/exportHistory.store";
import UserStore from "../../stores/user.store";
import { contentBox, primaryButton } from "../../styles";
import { Row, Col, Select, Table, Divider, DatePicker, Button, Spin } from "antd";
import ReactJson from 'react-json-view'
import { toJS } from "mobx";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import InvestigationStore from "../../stores/investigation.store";
import DeviceStore from "../../stores/device.store";
import deviceHistoryStore from "../../stores/deviceHistory.store";
import TargetStore from "../../stores/target.store";

const { RangePicker } = DatePicker;

function deviceHistoryTab(props) {
    const historyStore = useContext(deviceHistoryStore);
    const userStore = useContext(UserStore);
    const investigationStore = useContext(InvestigationStore);
    const deviceStore = useContext(DeviceStore);
    const targetStore = useContext(TargetStore);

    useEffect(async () => {
        await historyStore.UpdateHistory();
        await userStore.getUsers();
        await investigationStore.getInvestigations();
        await deviceStore.getAllDevices();
        await targetStore.getAllTargets();
    }, []);


    const columns = [
        {
            title: "Timestamp (UTC)",
            dataIndex: "created_at",
            key: "created_at",
            render: (text, record) => {
                const date = new Date(record.created_at * 1000);
                return (
                    <div>
                         {date.toISOString()}
                    </div>
                )
            }
        },
        {
            title: "Timestamp (Local)",
            dataIndex: "created_at",
            key: "created_at",
            render: (text, record) => {
                const date = new Date(record.created_at * 1000);
                return (
                    <div>
                        {date.toLocaleString()}
                    </div>
                )
            }
        },
        {
            title: "Device",
            dataIndex: "device_serial",
            key: "device_serial",
        },
        {
            title: "Target",
            dataIndex: "target",
            key: "target",
        },
    ]

    return (
        <div>
            <div
                style={contentBox({marginTop: "1px"})}
            >
                Device:
                <Divider type="vertical" />
                <Select
                    showSearch
                    style={{ width: 400 }}
                    placeholder="Any Device"
                    allowClear
                    onChange={(value) => {
                        historyStore.updateDevice(value);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={deviceStore.devices.map((device) => {
                        return {
                            label: device.serial,
                            value: device.serial
                        }
                    })}
                >
                </Select>

                <Divider type="vertical" />
                Target:
                <Divider type="vertical" />

                <Select
                    showSearch
                    style={{ width: 400 }}
                    placeholder="Any Target"
                    allowClear
                    onChange={(value) => {
                        historyStore.updateTarget(value);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={targetStore.targets.map((target) => {
                        return {
                            label: target.name,
                            value: target.name
                        }
                    })}
                ></Select>

                <Divider type="vertical" />
                Time Range:
                <Divider type="vertical" />
                <RangePicker
                    timePicker={true}
                    showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                    }}
                    allowClear={true}
                    onChange={(value) => {
                        if (value == null) {
                            historyStore.updateTimeRange([null, null])
                            return;
                        }
                        const start = value[0].unix();
                        const end = value[1].unix();
                        historyStore.updateTimeRange([start, end])
                    }}
                    style={{ width: 400 }} />
                (UTC)

                <Divider type="vertical" />
                {historyStore.spinning && <Spin />}

            </div>
            <div>
                <Row>
                    <Col span={11}
                        style={contentBox(
                            //{maxHeight: "70vh"}
                        )}
                    >
                        {historyStore.ResultSelectedEvent &&
                            <ReactJson src={toJS(historyStore.ResultSelectedEvent)} theme={"monokai"} />
                        }
                    </Col>
                    <Col span={1} />
                    <Col span={12}
                        style={contentBox()}
                    >
                        {
                        historyStore.auditHistory.length > 0 ? (
                        <Table
                            columns={columns}
                            dataSource={toJS(historyStore.displayHistory)}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { historyStore.selectEvent(record) }, // click row
                                    style: { background: historyStore.selectedEvent?.id == record.id ? "#00ddff" : "" }
                                };
                            }}
                            pagination={{
                                position: ['topRight', 'bottomRight'],
                                defaultPageSize: 10,
                                pageSizeOptions: [10, 20, 50, 100],
                            }}
                            size="medium"
                            footer={() => {
                                return (
                                    <div>
                                        <span style={{ marginRight: 10, }}>
                                            {`Total: ${historyStore.displayHistory.length}`}
                                        </span>
                                        <Button
                                            style={primaryButton()}
                                            onClick={() => {
                                                historyStore.downloadHistory();
                                            }}
                                            icon={<DownloadOutlined />}
                                        >
                                        </Button>
                                    </div>
                                )
                            }}
                        /> ) : (
                            <div>
                                Select a Device to show Device Logs

                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default observer(deviceHistoryTab);
