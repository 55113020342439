import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { IconLayer } from "@deck.gl/layers";
import React, { useEffect, useState } from "react";
import getPublicURL from "../../utilities/public-url.utility";
import useMaps from "./map";
import {convertSpeed, formatDate} from "../../util/CommonUtils";

let markersA = [];

function PointsMap({ panBy, mapPan, data, currentPosition, error, speedUnitString }) {
  const [ref, map] = useMaps({
    center: {
      lat:
        currentPosition && currentPosition.coords
          ? currentPosition.coords.latitude
          : 40.766581,
      lng:
        currentPosition && currentPosition.coords
          ? currentPosition.coords.longitude
          : -73.9872523,
    },
    zoom: 15,
    currentPosition: currentPosition,
  });
  let deckRef = React.useRef(null);

  const [hoverInfo, setHoverInfo] = useState(null);
  const [selectedIndex, setIndex] = useState(0);
  const [infoWindow, setInfoWindow] = useState(null);
  console.log(`data length ${JSON.stringify(data[1])}`);
  useEffect(() => {
    console.log("WORKING")
    data.forEach(function (t) {
      let temp = [];
      temp.push(t.longitude);
      temp.push(t.latitude);
      t.coordinates = temp;
    });

    setHoverInfo(null)
    if(infoWindow) {
      infoWindow.close()
      setHoverInfo(null)
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const layer = new IconLayer({
      id: "icon-layer",
      data,
      pickable: true,
      getIcon: (d) => ({
        url:
          d.record_type === "GPS" && d.speed >= 3
            ? `${getPublicURL()}/motion.png`
            : d.in_trip && d.in_trip === 1
            ? `${getPublicURL()}/circle_green.png`
            : `${getPublicURL()}/untitled1.svg`,
        width: d.record_type === "GPS" && d.speed >= 3 ? 128 : 100,
        height: d.record_type === "GPS" && d.speed >= 3 ? 128 : 100,
      }),
      getAngle: (d) => d.heading,
      // getBearing: d => d.heading + 120,
      sizeScale: 6,
      getPosition: (d) => d.coordinates,
      getSize: (d) => 6,
      getColor: (d) => [255, 0, 0, 255],
     /* onHover: (d) => {
        setHoverInfo(d);
        setIndex(d.index);
      },*/
      onClick: (d, event) => {
        // console.log('second', d);
        setIndex(d.index);
        setHoverInfo(d);

      },
    });

    if (!deckRef.current) {
      deckRef.current = new GoogleMapsOverlay({
        layers: [layer],
      });
    }

    if (deckRef.current) {
      deckRef.current.setMap(map.current);

      if (data && data.length > 0) {
        const points = data.map((point) => ({
          ...point,
          lng: point.longitude,
          lat: point.latitude,
        }));
        const bounds = new window.google.maps.LatLngBounds();
        points.forEach((point) => {
          bounds.extend(point);
        });

        map.current.fitBounds(bounds);
      }
    }
    // eslint-disable-next-line
  }, [map, deckRef]);

  useEffect(() => {
   

    const layer = new IconLayer({
      id: "icon-layer",
      data,
      pickable: true,
      getIcon: (d) => ({
        url:
          d.record_type === "GPS" && d.speed >= 3
            ? `${getPublicURL()}/motion.png`
            : d.in_trip && d.in_trip === 1
            ? `${getPublicURL()}/circle_green.png`
            : `${getPublicURL()}/untitled1.svg`,
        width: d.record_type === "GPS" && d.speed >= 3 ? 128 : 80,
        height: d.record_type === "GPS" && d.speed >= 3 ? 128 : 80,
      }),
      getAngle: (d) => {
        return 360 - d.heading;
      },
      // getBearing: d => d.heading + 120,
      sizeScale: 6,
      getPosition: (d) => d.coordinates,
      getSize: (d) => (d.record_type === "GPS" && d.speed >= 3 ? 6 : 4),
      getColor: (d) => [255, 0, 0, 255],
     /* onHover: (d) => {
        setHoverInfo(d);
        setIndex(d.index);
      },*/
      onClick: (d, event) => {
        // console.log('first', d);
        setIndex(d.index);
        setHoverInfo(d);

      },
    });

    if (deckRef.current) {
      deckRef.current = new GoogleMapsOverlay({
        layers: [layer],
      });
    }

    if (deckRef.current) 
    {
      if(map.current){
        deckRef.current.setMap(map.current);
      }
      
    }
  }, [map, deckRef, data]);

  useEffect(() => {
    console.log(`here`);
    if (hoverInfo && Object.keys(hoverInfo).length !== 0) {
      var marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          hoverInfo.object.latitude,
          hoverInfo.object.longitude
        ),
        map: map,
        icon: {
          path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          fillColor: "#06ff00",
          strokeColor: "#06ff00",
          fillOpacity: 0,
          strokeOpacity: 0,
          scale: 1,
        },
      });

      if (markersA && markersA.length) {
          markersA.forEach(function (mar) {
            mar.setMap(null);
          });
        }

        markersA = [];

        markersA.push(marker);

        marker.setMap(map.current);

        // console.log('selected index', selectedIndex, data);

        if(data[selectedIndex] && hoverInfo){
          let address =
              selectedIndex > 0 && data && data.length > 0 &&
              data[selectedIndex].address
                  ? data[selectedIndex].address
                  : "N/A";

          let speed = selectedIndex > 0 && data && data.length > 0 ?
              (data[selectedIndex].record_type === "WIFI" || data[selectedIndex].record_type === "CELLTOWER")
                  ?
                  data[selectedIndex].in_trip && data[selectedIndex].in_trip === 1
                      ? "In trip"
                      : "Stopped"
                  : convertSpeed(speedUnitString, data[selectedIndex].speed) || 0`${speedUnitString}`
              : "N/A"

          let latitude =
              selectedIndex > 0 && data && data.length > 0
                  ? data[selectedIndex].latitude
                  : "N/A";
          let longitude =
              selectedIndex > 0 && data && data.length > 0
                  ? data[selectedIndex].longitude
                  : "N/A";

          let dateTime =
              selectedIndex > 0 && data && data.length > 0
                  ? /*new Date(
                  Number(data[selectedIndex].device_utc_date_time) * 1000
                  ).toLocaleString()*/
                  formatDate(data[selectedIndex].device_utc_date_time, "HH:mm:ss")
                  : "N/A";

          const contentString =
              "<div>" +
              "Address: " +
              address +
              "<br/>" +
              "Longitude: " +
              longitude +
              "<br/>" +
              "Latitude: " +
              latitude +
              "<br/>" +
              "Speed: " +
              speed +
              "<br/>" +
              "Date & Time: " +
              dateTime +
              "<br/>" +
              "</div>";

          var infowindow = new window.google.maps.InfoWindow({
            content: contentString,
          });
          setInfoWindow(infowindow)
          infowindow.open(map, marker);

          // setMarker(marker)
        }
      }



    // eslint-disable-next-line
  }, [hoverInfo, map, data]);

  return (
    <React.Fragment>
      <div
        style={{
          height: "100vh",
          width: "100%",
        }}
        ref={ref}
      ></div>
    </React.Fragment>
  );
}
export default PointsMap;
