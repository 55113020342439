import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const getSubscriptionStreamsByTarget = async (requestData) => {
    try {
        console.log(requestData, "requestData")
        const response = await HTTP.get(`/notificationStreams/getStreamsByTarget/${requestData}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.streams;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateStreams = async (requestData) => {
    try {
        const response = await HTTP.post("/notificationStreams/updateStreams", {
            requestData
        });
        return Promise.resolve(response);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const updateEntityStreams = async (requestData) => {
    try {
        const response = await HTTP.post("/notificationStreams/updateEntityStreams", {
            requestData
        });
        return Promise.resolve(response);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getEntityStreams = async (requestData) => {
    try {
        const response = await HTTP.get(`/notificationStreams/getStreamsByEntity/${requestData}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.streams;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getSubscriptionStreamsByTarget,
    updateStreams,
    updateEntityStreams,
    getEntityStreams
};
