import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const authenticate = async (email, password, clientCode) => {
    try {
        const response = await HTTP.post("/user/login", {
            email: email,
            password: password,
        });

        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user[response.data.data.user.length - 1];
        const role =
      response.data.data.userRole[response.data.data.userRole.length - 1];
        const googleMapKey =
      response.data.data.google_api_key[
          response.data.data.google_api_key.length - 1
      ].key_1;
        const customObj = {
            profile: data,
            role: role,
            googleMapKey,
            isAuthenticated: true,
        };

        return Promise.resolve(customObj);
    } catch (exception) {
        return Promise.resolve({ isAuthenticated: false, errorObj:  exception.response.data});
    }
};

const forgotPassword = async (email) => {
    try {
        const response = await HTTP.put("/user/forget-password", {
            email,
        });
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.resolve(false);
    }
};

const resetPassword = async (token, password) => {
    try {
        const response = await HTTP.put(`/user/password-reset/${token}`, {
            password,
        });
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const loginRequest = async ({
    username,
    name,
    alias,
    email,
    mobile,
    serviceNumber,
    code,
    notes,
}) => {
    try {
        const response = await HTTP.post("/user/signup", {
            username,
            name,
            alias,
            email,
            mobile,
            serviceNumber,
            code,
            notes,
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user[response.data.data.user.length - 1];
        const message = response.data.message;
        return Promise.resolve({ data, message });
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateProfile = async ({
    username,
    name,
    alias,
    email,
    mobile,
    serviceNumber,
    date_format,
    utcOffset,
}) => {
    const response = await HTTP.put("/user/profile", {
        username,
        name,
        alias,
        email,
        mobile,
        serviceNumber,
        date_format,
        utcOffset,
    });

    const data = response.data.data.user[response.data.data.user.length - 1];
    return Promise.resolve(data);
};

const updatePassword = async ({ oldPassword, password }) => {
    try {
    // TODO: Come back and take a look at this better.
        await HTTP.put("/user/profile/password", {
            oldPassword,
            password,
        });

        // const data = response.data.data.user[response.data.data.user.length - 1];
        return Promise.resolve(true);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const DemoRequest = async (Data) => {
    try {
    // TODO: Come back and take a look at this better.
        console.log(Data)
        const response = await HTTP.put("/user/DemoRequest", Data);

        // const data = response.data.data.user[response.data.data.user.length - 1];
        return Promise.resolve(true);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const contactAdmin = async ({ subject, message }) => {
    await HTTP.post("/user/profile/contact", {
        subject,
        message,
    });
    return Promise.resolve(true);
};

const getDateFormats = async () => {
    try {
        const response = await HTTP.get("/user/date-format")
        return Promise.resolve(response.data.data.dateFormat)
    } catch (e) {
        return Promise.reject(e);
    }
}

const getNewRelicSettings = async () => {
    try {
        const response = await HTTP.get("/site-setting-portal/js_header");
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject(error);
    }
}

export default {
    authenticate,
    forgotPassword,
    loginRequest,
    resetPassword,
    updateProfile,
    contactAdmin,
    updatePassword,
    getDateFormats,
    getNewRelicSettings,
    DemoRequest,
};
