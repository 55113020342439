import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";
import { Parser } from "json2csv";
const fileDownload = require("js-file-download");
const parser = new Parser();

// DEBUG
window.API = API;

export class userAuditsStore {
    constructor() {
        this.api = API;
        this.auditHistory = [];

        // search options
        this.user = null;
        this.timerange = [null, null];
        this.module = null;
        this.action = null;

        this.selectedEvent = null;
        this.filterText = null;
        makeAutoObservable(this);
    }

    UpdateHistory = async () => {
        this.auditHistory = [];
        this.user = null;
        this.timerange = [null, null];
        this.module = null;
        this.action = null;
        this.selectedEvent = null;
        const response = await this.api.userAudit.getALLUserAudit();
        this.auditHistory = response;
    }

    get displayHistory() {
        let returnValue = this.auditHistory;
        if (this.module) {
            returnValue = returnValue.filter((item) => item.module_name === this.module);
        }
        if (this.action) {
            returnValue = returnValue.filter((item) => item.action === this.action);
        }
        if (this.user) {
            returnValue = returnValue.filter((item) => item.created_by === this.user);
        }
        if (this.timerange[0] && this.timerange[1]) {
            returnValue = returnValue.filter((item) => item.created_at >= this.timerange[0] && item.created_at <= this.timerange[1]);
        }
        if (this.filterText) {
            returnValue = returnValue.filter((item) =>
                JSON.stringify(item.payload).toLowerCase().includes(this.filterText.toLowerCase())
            );
        }
        return returnValue;
    }

    updateModule(module) {
        this.module = module;
    }

    updateAction(action) {
        this.action = action;
    }

    updateUser(user) {
        this.user = user;
    }

    updateTimeRange(timeRange) {
        this.timerange = timeRange;
    }

    selectEvent(event) {
        this.selectedEvent = event;
    }

    updateFilterText(text) {
        this.filterText = text;
    }

    get ResultSelectedEvent() {
        const event = this.selectedEvent;
        if (event) {
            try {
                const newPayload = JSON.parse(event.payload);
                event.payload = newPayload;
                if (event.payload.settings) {
                    const newSettings = JSON.parse(event.payload.settings);
                    event.payload.settings = newSettings;
                }
                if (event.payload.past_config) {
                    const newPastConfig = JSON.parse(event.payload.past_config[0].settings);
                    event.payload.past_config = newPastConfig;
                }
            } catch (e) {
                console.log(e);
            }
        }
        return event;
    }

    downloadHistory = async () => {
        const contents = this.displayHistory;
        // this will be downloaded as a csv file
        const csv = parser.parse(contents);
        const date = new Date();
        fileDownload(csv, `UserAuditRecord-${date.toISOString()}.csv`);
    }

}

export default createContext(new userAuditsStore());
