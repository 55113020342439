import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, message, Drawer, Switch, Checkbox, TimePicker, Tabs, Table, Divider } from 'antd'
import Icon, { ArrowLeftOutlined, StopOutlined, BorderOutlined } from '@ant-design/icons'
import { getTimeZones } from "../../util/CommonUtils";
import { contentBox, primaryButton, secondaryButton } from '../../styles';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import moment from 'moment';
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import { getGMTDateTime } from '../../util/CommonUtils';
import API from '../../services/api';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { toJS } from "mobx"
import { StartValueType } from 'tsparticles';

export default function GeofenceAdditionalWindow({ opened, onCancel, onSubmit, name, shapeSelect, type, users, alwaysActive, setAlwaysActive, investigations, setType, margin, loadview, loadedit, clearFence, setRadius, setCenter, setRectangleBounds, setActiveShape }) {

    const Circlesvg = () => (
        <svg viewBox="64 64 896 896" focusable="false" xmlns="http://www.w3.org/2000/svg" width={'30px'} height={'30px'}>
            <path fill={type === "circle" ? '#0066CC' : "black"} d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
        </svg>
    )

    //typescript interpolation pulled directly from antd docs
    const CircleIcon = (props) => (<Icon component={Circlesvg} {...props} />);

    const [investigation, setInvestigation] = useState(null)
    const [allData, setAllData] = useState(null)
    const [selectedRowKey, setSelectedRowKey] = useState([])
    const [investigationId, setInvestigationId] = useState(null)
    const [data, setData] = useState(null);
    const [activeKey, setActiveKey] = useState("1")
    const [mode, setMode] = useState("New")
    const [fromTime, setFromTime] = useState(0);

    const [form] = Form.useForm()
    const options = [
        { label: 'Sunday', value: 0 },
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
    ]

    const { TabPane } = Tabs;
    const { Option } = Select;

    const [
        {
            usersForInvestigation,
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            entitiesForInvestigation,
        },
        setSelectedInvestigation,
    ] = useInvestigationForUser();

    useEffect(() => {
        API.geofence.getGeofences().then((res) => {
            console.log(res)
            setAllData(res)
        })
    }, [])

    useEffect(() => {
        form.resetFields()
    }, [opened])

    useEffect(() => {
        if (allData) {
            allData.forEach(element => {
                if (element.id === parseInt(investigationId, 10)) {
                    console.log(element)
                    setInvestigation(element)
                }
            });
        }
    }, [allData])

    const onDelete = (e) => {
        clearFence(e)
        API.geofence.deleteGeofence(e.id).then((res) => {
            message.success("Geofence deleted successfully")
            API.geofence.getGeofences().then((res) => {
                setAllData(res)

            })
        })
    }

    const editFence = (e) => {

        setSelectedRowKey([])
        loadedit(e)
        setActiveKey("2")
        setMode("Edit")

        if (e._days.length === 0 && e._from === null && e._to === null) {
            setAlwaysActive(true)
        } else {
            setAlwaysActive(false)
        }

        form.setFieldsValue({
            name: e.name,
            type: e.type,
            users: e.user_id,
            days: e.days,
            trigger: e.trigger_type,
            timezone: e.timezone,
            target: e.target_id,
            activeDays: e._days,
            activeDuration: [e._from ? moment(e._from * 1000) : null, e._to ? moment(e._to * 1000) : null]
        })
    }

    const tabChange = e => {
        form.resetFields();
        setType('');
        shapeSelect('');
        setActiveShape(null);
        if (mode !== "Edit") {
            setActiveKey(e)
        }
    }

    if (investigation) {
        console.log(investigation)
    }

    if (!opened) {
        return null
    }

    const getTime = (offset = 0) => {
        let times = []
        for (let i = 0; i < 24; i++) {
            times.push({ value: i, label: i })
        }
        return times
    }

    return (
        <Drawer
            visible={opened}
            style={{
                boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                      0 12.5px 10px rgba(0, 0, 0, 0.06),
                      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                      0 100px 80px rgba(0, 0, 0, 0.12)`,

                marginBottom: "2px",
                width: margin,
                marginLeft: "4rem"
            }}
            placement="left"
            onClose={() => {
                setActiveKey("1")
                setType(null)
                setSelectedRowKey([])
                setMode("New")
                onCancel();
            }}
            bodyStyle={{ background: '#f8f4f4' }}
            headerStyle={{ background: '#f8f4f4' }}
            closeIcon={
                <ArrowLeftOutlined />
            }
        >
            <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Investigation"
                id={"test"}
                disabled={mode === "Edit"}
                // onSelect={this.frerender}
                filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())}
                onChange={(value) => {
                    setInvestigationId(value)
                    allData.forEach(element => {
                        if (element.id === parseInt(value, 10)) {
                            setInvestigation(element)
                            setSelectedInvestigation(element.id)
                        }
                    });
                }}
            >
                {investigationsForUser.map((investigation) => (
                    <Option
                        id={"investigation_" + investigation.id}
                        key={investigation.id}
                        label={investigation.name}
                    >
                        {investigation.name}
                    </Option>
                ))}
            </Select>
            {
                investigation ?
                    <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={tabChange}>
                        <TabPane tab="Current Geofences" key="1">
                            {investigation && investigation.geofences &&
                                <Table
                                    dataSource={investigation.geofences}
                                    size={"small"}
                                    pagination={{ pageSize: 7 }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: () => {
                                                loadview(record)

                                                const x = selectedRowKey.filter((item) => item === record.id)

                                                if (x.length === 0) {
                                                    setSelectedRowKey([...selectedRowKey, record.id])
                                                } else {
                                                    setSelectedRowKey(selectedRowKey.filter((item) => item !== record.id))
                                                }
                                            },
                                            style: { background: selectedRowKey.includes(record.id) ? '#f8f4f4' : '' }
                                        }
                                    }}
                                >
                                    <Table.Column title="Name" dataIndex="name" key="name" />
                                    <Table.Column title="Trigger" dataIndex="trigger_type" key="trigger_type" />
                                    <Table.Column title="Created Time" dataIndex="created_at" key="created_at" render={(e) => {
                                        return getGMTDateTime(e)
                                    }} />
                                    <Table.Column title="Action" key="action" render={(e) => {
                                        return (
                                            <div>
                                                <EditOutlined id={e.id} style={{ margin: '8px' }} onClick={(f) => { f.stopPropagation(); editFence(e) }} />
                                                <DeleteOutlined style={{ margin: '8px' }} onClick={(f) => { f.stopPropagation(); onDelete(e) }} />
                                            </div>
                                        )
                                    }} />
                                </Table>

                            }
                        </TabPane>
                        <TabPane tab={`${mode} Geofence`} key="2">
                            <Form
                                form={form}
                                onFinish={() => {
                                    form.validateFields().then((values) => {
                                        if (type === "rectangle" || type === "circle") {
                                            form.resetFields()
                                            onSubmit(values);
                                            setMode("New")
                                            setActiveKey("1")
                                            setActiveShape(null)

                                            API.geofence.getGeofences().then((res) => {
                                                setAllData(res)

                                            })
                                        } else {
                                            message.error("Please select a shape for the geofence!");
                                        }
                                    }).catch((info) => {
                                        console.log('Validate Failed:', info);
                                        if (info.errorFields = []) {
                                            if (type === "rectangle" || type === "circle") {
                                                form.resetFields()

                                                API.geofence.getGeofences().then((res) => {
                                                    setAllData(res)

                                                })
                                            } else {
                                                message.error("Please select a shape for the geofence!");
                                            }
                                        }
                                    })
                                }}
                            >
                                <Form.Item
                                    label="Border shape"
                                >
                                    <div>
                                        {/* <Button type="link" style={{ color: (type === "circle" ? '#0066CC' : "black") }} onClick={() => shapeSelect('circle')} icon={<CircleIcon/>}></Button> */}
                                        <Button
                                            style={{
                                                border: '1px solid #2980b9',
                                                // top: '-5px',
                                                width: '40px',
                                                height: '40px',
                                            }}
                                            onClick={() => shapeSelect('circle')}
                                        >
                                            <CircleIcon
                                                style={{
                                                    marginTop: '-5px',
                                                    marginLeft: '-10.5px',
                                                    fontSize: '30px',
                                                    color: (type === "circle" ? '#0066CC' : "black")
                                                }}
                                            />
                                        </Button>
                                        <Button
                                            style={{
                                                border: '1px solid #2980b9',
                                                width: '40px',
                                                height: '40px',
                                                marginLeft: '10px'
                                            }}
                                            onClick={() => shapeSelect('rectangle')}
                                        >
                                            <BorderOutlined
                                                style={{
                                                    marginLeft: '-10.5px',
                                                    marginTop: '-5px',
                                                    fontSize: '30px',
                                                    color: (type === "rectangle" ? '#0066CC' : "black")
                                                }}
                                            />
                                        </Button>
                                        <DeleteOutlined
                                            style={{
                                                fontSize: '30px',
                                                color: 'red',
                                                marginLeft: "150px"
                                            }}
                                            onClick={() => shapeSelect('cancel')}
                                        />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input a name for the geofence!' }]}
                                // initialValue={data.name}
                                >
                                    <Input
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Target"
                                    name="target"
                                    rules={[{ required: true, message: 'Please select a target for the geofence!' }]}
                                // initialValue={data.target_id}
                                >
                                    <Select
                                        mode="multiple"
                                        showSearch
                                    >
                                        {
                                            // console.log(data.target_id),
                                            targetsForInvestigation.map((target) => {
                                                return (
                                                    <Select.Option value={target.id}>{target.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Timezone"
                                    name="timezone"
                                    rules={[{ required: true, message: 'Please select a timezone for the geofence!' }]}
                                // initialValue={data.timezone}
                                >
                                    <Select
                                        showSearch
                                    >
                                        {getTimeZones().map((timezone) => (
                                            <Select.Option key={timezone.value}>{timezone.label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Always Active"
                                    name="alwaysActive"
                                // initialValue={data.always_active.length > 0 ? true : false}
                                >
                                    <Switch
                                        // defaultChecked={!data.activeDays}
                                        checked={alwaysActive}
                                        onChange={(checked) => {
                                            setAlwaysActive(checked)
                                        }}
                                    />
                                </Form.Item>{
                                    !alwaysActive && (
                                        <div style={contentBox()}>
                                            <Form.Item
                                                label="Active days"
                                                name="activeDays"
                                                // initialValue={data._days}
                                                rules={[{ required: true, message: 'Please select active days for the geofence!' }]}
                                            >
                                                <Checkbox.Group options={options}
                                                    // defaultValue={data._days}
                                                    style={{ "marginRight": 0, "display": "flex", "flexDirection": "column" }} />
                                            </Form.Item>
                                            <Form.Item
                                                label="Active Duration from"
                                                name="activeDurationFrom"
                                                rules={[{ required: true, message: 'Please select active duration for the geofence!' }]}
                                            >
                                                <Select
                                                    onChange={(value) => {
                                                        setFromTime(value + 1)
                                                    }}
                                                >
                                                    {getTime().map((time) => (
                                                        <Select.Option key={time.value}>{time.label}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="Active Duration To"
                                                name="activeDurationTo"
                                                rules={[{ required: true, message: 'Please select active duration for the geofence!' }]}
                                            >
                                                <Select>
                                                    {getTime(fromTime).map((time) => (
                                                        <Select.Option key={time.value}>{time.label}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    )
                                }
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"
                                        style={primaryButton()}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        style={secondaryButton({ marginLeft: 8 })}
                                        onClick={() => {
                                            setMode("New")
                                            setActiveKey("1")
                                            setActiveShape(null)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Form.Item>
                            </Form>
                        </TabPane>
                    </Tabs>
                    :
                    <h4>Please select an investigation</h4>
            }
        </Drawer>
    )
}