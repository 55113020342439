// import { Input } from "antd";
import React, {useRef, useEffect, useCallback} from "react";
// import ReactDOM from "react-dom";

const SearchBox = ({  onPlacesChanged, placeHolder }) => {
    const input = useRef(null);
    const searchBox = useRef(null);

    const handleOnPlacesChanged = useCallback(() => {
        if (onPlacesChanged) {
            onPlacesChanged(searchBox.current.getPlaces());
        }
    }, [onPlacesChanged, searchBox]);

    useEffect(() => {
        if (!searchBox.current && window.google.maps) {
            searchBox.current = new window.google.maps.places.SearchBox(input.current);
            searchBox.current.addListener('places_changed', handleOnPlacesChanged);
        }

        return () => {
            if (window.google.maps) {
                searchBox.current = null;
                window.google.maps.event.clearInstanceListeners(searchBox);
            }
        };

    // eslint-disable-next-line
  }, [window.google.maps, handleOnPlacesChanged]);

    return <input ref={input} placeholder={placeHolder} style={{ width: "100%", borderRadius: "7px", padding: "4px", border: "2px solid black" }} type="text" />;
};


export default SearchBox;
