import { Button, Divider, Table } from "antd";
import React from "react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import "./InvestigationTable.scss";
import {customSorter, formatDate} from "../../util/CommonUtils";

const { Column } = Table;

export default function ({ onDelete, onEditRecord, data, canDelete }) {
    const _onEditRecord = (record) => {
        onEditRecord(record);
    };

    const _onDeleteRecord = (record) => {
        onDelete(record);
    };

    const sortDirections = ['ascend', 'descend'];

    return (
        <Table
            dataSource={data}
            size="middle"
            rowKey="id"
            pagination={{ pageSize: 10 }}
        >
            <Column
                title="Alias"
                dataIndex="nickname"
                key="nickname"
                sorter={(a, b) =>  customSorter(a.investigation_name, b.investigation_name)}
                sortDirections={sortDirections}
            />
            <Column
                title="SSID"
                dataIndex="ssid"
                key="ssid"
                sorter={(a, b) =>  customSorter(a.name, b.name)}
                sortDirections={sortDirections}
            />
            <Column
                title="Cloned Device Mac Address"
                dataIndex="clonedMac"
                key="clonedMac"
                sorter={(a, b) => customSorter(a.device_name,b.device_name)}
                sortDirections={sortDirections}
            />
            <Column
                title="Notes"
                dataIndex="notes"
                key="notes"
                sorter={(a, b) =>  a.created_at - b.created_at }
                sortDirections={sortDirections}

            />
            <Column
                title=""
                key="action"
                render={(text, record) => (
                    <span>
                        <span>
                            <Button
                                type="link"
                                onClick={() => {
                                    _onEditRecord(record);
                                }}
                            >
                Edit
                            </Button>
                        </span>
                    </span>
                )}
            />
        </Table>
    );
}
