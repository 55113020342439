import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import API from '../../services/api';

const WiFiDeviceCapturesTable = ({ data, InvestigationID, selected, setApLatitude, setApLongitude, setApTrip, setClones, setSelected, setMainEvent, setIsLoading }) => {

    const [captures, setCaptures] = useState([]);

    useEffect(() => {
        API.wifiDevice.getCaptures(data.id).then((response) => {
            setCaptures(response);
        });
    }, [data]);

    return (
        <Table
            dataSource={captures}
            size="small"
            pagination={{ pageSize: 5 }}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setIsLoading(true);
                        setApLatitude(record.latitude);
                        setApLongitude(record.longitude);
                        setApTrip(null);
                        setClones(null);
                        setSelected(record.id);
                        setMainEvent(record.capture_type);

                        API.analysis.getWIfiDeviceLastTrip(InvestigationID, record.target_id, record.timestamp).then((res) => {
                            setApTrip(res.trip_data);
                            return (res)
                        }).then((res) => {
                            API.CloneCapture.ClonedTripCheck(InvestigationID, res.device_utc_date_time, res.stopped_date_time).then((res) => {
                                setClones(res);
                                setIsLoading(false);
                            }).catch((err) => {
                                setIsLoading(false);
                            });
                        }).catch((err) => {
                            setIsLoading(false);
                        })
                    },
                    style: (function () {
                        if (record.id === selected) {
                            return { backgroundColor: "#f8f4f4" };
                        } else {
                            return {};
                        }
                    })()
                };
            }}
        >
            <Table.Column title="Band" dataIndex="channel" key="channel"
                render={(text) => (text > 14 ? '5GHz' : '2.4GHz')}
            />
            <Table.Column title="Rssi" dataIndex="rssi" key="rssi" />
            <Table.Column title="TimeStamp" dataIndex="timestamp" key="timestamp"
                render={(text) => new Date(text * 1000).toLocaleString()}
            />
            <Table.Column title="Capture Type" dataIndex="capture_type" key="capture_type" />
            <Table.Column title="Target Name" dataIndex="name" key="name" />
        </Table>
    )
}

export default WiFiDeviceCapturesTable;