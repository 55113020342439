import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";

export class AppStore {
    constructor() {
        this.navigationCollapsed = true;
        makeAutoObservable(this);
    }

    toggleNavigation(isCollapsed) {
        this.navigationCollapsed = !this.navigationCollapsed;
    }
}

export default createContext(new AppStore());
