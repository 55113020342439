import { SettingFilled } from '@ant-design/icons';
import { Col, Row, Select, Spin, Modal, Tooltip, Button, Tabs, Card, message } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import Markdown from "react-markdown";
import gfm from 'remark-gfm';
// import TripMaps from "../../components/AnalysisDashboardMap";
import WifiMapTrips from '../../components/AnalysisMapTrips';

import DashboardStopMap from "../../components/DashboardStopMap";
import DashboardTripTable from "../../components/DashboardTripTable";
import DashboardStopTable from "../../components/DashboardStopTable";
import DashboardSettings from "../../components/DashboardSettings";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import API from "../../services/api";
import PermissionStore from "../../stores/permission.store";
import NotificationStore from "../../stores/notification.store";
import Layout from "../AppLayout";
import "./DashboardPage.scss";
import { toJS } from "mobx";
import LiveStore from "../../stores/live.store";
import { formatDate, getGMTDateTime, has } from "../../util/CommonUtils";
import { arrayMoveImmutable } from "array-move";
import { contentBox, primaryButton, secondaryButton } from '../../styles';
import DeviceTable from '../../components/DeviceTable';
import DeviceMap from '../../components/DeviceMap';
import { browserName, browserVersion } from "react-device-detect";

const prettyMS = require("pretty-ms");
const { Option } = Select;
const { TabPane } = Tabs;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


function Dashboard(props) {
    const store = useContext(PermissionStore);
    const notificationStore = useContext(NotificationStore);
    const liveStore = useContext(LiveStore);
    const [timeInterval, setTimeInterval] = useState("no-filter");
    const [trips, setTrips] = useState([]);
    const [stops, setStops] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [filteredStops, setFilteredStops] = useState([]);
    const [currentlySelectedTrip, setCurrentlySelectedTrip] = useState(null);
    const [currentlySelectedStop, setCurrentlySelectedStop] = useState(null);
    const [currentlySelectedDevice, setCurrentlySelectedDevice] = useState(null);
    const [showStopsMap, setShowStopsMap] = useState(false);
    const [liveTargets, setLiveTargets] = useState([]);
    const [speedUnitString, setSpeedUnitString] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [settings, setSettings] = useState([]);
    const [initialSettings, setInitialSettings] = useState([]);
    const [userSettingIds, setUserSettingIds] = useState([]);
    const [userSettings, setUserSettings] = useState([]);
    const [unAllocatedLicenses, setUnAllocatedLicenses] = useState([]);
    const [allLicenses, setAllLicenses] = useState([]);
    const [devices, setDevices] = useState([]);
    const [map, setMap] = useState('');
    const [headings, setHeadings] = useState([]);
    const [speeds, setSpeeds] = useState([]);
    const [cords, setCords] = useState([]);
    const [MarkerPlots, setMarkerPlots] = useState([]);

    const [
        {
            isLoading,
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            targetNameMappingList
        },
        setSelectedInvestigation,
        setSelectedTarget,
    ] = useInvestigationForUser();

    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        API.license.getAllLicenses().then((points) => {
            setAllLicenses(points);
        });
    }, []);
    useEffect(() => {
        API.license.getAllUnAllocatedLicenses().then((unAllocatedLicenses) => {
            setUnAllocatedLicenses(unAllocatedLicenses);
        });
    }, []);

    useEffect(() => {
        API.device.getByInvestigation(selectedInvestigation).then((devices) => {
            setDevices(devices);

            for (let i = 0; i < devices.length; i++) {
                API.device.getRecoveryStatus(devices[i].id).then((configs) => {
                    if (configs.length === 0) { return; }

                    // Backwards compatibility with old settings object structure
                    // DEPR: Remove once all configs have been updated
                    let settingsObj = JSON.parse(configs[0].settings);
                    if (has.call(settingsObj, 'settings')) {
                        console.log('DEPRECATION WARNING: Device configuration is using deprecated settings field. Please update your device.');
                        settingsObj = settingsObj.settings;
                    }

                    devices[i]['recovery_settings'] = Number(settingsObj.recovery);
                    devices[i]['recovery_created_at'] = settingsObj.created_at;
                    devices[i]['recovery_updated_at'] = settingsObj.updated_at;
                    devices[i]['recovery_status'] = settingsObj.status;

                })
            }
        });

        mapSelect()
    }, [selectedInvestigation]);

    useEffect(() => {
        for (let i = 0; i < devices.length; i++) {
            API.device.getDashboardGPS(devices[i].id).then((res) => {
                setCords(cords => [...cords, { lat: res.latitude, lng: res.longitude, id: res.device_id }]);
                setSpeeds(speeds => [...speeds, res.speed]);
                setHeadings(headings => [...headings, res.heading]);
            })
        }
    }, [devices])

    const onLicenseDeallocation = () => {
        // store.getAllDevices();

        setTimeout(function () {
            API.license.getAllUnAllocatedLicenses().then((unAllocatedLicenses) => {
                setUnAllocatedLicenses(unAllocatedLicenses);
            });
        }, 2000);
    };

    const handleOk = async () => {
        const updatedSettings = settings.filter(x => userSettingIds.includes(x.id)).map(x => x.id);
        if (updatedSettings.length === 0) return message.error('You have to select at least one option')
        await API.dashboard.updateDashboardSettings({
            dashSettingId: updatedSettings
        })
            .then((res) => {
                getUserDashboardSettings(initialSettings)
                message.success('Dashboard settings were successfully added')
            })
            .catch((err) => message.error(`${err.response.data.message}`))
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const markerselect = async (AP) => {
        let listedPoints = MarkerPlots;
        if (listedPoints) {
            if (listedPoints.length > 0) {
                let index = listedPoints.findIndex(point => point.id === AP.id);
                if (index >= 0) {
                    setMarkerPlots(MarkerPlots.filter(item => item.id !== AP.id));
                } else {
                    setMarkerPlots(prevAP => ([...prevAP, AP]));
                }
            } else {
                setMarkerPlots([AP]);
            }
        } else {
            setMarkerPlots([AP]);
        }
    }

    const markerdeselecter = async (AP) => {
        let listedPoints = MarkerPlots;
        if (listedPoints) {
            if (listedPoints.length > 0) {
                let index = listedPoints.findIndex(point => point.id === AP.id);
                if (index >= 0) {
                    setMarkerPlots(MarkerPlots.filter(item => item.id !== AP.id));
                } else {
                    setMarkerPlots(prevAP => ([...prevAP, AP]));
                }
            } else {
                setMarkerPlots([AP]);
            }
        } else {
            setMarkerPlots([AP]);
        }
    }

    const callback = (e) => {
        setCurrentlySelectedStop(null);
        setCurrentlySelectedDevice(null);
        setCurrentlySelectedTrip(null);

        if (userSettings[e].name === 'Stops') {
            setMap('stop')
        }

        else if (userSettings[e].name === 'Trips') {
            setMap('trip')
        }

        else if (userSettings[e].name === 'Devices') {
            setMap('device')
        }
        mapSelect()
    }

    useEffect(() => {
        async function getMessages() {
            await notificationStore.getDashboardAdminBulletins();
        }

        getMessages()
        liveStore.getLiveTargets()
        // eslint-disable-next-line
    }, [notificationStore])

    useEffect(() => {
        API.dashboard
            .getAllDashboardSettings()
            .then((res) => {
                setSettings(res.dashboardSetting);
                setInitialSettings(res.dashboardSetting)
                getUserDashboardSettings(res.dashboardSetting)
                mapSelect()
            });
        // eslint-disable-next-line
    }, [])


    const getUserDashboardSettings = (settings) => {
        API.dashboard
            .getUsersDashboardSettings()
            .then((res) => {
                setUserSettingIds(res.userDashboardSetting.map(x => x.dash_setting_id));
                setSettingsOrder(res.userDashboardSetting.map(x => x.dash_setting_id), settings)
                // setSettings(res.dashboardSetting);
            });
    }

    const setSettingsOrder = (userSettings, settings) => {
        const tempSettings = settings.filter(x => userSettings.includes(x.id))
            .sort((a, b) => userSettings.indexOf(a.id) - userSettings.indexOf(b.id))
        setUserSettings(tempSettings)
        if (userSettings[0] === 1) {
            setMap('stop')
        } else if (userSettings[0] === 2) {
            setMap('trip')
        } else {
            setMap('device');
        }
        const tempSettingIds = tempSettings.map(x => x.order_id)
        const updatedSettings = settings.filter(x => !tempSettingIds.includes(x.order_id));
        setSettings([...tempSettings, ...updatedSettings])
        mapSelect()
    }

    useEffect(() => {
        if (selectedInvestigation && investigationsForUser.length > 0) {
            const selectedInvestigationData = investigationsForUser.filter(x => Number(x.id) === Number(selectedInvestigation));

            if (selectedInvestigationData.length > 0) {
                const speedUnit = selectedInvestigationData[0].speed_unit;
                setSpeedUnitString(speedUnit);
            }
        }
    }, [selectedInvestigation, investigationsForUser])

    const previousInvestigation = usePrevious(selectedInvestigation);

    useEffect(() => {
        if (
            typeof previousInvestigation !== "undefined" &&
            selectedInvestigation !== previousInvestigation
        ) {
            setSelectedTarget([]);
        }
    }, [selectedInvestigation, previousInvestigation, setSelectedTarget]);

    useEffect(() => {
        const filteredStops = stops.filter((x) => {
            if (timeInterval && Number.isInteger(Number(timeInterval))) {
                return x.stopped >= Number(timeInterval);
            } else {
                return true;
            }
        });
        setFilteredStops(filteredStops);
    }, [stops, timeInterval]);

    useEffect(() => {
        if (selectedTarget && selectedInvestigation) {
            API.analysis
                .getTrips({
                    investigation_id: selectedInvestigation,
                    target_id: selectedTarget,
                    to: moment().unix(),
                    from: moment()
                        .subtract(24 * 3, "hours")
                        .unix(),
                    isDashboard: true
                })
                .then((newTrips) => { setTrips(newTrips); })
                .catch((err) => { console.err(err); });
        }
    }, [selectedInvestigation, selectedTarget]);

    useEffect(() => {
        if (selectedTarget && selectedInvestigation) {
            API.analysis
                .getStops({
                    investigation_id: selectedInvestigation,
                    target_id: selectedTarget,
                    to: moment().unix(),
                    from: moment()
                        .subtract(24 * 3, "hours")
                        .unix(),
                    isDashboard: true
                })
                .then((stops) => {
                    setStops(stops);
                });
        }
    }, [selectedInvestigation, selectedTarget]);

    useEffect(() => {
        if (selectedTarget && selectedInvestigation) {
            const investigation = toJS(liveStore.liveTargetsByInvestigation).filter(x => x.id === Number(selectedInvestigation));
            if (investigation.length > 0) {
                const selectedTargetIds = selectedTarget.map(x => Number(x))
                const targets = investigation[0].target.filter(target => selectedTargetIds.includes(target.target_id));
                setLiveTargets(targets.length > 0 ? targets : [])
            }
        }
        // eslint-disable-next-line
    }, [selectedInvestigation, selectedTarget]);

    useEffect(() => {
        if (selectedTarget && selectedInvestigation) {
            API.alerts
                .getDashboardAlertLogs({
                    investigation_id: selectedInvestigation
                })
                .then((alerts) => {
                    setAlerts(alerts);
                });
        }
    }, [selectedInvestigation, selectedTarget]);

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        store.getPermissionByRoleIdTest(JSON.parse(localStorage.getItem("role")));
        // eslint-disable-next-line
    }, []);

    // Automatically select an option when the select boxes load

    useLayoutEffect(() => {
        if (!isLoading && investigationsForUser.length > 0) {
            const _currentInvestigation =
                localStorage.getItem("defaultInvestigation") ||
                investigationsForUser[investigationsForUser.length - 1].id.toString();
            setSelectedInvestigation(_currentInvestigation);
        }
    }, [
        investigationsForUser,
        setSelectedInvestigation,
        isLoading,
        selectedTarget,
        setSelectedTarget,
    ]);

    useLayoutEffect(() => {
        if (!isLoading && targetsForInvestigation.length > 0) {
            if (!selectedTarget) {
                const _currentTarget =
                    localStorage.getItem("defaultTarget") ||
                    targetsForInvestigation[
                        targetsForInvestigation.length - 1
                    ].id.toString();
                setSelectedTarget([..._currentTarget.split(",")]);
            }
        }
    }, [targetsForInvestigation, setSelectedTarget, isLoading, selectedTarget]);

    const onDeleteRecord = (record) => {
        store.deleteDevice(record);
        store.getAllDevices();
    };

    const stopColumns = [
        // { title: "Trip ID", dataIndex: "id", key: "id" },

        {
            title: "Target",
            dataIndex: "target_name",
            key: "target_id",
            render: (text, record) => {
                return targetNameMappingList[record?.target_id?.toString()]
            },
        },
        {
            title: "Date",
            dataIndex: "device_utc_date_time",
            key: "device_utc_date_time",
            render: (text, record) => {
                return getGMTDateTime(record.device_utc_date_time, false)
            },
        },
        {
            title: "Heading",
            dataIndex: "heading",
            key: "heading",
            render: (text, record) => {
                return `${text}°`;
            },
        },
        {
            title: "Duration",
            dataIndex: "stopped",
            key: "stopped",
            render: (text, record) => {
                return prettyMS(Number(text) * 1000 || 0);
            },
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
    ];

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setSettings(arrayMoveImmutable(settings, oldIndex, newIndex))
    };

    const onChange = (checkedValues) => {
        setUserSettingIds(checkedValues)
    }

    const getDashboardComponents = (module) => {
        switch (module) {
            case "Stops":
                return (
                    <Card style={{ height: `100%`, overflow: 'auto' }} onClick={() => { setMap('stop') }}>
                        <Select
                            defaultActiveFirstOption
                            defaultValue={timeInterval}
                            onChange={(interval) => {
                                setTimeInterval(interval);
                            }}
                            showSearch
                            style={{
                                width: "100%",
                                paddingBottom: "5px",
                            }}
                            placeholder="No Filter"
                        >
                            <Option key={"no-filter"}>No Filter</Option>
                            <Option key={300}>5 Min</Option>
                            <Option key={900}>15 Min</Option>
                            <Option key={3600}>1 Hour</Option>
                            <Option key={14400}>4 Hours</Option>
                            <Option key={43200}>12 Hour</Option>
                            <Option key={86400}>24 Hours</Option>
                        </Select>

                        <DashboardStopTable
                            isLoading={isLoading}
                            targetNameMappingList={targetNameMappingList}
                            filteredStops={filteredStops}
                            stopColumns={stopColumns}
                            onStopClick={(record) => {
                                setCurrentlySelectedStop(record);
                            }}
                            currentlySelectedStop={currentlySelectedStop}
                        />
                    </Card>
                )
            case "Trips":
                return (
                    <Card style={{ height: `100%`, overflow: 'hidden' }} onClick={() => { setMap('trip') }}>
                        <div></div>
                    </Card>
                )
            case "Notifications":
                return (
                    null
                )
            case "Admin Bulletin":
                return (
                    <Card style={{ height: `63vh`, overflow: 'auto' }}>
                        {toJS(notificationStore.dashboardAdminBulletins).length <= 0 ? (
                            <p style={{ textAlign: "center" }}>
                                No bulletins to view.
                            </p>
                        ) : (
                            <div>
                                <div className="chat">
                                    <div className="yours messages">
                                        {toJS(notificationStore.dashboardAdminBulletins).map((message) => {
                                            return (
                                                <div className="message last" key={message.id}>
                                                    <div className="sender">{message.sender}</div>
                                                    {message.message !== "null" ? (
                                                        <Markdown source={message.message} />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <div className="date">
                                                        {
                                                            formatDate(message.timestamp, "hh:mm:ss A")
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                )
            case "Devices":
                return (
                    <Card style={{ height: `63vh`, overflow: 'auto' }} onClick={() => {setMap('device')}}>
                        <DeviceTable
                            canDelete={store.can("device.delete")}
                            router={props.history}
                            onDelete={onDeleteRecord}
                            data={devices}
                            unallocatedLicenses={unAllocatedLicenses}
                            onDeviceClick={setCurrentlySelectedDevice}
                            currentlySelectedDevice={currentlySelectedDevice}
                            allLicenses={allLicenses}
                            onLicenseDeallocationClick={onLicenseDeallocation}
                            deviceRecoveryPermission={!store.can("config.dmRecovery")}
                            lite={true}
                        />
                    </Card>
                )
            default:
                console.log("No component available")
        }
    }

    const mapSelect = () => {
        switch (map) {
            case 'trip':
                return (
                    <div></div>
                )

            case 'stop':
                return (
                    <DashboardStopMap
                        data={filteredStops}
                        panBy={[0, 0]}
                        selectedPoint={currentlySelectedStop}
                        style={{
                            height: "74vh",
                            width: "100%",
                            position: "relative",
                        }}
                        liveTargets={liveTargets}
                        speedUnit={speedUnitString}
                    />
                )

            case 'device':
                return (
                    <DeviceMap
                        deviceCords={cords}
                        speed={speeds}
                        heading={headings}
                        board={true}
                        currentlySelectedDevice={currentlySelectedDevice}
                        devices={devices}
                    />
                )

            default:
                break;
        }
    }
    return (
        <Layout
            showNavigation
            title={`Dashboard - ${JSON.parse(localStorage.getItem("profile")).client_name}`}
            description={"Location Data for the Past 72 Hour."}
            dashboardPage={true}
        >
            <Row style={{ paddingBottom: "10px" }}>
                <Col span={8} className={'select-space'}>
                    <Select
                        id={"investigationselect"}
                        defaultActiveFirstOption
                        showSearch
                        value={selectedInvestigation}
                        style={{ width: "100%" }}
                        placeholder="Investigations"
                        disabled={isLoading}
                        onChange={(selectedInvestigation) => {
                            setSelectedInvestigation(selectedInvestigation);
                            localStorage.setItem(
                                "defaultInvestigation",
                                selectedInvestigation
                            );
                        }}
                        filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase()) }
                    >
                        {investigationsForUser.map((investigation) => (
                            <Option
                                id={"investigation_" + investigation.id}
                                key={investigation.id}
                                label={investigation.name}
                            >
                                {investigation.name}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={16} className={'select-space'}>
                    <Select
                        id={"targetselect"}
                        value={selectedTarget}
                        mode="tags"
                        size='default'
                        showSearch
                        maxTagCount={5}
                        style={{ width: "100%" }}
                        placeholder="Targets"
                        onChange={(value) => {
                            if (Array.isArray(value) && value.length > 5) {
                                message.error("Cannot select more than 5 targets at a time in the Dashboard.");
                            } else {
                                setSelectedTarget(value);
                                localStorage.setItem("defaultTarget", value);
                            }
                        }}
                    >
                        {targetsForInvestigation.map((target) => {
                            return (
                                <Option
                                    id={"target_" + target.id}
                                    key={target.id}
                                >
                                    {target.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>

            <Row style={{ marginBottom: "10px", paddingBottom: "0" }}>
                <Col span={12} style={{ paddingRight: "10px" }}>
                    <div
                        style={contentBox({ padding: "0" })}
                    >
                        {showStopsMap === false && (
                            <div
                                style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "74vh"
                                }}
                            >
                                <Spin></Spin>
                            </div>
                        )}
                        {
                            showStopsMap === true && mapSelect()
                        }
                    </div>
                </Col>
                <Col span={12} style={{ paddingRight: "10px" }}>
                    <div
                        style={contentBox({ height: '74vh', paddingTop: "1rem", paddingBottom: "1rem" })}
                    >
                        <div style={{ height: `60vh` }}>
                            {
                            userSettings.length > 0 ? (
                                <Tabs defaultActiveKey="0" onChange={callback} tabBarExtraContent={(
                                    <Tooltip title="Dashboard Settings">
                                        <Button style={primaryButton({})} shape="circle" icon={<SettingFilled />} onClick={showModal} />
                                    </Tooltip>
                                )}>
                                    {
                                    userSettings.map((item, index) => {
                                        return (
                                            <TabPane tab={item.name} key={index} >
                                                {getDashboardComponents(item.name)}
                                            </TabPane>
                                        )
                                    })
                                }
                                </Tabs>
                            ) : (
                                    <div style={{
                                        padding: '150px'
                                    }}>
                                        <Row style={{
                                            justifyContent: 'center',
                                            marginBottom: '10px'
                                        }}>
                                            <SettingFilled style={{
                                                fontSize: '150px',
                                                color: '#67676738'
                                            }} />
                                        </Row>
                                        <Row style={{
                                            justifyContent: 'center'
                                        }}>
                                            <p>Setup your dashboard <button className='link-button' onClick={showModal}><b>Click Here</b></button></p>
                                        </Row>
                                    </div>
                                )
                        }

                        </div>
                    </div>
                </Col>
            </Row>

            <Modal
                title="Dashboard Settings"
                visible={isModalVisible}
                width={700}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ style: primaryButton() }}
                cancelButtonProps={{ style: secondaryButton() }}
            >
                <DashboardSettings
                    settings={settings}
                    onSortEnd={onSortEnd}
                    onChange={onChange}
                    userSettingIds={userSettingIds}
                    />
            </Modal>
        </Layout>
    );
}


const printAlertMessage = (data) => {

    if (data.incoming_msg_sub) {
        return (
            <>
                <div className="sender">{data.incoming_msg_sub}</div>
                <Markdown linkTarget="_blank" plugins={[gfm]} source={data.incoming_msg} />
                </>
        )
    } else {
        return (
            <em>
                {data &&
                    data.incoming_msg &&
                    data.incoming_msg
                        .trim()
                        .split("\n")
                        .reverse()
                        .map((item, idx) => (
                            <p key={idx} dangerouslySetInnerHTML={{ __html: item.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='_blank' >$1</a>") }}>

                            </p>
                        ))}
            </em>
        );
    }
}

export default observer(Dashboard);
