import { Button, Divider, Table } from "antd";
import React from "react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import "./TemplateTable.scss";

const { Column } = Table;

export default function({ data, onDelete, onEditRecord, canEdit, canDelete }) {
    const _onEditRecord = record => {
        onEditRecord(record);
    };

    const _onDeleteRecord = record => {
        onDelete(record);
    };

    return (
        <Table
            dataSource={data}
            rowKey="id"
            size="middle"
            pagination={{ pageSize: 10 }}
        >
            <Column title="Name" dataIndex="name" key="name" />
            <Column title="Description" key="description" dataIndex="description" />

            <Column
                title=""
                key="action"
                render={(text, record) => (
                    <span>
                        <span>
                            <Button
                                id={"template-edit-btn"}
                                disabled={canEdit}
                                type="link"
                                onClick={() => {
                                    _onEditRecord(record);
                                }}
                            >
                Edit
                            </Button>
                        </span>

                        <span>
                            <Divider type="vertical" />
                            <DeleteConfirmationModal
                                title={`Are you sure you want to delete '${record.name}'?`}
                                content={
                                    "Deletion of template will not do any harm but will be removed from the system. Press ok to proceed."
                                }
                                onClickCancel={() => {}}
                                onClickOkay={() => {
                                    _onDeleteRecord(record);
                                }}
                            >
                                <Button type="link" size="small" id={"template-delete-btn"} disabled={canDelete}>
                  Delete
                                </Button>
                            </DeleteConfirmationModal>
                        </span>
                    </span>
                )}
            />
        </Table>
    );
}
