import { Badge, Table, Tooltip, Space, Tag, Divider } from "antd";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';
import React, { useContext, useState, useEffect } from "react";
import "./DeviceTable.scss";
import TimeAgo from "react-timeago";

// liveMode: 1,
// loggingMode: 2,
// monitorMode: 3,
// default: 4,
// custom: 5,

export default function ConfigTag(props) {
    const { record } = props;
    const config = record.configuration_mode;

    const colourDict = {
        "none": "success",
        "pending": "processing",
        "rejected": "error",
    }

    const iconDict = {
        "none": <CheckCircleOutlined />,
        "pending": <SyncOutlined spin />,
        "rejected": <ExclamationCircleOutlined />,
    }
    let liveoverride = false;

    const configcheck = (config, record, next = false) => {
        const current = Math.floor(Date.now() / 1000);
        const expiryDate = parseInt(record.recovery_settings);
        // working out live mode expiry date
        const yearofseconds = 86400 * 365;
        let extra = null;
        if ((expiryDate < yearofseconds) && (parseInt(record.recovery_status,10) === 2) ) {
            // this is basically always on
            const display = new Date((parseInt(record.device_updated_at) + parseInt(record.recovery_settings)) * 1000);
            extra = <Tag icon={<ClockCircleOutlined />} color="volcano" style={{ minWidth: "10vh" }}> <TimeAgo date={display} /></Tag>
        }
        if (((parseInt(record.recovery_status, 10) === 2) && (current < (record.recovery_updated_at + record.recovery_settings)))) {
            config = 1;
            liveoverride = true;
        }
        if((parseInt(record.recovery_status,10) === 1) && (current < (record.recovery_updated_at + record.recovery_settings))) {
            if (record.recovery_settings > 300){
                config = 1;
                liveoverride = true;
            }
        }

        switch (config) {
            case 0:
                return <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "30vh" }}> No Config</Tag>
            case 1: {
                if (!extra) {
                    return <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "30vh" }}> Live Mode</Tag>
                }
                return (
                    (Date.now() / 1000 < (record.recovery_updated_at + record.recovery_settings)) ?
                    <div>
                        <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "14vh" }}> Live Mode</Tag>
                        <Divider type="vertical" />
                        {extra}
                    </div>
                    :
                    <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "30vh" }}> Store And Forward</Tag>
                )
            }
            case 3:
                return <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "30vh" }}> Monitoring</Tag>
            case 2:
                return <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "30vh" }}> Logging</Tag>
            case 4:
                return <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "30vh" }}> Default</Tag>
            case 5:
                return <Tag icon={iconDict[record.configPending]} color={colourDict[record.configPending]} style={{ minWidth: "30vh" }}> Store And Forward</Tag>
            default:
                return <Tag icon={<MinusCircleOutlined />} color="default" style={{ minWidth: "30vh" }}> N/A</Tag>
        }
    }
    return (
        <div>
            {configcheck(config, record)}
            {/* <Divider type="vertical" />
            {liveModeIndicator(record)} */}
        </div>
    )
}