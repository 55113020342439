import React, { Component } from "react";
import { Button, Result } from "antd";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            return (
                <Result
                    status="500"
                    title="Something went wrong."
                    subTitle="Our team has been notified about it and is fixing it."
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                window.location = "/login";
                            }}
                        >
                  Back Home
                        </Button>
                    }
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
