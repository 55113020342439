import { Button, Form, Input, message, Modal, Switch } from "antd";
import React, { Component } from "react";
import API from "../../services/api";
import { secondaryButton, primaryButton } from "../../styles";
import FederationWrapper from "../FederationWrapper";

export class FederationModal extends Component {

    formRef = React.createRef()

    constructor() {
        super()

        this.state = {
            federation: null,
            enabled: null,
        }
    }

    handlesubmit(e, device_id, close) {
        e.preventDefault();
        const formValues = {
            ip_address: this.formRef.current.getFieldValue('ipaddr'),
            port: this.formRef.current.getFieldValue('port'),
            device_id: device_id,
            enabled: this.formRef.current.getFieldValue('status') === true ? 1 : 0
        }
        console.log(formValues);

        if (formValues.ip_address === null || formValues.port === null) {
            message.error('Please enter all fields')
            return
        }

        API.federation.create(formValues).then(res => {
            if (!res.error) {
                message.success('Device federation COT updated')
                this.setState({enabled: !this.state.enabled})

            } else {
                message.error('Device Federation COT not updated')
            }
        })

        close()
    }

    componentDidMount() {
        API.federation.getFederation(this.props.device_id).then((res) => {
            // console.log(res)
            if (res.federation.length === 0) {
                this.setState({
                    federation: {
                        ip_address: null,
                        port: null,
                        enabled: null
                    }
                })
            } else {
                this.setState({ federation: res.federation[0] })
            }
            if (this.state.federation !== null) {
                this.setState({
                    enabled: this.state.federation.enabled
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    render() {
        return (
            <Form id={this.props.device_id} onFinish={(e) => this.handlesubmit(e, this.props.device_id, this.props.cancel)} ref={this.formRef}>
                <Modal
                    visible={this.props.isVisible}
                    onCancel={this.props.cancel}
                    title="Configure Federation COT"
                    footer={[
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={this.props.cancel}
                                style={secondaryButton({ width: '47%' })}
                            >
                                Cancel
                            </Button>
                            <Button
                                // onclick={this.handlesubmit}
                                style={primaryButton({ width: '47%' })}
                                form={this.props.device_id}
                                onClick={(e) => this.handlesubmit(e, this.props.device_id, this.props.cancel)}
                                key='submit'
                            >
                                Submit
                            </Button>
                        </div>
                    ]}
                >
                    {this.state.federation &&
                        <div>
                            <Form.Item
                                name='ipaddr'
                                rules={[{
                                    required: true,
                                    pattern: new RegExp('((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))'),
                                    message: "Valid ip v4 or ip v6 address not detected"
                                }]}
                                label="IP Address"
                                initialValue={this.state.federation.ip_address}
                            >
                                <Input disabled={this.state.enabled}></Input>
                            </Form.Item>
                            <Form.Item
                                name='port'
                                rules={[{
                                    required: true,
                                    pattern: new RegExp('^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$'),
                                    message: "Valid port not detected"
                                }]}
                                label="Port Number"
                                initialValue={this.state.federation.port}
                            >
                                <Input disabled={this.state.enabled}></Input>
                            </Form.Item>
                            <Form.Item
                                name='status'
                                label="Enable Federation"
                            >
                                <Switch defaultChecked={this.state.federation.enabled} />
                            </Form.Item>
                        </div>
                    }
                </Modal>
            </Form>
        )
    }
}
