import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, Row, Select, Spin, Table, Switch } from "antd";
import { toJS } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import API from "../../services/api";
import DeviceStore from "../../stores/device.store";
import AnalyticsAdditionalInfoDialog from "../AnalyticsAdditionalInfoDialog";
import HuntsmanLiveDataLogTable from "../HuntsmanLiveDataLogTable";
import { formatDate, getUTCDateTime } from "../../util/CommonUtils";
import { secondaryButton, primaryButton, contentBox } from '../../styles';

function DeviceAuditTab(props) {
    const deviceStore = useContext(DeviceStore);

    const [showDropDown, setShowDropDown] = useState(false);
    const [deviceSerial, setDeviceSerial] = useState(null);
    const [date, setDate] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isLiveMode, setIsLiveMode] = useState(false);

    useEffect(() => {
        async function getDevice() {
            await deviceStore.getAllDevices().then((res) => {
                setShowDropDown(true);
            });
        }

        getDevice();
        // eslint-disable-next-line
    }, []);

    const dataSource = [];
    if (props.analytics && props.analytics.length > 0) {
        props.analytics.forEach((item) => {
            // const localDate = new Date(item.epochDate);
            dataSource.push({
                key: item.epochDate,
                // date: moment(item.epochDate).utc().format(`${JSON.parse(localStorage.getItem("profile")).date_format} HH:mm:ss`),
                date: getUTCDateTime((item.epochDate / 1000)),
                localDate: formatDate((item.epochDate / 1000), 'HH:mm:ss'),
                ipAddress: item.ipAddress,
                serialNumber: item.serialNumber,
                incomingType: item.incomingType,
                outgoingType: item.outgoingType,
                serializedPayload: item.serializedPayload,
            });
        });
    }

    const sortedDataSource = dataSource.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
    );

    const [isVisible, setIsVisible] = useState(false);
    const [payload, setPayload] = useState({});

    const analyticsAdditionalView = (record) => {
        setIsVisible(true);
        setPayload(JSON.parse(record.serializedPayload));
    };

    const onModalCancelClicked = () => {
        setIsVisible(false);
    };

    const exportData = async () => {
        const fromConverted = date.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = date.toDate();
        toConverted.setHours(23, 59, 59);
        const response = await API.analytics.getAllAnalytics(
            deviceSerial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            0,
            keyword,
            1
        );

        const updatedData = response.deviceAnalytic.map(x => {
            return {
                localDate: ` ${formatDate((x.epochDate / 1000), 'HH:mm:ss')}`,
                UTCDate: ` ${getUTCDateTime((x.epochDate / 1000))}`,
                ipAddress: x.ipAddress,
                serialNumber: x.serialNumber,
                incomingType: x.incomingType,
                outgoingType: x.outgoingType,
                serializedPayload: x.serializedPayload,
            }
        })

        await API.analytics.downloadAnalyticsDump(updatedData);
        //await API.analytics.downloadAnalyticsDump(sortedDataSource);
    };

    const exportDataForCurrentPage = async () => {
        let updatedData = sortedDataSource.map(x => {
            return {
                localDate: ` ${x.localDate}`,
                UTCDate: ` ${x.date}`,
                ipAddress: x.ipAddress,
                serialNumber: x.serialNumber,
                incomingType: x.incomingType,
                outgoingType: x.outgoingType,
                serializedPayload: x.serializedPayload,
            }
        })
        await API.analytics.downloadAnalyticsDump(updatedData);
    }

    const handleSubmit = (e) => {
        props.form.validateFields((error, values) => {
            if (!error) {
                setDeviceSerial(values.deviceSerial);
                setDate(values.timeRange);
                setKeyword(values.keyword);
                if (isLiveMode) {
                    props.onLiveModeClicked(values.deviceSerial, values.timeRange, values.timeRange)
                }
                props.onFilterClicked(
                    values.deviceSerial,
                    values.timeRange,
                    values.timeRange,
                    0,
                    values.keyword
                );
            }
        });
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        const offset = (pagination.current - 1) * 50;
        props.onFilterClicked(deviceSerial, date, date, offset, keyword);
    };

    const onChange = (checked) => {
        setIsLiveMode(checked)
        if (checked) {
            props.onLiveModeClicked(deviceSerial, date, date)

            // setIntervalId(refreshIntervalId)
        } else {
            /*if (intervalId) {
        clearInterval(intervalId);
      }*/
        }
        console.log(`switch to ${checked}`);
    }

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            responsive: ['md'],
        },
        {
            title: "Local Date",
            dataIndex: "localDate",
            key: "localDate",
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            key: "serialNumber",
        },
        {
            title: "Incoming Type",
            dataIndex: "incomingType",
            key: "incomingType",
        },
        {
            title: "Outgoing Type",
            dataIndex: "outgoingType",
            key: "outgoingType",
        },
        {
            title: "IP Address",
            dataIndex: "ipAddress",
            key: "ipAddress",
        },
        {
            title: "Payload",
            key: "payload",
            render: (text, record) => {
                if (record.serializedPayload !== null) {
                    return (
                        <Button
                            id={record.key}
                            type="primary"
                            onClick={() => {
                                analyticsAdditionalView(record);
                            }}
                        >
                            View
                        </Button>
                    );
                }
            },
        },
    ];
    const { getFieldDecorator } = props.form;
    return (
        <React.Fragment>
            <div style={contentBox()}>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Device
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("deviceSerial", {
                                    // onChange: props.onDateRangeSelect,
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select a Device!",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.indexOf(input) >= 0
                                        }
                                        disabled={showDropDown !== true}
                                    >
                                        {showDropDown === true &&
                                            toJS(
                                                deviceStore.devices.map((device) => {
                                                    return (
                                                        <Select.Option
                                                            key={device.id}
                                                            id={device.id}
                                                            value={device.serial}
                                                        >
                                                            {device.serial}
                                                        </Select.Option>
                                                    );
                                                })
                                            )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Date
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("timeRange", {
                                    onChange: props.onDateRangeSelect,
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select a date!",
                                        },
                                    ],
                                })(<DatePicker style={{ width: "100%" }} />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <label> Keyword</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("keyword", {
                                    initialValue: "",
                                })(
                                    <Input
                                        type="text"
                                        placeholder="Search Keyword"
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            textAlign: "end",
                        }}
                    >
                        <Button
                            id={"device-analytics-search-btn"}
                            onClick={() => {
                                handleSubmit();
                            }}
                            style={primaryButton({ marginLeft: "auto" })}
                            key="filrer"
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                exportData();
                            }}
                            id={"export-analytics-btn"}
                            disabled={props.analytics && props.analytics.length === 0}
                            style={secondaryButton({ marginLeft: "3px" })}
                        >
                            Export day
                        </Button>
                        <Button
                            onClick={() => {
                                exportDataForCurrentPage();
                            }}
                            disabled={props.analytics && props.analytics.length === 0}
                            style={secondaryButton({ marginLeft: "3px" })}
                        >
                            Export current page
                        </Button>
                    </Row>
                    <hr />
                </Form>
                <Row style={{
                    justifyContent: "flex-end",
                }}>
                    <label style={{
                        paddingRight: '10px'
                    }}>Live Mode</label>
                    <Switch onChange={onChange} disabled={props.analytics && props.analytics.length === 0} />
                </Row>
                <br></br>
            </div>
            <div>
                <AnalyticsAdditionalInfoDialog
                    modalVisible={isVisible}
                    onCancelClick={onModalCancelClicked}
                    data={payload}
                />
            </div>
            <div
                style={contentBox()}
            >
                {
                    props.spinnerVisible === true ? (
                        <div
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "5%",
                            }}
                        >
                            <Spin size={"large"} />
                        </div>
                    ) : !isLiveMode ? (
                        <Table
                            dataSource={sortedDataSource}
                            columns={columns}
                            onChange={handleTableChange}
                            pagination={{
                                position: ['topRight', 'bottomRight'],
                                pageSize: 50,
                                current: currentPage,
                                total: props.analyticsCount,
                            }}
                            scroll={{ y: 550 }}
                        />
                    ) : (<HuntsmanLiveDataLogTable
                        data={props.huntsmanData}
                        timeIntervalHuntsmanAnalytics={props.timeIntervalHuntsmanAnalytics}
                        deviceSerial={deviceSerial}
                        date={date}
                    />)
                }
            </div>
        </React.Fragment >
    );
}

export default Form.create({})(DeviceAuditTab);
