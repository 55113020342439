import React from "react";
import PropTypes from "prop-types";
import { Form, Modal, Input } from "antd";

export default class WarningModal extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.checkConfirm = (typeof this.props.confirmInput === 'string');
        this.state = { formValid: !this.checkConfirm, }
    }

    confirmInput(text) {
        return (
            <Form
                ref={this.formRef}
                onFieldsChange={() => {
                    this.setState({
                        formValid: (this.formRef.current.getFieldError('confirmText').length <= 0)
                    });
                }}
            >
                <p>Enter the text '{text}' below to confirm.</p>
                <Form.Item
                    name="confirmText"
                    rules={[{
                        enum: [text],
                        type: 'enum',
                        message: "Input does not match text",
                    }]}
                >
                    <Input placeholder="Confirm text..." />
                </Form.Item>
            </Form>
        );
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                okButtonProps={{disabled: !this.state.formValid}}
                zIndex={2000}
                onOk={(e) => {
                    e.preventDefault();
                    if (this.checkConfirm) {
                        this.formRef.current.validateFields(['confirmText'])
                            .then(() => {
                                this.props.onOk();
                                this.props.close();
                            });
                    } else {
                        this.props.onOk();
                        this.props.close();
                    }
                }}
                onCancel={() => { this.props.onCancel(); this.props.close(); }}
                title={<h3>Warning</h3>}
                closable={false}
            >
                <h5>{this.props.title}</h5>
                <p>{this.props.message}</p>
                <br />
                {this.checkConfirm ? this.confirmInput(this.props.confirmInput) : null}
            </Modal>
        );
    }
}

WarningModal.propTypes = {
    confirmInput: PropTypes.string,
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    close: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
};

WarningModal.defaultProps = {
    confirmInput: null,
    onOk: () => {},
    onCancel: () => {},
    close: () => {},
    title: 'Warning',
    message: 'This is a warning.',
};
