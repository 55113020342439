import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import RequestAccessForm from "../../components/RequestAccessForm";
import AuthStore from "../../stores/auth.store";
import getPublicURL from "../../utilities/public-url.utility";
import "./RequestAccessPage.scss";

function RequestAccessPage(props) {
    const store = useContext(AuthStore);
    return (
        <div className="RequestAccess">
            <img
                className="front-logo"
                alt="logo"
                src={`${getPublicURL()}/atrax_logo.png`}
            ></img>
            <div className="access-text">
                <strong>Think you should have access to the ATRAX system?</strong>
                <div>
                    <p>
            Fill in the form with your details and an organizational admin will
            validate your request and grant you access if required.
                    </p>
                </div>
            </div>
            <RequestAccessForm
                onBack={() => {
                    props.history.push("/login");
                }}
                onSubmit={(data) => {
                    store.sendUserRequest(data);
                    props.history.push("/login");
                }}
            ></RequestAccessForm>
        </div>
    );
}

export default observer(RequestAccessPage);
