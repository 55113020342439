import React from "react"
import { Alert, Button, Input, Select, Tag, Tooltip, Typography, Switch, Form, Slider, Col, Row } from "antd"
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { format } from "date-fns";

const onSlideChange = (checked, item) => {

    return item.valueListFields[checked].name
}

const ComponentSelect = (item, settings) => {
    const { Option } = Select;
    if (item.hasOwnProperty("valueListFields")) {
        const check = item.valueListFields.length
        switch (true) {
            case (check === 2):
                let defaultState = false
                if (settings[item.name] == item.valueListFields[0].value) {
                    defaultState = true
                }
                return (
                    <Switch checkedChildren={item.valueListFields[0].name} unCheckedChildren={item.valueListFields[1].name} defaultChecked={defaultState} />
                )

            case (check > 10):
                const limit = item.valueListFields.length - 1;
                return (
                    <Slider
                        tipFormatter={(e) => onSlideChange(e, item)}
                        min={0}
                        max={item.valueListFields.length - 1}
                        marks={{
                            0: item.valueListFields[0].name,
                            [limit]: item.valueListFields[limit].name,
                            [Math.floor(limit / 2)]: item.valueListFields[Math.floor(limit / 2)].name,
                            [Math.floor(limit / 4)]: item.valueListFields[Math.floor(limit / 4)].name,
                            [Math.floor(limit / 4 * 3)]: item.valueListFields[Math.floor(limit / 4 * 3)].name,
                        }}
                    >
                    </Slider>
                )

            default:
                return (
                    <Select
                        // defaultValue={settings[item.name].toString()}
                        style={{ maxWidth: "200px" }}
                    >
                        {item.valueListFields.map((entry) => (
                            <Option value={entry.value.toString()}>
                                {entry.name}
                            </Option>
                        ))}
                    </Select>
                )

        }
    } else {
        return (
            <Input
                type="text"
                placeholder={`${item.displayName}`}
                style={{ maxWidth: "100px" }}
            // defaultValue={settings[item.name].toString()}
            />
        )
    }
}

export function componentFactory(item, settings, getFieldDecorator, hunt) {
    // item.hasOwnProperty("valueListFields") &&
    let intailVals = settings && (settings || {}).hasOwnProperty(item.name)
        ? settings[item.name].toString()
        : item.hasOwnProperty("valueListFields") && item.hasOwnProperty("min") ? (item.default - Number(item.min)).toString() : item.default.toString();
    if (item.hasOwnProperty("valueListFields")) {
        // find value mapped in array
        if (item.valueListFields.length > 2) {
            let val = item.valueListFields.findIndex((element) => element.value === item.value);
            intailVals = val.toString();
        }
        else {
            if (item.valueListFields[0].value === item.value) {
                intailVals = true
            } else {
                intailVals = false
            }
        }
    }
    if (item.displayName === "divider") {
        return (
            null
        )
    }
    return (
        <div>
            <Form.Item
                label={
                    <span>
                        {item.displayName || "No title in backend"}&nbsp;
                        <Tooltip title={`${item.desc}`}>
                            <QuestionCircleTwoTone twoToneColor="#27ae60" />
                        </Tooltip>
                    </span>
                }
                name={String(item.name)}
                valuePropName="checked"
            >
                {!!localStorage.getItem("show-config") === true && (
                    <div>
                        <pre
                            style={{
                                maxHeight: "400px",
                                overflowY: "scroll",
                            }}
                        >
                            {JSON.stringify(item, null, 2)}
                        </pre>
                        <pre>
                            Server Value:{" "}
                            {(settings || {}).hasOwnProperty(item.name)
                                ? settings[item.name]
                                : "N/A"}
                        </pre>
                        <pre>
                            Initial Value:{" "}
                            {settings &&
                                (settings || {}).hasOwnProperty(item.name)
                                ? settings[item.name].toString()
                                : item.default.toString()}
                        </pre>
                    </div>
                )}

                {getFieldDecorator(
                    item.name,
                    {
                        rules: [
                            !item.hasOwnProperty("valueListFields") && {
                                validator: (rule, value, callback) => {
                                    const min = Number(item.min) || 0;
                                    const max = Number(item.max) || Infinity;
                                    if (value >= min && value <= max) {
                                        callback();
                                    } else {
                                        callback(
                                            `Range should be between ${min} and ${max}`
                                        );
                                    }
                                },
                            },
                        ],
                        initialValue: intailVals
                    })(ComponentSelect(item, settings))}
            </Form.Item>
        </div>
    )
}
