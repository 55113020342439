import { Badge, Button, Modal, Select } from "antd";

import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";

import LicenseStore from "../../stores/license.store";
import deviceStore from "../../stores/device.store";


function LicenseAllocationDialog({ children: Component, device, title, unallocatedLicensesData, onLicenseAllocatedClicked, updateData }) {
    const [isVisible, setIsVisible] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState(null);

    const licenseStore = useContext(LicenseStore);
    const DeviceStore = useContext(deviceStore);

    const onChildClick = () => {
        setIsVisible(true);
    };

    const ComponentWithHandler = React.cloneElement(Component, {
        onClick: onChildClick
    });

    const handleChange = (value) => {
        setSelectedLicense(value)
    }

    const update = () => {
        updateData()
    }

    const getNumber = () => {
        let out = 0;
        unallocatedLicensesData.dlMapping.map((entry) => (
            entry.expiry_date > Date.now() / 1000
                ?
                out++
                :
                []
        ))
        return out
    }

    return (
        <React.Fragment>
            <Modal
                destroyOnClose={true}
                width={"35vw"}
                title={title}
                visible={isVisible}
                onOk={() => {
                    setIsVisible(false);
                }}
                onCancel={() => {
                    setIsVisible(false);
                }}
                footer={null}
            >

                <div style={{ display: "flex", marginTop: 0 }}>
                    <label
                        style={{ "font-weight": "bold" }}
                        key="submit"
                        type="primary"
                    >
                        {" "}
                        {unallocatedLicensesData && unallocatedLicensesData.dlMapping
                            && unallocatedLicensesData.dlMapping.length ?
                            `There are ${getNumber()} licenses available` : "There are no licenses available"}
                    </label>
                </div>

                <br />

                <div style={{ marginTop: 0 }}>
                    <label> <span style={{ "color": "red" }}> * </span> Select a license </label>
                </div>

                <Select disabled={unallocatedLicensesData && !unallocatedLicensesData.dlMapping} style={{ width: "50%" }}
                    onChange={handleChange}>
                    {unallocatedLicensesData && unallocatedLicensesData.dlMapping && unallocatedLicensesData.dlMapping.map((entry) => (
                        entry.expiry_date > Date.now() / 1000 ?
                            <Select.Option value={entry.license_id}>
                                <p>{entry.alias}</p>

                                <React.Fragment>
                                    <Badge status={"success"}></Badge>{" "}
                                    <span>Expiry Date {new Date(Number(entry.expiry_date) * 1000).toLocaleDateString()}</span>{" "}

                                </React.Fragment>
                            </Select.Option>
                            :
                            []
                    ))}
                </Select>

                <hr></hr>

                <div style={{ display: "flex", marginTop: 5, margin: 2 }}>
                    <Button
                        onClick={() => {
                            setIsVisible(false)
                            setSelectedLicense(null)
                        }}
                        style={{ marginLeft: "auto" }}
                        key="submit"
                    >
                        {" "}
                        Cancel
                    </Button>

                    <Button
                        onClick={() => {
                            licenseStore.allocateLicense(selectedLicense, device.id);
                            DeviceStore.getAllDevices();
                            // update()
                            setIsVisible(false)
                            return onLicenseAllocatedClicked();
                        }}
                        style={{ marginLeft: "3px" }}
                        key="submit"
                        type="primary"
                        disabled={selectedLicense === null}
                    >
                        {" "}
                        Update
                    </Button>


                </div>

            </Modal>
            {ComponentWithHandler}
        </React.Fragment>
    );
}

export default observer(LicenseAllocationDialog);
