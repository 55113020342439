import {makeAutoObservable} from 'mobx';
import {createContext} from 'react';

import API from '../services/api';
import {macToMAC} from "../util/CommonUtils";
import axios from "axios";
export class WifiStore {

    markedPoints = [];
    wifiHoverDisplayKey = -1;
    wifiClickDisplayArray = [];

    macRequestLoading = false;

    constructor() {
        this.api = API;
        this.deviceId = -1;
        this.isOpened = false;
        this.WifiHistory = [];
        this.isLoading = false;
        this.MapDisplayPoints = [];
        this.investigationId = -1;
        this.wifiActivity = [];
        makeAutoObservable(this);
    }

    setDeviceIdAndOpen(deviceId) {
        console.log("Device ID: ", deviceId)
        this.deviceId = deviceId;
        this.isOpened = true;
    }

    async macAddressLookup(address) {
        this.macRequestLoading = true
        let resData = ""
        await API.wifiDevice.ReverseLookUp(address)
            .then(res => {
                this.macRequestLoading = false
                resData = res.data.details
            })
        return resData
    }

    close() {
        this.isOpened = false;
    }

    loadWifiData() {
        console.log("loading Data")
        API.wifiDevice.getCaptures(this.deviceId).then((response) => {
            this.WifiHistory = response.sort((a, b) => {
                return b.timestamp - a.timestamp;
            })
        });
    }
    setInvestigationId(id) {
        this.investigationId = id;
    }

    loadTripData(TargetID, TimeStamp) {
        this.MapDisplayPoints = [];
        this.isLoading = true;
        API.analysis.getWIfiDeviceLastTrip(this.investigationId, TargetID, TimeStamp).then((res) => {
            this.MapDisplayPoints = res.trip_data;
            return (res)
        }).then((res) => {
            API.CloneCapture.ClonedTripCheck(this.investigationId, res.device_utc_date_time, res.stopped_date_time).then((res) => {
                this.wifiActivity = res;
                this.isLoading = false;
            }).catch((err) => {
                this.isLoading = false;
            });
        }).catch((err) => {
            this.isLoading = false;
        })
    }

    findClosestPoint(timeStamp) {
        let closest = null;
        let closestTime = 0;
        if (this.MapDisplayPoints === null) {
            return [];
        }
        this.MapDisplayPoints.forEach((point) => {
            if (closest === null) {
                closest = point;
                closestTime = Math.abs(point.device_utc_date_time - timeStamp);
                return;
            }
            if (Math.abs(point.device_utc_date_time - timeStamp) < closestTime) {
                closest = point;
                closestTime = Math.abs(point.device_utc_date_time - timeStamp);
            }
        });
        return closest;
    }

    get WifiDevicesForMap(){
        let returnList = [];
        if (this.wifiActivity === undefined || this.wifiActivity === null || this.wifiActivity.length === 0) return [];
        if (this.MapDisplayPoints === undefined || this.MapDisplayPoints === null || this.MapDisplayPoints.length === 0) return [];
        this.wifiActivity.forEach((wifi, index) => {

            if (!this.isWifiCloned(wifi.entity.content.capture_type)) return;
            const latLngData = this.findClosestPoint(wifi.entity.content.timestamp);
            const entity = {
                TimeStamp: wifi.entity.content.timestamp,
                latitude: latLngData.latitude,
                longitude: latLngData.longitude,
                capture_type: wifi.entity.content.capture_type,
                end: false,
                key: index,
                Alias: wifi.entity.alias,
                Type: wifi.entity.content.capture_type,
                MAC: macToMAC(wifi.entity.content.mac_address),
                EntityName: wifi.entity.content.entityAlias,
                id: wifi.entity.content.id,
            };

            if (entity.Alias === undefined || entity.Alias === null) {
                entity.Alias = macToMAC(wifi.entity.content.mac_address) + ":" + wifi.entity.content.apAlias;
            }

            returnList.push(entity);
        });
        returnList.push({
            latitude: this.MapDisplayPoints[this.MapDisplayPoints.length - 1].latitude,
            longitude: this.MapDisplayPoints[this.MapDisplayPoints.length - 1].longitude,
            capture_type: "end",
            end: true,
            key: "endFlag",
            Alias: "",
            Type: "",
            MAC: "",
            EntityName: "",
        });

        return returnList;
    }

    get latlngTracks(){
        return this.MapDisplayPoints.map((point) => {
            return {
                lat: point.latitude,
                lng: point.longitude
            };
        });
    }


    addToMarkedPoints(key) {
        this.markedPoints.push(key);
    }

    makerVisible(key) {
        return this.markedPoints.includes(key);
    }

    removeFromMarkedPoints(key) {
        this.markedPoints = this.markedPoints.filter((k) => k !== key);
    }

    isWiFiInfoVis(key) {
        return this.wifiHoverDisplayKey === key || this.wifiClickDisplayArray.includes(key);
    }

    setHoverWifiInfoVis(key) {
        this.wifiHoverDisplayKey = key;
    }

    setHoverExitWifiInfoVis() {
        this.wifiHoverDisplayKey = -1;
    }

    addWiFiInfoVis(key) {
        this.wifiClickDisplayArray.push(key);
    }

    removeWiFiInfoVis(key) {
        this.wifiClickDisplayArray = this.wifiClickDisplayArray.filter((k) => k !== key);
    }

    isWifiCloned(captureType) {
        return captureType === "WIFI_CLONE_SCAN"
            || captureType === 'WIFI_FRAME_FUNCTION_AUTHENTICATION'
            || captureType === 'WIFI_FRAME_FUNCTION_ASSOCIATION'
            || captureType === 'WIFI_FRAME_FUNCTION_REASSOCIATION'
            || captureType === 'WIFI_FRAME_SUBTYPE_AUTHENTICATION'
        // || captureType === 'WIFI_FRAME_FUNCTION_PROBE_RES';
    }

}

export default createContext(new WifiStore());
