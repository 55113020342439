import { Modal } from "antd";
import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useTimer } from "use-timer";

export default function InactivityModal({ children, isAuthenticated }) {
    const { time, start, pause, reset } = useTimer({
        initialTime: 30,
        endTime: 0,
        timerType: "DECREMENTAL",
        onTimeOver: () => {
            if (isAuthenticated) {
                localStorage.setItem("token", "");
                window.location.replace("/");
            }
        },
    });
    const handleOnIdle = (event) => {
        if (isAuthenticated) {
            setVisible(true);
            start();
        }
    };

    const handleOnActive = (event) => {
        if (isAuthenticated) {
            setVisible(false);
            pause();
            reset();
        }
    };

    const handleOnAction = (e) => {};
    const [visible, setVisible] = useState(false);
    useIdleTimer({
        timeout: 1000 * 60 * 60 * 8,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
    });

    return (
        <React.Fragment>
            <Modal
                title="You've been inactive for a while.."
                visible={visible}
                okButtonProps={{
                    disabled: true,
                    style: {
                        display: "none",
                    },
                }}
                cancelButtonProps={{
                    disabled: true,
                    style: {
                        display: "none",
                    },
                }}
            >
        You will be disconnected in a <strong>{time} seconds</strong>.
            </Modal>
            {children}
        </React.Fragment>
    );
}
