import { message } from "antd";
import FuzzySearch from "fuzzy-search";
import { action, computed, observable, values, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

export class NickNameStore {


    constructor() {
        this.NickNames = [];
        this.api = API;
        makeAutoObservable(this);
    }

    async getallnames(id) {
        const response = await this.api.nickName.getAllNickNamesByTarget(id);
        this.NickNames = response;
    }

    async updateTarget(id, nickname, notes) {
        try {
            await this.api.nickName.updateNickName(id, nickname, notes);
            message.success(`Target  has been updated.`);
            this.getallnames(id);
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

}

export const store = new NickNameStore();
export default createContext(store);
