import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, Row, Select, Spin, Table, Switch } from "antd";
import { toJS } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import API from "../../services/api";
import DeviceStore from "../../stores/device.store";
import AnalyticsAdditionalInfoDialog from "../AnalyticsAdditionalInfoDialog";
import HuntsmanLiveDataLogTable from "../HuntsmanLiveDataLogTable";
import {formatDate, getUTCDateTime} from "../../util/CommonUtils";
import targetStore from '../../stores/target.store';
import NickNameTable from '../NickNameTable';
import NickNameForm from '../NickNameForm';
import NickNameStore from '../../stores/NickName.store';
import investigationStore from '../../stores/investigation.store';

function WifideviceAuditTab(props) {
    const deviceStore = useContext(DeviceStore);
    const targetstore = useContext(targetStore);
    const nicknamestore = useContext(NickNameStore);
    const investigationstore = useContext(investigationStore);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [deviceSerial, setDeviceSerial] = useState(null);
    const [date, setDate] = useState(null);
    const [targets, setTargets] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isLiveMode, setIsLiveMode] = useState(false);
    const [target, setTarget] = useState([]);
    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});
    const [update, setupdate] = useState(false);
    useEffect(() => {
        async function getTarget() {
            await targetstore.getAllTargets().then((res) => {
                setShowDropDown(true);
            });
        }
        async function getInvest() {
            await investigationstore.getInvestigationsForUser().then((res) => {
                setShowDropDown(true);
            })
        }
        getInvest();
        // getTarget();
        targetstore.targets = props.targets;
    }, []);
    const dataSource = [];
    if (props.analytics && props.analytics.length > 0) {
        props.analytics.forEach((item) => {
            // const localDate = new Date(item.epochDate);
            dataSource.push({
                key: item.id,
                ssid: item.ssid,
                clonedMac: item.clonedMac,
                nickname: item.nickname,
                notes: item.notes,
            });
        });
    }

    const sortedDataSource = dataSource.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
    );

    const [isVisible, setIsVisible] = useState(false);
    const [payload, setPayload] = useState({});

    const analyticsAdditionalView = (record) => {
        setIsVisible(true);
        setPayload(JSON.parse(record.serializedPayload));
    };

    const onModalCancelClicked = () => {
        setIsVisible(false);
    };

    const exportData = async () => {
        const fromConverted = date.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = date.toDate();
        toConverted.setHours(23, 59, 59);
        const response = await API.analytics.getAllAnalytics(
            deviceSerial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            0,
            keyword,
            1
        );

        const updatedData = response.deviceAnalytic.map(x => {
            return {
                localDate: ` ${formatDate((x.epochDate / 1000), 'HH:mm:ss')}`,
                UTCDate: ` ${getUTCDateTime((x.epochDate / 1000))}`,
                ipAddress: x.ipAddress,
                serialNumber: x.serialNumber,
                incomingType: x.incomingType,
                outgoingType: x.outgoingType,
                serializedPayload: x.serializedPayload,
            }
        })

        await API.analytics.downloadAnalyticsDump(updatedData);
    //await API.analytics.downloadAnalyticsDump(sortedDataSource);
    };

    const exportDataForCurrentPage = async () => {
        let updatedData = sortedDataSource.map(x => {
            return {
                localDate: ` ${x.localDate}`,
                UTCDate: ` ${x.date}`,
                ipAddress: x.ipAddress,
                serialNumber: x.serialNumber,
                incomingType: x.incomingType,
                outgoingType: x.outgoingType,
                serializedPayload: x.serializedPayload,
            }
        })
        await API.analytics.downloadAnalyticsDump(updatedData);
    }

    const handleSubmit = (e) => {
        props.form.validateFields((error, values) => {
            if (!error) {
                nicknamestore.getallnames(values.name);
                setTarget(values.name);
                setKeyword(values.keyword);
                props.onFilterClicked(
                    values.name,
                    values.keyword
                );
            }
        });
    };

    const onEditRecord = (record) => {
        setMode("edit");
        setExistingData(record);
        setIsModalOpen(true);
    }

    const onDeleteRecord = (e) => {
        props.form.validateFields((error, values) => {
            if(!error){
                console.log(`delete`);
            }
        })
    }

    const onChange = (checked) => {
        setIsLiveMode(checked)
        if (checked) {
            props.onLiveModeClicked(deviceSerial, date, date)

            // setIntervalId(refreshIntervalId)
        }else{
            /*if (intervalId) {
        clearInterval(intervalId);
      }*/
        }
    }

    useEffect(() => {
        if ( update == true) {
            handleSubmit(toJS(nicknamestore.NickNames[0].id));
            setupdate(false);
        }
    }, [update]);

    const { getFieldDecorator } = props.form;
    return (
        <React.Fragment>
            <div style={{ margin: "0vh 6vh" }}>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Investigation
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("InvestigationID", {
                                    // onChange: props.onDateRangeSelect,
                                    initialValue: [],
                                    onChange : props.onInvestigationSelect,
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Investigation"
                                        disabled={showDropDown !== true}
                                    >
                                        {showDropDown === true &&
                      toJS(
                          investigationstore.investigationsForUser.map((ainvest) => {
                              return (
                                  <Select.Option

                                      key={ainvest.id}
                                      id={ainvest.id}
                                      value={ainvest.id}
                                  >
                                      {ainvest.name}
                                  </Select.Option>
                              );
                          })
                      )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Target
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("name", {
                                    // onChange: props.onDateRangeSelect,
                                    initialValue: null,
                                })(
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.indexOf(input) >= 0
                                        }
                                        disabled={showDropDown !== true}
                                    >
                                        {showDropDown === true &&
                      toJS(
                          props.targets.map((atarget) => {
                              return (
                                  <Select.Option
                                      key={atarget.id}
                                      id={atarget.id}
                                      value={atarget.id}
                                  >
                                      {atarget.name}
                                  </Select.Option>
                              );
                          })
                      )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            textAlign: "end",
                        }}
                    >
                        <Button
                            id={"device-analytics-search-btn"}
                            onClick={() => {
                                handleSubmit();
                            }}
                            style={{ marginLeft: "auto" }}
                            key="filrer"
                        >
              Search
                        </Button>
                    </Row>
                </Form>
                <br></br>
            </div>
            <div>
                <AnalyticsAdditionalInfoDialog
                    modalVisible={isVisible}
                    onCancelClick={onModalCancelClicked}
                    data={payload}
                />
            </div>
            <NickNameForm
                opened={isModalOpen}
                mode={mode}
                data={existingData || {}}
                devices={toJS(deviceStore.unassignedDevices)}
                onSubmit={(data) => {
                    const { values, mode } = data;
                    if (mode === "edit") {
                        nicknamestore.updateTarget(values.id, values.nickname, values.notes);
                        setIsModalOpen(false);
                        setExistingData({});
                        setupdate(true);

                    } else {
                        console.log(`not edit module view form: ${JSON.stringify(values)}`);
                    }
                    deviceStore.getAllUnassignedDevices();
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                    deviceStore.getAllUnassignedDevices();
                }}
            ></NickNameForm>
            {props.spinnerVisible === true ? (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5%",
                    }}
                >
                    <Spin size={"large"} />
                </div>
            ) : !isLiveMode ? (
                <div className='target-table'>
                    <NickNameTable
                        canEdit={true}
                        canDelete={true}
                        onEditRecord={onEditRecord}
                        onDelete={onDeleteRecord}
                        data={toJS(nicknamestore.NickNames)}
                    />
                </div>
            ) : (<HuntsmanLiveDataLogTable
                data={props.huntsmanData}
                timeIntervalHuntsmanAnalytics={props.timeIntervalHuntsmanAnalytics}
                deviceSerial={deviceSerial}
                date={date}
            />)}
        </React.Fragment>
    );
}

export default Form.create({})(WifideviceAuditTab);
