import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const accessPointsByInvestigation = async (investigationID) => {
  try {
    //const offset = 60 * 30;
    const response = await HTTP.get(`/accessPoint/${investigationID}/capture/access_point`);
    if (response.data.code === 'RECORD_NOT_FOUND') {
      return Promise.resolve([]);
    }
    const data = response.data.data.accessPoints;
    return Promise.resolve(data);
  } catch (exception) {
    return Promise.reject(exception);
  }
}

const updateAccessPointsLocation = async (accessPointID, lat, lng) => {
  try {
    const response = await HTTP.post('/accessPoint/update', {
      accessPointID,
      lat,
      lng
    });
    if (response.status == 200) {
      return true;
    }
    return(false);
  } catch {
    return Promise.reject();
  }
}

const updateAccessMeta = async (accessPointID, metadata) => {
  try {
    const response = await HTTP.post('/accessPoint/updatemeta', {
      accessPointID,
      metadata
    });
    if (response.status == 200) {
      return true;
    }
    return(false);
  } catch {
    return Promise.reject();
  }
}

export default {
  updateAccessPointsLocation,
  accessPointsByInvestigation,
  updateAccessMeta,
};
