import { Spin, Button, message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState, useContext } from 'react';
import WifiNetworkAnalysisForm from '../../components/AnalysisWifiNetworkForm';
import WifiNetWorkMap from '../../components/AnalysisWifiNetWorkMap';
import WifiNetworkPanel from '../../components/AnalysisWifiNetworkPanel';
import API from '../../services/api';
import Layout from '../AppLayout';
import { primaryButton } from '../../styles';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import wifiNetworkStore from "../../stores/wifiNetwork.store";


function AnalysisWifiNetworkPage(){
    const [showWifiMap, setShowWifiMap] = useState(false);
    const [panTo, setPanTo] = useState(null);
    const [visible, setVisible] = useState(true);

    // Alias form and customization
    const store = useContext(wifiNetworkStore);
    const [margin, setMargin] = useState('33%');
    const [buttonOpacity, setButtonOpacity] = useState('1.0');
    const [buttonMargin, setButtonMargin] = useState('0px')
    // Permissions for moving an access point
    const setHover = () => {
        setButtonOpacity('0.7');
    }

    const unSetHover = () => {
        setButtonOpacity('1.0');
    }

    const showDrawer = () => {
        setButtonOpacity('1.0');
        setButtonMargin('0px');
        setVisible(true);
        setMargin("33%");

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin('0px');
    };

    const selectedtarget = (name) => {
        setPanTo( { ...name } );
    }

    const updateAccessPoint = async (accessPointID, lat, lng) => {
        API.accessPoint.updateAccessPointsLocation(accessPointID, lat, lng)
            .then((_response) => {
                // If accesspoint update was successful, refresh investigation list
                store.wifiNetworks.findIndex((ap) => {
                    if (ap.id === accessPointID) {
                        ap.latitude = lat;
                        ap.longitude = lng;
                    }
                });
            });
    }

    useEffect(() => {
        API.investigation.getInvestigationsForUser().then((res) => {
            setShowWifiMap(true);
        });
    }, []);

    return (
        <Layout showNavigation hideMargins>
            {showWifiMap === false && (
                /* Show spinner */
                <div
                    style={{
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20%',
                    }}
                >
                    <Spin />
                </div>
            )}

            {showWifiMap === true && (
                <div
                    style={{ marginLeft: margin }}
                >
                <div style={{
                    position: "fixed",
                    zIndex: 2,
                    width: "100%"
                }}>
                    <Button type="primary" onClick={visible ? onClose : showDrawer}
                        style={primaryButton({
                            position: "absolute",
                            top: "40vh",
                            zIndex: 2,
                            height: "15vh",
                            marginTop: "0%",
                            left: buttonMargin,
                            marginLeft: "2px",
                            borderLeftColor: "#0066CC",
                            marginBottom: "2px",
                            opacity: buttonOpacity,
                        })}
                        onMouseEnter={() => { setHover() }}
                        onMouseLeave={() => { unSetHover() }}
                    >
                        {
                            visible
                                ?
                                <ArrowLeftOutlined
                                    width={25}
                                    height={25}
                                />
                                :
                                <ArrowRightOutlined
                                    width={25}
                                    height={25}
                                />
                        }
                    </Button>
                </div>
                    <WifiNetworkPanel
                        visible={visible}
                        showDrawer={showDrawer}
                        onClose={onClose}
                    // style={{ maxHeight: "70vh", overflow: "scroll", background: '#dedede' }}
                    >
                        <WifiNetworkAnalysisForm
                            style={{ maxHeight: "70vh", overflow: "scroll", background: '#dedede' }}
                            selectedtarget={selectedtarget}
                        >
                        </WifiNetworkAnalysisForm>


                    </WifiNetworkPanel>
                </div>
            )}{
                showWifiMap === true && (
                    /* Show map */
                    <div>
                        <WifiNetWorkMap
                            panBy={[-300, 0]}
                            data={store.displayAps || []}
                            selectedTarget={panTo}
                            updateAccessPoint={updateAccessPoint}
                            currentlyHiddenAccessPoints={store.hiddenAps}
                            accessPointsDevices={store.selectedAps}
                            dragList={store.DragList}
                        />
                    </div>

                )
            }
        </Layout >
    );
};

export default observer(AnalysisWifiNetworkPage);
