import { Button, Divider, Table } from "antd";
import React from "react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import "./InvestigationTable.scss";
import {customSorter, formatDate} from "../../util/CommonUtils";

const { Column } = Table;

export default function ({ onDelete, onEditRecord, data, canEdit, canDelete }) {
    const _onEditRecord = (record) => {
        onEditRecord(record);
    };

    const _onDeleteRecord = (record) => {
        onDelete(record);
    };

    const sortDirections = ['ascend', 'descend'];

    return (
        <Table
            dataSource={data}
            size="middle"
            rowKey="id"
            pagination={{ pageSize: 10 }}
        >
            <Column
                title="Investigation"
                dataIndex="investigation_name"
                key="investigation_name"
                sorter={(a, b) =>  customSorter(a.investigation_name, b.investigation_name)}
                sortDirections={sortDirections}
            />
            <Column title="Target Name" dataIndex="name" key="name"
                sorter={(a, b) =>  customSorter(a.name, b.name)}
                sortDirections={sortDirections}
            />
            <Column
                title="Current Device"
                dataIndex="device_name"
                key="device_name"
                sorter={(a, b) => customSorter(a.device_name,b.device_name)}
                sortDirections={sortDirections}
            />
            <Column
                title="Created At"
                key="created_at"
                sorter={(a, b) =>  a.created_at - b.created_at }
                sortDirections={sortDirections}
                render={(text, record) =>
                    record.created_at
                        ? formatDate(record.created_at, 'hh:mm:ss A')
                        : "N/A"
                }
            />

            <Column
                title="Assigned At"
                key="assign_at"
                sorter={(a, b) =>  a.assign_at - (b.assign_at)}
                sortDirections={sortDirections}
                render={(text, record) =>
                    record.assign_at
                        ? formatDate(record.assign_at, 'hh:mm:ss A')
                        : "N/A"
                }
            />
            <Column
                title=""
                key="action"
                render={(text, record) => (
                    <span>
                        <span>
                            <Button
                                disabled={canEdit}
                                type="link"
                                onClick={() => {
                                    _onEditRecord(record);
                                }}
                            >
                Edit
                            </Button>
                        </span>
                        <span>
                            <Divider type="vertical" />
                            <DeleteConfirmationModal
                                title={`Are you sure you want to delete '${record.name}'?`}
                                content={
                                    "Deletion of target will permanently delete all tracking data associated with this target and not be recoverable."
                                }
                                onClickCancel={() => {}}
                                onClickOkay={() => {
                                    _onDeleteRecord(record);
                                }}
                            >
                                <Button type="link" size="small" disabled={canDelete}>
                  Delete
                                </Button>
                            </DeleteConfirmationModal>
                        </span>
                    </span>
                )}
            />
        </Table>
    );
}
