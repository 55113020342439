import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, Row, Select, Spin, Table, Switch, message, Form, Tabs } from "antd";
import { toJS } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import API from "../../services/api";
import { formatDate, getUTCDateTime, getGMTDateTime } from "../../util/CommonUtils";
import EntityTableView from '../EntityTableView';
import investigationStore from '../../stores/investigation.store';
import EntityAddForm from '../EntityAddForm';
import entityPageStore from '../../stores/entitypage.store';
import { primaryButton, secondaryButton, contentBox } from '../../styles'
import { observer } from 'mobx-react-lite';
import EntityMap from "../EntityMap";
import { PlusOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import AnalysisSiteStore from "../../stores/analysisSuper.store";

function EntityMangementTab(props) {
    const investigationSiteStore = useContext(AnalysisSiteStore)
    const investigationstore = useContext(investigationStore);
    const [showStopsMap, setShowStopsMap] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [trip, setTrip] = useState(null);
    const [expandedData, setExpandedData] = useState([]);
    const [expandedContent, setExpandedContent] = useState([]);
    const [investigationID, setInvestigationID] = useState(null);
    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const InvestigationStore = useContext(investigationStore);
    const EntityStore = useContext(entityPageStore);

    const handleSubmit = (values) => {
        setInvestigationID(values.InvestigationID);
        EntityStore.updateEntityList(values.InvestigationID);
    }

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {

        async function getInvest() {
            await InvestigationStore.getInvestigationsForUser().then((res) => {
                setShowDropDown(true);
            })
        }
        getInvest();
        upDateEntlist();

    }, []);

    const upDateEntlist = () => {
        API.entity.GetAllEntities(investigationID).then((res) => {
            setEntityList(res);
        })
    }

    const onExpand = (expanded, record) => {
        const expandedRows = [];
        const expandedCon = [];
        console.log("record", record)
        console.log("EntityStore.entity", EntityStore.entity?.id)
        if (record.id !== EntityStore.entity?.id) {
            console.log("entity set");
            EntityStore.setEntity(record);
        }

        EntityStore.selectEntity(record);
        API.analysis.getWIfiDeviceLastTrip(record.id).then((res) => {
            EntityStore.setTrip(res.trip_data);
        })

        if (expanded) {
            expandedRows.push(record.id)
            expandedCon.push(record)
        }
        console.log("expandedRows", expandedRows)
        setExpandedData(expandedRows)
        setExpandedContent(expandedCon)
    }

    const createEntity = (data) => {
        setMode("add");
        setExistingData(data);
        setIsModalOpen(true);
    }

    const deletefromArray = (id) => {
        EntityStore.removeEntityFromArray(id);
    }

    return (
        <React.Fragment>
             <EntityAddForm
                investigations={toJS(
                    investigationstore.investigationsForUser)}
                opened={isModalOpen}
                mode={mode}
                passthrough={[]}
                data={existingData || null}
                onSubmit={(data) => {
                    const { values, mode } = data;
                    // updateEntity(values);
                    EntityStore.createEntity(values);
                    if (mode === "add") {
                        setIsModalOpen(false);
                        setExistingData({});

                    } else {
                        console.log(`not edit module view form: ${JSON.stringify(values)}`);
                    }
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                }}
            ></EntityAddForm>
            <div style={contentBox({marginTop: '0px'})}>
                <div style={contentBox({ marginTop: "0px", marginBottom: '8px' })}>
                    <Button
                        icon={<PlusOutlined />}
                        style={primaryButton({ marginLeft: "auto" })}
                        onClick={() => {
                            createEntity();
                        }}
                    >
                        Add Entity
                    </Button>
                    <Form
                        onFinish={handleSubmit}
                        layout="inline" form={form}
                        style={{ float: "right" }}
                        initialValues={{InvestigationID: investigationSiteStore.selectedInvestigation}}
                    >
                        <label
                            style={{ marginRight: "10px"}}
                        >
                            Investigation:
                        </label>
                        <Form.Item
                            name="InvestigationID"
                        >
                            <Select
                                showSearch
                                style={{ width: "400px", textAlign: "left" }}
                                placeholder="Investigation"
                                disabled={showDropDown !== true}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={
                                    toJS(
                                        InvestigationStore.investigationsForUser.map((ainvest) => {
                                            return {
                                                label: ainvest.name,
                                                value: ainvest.id,
                                            };
                                        }
                                        ))
                                }
                                onChange={(value) => {investigationSiteStore.setInvestigationId(value);}}
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                id={"device-analytics-search-btn"}
                                htmlType="submit"
                                style={primaryButton({ marginLeft: "auto" })}
                                key="filrer"
                            >
                                Search
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <Row>
                    <Col span={11}>
                        <Row>
                            <div
                                style={{
                                    background: 'white',
                                    borderRadius: '0.5rem',
                                    boxShadow: '5px 5px 5px 5px #E6E6E6',
                                }}
                            >
                                {showStopsMap ?
                                    <EntityMap
                                        type={EntityStore.displayType}
                                        trip={EntityStore.displayTrip}
                                        selectedTarget={props.latlng}
                                        latlng={{ lat: EntityStore.selectedEntity?.latitude, lng: EntityStore.selectedEntity?.longitude }}
                                        accessPoints={EntityStore.selectedEntity?.APs}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        containerElement={<div style={{ height: '100%', borderRadius: "0.5rem", overflow: "hidden" }} />}
                                        mapElement={<div style={{ height: '100%' }} />}
                                    />
                                    : <Spin />
                                }
                            </div>
                        </Row>
                    </Col>
                    <Col span={13} style={{ MarginTop: "0px" }}>
                        <div style={contentBox({ marginTop: "0px", height: "75vh" })}>
                            <Table
                                columns={[
                                    {
                                        title: "Entity alias",
                                        name: "Entity alias",
                                        dataIndex: "alias",
                                        key: "alias",
                                    },
                                    {
                                        title: "last seen",
                                        name: "last seen",
                                        dataIndex: "last_seen",
                                        key: "last_seen",
                                        render: (text, record) => {
                                            if (record.last_seen > 0) {
                                                return (
                                                    <span>
                                                        {getGMTDateTime(record.last_seen)}
                                                    </span>
                                                );
                                            }
                                        }
                                    }
                                ]}
                                onRow={(record, rowIndex) => {
                                    return {
                                        style: { background: "#00ddff" }
                                    };
                                }}
                                expandIcon={({ expanded, onExpand, record }) =>
                                    expanded ? (
                                        <UpOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <RightOutlined onClick={e => onExpand(record, e)} />
                                    )}
                                dataSource={toJS(EntityStore.displayEntityList)}
                                rowKey="id"
                                size='small'
                                pagination={false}
                                scroll={{ y: 600 }}
                                expandRowByClick={true}
                                expandable={{
                                    expandedRowRender: (record) => {

                                        return (
                                            <div>
                                                <EntityTableView
                                                    investigation={investigationID}
                                                    data={record}
                                                    removeEntityFromArray={deletefromArray}
                                                    //style={{ height: "100%" }}
                                                />
                                            </div>
                                        );
                                    },
                                    onExpand: onExpand,
                                    expandedRowKeys: expandedData,
                                }}
                            />


                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default observer(EntityMangementTab);
