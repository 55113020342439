import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();


/**
 * Get Live Pins
 * @returns {Promise<unknown>}
 */
const getLivePins = async () => {
    try {
        const response = await HTTP.get(`/live-pin`);
        const data = response.data.data;


        return Promise.resolve(data);
    } catch (exception) {
        if (exception.response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve({livePin: []});
        }
        return Promise.reject(exception);
    }
}

/**
 * Add new live pin
 * @param payload
 * @returns {Promise<unknown>}
 */
const addLivePin = async (payload) => {
    try {
        const response = await HTTP.post(`/live-pin`, payload);
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

/**
 * Remove Live pin
 * @param id
 * @returns {Promise<unknown>}
 */
const removeLivePin = async (id) => {
    try {
        const response = await HTTP.delete(`/live-pin/${id}`);
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}


export default {
    getLivePins,
    removeLivePin,
    addLivePin
};