import React, { useEffect, useState } from "react";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    HeatmapLayer,
} from "@react-google-maps/api";
// https://stackoverflow.com/questions/46591519/react-google-maps-heatmaplayer-undefined
import MapStyles from "../../map-styles";
import { mapDefault } from "../../util/CommonUtils";
import "./index.css";

// https://blog.alexdevero.com/custom-styled-google-map-react/
// https://tomchentw.github.io/react-google-maps/#installation

const StopsMap = (({ data, selectedPoint, panBy, currentPosition, error }) => {

    const [map, setMap] = useState();



    const [located, setLocated] = useState(false);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;
        if (located === false) {
            map.panTo({
                lat: crd.latitude,
                lng: crd.longitude,
            });
            setLocated(true);
        }
    }

    const fail = (error) => {
        console.log(`Error: ${error.code}`);

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    navigator.geolocation.getCurrentPosition(success, fail, options);

    const coordinates = data.map((point) => {
        return new window.google.maps.LatLng(point.latitude, point.longitude);
    });

    useEffect(() => {
        if (data) {
            if (selectedPoint) {
                map.panTo({
                    lat: selectedPoint.latitude,
                    lng: selectedPoint.longitude,
                });
                map.panBy(...panBy);
            } else {
                if (data.length > 0) {
                    const points = data.map((point) => ({
                        ...point,
                        lng: point.longitude,
                        lat: point.latitude,
                    }));
                    const bounds = new window.google.maps.LatLngBounds();
                    points.forEach((point) => {
                        bounds.extend(point);
                    });
                    if (map) {
                        map.fitBounds(bounds);
                    }
                }
            }
        }
    }, [data, panBy, selectedPoint]);

    return (
        <GoogleMap
            defaultCenter={
                mapDefault()
            }
            yesIWantToUseGoogleMapApiInternals
            mapContainerStyle={{
                height: "100vh",
                width: "100%"
            }}
            onIdle={() => {

                if (map.getCenter()) {
                    localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                }
            }}
            zoom={15}
            onLoad={map => setMap(map)}
        >
            {selectedPoint && (
                <Marker
                    // animation={window.google.maps.Animation.BOUNCE}
                    position={{
                        lat: selectedPoint.latitude,
                        lng: selectedPoint.longitude,
                    }}
                >
                    <InfoWindow
                        position={{
                            lat: selectedPoint.latitude,
                            lng: selectedPoint.longitude,
                        }}
                    >
                        <div>{selectedPoint.address || "N/A"}</div>
                    </InfoWindow>
                </Marker>
            )}
            <HeatmapLayer data={coordinates} options={{ radius: 30 }} />
            {/* <TrafficLayer autoUpdate /> */}
        </GoogleMap>
    );
});

export default StopsMap;
