import React, { useEffect, useState } from "react";
import { secondaryButton, primaryButton } from '../../styles';
import { Input, Tabs, Checkbox, Button, Modal, Form, Row, Col } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { observer } from "mobx-react-lite";

function UserPermissionForm(props) {

    const { mode, opened, onCancel, loading, onSubmit, data, permissionList } = props;

    const [form] = Form.useForm();

    const TabPane = Tabs.TabPane;

    const [permissions, setPermissions] = useState({});
    const [screenPermissions, setScreenPermissions] = useState([]);
    const [selectedTab, setSelectedTab] = useState("");

    const selectedPermissions = (permissions) => {
        let selectedPermissions = [];

        permissionList.forEach((permission) => {
            if (permissions?.some((perm) => perm.id === permission.id)) {
                selectedPermissions.push(permission.id);
            }
        })

        return selectedPermissions;
    }

    useEffect(() => {
        console.log(screenPermissions)
    }, [screenPermissions])

    useEffect(() => {
        let permissions = {};
        permissionList.forEach((item) => {
            if (permissions[item.category]) {
                permissions[item.category].push(item);
            } else {
                permissions[item.category] = [item];
            }
        })

        setPermissions(permissions);
    }, [permissionList]);

    useEffect(() => {
        setScreenPermissions(selectedPermissions(data?.permission || []));

        if (mode === "edit") {
            form.setFieldsValue({
                name: data.name,
                description: data.description,
                permissions: selectedPermissions(data?.permission || []),
                id: data.id,
            });
        }
    }, [data]);

    return (
        <Form
            form={form}
            onFinish={(e) => {
                onSubmit({ id: e.id, name: e.name, description: e.description, permissions: screenPermissions })
            }}
        >
            <Modal
                title={mode === "add" ? "Add User Role" : "Edit User Role"}
                visible={opened}
                confirmLoading={false}
                onCancel={() => {
                    form.resetFields();
                    onCancel();
                }}
                footer={
                    <div>
                        <Button style={secondaryButton()} key="back" onClick={() => { form.resetFields(); onCancel(); }}>
                            Cancel
                        </Button>
                        <Button
                            htmlType="submit"
                            style={primaryButton()}
                            onClick={() => onSubmit({ id: form.getFieldValue().id, name: form.getFieldValue().name, description: form.getFieldValue().description, permissions: screenPermissions })}
                        >
                            {mode === "add" ? "Add User Role" : "Edit User Role"}
                        </Button>
                    </div>
                }
            >
                <Form.Item
                    style={{ display: "none" }}
                    name="id"
                    value={data.id}
                />
                <Form.Item
                    label="Role Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input a role name less than or equal to 100 characters!"
                        }
                    ]}
                    initialValue={mode === "edit" && data ? data.name : ""}
                >
                    <Input
                        prefix={
                            <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="text"
                        placeholder="User Role Name"
                    />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please input a description less than or equal to 100 characters!"
                        }
                    ]}
                    initialValue={mode === "edit" && data ? data.description : ""}
                >
                    <Input
                        prefix={
                            <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="text"
                        placeholder="Description"
                    />
                </Form.Item>
                <Form.Item
                    label="Permissions"
                    name="permissions"
                    rules={[
                        {
                            required: true,
                            message:
                                "Please select at least one permission!"
                        }
                    ]}
                >
                    <Checkbox.Group
                        onChange={(e) => {
                            permissions[selectedTab].forEach((item) => {
                                if (e.includes(item.id)) {
                                    if (!screenPermissions.includes(item.id)) {
                                        setScreenPermissions([...screenPermissions, item.id]);
                                    }
                                } else {
                                    if (screenPermissions.includes(item.id)) {
                                        setScreenPermissions(screenPermissions.filter((id) => id !== item.id));
                                    }
                                }
                            })

                            console.log(screenPermissions);
                        }}
                    >
                        <Row>
                            <Tabs
                                tabPosition={"bottom"}
                                defaultActiveKey="1"
                                type="mode"
                                style={{ width: "500px" }}
                                onChange={(e) => {
                                    setSelectedTab(e);

                                    form.setFieldsValue({
                                        permissions: selectedPermissions(data?.permission || [])
                                    });
                                }}
                            >
                                {
                                    permissions && Object.keys(permissions).map((key, index) => {
                                        if (key === "Messaging" || key === "SOS") {
                                            return (null)
                                        }
                                        return (
                                            <TabPane
                                                tab={key}
                                                key={key}
                                                forceRender={true}
                                            >
                                                {permissions[key].map((item, index) => {
                                                    if (item.category === 'Messaging' || item.category === 'SOS') {
                                                        return (null)
                                                    }
                                                    return (
                                                        <Row key={index}>
                                                            <Col span={24} key={item.id} id={"perm_" + item.id}>
                                                                <Checkbox
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                </Checkbox>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                            </TabPane>
                                        );
                                    })
                                }
                            </Tabs>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Modal>
        </Form>
    )
}

export default observer(UserPermissionForm);