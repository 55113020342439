import React, {useContext, useEffect} from "react";
import { Drawer, Form, Switch, Select, DatePicker, Button, Slider } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import { contentBox, primaryButton } from "../../styles";
import API from "../../services/api";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import AnalysisSiteStore from "../../stores/analysisSuper.store";

function AnalysisMultiForm({
    setSpeedUnitString,
    visible,
    onClose,
    setHour,
    setDay,
    setPoints,
    hour,
    points
}) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
    );

    const [form] = Form.useForm();

    const Option = Select;
    const investigationSiteStore = useContext(AnalysisSiteStore)
    const [
        {
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            targetNameMappingList
        },
        setSelectedInvestigation,
        setSelectedTarget,
    ] = useInvestigationForUser();

    useEffect(() => {
        if (selectedInvestigation && investigationsForUser.length > 0) {
            const selectedInvestigationData = investigationsForUser.filter(x => Number(x.id) === Number(selectedInvestigation));

            if (selectedInvestigationData.length > 0) {
                const speedUnit = selectedInvestigationData[0].speed_unit;

                setSpeedUnitString(speedUnit);

            }
        }
    }, [investigationsForUser, selectedInvestigation])

    const onFinish = (values) => {
        const date = new Date(values.date)
        const to = new Date(date.setHours(23, 59, 59, 0))
        const from = new Date(date.setHours(0, 0, 0, 0))
        setDay(from.getTime() / 1000)
        API.analysis.multiTarget(values.target_id, from.getTime(), to.getTime()).then((res) => {
            setPoints(res.sort((a, b) => {
                return a.id - b.id
            }))
        })
    };

    const mainColour = [
        "#71FC27",
        "#fc5353",
        "#54e3ff",
        "#fffc54",
    ]

    const highlightColour = [
        "#01290D",
        "#381313",
        "#152f40",
        "#454016",
    ]

    if (!visible) {
        return null
    }

    return (
        <Drawer
            title={
                <div>
                    <h4>Multi-Target Analysis <Switch
                    style={{ marginLeft: "10px"}}
                        checkedChildren="Local Time"
                        unCheckedChildren="UTC Time"
                        defaultChecked={localStorage.getItem("utcTime") === "false"}
                        onChange={(e) => {
                            if (e) {
                                localStorage.setItem("utcTime", "false")
                            } else {
                                localStorage.setItem("utcTime", "true")
                            }
                            // onUTCChange();
                        }}
                    /></h4>
                </div>
            }
            style={{
                boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                              0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                              0 12.5px 10px rgba(0, 0, 0, 0.06),
                              0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                              0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                              0 100px 80px rgba(0, 0, 0, 0.12)`,
                width: "35%",
                marginLeft: "4rem"
            }}
            placement="left"
            onClose={onClose}
            visible={visible}
            mask={false}

            bodyStyle={{ background: '#f8f4f4' }}
            headerStyle={{ background: '#f8f4f4' }}
            closeIcon={
                <ArrowLeftOutlined />
            }
        >
            <div
                style={contentBox()}
            >
                <Form form={form} onFinish={onFinish} >
                    <Form.Item
                        name={"investigation_id"}
                        initialValue={selectedInvestigation ? selectedInvestigation : []}
                        rules={[
                            {
                                required: true,
                                message: "Please select an investigation!",
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Investigation"
                            onChange={e => {
                                setSelectedInvestigation(e);
                                investigationSiteStore.setInvestigationId(e);
                            }}
                            filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())}
                            options={investigationsForUser.map((investigation) => ({
                                    value: investigation.id,
                                    label: investigation.name,
                                }
                            ))}
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="target_id"
                        initialValue={selectedTarget ? selectedTarget : []}
                        rules={[
                            {
                                required: true,
                                message: "Please select a target!",
                            },
                        ]}
                    >
                        <Select
                            showSearch style={{ width: "100%" }}
                            placeholder="Target"
                            onChange={e => {
                                if (e?.length > 4) {
                                    e.pop();
                                }
                                setSelectedTarget(e);
                            }}
                            mode="multiple"
                        >
                            {targetsForInvestigation.map((target) => (
                                <Option id={target.id} key={target.id}>{target.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="date"
                    >
                        <DatePicker
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="submit button"
                    >
                        <Button
                            style={primaryButton({ width: "100%" })}
                            type="primary"
                            htmlType="submit"
                        >
                            Search
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div
                style={contentBox()}
            >
                <p>Hours Selected: {hour[0] > 9 ? `${hour[0]}00` : `0${hour[0]}00`} - {hour[1] > 9 ? `${hour[1]}59` : `0${hour[1]}59`}</p>
                <Slider
                    range
                    min={0}
                    max={23}
                    defaultValue={[0, 23]}
                    onChange={(e) => setHour(e)}
                />
            </div>
            <div
                style={contentBox()}
            >
                <Line
                    data={{
                        labels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
                        datasets:
                            points.map((point, index) => {
                                const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                point.points.map((p) => {
                                    data[Math.floor(((new Date().getTimezoneOffset() * -60 + p.device_utc_date_time) % (86400)) / (3600))] += 1;
                                })
                                return (
                                    {
                                        label: point.name,
                                        data: data,
                                        borderColor: mainColour[index],
                                        backgroundColor: highlightColour[index],
                                    }
                                )
                            }),
                    }}
                    options={{
                        responsive: true,
                        scales: {
                            y: {
                                title: {
                                    display: true,
                                    text: 'Number of Points'
                                }
                            },
                            x: {
                                title: {
                                    display: true,
                                    text: 'Hour of Day'
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Target Movment History Graph'
                            },
                            light: {
                                hours: hour
                            }
                        }
                    }}
                    plugins={[
                        {
                            id: 'light',
                            beforeDraw: (chart, args, options) => {
                                const { ctx, chartArea, scales: { x, y } } = chart;
                                ctx.save();
                                ctx.fillStyle = 'lightgrey';
                                ctx.fillRect(
                                    x.getPixelForValue(chart.config.options.plugins.light.hours[0]),
                                    chartArea.top,
                                    x.getPixelForValue(chart.config.options.plugins.light.hours[1]) - x.getPixelForValue(chart.config.options.plugins.light.hours[0]) + (x.getPixelForValue(chart.config.options.plugins.light.hours[1] + 1) - x.getPixelForValue(chart.config.options.plugins.light.hours[1])) - 2,
                                    chartArea.bottom - chartArea.top
                                );
                            }
                        }
                    ]}
                />
            </div>
        </Drawer >
    );
}

export default AnalysisMultiForm;