import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";
import { Parser } from "json2csv";
const fileDownload = require("js-file-download");
const parser = new Parser();
// DEBUG
window.API = API;

export class ExportHistoryStore {
    constructor() {
        this.api = API;
        this.exportHistory = [];

        // search options
        this.investigation = null;
        this.export = null;
        this.target = null;
        this.search = null;
        this.user = null;
        this.timerange = [null, null];

        this.selectedEvent = null;
        makeAutoObservable(this);
    }

    UpdateExportHistory = async () => {
        this.exportHistory = [];
        this.investigation = null;
        this.export = null;
        this.target = null;
        this.search = null;
        this.user = null;
        this.timerange = [null, null];
        const response = await this.api.exportsPageApi.getExportHistory();
        this.exportHistory = response.data.attribute;
    }

    get displayHistory() {
        let returnValue = this.exportHistory;
        if (this.investigation) {
            returnValue = returnValue.filter((item) => item.investigationID === this.investigation);
        }
        if (this.user) {
            returnValue = returnValue.filter((item) => item.userID === this.user);
        }
        if (this.timerange[0] && this.timerange[1]) {
            returnValue = returnValue.filter((item) => item.timestamp >= this.timerange[0] && item.timestamp <= this.timerange[1]);
        }
        return returnValue;
    }

    updateInvestigation(investigation) {
        this.investigation = investigation;
    }

    updateUser(user) {
        this.user = user;
    }

    updateTimeRange(timeRange) {
        this.timerange = timeRange;
    }

    selectEvent(event) {
        this.selectedEvent = event;
    }

    get ResultSelectedEvent() {
        return this.selectedEvent;
    }

    downloadHistory = async () => {
        const contents = [];
        // this will be downloaded as a csv file
        this.displayHistory.forEach((item) => {
            const timestamphd = new Date(item.timestamp * 1000);
            contents.push({
                "Export Name": item.exportName,
                "Export Type": item.exportType,
                "Investigation": item.investigationName,
                "Target": item.targetName,
                "User": item.userName,
                "Timestamp": timestamphd.toISOString(),
            });
        });
        const csv = parser.parse(contents);
        const date = new Date();
        fileDownload(csv, `ExportHistory-${date.toISOString()}.csv`);
    }

}

export default createContext(new ExportHistoryStore());
