import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tag } from 'antd';
import { formatDate } from "../../util/CommonUtils";
import {
    ExclamationCircleOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';

function Status({
    status,
    deviceUpdatedDate,
    configurationUpdatedDate,
}) {
    const STATUS = {
        0: "Default",
        1: "Pending",
        2: "Success",
        3: "Failed",
    };

    const STATUS_MESSAGE = {
        0: 'Device has not been configured and is operating with its default configuration.',
        1: 'Device configuration is ready to be sent, awaiting device connection.',
        2: 'Device was successfully configured.',
        3: 'Device rejected the configuration.',
    };

    const STATUS_COLOR = {
        0: "warning",
        1: "processing",
        2: "success",
        3: "error",
    };

    const STATUS_ICON = {
        0: <ExclamationCircleOutlined />,
        1: <SyncOutlined spin />,
        2: <CheckCircleOutlined />,
        3: <CloseCircleOutlined />,
    }

    const statusTag = STATUS[status] || "N/A";
    const lastUpdateTime = configurationUpdatedDate || deviceUpdatedDate || null;
    const lastUpdateMessage = `Last update: ${(lastUpdateTime === null ? 'Unknown' : formatDate(lastUpdateTime, 'HH:mm:ss'))}`;
    const statusMessage = `${STATUS_MESSAGE[status] || "Device configuration status unknown"} `;

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <Tag icon={STATUS_ICON[status]} color={STATUS_COLOR[status]}>{statusTag}</Tag>
            <Typography.Text>{statusMessage}
                <Typography.Text style={{ whiteSpace: 'nowrap' }}>{lastUpdateMessage}</Typography.Text>
            </Typography.Text>
        </div>
    );
}

Status.propTypes = {
    status: PropTypes.number.isRequired,
    deviceUpdatedDate: PropTypes.number,
    configurationUpdatedDate: PropTypes.number,
}

export default Status;
