import { message } from "antd";
import { Parser } from "json2csv";
import moment from "moment";
import BaseAPI from "./utilities/request.lib";

var fileDownload = require("js-file-download");

const queryString = require("query-string");

const HTTP = BaseAPI();

/**
 * Get all analytics data
 * @param serial
 * @param from
 * @param to
 * @param offset
 * @param keyword
 * @returns {Promise<*[]>}
 */
const getUserAudit = async (module, from, to, offset, keyword, user, limit) => {
    try {
        const response = await HTTP.get(
            `/user-audit/?${queryString.stringify({
                module,
                from: moment(from).startOf("day").unix(),
                to: moment(to).endOf("day").unix(),
                keyword: keyword,
                offset: offset,
                user: user,
                limit: limit
            })}`
        );
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Download analytics data to a csv file
 * @param data
 * @returns {Promise<void>}
 */
const downloadAnalyticsDump = async (data) => {
    const parser = new Parser();
    if (data.length > 0) {
        const csv = parser.parse(data);
        message.success(`Exported dump to CSV.`);
        fileDownload(csv, `export-${Date.now()}.csv`);
    } else {
        message.error(`No data Available`);
    }
};

const getALLUserAudit = async () => {
    try {
        const response = await HTTP.get(`/user-audit/all`);
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getUserAudit,
    downloadAnalyticsDump,
    getALLUserAudit,
};
