import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    Polyline,
    InfoBox,
} from "@react-google-maps/api";
import getPublicURL from "../../utilities/public-url.utility";
import {message, Image, Badge, Avatar, Tooltip, Progress, Divider, Popover, Button, Row} from "antd";
import {observer, useForceUpdate} from "mobx-react-lite";
import { convertSpeed, getGMTDateTime, mapDefault } from "../../util/CommonUtils";
import { macToMAC } from "../../util/CommonUtils";
import AnalysisTripsStore from "../../stores/analysisTrips.store";
import AnalysisSiteStore from "../../stores/analysisSuper.store";
import {toJS} from "mobx";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import {
    ArrowDownOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined, ClockCircleOutlined, EnvironmentFilled, EnvironmentOutlined, ExclamationCircleOutlined,
    PauseCircleOutlined,
    StopOutlined
} from "@ant-design/icons";
import './index.css'
import WifiActivityTableMapView from "./WifiActivityTableMapView";

// https://blog.alexdevero.com/custom-styled-google-map-react/

// https://tomchentw.github.io/react-google-maps/#installation
const TripsMap = ((
    {
        data,
        selectedPoint,
        panBy,
        style,
        selectedTarget,
        bluetoothCapture,
        currentPosition,
        error,
        cloned_captured_data,
        entityEdit,
        MarkerPlots,
        tripsFormVisible,
        speedUnitString,
        Eswitch,
        selectedTargetID,
    }) => {

    const google = window.google
    const tripsStore = useContext(AnalysisTripsStore);
    const investigationSiteStore = useContext(AnalysisSiteStore);
    const EntityStore = useContext(EntityDisplayStore);

    const [map, setMap] = useState(null);
    let location = null;
    let bounds = new window.google.maps.LatLngBounds();

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;

        map.panTo({
            lat: crd.latitude,
            lng: crd.longitude,
        });
    }

    const fail = (error) => {
        if (error.code === 1) {
          message.error("Failed to load map position. We do not have location privileges.");
        }
        console.log(`Error: ${error.code}`);
    }

    useEffect(() => {
        if (map) {
            navigator.geolocation.getCurrentPosition(success, fail, options);
        }
    }, [map]);

    useEffect(() => {
        tripsStore.latlngTracks.forEach(point => {
            bounds.extend({
                lat: point.lat,
                lng: point.lng
            })
            console.log("beep boop")
        });
        if (map) map.fitBounds(bounds, window.innerWidth * 0.15);
    },[
        tripsStore.latlngTracks,
    ]);


    const bluetoothCaptures = []

    if (bluetoothCapture && bluetoothCapture.length > 0) {
        bluetoothCaptures.push.apply(bluetoothCaptures, bluetoothCapture);
    }
    const onclick = (record, type) => {
        EntityStore.setDeviceAndOpen(record.id);
    };
    let final = [];
    let count = 0;

    if (bluetoothCaptures.length > 0) {
        bluetoothCaptures.forEach((capture) => {
            if (final.findIndex(point => point.mac_address === capture.mac_address) < 0) {
                final.push({
                    type: 'bluetooth',
                    id: capture.id,
                    device_id: capture.device_id,
                    mac_address: capture.mac_address,
                    device_name: capture.local_name,
                    manufacture_name: capture.manufacture_name,
                    class_of_device: capture.class_of_device,
                    local_name: capture.local_name,
                    entity: capture.entity_alias,
                    entity_id: capture.entity_id,
                    hitcount: 1
                });
            }
            else {

                let ind = final.findIndex(point => point.mac_address === capture.mac_address);
                final[ind].hitcount += 1;
            }


            capture.pointId = count;
            count = count + 1;

            let x = (aliasList.findIndex(item => item.latitude === capture.latitude && item.longitude === capture.longitude));


            if (x < 0) {
                aliasList.push({
                    aliases:
                        [{
                            type: 'bluetooth',
                            id: capture.id,
                            device_id: capture.device_id,
                            mac_address: capture.mac_address,
                            device_name: capture.local_name,
                            manufacture_name: capture.manufacture_name,
                            class_of_device: capture.class_of_device,
                            local_name: capture.local_name,
                            entity: capture.entity_alias,
                            entity_id: capture.entity_id,
                            local_name: capture.local_name,
                            hitcount: 1
                        }],
                    latitude: capture.latitude,
                    longitude: capture.longitude,
                    pointId: capture.pointId,
                });
            } else {
                let y = aliasList.findIndex(item => item.latitude === capture.latitude && item.longitude === capture.longitude);
                if (aliasList[y].aliases) {
                    let j = aliasList[y].aliases.findIndex(point => point.entity_id === capture.entity_id && point.mac_address === capture.mac_address);
                    if (j >= 0) {
                        aliasList[y].aliases[j].hitcount += 1;
                    } else {
                        aliasList[y].aliases.push({
                            type: 'bluetooth',
                            id: capture.id,
                            device_id: capture.device_id,
                            mac_address: capture.mac_address,
                            device_name: capture.local_name,
                            manufacture_name: capture.manufacture_name,
                            class_of_device: capture.class_of_device,
                            local_name: capture.local_name,
                            entity: capture.entity_alias,
                            entity_id: capture.entity_id,
                            local_name: capture.local_name,
                            hitcount: 1
                        });
                    }
                } else {
                    aliasList[y].aliases.push({
                        type: 'bluetooth',
                        id: capture.id,
                        device_id: capture.device_id,
                        mac_address: capture.mac_address,
                        device_name: capture.local_name,
                        manufacture_name: capture.manufacture_name,
                        class_of_device: capture.class_of_device,
                        local_name: capture.local_name,
                        entity: capture.entity_alias,
                        entity_id: capture.entity_id,
                        local_name: capture.local_name,
                        hitcount: 1
                    });
                }
            }
        })
    }
    return (

        <Fragment>
            <GoogleMap
                defaultCenter={{
                    lat: -27.4705,
                    lng: 153.0260,
                }}
                yesIWantToUseGoogleMapApiInternals
                mapContainerStyle={style}
                onIdle={() => {

                    if (map.getCenter()) {
                        localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                    }
                }}
                zoom={15}
                onLoad={(map) => {
                    setMap(map);
                    tripsStore.setMapRef(map);
                }}
            >
                {tripsStore.multipleTarget && (
                    tripsStore.latlngTracksMultiple.map(track => {
                        return (
                            <Polyline
                                path={track}
                                geodesic={true}
                                zIndex={3}
                                options={{
                                    strokeColor: "#125473",
                                    strokeOpacity: 0.75,
                                strokeWeight: 3,
                            }}
                            />
                        )
                    })
                )}

                {tripsStore.multipleTarget && (
                    tripsStore.MapDisplayPointsMultiple.map(trip => {
                        return trip.map((target, index) => {
                            target.id = index;
                            if (target.latitude && target.longitude) {
                                let markerType;
                                if (index === 0) {
                                    markerType = {
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        fillColor: "#27B8FC",
                                        fillOpacity: 1,
                                        strokeWeight: 1,
                                        strokeColor: "#01290D",
                                        rotation: target.heading,
                                        scale: 5,
                                        anchor: new window.google.maps.Point(0, 0)
                                    };
                                } else if (index === tripsStore.MapDisplayPoints.length - 1) {
                                    markerType = {
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        fillColor: "#FC2771",
                                        fillOpacity: 1,
                                        strokeWeight: 1,
                                        strokeColor: "#01290D",
                                        rotation: target.heading,
                                        scale: 5,
                                        anchor: new window.google.maps.Point(0, 0)
                                    };
                                } else if (target.speed < 1) {
                                    markerType = {
                                        path: window.google.maps.SymbolPath.CIRCLE,
                                        fillColor: investigationSiteStore.getColourByTargetId(target.targetId),
                                        fillOpacity: 1,
                                        strokeWeight: 1,
                                        strokeColor: "#01290D",
                                        rotation: target.heading,
                                        scale: 5,
                                        anchor: new window.google.maps.Point(0, 0)
                                    };
                                } else {
                                    markerType = {
                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        fillColor: investigationSiteStore.getColourByTargetId(target.targetId),
                                        fillOpacity: 1,
                                        strokeWeight: 1,
                                        strokeColor: "#01290D",
                                        rotation: target.heading,
                                        scale: 4,
                                        anchor: new window.google.maps.Point(0, 3)
                                    };
                                }

                                return (
                                    <Marker
                                        position={{
                                            lat: target.latitude,
                                            lng: target.longitude,
                                        }}
                                        icon={markerType}

                                        zIndex={4}

                                        onClick={() => tripsStore.addToMarkedPoints(index)}
                                    >
                                        <div
                                        >
                                            {
                                                tripsStore.makerVisible(index)
                                                    ?
                                                    <InfoWindow
                                                        onCloseClick={() => tripsStore.removeFromMarkedPoints(index)}
                                                        position={{
                                                            lat: target.latitude,
                                                            lng: target.longitude,
                                                        }}
                                                    >
                                                        <div>
                                                            <Row><p style={{color: '#717171'}}>Time:</p>
                                                                <p> {getGMTDateTime(target.device_utc_date_time)}</p></Row>
                                                            <Row><p style={{color: '#717171'}}>Speed:</p> <p> {target.speed} Kmh
                                                                / {Number((target.speed / 1.60934).toFixed(2))} Mph</p></Row>
                                                            <Row><p style={{color: '#717171'}}>Latitude:</p> <p> {target.latitude.toFixed(4)}</p></Row>
                                                            <Row><p style={{color: '#717171'}}>Longitude:</p>
                                                                <p> {target.longitude.toFixed(4)}</p></Row>
                                                        </div>
                                                    </InfoWindow>
                                                    :
                                                    <div></div>
                                            }
                                        </div>

                                    </Marker>
                                )
                            }
                        });
                    })
                )};

                {!tripsStore.multipleTarget &&
                    <Polyline
                        path={tripsStore.latlngTracks}
                        geodesic={true}
                        zIndex={3}
                        options={{
                            strokeColor: "#125473",
                            strokeOpacity: 0.75,
                            strokeWeight: 3,
                        }}
                    />
                }

                {/*Standard Markers layer*/}
                { !tripsStore.multipleTarget && tripsStore.MapDisplayPoints.map((target, index) => {
                    if (target.latitude && target.longitude) {

                        let markerType;
                        if (index === 0) {
                            markerType = {
                                url: `${getPublicURL()}/TargetStart.png`,
                                scaledSize: new google.maps.Size(55, 60),

                            };
                        } else if (index === tripsStore.MapDisplayPoints.length - 1) {
                            markerType = {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                fillColor: "#FC2771",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                rotation: target.heading,
                                scale: 5,
                                anchor: new window.google.maps.Point(0, 0)
                            };
                        } else if (target.speed < 1) {
                            markerType = {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                fillColor: investigationSiteStore.getColourByTargetId(target.targetId),
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                rotation: target.heading,
                                scale: 5,
                                anchor: new window.google.maps.Point(0, 0)
                            };
                        } else {
                            markerType = {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: investigationSiteStore.getColourByTargetId(target.targetId),
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                rotation: target.heading,
                                scale: 4,
                                anchor: new window.google.maps.Point(0, 3)
                            };
                        }

                        return (
                            <div style={{zIndex: 1}}>
                                <Marker
                                    position={{
                                        lat: target.latitude,
                                        lng: target.longitude,
                                    }}
                                    icon={markerType}

                                    zIndex={4}

                                    onClick={() => tripsStore.addToMarkedPoints(index)}

                                >
                                    <div
                                    >
                                        {
                                            tripsStore.makerVisible(index)
                                                ?
                                                <InfoWindow
                                                    onCloseClick={() => tripsStore.removeFromMarkedPoints(index)}
                                                    position={{
                                                        lat: target.latitude,
                                                        lng: target.longitude,
                                                    }}
                                                >
                                                    <div>
                                                        <Row><p style={{color: '#717171'}}><ClockCircleOutlined /> Time:</p>
                                                            <p> {getGMTDateTime(target.device_utc_date_time)}</p></Row>
                                                        <Row><p style={{color: '#717171'}}><ArrowRightOutlined /> Speed:</p> <p> {target.speed} Kmh
                                                            / {Number((target.speed / 1.60934).toFixed(2))} Mph</p></Row>
                                                        <Row><p style={{color: '#717171'}}><EnvironmentOutlined /> Latitude:</p> <p> {target.latitude.toFixed(4)}</p></Row>
                                                        <Row><p style={{color: '#717171'}}><EnvironmentOutlined /> Longitude:</p>
                                                            <p> {target.longitude.toFixed(4)}</p></Row>
                                                    </div>
                                                </InfoWindow>
                                                :
                                                <div></div>
                                        }
                                    </div>

                                </Marker>
                            </div>
                    )
                    }
                    target.id = index;
                })}
                {/* clone capture markers layer*/}
                {tripsStore.WifiDevicesForMap.map((entity, trueIndex) => {
                    let icon = {};
                    try {

                        if (entity.end === true) {
                            icon = {
                                url: tripsStore.isWiFiInfoVis(entity.key) ? `${getPublicURL()}/EndTripIcon_hover.png`: `${getPublicURL()}/EndTripIcon_unhover.png`,
                                scaledSize: tripsStore.isWiFiInfoVis(entity.key) ? new google.maps.Size(60, 66) : new google.maps.Size(55, 60),
                            }
                        }
                        else if (tripsStore.isWifiCloned(entity.capture_type)) {
                            icon = {
                                url: `${getPublicURL()}/CloneCapture100px.png`,
                                scaledSize: tripsStore.isWiFiInfoVis(entity.key) ? new window.google.maps.Point(50, 50) : new google.maps.Size(40, 40),
                                anchor:  new window.google.maps.Point(40, 40),
                            };

                        } else {
                            icon = {
                                url: `${getPublicURL()}/sniff.png`,
                                scaledSize: tripsStore.isWiFiInfoVis(entity.key) ? new window.google.maps.Point(40, 40) : new google.maps.Size(35, 35),
                            };
                        }
                    } catch {
                        icon = {
                            url: `${getPublicURL()}/BluetoothCapture.png`,
                            scaledSize: new google.maps.Size(25, 25),
                        }
                    }
                    return (
                        <div
                            style={{
                                zIndex: 87
                            }}
                        >
                            <Marker
                                position={{
                                    lat: entity.latitude,
                                    lng: entity.longitude,
                                }}
                                icon={icon}
                                zIndex={9999999}
                                onClick={() => {
                                    // TODO add this so it opens drawer
                                    tripsStore.setEntityDrawVisible(!tripsStore.entityDrawVisible);
                                    if (!(entity.end === true)) {
                                        tripsStore.addWiFiInfoVis(entity.key)
                                    }
                                }}
                                onMouseOver={() => tripsStore.setHoverWifiInfoVis(entity.key)}
                                onMouseOut={() => tripsStore.setHoverExitWifiInfoVis()}
                            >
                                {
                                    tripsStore.isWiFiInfoVis(entity.key)
                                        ?
                                        <InfoWindow
                                            position={{
                                                lat: entity.latitude,
                                                lng: entity.longitude,
                                            }}
                                            width={700}
                                            onCloseClick={() => tripsStore.removeWiFiInfoVis(entity.key)}
                                        >
                                            <WifiActivityTableMapView entity={entity} map={map}/>
                                        </InfoWindow>
                                        :
                                        <div></div>
                                }
                            </Marker>
                        </div>
                    )
                })}
            </GoogleMap>
        </Fragment>
    );
});

export default observer(TripsMap);
