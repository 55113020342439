import { Button, Col, message, Row, Spin } from "antd";
import './stopsPage.scss'
import { Parser } from "json2csv";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { printComponent } from "react-print-tool";
import StopsForm from "../../components/AnalysisStopsForm";
import StopsMap from "../../components/AnalysisStopsMap";
import StopsPanel from "../../components/AnalysisStopsPanel";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import API from "../../services/api";
import Layout from "../AppLayout";
import { primaryButton, secondaryButton } from "../../styles";
import getPublicURL from "../../utilities/public-url.utility";
import {
    formatDate,
    getDefaultAnalysisInvestigation,
    getDefaultAnalysisTarget, getGMTDateTime,
    setDefaultAnalysisInvestigation, setDefaultAnalysisTarget
} from "../../util/CommonUtils";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { browserName, browserVersion } from "react-device-detect";

const fileDownload = require("js-file-download");
const prettyMS = require("pretty-ms");
const parser = new Parser();

const secondsToHMSFormat = (sec_num) => {
    // Previous Implementation (Keep the changes)
    /* let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;*/

    const formattedDate = moment.utc(sec_num).format("HH:mm:ss");

    return formattedDate;
};

const PrintPDF = ({
    selectedTarget,
    selectedInvestigation,
    investigations,
    targets,
    data,
    dateRange,
    timeInterval,
}) => {
    const investigationDetails =
        investigations &&
        investigations.find(
            (investigation) => investigation.id === Number(selectedInvestigation)
        );

    const targetDetails =
        targets && targets.find((target) => target.id === Number(selectedTarget));
    return (
        <React.Fragment>
            <div
                style={{
                    top: "0",
                    margin: "10px 10px",
                }}
            >
                <strong>
                    Stops for {targetDetails.name} of {investigationDetails.name} from{" "}
                    {dateRange[0].format("LLLL")} to {dateRange[1].format("LLLL")}
                </strong>
                {timeInterval && timeInterval > 5 && (
                    <strong>
                        <br />
                        Filter is selected. Only stops greater than{" "}
                        {prettyMS(timeInterval * 1000)} are shown.
                    </strong>
                )}
            </div>
            <table style={{ margin: "40px 10px", width: "100%", display: "table" }}>
                <thead>
                    {[
                        // "Target Name",
                        // "Start Date",
                        // "End Date",
                        // "Address",
                        // "Stopped For",
                        // "Coordinates"
                        {
                            name: "Start Date",
                            width: "10%",
                        },
                        {
                            name: "End Date",
                            width: "10%",
                        },
                        {
                            name: "Address",
                            width: "40%",
                        },
                        {
                            name: "Stopped For",
                            width: "10%",
                        },
                        {
                            name: "Coordinates",
                            width: "35%",
                        },
                    ].map((header) => (
                        <th
                            style={{
                                width: header.width,
                            }}
                        >
                            {header.name}
                        </th>
                    ))}
                </thead>
                <tbody>
                    {data.map((record) => (
                        <tr style={{ paddingBottom: "10px" }}>
                            {/* <td>{record.target_name}</td> */}
                            <td>
                                {
                                    getGMTDateTime(record.device_utc_date_time, false)
                                }
                            </td>
                            <td>
                                {" "}
                                {
                                    getGMTDateTime(Number(record.device_utc_date_time + record.stopped), false)
                                }
                            </td>
                            <td>{record.address || "N/A"}</td>
                            <td>{prettyMS(record.stopped * 1000)}</td>
                            <td>
                                {record.latitude}, {record.longitude}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div
                style={{
                    // position: "fixed",
                    bottom: "0",
                    margin: "10px 10px",
                }}
            >
                <strong>
                    Created By {JSON.parse(localStorage.getItem("profile")).name} on{" "}
                    {new Date().toLocaleString()}
                </strong>
            </div>
        </React.Fragment>
    );
};

function AnalysisStopsPage() {

    const [timeInterval, setTimeInterval] = useState("no-filter");
    const [stops, setStops] = useState([]);
    const [filteredStops, setFilteredStops] = useState([]);
    const [currentlySelectedStop, setCurrentlySelectedStop] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [dateRange, setDateRange] = useState([]);

    const [showStopsMap, setShowStopsMap] = useState(false);
    const [visible, setVisible] = useState(true);
    const [margin, setMargin] = useState('33%');
    const [buttonOpacity, setButtonOpacity] = useState('1.0');
    const [buttonMargin, setButtonMargin] = useState('32%');
    const showDrawer = () => {
        setButtonOpacity('1.0');
        setButtonMargin('32%');
        setVisible(true);
        setMargin("33%");

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin('0px');
    };

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    const [
        {
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            targetNameMappingList
        },
        setSelectedInvestigation,
        setSelectedTarget,
    ] = useInvestigationForUser();

    useEffect(() => {
        const filteredStops = stops.filter((x) => {
            if (timeInterval && Number.isInteger(timeInterval)) {
                return x.stopped >= timeInterval;
            } else {
                return true;
            }
        });
        setFilteredStops(filteredStops);
    }, [stops, timeInterval]);

    useEffect(() => {
        if (investigationsForUser.length > 0) {
            const _currentInvestigation =
                getDefaultAnalysisInvestigation();
            setSelectedInvestigation(_currentInvestigation);
        }
    }, [
        investigationsForUser,
        setSelectedInvestigation,
        selectedTarget,
        setSelectedTarget]);

    useEffect(() => {
        if (targetsForInvestigation.length > 0) {
            if (!selectedTarget) {
                const _currentTarget =
                    getDefaultAnalysisTarget();
                setSelectedTarget(_currentTarget);
            }
        }
    }, [targetsForInvestigation, setSelectedTarget, selectedTarget]);

    useEffect(() => {
        if (selectedTarget && selectedInvestigation) {
            API.analysis.getDeviceLogDateRange({
                investigation_id: selectedInvestigation,
                target_id: selectedTarget
            }).then((records) => {
                setDateRange(records);
            });
        }
    }, [selectedInvestigation, selectedTarget]);

    function onDownload(data) {
        const parsedData = data.map((record) => {
            return {
                targetName: targetsForInvestigation.filter(x => x.id === record.target_id).length > 0 ? targetsForInvestigation.filter(x => x.id === record.target_id)[0].name : 'N/A',
                fromDateType:
                    formatDate(record.device_utc_date_time, ", HH:mm:ss")
                ,
                toDateType:
                    formatDate(Number(record.device_utc_date_time + record.stopped), ", HH:mm:ss")
                ,
                address: record.address || null,
                stoppedFor: secondsToHMSFormat(record.stopped * 1000),
                coordinates: `${record.latitude}, ${record.longitude}`,
            };
        });

        const csv = parser.parse(parsedData);
        message.success(`Exported dump to CSV.`);
        fileDownload(csv, `export-stops-${Date.now()}.csv`);
    }

    const [speedUnitString, setSpeedUnitString] = useState("");

    useEffect(() => {
        // console.log('use effect hooks', investigationsForUser, selectedInvestigation);

        if (selectedInvestigation && investigationsForUser.length > 0) {
            const selectedInvestigationData = investigationsForUser.filter(x => Number(x.id) === Number(selectedInvestigation));
            // console.log('selected investigation data', selectedInvestigationData);

            if (selectedInvestigationData.length > 0) {
                const speedUnit = selectedInvestigationData[0].speed_unit;

                // const speed_Units = speedUnits();

                // const speed_unit_string = speed_Units.filter((unit) => unit.id === speedUnit);

                setSpeedUnitString(speedUnit);

                // console.log('speed units', speedUnit);
            }

        }

    }, [investigationsForUser, selectedInvestigation])
    const setHover = () => {
        setButtonOpacity('0.7');
    }

    const unSetHover = () => {
        setButtonOpacity('1.0');
    }

    return (
        <Layout showNavigation hideMargins>
            {showStopsMap === false && (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                    }}
                >
                    <Spin></Spin>
                </div>
            )}

            {showStopsMap === true && (
                <div>
                    <div style={{
                        position: "fixed",
                        zIndex: 2,
                        width: "100%"
                    }}>
                        < Button type="primary" onClick={visible ? onClose : showDrawer}
                            style={primaryButton({
                                position: "absolute",
                                top: "40vh",
                                zIndex: 2,
                                height: "15vh",
                                marginTop: "0%",
                                left: buttonMargin,
                                marginLeft: "2px",
                                borderLeftColor: "#0066CC",
                                marginBottom: "2px",
                                opacity: buttonOpacity,
                            })}
                            onMouseEnter={() => { setHover() }}
                            onMouseLeave={() => { unSetHover() }}
                        >
                            {
                                visible
                                    ?
                                    <ArrowLeftOutlined
                                        width={25}
                                        height={25}
                                    />
                                    :
                                    <ArrowRightOutlined
                                        width={25}
                                        height={25}
                                    />
                            }
                        </Button>
                    </div>
                    <StopsPanel
                        style={{ maxHeight: "50vh" }}
                        stops={filteredStops}
                        onClose={onClose}
                        visible={visible}
                        targetNameMappingList={targetNameMappingList}
                        onStopClick={(item) => {
                            setCurrentlySelectedStop(item);
                        }}
                        currentlySelectedStop={currentlySelectedStop}
                        speedUnitString={speedUnitString}
                        onUTCChange={() => {
                            if (selectedTarget && selectedInvestigation) {
                                API.analysis.getDeviceLogDateRange({
                                    investigation_id: selectedInvestigation,
                                    target_id: selectedTarget
                                }).then((records) => {
                                    setDateRange(records);
                                });
                            }
                        }}
                    >
                        <StopsForm
                            onIntervalSelect={(interval) => {
                                setTimeInterval(Number(interval));
                            }}
                            interval={timeInterval}
                            onInvestigationSelect={(investigationID) => {
                                setSelectedInvestigation(investigationID);
                                setDefaultAnalysisInvestigation(investigationID)
                            }}
                            onTargetSelect={(targetID) => {
                                setSelectedTarget(targetID);
                                setDefaultAnalysisTarget(targetID)
                            }}
                            investigations={investigationsForUser}
                            targets={targetsForInvestigation}
                            dateRange={dateRange}
                            selectedTarget={selectedTarget}
                            selectedInvestigation={selectedInvestigation}
                            handleSubmit={(values) => {
                                const [from, to] = values.timeRange;
                                setSelectedDateRange([from, to]);
                                if (selectedTarget && selectedInvestigation) {
                                    API.analysis
                                        .getStops({
                                            investigation_id: selectedInvestigation,
                                            target_id: [selectedTarget],
                                            to: moment(to).endOf("day").unix(),
                                            from: moment(from).startOf("day").unix(),
                                        })
                                        .then((stops) => {
                                            setStops(stops);
                                        })
                                        .catch((err) => {
                                            setStops([]);
                                        });
                                }
                            }}
                        />
                        {filteredStops.length > 0 && (
                            <Row style={{ paddingTop: "10px" }}>
                                <Col span={12}>
                                    <Button
                                        onClick={() => {
                                            onDownload(filteredStops);
                                        }}
                                        style={secondaryButton({
                                            width: "100%",
                                        })}
                                    >
                                        Export
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        onClick={async () => {
                                            await printComponent(
                                                <PrintPDF
                                                    timeInterval={timeInterval}
                                                    dateRange={selectedDateRange}
                                                    selectedInvestigation={selectedInvestigation}
                                                    selectedTarget={selectedTarget}
                                                    investigations={investigationsForUser}
                                                    targets={targetsForInvestigation}
                                                    data={filteredStops}
                                                />
                                            );
                                        }}
                                        style={secondaryButton({
                                            width: "100%",
                                        })}
                                    >
                                        Print
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </StopsPanel>
                </div>
            )}

            {showStopsMap === true && (
                <div style={{ marginLeft: margin }} className="stopsPage">
                    <StopsMap
                        panBy={[-300, 0]}
                        data={filteredStops}
                        selectedPoint={currentlySelectedStop}
                    />
                </div>
            )}
        </Layout>
    );
};

export default observer(AnalysisStopsPage);
