import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, Select, Tag, Tooltip, Typography, Modal, Layout, message } from "antd";
import React from "react";
import { formatDate } from "../../util/CommonUtils";
import { componentFactory } from '../../factories/DeviceConfig';

import { primaryButton, secondaryButton } from '../../styles';
import { event } from 'jquery';

class BasicWrapperConfigurationForm extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((error, values) => {
            let goodValues = true;
            for (let i = 0; i < this.props.fieldConstraints.fields.length; i++) {
                // go through array and deal with weird values that have mins of 1 because they map to 0th element of an array
                // interface doesnt handle array mapping to values going backwards so form gives nth element and interface takes that as the value
                const name = this.props.fieldConstraints.fields[i].name;
                // dealing with invisable fields
                if (this.props.fieldConstraints.fields[i].visible === 'false'){
                    values[this.props.fieldConstraints.fields[i].name] = this.props.fieldConstraints.fields[i].value;
                }
                else if (this.props.fieldConstraints.fields[i].hasOwnProperty("valueListFields") && this.props.fieldConstraints.fields[i].valueListFields.length > 2) {
                    values[this.props.fieldConstraints.fields[i].name] = this.props.fieldConstraints.fields[i].valueListFields[values[this.props.fieldConstraints.fields[i].name]].value;
                }
            }
            if (!error) {
                this.props.onSubmit({ values });
            } else {
                for (let i = 0; i < this.props.fieldConstraints.fields.length; i++) {
                    if (error[this.props.fieldConstraints.fields[i].name] && this.props.fieldConstraints.fields[i].hasOwnProperty("valueListFields") && this.props.fieldConstraints.fields[i].valueListFields.length === 2) {
                        if (error[this.props.fieldConstraints.fields[i].name]) {

                            if (values[this.props.fieldConstraints.fields[i].name] === true) {
                                values[this.props.fieldConstraints.fields[i].name] = this.props.fieldConstraints.fields[i].valueListFields[0].value
                            } else {
                                values[this.props.fieldConstraints.fields[i].name] = this.props.fieldConstraints.fields[i].valueListFields[1].value
                            }
                        }
                    } else if (error[this.props.fieldConstraints.fields[i].name] && !(this.props.fieldConstraints.fields[i].hasOwnProperty("valueListFields"))) {
                        goodValues = false
                        this.props.setVisible(false)
                        message.error(`${this.props.fieldConstraints.fields[i].name}: ${error[this.props.fieldConstraints.fields[i].name].errors[0].message}`)
                    }
                }
                if (goodValues === true) {
                    this.props.onSubmit({ values });
                }
            }
        }
        );
    };

    onCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    reset = (fields) => {

        let values = {}
        fields.map((field) => {
            values[field.name] = field.default
        })



        this.props.onSubmit({ values });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const config = this.props.configData;
        const settings =
            this.props.configData &&
            JSON.parse(this.props.configData.settings) &&
            JSON.parse(this.props.configData.settings).settings;


        //
        //
        return (
            <Layout style={{ padding: '20px', borderRadius: '10px' }}>
                <Form
                    className="tab1-form"
                >
                    <div>
                        {this.props.configData !== undefined ? (
                            <Status
                                status={config.status}
                                deviceCreatedDate={config.created_at}
                                deviceUpdatedDate={config.device_updated_at}
                                configurationUpdatedDate={config.updated_at}
                            ></Status>
                        ) : (
                            <Alert
                                type="error"
                                message="The device has no saved configurations for this tab. Please click update to load the device with the default/updated configuration."
                                banner
                            />
                        )}
                    </div>

                    {this.props.fieldConstraints.fields.map((field, index) => {

                        return (
                            <React.Fragment>
                                {componentFactory(field, settings, getFieldDecorator, this.props.isHuntsman)}
                            </React.Fragment>
                        );

                    })}

                    {!this.props.fieldConstraints && (
                        <p>This configuration hasn't been made available yet.</p>
                    )}

                    <div style={{ display: 'inline', float: 'right' }}>
                        <Button
                            onClick={
                                () => {
                                    // this.props.setVisible(true)
                                    // this.handleSubmit()
                                    this.reset(this.props.fieldConstraints.fields)
                                    this.props.form.resetFields()
                                }
                            }
                            style={secondaryButton({ margin: '0.3em' })}
                            key="reset"
                        >
                            Reset To default
                        </Button>
                        <Button
                            onClick={
                                (e) => {
                                    this.handleSubmit(e)
                                }
                            }
                            style={primaryButton({ margin: '0.3em' })}
                            key="submit"
                        >
                            Update
                        </Button>
                    </div>
                </Form>
            </Layout>
        );
    }
}

export default Form.create({})(BasicWrapperConfigurationForm);

const Status = ({
    status,
    deviceCreatedDate,
    deviceUpdatedDate,
    configurationUpdatedDate,
}) => {
    const STATUSES = {
        1: "Pending",
        2: "Success",
        3: "Failed",
    };
    const STATUSES_COLOR = {
        1: "#f39c12",
        2: "#27ae60",
        3: "#c0392b",
    };

    const statusInText = STATUSES[status] || "N/A";
    return (
        <Typography.Text>
            <Tag color={STATUSES_COLOR[status]}>{statusInText}</Tag>
            {statusInText === "Pending"
                ? configurationUpdatedDate !== null
                    ? `Configuration was updated at ${formatDate(configurationUpdatedDate, 'HH:mm:ss')}. Device has not received the settings.`
                    : `Configuration was updated at ${formatDate(deviceCreatedDate, 'HH:mm:ss')}. Device has not received the settings.`
                : statusInText === "Success"
                    ? `Configuration was pushed to the device on  ${formatDate(deviceUpdatedDate, 'HH:mm:ss A')}. Device has accepted the settings. `
                    : `Device has not accepted the current configuration. ${formatDate(deviceUpdatedDate, 'HH:mm:ss A')}.`}
        </Typography.Text>
    );
};
