import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const getDashboardAlertLogs = async ({ investigation_id }) => {
    const fetchAlerts = () => {
        return new Promise(async (resolve, reject) => {
            const response = await HTTP.get("/alert-log/", {
                params: {
                    investigation_id: investigation_id
                },
            });

            if (response.data.code === "EMPTY_RESULT") {
                return resolve([]);
            }

            const data = response.data.data.alertLog;
            // const sortedAlerts = data.sort((a, b) =>
            //   a.alert_log_datetime > b.alert_log_datetime ? 1 : -1
            // );
            return resolve(data);
        });
    };

    try {
        const promises = await fetchAlerts();
        const alerts = await Promise.all(promises);
        return Promise.resolve(alerts.flat());
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllAlerts = async () => {
    try {
        const response = await HTTP.get("/alert");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.alert;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAlertTypes = async () => {
    try {
        const response = await HTTP.get("/alert-type");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.alertType;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const createAlert = async ({
    alert_name,
    investigation_id,
    target_id,
    user_id,
    alert_type_id,
}) => {
    try {
        const response = await HTTP.post("/alert/", {
            alert_name,
            investigation_id,
            target_id,
            user_id,
            alert_type_id,
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.alert[response.data.data.alert.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteAlert = async (alertID) => {
    try {
        await HTTP.delete(`/alert/${alertID}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        return Promise.resolve(true);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateAlert = async (
    alert_id,
    { alert_name, investigation_id, target_id, user_id, alert_type_id }
) => {
    try {
        const response = await HTTP.put(`/alert/${alert_id}`, {
            alert_name,
            investigation_id,
            target_id,
            user_id,
            alert_type_id,
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.alert[response.data.data.alert.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getDashboardAlertLogs,
    getAllAlerts,
    getAlertTypes,
    createAlert,
    deleteAlert,
    updateAlert,
};
