import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import API from "../../services/api";
import Layout from "../AppLayout";
import React, { useEffect, useState, useContext } from 'react';
import WifideviceAuditTab from "../../components/WifiAuditTab"
import WifiNickNameCloned from "../../components/WifiNickNameCloned";

const { TabPane } = Tabs;

function AuditManagementPage() {
    const [allAnalytics, setAllAnalytics] = useState([]);
    const [allSOS, setAllSOS] = useState([]);
    const [analyticsCount, setAnalyticsCount] = useState(0);

    const [userAudit, setUserAudit] = useState([]);
    const [userAuditCountAll, setUserAuditCountAll] = useState(0);

    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [nickNameHistory, setNickNameHistory] = useState([]);
    const [deviceTargetHistory, setDeviceTargetHistory] = useState([]);
    const [huntsmanLiveData, setHuntsmanLiveData] = useState([]);
    const [huntsmanLastId, setHuntsmanLastId] = useState(null);
    const [
        deviceTargetHistoryCountAll,
        setDeviceTargetHistoryCountAll,
    ] = useState(0);
    const [targets, setTargets] = useState([]);
    const [selectedInvestigation, setSelectedInvestigation] = useState();
    const [wifiCloneHistory, setWifiClonedHistory] = useState([]);

    useEffect(() => {
        searchInvestigationhandleSubmit(selectedInvestigation);
    }, [selectedInvestigation]);

    const searchInvestigationhandleSubmit = async (name) => {
        const response = await API.target.getAllTargetsByInvestigation(name);
        setTargets(response);
        console.log(response);
    }

    const onWIFIBtnClicked = async (name,  keyword) => {
        setSpinnerVisible(true);
        const response = await API.target.getClonedMacAddressAllTarget(name, keyword);
        setWifiClonedHistory(response);
        setAnalyticsCount(response.deviceAnalyticCount);
        setSpinnerVisible(false);
    };

    const onNickNameBtnClicked = async (name) => {
        setSpinnerVisible(true);
        const response = await API.nickName.getAllNickNamesByTarget(name);
        setNickNameHistory(response);
        setAnalyticsCount(response.deviceAnalyticCount);
        setSpinnerVisible(false);
    }

    const onLiveMode = async (serial, from, to) => {
        setSpinnerVisible(true);
        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.analytics.getAllHuntsmanAnalytics(
            serial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            50
        );
        setHuntsmanLiveData(response.deviceAnalyticLatest);
        if (response.deviceAnalyticLatest.length > 0){
            const huntsmanIds = response.deviceAnalyticLatest.map(x => x.id);
            console.log(Math.max(...huntsmanIds))

            setHuntsmanLastId(Math.max(...huntsmanIds))
        }
        setSpinnerVisible(false);
    }

    const timeIntervalHuntsmanAnalytics = async (serial, from, to) => {
        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.analytics.getAllHuntsmanAnalytics(
            serial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            1,
            huntsmanLastId
        );

        if (response.deviceAnalyticLatest.length > 0) {
            let huntsManDataLatest = huntsmanLiveData.filter(x => x.id === response.deviceAnalyticLatest[0].id);
            if (huntsManDataLatest.length === 0) {
                setHuntsmanLiveData([...huntsmanLiveData, ...response.deviceAnalyticLatest])
            }
        }
    }

    const onUserAuditSearchBtnClicked = async (module, from, to, offset, keyword, user) => {
        setSpinnerVisible(true);

        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.userAudit.getUserAudit(
            module,
            fromConverted, // Convert seconds to milliseconds
            toConverted, // Convert seconds to milliseconds
            offset,
            keyword,
            user,
            0
        );

        console.log('response user audit', response)
        setUserAuditCountAll(response.total);
        setUserAudit(response.userAudit);

        setSpinnerVisible(false);
    }

    const onDuressFilterClicked = async (investigation, from, to) => {
        setSpinnerVisible(true);

        const response = await API.sos.getAllSOS(
            investigation,
            from.toDate(), // Convert seconds to milliseconds
            to.toDate() // Convert seconds to milliseconds
        );
        setAllSOS(response.sos);
        setSpinnerVisible(false);
    };

    const onFilterDeviceTargetHistory = async (
        device_serial,
        target,
        from,
        to,
        offset,
    ) => {
        const response = await API.deviceTargetHistory.getAllDeviceTargetHistory(
            target ? target : null,
            device_serial ? device_serial : null,
            from, // Convert seconds to milliseconds
            to, // Convert seconds to milliseconds
            offset,
            0
        );

        console.log(response, "response");

        if (response && response.deviceTargetHistory && response.deviceTargetHistory.length > 0) {
            setDeviceTargetHistoryCountAll(response.Total);
            setDeviceTargetHistory(response.deviceTargetHistory);
        } else {
            setDeviceTargetHistoryCountAll(0);
            setDeviceTargetHistory([]);
        }
    };

    return (
        <div>
            <Layout
                showNavigation
                title={"WiFi Management"}
                description="Configure wiFi settings for huntsman devices."
                extra={[]}
            >
                <div className="AuditPage">
                    <Tabs tabPosition={"left"} defaultActiveKey="1">
                        <TabPane tab={<span>Aliasing</span>} key="1">
                            <WifiNickNameCloned
                                onFilterClicked={onNickNameBtnClicked}
                                analytics={nickNameHistory}
                                analyticsCount={analyticsCount}
                                targets={targets}
                                onInvestigationSelect={(investigationID) => {
                                    setSelectedInvestigation(investigationID);
                                }}
                                spinnerVisible={spinnerVisible}
                                huntsmanData={huntsmanLiveData}
                                onLiveModeClicked={onLiveMode}
                                timeIntervalHuntsmanAnalytics={timeIntervalHuntsmanAnalytics}
                            />
                        </TabPane>
                        <TabPane tab={<span> WiFi Clone Captured Targets</span>} key="2">
                            <WifideviceAuditTab
                                onFilterClicked={onWIFIBtnClicked}
                                analytics={wifiCloneHistory}
                                analyticsCount={analyticsCount}
                                targets={targets}
                                onInvestigationSelect={(investigationID) => {
                                    setSelectedInvestigation(investigationID);
                                }}
                                spinnerVisible={spinnerVisible}
                                huntsmanData={huntsmanLiveData}
                                onLiveModeClicked={onLiveMode}
                                timeIntervalHuntsmanAnalytics={timeIntervalHuntsmanAnalytics}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </Layout>
        </div>
    );
}

export default observer(AuditManagementPage);
