import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import React from 'react';


class captcha extends React.Component {

    componentDidMount () {
        loadCaptchaEnginge(6);
    }

    doSubmit(user_captcha_value){
        if (validateCaptcha(user_captcha_value) == true) {
            alert('Captcha Matched');
        }

        else {
            alert('Captcha Does Not Match');
        }
    }

    render() {
        return (<div>
            <LoadCanvasTemplateNoReload />
        </div>);
    }
}

export default( new captcha());