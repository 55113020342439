import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
import { Marker, InfoWindow, Polyline } from "@react-google-maps/api";
import { convertSpeed, getGMTDateTime, mapDefault } from "../util/CommonUtils";
// Services
import API from "../services/api";
import { Parser } from "json2csv";
const fileDownload = require("js-file-download");
const parser = new Parser();
// DEBUG
window.API = API;

export class AnalysisFenceStore {
    constructor() {
        this.api = API;
        this.exportHistory = [];

        // search options
        this.investigation = null;
        this.export = null;
        this.target = null;
        this.search = null;
        this.user = null;
        this.timerange = [null, null];

        this.action = null;

        this.loading = false;

        this.selectedEvent = null;
        this.fenceBounds = null;
        this.startTime = null;
        this.endTime = null;
        this.tableEvents = null;
        makeAutoObservable(this);
    }

    UpdateExportHistory = async () => {
        if (!this.investigation) {
            message.error("Please select an investigation");
            return;
        }
        this.loading = true;
        this.exportHistory = [];
        this.timerange = [null, null];
        const response = await this.api.analysis.fenceData(this.investigation);
        this.exportHistory = response;
        this.loading = false;
        message.success("Investigation Data Loaded");
    }

    get displayHistory() {
        let returnResult = [];
        if (this.fenceBounds !== null) {
            this.exportHistory.forEach((item) => {
                if (item.latitude >= this.fenceBounds.south && item.latitude <= this.fenceBounds.north && item.longitude >= this.fenceBounds.west && item.longitude <= this.fenceBounds.east) {
                    returnResult.push(item);
                }
            });
            // this.startTime = returnResult[0].device_utc_date_time;
            // this.endTime = returnResult[returnResult.length - 1].device_utc_date_time;
        }
        if (this.timerange[0] !== null && this.timerange[1] !== null) {
            const newArray = [];
            returnResult.forEach((item) => {
                if (item.device_utc_date_time >= this.timerange[0] && item.device_utc_date_time <= this.timerange[1]) {
                    newArray.push(item);
                }
            });
            returnResult = newArray;
        }
        if (this.target !== null) {
            const newArray = [];
            returnResult.forEach((item) => {
                if (item.targetName === this.target) {
                    newArray.push(item);
                }
            });
            returnResult = newArray;
        }
        return returnResult;
    }

    setAction(action) {
        this.action = action;
    }
    setFenceBounds(bounds) {
        this.loading = true;
        this.fenceBounds = bounds;
    }

    updateTimeRange(timeRange) {
        this.timerange = timeRange;
    }

    updateInvestigation(investigation) {
        this.investigation = investigation;
        this.exportHistory = [];
    }

    setSelectEvent(event) {
        this.selectedEvent = event;
    }

    isEventSelected(event) {
        return this.selectedEvent === event;
    }

    get ResultSelectedEvent() {
        return this.selectedEvent;
    }

    generatePoint() {
        let markerType = this.selectMarkerType(this.selectedEvent);
        return (
            <Marker
                position={{
                    lat: this.selectedEvent.latitude,
                    lng: this.selectedEvent.longitude,
                }}
                icon={markerType}

                zIndex={4}
            >
                <div
                >
                    <InfoWindow
                        offset={{
                            x: 0,
                            y: -30,
                        }}
                        position={{
                            lat: this.selectedEvent.latitude,
                            lng: this.selectedEvent.longitude,
                        }}
                    >
                        <div>
                            <p>Marker Latitude: {this.selectedEvent.latitude}</p>
                            <p>Marker Longitude: {this.selectedEvent.longitude}</p>
                            <p>Serial: {this.selectedEvent.serial}</p>
                            <p>Time: {getGMTDateTime(this.selectedEvent.device_utc_date_time)}</p>
                            <p>Speed: {convertSpeed("KMH", this.selectedEvent.speed)}</p>

                        </div>
                    </InfoWindow>

                </div>

            </Marker>
        )
    }

    inFence(item) {
        if (item.latitude >= this.fenceBounds.south && item.latitude <= this.fenceBounds.north && item.longitude >= this.fenceBounds.west && item.longitude <= this.fenceBounds.east) {
            return true;
        }
        return false;
    }

    InboundCase(point, NextPoint) {
        if (point.serial !== NextPoint.serial) {
            return false;
        }
        if (!this.inFence(point) && this.inFence(NextPoint)) {
            return true;
        }
        return false;
    }

    OutboundCase(point, NextPoint) {
        if (point.serial !== NextPoint.serial) {
            return false;
        }
        if (this.inFence(point) && !this.inFence(NextPoint)) {
            return true;
        }
        return false;
    }

    findNextPoint(point, array, index) {
        let returnIndex = null;
        for (let i = index + 1; i < array.length; i++) {
            if (array[i].serial === point.serial) {
                returnIndex = i;
                break;
            }
        }
        if (returnIndex === null) {
            return index;
        }
        return returnIndex;
    }

    findIndexInTime(point, array) {
        let returnIndex = -1;
        for(let i = 0; i < array.length; i++) {
            if (array[i].serial === point.serial) {
                if(array[i].in < point.device_utc_date_time) {
                    returnIndex = i;
                    break;
                }
            }
        }
        return returnIndex;
    }

    // point is the data
    // index is the index of the point
    // array is the array of points
    get DisplayInNOut() {
        let result = [];
        let rollingDevices = [];
        // starts at early point
        this.loading = true;
        if (this.fenceBounds !== null) {
            this.exportHistory.forEach((item, index) => {
                //target filter
                if (this.target !== null) {
                    if (item.targetName !== this.target) {
                        return;
                    }
                }

                // if (index === this.exportHistory.length - 1) {
                //     // end of array dont check in and outs
                //     return;
                // }
                const nextPoint = this.findNextPoint(item, this.exportHistory, index);
                const isGoingIn = this.InboundCase(item, this.exportHistory[nextPoint]);
                const isGoingOut = this.OutboundCase(item, this.exportHistory[nextPoint]);
                if (isGoingIn) {
                    // add to rolling devices
                    const arrayOBJ = {
                        serial: item.serial,
                        targetName: item.targetName,
                        in: item.device_utc_date_time,
                        out: 0,
                        duration: 0,
                        oldPoint: item,
                        newPoint: null,
                        pointLog: [],
                        latlngTracks: [
                            {
                                lat: item.latitude,
                                lng: item.longitude,
                            }
                        ]
                    }
                    arrayOBJ.pointLog.push(item);
                    rollingDevices.push(arrayOBJ);
                } else if (isGoingOut) {
                    const index = rollingDevices.findIndex((device) => device.serial === item.serial);
                    if (index !== -1) {
                        const PointToadd = this.exportHistory[nextPoint];
                        const oldPoint = rollingDevices[index];
                        oldPoint.out = PointToadd.device_utc_date_time;
                        oldPoint.duration = PointToadd.device_utc_date_time - oldPoint.in;
                        oldPoint.newPoint = PointToadd;
                        oldPoint.pointLog.push(PointToadd);
                        oldPoint.latlngTracks.push({
                            lat: PointToadd.latitude,
                            lng: PointToadd.longitude,
                        });
                        result.push(oldPoint);
                        if (this.timerange[0] !== null && this.timerange[1] !== null) {
                            if (oldPoint.in >= this.timerange[0] && oldPoint.in <= this.timerange[1] && oldPoint.out >= this.timerange[0] && oldPoint.out <= this.timerange[1]) {
                                result.push(oldPoint);
                            }
                        } else {
                            result.push(oldPoint);
                        }
                        rollingDevices = rollingDevices.filter((device) => device.serial !== PointToadd.serial);
                    }
                } else {
                    if ((item.serial === this.exportHistory[nextPoint].serial) && this.inFence(item)) {
                        const index = this.findIndexInTime(item, rollingDevices);
                        if (index !== -1) {
                            rollingDevices[index].pointLog.push(item);
                            rollingDevices[index].latlngTracks.push({
                                lat: item.latitude,
                                lng: item.longitude,
                            });
                        }
                    }
                }
            })
        };
        //remove duplicates check start and end time and target name
        result = result.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.in === item.in && t.out === item.out && t.targetName === item.targetName
            ))
        )
        result.sort((a, b) => {
            return b.in - a.in;
        })
        this.loading = false;
        return result;

    }

    tableselect(event) {
        this.tableEvents = event;
    }

    selectMarkerType(event, endPoint = false) {
        let markerType = null;
        if (event.speed < 1) {
            markerType = {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "#71FC27",
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: "#01290D",
                rotation: event.heading,
                scale: 5,
                anchor: new window.google.maps.Point(0, 0)
            };
        } else {
            markerType = {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: "#71FC27",
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: "#01290D",
                rotation: event.heading,
                scale: 4,
                anchor: new window.google.maps.Point(0, 3)
            };
        }
        if (endPoint) {
            markerType = {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "#FF0000",
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: "#01290D",
                rotation: event.heading,
                scale: 5,
                anchor: new window.google.maps.Point(0, 0)
            };
        }
        return markerType;
    }

    GenerateTablePoints() {
        if (this.tableEvents === null) {
            return null;
        }
        let markerTypeOld = this.selectMarkerType(this.tableEvents.oldPoint);
        let markerTypeNew = this.selectMarkerType(this.tableEvents.newPoint, true);
        return (
            <div>
                <Marker
                    position={{
                        lat: this.tableEvents.oldPoint.latitude,
                        lng: this.tableEvents.oldPoint.longitude,
                    }}
                    icon={markerTypeOld}

                    zIndex={6}
                >
                    <div
                    >
                        <InfoWindow
                            offset={{
                                x: 0,
                                y: -30,
                            }}
                            position={{
                                lat: this.tableEvents.oldPoint.latitude,
                                lng: this.tableEvents.oldPoint.longitude,
                            }}
                        >
                            <div>
                                <p>Marker Latitude: {this.tableEvents.oldPoint.latitude}</p>
                                <p>Marker Longitude: {this.tableEvents.oldPoint.longitude}</p>
                                <p>Serial: {this.tableEvents.oldPoint.serial}</p>
                                <p>Time: {getGMTDateTime(this.tableEvents.oldPoint.device_utc_date_time)}</p>
                                <p>Speed: {convertSpeed("KMH", this.tableEvents.oldPoint.speed)}</p>

                            </div>
                        </InfoWindow>
                    </div>
                </Marker>
                <Marker
                    position={{
                        lat: this.tableEvents.newPoint.latitude,
                        lng: this.tableEvents.newPoint.longitude,
                    }}
                    icon={markerTypeNew}
                    zIndex={6}
                >
                    <div
                    >
                        <InfoWindow
                            offset={{
                                x: 0,
                                y: -30,
                            }}
                            position={{
                                lat: this.tableEvents.newPoint.latitude,
                                lng: this.tableEvents.newPoint.longitude,
                            }}
                        >
                            <div>
                                <p>Marker Latitude: {this.tableEvents.newPoint.latitude}</p>
                                <p>Marker Longitude: {this.tableEvents.newPoint.longitude}</p>
                                <p>Serial: {this.tableEvents.newPoint.serial}</p>
                                <p>Time: {getGMTDateTime(this.tableEvents.newPoint.device_utc_date_time)}</p>
                                <p>Speed: {convertSpeed("KMH", this.tableEvents.newPoint.speed)}</p>
                            </div>
                        </InfoWindow>
                    </div>
                </Marker>
                <Polyline
                    path={this.tableEvents.latlngTracks}
                    geodesic={true}
                    zIndex={6}
                    options={{
                        strokeColor: "#0ee820",
                        strokeOpacity: 1.0,
                        strokeWeight: 5,
                    }}
                />
            </div>
        )
    }

    clearTablePoints() {
        this.tableEvents = null;
    }

    setTarget(target) {
        if (target === undefined) {
            target = null;
        }
        this.target = target;
    }

    downloadHistory = async () => {
        const contents = this.DisplayInNOut;
        contents.forEach((item) => {
            item.in = getGMTDateTime(item.in);
            item.out = getGMTDateTime(item.out);
            item.duration = `${Math.floor(item.duration / 3600)} hours ${Math.floor(item.duration % 3600 / 60)} minutes ${Math.floor(item.duration % 60)} seconds`;
        })
        // this will be downloaded as a csv file
        const csv = parser.parse(contents);
        const date = new Date();
        fileDownload(csv, `FenceHistory-${date.toISOString()}.csv`);
    }

}

export default createContext(new AnalysisFenceStore());
