export class RedbackConfig {

    config = {}

    constructor(advanced, values) {
        this.config.advanced = advanced;

        try { //checking to stop more fields than intended
            if (values.length > 17) { //api can send incorrect values
                values = [
                    values[0],
                    values[1],
                    values[2],
                    values[3],
                    values[4],
                    values[5],
                    values[6],
                    values[7],
                    values[8],
                    values[9],
                    values[10],
                    values[11],
                    values[12],
                    values[13],
                    values[14],
                    values[15],
                    values[16]
                ]
            }

            for (let i = 0; i < values.length; i++) { //mapping through 2 API array responses
                for (let j = 0; j < this.config.advanced.length; j++) {
                    if (values[i].config_id === Number(this.config.advanced[j].id)) {
                        this.config.advanced[j].profile = values[i];
                        const settings = JSON.parse(values[i].settings)

                        for (let k = 0; k < this.config.advanced[j].fields.length; k++) {
                            this.config.advanced[j].fields[k].value = settings.settings[this.config.advanced[j].fields[k].name];
                        }
                    }
                }
            }
        }
        catch {

        }

        for (let i = 0; i < this.config.advanced.length; i++) {
            for (let j = 0; j < this.config.advanced[i].fields.length; j++) {
                if (this.config.advanced[i].fields[j].value === undefined) {
                    this.config.advanced[i].fields[j].value = this.config.advanced[i].fields[j].default
                }
            }

            if (this.config.advanced[i].profile === undefined) {
                let profile = { settings: {} }

                profile = JSON.stringify(profile)

                let profileadd = { settings: profile }

                this.config.advanced[i].profile = profileadd;
            }
        }

        if (advanced.length === 17) { //yabby GPS
            this.config.basic = [
                advanced[0],
                {
                    fields: [
                        advanced[3].fields[2],
                        advanced[3].fields[3],
                        advanced[3].fields[4],
                        advanced[3].fields[5],
                        advanced[3].fields[6],
                        advanced[3].fields[7],
                        advanced[3].fields[8],
                        advanced[3].fields[9],
                        advanced[3].fields[10]
                    ],
                    id: "2400",
                    name: "Movement Detection",
                    size: "9",
                    profile: advanced[3].profile
                },
                advanced[14]
            ]
        }
        else if (advanced.length === 14) { //yabby WIFI
            this.config.basic = [
                advanced[0],
                {
                    fields: [
                        advanced[2].fields[1],
                        advanced[2].fields[2],
                        advanced[2].fields[3],
                        advanced[2].fields[4],
                        advanced[2].fields[5],
                        advanced[2].fields[6],
                        advanced[2].fields[7]
                    ],
                    id: "2400",
                    name: "Movement Detection",
                    size: "7",
                    profile: advanced[2].profile
                },
                advanced[10]
            ]
        }


    }

    get basic() {
        return this.config.basic;
    }

    get advanced() {
        return this.config.advanced;
    }

    submit(input, id) {
        let output = {}

        let page;
        
        for (let i = 0; i < this.config.advanced.length; i++) {
            if (id === this.config.advanced[i].id) {
                page = i;
            }
        }

        for (let i = 0; i < this.config.advanced[page].fields.length; i++) {

            const name = this.config.advanced[page].fields[i].name

            if (input.values[name]) {
                output[name] = input.values[name]
            }
            else {
                output[name] = this.config.advanced[page].fields[i].value
            }

            if (output[name] === undefined) {
                output[name] = this.config.advanced[page].fields[i].default
            }
        }
        return (output)
    }

    submitAdvanced(input, id) {
        let page;

        let output = {};

        console.log(id)

        for (let i = 0; i < this.config.advanced.length; i++) {
            if (id === this.config.advanced[i].id) {
                page = i;
            }
        }

        for (let i = 0; i < this.config.advanced[page].fields.length; i++) {

            const name = this.config.advanced[page].fields[i].name

            if (input.values[name]) {
                output[name] = input.values[name]
                this.config.advanced[page].fields[i].value = input.values[name]
            }
            else {
                output[name] = this.config.advanced[page].fields[i].value
            }
        }

        return (output)
    }

    monitoringPreset(index) {

        let output = {};

        for (let i = 0; i < this.config.basic[index].fields.length; i++) {
            if ('bPeriodicUploadHrMin' === this.config.basic[index].fields[i].name) {
                output[this.config.basic[index].fields[i].name] = 360;
            } else if ("bMoveLogHrMin" === this.config.basic[index].fields[i].name) {
                output[this.config.basic[index].fields[i].name] = 15;
            } else if ("bMoveUploadHrMin" === this.config.basic[index].fields[i].name) {
                output[this.config.basic[index].fields[i].name] = 10;
            } else {
                output[this.config.basic[index].fields[i].name] = this.config.basic[index].fields[i].value;
            }
        }

        return output;
    }

    loggingPreset(index) {

        let output = {};

        for (let i = 0; i < this.config.basic[index].fields.length; i++) {
            if ('bPeriodicUploadHrMin' === this.config.basic[index].fields[i].name) {
                output[this.config.basic[index].fields[i].name] = 720;
            } else if ("bMoveLogHrMin" === this.config.basic[index].fields[i].name) {
                output[this.config.basic[index].fields[i].name] = 30;
            } else {
                output[this.config.basic[index].fields[i].name] = this.config.basic[index].fields[i].value;
            }
        }

        return output;
    }
}
