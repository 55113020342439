import AlertsAPI from "./alerts.api";
import AnalysisAPI from "./analysis.api";
import AnalyticsAPI from "./analytics.api";
import AuthenticationAPI from "./authentication.api";
import bluetoothApi from "./bluetooth.api";
import DeviceAPI from "./device.api";
import DeviceTargetHistory from "./deviceTargetHistory.api";
import GeofenceAPI from "./geofence.api";
import InvestigationAPI from "./investigation.api";
import LicenseAPI from "./license.api";
import LivePinAPI from "./live-pin.api";
import LiveAPI from "./live.api";
import MessageAPI from "./message.api";
import NotificationAPI from "./notification.api";
import profileCounterAPI from "./profile-counter.api";
import ProfileAPI from "./profile.api";
import RoleAPI from "./role.api";
import sos from "./sos.api";
import TargetAPI from "./target.api";
import TemplateAPI from "./template.api";
import UserAPI from "./user.api";
import AdminBulletinAPI from "./adminBulletin.api";
import UserAuditAPI from "./userAudit.api";
import FederationAPI from "./federation.api";
import DashboardAPI from "./dashboard.api";
import nickNameApi from "./nickName.api";
import wifiAccessPointAPI from "./wifi_access_point";
import wifiCloneCaptureAPI from "./wifi_clone_capture";
import entity from "./entity";
import wifiDevice from "./wifiDevice";
import searchByEntities from "./entityid";
import devicedashboardApi from "./devicedashboard.api";
import investigationDashboardApi from "./investigationDashboard.api";
import exportsPageApi from "./exportsPage.api";
import notificationStreams from "./notificationStreams";
import geoCoderApi from "./geoCoder.api";

const API = {
    authentication: AuthenticationAPI,
    nickName: nickNameApi,
    accessPoint: wifiAccessPointAPI,
    CloneCapture: wifiCloneCaptureAPI,
    device: DeviceAPI,
    profile: ProfileAPI,
    user: UserAPI,
    investigation: InvestigationAPI,
    target: TargetAPI,
    role: RoleAPI,
    template: TemplateAPI,
    geofence: GeofenceAPI,
    live: LiveAPI,
    message: MessageAPI,
    analysis: AnalysisAPI,
    license: LicenseAPI,
    analytics: AnalyticsAPI,
    profileCounter: profileCounterAPI,
    sos: sos,
    deviceTargetHistory: DeviceTargetHistory,
    notification: NotificationAPI,
    livePin: LivePinAPI,
    alerts: AlertsAPI,
    adminBulletin: AdminBulletinAPI,
    userAudit: UserAuditAPI,
    federation: FederationAPI,
    dashboard: DashboardAPI,
    entity: entity,
    wifiDevice:wifiDevice,
    devicedashboard: devicedashboardApi,
    searchByEntities: searchByEntities,
    exportsPageApi: exportsPageApi,
    bluetooth: bluetoothApi,
    investigationDashboard: investigationDashboardApi,
    notificationStreams: notificationStreams,
    geoCoding: geoCoderApi

};
export default API;
