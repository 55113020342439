import { Collapse, List, Typography, Drawer, Button } from "antd";
import React, { useState } from "react";
import { DrawerProps } from 'antd/es/drawer';
import getPublicURL from "../../utilities/public-url.utility";
const prettyMS = require("pretty-ms");
import { ArrowLeftOutlined } from "@ant-design/icons";
import './index.css';

const { Panel } = Collapse;

export default function ({
    visible,
    children,
    showDrawer,
    onClose
}) {

    const _showDrawer = () => {
        showDrawer();
    };
    const _onClose = () => {
        onClose();
    };

    if (!visible) {
        return null
    }

    return (
        <Drawer
            title={<div><h4>Access Point Mapping</h4></div>}
            placement="left"
            onClose={_onClose}
            mask={false}
            visible={visible}
            style={{
                marginBottom: "2px",
                width: "35%",
                marginLeft: "4rem"
            }}
            bodyStyle={{ background: '#f8f4f4' }}
            headerStyle={{ backgroundColor: '#f8f4f4' }}
            defaultActiveKey={0}
            closeIcon={
                <ArrowLeftOutlined />
            }
        >
            <div>{<React.Fragment>{children}</React.Fragment>}</div>
        </Drawer>
    );
}
