import { HomeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./LoginFormBlock.scss";
import authStore from '../../stores/auth.store';

function LoginFormBlock(props) {

  const [disabled, setDisabled] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [form] = Form.useForm();

  const handleSubmit = (e) => {
    setAttempts(attempts + 1);
    form.validateFields().then(values => {
      props.onSubmit({ values });
    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    if (attempts >= 3) {
      setDisabled(true);
    }
  }, [attempts]);

  const AuthStore = useContext(authStore);
  return (
    <Form onFinish={handleSubmit} className="login-form" form={form}>
      <Form.Item
        name="email"
        rules={[{
          required: true,
          message: "Your email is required.",
          type: "email"
        }]}
      >
        <Input
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="email"
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{
          required: true,
          message: "Your password is required."
        }]}
      >
        <Input
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="Password"
          disabled={disabled}
        />


      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={disabled}
        >
          Log in
        </Button>
      </Form.Item>
      <a className="login-form-forgot" href="/forgot-password">
        Forgot your password?
      </a>
      Can&#39;t log in? You can < a href="/request-access" > request access</a >.
      < a className="login-form-forgot" href="/SignUP" >
        Request Demo Access
      </a >

    </Form >
  );
}

export default LoginFormBlock;
