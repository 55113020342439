import { LockOutlined, MailOutlined, UserOutlined, PhoneOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select } from "antd";
import React from "react";
import { contentBox, primaryButton, secondaryButton } from '../../styles';
import { getTimeZones } from "../../util/CommonUtils";

const { Option } = Select;

class ProfileUpdateForm extends React.Component {
    state = {
        loading: false
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                console.log("Received values of form: ", values)
                this.props.onSubmit(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            username = "",
            name = "",
            alias = "",
            email = "",
            mobile = "",
            serviceNumber = "",
            date_format = "",
            utcOffset = "",
        } = this.props.initialValues;

        console.log(utcOffset)
        return (
            <div
                style={contentBox()}
            >
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item label="Full Name">
                        {getFieldDecorator("name", {
                            rules: [{ required: true, message: "Please input your name!" }],
                            initialValue: name
                        })(
                            <Input
                                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="text"
                                placeholder="Full Name"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Email Address">
                        {getFieldDecorator("email", {
                            rules: [
                                { required: true, message: "Please input your email address" }
                            ],
                            initialValue: email
                        })(
                            <Input
                                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="email"
                                placeholder="Email Address"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Alias">
                        {getFieldDecorator("alias", {
                            rules: [],
                            initialValue: alias
                        })(
                            <Input
                                prefix={<UserDeleteOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="text"
                                placeholder="Alias"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Mobile Number: (Please use international number) ">
                        {getFieldDecorator("mobile", {
                            rules: [
                                { required: true, message: "Please input your mobile number", pattern: new RegExp('^[0-9]{1,11}$'), }
                            ],
                            initialValue: mobile
                        })(
                            <Input
                                prefix={<PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="number"
                                placeholder="Mobile Number"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Date Format">
                        {getFieldDecorator("date_format", {
                            rules: [],
                            initialValue: date_format
                        })(
                            <Select
                                mode="single"
                                style={{ width: "100%" }}
                                placeholder="Date Format"
                            >
                                {this.props.dateFormats.map(value => (
                                    <Option key={value}>{value}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="utcOffset"
                    >
                        {getFieldDecorator("utcOffset", {
                            rules: [{ required: true, message: "Please select your timezone" }],
                            initialValue: utcOffset
                        })(
                            <Select
                                showSearch
                            >
                                {getTimeZones().map((timezone) => (
                                    <Select.Option key={timezone.value}>{timezone.label}</Select.Option>
                                ))}
                            </Select>

                        )}
                    </Form.Item>

                    <Form.Item>
                        <Button
                            style={primaryButton()}
                            htmlType="submit"
                            className="profile-update-form-button"
                            block
                        >
                            Update Profile
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default Form.create({})(ProfileUpdateForm);
