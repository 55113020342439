import { message } from "antd";
import FuzzySearch from "fuzzy-search";
import { action, computed, observable, makeAutoObservable } from "mobx";
import { createContext, useState } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

export class TargetStore {
    constructor() {
        this.targets = [];
        this.searchQuery = "";

        this.targetsForInvestigation = [];
        this.currentSelectedInvestigation = "";

        this.targetForGeoFence = [];

        this.targetwifiClone = [];

        this.clonewifiTrip = [];

        this.accessPoints = [];
        this.api = API;
        makeAutoObservable(this);
    }

    get results() {
        if (this.searchQuery) {
            const searcher = new FuzzySearch(
                this.targets,
                ["name", "investigation_name", "device_name"],
                {
                    caseSensitive: false
                }
            );
            return searcher.search(this.searchQuery.trim());
        } else {
            return this.targets;
        }
    }

    async getAllTargets() {
        const response = await this.api.target.getAllTargets();
        this.targets = response;
    }

    async getAllTargetsByInvestigationAndUser() {
        const response = await this.api.target.getAllTargetsByInvestigationAndUserDetails();
        this.targetForGeoFence = response;
    }

    async setActiveInvestigation(investigationID) {
        this.currentSelectedInvestigation = investigationID;
        this.getTargetsForInvestigation(investigationID);
    }

    async getTargetsForInvestigation(investigationID) {
        const response = await this.api.target.getAllTargetsByInvestigation(
            investigationID
        );

        this.targetsForInvestigation = response;
    }

    async clondedtrips(targetID, start, finish) {
        try {
            const response = await this.api.target.ClonedTripCheck(
                targetID, start, finish
            )
            if (response.length > 0) {
                for(let i = 0; i < response.length; i++){
                    this.clonewifiTrip.push(response[i]);
                }
                return response;
            }
            return false;
        } catch (e){
            return false;
        }
    }

    async clonedWifimessage(target) {
        try {
            const response = await this.api.target.getClonedMacAddressTarget(
                target.target_id
            );
            if (response.length > 0) {
                message.info(`Cloned target: ${target.name} mac address: ${JSON.stringify(response[0].deviceMAC)}`);
                return response
            }
            return false;
        } catch(e) {
            return false;
        }
    }

    async getWifitargets(target) {
        try {
            const response = await this.api.target.getClonedMacAddressTarget(
                target.target_id
            );
            if (response.length > 0) {
                this.targetwifiClone.push(response);
                return response
            }
            else {
                this.targetwifiClone = response;
            }
            return false;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async getWifitargetsNoTime(target) {
        try {
            const response = await this.api.target.getClonedMacAddressTargetNoTime(
                target.target_id
            );
            if (response.length > 0) {
                this.targetwifiClone.push(response);
                return response
            }
            else {
                this.targetwifiClone.push(response);
            }
            return false;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async getAllWifiTargets() {
        const targetarray = await this.api.live.getLiveTargets();
        var wifiarray = [];
        for (let i = 0; i < targetarray.length; i++) {
            for (let j = 0; j < targetarray[i].target.length; j++) {
                let ifcloned = await this.getWifitargets(targetarray[i].target[j]);
                // clean up stored values
                // if value is passed pushed to setting
                if (ifcloned) {
                    wifiarray.push(targetarray[i].target[j]);
                }
            }
        }
        this.targetwifiClone = wifiarray;
    }

    async getAllAccessPoints() {
        this.accessPoints = await this.api.target.APcapture(0);
    }

    async createTarget({ name, investigation_id, device_id, colour }) {
        try {
            await this.api.target.createTarget({
                name,
                investigation_id,
                device_id,
                colour
                // assign_at
            });
            message.success(`Target  has been created.`);
            this.getAllTargets();
        } catch (exception) {
            console.log(exception);
            message.error(`${exception.response.data.message}`);
        }
    }

    async updateTarget({ name, assign_at, device_id, id, investigation_id, colour, warrant }) {
        try {
            await this.api.target.updateTarget({
                id,
                name,
                assign_at,
                device_id,
                investigation_id,
                colour,
                warrant
            });
            message.success(`Target  has been updated.`);
            this.getAllTargets();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }


    async deleteTarget(record) {
        try {
            await this.api.target.deleteTarget(record.id);
            message.success(`Target '${record.name}' has been deleted.`);
            this.getAllTargets();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }
}

export const store = new TargetStore();
export default createContext(store);
