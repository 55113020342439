import {Button, Divider, Input, Modal, Select} from "antd";
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import entityDisplayStore from "../../stores/entityDisplay.store";
import {ArrowLeftOutlined, UserAddOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {Form} from "antd/es";

function EntityCreationModal() {

    const store = useContext(entityDisplayStore);

    const [form] = useForm();

    const onFormFinish = (values) => {
        store.processEntityCreation(values.entityName);
    };

    const onFormFinishFailed = (errorInfo) => {
        // todo handle form finish fail
    };

    const [form1] = useForm();

    const onFormFinish1 = (values) => {

        store.processEntityAssignment(values.Entity);
    };
    return (
        <div>

            <Modal
                title="Entity"
                visible={store.entityCreationModalOpen}
                onCancel={()=>{store.setEntityCreationModalOpen(false);}}
                onOk={()=>{}}
                footer={null}
            >
                {store.entityCreationState === 0 &&
                    <div>
                        <Button
                            style={{width: '45%'}}
                            onClick={()=>{store.setEntityCreateState(1)}}
                            icon={<UserAddOutlined style={{fontSize: '140%'}}/>}
                        >Create Entity</Button>
                        <Divider type={"vertical"}/>
                        <Button
                            style={{width: '45%'}}
                            onClick={()=>{store.setEntityCreateState(2)}}
                            icon={<UserSwitchOutlined style={{fontSize: '140%'}} />}
                        >Assign To An Entity</Button>
                        <Divider/>
                        <p>An Entity is a grouping of related datas to a target.</p>
                        <p>When adding Connections to an Entity ATRAX will group them together for searching</p>
                    </div>
                }
                {store.entityCreationState === 1 &&
                    <div>
                        <Form
                            form={form}
                            layout="horizontal"
                            onFinish={onFormFinish}
                            onFinishFailed={onFormFinishFailed}
                        >
                            <Form.Item
                                label="Entity Name"
                                name="entityName"
                                rules={[{ required: true, message: 'Please input an entity name!' }]}
                                >
                                <Input style={{borderRadius: '10px', width: '80%', marginLeft: '2%'}} />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    onClick={()=>{store.setEntityCreateState(0)}}
                                    icon={<ArrowLeftOutlined />}
                                    shape={"circle"}
                                    style={{marginRight: '2%'}}
                                ></Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>

                            </Form.Item>
                        </Form>
                    </div>
                }
                {store.entityCreationState === 2 &&
                    <div>


                        <Form
                            form={form1}
                            layout="horizontal"
                            onFinish={onFormFinish1}
                        >
                            <Form.Item label="Entity" name="Entity" rules={[
                                {
                                    required: true,
                                    message: 'Please select an entity!'
                                }
                            ]}>
                                <Select
                                    style={{width: '80%', marginLeft: '2%'}}
                                    options={store.entities}
                                />
                            </Form.Item>
                            <Form.Item >
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        store.setEntityCreateState(0)
                                    }}
                                    icon={<ArrowLeftOutlined/>}
                                    shape={"circle"}
                                    style={{marginRight: '2%'}}
                                ></Button>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                }
            </Modal>
        </div>
    )
}

export default observer(EntityCreationModal);
