import { Alert, Modal } from "antd";
import React, { useState } from "react";

export default function DeleteConfirmation({
    children: Component,
    onClickOkay,
    onClickCancel,
    title,
    content,
    licenseDeallocationModal,
}) {
    // const [isDeleteable, setIsDeleteable] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const onChildClick = () => {
        setIsVisible(true);
    };

    const ComponentWithHandler = React.cloneElement(Component, {
        onClick: onChildClick,
    });

    return (
        <React.Fragment>
            <Modal
                title={title}
                visible={isVisible}
                onOk={() => {
                    setIsVisible(false);
                    return onClickOkay();
                }}
                onCancel={() => {
                    setIsVisible(false);
                    return onClickCancel();
                }}
                // okButtonProps={isDeleteable}
            >
                <Alert
                    showIcon
                    banner
                    message={
                        licenseDeallocationModal && licenseDeallocationModal === true
                            ? "Deallocation of the license will stop communication between the device and the server."
                            : "This action can not be reversed."
                    }
                    type={
                        licenseDeallocationModal && licenseDeallocationModal === true
                            ? "warning"
                            : "error"
                    }
                    style={{ marginBottom: "10px" }}
                />
                {content}
            </Modal>
            {ComponentWithHandler}
        </React.Fragment>
    );
}
