import { Collapse, Drawer, List, Typography, Switch } from "antd";
import React from "react";
import { convertSpeed, getGMTDateTime } from "../../util/CommonUtils";
import { contentBox, primaryButton } from "../../styles";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AddressDisplay from "../AnalysisTripsPanel/AddressDisplay";

const prettyMS = require("pretty-ms");

const { Panel } = Collapse;

export default function ({
    margin,
    stops,
    targetNameMappingList,
    children,
    onStopClick,
    currentlySelectedStop,
    speedUnitString,
    showDrawer,
    onClose,
    onExpand,
    visible,
    onUTCChange,
}) {
    const isCurrentlySelectedStop = (stop) => {
        // alert("stop", JSON.stringify(currentlySelectedStop, null, 2));
        return currentlySelectedStop && stop.id === currentlySelectedStop.id;
    };
    const _showDrawer = () => {
        showDrawer();
    };
    const _onClose = () => {
        onClose();
    };
    if (!visible) {
        return null
    }

    stops.sort((a, b) => { return b.device_utc_date_time - a.device_utc_date_time });

    return (

        <Drawer
            title={
                <div>
                    <h4>Stops Analysis <Switch
                    style={{ marginLeft: "10px"}}
                        checkedChildren="Local Time"
                        unCheckedChildren="UTC Time"
                        defaultChecked={localStorage.getItem("utcTime") === "false"}
                        onChange={(e) => {
                            if (e) {
                                localStorage.setItem("utcTime", "false")
                            } else {
                                localStorage.setItem("utcTime", "true")
                            }
                            onUTCChange();
                        }}
                    /></h4>
                </div>
            }
            defaultActiveKey={["trips"]}
            style={{
                boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                  0 12.5px 10px rgba(0, 0, 0, 0.06),
                  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                  0 100px 80px rgba(0, 0, 0, 0.12)`,
                width: "35%",
                marginLeft: "4rem"
            }}
            placement="left"
            onClose={_onClose}
            visible={visible}
            mask={false}

            bodyStyle={{ background: '#f8f4f4' }}
            headerStyle={{ background: '#f8f4f4' }}
            closeIcon={
                <ArrowLeftOutlined />
            }
        >
            {children}
            <div style={contentBox({
                //maxHeight: "45vh",
                //overflow: "scroll",
            })}>
                <List
                    itemLayout="horizontal"
                    dataSource={stops}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                backgroundColor: isCurrentlySelectedStop(item)
                                    ? "#faf3dc"
                                    : null,

                                maxHeight: "10%"
                            }}
                            onClick={() => {
                                return onStopClick(item);
                            }}
                        >
                            <Typography.Text type="secondary">
                                {/*<strong>Target Name:</strong> {targetNameMappingList[item.target_id.toString()]} <br />*/}
                                <strong>Date/Time: </strong>{" "}
                                <i>
                                    {
                                        getGMTDateTime(item.device_utc_date_time, false)
                                    }
                                </i>{" "}
                                to{" "}
                                <i>
                                    {
                                        getGMTDateTime(Number(item.device_utc_date_time + item.stopped), false)
                                    }
                                </i>
                                <br />
                                <strong>Address: </strong> <AddressDisplay lat={item.latitude} lng={item.longitude} />
                                <br />
                                <strong>Speed: </strong> {convertSpeed(speedUnitString, item.speed)}
                                <br />
                                <strong>Heading: </strong> {item.heading} °
                                <br />
                                <strong>Stopped For: </strong> {prettyMS(item.stopped * 1000)}
                                <br />
                                <strong>Coordinates:</strong> {item.latitude}, {item.longitude}
                            </Typography.Text>
                        </List.Item>
                    )}
                ></List>
            </div>
        </Drawer>
    );
}
