import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Row, Segmented, Select } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import InvestigationFormModal from "../../components/InvestigationForm";
import InvestigationTable from "../../components/InvestigationTable";
import InvestigationStore from "../../stores/investigation.store";
import UserStore from "../../stores/user.store";
import PermissionStore from "../../stores/permission.store";
import Layout from "../AppLayout";
import "./InvestigationManagementPage.scss";
import { secondaryButton, contentBox, primaryButton } from '../../styles';

function InvestigationManagementPage() {
    const store = useContext(InvestigationStore);
    const userStore = useContext(UserStore);
    const permissionStore = useContext(PermissionStore);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChecked, setIsCheckedTrue] = useState(false);

    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await permissionStore.getPermissionByRoleId(role);
            permissionStore.assignedPermission = permission;
        };
        store.getInvestigations();
        store.getInvestigationsForUser();
        fetchRolePermission();
    }, [store, permissionStore]);

    useEffect(() => {
        userStore.getUsers();
    }, [userStore])

    const onDeleteRecord = (record) => {
        store.deleteInvestigation(record);
        store.getInvestigations();
    };

    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});

    const onEditRecord = (record) => {
        setMode("edit");
        setExistingData(record);
        setIsModalOpen(true);
    };

    const clearSearch = () => {
        store.searchQuery = "";
    };

    const onCheck = () => {
        if (isChecked) setIsCheckedTrue(false);
        else setIsCheckedTrue(true);
    };
    return (
        <div className="InvestigationManagementPage">
            <InvestigationFormModal
                opened={isModalOpen}
                mode={mode}
                data={existingData || null}
                onSubmit={(data) => {
                    const { values, mode } = data;

                    console.log('data', values);
                    values.start_datetime = Math.floor(
                        new Date(values.start_datetime.startOf("day")).getTime() / 1000
                    );
                    if (mode === "edit") {

                        let isArray = Array.isArray(values.speed_unit);
                        // console.log('is array', isArray);

                        if (isArray) {
                            values.speed_unit = values.speed_unit[0];
                        }

                        store.updateInvestigation(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    } else {
                        store.createInvestigation(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    }
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                }}
                allUsers={userStore.users}
            ></InvestigationFormModal>
            <Layout
                showNavigation
                title="Investigation Administration"
                description="Manage investigations"
            >

                <div style={contentBox({ marginTop: "0" })}>
                    {/* <div> */}
                    <div>
                        <Button
                            icon={<PlusOutlined />}
                            type='primary'
                            style={primaryButton({marginBottom: '15px'})}
                            disabled={permissionStore.can("investigation.create")}
                            onClick={() => {
                                setMode("add");
                                setIsModalOpen(true);
                            }}
                        >
                            Add Investigation
                        </Button>
                        <Input
                            style={{ float: "right", width: "20%" }}
                            placeholder="Search .."
                            value={store.searchQuery}
                            allowClear
                            onChange={(event) => {
                                const query = event.target.value;
                                store.setSearchQuery(query);
                                // store.searchQuery = query;
                            }}
                        ></Input>
                        <Checkbox
                            style={{float: 'right' }}
                            onClick={() => {
                                store.showOnlyMyInvestigations();
                            }}
                        >
                            Show only my investigations
                        </Checkbox>


                    </div>
                    {/* </div> */}
                    <div className="investigation-table">
                        <InvestigationTable
                            canEdit={permissionStore.can("investigation.update")}
                            canDelete={permissionStore.can("investigation.delete")}
                            onDelete={onDeleteRecord}
                            onEditRecord={onEditRecord}
                            data={
                                !permissionStore.can("investigation.list")

                                    ? toJS(store.results)
                                    : []
                            }
                        ></InvestigationTable>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default observer(InvestigationManagementPage);
