import { AndroidOutlined, AppleOutlined, LockOutlined, MessageOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Row, Tabs, Col } from "antd";
import { toJS } from "mobx";
import QRCode from "react-qr-code";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ContactAdminForm from "../../components/ContactAdminForm";
import NotificationUpdateForm from "../../components/ProfileNotificationsForm";
import ProfilePasswordUpdateForm from "../../components/ProfilePasswordUpdateForm";
import ProfileUpdateForm from "../../components/ProfileUpdateForm";
import AuthStore from "../../stores/auth.store";
import NotificationStore from "../../stores/notification.store";
import Layout from "../AppLayout";
import "./index.scss";
import API from "../../services/api";
import socketHandlerStore from '../../stores/socketHandler.store';

const { TabPane } = Tabs;

function ProfilePage() {
    const store = useContext(AuthStore);
    const notification = useContext(NotificationStore);
    const profile = JSON.parse(localStorage.getItem("profile"));
    const [visible, setVisible] = useState(false);
    const [dateFormats, setDateFormats] = useState([]);
    const socketHandler = useContext(socketHandlerStore);

    useEffect(() => {
        notification.getNotificationSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        API.authentication.getDateFormats().then((formats) => {
            setDateFormats(formats);
        });
    }, []);

    const onContactFormSubmit = async (data) => {
        await store.contactAdmin(data);
    };
    const onPasswordFormSubmit = async (data) => {
        await store.updatePassword(data);
    };
    const onProfileFormSubmit = async (data) => {
        console.log(data);
        await store.updateProfile(data);
    };

    const onNotificationsSubmit = async (data) => {
        await notification.saveNotification(data);
    };

    const createQrData = () => {
        let string = window.location.origin + "/api/v1/";
        string += ",";
        string += localStorage.getItem("token");
        return string;
    }

   //  socketHandler.sendSocketMessage("notification_action", "hello");

    return (
        <div className="ProfilePage">
            <Layout
                showNavigation
                title={`Profile & Settings - Client: ${JSON.parse(localStorage.getItem("profile")).client_name}`}
                description="Update your profile details and your settings"
            >
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <UserOutlined />
                                Profile
                            </span>
                        }
                        key="1"
                    >
                        <div className="profile-form">
                            <Alert
                                style={{
                                    marginBottom: "22px",
                                }}
                                message="Your profile can help your teammates know who you are and how to reach you."
                                type="info"
                                showIcon
                            />
                            <ProfileUpdateForm
                                onSubmit={onProfileFormSubmit}
                                initialValues={profile}
                                dateFormats={dateFormats}
                            ></ProfileUpdateForm>

                        </div>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <LockOutlined />
                                Credentials
                            </span>
                        }
                        key="2"
                    >
                        <div className="profile-password-form">
                            <Alert
                                style={{
                                    marginBottom: "22px",
                                }}
                                message="A strong password can help secure your account."
                                type="info"
                                showIcon
                            />
                            <ProfilePasswordUpdateForm
                                onSubmit={onPasswordFormSubmit}
                            ></ProfilePasswordUpdateForm>
                        </div>
                    </TabPane>

                    <TabPane
                        tab={
                            <span>
                                <NotificationOutlined />
                                Notification & Alerts
                            </span>
                        }
                        key="4"
                    >
                        <div className="notification-admin-form">
                            <Alert
                                style={{
                                    marginBottom: "22px",
                                }}
                                message="Please select how you'd like to be notified from ATRAX."
                                type="info"
                                showIcon
                            />
                            <NotificationUpdateForm
                                initialValues={toJS(notification.notificationPreferences)}
                                fields={toJS(notification.settings)}
                                onSubmit={onNotificationsSubmit}
                            ></NotificationUpdateForm>
                        </div>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <AndroidOutlined /><AppleOutlined />
                                App Settings

                            </span>
                        }
                        key="5"
                    >
                        <div className="notification-admin-form">
                        <Col>
                            <h3>Sign in to app</h3>
                                <Row
                                    style={{
                                        horizontalAlign: "center",
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            setVisible(visible ? false : true);
                                        }}
                                        type="primary"
                                        style={{ marginBottom: "22px", horizontalAlign: "center" }}
                                    >
                                        Display QR Code
                                    </Button>
                                </Row>
                                <Row>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "50%", width: "50%", visibility: visible ? "visible" : "hidden" }}
                                    value={createQrData()}
                                    viewBox={`0 0 256 256`}
                                />
                                </Row>
                            <Row>
                                <p>
                                    * Token Will last for 30 days
                                </p>
                            </Row>
                        </Col>

                        </div>
                    </TabPane>
                </Tabs>
            </Layout>
        </div>
    );
}

export default observer(ProfilePage);
