import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import TemplateFormModal from "../../components/TemplateForm/index";
import TemplateTable from "../../components/TemplateTable/index";
import TemplateStore from "../../stores/template.store";
import PermissionStore from "../../stores/permission.store";
import Layout from "../AppLayout";
import "./TemplateManagementPage.scss";
import { secondaryButton, contentBox } from '../../styles';

function TemplateManagementPage() {
    const templateStore = useContext(TemplateStore);
    const permissionStore = useContext(PermissionStore);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await permissionStore.getPermissionByRoleId(role);
            permissionStore.assignedPermission = permission;
        };

        templateStore.getAllTemplates();
        templateStore.getAllFields();
        fetchRolePermission();
    }, [templateStore, permissionStore]);

    const onDeleteRecord = record => {
        templateStore.deleteTemplate(record);
    };

    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});

    const onEditRecord = async record => {
        setMode("edit");
        setExistingData(record);
        setIsModalOpen(true);
    };

    return (
        <div className="TemplateManagementPage">
            <TemplateFormModal
                opened={isModalOpen}
                mode={mode}
                fields={toJS(templateStore.fields)}
                data={existingData || null}
                onSubmit={data => {
                    const { values, mode } = data;
                    if (mode === "edit") {
                        templateStore.updateTemplate(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    } else {
                        templateStore.createTemplate(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    }
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                }}
            ></TemplateFormModal>
            <Layout
                showNavigation
                title="Template Administration"
                description="Manage templates"
                extra={[

                ]}
            >
                <div
                    style={contentBox()}
                >
                    <Button
                        style={secondaryButton()}
                        id={"add-template-btn"}
                        disabled={permissionStore.can("template.create")}
                        onClick={() => {
                            setMode("add");
                            setIsModalOpen(true);
                        }}
                    >
                        <PlusOutlined></PlusOutlined>
                        Add Template
                    </Button>
                    <div className="target-table">
                        <TemplateTable
                            canEdit={permissionStore.can("template.update")}
                            canDelete={permissionStore.can("template.delete")}
                            onEditRecord={onEditRecord}
                            onDelete={onDeleteRecord}
                            data={
                                !permissionStore.can("template.list")
                                    ? toJS(templateStore.templates)
                                    : []
                            }
                        ></TemplateTable>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default observer(TemplateManagementPage);
