import React, { useEffect, useContext, useState } from "react";
import { Form, Input, Button, Select, DatePicker, Row, Col, message, Modal, Tag, Table, Checkbox, Tabs } from "antd";
import AlertStore from "../../stores/alert.store";
import UserStore from "../../stores/user.store";
import notificationStore from "../../stores/notification.store";
import _, { set } from "lodash";
import { toJS, observable } from "mobx";
import { observer } from "mobx-react-lite";

const { TabPane } = Tabs;

function NotificationModal({ opened, onCancel, onSubmit, data, name, }) {

    const alertStore = useContext(AlertStore);
    const userStore = useContext(UserStore);
    const notificationStreams = useContext(notificationStore);

    const [deviceName, setDeviceName] = useState(null);

    useEffect(() => {
        userStore.getUsers()
        notificationStreams.setDeviceType(data.device_code);
        //  notificationStreams.getNotificationStreams(data.id);
        console.log(data);
        switch (data.device_code) {
            case "HUNT":
                setDeviceName("Huntsman");
                break;
            case "CCAT":
                setDeviceName("CCAT");
                break;
            case "ATS_JSON":
                setDeviceName("ATS-Tag");
                break;
        }
    }, [data])

    const columns = [
        {
            title: "Notification Stream",
            dataIndex: "alert_name",
            key: "alert_name",
            width: "20%",
        },
        {
            title: "Subscribed Users",
            dataIndex: "Users",
            key: "Users",
            width: "80%",
            render: (text, record) => {
                if (record && record.Users) {
                    return (
                        <div>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Subscribe Users"
                                defaultValue={record.Users.map(user => {
                                    return {
                                        label: user.name,
                                        value: user.userID,
                                    }
                                })}
                                onChange={(value) => {
                                    setNotificationStreams(value, record.alert_name);
                                }}
                                options={userStore.usersInInvestigation(data.investigation_id).map(user => {
                                    return {
                                        label: user.name,
                                        value: user.id,
                                    }
                                })}
                            />
                        </div>
                    )
                }
                return (
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Subscribe Users"
                        onChange={(value) => {
                            setNotificationStreams(value, record.alert_name);
                        }}
                        options={userStore.usersInInvestigation(data.investigation_id).map(user => {
                            return {
                                label: user.name,
                                value: user.id,
                            }
                        })}
                    />
                )
            }
        },
    ];

    const setNotificationStreams = (value, mode) => {
        // work on stream to update
        // value is array of user ideas we'll need their names as well annoyingly
        // get user names from id
        let users = [];
        userStore.usersInInvestigation(data.investigation_id).map((user) => {
            if (value.includes(user.id)) {
                users.push({
                    userID: user.id,
                    name: user.name,
                });
            }
        })
        console.log(users)
        switch (mode) {
            case "Start Movement":
                notificationStreams.StartMovement = users;
                break;
            case "Stop Movement":
                notificationStreams.StopMovement = users;
                break;
            case "Geo Fence IN":
                notificationStreams.GeoIN = users;
                break;
            case "Geo Fence OUT":
                notificationStreams.GeoOUT = users;
                break;
            case "Battery Low":
                notificationStreams.battery = users;
                break;
            case "Tamper Alert":
                notificationStreams.tamper = users;
                break;
            case "Warrant Alert":
                notificationStreams.warrants = users;
                break;
            case "Clone Capture":
                notificationStreams.CloneCapture = users;
                break;
            case "Break Wire 1 Alarming":
                notificationStreams.breakwire1 = users;
                break;
            case "Break Wire 2 Alarming":
                notificationStreams.breakwire2 = users;
                break;
            case "Break Wire 1 Not Alarming":
                console.log("here")
                notificationStreams.breakwire1Falling = users;
                break;
            case "Break Wire 2 Not Alarming":
                  notificationStreams.breakwire2Falling = users;
                  break;
            case "Light Not Detected":
                notificationStreams.lightsensorNot = users;
                break;
            case "Light Detected":
                notificationStreams.lightsensor = users;
                break;
            case "Status0":
                notificationStreams.status0 = users;
                break;
            case "Status1":
                notificationStreams.status1 = users;
                break;
            case "Status2":
                notificationStreams.status2 = users;
                break;
            case "Status3":
                notificationStreams.status3 = users;
                break;
            case "Status4":
                notificationStreams.status4 = users;
                break;
            case "Status5":
                notificationStreams.status5 = users;
                break;
            case "Status6":
                notificationStreams.status6 = users;
                break;

            default:
                // this is to be geofences
                notificationStreams.geoFenceDatas.map((geoFence) => {
                    if (geoFence.alert_name === mode) {
                        geoFence.Users = users;
                    }
                })
                break;
        }
    };

    return (
        <div>
            <Modal
                title={`Notifications: ${name}`}
                visible={opened}
                onOk={() => {
                    notificationStreams.updatestream(data.id, data.investigation_id);
                    onCancel();
                }}
                onCancel={() => {
                    //notificationStreams.clearNotificationStreams();
                    onCancel()
                }}
                width={"70%"}
            >
                <Tabs defaultActiveKey="1" onChange={() => { }}>
                    <TabPane tab="Target Notifications" key="1">
                        <Table
                            columns={columns}
                            size="middle"
                            rowKey="alert_name"
                            dataSource={toJS(notificationStreams.tableStructureCOMP)}
                            scroll={{
                                y: 600,
                            }}
                        />
                    </TabPane>
                    {
                        deviceName !== null &&
                        <TabPane tab={`${deviceName} Notifications`} key="2">
                            <Table
                                columns={columns}
                                size="middle"
                                rowKey="alert_name"
                                dataSource={toJS(notificationStreams.deviceStructureCOMP)}
                                scroll={{
                                    y: 600,
                                }}
                            />
                        </TabPane>
                    }
                </Tabs>
            </Modal>

        </div>
    )
}

export default observer(NotificationModal);