import {Badge, Button, Card, Col, message, Popover, Progress, Row, Tooltip} from "antd";
import {action, observable, makeAutoObservable, makeObservable, computed} from "mobx";
import React, {createContext, useEffect} from "react";
import API from "../services/api";
import {macToMAC} from "../util/CommonUtils";
import moment from "moment/moment";
import getPublicURL from "../utilities/public-url.utility";
import {EnvironmentFilled, UserOutlined} from "@ant-design/icons";

export class AnalysisTripsStore{
    tripsData = [];
    selectedTrip = null;
    targetId = [];
    investigationId = -1;
    timeStart = null;
    timeEnd = null;
    joinTrips = false;

    tableSelectedTrip = [];

    addressArray = {};

    exclusiveSearch = false;
    tripsSettingsOpen = false;

    wifiActivity = [];
    btActivity = [];

    wifiHoverDisplayKey = -1;
    wifiClickDisplayArray = [];

    expandedRow = -1;

    markedPoints = [];

    multipleTarget = false;
    tripsForDisplay = [];

    wifiTableDataForDisplay = [];

    UseSniffScans = false;

    wifiActivityDrawer = false;

    mapRef = null;

    entityDrawVisible = false;

    setEntityDrawVisible(set){
        this.entityDrawVisible = set;
    }

    associatedAccessPoints = []

    isTripSelected(trip){
        // trip.key
        if (this.selectedTrip === null || !this.multipleTarget) return -1;
        return this.tripsForDisplay.findIndex((t) =>
            (t.device_utc_date_time === trip.device_utc_date_time) &&
            (t.stopped_date_time === trip.stopped_date_time) &&
            (t.targetId[1] === trip.targetId[1])
        );
    }

    setMapRef(map){
        this.mapRef = map;
    }

    setUseSniffScans(){
        this.UseSniffScans = !this.UseSniffScans;
    }

    setWifiActivityDrawer(set) {
        this.wifiActivityDrawer = set;
    }

    constructor() {
        this.api = API;
        makeAutoObservable(this);
    }

    SetExpandedRow(key, type) {
        if (type) {
            this.expandedRow = key;
            return;
        }
        this.expandedRow = -1;
    }

    addToMarkedPoints(key) {
        this.markedPoints.push(key);
    }

    makerVisible(key) {
        return this.markedPoints.includes(key);
    }

    removeFromMarkedPoints(key) {
        this.markedPoints = this.markedPoints.filter((k) => k !== key);
    }

    isWiFiInfoVis(key) {
        return this.wifiHoverDisplayKey === key || this.wifiClickDisplayArray.includes(key);
    }

    setHoverWifiInfoVis(key) {
        this.wifiHoverDisplayKey = key;
    }

    setHoverExitWifiInfoVis() {
        this.wifiHoverDisplayKey = -1;
    }

    addWiFiInfoVis(key) {
        this.wifiClickDisplayArray.push(key);
    }

    removeWiFiInfoVis(key) {
        this.wifiClickDisplayArray = this.wifiClickDisplayArray.filter((k) => k !== key);
    }

    isWifiCloned(captureType) {
        return captureType === "WIFI_CLONE_SCAN"
            || captureType === 'WIFI_FRAME_FUNCTION_AUTHENTICATION'
            || captureType === 'WIFI_FRAME_FUNCTION_ASSOCIATION'
            || captureType === 'WIFI_FRAME_FUNCTION_REASSOCIATION'
            || captureType === 'WIFI_FRAME_SUBTYPE_AUTHENTICATION'
            || captureType === 'WIFI_FRAME_FUNCTION_DISASSOCIATION'
            || captureType === 'WIFI_FRAME_FUNCTION_DEAUTHENTICATION'
            // || captureType === 'WIFI_FRAME_FUNCTION_PROBE_RES'

            // || captureType === 'WIFI_FRAME_FUNCTION_PROBE_RES';
    }

    clalcHitCount(mac) {
        const captures = [];
        this.wifiActivity.forEach((wifi) => {
            if (wifi.entity.content.mac_address === mac) {
                captures.push(wifi.entity.content.capture_type)
            }
        });
        return captures;
    }

    findClosestPoint(timeStamp) {
        let closest = null;
        let closestTime = 0;
        if (this.selectedTrip === null) {
            return [];
        }
        this.selectedTrip.trip_data.forEach((point) => {
            if (closest === null) {
                closest = point;
                closestTime = Math.abs(point.device_utc_date_time - timeStamp);
                return;
            }
            if (Math.abs(point.device_utc_date_time - timeStamp) < closestTime) {
                closest = point;
                closestTime = Math.abs(point.device_utc_date_time - timeStamp);
            }
        });
        return closest;
    }

    get TripHalfWayTimeStamp(){
        if (this.selectedTrip === null) {
            return 0;
        }
        return this.selectedTrip.trip_data[Math.floor(this.selectedTrip.trip_data.length / 2)].device_utc_date_time;
    }

    EntitiesExist(key) {
        if (key === 'endFlag') {
            const index = this.wifiActivity.findIndex(item => {
                return (item.entity.content.id !== null && item.entity.content.id !== -1);
            })
            return index !== -1;

        }
        const wifi = this.wifiActivity[key];
        return wifi.entity.content.entity_id !== null && wifi.entity.content.entity_id >= 0;

    }

    NotEntitiesExist(key) {
        if (key === 'endFlag') {
            const index = this.wifiActivity.findIndex(item => {
                return (item.entity.content.id === null && item.entity.content.id === -1);
            })
            return !!index;
        }
        const wifi = this.wifiActivity[key];
        return wifi.entity.content.entity_id === null && wifi.entity.content.entity_id === -1;
    }

    clearList() {
        this.associatedAccessPoints = []
    }

    CreateEntityTree(key) {
        if (this.selectedTrip === null) {
            return [];
        }
        if (key !== "endFlag" ) return [];
        this.clearList()

        const entityArray = [];
        const aplist = [];
        this.wifiActivity.forEach((item, i) => {
            if (item.entity.content.entity_id === null || item.entity.content.entity_id < 0) return;
            if (key !== "endFlag" && i !== key) return;
            // console.log(item);
            const apIndex = aplist.findIndex(e => e === item.entity.content.apID);
            if (apIndex === -1) {
                aplist.push(item.entity.content.apID);
            }
            const index = entityArray.findIndex((e) => e.key === `e_${item.entity.content.entity_id}`);
            if (index === -1) {
                const entity = {
                    key: `e_${item.entity.content.entity_id}`,
                    title: <h6>{item.entity.content.entityAlias}</h6>,
                    label: item.entity.content.entityAlias,
                    children: [],
                };
                const latLngData = this.findClosestPoint(item.entity.content.timestamp);
                const device = {
                    TimeStamp: item.entity.content.timestamp,
                    latitude: latLngData.latitude,
                    longitude: latLngData.longitude,
                    capture_type: item.entity.content.capture_type,
                    end: false,
                    key: item.entity.content.id,
                    Alias: item.entity.alias,
                    Type: item.entity.content.capture_type,
                    MAC: macToMAC(item.entity.content.mac_address),
                    EntityName: item.entity.content.entityAlias,
                    id: item.entity.content.id,
                    hitCount: this.clalcHitCount(item.entity.content.mac_address),
                    apAlias: item.entity.content.apAlias,
                    rssi: item.entity.content.rssi,
                };

                entity.children.push(device)
                entityArray.push(entity);
            } else {
                const latLngData = this.findClosestPoint(item.entity.content.timestamp);
                const device = {
                    TimeStamp: item.entity.content.timestamp,
                    latitude: latLngData.latitude,
                    longitude: latLngData.longitude,
                    capture_type: item.entity.content.capture_type,
                    end: false,
                    key: item.entity.content.id,
                    Alias: item.entity.alias,
                    Type: item.entity.content.capture_type,
                    MAC: macToMAC(item.entity.content.mac_address),
                    EntityName: item.entity.content.entityAlias,
                    id: item.entity.content.id,
                    hitCount: this.clalcHitCount(item.entity.content.mac_address),
                    apAlias: item.entity.content.apAlias,
                    rssi: item.entity.content.rssi,
                };
                if (entityArray[index].children.findIndex((e) => e.MAC === device.MAC) === -1) {
                    entityArray[index].children.push(device);
                }
            }
        })
        this.associatedAccessPoints = aplist;
        return entityArray;
    }

    CreateEntityNames(key) {
        if (key !== "endFlag") return [""];
        const entityNames = [];
        this.wifiActivity.forEach((item, i) => {
            if (item.entity.content.entity_id === null || item.entity.content.entity_id < 0) return;
            const index = entityNames.findIndex(e => e === item.entity.content.entityAlias);
            if (index === -1) {
                entityNames.push(item.entity.content.entityAlias)
            }
        });
        return entityNames
    }

    BuildWifiList() {
        const returnArray = [];
        const apListGrouping = [];
        this.wifiActivity.forEach((wifi, index) => {
            if (returnArray.findIndex((e) => e.MAC === macToMAC(wifi.entity.content.mac_address)) !== -1) return;
            if (!this.UseSniffScans && !(this.isWifiCloned(wifi.entity.content.capture_type))) return;
            if (wifi.entity.content.entity_id !== null && wifi.entity.content.entity_id >= 0) return;
            const apIndex = apListGrouping.findIndex((e) => e.id === wifi.entity.content.apID);
            const latLngData = this.findClosestPoint(wifi.entity.content.timestamp);
            const entity = {
                TimeStamp: wifi.entity.content.timestamp,
                latitude: latLngData.latitude,
                longitude: latLngData.longitude,
                capture_type: wifi.entity.content.capture_type,
                end: false,
                key: index,
                Alias: wifi.entity.alias,
                Type: wifi.entity.content.capture_type,
                MAC: macToMAC(wifi.entity.content.mac_address),
                EntityName: wifi.entity.content.entityAlias,
                id: wifi.entity.content.id,
                rssi: wifi.entity.content.rssi,
                hitCount: this.clalcHitCount(wifi.entity.content.mac_address),
            };
            if (apIndex === -1) {
                const ApListItem = {
                    Alias: wifi.entity.content.apAlias,
                    id: wifi.entity.content.apID,
                    Mac: wifi.entity.content.accessPointMAC,
                    DeviceList: [],
                }
                ApListItem.DeviceList.push(entity);
                apListGrouping.push(ApListItem);
            } else {
                apListGrouping[apIndex].DeviceList.push(entity);
            }
            returnArray.push(entity);
        });

        // can order it here

        // apListGrouping.sort((a, b) => {
        //     const next = this.associatedAccessPoints.findIndex( e => e === b.id)
        //     const currect = this.associatedAccessPoints.findIndex(e => e === a.id )
        //     if (next === currect) return 0;
        //     if (next === -1 && currect !== -1) return -1;
        //     if (next !== -1 && currect === -1) return 1;
        //     return 0;
        // });
        return apListGrouping;
    }

    getWifiInfo(key) {
        if (this.wifiActivity === undefined || this.wifiActivity === null) return [];
        if (key === "endFlag") {

            return this.BuildWifiList()
        }
        if (this.wifiActivity[key] === undefined || this.wifiActivity[key] === null) return [];
        const wifi = this.wifiActivity[key];
        if (wifi.entity.content.entity_id !== null && wifi.entity.content.entity_id >= 0) return [];
        const latLngData = this.findClosestPoint(wifi.entity.content.timestamp);
        const entity = {
            TimeStamp: wifi.entity.content.timestamp,
            latitude: latLngData.latitude,
            longitude: latLngData.longitude,
            capture_type: wifi.entity.content.capture_type,
            end: false,
            key: key,
            Alias: wifi.entity.alias,
            Type: wifi.entity.content.capture_type,
            MAC: macToMAC(wifi.entity.content.mac_address),
            EntityName: wifi.entity.content.entityAlias,
            id: wifi.entity.content.id,
            hitCount: this.clalcHitCount(wifi.entity.content.mac_address),
            rssi: wifi.entity.content.rssi,
        };
        const ApListItem = {
            Alias: wifi.entity.content.apAlias,
            id: wifi.entity.content.apID,
            Mac: wifi.entity.content.accessPointMAC,
            DeviceList: [],
        }
        ApListItem.DeviceList.push(entity);
        return [ApListItem];
    }

    getTripsData() {
        this.tripsData = [];
        this.addressArray = {};
        this.targetId.forEach((target) => {
            if (target[0] === "Target") {
                API.analysis
                    .getTrips({
                        investigation_id: this.investigationId,
                        target_id: [target[1]],
                        to: this.timeEnd,
                        from: this.timeStart,
                    })
                    .then((tripsData) => {
                        this.tripsData.push({id: target, data: tripsData, type: "Target"});
                    });
            }
            if (target[0] === "Entity") {
                API.searchByEntities(
                    target[1],
                    this.investigationId,
                    this.timeEnd,
                    this.timeStart,
                ).then((trips) => {
                    this.tripsData.push({id: target, data: trips, type: "Entity"});
                });
            }
        });
    }

    setTripsMultiTarget() {
        this.multipleTarget = !this.multipleTarget;
        this.tripsForDisplay = [];
    }

    get MapDisplayPoints(){
        if (this.selectedTrip === null) {
            return [];
        }
        this.selectedTrip.trip_data.forEach((point) => {
            point.targetId = this.selectedTrip.targetId;
        });
        return this.selectedTrip.trip_data;
    }

    get MapDisplayPointsMultiple(){
        if (this.tripsForDisplay === null || this.tripsForDisplay.length === 0) {
            return [];
        }
        let returnList = [];
        this.tripsForDisplay.forEach((trip) => {
            trip.trip_data.forEach((point) => {
                point.targetId = trip.targetId;
            });
            returnList.push(trip.trip_data);
        });
        return returnList;
    }

    get latlngTracks(){
        if (this.selectedTrip === null) {
            return [];
        }
        return this.selectedTrip.trip_data.map((point) => {
            return {
                lat: point.latitude,
                lng: point.longitude
            };
        });
    }

    get latlngTracksMultiple(){
        if (this.tripsForDisplay === null || this.tripsForDisplay.length === 0) {
            return [];
        }
        let returnList = [];
        this.tripsForDisplay.forEach((trip) => {
            let tripTracks = trip.trip_data.map((point) => {
                return {
                    lat: point.latitude,
                    lng: point.longitude
                };
            });
            returnList.push(tripTracks);
        });
        return returnList;
    }

    sTableSelectedTrip(record){
        if (this.tableSelectedTrip === null) {
            return false;
        }
        return this.tableSelectedTrip.includes(record.key);
    }

    selectTheTrip(data) {
        let trip = null;
        this.wifiActivity = [];
        this.tripsData.forEach((tripData) => {
            if (tripData.id[1] === data.target) {
                tripData.data.forEach((td) => {
                    if (td.device_utc_date_time === data.start) {
                        trip = td;
                        trip.targetId = tripData.id;
                    }
                });
            }
        });
        const targetId = data.target;
        console.log("loading new data");
        API.CloneCapture.ClonedTripCheck(targetId, data.start, data.end).then((response) => {
            this.wifiActivity = response;
            this.wifiTableDataForDisplay = this.SetWifiTableDataForDisplay(response);
        });
        if (this.multipleTarget && trip !== null) {
            const index = this.isTripSelected(trip);
            if (index === -1) {
                this.tripsForDisplay.push(trip);
                this.tableSelectedTrip.push(data.key);
            } else {
                this.tripsForDisplay.splice(index, 1);
                this.tableSelectedTrip = this.tableSelectedTrip.filter((key) => key !== data.key);
            }
        }
        this.expandedRow = data.key;
        this.selectedTrip = trip;
        if (!this.multipleTarget) {
            this.tableSelectedTrip = [data.key];
        }
        // API.bluetooth.getCaptures(data.target, data.start. data.end).then((response) => {
        //     this.btActivity = response;
        // })
    }

    SetWifiTableDataForDisplay(data) {
        let returnList = [];
        // race condition with the wifiActivity
        if (this.wifiActivity === undefined || this.wifiActivity === null || this.wifiActivity.length === 0) {
            return [];
        }
        this.wifiActivity.forEach((wifi, index) => {
            if (!this.UseSniffScans && !(this.isWifiCloned(wifi.entity.content.capture_type))) return;
            const entity = {
                key: index,
                Alias: wifi.entity.alias,
                Type: wifi.entity.content.capture_type,
                MAC: macToMAC(wifi.entity.content.mac_address),
                EntityName: wifi.entity.content.entityAlias,
                id: wifi.entity.content.id,
            };
            // if (entity.Alias === undefined || entity.Alias === null) {
            //     entity.Alias = macToMAC(wifi.entity.content.mac_address) + ":" + wifi.entity.content.apAlias;
            // }
            returnList.push(entity);
        });
        return returnList.sort((a, b) => {
            return b.end - a.end;
        });
    }

    get WifiDevicesForMap(){
        let returnList = [];
        if (this.wifiActivity === undefined || this.wifiActivity === null || this.wifiActivity.length === 0) return [];
        this.wifiActivity.forEach((wifi, index) => {
            if (!this.UseSniffScans && !(this.isWifiCloned(wifi.entity.content.capture_type))) return;
            const latLngData = this.findClosestPoint(wifi.entity.content.timestamp);
            const entity = {
                TimeStamp: wifi.entity.content.timestamp,
                latitude: latLngData.latitude,
                longitude: latLngData.longitude,
                capture_type: wifi.entity.content.capture_type,
                end: false,
                key: index,
                Alias: wifi.entity.alias,
                Type: wifi.entity.content.capture_type,
                MAC: macToMAC(wifi.entity.content.mac_address),
                EntityName: wifi.entity.content.entityAlias,
                id: wifi.entity.content.id,
            };

            // if (entity.Alias === undefined || entity.Alias === null) {
            //     entity.Alias = macToMAC(wifi.entity.content.mac_address) + ":" + wifi.entity.content.apAlias;
            // }

            returnList.push(entity);
        });
        returnList.push({
            latitude: this.selectedTrip.trip_data[this.selectedTrip.trip_data.length - 1].latitude,
            longitude: this.selectedTrip.trip_data[this.selectedTrip.trip_data.length - 1].longitude,
            capture_type: "end",
            end: true,
            key: "endFlag",
            Alias: "",
            Type: "",
            MAC: "",
            EntityName: "",
        });

        return returnList;
    }

    tableDisplayTrips(){
        let returnList = [];
        if (this.tripsData === undefined || this.tripsData === null) return [];
        let rollingIndex  = 0;
        this.tripsData.forEach((trip) => {
            if (!trip.data || trip.data.length === 0 ) return [];
            trip.data.forEach((tripData, index) => {
                returnList.push({
                    key: index + rollingIndex,
                    target: trip.id[1],
                    type: trip.type,
                    start: tripData.device_utc_date_time,
                    end: tripData.stopped_date_time,
                    duration: tripData.trip_duration,
                    startLatLng: {lat: tripData.trip_data[0].latitude, lng: tripData.trip_data[0].longitude},
                    startAddress: null,
                    endLatLng: {lat: tripData.trip_data[tripData.trip_data.length - 1].latitude, lng: tripData.trip_data[tripData.trip_data.length - 1].longitude},
                    endAddress: null,
                });
            });
            rollingIndex += trip.data.length;
        });
        if (returnList.length === 0) return [];
        return returnList.sort((a, b) => {
            return b.end - a.end;
        });
    }

    getAddressInArray(key) {
        if (this.addressArray[key] === undefined || this.addressArray[key] === null) return "";
        return this.addressArray[key];
    }

    completedForm(data) {
        this.tripsData = [];
        this.targetId = data.TargetSelection;
        // ['Target'] means all targets
        this.investigationId = data.Investigation;
        if (data.Date !== undefined) {
            this.timeEnd = Math.floor(Date.now() / 1000);
            this.timeStart = this.timeEnd - data.Date;
        } else if (data.datepicker) {
            this.timeStart = data.datepicker[0].unix();
            this.timeEnd = data.datepicker[1].unix();
        }
        this.getTripsData();
    }

    async geoCodingTask(lat, lng) {
        let text = "";
        await this.api.geoCoding.DoTheGeoCoder(lat, lng).then((response) => {
            text = response;
        });
        return `${text.split(",")[0]}, ${text.split(",")[1]}`;
    }
}

export default createContext(new AnalysisTripsStore());
