import { Badge } from "antd";
import moment from "moment";
import React from "react";
import "./StatusDot.scss";

export default function StatusDot({ timestamp }) {
    const getLabelForTime = timestamp => {
        if (!timestamp) {
            return "default";
        } else {
            const isInactive = moment().diff(moment(timestamp), "days") > 3;
            if (isInactive) {
                return "warning";
            } else {
                return "success";
            }
        }
    };

    return <Badge dot offset={[ 4, 4 ]} status={getLabelForTime(timestamp)} />;
}
