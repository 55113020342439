import { MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Row, Col } from "antd";
import React from "react";
import "./ForgotPasswordForm.scss";
import { captcha } from "../CaptchaEngine/index";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

class DemoUserForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let user_captcha_value = values.Captcha;
        if (validateCaptcha(user_captcha_value) == true) {
          this.props.onSubmit(values);
        }

        else {
          alert('Captcha Does Not Match');
        }
      }
    });
  };
  componentDidMount() {
    loadCaptchaEnginge(6);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="password-form">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Full Name">
            {getFieldDecorator("Name", {
              rules: [
                { required: true, message: "Your name is required." }
              ]
            })(
              <Input
                prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Full Name"
              />
            )}
          </Form.Item>
          <Form.Item label="Email Address">
            {getFieldDecorator("emailAddress", {
              rules: [
                { required: true, message: "Your email address is required." }
              ]
            })(
              <Input
                prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Email Address"
              />
            )}
          </Form.Item>
          <Form.Item label="Mobile Number">
            {getFieldDecorator("mobileNumber", {
              rules: [
                { required: true, message: "Your Mobile Number is required." }
              ]
            })(
              <Input
                prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Mobile Number"
              />
            )}
          </Form.Item>
          <Form.Item label="Notes">
            {getFieldDecorator("Notes", {
              rules: [
                { required: true, message: "Your notes is required." },
                { max: 500, message: "Your notes is too long." },
              ]
            })(
              <Input
                prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Notes"
              />
            )}
          </Form.Item>
          <div>
            <LoadCanvasTemplateNoReload />
          </div>
          <Form.Item label="Captcha" extra="We must make sure that your are a human.">
            {getFieldDecorator("Captcha", {
              rules: [
                { required: true, message: "Captcha Required." },
              ]
            })(
                <Input
                placeholder='captcha'/>
              )}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="password-form-button"
          >
            Continue
          </Button>
          <Button
            type="link"
            block
            onClick={() => {
              this.props.onBack();
            }}
          >
            Go back
          </Button>
        </Form>

      </div>
    );
  }
}

export default Form.create({})(DemoUserForm);
