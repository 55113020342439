import React, { useEffect, useRef, useState } from "react";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    HeatmapLayer,
} from "@react-google-maps/api";
// https://stackoverflow.com/questions/46591519/react-google-maps-heatmaplayer-undefined
import MapStyles from "../../map-styles";
import { getTargetIcon } from "../../util/CommonUtils";
import DeviceDetails from "../DeviceDetails";
import { mapDefault } from "../../util/CommonUtils";
import "./index.css";

// https://blog.alexdevero.com/custom-styled-google-map-react/
// https://tomchentw.github.io/react-google-maps/#installation

const StopsMap = (
    ({ data, selectedPoint, panBy, currentPosition, error, liveTargets, speedUnit }) => {
        const [map, setMap] = useState();

        const [isOpen, setIsOpen] = useState(false);
        const [targetId, setTargetId] = useState(null);

        const coordinates = data.map((point) => {
            return new window.google.maps.LatLng(point.latitude, point.longitude);
        });

        const [located, setLocated] = useState(false);

        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        }

        const success = (pos) => {
            const crd = pos.coords;

            console.log("success")

            if (located === false) {
                map.panTo({
                    lat: crd.latitude,
                    lng: crd.longitude,
                });
                setLocated(true);
            }
        }

        const fail = (error) => {
            console.log(`Error: ${error.code}`);

            if (located === false) {
                map.panTo(mapDefault())
                setLocated(true);
            }
        }

        navigator.geolocation.getCurrentPosition(success, fail, options);

        useEffect(() => {
            if (data) {
                if (selectedPoint && map) {
                    map.panTo({
                        lat: selectedPoint.latitude,
                        lng: selectedPoint.longitude,
                    });
                    map.panBy(...panBy);
                } else {
                    if (data.length > 0) {
                        const points = data.map((point) => ({
                            ...point,
                            lng: point.longitude,
                            lat: point.latitude,
                        }));
                        const bounds = new window.google.maps.LatLngBounds();
                        points.forEach((point) => {
                            bounds.extend(point);
                        });
                        if (map) {
                            map.fitBounds(bounds);
                        }
                    }
                }
            }
        }, [data, panBy, selectedPoint, map]);

        useEffect(() => {
            if (currentPosition) {
            }
        }, [currentPosition]);

        return (
            <GoogleMap
                // googleMapURL={googleMapURL}
                style={{
                    borderRadius: "7px",
                }}
                zoom={15}
                defaultCenter={mapDefault()}

                onIdle={() => {

                    if (map.getCenter()) {
                        localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                    }
                }}
                onLoad={(map) => { setMap(map) }}

                mapContainerStyle={{
                    height: "74vh",
                    width: "100%",
                    borderRadius: '8px',
                    overflow: 'hidden'
                }}
            >
                {selectedPoint && (
                    <Marker
                        // animation={window.google.maps.Animation.BOUNCE}
                        position={{
                            lat: selectedPoint.latitude,
                            lng: selectedPoint.longitude,
                        }}
                    >
                        <InfoWindow
                            position={{
                                lat: selectedPoint.latitude,
                                lng: selectedPoint.longitude,
                            }}>
                            <div>{selectedPoint.address || "N/A"}</div>
                        </InfoWindow>
                    </Marker>
                )}

                {
                    liveTargets.map(x => {
                        return (
                            <Marker
                                key={x.target_id}
                                position={{
                                    lat: Number(x.latitude),
                                    lng: Number(x.longitude),
                                }}
                                clickable={true}

                                options={{
                                    icon: {
                                        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getTargetIcon(x.colour)),
                                        scaledSize: new window.google.maps.Size(25, 25)
                                    }
                                }}
                                cursor="pointer"
                                onMouseOut={() => {
                                    console.log("Working")
                                    setIsOpen(false);
                                }}
                                onMouseOver={() => {
                                    console.log("Not Working")
                                    setIsOpen(true)
                                    setTargetId(x.target_id)
                                }}
                            >

                                {
                                    isOpen && x.target_id === targetId && (
                                        <InfoWindow
                                            position={{
                                                lat: Number(x.latitude),
                                                lng: Number(x.longitude),
                                            }}
                                        >
                                            <DeviceDetails
                                                isTestLiveMap={false}
                                                color={"#ffffff"}
                                                item={x}
                                                speed_unit={speedUnit}
                                            />
                                        </InfoWindow>
                                    )
                                }
                            </Marker>
                        )
                    })
                }
                <HeatmapLayer data={coordinates} options={{ radius: 30 }} />
                {/* <TrafficLayer autoUpdate /> */}
            </GoogleMap>
        );
    }
);

export default StopsMap;
