import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Row, Select, Spin, Table, Tag } from "antd";
import { toJS } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import API from "../../services/api";
import InvestigationStore from "../../stores/investigation.store";
import { contentBox, primaryButton, secondaryButton } from '../../styles';
import {formatDate} from "../../util/CommonUtils";

function DuressAuditTab(props) {
    const investigationStore = useContext(InvestigationStore);

    const [showDropDown, setShowDropDown] = useState(false);

    useEffect(() => {
        async function getInvestigations() {
            await investigationStore.getInvestigationsForUser().then((res) => {
                setShowDropDown(true);
            });
        }

        getInvestigations();
    // eslint-disable-next-line
  }, []);

    const exportData = async () => {
        const updatedData = props.sos.map(x => {
            x.initiatedAt = ` ${formatDate(x.initiatedAt, 'HH:mm:ss')}`
            x.acceptedAt = x.acceptedAt ? ` ${formatDate(x.acceptedAt, 'HH:mm:ss')}` : "";
            x.canceledAt = x.canceledAt ? ` ${formatDate(x.canceledAt, 'HH:mm:ss')}` : "";
            return x;

        })
        await API.sos.downloadSOSDump(updatedData);
    };

    const handleSubmit = (e) => {
        props.form.validateFields((error, values) => {
            if (!error) {
                const [from, to] = values.timeRangeDuress;

                props.onFilterClicked(values.investigation_id, from, to);
                // props.onFilterClicked("175583", "1584433470860", "1584433471091");
            } else {
                // alert(JSON.stringify(error, null, 2));
            }
        });
    };

    const dataSource = [];
    if (props.sos && props.sos.length > 0) {
        props.sos.forEach((item) => {
            dataSource.push({
                key: item.id,
                initiatedTime: formatDate(item.initiatedAt, 'HH:mm:ss'),
                initiator: item.initiator_alias,
                acceptedTime: item.acceptedAt
                    ? formatDate(item.acceptedAt, 'HH:mm:ss')
                    : "N/A",
                acceptor: item.acceptor_alias ? item.acceptor_alias : "N/A",
                canceledTime: item.canceledAt
                    ? formatDate(item.canceledAt, 'HH:mm:ss')
                    : "N/A",
                canceledBy: item.canceledBy ? item.canceledBy : "N/A",
                status: item.status,
            });
        });
    }
    const columns = [
        {
            title: "Initiated Time",
            dataIndex: "initiatedTime",
            key: "initiatedTime",
            width: "70",
        },
        {
            title: "Initiated User",
            dataIndex: "initiator",
            key: "initiator",
            width: "40",
        },
        /*{
            title: 'Initiators Latitude',
            dataIndex: 'action',
            key: 'action',
            width: '90'
        },
        {
            title: 'Initiators Longitude',
            dataIndex: 'email',
            key: 'email',
            width: '70'
        },*/
        {
            title: "Accepted Time",
            dataIndex: "acceptedTime",
            key: "acceptedTime",
            width: "70",
        },
        {
            title: "Accepted User",
            dataIndex: "acceptor",
            key: "acceptor",
            width: "40",
        },
        /* {
             title: 'Acceptors Latitude',
             dataIndex: 'username',
             key: 'username',
             width: '70'
         },
         {
             title: 'Acceptors Longitude',
             dataIndex: 'username',
             key: 'username',
             width: '70'
         },*/
        {
            title: "Canceled By",
            dataIndex: "canceledBy",
            key: "canceledBy",
            width: "40",
        },
        {
            title: "Canceled Time",
            dataIndex: "canceledTime",
            key: "canceledTime",
            width: "60",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: "120",
            render: (text, record) => {
                if (record.status === "pending") {
                    return <Tag color="orange">Pending</Tag>;
                } else if (
                    record.status === "canceled" ||
          record.status === "system_canceled"
                ) {
                    return <Tag color="red">Canceled</Tag>;
                } else if (record.status === "accepted") {
                    return <Tag color="green">Accepted</Tag>;
                } else if (record.status === "timeout") {
                    return <Tag color="purple">Timeout</Tag>;
                }
            },
        },
    ];

    const { getFieldDecorator } = props.form;
    return (
        <React.Fragment>
            <div style={contentBox()}>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Investigation
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("investigation_id", {
                                    // onChange: props.onDateRangeSelect,
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select a investigation!",
                                        },
                                    ],
                                })(
                                    <Select showSearch placeholder="Investigation" id={"test"}>
                                        {showDropDown === true &&
                      toJS(
                          investigationStore.investigationsForUser.map(
                              (investigation) => {
                                  return (
                                      <Select.Option
                                          key={investigation.id}
                                          id={"investigation_" + investigation.id}
                                          value={investigation.id}
                                      >
                                          {investigation.name}
                                      </Select.Option>
                                  );
                              }
                          )
                      )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Date Range
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("timeRangeDuress", {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select a time-range!",
                                        },
                                    ],
                                })(
                                    <DatePicker.RangePicker
                                        id={"datepicker_range"}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            textAlign: "end",
                        }}
                    >
                        <Button
                            onClick={() => {
                                handleSubmit();
                            }}
                            style={primaryButton({ marginLeft: "auto" })}
                            key="filrer"
                            id={"duress-submit"}
                        >
              Search
                        </Button>

                        <Button
                            onClick={() => {
                                exportData();
                            }}
                            disabled={dataSource.length === 0}
                            style={secondaryButton({ marginLeft: "3px" })}
                            id={"duress-export"}
                        >
              Export
                        </Button>
                    </Row>
                    <hr />
                </Form>
                <br />
            </div>

            {props.spinnerVisible === true ? (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5%",
                    }}
                >
                    <Spin size={"large"} />
                </div>
            ) : (
                <Table
                    style={contentBox()}
                    className={"duress-table"}
                    dataSource={dataSource}
                    columns={columns}
                    scroll={{ x: 1271 }}
                />
            )}
        </React.Fragment>
    );
}

export default Form.create({})(DuressAuditTab);
