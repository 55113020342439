import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import analysisTripsStore from "../../stores/analysisTrips.store";
import {Spin} from "antd";
import {EnvironmentOutlined} from "@ant-design/icons";

function AddressDisplay(data) {
    const tripsStore = useContext(analysisTripsStore);
    const [Address, setAddress] = useState("")
    tripsStore.geoCodingTask(data.lat, data.lng).then((res) => {
        setAddress(res);
    })
    return (
        <div>
            <Spin spinning={Address === ""}></Spin>
            <EnvironmentOutlined style={{color: '#717171'}}/>   {Address}
        </div>
    )
}

export default observer(AddressDisplay);