import { message } from "antd";
import {action, observable, toJS, makeAutoObservable} from "mobx";
import moment from "moment";
import { createContext } from "react";
// Services
import API from "../services/api";

export class SOSStore {

    constructor() {
        this.api = API;
        this.ongoingSOS = [];

        this.filteredSOS = [];

        this.isMuted = false;

        this.receivedSOS = null;

        this.usersSOS = [];
        makeAutoObservable(this);
    }

    setSocketData(data) {
        return
    }

    getPendingSOS() {
        return
    }

    setIsMuted(value) {
        return
    }

    async getUSerSOS() {
        return
    }

    async cancelSOS(sosId) {
        return
    }
}

export default createContext(new SOSStore());
