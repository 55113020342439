import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

// eslint-disable-next-line
const getLiveTargets = async () => {
    try {
        const response = await HTTP.get("/target/live");
        const data = response.data.data.investigation;

        // for (const investigation of data) {
        //   for (const target of investigation.target) {
        //     const index = investigation.target
        //       .map((e) => e.target_id)
        //       .indexOf(target.target_id);
        //
        //     const recoveryDetails = await fetchRecoveryModeForDeviceID(
        //       target.device_id
        //     );
        //     investigation.target[index] = {
        //       ...investigation.target[index],
        //       recoveryDetails: recoveryDetails,
        //     };
        //
        //   }
        // }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getLiveTargets,
};
