import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Badge } from 'antd';
import TimeAgo from "react-timeago";
import { formatDate } from '../../util/CommonUtils';

// One Year in milliseconds
const NEVER_EXPIRE_THRESHOLD = (365 * 24 * 60 * 60 * 1000);

export default function LiveModeIndicator({
    record
}) {
    let indicator = "default";
    let expiryDate = null;
    let expiredText = "N/A";
    let fragment = (
        <React.Fragment>
            <Badge status={indicator}></Badge>
            {"N/A"}
        </React.Fragment>);

    // If the configuration has not been updated, then we know nothing about live status
    if (record.recovery_settings === null) {
        return fragment;
    }

    // Huntsman can not define an expiry date, and live mode is either on or off
    if (record.model === 'HUNTSMAN') {
        // Huntsman device expiration logic
        // Huntsman is in live mode if recovery_settings is set to 1, else it is not live
        if (parseInt(record.recovery_settings) === 1) {
            switch (parseInt(record.recovery_status)) {
                case 1: {
                    indicator = "processing";
                    expiredText = "Pending";
                    break;
                }
                case 2: {
                    indicator = "success";
                    expiryDate = (parseInt(record.device_updated_at) * 1000);
                    expiredText = (<TimeAgo date={expiryDate} />);
                    break;
                }
                case 3: {
                    indicator = "error";
                    expiredText = "Rejected";
                    break;
                }
                default: {
                    indicator = "error";
                    expiredText = "Unknown";
                    break;
                }
            }
        }

        // Generate Huntsman live mode indicator
        fragment = (
            <React.Fragment>
                <Tooltip title={formatDate((record.device_updated_at + record.recovery_settings), 'HH:mm:ss')}>
                    <Badge status={indicator} />
                    {(expiredText)}
                </Tooltip>
            </React.Fragment>
        );

    } else {

        // Indicator on if the setting has been accepted
        if (record.recovery_status !== null) {
            switch (parseInt(record.recovery_status)) {
                case 1: {
                    indicator = "processing";
                    expiredText = "Pending";
                    break;
                }
                case 2: {
                    // Get the recovery date
                    if (record.device_updated_at > record.recovery_updated_at) {
                        // Expiry date from the time the device accepted recovery settings
                        expiryDate = new Date((parseInt(record.device_updated_at) + parseInt(record.recovery_settings)) * 1000);
                        indicator = (Date.now() > expiryDate.getTime()) ? "default" : "success";
                        expiredText = (<TimeAgo date={expiryDate} />);
                    } else {
                        // Device has not communicated with server, yet is marked as configuration accepted
                        indicator = "error";
                        expiredText = "ERROR";
                    }
                    break;
                }
                case 3: {
                    indicator = "error";
                    expiredText = "Rejected";
                    break;
                }
                default: {
                    indicator = "error";
                    expiredText = "Unknown";
                    break;
                }
            }
        }

        // Generate digital matters live mode indicator
        fragment = (
            <React.Fragment>
                <Tooltip title={formatDate((record.device_updated_at + record.recovery_settings), 'HH:mm:ss')}>
                    <Badge status={indicator} />
                    {expiredText}
                </Tooltip>
            </React.Fragment>
        );
    }

    return (fragment);
}

LiveModeIndicator.propTypes = {
    record: PropTypes.object.isRequired
};
