import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();


/**
 * Get admin bulletin
 * @returns {Promise<unknown>}
 */
const getAdminBulletin = async () => {
    try {
        const response = await HTTP.get(`/admin-bulletin`);
        const data = response.data.data;


        return Promise.resolve(data);
    } catch (exception) {
        if (exception.response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve({adminBulletin: []});
        }
        return Promise.reject(exception);
    }
}

/**
 * Get dashboard admin bulletin
 * @returns {Promise<unknown>}
 */
const getDashboardAdminBulletin = async () => {
    try {
        const response = await HTTP.get(`/admin-bulletin?dashboard=1`);
        const data = response.data.data;


        return Promise.resolve(data);
    } catch (exception) {
        if (exception.response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve({adminBulletin: []});
        }
        return Promise.reject(exception);
    }
}


export default {
    getAdminBulletin,
    getDashboardAdminBulletin
};