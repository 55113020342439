import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";
import { Parser } from "json2csv";
const fileDownload = require("js-file-download");
const parser = new Parser();

// DEBUG
window.API = API;

export class userAuditsStore {
    constructor() {
        this.api = API;
        this.auditHistory = [];
        this.timerange = [null, null];
        this.device = null;
        this.target = null;

        this.selectedEvent = null;
        makeAutoObservable(this);
    }

    UpdateHistory = async () => {
        this.auditHistory = [];
        this.timerange = [null, null];
        this.selectedEvent = null;
        this.device = null;
        this.target = null;
        const response = await this.api.deviceTargetHistory.getALL();
        this.auditHistory = response;
    }

    get displayHistory() {
        let returnValue = this.auditHistory;
        if (this.device) {
            returnValue = returnValue.filter((item) => item.device_serial === this.device);
        }
        if (this.target) {
            returnValue = returnValue.filter((item) => item.target === this.target);
        }
        if (this.timerange[0] && this.timerange[1]) {
            returnValue = returnValue.filter((item) => item.created_at >= this.timerange[0] && item.created_at <= this.timerange[1]);
        }
        return returnValue;
    }

    updateDevice(device) {
        this.device = device;
    }

    updateTarget(target) {
        this.target = target;
    }

    updateTimeRange(timeRange) {
        this.timerange = timeRange;
    }

    selectEvent(event) {
        this.selectedEvent = event;
    }

    get ResultSelectedEvent() {
        const event = this.selectedEvent;
        if (event) {
            try {
                const newPayload = JSON.parse(event.payload);
                event.payload = newPayload;
                if (event.payload.settings) {
                    const newSettings = JSON.parse(event.payload.settings);
                    event.payload.settings = newSettings;
                }
                if (event.payload.past_config) {
                    const newPastConfig = JSON.parse(event.payload.past_config[0].settings);
                    event.payload.past_config = newPastConfig;
                }
            } catch (e) {
                console.log(e);
            }
        }
        return event;
    }

    downloadHistory = async () => {
        const contents = this.displayHistory;
        // this will be downloaded as a csv file
        const csv = parser.parse(contents);
        const date = new Date();
        fileDownload(csv, `UserAuditRecord-${date.toISOString()}.csv`);
    }

}

export default createContext(new userAuditsStore());
