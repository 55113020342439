export const videoLinks = {
    "/help": "https://www.youtube.com/embed/dQw4w9WgXcQ",
    "/": "https://www.youtube.com/embed/dQw4w9WgXcQ",
}
export const videoText = {
    "/help": "This is a video tutorial for help page",
    "/": "This is a video tutorial for home page",
}

export const DeviceMessageDictionary = {
    get ATRAXWIFI () {
        return this.ATRAXGPS;
    },
    ATRAXGPS: [
        'HELLO_REQUEST',
        'COMMIT_REQUEST',
        'VERSION_DATA',
        'RESERVED',
        'START_OF_TRIP',
        'END_OF_TRIP',
        'ELAPSED_TIME',
        'SPEED_CHANGE',
        'HEADING_CHANGE',
        'DISTANCE_TRAVELLED',
        'MAXIMUM_SPEED',
        'STATIONARY',
        'DIGITAL_INPUT_CHANGED',
        'DIGITAL_OUTPUT_CHANGED',
        'HEARTBEAT',
        'HARSH_BREAK',
        'HARSH_ACCELERATION',
        'HARSH_CORNERING',
        'EXTERNAL_POWER_CHANGE',
        'SYSTEM_POWER_MONITORING',
        'DRIVER_ID_TAG_READ',
        'OVERSPEED',
        'FUEL_SENSOR_RECORD',
        'TOWING_ALERT',
        'DEBUG',
        'SDI-12-SENSOR_DATA',
        'ACCIDENT',
        'ACCIDENT_DATA',
        'SENSOR_VALUE_ELAPSED_TIME',
        'SENSOR_VALUE_CHARGE',
        'SENSOR_ALARM',
        'RAIN_GAUGE_TIPPED',
        'TAMPER_ALERT',
        'BLOB_NOTIFICATION',
        'TIME_AND_ATTENDANCE',
        'TRIP_RESTART',
        'TAG_GAINED',
        'TAG_UPDATE',
        'TAG_LOST',
        'RECOVERY_MODE_ON',
        'RECOVERY_MODE_OFF',
        'IMMOBILISER_ON',
        'IMMOBILISER_OFF',
        'GARMIN_FMI_STOP_RESPONSE',
        'LONE_WORKER_ALARM',
        'DEVICE_COUNTERS',
        'CONNECTED_DEVICE_DATA',
        'ENTERED_GEO_FENCE',
        'EXITED_GEO_FENCE',
        'HIGH_G_EVENT',
        'THIRD_PARTY_DATA_RECORD',
        'DURESS',
        'CELL_TOWER_CONNECTION',
        'BLUETOOTH_TAG_DATA',
    ],
    get ATRAXBOLT () {
        return this.ATRAXGPS;
    },
    INPR: [null],
    HUNT: [
        'MOVEMENT_LOG',
        'DEVICE_STATUS',
        'BLUETOOTH_CAPTURE',
        'WIFI_ROUTER',
        'WIFI_CLONE_SCAN',
        'WIFI_SNIFF_SCAN',
        'COMMIT_REQUEST',
        'COMMAND_ACKNOWLEDGED',
        'HELLO',
        'DEBUG'
    ],
    MT700: ['MICTRACK POINT'],
    Q: ['Q_DEVICE'],
    get ATRAXGPS3() {
        return this.ATRAXGPS;
    },
    MC350: ['MICTRACK POINT'],
    TCP_COT: ['COT_POINT'],
    CCAT: [
        'MOVEMENT_LOG',
        'HELLO',
        'COMMIT',
        'ALERT',
        'DEVICE_STATUS',
        'Led_covert_Mode',
        'Relay_settings',
        'Cell_settings',
        'Timer_settings',
        'Alert_settings',
        'Rf_Settings',
    ],
    ATS_JSON:['ATS_TAG'],
};