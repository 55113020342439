import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const updateProfile = async ({
    username,
    name,
    alias,
    email,
    mobile,
    serviceNumber
}) => {
    try {
        const response = await HTTP.post("/user/profile", {
            username,
            name,
            alias,
            email,
            mobile,
            serviceNumber
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user[response.data.data.user.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updatePassword = async ({ oldPassword, password }) => {
    try {
        const response = await HTTP.post("/user/profile/password", {
            oldPassword,
            password
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user[response.data.data.user.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const contactAdmin = async ({ subject, message }) => {
    try {
        const response = await HTTP.post("/user/profile/contact", {
            subject,
            message
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.user[response.data.data.user.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    updateProfile,
    updatePassword,
    contactAdmin
};
