import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import './DashboardSettings.scss';
import {Checkbox, Typography} from "antd";

const SortableItem = SortableElement(({value}) => {
    return (
        <div className={'item-sortable'}>
            <Checkbox value={value.id}>
                <Typography.Text strong>{value.name}</Typography.Text><br/>
                <Typography.Text type="secondary">{value.description}</Typography.Text>
            </Checkbox>
        </div>
    );
});

const SortableContainerWrapper = SortableContainer(({children}) => {
    return <div className={'container-sortable'}>{children}</div>;
});

const DashboardSettings = (props) => {
    return (
        <Checkbox.Group style={{ width: '100%' }} onChange={props.onChange} defaultValue={props.userSettingIds}>
            <SortableContainerWrapper onSortEnd={props.onSortEnd}>
                {props.settings.map((value, index) => (
                    <SortableItem key={`item-${value.name}`} index={index} value={value}/>
                ))}
            </SortableContainerWrapper>
        </Checkbox.Group>
    );
}

export default DashboardSettings