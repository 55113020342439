import { EditOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from "antd";
import React from "react";
import "./ContactAdminForm.scss";
import { contentBox, primaryButton, secondaryButton } from '../../styles';

const { TextArea } = Input;

class ContactAdminForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
      this.props.form.resetFields();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="access-form"
        style={contentBox({ height: "100%" })}
      >
        <Form onSubmit={this.handleSubmit} style={{ height: "100%" }}>
          <div style={{
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "space-between",
            alignItems: "stretch",
            alignContent: "stretch"
          }}>
            <Form.Item label="Subject">
              {getFieldDecorator("subject", {
                rules: [{
                  required: true,
                  min: 5,
                  message: "Please enter a Subject with at least 5 letters."
                }]
              })(
                <Input
                  prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                  placeholder="Subject"
                  />
              )}
            </Form.Item>{" "}
            <Form.Item label="Message" style={{ flexGrow: "1", height: "100%" }}>
              {getFieldDecorator("message", {
                rules: [
                  {
                    required: true,
                    message: "A message for the admin is required."
                  }
                ]
              })(
                <TextArea placeholder="Message to the admin" style={{ height: "100%", resize: "none" }} />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="access-form-button"
                style={primaryButton({marginTop: "2em"})}
              >
                Send Message
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(ContactAdminForm);
