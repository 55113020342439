import {
    GoogleMap,
    Polygon,
    TrafficLayer,
    Marker,
    OverlayView,
    InfoWindow,
    Circle,
    InfoBox,
    Polyline,
} from "@react-google-maps/api";
import React, { useEffect, useState, useRef, useContext } from "react";
import { mapDefault, macToMAC } from "../../util/CommonUtils";
import getPublicURL from "../../utilities/public-url.utility";
import { Badge } from "antd";

const WiFiDeviceMap = (({ apLatitude, apLongitude, apTrip, clones, clicked, cloneSelect, clonedeselection, mainEvent, editbtn }) => {

    const google = window.google

    const [map, setMap] = useState(null);
    const [located, setLocated] = useState(false);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;


        if (located === false) {
            map.panTo({
                lat: crd.latitude,
                lng: crd.longitude,
            });
            setLocated(true);
        }
    }

    const fail = (error) => {

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, fail, options);
    }, [map]);

    useEffect(() => {
        if (apLatitude && apLongitude) {
            if (map) {
                map.panTo({
                    lat: apLatitude,
                    lng: apLongitude,
                });
            }
        }
    }, [apLatitude, apLongitude]);

    const latlngTracks = [];
    // converting trips into google maps latlng format
    if (apTrip) {
        apTrip.forEach(point => {
            latlngTracks.push({
                lat: point.latitude,
                lng: point.longitude
            })
        });
    }

    const aliasList = [];

    if (clones) {
        clones.forEach((device, index) => {
            if (device.entity.alias === null || device.entity.alias === "") {

                // cleaning up ssid
                let wifiSSID = {};
                if (typeof (device.entity.wifiConnection.ssid) === "string") {
                    //dealing with the case if wifissid is not a meta data field and being passed as a string
                    try {
                        wifiSSID = JSON.parse(device.entity.wifiConnection.ssid)
                    } catch {
                        wifiSSID.ssid = device.entity.wifiConnection.ssid
                    }
                } else {
                    wifiSSID.ssid = "No AccessPoint found";
                }

                // cleaning up mac address

                let str = device.entity.wifiConnection.deviceAddress.toUpperCase();
                let strModify = macToMAC(str);

                device.entity.alias = `${wifiSSID.ssid} - ${strModify}`;
            }

            // grouping by same location of scan
            let x = (aliasList.
                find(item => item.latitude === device.entity.wifiConnection.latitude && item.longitude === device.entity.wifiConnection.longitude
                ));
            //no location found
            if (typeof (x) === "undefined") {
                aliasList.push({
                    aliases: [{ entity: device.entity, id: device.entity.content.id, hitcount: 1 }],
                    latitude: device.entity.wifiConnection.latitude,
                    longitude: device.entity.wifiConnection.longitude,
                    pointId: index,
                });
            } else {
                //location found
                let y = aliasList.findIndex(item => item.latitude === device.entity.wifiConnection.latitude && item.longitude === device.entity.wifiConnection.longitude);
                // check if wifi device is already in list
                if (aliasList[y].aliases) {
                    let j = aliasList[y].aliases.findIndex(point => point.entity.alias === device.entity.alias);
                    if (j >= 0) {
                        aliasList[y].aliases[j].hitcount += 1;
                    } else {
                        aliasList[y].aliases.push({ entity: device.entity, id: device.entity.content.id, hitcount: 1 });
                    }
                } else {
                    aliasList[y].aliases.push({ entity: device.entity, id: device.entity_alias, hitcount: 1 });
                }
            }
        })

        console.log(aliasList)
    }

    const checkvisentity = (props) => {
        console.log(props)
        const savedindex = clicked.findIndex(item => item.pointId === props.pointId);
        if (savedindex >= 0) {
            return (true);
        }
        return false;
    }

    const cloneSelectHandler = (props) => {
        cloneSelect(props);
    }

    return (
        <GoogleMap
            zoom={15}
            onLoad={(map) => { setMap(map) }}
            mapContainerStyle={{
                height: "75vh",
                width: "40vw",
                boxShadow: `0 0 15px #fff 0 0 15px #fff`,
            }}
            onIdle={() => {

                if (map.getCenter()) {
                    localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                }
            }}
        >
            <Marker
                zIndex={1000}
                position={{ lat: apLatitude, lng: apLongitude }}
                icon={{
                    url: `${getPublicURL()}/${mainEvent === "WIFI_CLONE_SCAN" ? "clone.png" : "sniff.png"}`,
                    scaledSize: new google.maps.Size(35, 35),
                }}
            ></Marker>
            <Polyline
                path={latlngTracks}
                geodesic={true}
                zIndex={3}
                options={{
                    strokeColor: "#03B83C",
                    strokeOpacity: 0.75,
                    strokeWeight: 3,
                }}
            />
            {
                apTrip &&
                apTrip.map((target, index) => {
                    if (target.latitude && target.longitude) {

                        let markerType;
                        if (index === 0) {
                            markerType = {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                fillColor: "#27B8FC",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                rotation: target.heading,
                                scale: 5,
                                anchor: new window.google.maps.Point(0, 0)
                            };
                        } else if (index === apTrip.length - 1) {
                            markerType = {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                fillColor: "#FC2771",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                rotation: target.heading,
                                scale: 5,
                                anchor: new window.google.maps.Point(0, 0)
                            };
                        } else if (target.speed < 1) {
                            markerType = {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                fillColor: "#71FC27",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                rotation: target.heading,
                                scale: 5,
                                anchor: new window.google.maps.Point(0, 0)
                            };
                        } else {
                            markerType = {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                fillColor: "#71FC27",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                rotation: target.heading,
                                scale: 4,
                                anchor: new window.google.maps.Point(0, 3)
                            };
                        }

                        return (
                            <Marker
                                position={{
                                    lat: target.latitude,
                                    lng: target.longitude,
                                }}
                                icon={markerType}

                                zIndex={4}
                            >

                            </Marker>
                        )
                    }
                })
            }
            {
                clones &&
                aliasList.map((entity, index) => {

                    let icon = {};
                    if (entity.aliases[0].entity.content.capture_type === "WIFI_CLONE_SCAN") {
                        icon = {
                            url: `${getPublicURL()}/greyclone.png`,
                            scaledSize: new google.maps.Size(35, 35),
                        };

                    } else {
                        icon = {
                            url: `${getPublicURL()}/icons8-wifi-64.png`,
                            scaledSize: new google.maps.Size(35, 35),
                        };
                    }
                    return (

                        <Marker
                            key={index}
                            position={{
                                lat: entity.latitude,
                                lng: entity.longitude,
                            }}
                            icon={icon}

                            zIndex={15}

                            onClick={() => { cloneSelectHandler(entity) }}
                        >
                            {
                                checkvisentity(entity)
                                    // true
                                    ?
                                    <InfoWindow
                                        onCloseClick={() => clonedeselection(entity)}
                                        position={{
                                            lat: entity.latitude,
                                            lng: entity.longitude,
                                        }}
                                        options={{ pixelOffset: new window.google.maps.Size(0, 0) }}
                                    >
                                        <div style={{ zIndex: 9999999 }}>
                                            {entity.aliases && entity.aliases.map((device, index) => {
                                                return (
                                                    <p
                                                        key={index}
                                                        onClick={() => { editbtn(device) }}
                                                        style={{
                                                            padding: "5px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img
                                                            src={`${getPublicURL()}/wifiMini.png`}
                                                            width={12}
                                                            height={12}
                                                        />
                                                        <u
                                                            style={{
                                                                color: "#0000FF"
                                                            }}
                                                        >
                                                            <Badge
                                                                count={device.hitcount}
                                                                size="small"
                                                                color="blue"
                                                                offset={[8, 5]}
                                                            >
                                                                {`  ${device.entity.content.entityAlias || ""} ${device.entity.alias}     `}
                                                            </Badge>
                                                        </u></p>
                                                )
                                            })}
                                        </div>
                                    </InfoWindow>
                                    :
                                    <div></div>
                            }
                        </Marker>
                    )
                })
            }
        </GoogleMap>
    )
})

export default WiFiDeviceMap;