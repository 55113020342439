import { Button, Divider, Popconfirm, Table, Tag, Tooltip } from "antd";
import { QuestionCircleTwoTone } from '@ant-design/icons';
import React, {useContext } from "react";
import Avatar from "react-avatar";
import TimeAgo from "timeago-react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import StatusDot from "../StatusDot";
import "./UserTable.scss";
import { formatDate } from "../../util/CommonUtils";
import { primaryButton, secondaryButton, contentBox } from '../../styles'
import PasswordResetModal from "../PasswordResetModal";
import permissionStore from "../../stores/permission.store";

const { Column } = Table;

export default function UserTable({
    data,
    onResendEmail,
    onDelete,
    onEditRecord,
    canEdit,
    canDelete,
    canResendEmail
}) {
    const _onResendEmail = record => {
        onResendEmail(record);
    };

    const _onEditRecord = record => {
        onEditRecord(record);
    };

    const _onDeleteRecord = record => {
        onDelete(record);
    };
    const permissionstore = useContext(permissionStore);
    const resetToken = !permissionstore.can('user.token');

    return (
        <Table
            dataSource={data}
            rowKey="id"
            size="middle"
            pagination={{ pageSize: 8 }}
            style={{ height: "80%" }}
            // scroll={{ x: 1750 }}
        >
            <Column
                title=""
                key="avatar"
                render={(text, record) => (
                    <Avatar round size="35" name={record.name}></Avatar>
                )}
            />
            <Column title="Full Name" dataIndex="name" key="name" />
            <Column
                title="Role"
                key="name"
                render={(text, record) => {
                    if (record.role_name) {
                        return (
                            <span style={{
                                display: 'flex',
                                alignItems: "center",
                            }}>
                                {record.role_name}
                                <Tooltip title={`${record.role_description}`} >
                                    <QuestionCircleTwoTone twoToneColor="#27ae60" style={{
                                        marginLeft: '5px',
                                        cursor: 'pointer'
                                    }}/>
                                </Tooltip>
                            </span>
                        )
                    } else {
                        return "N/A"
                    }


                }}/>
            <Column title="Alias" dataIndex="alias" key="alias" />
            <Column
                title="Status"
                key="status"
                render={(text, record) =>
                    record.status === "PENDING" ? (
                        <Tag color="orange">Pending</Tag>
                    ) : record.status === "DENIED" ? (
                        <Tag color="red">Denied</Tag>
                    ) : (
                        <Tag color="green">Approved</Tag>
                    )
                }
            />
            <Column
                title="Last Login"
                key="lastLogin"
                render={(text, record) =>
                    record.last_login_at ? (
                        <Tooltip
                            title={formatDate(record.last_login_at, 'hh:mm:ss A')}
                        >
                            <StatusDot
                                timestamp={new Date(Number(record.last_login_at) * 1000)}
                            ></StatusDot>
                            <TimeAgo
                                datetime={new Date(Number(record.last_login_at) * 1000)}
                            />
                        </Tooltip>
                    ) : (
                        <React.Fragment>
                            <StatusDot timestamp={record.last_login_at}></StatusDot>
                            Not logged in yet
                        </React.Fragment>
                    )
                }
            />
            <Column
                title=""
                key="action"
                render={(text, record) => (
                    <span>
                        <span>
                            <Button
                                disabled={canEdit}
                                type="link"
                                onClick={() => {
                                    _onEditRecord(record);
                                }}
                            >
                                Edit
                            </Button>
                        </span>
                        {record.status === "APPROVED" && record.last_login_at === null ? (
                            <span>
                                <Divider type="vertical" />
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => {
                                        _onResendEmail(record);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link" size="small" disabled={canResendEmail}>
                                        Resend Email
                                    </Button>
                                </Popconfirm>
                            </span>
                        ) : null}
                        <span>
                            <Divider type="vertical" />
                            <DeleteConfirmationModal
                                title={`Are you sure you want to delete '${record.name}'?`}
                                content={
                                    "Deletion of user will permanently remove all of his/her data from the system and will deny the user from authenticating with the ATRAX system."
                                }
                                onClickCancel={() => { }}
                                onClickOkay={() => {
                                    _onDeleteRecord(record);
                                }}
                            >
                                <Button id={"deleteUserBtn"} type="link" size="small" disabled={canDelete}>
                                    Delete
                                </Button>
                            </DeleteConfirmationModal>
                            {/* <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  _onDeleteRecord(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" size="small">
                  Delete
                </Button>
              </Popconfirm> */}
                        </span>

                        {resetToken && (
                            <span>
                                <Divider type="vertical" />
                                <PasswordResetModal
                                    title={`Password Reset for '${record.name}'?`}
                                    content={
                                        "Password reset token is presented here copy this link and send to user, if you need to manually reset password click the button"
                                    }
                                    onClickCancel={() => { }}
                                    onClickOkay={() => {
                                        // return onDeleteSuperUserRecord(record);
                                    }}
                                    userID={record.id}
                                    userDetails={record}
                                >

                                    <Button
                                        type="link"

                                    >
                                        Password reset
                                    </Button>
                                </PasswordResetModal>
                            </span>
                        )}

                    </span>
                )}
            />
        </Table>
    );
}
