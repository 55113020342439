import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, DatePicker, Input, Modal, Select } from "antd";
import moment from "moment";
import React from "react";
import {getTimeZones} from "../../util/CommonUtils";
import { secondaryButton, primaryButton } from '../../styles';

const { Option } = Select;

class ExportForm extends React.Component {
  state = {
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.props.form.resetFields();
        this.props.onSubmit(values);
      }
    });
  };

  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical" className="login-form">
        <Modal
          title={"Create Export"}
          visible={this.props.opened}
          onOk={this.onUserSubmit}
          confirmLoading={false}
          onCancel={() => {
            this.props.form.resetFields();
            this.props.onCancel();
          }}
          footer={[
            <Button key="back" onClick={this.onCancel} style={secondaryButton()}>
              Cancel
            </Button>,
            <Button
                id={"createexportbtn"}
              loading={this.state.loading}
              onClick={this.handleSubmit}
              key="submit"
              style={primaryButton()}
            >
              Create Export
            </Button>
          ]}
        >
          <Form.Item label="File Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please enter a file name."
                }
              ]
            })(<Input type="text" placeholder="Filename" />)}
          </Form.Item>
          <Form.Item label="Investigation">
            {getFieldDecorator("investigation_id", {
              rules: [
                { required: true, message: "Please select an investigation!" }
              ]
            })(
              <Select
                showSearch
                allowClear="true"
                style={{ width: "100%" }}
                placeholder="Investigation"
                onChange={event => {
                  return this.props.onInvestigationChanged(event);
                }}
              >
                {this.props.investigations.map(investigation => (
                  <Option id={investigation.id} key={investigation.id}>{investigation.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Target">
            {getFieldDecorator("target_id", {
              rules: [{ required: true, message: "Please select a target!" }]
            })(
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Targets"
              >
                {this.props.targets.map(target => (
                  <Option id={target.id} key={target.id}>{target.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Time Zone">
            {getFieldDecorator("timezone", {
              rules: [{ required: true, message: "Please select a timezone" }]
            })(
              <Select
                showSearch
                allowClear="true"
                style={{ width: "100%" }}
                placeholder="Timezone"
              >
                {getTimeZones().map(timezone => (
                  <Option key={timezone.value}>{`${timezone.label}`}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Time Period">
            {getFieldDecorator("timePeriod", {
              rules: [{ required: true, message: "Please enter a time period!" }]
            })(
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                showTime={{
                  defaultValue: [moment().startOf("day"), moment().endOf("day")]
                }}
                // format="YYYY-MM-DD HH:mm"
              />
            )}
          </Form.Item>
          <Form.Item label="device serial">
            {getFieldDecorator("serial", {
            })(<Checkbox>Include device serial</Checkbox>)}
          </Form.Item>

          <Form.Item label="Altitude">
            {getFieldDecorator("altitude", {

            })(<Checkbox>Include altitude</Checkbox>)}
          </Form.Item>
        </Modal>
      </Form>
    );
  }
}

export default Form.create({})(ExportForm);
