import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import WifiStore from "../../stores/wifi.store";
import AnalysisTripsStore from "../../stores/analysisTrips.store";
import {Badge, Button, Card, Col, Collapse, Divider, message, Popover, Progress, Row, Tooltip} from "antd";
import {
    EnvironmentFilled,
    HistoryOutlined,
    InfoCircleOutlined, UserOutlined,
    UserSwitchOutlined,
    WifiOutlined
} from "@ant-design/icons";
import getPublicURL from "../../utilities/public-url.utility";

function formatWifiEntityTitle(entity) {
    const tripsStore = useContext(AnalysisTripsStore)
    const EntityStore = useContext(EntityDisplayStore)
    const wifiStore = useContext(WifiStore)
    const onclick = (record, type) => {
        EntityStore.setDeviceAndOpen(record.id);
    };
    return (
        <div>
            <Card
                style={{width: '100%', marginBottom: '1%', boxShadow: '5px 5px 5px rgba(0,0,0,0.7)', borderRadius: '5px'}}
                bodyStyle={{paddingTop: "0", padding: '0', paddingLeft: '2%'}}
            >
                <Row>
                    <Col span={8} tyle={{display: 'flex', justifyContent: 'center', verticalAlign: 'center', transform: 'translateY(40%)' }}>
                        <div>
                            {tripsStore.isWifiCloned(entity.capture_type) ? (
                                <Popover title={"Clone Capture"} content={entity.capture_type}>
                                    <img
                                        src={`${getPublicURL()}/CloneCapture100px.png`}
                                        style={{width: '2vw'}}
                                    />
                                </Popover>
                            ) : (
                                <Popover title={"Sniff Capture"}
                                         content={entity.capture_type}>
                                    <img
                                        src={`${getPublicURL()}/wifiMini.png`}
                                        style={{width: '2vw'}}
                                    />
                                </Popover>
                            )}
                            <Badge
                                count={entity.hitCount.length}
                                // size="small"
                                color="blue"
                                offset={[2, -40]}
                            > </Badge>
                        </div>
                    </Col>
                    <Col span={13} style={{marginRight: '5%'}}>
                        <Row><p style={{color: 'grey'}}>Association Name: </p><p>{`${entity.Alias}`}</p></Row>
                        <Row><p style={{color: 'grey'}}>Mac Address: </p><p>{`${entity.MAC}`}</p></Row>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

function WifiActivityTableMapView(data) {
    const tripsStore = useContext(AnalysisTripsStore)
    const EntityStore = useContext(EntityDisplayStore)
    const entity = data.entity;
    const wifiStore = useContext(WifiStore)
    const map = data.map;

    const onclick = (record, type) => {
        EntityStore.setDeviceAndOpen(record.id);
    };
    return (
        // todo: this uses vw here
        <div style={{zIndex: 9999999, width: '100%', minWidth: '20vw', padding: '1%'}}>
            <h6>Entities Seen</h6>
            <Collapse
                style={{width: '100%', backgroundColor: '#f5f5f5', borderRadius: '5px', border: '#4096ff solid 1px'}}
                accordion={true}
                expandIcon={() => <UserSwitchOutlined style={{fontSize: '120%'}}/>}>
                {tripsStore.EntitiesExist(entity.key) ? tripsStore.CreateEntityNames(entity.key).map((entity, index) => {
                        return (
                           <h6>{entity}</h6>
                        )
                    })
                    :
                    <p>No Entities</p>
                }
            </Collapse>

            <Divider style={{marginBottom: '1%', marginTop: '1%'}}/>
            <Row>
                <h6 style={{marginRight: '1%'}}>Unassigned Captures</h6>
            </Row>
            {tripsStore.NotEntitiesExist ? tripsStore.getWifiInfo(entity.key).map((ap) => {
                return (
                    <div style={{marginBottom: '5%'}}>
                        <Tooltip title={"Access Point"} >
                            <h6 style={{textAlign: 'center'}}>{ap.Alias}</h6>
                        </Tooltip>
                        {ap.DeviceList.map((device, index) => {
                            return (
                                <div>
                                    <Card
                                        style={{width: '100%', marginBottom: '1%', boxShadow: '5px 5px 5px rgba(0,0,0,0.7)', borderRadius: '5px', paddingTop: '10px', paddingBottom: '10px'}}
                                        bodyStyle={{paddingTop: "0", padding: '0', paddingLeft: '5%'}}
                                    >
                                        <Row>
                                            <Col span={4}
                                                style={{marginRight: '10%'}}>
                                                <Row style={{width: '100%', justifyContent: 'center', marginTop: '50%',  marginBottom: "10%"}}>
                                                    {tripsStore.isWifiCloned(device.capture_type) ? (
                                                        <Popover title={"Clone Capture"} content={device.capture_type}>
                                                            <img
                                                                src={`${getPublicURL()}/CloneCapture100px.png`}
                                                                style={{width: '2vw'}}
                                                            />
                                                        </Popover>
                                                    ) : (
                                                        <Popover title={"Sniff Capture"}
                                                                 content={device.capture_type}>
                                                            <img
                                                                src={`${getPublicURL()}/wifiMini.png`}
                                                                style={{width: '2vw'}}
                                                            />
                                                        </Popover>
                                                    )}
                                                    <Badge
                                                        count={device.hitCount.length}
                                                        // size="small"
                                                        color="blue"
                                                        offset={[2, 0]}
                                                    > </Badge>
                                                </Row>
                                                <Row style={{width: '100%', justifyContent: 'center'}}>
                                                    <Tooltip title={`rssi: -${device.rssi} dbm`} placement={"left"}>
                                                        <Progress steps={4} percent={100 - device.rssi} showInfo={false} success={{percent: 25}}/>
                                                    </Tooltip>
                                                </Row>

                                            </Col>
                                            <Col span={16} style={{marginRight: '5%', marginTop: '5%'}}>
                                                <Row><p style={{color: 'grey'}}>Alias: </p><p>{`${device.Alias}`}</p>
                                                </Row>
                                                <Row><p style={{color: 'grey'}}>Mac Address: </p>
                                                    <p>{`${device.MAC}`}</p></Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )
                        })}

                    </div>
                )
            }) : <p>No Unassociated Captures</p>}
        </div>
    )
}

export default observer(WifiActivityTableMapView);