import BaseAPI from "./utilities/request.lib";
import {message} from "antd";

const HTTP = BaseAPI();

/**
 * get profile counter related to the device
 * @param deviceId
 * @returns {Promise<unknown>}
 */
const getProfileCounters = async (deviceId) => {
    try {
        const response = await HTTP.get(
            `/profile-counters/${deviceId}`
        );
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        message.error(`No profile counters available`)
        return Promise.reject(exception);
    }
}

export default {
    getProfileCounters,
};