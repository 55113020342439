import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button, message, Modal, Tabs, Typography, Row, Col, Divider } from "antd";
import { observer } from "mobx-react-lite";
import API from "../../services/api";
import '../../styles.css';
import ConfigTag from "../DeviceTable/configtag";

const { TabPane } = Tabs;

function PresetDeviceConfigDialogue({
    children: Component,
    device,
    deviceRecoveryPermission,
    record,
}) {
    const [isVisible, setIsVisible] = useState(false);
    const onChildClick = () => { setIsVisible(true); }

    const ComponentWithHandler = React.cloneElement(Component, {
        onClick: onChildClick
    });

    // Use API to apply predefined presets on the device-configuration/preset route
    const applyPreset = async (preset) => {
        message.loading({ content: 'Applying preset...', key: preset, duration: 0 });
        API.device.postDevicePreset(device.id, preset)
            .then(() => {
                message.success({ content: 'Preset applied successfully', key: preset });
            })
            .catch((err) => {
                message.error({ content: `Failed to apply preset. [${err.message}]`, key: preset });
            });
    }

    const descWidth = 15;
    const buttonWidth = 8;
    // console.log(device);

    const configCheck = (mode) => {
        if (device.configuration_mode === mode) {
            return {backgroundColor: 'Green', color: 'white' };
        } else {
            return {backgroundColor: 'White', color: 'black' };
        }
    }
    return (
        <React.Fragment>
            <Modal
                title={`Device Presets: ${device.name} - ${device.serial}`}
                visible={isVisible}
                width='1000px'
                onCancel={() => { setIsVisible(false); }}
                footer={null}
            >
                <div>
                    <ConfigTag record={record} />
                </div>
                <Tabs defaultActiveKey="1" tabPosition="left" style={{marginTop: '1%'}}>
                    <TabPane tab="Preset Configurations" key="1">
                        <Row gutter={[16, 0]} justify='space-around' align='middle' style={{ padding: '16px 4px' }}>
                        <Col span={descWidth}>
                                <Typography>Reset the device to factory logging settings</Typography>
                            </Col>
                            <Col span={buttonWidth} align='baseline'>
                                <Button
                                    className='roundButton'
                                    onClick={() => {
                                        device.configuration_mode = 4;
                                        applyPreset('default');
                                    }}
                                    block
                                >Default Settings</Button>
                            </Col>
                            <Divider />
                            <Col span={descWidth}>
                                <Typography>Live Mode logging and uploading every 10 seconds</Typography>
                            </Col>
                            <Col span={buttonWidth} align='baseline'>
                                <Button
                                    className='roundButton'
                                    onClick={() => {
                                        device.configuration_mode = 1;
                                        applyPreset('liveMode');
                                    }}
                                    block
                                >Enable Live Mode</Button>
                            </Col>
                            <Divider />
                            <Col span={descWidth}>
                                <Typography>Log a location every 15 seconds when moving. Connect and upload log every 10 minutes when moving and on STOP.</Typography>
                            </Col>
                            <Col span={buttonWidth}>
                                <Button
                                    className='roundButton'
                                    onClick={() => {
                                        device.configuration_mode = 3;
                                        applyPreset('monitorMode');
                                    }}
                                    block
                                >Enable Monitoring Mode</Button>
                            </Col>
                            <Divider />
                            <Col span={descWidth}>
                                <Typography>Log a location every 30 seconds when moving. Connect and upload log every 12 hours.</Typography>
                            </Col>
                            <Col span={buttonWidth}>
                                <Button
                                    className='roundButton'
                                    onClick={() => {
                                        device.configuration_mode = 2;
                                        applyPreset('loggingMode');
                                    }}
                                    block
                                >Enable Logging Mode</Button>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Modal>
            {((deviceRecoveryPermission === true) ? ComponentWithHandler : null)}
        </React.Fragment>
    );
}

PresetDeviceConfigDialogue.propTypes = {
    children: PropTypes.node,
    device: PropTypes.object,
    deviceRecoveryPermission: PropTypes.bool,
}

export default observer(PresetDeviceConfigDialogue);
