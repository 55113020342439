import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import ExportHistoryStore from "../../stores/exportHistory.store";
import UserStore from "../../stores/user.store";
import { contentBox, primaryButton } from "../../styles";
import { Row, Col, Select, Table, Divider, DatePicker, Button } from "antd";
import ReactJson from 'react-json-view'
import { DownloadOutlined } from "@ant-design/icons";
import { toJS } from "mobx";
import moment from "moment";
import InvestigationStore from "../../stores/investigation.store";

const { RangePicker } = DatePicker;

function ExportHistory(props) {
    const historyStore = useContext(ExportHistoryStore);
    const userStore = useContext(UserStore);
    const investigationStore = useContext(InvestigationStore);

    useEffect(async () => {
        await historyStore.UpdateExportHistory();
        await userStore.getUsers();
        await investigationStore.getInvestigations();
    }, []);


    const columns = [
        {
            title: "Export Name",
            dataIndex: "exportName",
            key: "exportName",
        },
        {
            title: "Export Type",
            dataIndex: "exportType",
            key: "exportType",
        },
        {
            title: "Invesitgation",
            dataIndex: "investigationName",
            key: "investigationName",
        },
        {
            title: "Target",
            dataIndex: "targetName",
            key: "targetName",
        },
        {
            title: "User",
            dataIndex: "userID",
            key: "userID",
            render: (text, record) => {
                return (
                    <div>
                        {userStore.getUserByID(record.userID).name}
                    </div>
                )
            }
        },
        {
            title: "timestamp",
            dataIndex: "timestamp",
            key: "timestamp",
            render: (text, record) => {
                return (
                    <div>
                        {moment.unix(record.timestamp).format('MM/DD/YYYY HH:mm:ss')}
                    </div>
                )
            }
        }


    ]

    return (
        <div>
            <div
                style={contentBox({marginTop: "1px"})}
            >
                Investigation:
                <Divider type="vertical" />
                <Select
                    showSearch
                    style={{ width: 400 }}
                    placeholder="Any Investigation"
                    allowClear
                    onChange={(value) => {
                        historyStore.updateInvestigation(value);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={investigationStore.investigations.map((investigation) => {
                        return {
                            label: investigation.name,
                            value: investigation.id,
                        }
                    })}
                >
                </Select>

                <Divider type="vertical" />
                User:
                <Divider type="vertical" />

                <Select
                    showSearch
                    style={{ width: 400 }}
                    placeholder="Any User"
                    allowClear
                    onChange={(value) => {
                        historyStore.updateUser(value);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={userStore.users.map((user) => {
                        return {
                            label: user.name,
                            value: user.id,
                        }
                    })}
                >
                </Select>

                <Divider type="vertical" />
                Time Range (UTC):
                <Divider type="vertical" />
                <RangePicker
                    timePicker={true}
                    showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                    }}
                    allowClear={true}
                    onChange={(value) => {
                        if (value == null) {
                            historyStore.updateTimeRange([null, null])
                            return;
                        }
                        const start = value[0].unix();
                        const end = value[1].unix();
                        historyStore.updateTimeRange([start, end])
                    }}
                    style={{ width: 400 }} />
            </div>
            <div>
                <Row>
                    <Col span={11}
                        style={contentBox()}
                    >
                        {historyStore.ResultSelectedEvent &&
                            <ReactJson src={toJS(historyStore.ResultSelectedEvent)} theme={"monokai"} />
                        }
                    </Col>
                    <Col span={1} />
                    <Col span={12}
                        style={contentBox()}
                    >
                        <Table
                            columns={columns}
                            dataSource={toJS(historyStore.displayHistory)}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { historyStore.selectEvent(record) }, // click row
                                    style: { background: historyStore.selectedEvent?.exportId == record.exportId ? "#00ddff" : "" }
                                };
                            }}
                            pagination={{
                                position: ['topRight', 'bottomRight'],
                                defaultPageSize: 10,
                                pageSizeOptions: [10, 20, 50, 100],
                            }}
                            footer={() => {
                                return (
                                    <div>
                                        <span style={{ marginRight: 10 }}>
                                            {`Total: ${historyStore.displayHistory.length}`}
                                        </span>
                                        <Button
                                            style={primaryButton()}
                                            onClick={() => {
                                                historyStore.downloadHistory();
                                            }}
                                            icon={<DownloadOutlined />}
                                        >
                                        </Button>
                                    </div>
                                )
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default observer(ExportHistory);