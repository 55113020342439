import BaseAPI from "./utilities/request.lib";
import moment from "moment";
import {message} from "antd";
import {Parser} from "json2csv";

var fileDownload = require("js-file-download");

const queryString = require("query-string");

const HTTP = BaseAPI();

/**
 * Get all analytics data
 * @param serial
 * @param from
 * @param to
 * @param offset
 * @param keyword
 * @returns {Promise<*[]>}
 */
const getAllDeviceTargetHistory = async (target, device_serial, from, to, offset, limit = null) => {
    try {
        let param = {
            target,
            device_serial,
            from: moment(from).startOf("day").unix(),
            to: moment(to).endOf("day").unix(),
            offset: offset
        }

        if (limit) {
            param.limit = 1;
        }

        const response = await HTTP.get(
            `/device-target-history/?${queryString.stringify(param)}`
        );
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

/**
 * Download analytics data to a csv file
 * @param data
 * @returns {Promise<void>}
 */
const downloadAnalyticsDump = async (data) => {
    const parser = new Parser();
    if (data.length > 0) {
        const csv = parser.parse(data);
        message.success(`Exported dump to CSV.`);
        fileDownload(csv, `export-${Date.now()}.csv`);
    } else {
        message.error(`No data Available`)
    }
}

const getALL = async () => {
    try {
        const response = await HTTP.get(`/device-target-history/all`);
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

export default {
    getAllDeviceTargetHistory,
    downloadAnalyticsDump,
    getALL,
};
