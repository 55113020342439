import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Table, Tabs } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import API from "../../services/api";
import DeviceStore from "../../stores/device.store";
import Layout from "../AppLayout";
import "./DeviceManagementPage.scss";
import {formatDate} from "../../util/CommonUtils";
import { secondaryButton, contentBox, primaryButton } from '../../styles';

const { TabPane } = Tabs;

const columns = [
  {
    title: "Alias",
    dataIndex: "alias",
    key: "alias",
  },
  {
    title: "Device Serial No",
    dataIndex: "DeviceSerial",
    key: "DeviceSerial",
    render: (text) => {
      return `${text || "N/A"}`;
    },
  },
  {
    title: "License Purchase Date",
    dataIndex: "purchased_date",
    key: "purchased_date",
    render: (text, record) => {
      return formatDate(text, 'hh:mm:ss A');
    },
  },
  {
    title: "License Expiry Date",
    dataIndex: "expiry_date",
    key: "expiry_date",
    render: (text, record) => {
      return formatDate(text, 'hh:mm:ss A');
    },
  },
];

function LicenseManagementPage() {
  const deviceStore = useContext(DeviceStore);
  const [licenses, setLicenses] = useState([]);
  const [activeLicenses, setActiveLicenses] = useState([]);
  const [expiredLicenses, setExpiredLicenses] = useState([]);

  const [licenseForExport, setLicenseForExport] = useState([]);

  useEffect(() => {
    let devicesWithALicense = [];

    const fetchData = async () => {
      await deviceStore
        .getAllDevices()
        .then((res) => {
          const devices = toJS(deviceStore.devices);

          if (devices && devices.length > 0) {
            devicesWithALicense = devices.filter((dev) => dev.ed);
          }
        })
        .catch((err) => {});

      let temp = [];
      API.license.getAllLicenses().then((licenses) => {
        if (licenses && licenses.length > 0) {
          setLicenses(licenses);

          let expiredLicenses = [];
          let activeLicenses = [];
          licenses.forEach(function (license) {
            if (license.device_id) {
              const relatedDevice = devicesWithALicense.filter(
                (device) => device.id === license.device_id
              );

              if (relatedDevice && relatedDevice.length > 0) {
                license.DeviceSerial = relatedDevice[0].serial;
              }
            }

            if (
              license &&
              new Date(license.expiry_date * 1000).getTime() >
                new Date().getTime()
            ) {
              temp.push(license);
              activeLicenses.push(license);
            } else {
              temp.push(license);
              expiredLicenses.push(license);
            }
          });
          setActiveLicenses(activeLicenses);
          setExpiredLicenses(expiredLicenses);

          setLicenseForExport(temp);
        }
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportData = async () => {
    let tempLicenseArray = [];
    if (licenseForExport && licenseForExport.length) {
      licenseForExport.forEach(function (license) {
        const licenseItem = { ...license };

        if (licenseItem.purchased_date) {
          licenseItem.purchased_date = formatDate(licenseItem.purchased_date, 'hh:mm:ss A');
        }

        if (licenseItem.expiry_date) {
          licenseItem.expiry_date = formatDate(licenseItem.expiry_date, 'hh:mm:ss A');
        }

        delete licenseItem.license_id;
        delete licenseItem.device_id;
        delete licenseItem.duration_period;

        tempLicenseArray.push(licenseItem);
      });
    }
    await API.license.exportLicense(tempLicenseArray);
  };

  return (
    <div className="DeviceManagementPage">
      <Layout
        showNavigation
        title="License Administration"
        description="Manage licenses in the ATRAX system"
      >
        <div
          style={contentBox()}
        >
          <div
            className="site-card-wrapper"
            style={{ background: "rgb(252, 252, 252)", padding: "15px" }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Card
                  title="Total License Count"
                  headStyle={{
                    border: "none",
                    textAlign: "center",
                    fontSize: "26px",
                    color: "rgba(0,0,0,.45)",
                  }}
                  bordered={true}
                  bodyStyle={{ textAlign: "center", fontSize: "45px" }}
                >
                  {licenses ? <CountUp end={licenses.length} /> : 0}
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  title="Active Licenses"
                  headStyle={{
                    border: "none",
                    textAlign: "center",
                    fontSize: "26px",
                    color: "rgba(0,0,0,.45)",
                  }}
                  bordered={true}
                  bodyStyle={{ textAlign: "center", fontSize: "45px" }}
                >
                  {activeLicenses ? <CountUp end={activeLicenses.length} /> : 0}
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  title="Expired Licenses"
                  headStyle={{
                    border: "none",
                    textAlign: "center",
                    fontSize: "26px",
                    color: "rgba(0,0,0,.45)",
                  }}
                  bordered={true}
                  bodyStyle={{ textAlign: "center", fontSize: "45px" }}
                >
                  {expiredLicenses ? (
                    <CountUp end={expiredLicenses.length} />
                  ) : (
                    0
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <div style={contentBox()}>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <Button
                onClick={() => {
                  exportData();
                }}
                // id={"export-analytics-btn"}
                disabled={licenseForExport.length === 0}
                style={primaryButton()}
              >
                <DownloadOutlined></DownloadOutlined>Export
              </Button>
            }
          >
            <TabPane tab="Active licenses" key="1">
              <Table
                dataSource={activeLicenses}
                columns={columns}
                style={{
                  maxHeight: "60vh",
                  overflow: "scroll",
                }}
              ></Table>
            </TabPane>
            <TabPane tab="Expired licenses" key="2">
              <Table
                pagination={{ pageSize: 5 }}
                dataSource={expiredLicenses}
                columns={columns}
                style={{
                  maxHeight: "60vh",
                  overflow: "scroll",
                }}
              ></Table>
            </TabPane>
          </Tabs>
        </div>
      </Layout>
    </div>
  );
}

export default observer(LicenseManagementPage);
