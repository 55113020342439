import { action, observable, makeAutoObservable, computed } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";
import { message } from "antd";

export class EntityNotificationStore {
    constructor() {
        this.settings = [];
        this.notificationPreferences = [];
        this.receivedAdminBulletin = [];
        this.receivedAdminBulletinList = [];
        this.allAdminBulletins = [];
        this.dashboardAdminBulletins = [];

        this.deviceType = null;

        this.SniffScan = [];
        this.CloneScan = [];

        this.tableStructure = [];
        this.deviceStructure = [];

        this.api = API;
        makeAutoObservable(this);
    }

    setDeviceType(type) {
        this.deviceType = type;
    }

    setSocketData(data) {
        this.receivedAdminBulletin = []
        this.receivedAdminBulletin.push(data)
        this.receivedAdminBulletinList.push(data);
    }

    async getNotificationSettings() {
        const response = await this.api.notification.getNormalizedFieldAndValues();
        this.settings = response;
        let existingValues = {};
        this.settings.forEach((setting) => {
            existingValues[setting.alertType.name] = [];
            setting.sms && existingValues[setting.alertType.name].push("sms");
            setting.email && existingValues[setting.alertType.name].push("email");
            setting.push_notification && existingValues[setting.alertType.name].push("push-notification");
        });
        this.notificationPreferences = existingValues;
    }

    async saveNotification(settings) {
        try {
            let response = [];
            this.settings.forEach((value) => {
                const sms = settings[value.alertType.name].includes("sms");
                const email = settings[value.alertType.name].includes("email");
                const pushNotifications = settings[value.alertType.name].includes(
                    "push-notification"
                );
                console.log(value)
                response.push({
                    id: value.alertType.id,
                    medium: {
                        sms: sms ? 1 : 0,
                        email: email ? 1 : 0,
                        push_notification: pushNotifications ? 1 : 0,
                    },
                });
            });
            await API.notification.updateNotificationSettings(response);
            await this.getNotificationSettings();
            message.info("Your notifications have been successfully updated.");
        } catch (e) {
            message.error(`${e.response.data.message}`);
        }
    }

    // ============ NEW NOTIFICATION ============

    get deviceStructureCOMP() {
        return this.deviceStructure
    }

    get tableStructureCOMP() {
        return this.tableStructure

    }
    updateTableStrcuture(children) {
        //this.tableStructure = null;
        this.tableStructeSetup();
    }

    tableStructeSetup() {
        this.tableStructure = [
            { alert_name: "Clone Scan", Users: this.CloneScan },
            { alert_name: "Sniff Scan", Users: this.SniffScan },

        ];
    }

    async getNotificationStreams(target_id) {
        this.tableStructure = [];
        // TODO: get notificiation streams for entity

        await this.api.notificationStreams.getEntityStreams(target_id).then((response) => {
            this.StartMovement = response.Startmovement || [];
            this.StopMovement = response.Stopmovement || [];
        });
    }

    async updatestream(target_id, investigation_id) {
        const streams = [
            { outType: "Clone Scan", userArray: this.CloneScan },
            { outType: "Sniff Scan", userArray: this.SniffScan },
        ];
        streams.forEach((stream) => {
            console.log(stream);
            const outArray = stream.userArray?.map((x) => x.userID);
            this.api.notificationStreams.updateEntityStreams({
                type: stream.outType,
                entity_id: target_id,
                investigation_id: investigation_id,
                user_ids: outArray
            }).then((response) => {
                console.log(response);
            });
        });
    }

    clearNotificationStreams() {
        this.CloneScan = [];
        this.SniffScan = [];
        this.updateTableStrcuture();
    }

}

export default createContext(new EntityNotificationStore());
