import { message } from "antd";
import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import LoginFormBlock from "../../components/LoginFormBlock";
import AuthStore from "../../stores/auth.store";
import getPublicURL from "../../utilities/public-url.utility";
import Layout from "../AppLayout";
import "./LoginPage.scss";


// this page is the same as the log in page
// but it has a message that pops up to
// say session expired

function LoginPageExpire(props) {
    const store = useContext(AuthStore);
    message.info("Session Expired");
    return (
        <Layout>
            <div className="LoginPage">
                <img
                    className="front-logo"
                    alt="logo"
                    src={`${getPublicURL()}/atrax_logo.png`}
                ></img>
                <div className="login-form">
                    <LoginFormBlock
                        showDefaultCredentials={
                            !!localStorage.getItem("defaultCredentials")
                        }
                        onSubmit={async (data) => {
                            const credentials = data.values;
                            const isValid = await store.authenticate(credentials);
                            if (isValid) {
                                window.location.replace("/");
                            }
                        }}
                    ></LoginFormBlock>
                </div>
            </div>
        </Layout>
    );
}

export default observer(LoginPageExpire);
