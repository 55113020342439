import { Collapse, Switch, Typography, Drawer, Button, Select, Divider, Table, Slider, DatePicker, message, Spin, Tree, Row, Col } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { DrawerProps } from 'antd/es/drawer';
import getPublicURL from "../../utilities/public-url.utility";
const prettyMS = require("pretty-ms");
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { contentBox, primaryButton, secondaryButton } from "../../styles";
const { Panel } = Collapse;
import { BorderOuterOutlined, ClearOutlined } from "@ant-design/icons";
import moment from "moment";

import { toJS } from "mobx";

import investigationStore from "../../stores/investigation.store";
import analysisFenceLookUpStore from "../../stores/AnalysisFence.store";
import TargetStore from "../../stores/target.store";
import { getGMTDateTime } from "../../util/CommonUtils";
import AnalysisSiteStore from "../../stores/analysisSuper.store";

const { RangePicker } = DatePicker;

function FencePanel({
    visible,
    children,
    onClose
}) {

    const InvestigationStore = useContext(investigationStore);
    const targetStore = useContext(TargetStore);
    const AnalysisFenceLookUpStore = useContext(analysisFenceLookUpStore);
    const investigationSiteStore = useContext(AnalysisSiteStore);

    useEffect(() => {
        InvestigationStore.getInvestigationsForUser();
        targetStore.getAllTargets();
    }, []);

    const columns = [
        {
            title: 'TargetName',
            dataIndex: 'targetName',
            key: 'targetName',
        },
        {
            title: 'In and Out',
            dataIndex: 'in',
            key: 'in',
            render: (text, record) => {
                return (
                    <div>
                        {getGMTDateTime(record.in)}
                        {/* {moment.unix(record.in).format("DD/MM/YYYY HH:mm:ss")} */}
                        <Divider type="vertical" />
                        {/* {moment.unix(record.out).format("DD/MM/YYYY HH:mm:ss")} */}
                        {getGMTDateTime(record.out)}
                    </div>
                )
            }
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                return (
                    <div>
                        {prettyMS((record.duration) * 1000)}
                    </div>
                )
            }
        },
    ];

    const _onClose = () => {
        onClose();
    };

    if (!visible) {
        return null
    }

    const highlightAvailableDates = (current) => {
        const style = {};
        if (current.unix() > AnalysisFenceLookUpStore.startTime && current.unix() < AnalysisFenceLookUpStore.endTime) {
            style.border = '1px solid black';
            style.backgroundColor = "#80808075";
            style.color = "black";
        }
        return (
            <div className="ant-picker-cell ant-picker-cell-in-view" style={style}>
                {current.date()}
            </div>
        );
    }

    return (
        <Drawer
            title={
                <div>
                    <h4>Area Analysis
                    <Switch
                        style={{ marginLeft: "10px"}}
                        checkedChildren="Local Time"
                        unCheckedChildren="UTC Time"
                        defaultChecked={localStorage.getItem("utcTime") === "false"}
                        onChange={(e) => {
                            if (e) {
                                localStorage.setItem("utcTime", "false")
                            } else {
                                localStorage.setItem("utcTime", "true")
                            }
                            onUTCChange();
                        }}
                    />
                    </h4>
                </div>
            }
            placement="left"
            onClose={_onClose}
            mask={false}
            visible={visible}
            style={{
                marginBottom: "2px",
                width: "35%",
                marginLeft: "4rem"
            }}
            bodyStyle={{ background: '#f8f4f4' }}
            headerStyle={{ backgroundColor: '#f8f4f4' }}
            defaultActiveKey={0}
            closeIcon={
                <ArrowLeftOutlined />
            }
        >
            <div
                style={contentBox()}
            >
                <Row>
                    <Col span={18}>
                        <div>
                            <Select
                                showSearch
                                style={{ width: '100%', marginBottom: '1vh' }}
                                placeholder="Any Investigation"
                                allowClear
                                onChange={(value) => {
                                    AnalysisFenceLookUpStore.updateInvestigation(value);
                                    targetStore.getTargetsForInvestigation(value);
                                    investigationSiteStore.setInvestigationId(value);
                                }}
                                filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={InvestigationStore.investigationsForUser.map((investigation) => {
                                    return {
                                        label: investigation.name,
                                        value: investigation.id,
                                    }
                                })}
                            ></Select>
                            <br />
                            <Button
                                type="primary"
                                style={primaryButton({ width: "100%" })}
                                onClick={() => {
                                    // historyStore.updateInvestigation(null);
                                    AnalysisFenceLookUpStore.UpdateExportHistory();
                                }}
                            >
                                Load Investigation
                            </Button>
                            <div
                            >
                                <Divider />
                                <Row>
                                    <RangePicker
                                        timePicker={true}
                                        showTime={{
                                            hideDisabledOptions: true,
                                            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                                        }}
                                        disabled={AnalysisFenceLookUpStore.fenceBounds === null}
                                        allowClear={true}
                                        onChange={(value) => {
                                            if (value == null) {
                                                AnalysisFenceLookUpStore.updateTimeRange([null, null])
                                                return;
                                            }
                                            const start = value[0].unix();
                                            const end = value[1].unix();
                                            AnalysisFenceLookUpStore.updateTimeRange([start, end])
                                        }}
                                        dateRender={highlightAvailableDates}
                                        style={{ width: "100%" }}
                                    />


                                    <Select
                                        disabled={AnalysisFenceLookUpStore.fenceBounds === null}
                                        showSearch
                                        style={{ marginTop: '1vh', width: "100%", float: "right", marginRight: "1px" }}
                                        placeholder="Any Target"
                                        allowClear
                                        filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())}
                                        onChange={(value) => {
                                            AnalysisFenceLookUpStore.setTarget(value);
                                        }}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={targetStore.targetsForInvestigation.map((target) => {
                                            return {
                                                label: target.name,
                                                value: target.name
                                            }
                                        })}
                                    ></Select>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <Divider type="vertical" style={{ marginLeft: '3vh', height: "100%" }} />
                    </Col>
                    <Col span={2}>
                        <div
                        >
                            <Row>
                                <Button
                                    disabled={AnalysisFenceLookUpStore.exportHistory.length === 0}
                                    style={secondaryButton({ width: "8vh", height: "5vh", backgroundColor: "#f8f4f4", marginTop: "2vh" })}
                                    onClick={() => {
                                        AnalysisFenceLookUpStore.setAction('Draw');
                                    }}
                                    icon={
                                        <BorderOuterOutlined
                                            style={{ fontSize: '3vh' }}
                                        />
                                    }
                                />
                            </Row>
                            <Row>
                                <Button
                                    disabled={AnalysisFenceLookUpStore.exportHistory.length === 0}
                                    style={secondaryButton({ width: "8vh", height: "5vh", backgroundColor: "#f8f4f4", marginTop: "7vh" })}
                                    onClick={() => {
                                        AnalysisFenceLookUpStore.setAction('Clear');
                                    }}
                                    icon={
                                        <ClearOutlined
                                            style={{ fontSize: '3vh' }}
                                        />
                                    }
                                />
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <div
                style={contentBox()}
            >

                <Spin spinning={AnalysisFenceLookUpStore.loading}> </Spin>
                <Table
                    dataSource={toJS(AnalysisFenceLookUpStore.DisplayInNOut)}
                    columns={columns}
                    pagination={50}
                    onRow={(record) => {
                        if (true) {
                            return {
                                onClick: () => AnalysisFenceLookUpStore.tableselect(record),

                            };
                        }
                    }}
                    footer={() => {
                        return (
                            <div>
                                <span style={{ marginRight: 10 }}>
                                    {`Total: ${AnalysisFenceLookUpStore.DisplayInNOut.length}`}
                                </span>
                                <Button
                                    style={primaryButton()}
                                    onClick={() => {
                                        AnalysisFenceLookUpStore.downloadHistory();
                                    }}
                                    icon={<DownloadOutlined />}
                                >
                                </Button>
                                <Button
                                    onClick={() => {
                                        AnalysisFenceLookUpStore.clearTablePoints();
                                    }}
                                    style={secondaryButton({ float: "right" })}
                                >
                                    Clear
                                </Button>
                            </div>
                        )
                    }}
                />
            </div>
        </Drawer>
    );
}

export default observer(FencePanel);
