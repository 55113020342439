import { UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import API from '../../services/api';
import { primaryButton, secondaryButton } from '../../styles';

const { Option } = Select;

class EntityAddForm extends React.Component {
    state = {
        loading: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                this.props.onSubmit({ values, mode: this.props.mode });
            }
        });
    };

    onCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, mode } = this.props;
        let extraData = {};
        const colors = [
            { name: 'Red', value: '#C0392B' },
            { name: 'Blue', value: '#0000FF' },
            { name: 'Purple', value: '#6a0dad' },
            { name: 'Green', value: '#008000' },
            { name: 'Orange', value: '#FFA500' }
        ]
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Modal
                    title={mode === "add" ? "Create Entity" : "Edit Entity"}
                    visible={this.props.opened}
                    onOk={this.onUserSubmit}
                    confirmLoading={false}
                    onCancel={() => {
                        this.props.form.resetFields();
                        this.props.onCancel();
                    }}
                    footer={[
                        <Button
                            style={secondaryButton({ width: "47%", right: "3%", borderRadius: "5px", })}
                            key="back"
                            onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <Button
                            style={primaryButton({ width: "47%", right: "2%", borderRadius: "5px", })}
                            loading={this.state.loading}
                            onClick={this.handleSubmit}
                            key="submit"
                        >
                            {mode === "add" ? "Create Entity" : "Save Changes"}
                        </Button>,
                    ]}

                    okButtonProps={{ style: primaryButton() }}
                    cancelButtonProps={{ style: secondaryButton() }}
                >
                    <Form.Item>
                        {getFieldDecorator("InvestigationID", {
                            // onChange: props.onDateRangeSelect,
                            initialValue: this.props.selectedInvestigation || [],

                        })(
                            <Select
                                disabled={this.props.selectedInvestigation}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Investigation"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={this.props.investigations.map((ainvest) => {
                                    return {
                                        label: ainvest.name,
                                        value: ainvest.id,
                                    }})
                                }

                            >
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Entity Name">
                        {getFieldDecorator("nickname", {
                            rules: [{ required: true, message: "Please input a name!" }],
                            initialValue: mode === "edit" && data ? data.alias : "",
                        })(
                            <Input
                                prefix={
                                    <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                type="text"
                                placeholder="Entity Alias"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Notes">
                        {getFieldDecorator("notes", {
                            rules: [{ required: true, message: "Enter Notes" }],
                            initialValue: mode === "edit" && data ? data.notes : "",
                        })(
                            <Input
                                prefix={
                                    <UnorderedListOutlined style={{ color: "rgba(0,0,0,.25)" }}/>
                                }
                                type="textarea"
                                height={200}
                                placeholder="notes"
                            />
                        )}
                    </Form.Item>
                </Modal>
            </Form>
        );
    }
}

export default Form.create({})(EntityAddForm);
