import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import AuthStore from "../../stores/auth.store";
import getPublicURL from "../../utilities/public-url.utility";
import "./ForgotPassword.scss";
import DemoUserForm from "../../components/DemoUserSignUpForm";


function DemoSignUp(props) {
    const store = useContext(AuthStore);
    return (
        <div className="ForgotPassword">
            <img className="front-logo" alt="logo" src={`${getPublicURL()}/atrax_logo.png`}></img>
            <div className="forgot-password-text">
                <strong>Would you like a Trail Account?</strong>
                <div>
                    <p>
        Please fill out the following details
                        <br></br>
            We'll process your request and get back to you with account details
                    </p>
                </div>
            </div>
            <DemoUserForm
                onBack={() => {
                    props.history.push("/login");
                }}
                onSubmit={async (values) => {
                    await store.DemoRequest(values);
                    props.history.push("/login");
                }}
            ></DemoUserForm>
        </div>
    );
}

export default observer(DemoSignUp);
