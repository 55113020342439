import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Table, DatePicker, Row, Select, Col, Spin, Input } from "antd";
import {toJS} from "mobx";
import React, {useState, useContext, useEffect} from "react";
import ReactJson from 'react-json-view'

import UserStore from "../../stores/user.store";
import API from "../../services/api";
import {formatDate} from "../../util/CommonUtils";
import { contentBox, primaryButton, secondaryButton } from '../../styles';


function UserAuditTab(props) {

    const userStore = useContext(UserStore);

    const [showDropDown, setShowDropDown] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [module, setModule] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [keyword, setKeyword] = useState("");
    const [user, setUser] = useState("");

    const [wifiConfig, setWifiConfig] = useState([]);
    const [gpsConfig, setGpsConfig] = useState([]);
    const [obdConfig, setObdConfig] = useState([]);

    // const [xmlConfigIds, setXmlConfigIds] = useState([]);
    // const [configurationsFromXML, setConfigurationsFromXML] = useState([]);

    const MODULES = [
        {   name: 'INVESTIGATION'  },
        {   name: 'TARGET'  },
        {   name: 'DEVICE'  },
        {   name: 'USER'  },
        {   name: 'TEMPLATE'  },
        {   name: 'GEO_FENCE'  },
        {   name: 'ALERT'  },
    ];

    useEffect(() => {
        API.device.getDeviceConfigurationSchema("dm", "wifi").then((response) => {
            if (response) {
                setWifiConfig(response);
            }
        });

        API.device.getDeviceConfigurationSchema("dm", "gps").then((response) => {
            if (response) {
                setGpsConfig(response);
            }
        });

        API.device.getDeviceConfigurationSchema("dm", "obd").then((response) => {
            if (response) {
                setObdConfig(response);
            }
        });
    }, [])

    useEffect(() => {
        async function getUsers() {
            await userStore.getUsers().then(res => {
                setShowDropDown(true)
            });
        }

        getUsers();
        // eslint-disable-next-line
    }, [])

    const exportData = async () => {
        const updatedData = props.userAudit.map(x => {
            x.created_at = ` ${formatDate(x.created_at, 'HH:mm:ss')}`
            return x
        })
        await API.userAudit.downloadAnalyticsDump(updatedData);
    }

    const exportAllData = async () => {

        const fromConverted = new Date(fromDate);
        fromConverted.setHours(0, 0, 0);

        const toConverted = new Date(toDate);
        toConverted.setHours(23, 59, 59);

        const response = await API.userAudit.getUserAudit(
            module,
            fromConverted, // Convert seconds to milliseconds
            toConverted, // Convert seconds to milliseconds
            0,
            keyword,
            user,
            1
        );

        if (response && response.userAudit.length > 0) {
            const updatedData = response.userAudit.map(x => {
                x.created_at = ` ${formatDate(x.created_at, 'HH:mm:ss')}`
                return x
            })
            await API.userAudit.downloadAnalyticsDump(updatedData);
        }

        // await API.userAudit.downloadAnalyticsDump(props.userAudit);
    }

    const handleSubmit = (e) => {
        props.form.validateFields((error, values) => {
            if (!error) {
                const [from, to] = values.timeRange;
                props.onFilterClicked(values.module, from, to, 0, values.keyword, values.name);
                // props.onFilterClicked("175583", "1584433470860", "1584433471091");
                setModule(values.module);
                setFromDate(from);
                setToDate(to);
                setKeyword(values.keyword);
                setUser(values.name);


            } else {
                // alert(JSON.stringify(error, null, 2));
            }
        });
    };

    const handlePageChange = (pagination) => {
        setCurrentPage(pagination.current);
        const offset = (pagination.current - 1) * 50;

        props.onFilterClicked(module, fromDate, toDate, offset, keyword);
    };

    /*const exportDataForCurrentPage = async () => {
        await API.analytics.downloadAnalyticsDump(sortedDataSource);
    }*/

    // const dataSource = []
    const columns = [
        {
            title: 'User',
            dataIndex: 'created_by',
            key: 'created_by',
            width: '40'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '90'
        },
        {
            title: 'Payload',
            dataIndex: 'payload',
            key: 'payload',
            width: '50',
            render: (text, record) => {
                const json = JSON.parse(text);
                if (module === "INVESTIGATION") {
                    delete json.id;
                    delete json.created_at;
                    json.start_datetime = formatDate(json.start_datetime, 'HH:mm:ss');
                    json.end_datetime = formatDate(json.end_datetime, 'HH:mm:ss');
                }

                if (module === "TARGET") {
                    delete json.id;
                    delete json.created_by;
                    delete json.created_at;

                    delete json.updated_by;
                    delete json.updated_at;

                    json.assign_at = formatDate(json.assign_at, 'HH:mm:ss')
                    // json.investigation_id ? json.investigation_name = json.investigation_id && delete json.investigation_id: json.investigation_name = "No Investigation";
                    // json.device_id ? json.device_serial = json.device_id && delete json.device_id: json.device_serial = "No Device Assgigned";
                }

                if (module === "DEVICE") {
                    delete json.id;
                    // delete json.created_by;
                    // delete json.created_at;

                    delete json.device_type_id;

                    if (record.action === "LICENSE_ALLOCATION") {
                        json.purchased_date = formatDate(json.purchased_date, '');
                        json.expiry_date = formatDate(json.expiry_date, '');
                        delete json.license_id;
                    }

                    if (record.action === "CREATE") {
                        delete json.created_at;
                        delete json.created_by;
                        delete json.id;
                        delete json.device_type_id;
                    }

                    if (record.action === "DELETE") {
                        json.created_at = formatDate(json.created_at, '');
                    }

                    if (record.action === "DEVICE_CONFIGURATION") {

                        if (json.device_type === "wifi") {

                            const config = wifiConfig.categories.filter(x => Number(x.id) === json.config_id);

                            let settingObj = {};

                            if (config.length > 0) {
                                config[0].fields.map((setting) => {
                                    console.log(setting);
                                    if(json.settings && json.settings.hasOwnProperty(setting.name))
                                    {
                                        settingObj[setting.displayName] = json.settings[setting.name];
                                    }
                                    return null;
                                });

                            }

                            json.settings = settingObj;
                        }

                        if (json.device_type === "gps") {

                            const config = gpsConfig.categories.filter(x => Number(x.id) === json.config_id);

                            let settingObj = {};

                            if (config.length > 0) {
                                config[0].fields.map((setting) => {
                                    console.log(setting);
                                    console.log(json);
                                    if(json.settings && json.settings.hasOwnProperty(setting.name))
                                    {
                                        settingObj[setting.displayName] = json.settings[setting.name];
                                    }
                                    return null;
                                });
                            }


                            json.settings = settingObj;
                        }

                        if (json.settings && json.device_type === "obd") {

                            const config = obdConfig.categories.filter(x => Number(x.id) === json.config_id);

                            let settingObj = {};

                            if (config.length > 0) {
                                config[0].fields.map((setting) => {
                                    if(json.settings.hasOwnProperty(setting.name))
                                    {
                                        settingObj[setting.displayName] = json.settings[setting.name];
                                    }
                                    return null;
                                });
                            }


                            json.settings = settingObj;
                        }

                        delete json.config_id;
                        delete json.device_id;
                        delete json.created_at;
                        delete json.created_by;
                    }

                    // json.assign_at = new Date(json.assign_at * 1000).toLocaleDateString();
                    // json.investigation_id ? json.investigation_name = json.investigation_id && delete json.investigation_id: json.investigation_name = "No Investigation";
                    // json.device_id ? json.device_serial = json.device_id && delete json.device_id: json.device_serial = "No Device Assgigned";
                }

                return <ReactJson src={json} theme={"monokai"}/>
            },
        },
        {
            title: 'Date/Time',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '70',
            render: (text, record) => {
                return formatDate(text, 'HH:mm:ss');
            },
        }
    ];

    const {getFieldDecorator} = props.form;
    return (
        <React.Fragment>
            <div style={contentBox()}>
                <Form onSubmit={handleSubmit}>

                    <Row>
                        <Col span={8}>
                            <label><span style={{color: "red"}}>*</span> Module</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("module", {
                                    // onChange: props.onDateRangeSelect,
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select a module!",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.indexOf(input) >= 0}
                                        // disabled={showDropDown !== true}
                                    >
                                        { MODULES.map((module) => {
                                            return (<Select.Option key={module.name}
                                                value={module.name}>{module.name}</Select.Option>)
                                        })}

                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <label><span style={{color: "red"}}>*</span> Date Range
                            </label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("timeRange", {
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select a time-range!",
                                        },
                                    ],
                                })(<DatePicker.RangePicker style={{width: "100%"}}/>)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <label> User</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("name", {
                                    // onChange: props.onDateRangeSelect,
                                    initialValue: null,
                                    rules: [
                                        {
                                            required: false,
                                            message: "Please select a user!",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.indexOf(input) >= 0}
                                        disabled={showDropDown !== true}
                                    >
                                        {showDropDown === true && toJS(userStore.users.map((user) => {
                                            return (<Select.Option key={user.username}
                                                value={user.name}>{user.name}</Select.Option>)
                                        }))}

                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <label> Keyword</label>
                        </Col>
                        <Col span={16}>
                            <Form.Item>
                                {getFieldDecorator("keyword", {
                                    initialValue: "",
                                })(
                                    <Input
                                        type="text"
                                        placeholder="Search Keyword"
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{
                        textAlign: 'end'
                    }}>
                        <Button
                            onClick={() => {
                                handleSubmit();
                            }}
                            style={primaryButton({marginLeft: "auto"})}
                            key="filrer"
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                exportAllData();
                            }}
                            disabled={props.userAudit && props.userAudit.length === 0}
                            style={secondaryButton({ marginLeft: "3px" })}
                        >
                            Export day
                        </Button>

                        <Button
                            onClick={() => {
                                exportData()
                            }}
                            disabled={props.userAudit && props.userAudit.length === 0}
                            style={secondaryButton({marginLeft: "3px"})}>
                            Export current page
                        </Button>

                    </Row>
                    <hr/>
                </Form>
                <br/>
            </div>

            { props.spinnerVisible === true ? (
                <div style={{
                    textAlign: 'center',
                    alignItems: 'center',
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "5%"
                }}>
                    <Spin size={"large"}/>
                </div>
            ) : (
                <Table
                    style={contentBox()}
                    dataSource={props.userAudit}
                    columns={columns}
                    pagination={{
                        position: "both",
                        pageSize: 50,
                        current: currentPage,
                        total: props.userAuditCountAll,
                    }}
                    onChange={handlePageChange}
                    scroll={{ x: 1271 }}
                />
            )}

        </React.Fragment>
    );
}

export default Form.create({})(UserAuditTab);
