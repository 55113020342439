import {observer} from "mobx-react-lite";
import {Modal} from "antd/es";
import WifiStore from "../../stores/wifi.store";
import React, {useContext, useEffect, useState} from "react";
import {Button, Col, message, Row, Spin, Table} from "antd";
import AnalysisSiteStore from "../../stores/analysisSuper.store";
import {GoogleMap, InfoWindow, Marker, Polyline} from "@react-google-maps/api";
import {getGMTDateTime} from "../../util/CommonUtils";
import getPublicURL from "../../utilities/public-url.utility";

function WifiDeviceHistory() {
    const store = useContext(WifiStore);
    const siteStore = useContext(AnalysisSiteStore);
    store.setInvestigationId(siteStore.getInvestigationId());

    const [map, setMap] = useState(null);
    let location = null;
    let bounds
    if (map) {
        bounds = new window.google.maps.LatLngBounds();
    }


    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    useEffect(() => {
        if (!bounds) return
        store.latlngTracks.forEach(point => {
            bounds.extend({
                lat: point.lat,
                lng: point.lng
            })
        });
        if (map) map.fitBounds(bounds, window.innerWidth * 0.05);
    },[
        store.latlngTracks,
    ]);


    // const onclick = (record, type) => {
    //     EntityStore.setDeviceAndOpen(record.id);
    // };

    const success = (pos) => {
        const crd = pos.coords;

        map.panTo({
            lat: crd.latitude,
            lng: crd.longitude,
        });
    }

    const fail = (error) => {
        if (error.code === 1) {
            message.error("Failed to load map position. We do not have location privileges.");
        }
        console.log(`Error: ${error.code}`);
    }

    useEffect(() => {
        if (map) {
            navigator.geolocation.getCurrentPosition(success, fail, options);
        }
    }, [map]);

    useEffect(() => {
        store.loadWifiData();
    }, []);

    useEffect(() => {
        store.loadWifiData();
    }, [store.deviceId]);

    const columns = [
        {
            title: 'Band',
            dataIndex: 'channel',
            key: 'channel',
            render: (text) => (text > 14 ? '5GHz' : '2.4GHz')
        },
        {
            title: 'Rssi',
            dataIndex: 'rssi',
            key: 'rssi'
        },
        {
            title: 'TimeStamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text) => new Date(text * 1000).toLocaleString()
        },
        {
            title: 'Capture Type',
            dataIndex: 'capture_type',
            key: 'capture_type',
            render: text => {
                return text.split('WIFI_FRAME_FUNCTION_')[1]
            }
        },
        {
            title: 'Target Name',
            dataIndex: 'name',
            key: 'name'
        }
    ];

    return (
        <div
        >

            <Modal
                title="WiFi Assoication Prevous Trips"
                visible={store.isOpened}
                onCancel={() => {
                    store.close();
                    store.deviceId = -1;
                    store.WifiHistory = [];
                    store.MapDisplayPoints = [];
                    store.wifiActivity = [];
                }}
                width={"auto"}
                footer={null}
                style={{minHeight: "70vh", height: "100%"}}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Spin spinning={store.isLoading}> </Spin>
                    <Row>
                        <Col span={11}
                            style={{marginRight: "1%"}}
                        >
                            <div
                                style={{boxShadow: '5px 5px 5px rgba(0,0,0,0.7)', borderRadius: "10px", padding: '2% ', minHeight: "55vh", height: '100%'}}
                            >
                                <GoogleMap
                                    defaultCenter={{
                                        lat: -27.4705,
                                        lng: 153.0260,
                                    }}
                                    yesIWantToUseGoogleMapApiInternals
                                    mapContainerStyle={{
                                        width: "100%",
                                        minHeight: "50vh",
                                        height: "100%",
                                        borderRadius: "10px"
                                    }}
                                    onIdle={() => {

                                        if (map.getCenter()) {
                                            localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                                        }
                                    }}
                                    zoom={15}
                                    onLoad={map => setMap(map)}
                                >

                                    <Polyline
                                        path={store.latlngTracks}
                                        geodesic={true}
                                        zIndex={3}
                                        options={{
                                            strokeColor: "#03B83C",
                                            strokeOpacity: 0.75,
                                            strokeWeight: 3,
                                        }}
                                    />
                                    {/* clone capture markers layer*/}
                                    {store.WifiDevicesForMap.map((entity, trueIndex) => {
                                        let icon = {};
                                        if (entity.end === true) {
                                            icon = {
                                                url: `${getPublicURL()}/End-of-trip.png`,
                                                scaledSize: new google.maps.Size(35, 35),
                                            }
                                        }
                                        else if (store.isWifiCloned(entity.capture_type)) {
                                            icon = {
                                                url: `${getPublicURL()}/clone.png`,
                                                scaledSize: new google.maps.Size(35, 35),
                                            };

                                        } else {
                                            icon = {
                                                url: `${getPublicURL()}/sniff.png`,
                                                scaledSize: new google.maps.Size(35, 35),
                                            };
                                        }
                                        return (
                                            <Marker
                                                position={{
                                                    lat: entity.latitude,
                                                    lng: entity.longitude,
                                                }}
                                                icon={icon}
                                                zIndex={25}
                                                onClick={() => store.addWiFiInfoVis(entity.key)}
                                                onMouseOver={() => store.setHoverWifiInfoVis(entity.key)}
                                                onMouseOut={() => store.setHoverExitWifiInfoVis()}
                                            >
                                                {
                                                    store.isWiFiInfoVis(entity.key)
                                                        ?
                                                        <InfoWindow
                                                            position={{
                                                                lat: entity.latitude,
                                                                lng: entity.longitude,
                                                            }}
                                                            width={700}
                                                            onCloseClick={() => store.removeWiFiInfoVis(entity.key)}
                                                        >
                                                            {/*<WifiActivityTable entity={entity} map={map}/>*/}
                                                            <p>hello</p>
                                                        </InfoWindow>
                                                        :
                                                        <div></div>
                                                }
                                            </Marker>
                                        )
                                    })}
                                    {/*Standard Markers layer*/}
                                    {store.MapDisplayPoints.map((target, index) => {
                                        target.id = index;
                                        if (target.latitude && target.longitude) {

                                            let markerType;
                                            if (index === 0) {
                                                markerType = {
                                                    path: window.google.maps.SymbolPath.CIRCLE,
                                                    fillColor: "#27B8FC",
                                                    fillOpacity: 1,
                                                    strokeWeight: 1,
                                                    strokeColor: "#01290D",
                                                    rotation: target.heading,
                                                    scale: 5,
                                                    anchor: new window.google.maps.Point(0, 0)
                                                };
                                            } else if (index === store.MapDisplayPoints.length - 1) {
                                                markerType = {
                                                    path: window.google.maps.SymbolPath.CIRCLE,
                                                    fillColor: "#FC2771",
                                                    fillOpacity: 1,
                                                    strokeWeight: 1,
                                                    strokeColor: "#01290D",
                                                    rotation: target.heading,
                                                    scale: 5,
                                                    anchor: new window.google.maps.Point(0, 0)
                                                };
                                            } else if (target.speed < 1) {
                                                markerType = {
                                                    path: window.google.maps.SymbolPath.CIRCLE,
                                                    fillColor: siteStore.getColourByTargetId(target.targetId),
                                                    fillOpacity: 1,
                                                    strokeWeight: 1,
                                                    strokeColor: "#01290D",
                                                    rotation: target.heading,
                                                    scale: 5,
                                                    anchor: new window.google.maps.Point(0, 0)
                                                };
                                            } else {
                                                markerType = {
                                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                    fillColor: siteStore.getColourByTargetId(target.targetId),
                                                    fillOpacity: 1,
                                                    strokeWeight: 1,
                                                    strokeColor: "#01290D",
                                                    rotation: target.heading,
                                                    scale: 4,
                                                    anchor: new window.google.maps.Point(0, 3)
                                                };
                                            }

                                            return (
                                                <Marker
                                                    position={{
                                                        lat: target.latitude,
                                                        lng: target.longitude,
                                                    }}
                                                    icon={markerType}
                                                    zIndex={4}
                                                    onClick={() => store.addToMarkedPoints(index)}
                                                >
                                                    <div
                                                    >
                                                        {
                                                            store.makerVisible(index)
                                                                ?
                                                                <InfoWindow
                                                                    onCloseClick={() => store.removeFromMarkedPoints(index)}
                                                                    position={{
                                                                        lat: target.latitude,
                                                                        lng: target.longitude,
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <p>Marker Latitude: {target.latitude}</p>
                                                                        <p>Marker Longitude: {target.longitude}</p>
                                                                        <p>Time: {getGMTDateTime(target.device_utc_date_time)}</p>
                                                                    </div>
                                                                </InfoWindow>
                                                                :
                                                                <div></div>
                                                        }
                                                    </div>

                                                </Marker>
                                            )
                                        }
                                    })}

                                </GoogleMap>
                            </div>
                        </Col>
                        <Col span={12}
                            style={{flex: 'auto', marginRight: "1%", marginLeft: 'auto', borderRadius: "10px"}}
                        >
                            <div
                                style={{borderRadius: "10px", boxShadow: '5px 5px 5px rgba(0,0,0,0.7)', padding: '2% ', minHeight: "55vh", height: '100%'}}
                            >
                                <Table
                                    dataSource={store.WifiHistory}
                                    columns={columns}
                                    size="small"
                                    onRow={(record) => {
                                        return {
                                            onClick: () => {
                                                store.loadTripData(record.target_id, record.timestamp);
                                            },
                                            style: (function () {
                                                if (record.id === 0) {
                                                    return { backgroundColor: "#f8f4f4" };
                                                } else {
                                                    return {};
                                                }
                                            })()
                                        };
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}


export default observer(WifiDeviceHistory)