import {Modal} from "antd/es";
import {observer} from "mobx-react-lite";
import flickDeviceStore from "../../stores/flickDeviceStore";
import {useContext} from "react";

function FlickModal() {
    const store = useContext(flickDeviceStore);
    console.log(store.Display);
    return (
        <div>
            <Modal
                title="Confirm Flick Drop"
                visible={store.Display}
                onCancel={() => {
                    store.SetDeviceId(-1);
                    store.Display = false
                }}
                onOk={() => store.DropTheDevice()}
            >
                <p>Are you sure you want to drop the device?</p>
            </Modal>
        </div>
    )
}
export default observer(FlickModal);
