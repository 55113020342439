import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import DeviceAuditTab from "../../components/DeviceAuditTab";
import DeviceTargetHistoryTab from "../../components/DeviceTargetHistoryTab";
import DuressAuditTab from "../../components/DuressAuditTab";
import UserAuditTab from "../../components/UserAuditTab";
import API from "../../services/api";
import Layout from "../AppLayout";
import "./AuditManagementPage.scss";
import { secondaryButton, contentBox } from '../../styles';

const { TabPane } = Tabs;

function AuditManagementPage() {
    const [allAnalytics, setAllAnalytics] = useState([]);
    const [allSOS, setAllSOS] = useState([]);
    const [analyticsCount, setAnalyticsCount] = useState(0);

    const [userAudit, setUserAudit] = useState([]);
    const [userAuditCountAll, setUserAuditCountAll] = useState(0);

    const [spinnerVisible, setSpinnerVisible] = useState(false);

    const [deviceTargetHistory, setDeviceTargetHistory] = useState([]);
    const [huntsmanLiveData, setHuntsmanLiveData] = useState([]);
    const [huntsmanLastId, setHuntsmanLastId] = useState(null);
    const [
        deviceTargetHistoryCountAll,
        setDeviceTargetHistoryCountAll,
    ] = useState(0);


    const onFilterBtnClicked = async (serial, from, to, offset, keyword) => {
        setSpinnerVisible(true);

        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.analytics.getAllAnalytics(
            serial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            offset,
            keyword
        );
        setAllAnalytics(response.deviceAnalytic);
        setAnalyticsCount(response.deviceAnalyticCount);
        setSpinnerVisible(false);
    };
    const onLiveMode = async (serial, from, to) => {
        setSpinnerVisible(true);
        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.analytics.getAllHuntsmanAnalytics(
            serial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            50
        );
        setHuntsmanLiveData(response.deviceAnalyticLatest);
        if (response.deviceAnalyticLatest.length > 0) {
            const huntsmanIds = response.deviceAnalyticLatest.map(x => x.id);
            console.log(Math.max(...huntsmanIds))

            setHuntsmanLastId(Math.max(...huntsmanIds))
        }
        setSpinnerVisible(false);
    }

    const timeIntervalHuntsmanAnalytics = async (serial, from, to) => {
        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.analytics.getAllHuntsmanAnalytics(
            serial,
            fromConverted * 1000, // Convert seconds to milliseconds
            toConverted * 1000, // Convert seconds to milliseconds
            1,
            huntsmanLastId
        );

        if (response.deviceAnalyticLatest.length > 0) {
            let huntsManDataLatest = huntsmanLiveData.filter(x => x.id === response.deviceAnalyticLatest[0].id);
            if (huntsManDataLatest.length === 0) {
                setHuntsmanLiveData([...huntsmanLiveData, ...response.deviceAnalyticLatest])
            }
        }
    }

    const onUserAuditSearchBtnClicked = async (module, from, to, offset, keyword, user) => {
        setSpinnerVisible(true);

        const fromConverted = from.toDate();
        fromConverted.setHours(0, 0, 0);

        const toConverted = to.toDate();
        toConverted.setHours(23, 59, 59);

        const response = await API.userAudit.getUserAudit(
            module,
            fromConverted, // Convert seconds to milliseconds
            toConverted, // Convert seconds to milliseconds
            offset,
            keyword,
            user,
            0
        );

        console.log('response user audit', response)
        setUserAuditCountAll(response.total);
        setUserAudit(response.userAudit);

        setSpinnerVisible(false);
    }

    const onDuressFilterClicked = async (investigation, from, to) => {
        setSpinnerVisible(true);

        const response = await API.sos.getAllSOS(
            investigation,
            from.toDate(), // Convert seconds to milliseconds
            to.toDate() // Convert seconds to milliseconds
        );
        setAllSOS(response.sos);
        setSpinnerVisible(false);
    };

    const onFilterDeviceTargetHistory = async (
        device_serial,
        target,
        from,
        to,
        offset,
    ) => {
        const response = await API.deviceTargetHistory.getAllDeviceTargetHistory(
            target ? target : null,
            device_serial ? device_serial : null,
            from, // Convert seconds to milliseconds
            to, // Convert seconds to milliseconds
            offset,
            0
        );

        console.log(response, "response");

        if (response && response.deviceTargetHistory && response.deviceTargetHistory.length > 0) {
            setDeviceTargetHistoryCountAll(response.Total);
            setDeviceTargetHistory(response.deviceTargetHistory);
        } else {
            setDeviceTargetHistoryCountAll(0);
            setDeviceTargetHistory([]);
        }
    };

    return (
        <div>
            <Layout
                showNavigation
                title={"Audit Management"}
                description="Create an audit report and manage audits."
            >
                <div
                    className="AuditPage"
                >
                    <Tabs tabPosition={"top"} defaultActiveKey="1">
                        <TabPane tab={<span>Device Analytics and Reporting</span>} key="1">
                            <div
                                style={{marginTop: "0px"}}
                            >
                                <DeviceAuditTab
                                    onFilterClicked={onFilterBtnClicked}
                                    analytics={allAnalytics}
                                    analyticsCount={analyticsCount}
                                    spinnerVisible={spinnerVisible}
                                    huntsmanData={huntsmanLiveData}
                                    onLiveModeClicked={onLiveMode}
                                    timeIntervalHuntsmanAnalytics={timeIntervalHuntsmanAnalytics}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab={<span>Audit Reports</span>} key="2">
                            <UserAuditTab
                                onFilterClicked={onUserAuditSearchBtnClicked}
                                userAudit={userAudit}
                                userAuditCountAll={userAuditCountAll}
                                spinnerVisible={spinnerVisible}
                            />
                        </TabPane>
                        {/* <TabPane
                            tab={<span id={"duress-audit-tab"}>Duress History Report</span>}
                            key="3"
                        >
                            <DuressAuditTab
                                onFilterClicked={onDuressFilterClicked}
                                sos={allSOS}
                                spinnerVisible={spinnerVisible}
                            />
                        </TabPane> */}
                        <TabPane
                            tab={<span>Device/Target Lifespan History Report</span>}
                            key="4"
                        >
                            <DeviceTargetHistoryTab
                                onFilterDeviceTargetHistory={onFilterDeviceTargetHistory}
                                deviceTargetHistory={deviceTargetHistory}
                                deviceTargetHistoryCount={deviceTargetHistoryCountAll}
                            ></DeviceTargetHistoryTab>
                        </TabPane>
                    </Tabs>
                </div>
            </Layout>
        </div>
    );
}

export default observer(AuditManagementPage);
