import { Button, Col, DatePicker, Input, Row, Select, Spin, Table, Switch, message, Form } from "antd";
import { toJS } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import API from "../../services/api";
import DeviceStore from "../../stores/device.store";
import AnalyticsAdditionalInfoDialog from "../AnalyticsAdditionalInfoDialog";
import HuntsmanLiveDataLogTable from "../HuntsmanLiveDataLogTable";
import { formatDate, getUTCDateTime } from "../../util/CommonUtils";
import NickNameStore from '../../stores/NickName.store';
import investigationStore from '../../stores/investigation.store';
import WifiDeviceTable from '../WifiDeviceTable';
import { primaryButton, secondaryButton, contentBox } from '../../styles'
import WiFiDeviceMap from '../WIfiDeviceMap';
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import DeviceEditForm from "../DeviceEditForm";
import EntityAddForm from "../EntityAddForm";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import AnalysisSiteStore from "../../stores/analysisSuper.store";

function WifiDeviceMangement(props) {
    const investigationSiteStore = useContext(AnalysisSiteStore)
    const investigationstore = useContext(investigationStore);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});
    const [showStopsMap, setShowStopsMap] = useState(false);
    const [apLatitude, setApLatitude] = useState(null);
    const [apLongitude, setApLongitude] = useState(null);
    const [apTrip, setApTrip] = useState(null);
    const [clones, setClones] = useState([]);
    const [clicked, setclicked] = useState([]);
    const [mainEvent, setMainEvent] = useState(null);
    const [entityList, setEntityList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [mode1, setMode1] = useState("add");
    const [existingData1, setExistingData1] = useState([]);

    const [
        {
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            entitiesForInvestigation,
        },
        setSelectedInvestigation,
        setSelectedTarget
    ] = useInvestigationForUser();

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {

        async function getInvest() {
            await investigationstore.getInvestigationsForUser().then((res) => {
                setShowDropDown(true);
            })
        }
        getInvest();
        upDateEntlist();

    }, []);

    const entityadd = () => {
        setMode1("add");
        setExistingData1();
        setIsModalOpen1(true);
    }

    async function updateDevice(values) {
        console.log(values);
        await API.wifiDevice.update(values.id, values.nickname, values.entityName).then((res) => {
            if (res.status === 200) {
                message.info("Updated");
                //window.location.reload(false);
            }
        })
    }

    const upDateEntlist = () => {
        API.entity.GetAllEntities(props.investigationID).then((res) => {
            setEntityList(res);
        })
    }
    const EntityStore = useContext(EntityDisplayStore);
    const editbtn = (record) => {
        EntityStore.setDeviceAndOpen(record.id);
    }

    let store = {};
    const clearSearch = () => {
        formSearch.resetFields;
        props.clearSearch();
    };

    const dataSource = [];
    if (props.analytics && props.analytics.length > 0) {
        props.analytics.forEach((item) => {
            // const localDate = new Date(item.epochDate);
            dataSource.push({
                key: item.id,
                ssid: item.ssid,
                clonedMac: item.clonedMac,
                nickname: item.nickname,
                notes: item.notes,
            });
        });
    }

    const sortedDataSource = dataSource.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
    );

    const [isVisible, setIsVisible] = useState(false);
    const [payload, setPayload] = useState({});

    const analyticsAdditionalView = (record) => {
        setIsVisible(true);
        setPayload(JSON.parse(record.serializedPayload));
    };

    const onModalCancelClicked = () => {
        setIsVisible(false);
    };

    const createEntity = () => {
        setMode("add");
        setExistingData();
        setIsModalOpen(true);
    }

    const handleSubmit = (e) => {
        props.onFilterClicked(
            e.InvestigationID
        );
    }

    const cloneSelect = async (AP) => {
        let listedPoints = clicked;
        if (listedPoints) {
            if (listedPoints.length > 0) {
                let index = listedPoints.findIndex(point => point.pointId === AP.pointId);
                if (index >= 0) {
                    setclicked(clicked.filter(item => item.pointId !== AP.pointId));
                } else {
                    setclicked(prevAP => ([...prevAP, AP]));
                }
            } else {
                setclicked([AP]);
            }
        } else {
            setclicked([AP]);
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col span={11}>
                    <Row>
                        {isLoading && (
                            <div
                                style={{
                                    position: "fixed",
                                    zIndex: 999,
                                    height: "75vh",
                                    width: "40vw",
                                    backgroundColor: "#f2f2f0",
                                    opacity: 0.6,
                                }}
                            >
                                <Spin className={"loader_on_search"}></Spin>
                            </div>
                        )}
                        <div
                            style={{
                                background: 'white',
                                borderRadius: '0.5rem',
                                boxShadow: '5px 5px 5px 5px #E6E6E6',
                            }}
                        >
                            {
                                showStopsMap ?
                                    <WiFiDeviceMap
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        investigationID={props.investigationID}
                                        containerElement={<div style={{ height: '100%', borderRadius: "0.5rem", overflow: "hidden" }} />}
                                        mapElement={<div style={{ height: '100%' }} />}
                                        apLatitude={apLatitude}
                                        apLongitude={apLongitude}
                                        apTrip={apTrip}
                                        clones={clones}
                                        clicked={clicked}
                                        editbtn={editbtn}
                                        mainEvent={mainEvent}
                                        cloneSelect={cloneSelect}
                                        clonedeselection={(AP) => {
                                            let listedPoints = clicked;
                                            if (listedPoints) {
                                                if (listedPoints.length > 0) {
                                                    let index = listedPoints.findIndex(point => point.pointId === AP.pointId);
                                                    if (index >= 0) {
                                                        setclicked(clicked.filter(item => item.pointId !== AP.pointId));
                                                        setClones(clones.filter(item => item.pointId !== AP.pointId));
                                                    } else {
                                                        setclicked(prevAP => ([...prevAP, AP]));
                                                        setClones(prevAP => ([...prevAP, AP]));
                                                    }
                                                } else {
                                                    setclicked([AP]);
                                                    setClones([AP]);
                                                }
                                            } else {
                                                setclicked([AP]);
                                                setclicked([AP]);
                                            }
                                        }}
                                    />
                                    :
                                    <Spin></Spin>
                            }
                        </div>
                    </Row>
                </Col>
                <Col span={12} style={{ MarginTop: "0px", marginRight: "3vh" }}>
                    <div style={contentBox({ marginTop: "0px", height: "75vh" })}>
                        <Form
                            onFinish={handleSubmit}
                            layout="inline"
                            form={form}
                            initialValues={{InvestigationID: investigationSiteStore.selectedInvestigation}}
                        >
                            <Col span={12}>
                                <label>
                                    <span style={{ color: "red" }}>*</span> Investigation
                                </label>
                            </Col>
                            <Form.Item
                                name={"InvestigationID"}
                            >
                                <Select
                                    showSearch
                                    style={{ width: "200px", textAlign: "left" }}
                                    placeholder="Investigation"
                                    onChange={(value) => {investigationSiteStore.setInvestigationId(value);}}
                                    options={toJS(investigationstore.investigationsForUser.map((ainvest) => {
                                        return {
                                            label: ainvest.name,
                                            value: ainvest.id
                                        }
                                    }))}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    id={"device-analytics-search-btn"}
                                    htmlType="submit"
                                    style={primaryButton({ marginLeft: "auto" })}
                                    key="filrer"
                                >
                                    Search
                                </Button>
                            </Form.Item>
                        </Form>
                        <hr />
                        <Form layout="inline"
                            form={formSearch}
                        >
                            <Col span={12}>
                            </Col>
                            <Form.Item
                                name="search"
                            >
                                <Input
                                    style={{ float: "right", width: "200px" }}
                                    placeholder="Search ..."
                                    value={store.searchQuery}
                                    onChange={(event) => {
                                        const query = event.target.value;
                                        props.filter(query);
                                    }}
                                ></Input>
                            </Form.Item>
                            <Form.Item
                                name="clearSearch"
                            >
                                <Button
                                    style={secondaryButton({
                                        float: "right",
                                        margin: "0 0 10px 5px",
                                    })}
                                    htmlType="submit"
                                    onClick={clearSearch}
                                >
                                    Clear
                                </Button>
                            </Form.Item>
                        </Form>

                        <div className='target-table'>
                            <WifiDeviceTable
                                InvestigationID={props.investigationID}
                                data={props.targets}
                                entities={props.entities}
                                setApLatitude={setApLatitude}
                                setApLongitude={setApLongitude}
                                setApTrip={setApTrip}
                                setClones={setClones}
                                setMainEvent={setMainEvent}
                                setIsLoading={setIsLoading}
                            />
                        </div>
                    </div>
                </Col>

            </Row>
            <div>
                <AnalyticsAdditionalInfoDialog
                    modalVisible={isVisible}
                    onCancelClick={onModalCancelClicked}
                    data={payload}
                />
            </div>
        </React.Fragment>
    );
}

export default (WifiDeviceMangement);
