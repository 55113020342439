import { LockOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Tooltip, message } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import React from "react";
import "./ChangePasswordForm.scss";

class ChangePasswordForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values, callback) => {
      if (!error && (!values.password || !values.confirmPassword)) {
        message.error("Both fields are required !");
        return;
      }

      if (!error) {
        this.props.onSubmit(values);
      } else {
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords do not match !");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="change-password-form">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item
            label={
              <span>
                New Password&nbsp;
                <Tooltip
                  title={
                    "Password must have at least 8 characters long and contain an uppercase character,a lowercase character, a numeral, a special character."
                  }
                >
                  <QuestionCircleTwoTone twoToneColor="#52c41a" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  type: "regexp",
                  pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
                  ),
                  message:
                    "Password should be at least 8 characters long and contain an uppercase character,a lowercase character, a numeral, a special character."
                },
                {
                  validator: this.validateToNextPassword
                }
              ],
            })(
              <PasswordInput
                type="password"
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="New Password"
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              <span>
                Confirm Password&nbsp;
                <Tooltip
                  title={
                    "Password must have at least 8 characters long and contain an uppercase character,a lowercase character, a numeral, a special character."
                  }
                >
                  <QuestionCircleTwoTone twoToneColor="#52c41a" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("confirmPassword", {
              rules: [
                {
                  required: true,
                  type: "regexp",
                  pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&*^])(?=.{8,})"
                  ),
                  message:
                    "Password should be at least 8 characters long and contain an uppercase character,a lowercase character, a numeral, a special character"
                },
                {
                  validator: this.compareToFirstPassword
                }
              ],
            })(
              <PasswordInput
                type="password"
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Confirm Password"
              />
            )}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="change-password-form-button"
          >
            Continue
          </Button>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(ChangePasswordForm);
