import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();


/**
 * Get Federation
 * @param id
 * @returns {Promise<unknown>}
 */
const getFederation = async (id) => {
    try {
        const response = await HTTP.get(`/federation/${id}`);
        const data = response.data.data;


        return Promise.resolve(data);
    } catch (exception) {
        if (exception.response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve({federation: []});
        }
        return Promise.reject(exception);
    }
}

/**
 * Add new federation
 * @param payload
 * @returns {Promise<unknown>}
 */
const create = async (payload) => {
    try {
        console.log(payload)
        const response = await HTTP.post(`/federation`, payload);
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

/**
 * Remove federation
 * @param id
 * @returns {Promise<unknown>}
 */
const remove = async (id) => {
    try {
        const response = await HTTP.delete(`/federation/${id}`);
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}


export default {
    getFederation,
    create,
    remove
};