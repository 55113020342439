import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, Row, Select, Spin, Table, Switch, message } from "antd";
import { toJS } from "mobx";
import React, { useContext, useEffect, useState } from "react";
import API from "../../services/api";
import DeviceStore from "../../stores/device.store";
import AnalyticsAdditionalInfoDialog from "../AnalyticsAdditionalInfoDialog";
import HuntsmanLiveDataLogTable from "../HuntsmanLiveDataLogTable";
import { formatDate, getUTCDateTime } from "../../util/CommonUtils";
import NickNameStore from '../../stores/NickName.store';
import investigationStore from '../../stores/investigation.store';
import WifiDeviceTable from '../WifiDeviceTable';
import BluetoothDeviceTable from '../BluetoothDeviceTable';
import { primaryButton, secondaryButton, contentBox } from '../../styles'



function BluetoothDeviceManagement(props) {

    const [keyword, setKeyword] = useState("");
    const [showDropDown, setShowDropDown] = useState(false);
    const investigationstore = useContext(investigationStore);

    const { getFieldDecorator } = props.form;

    let store = {};

    useEffect(() => {

        async function getInvest() {
            await investigationstore.getInvestigationsForUser().then((res) => {
                setShowDropDown(true);
            })
        }
        getInvest();

    }, []);

    const handleSubmit = () => {
        props.form.validateFields((error, values) => {
            if (!error) {
                setKeyword(values.keyword);
                props.onFilterClicked(
                    values.InvestigationID
                );
            }
        });
    }

    const clearSearch = () => {
        props.clearSearch();
    };

    return (
        <div>
            <div style={contentBox()}>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col span={8}>
                            <label>
                                <span style={{ color: "red" }}>*</span> Investigation
                            </label>
                        </Col><Col span={8}></Col>
                        <Col span={8}>
                            <Form.Item>
                                {getFieldDecorator("InvestigationID", {
                                    initialValue: [],
                                    onChange: props.onInvestigationSelect,
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Investigation"
                                        disabled={showDropDown !== true}
                                        filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase()) }
                                    >
                                        {showDropDown === true &&
                                            toJS(
                                                investigationstore.investigationsForUser.map((ainvest) => {
                                                    return (
                                                        <Select.Option
                                                            key={ainvest.id}
                                                            id={ainvest.id}
                                                            value={ainvest.id}
                                                            label={ainvest.name}
                                                        >
                                                            {ainvest.name}
                                                        </Select.Option>
                                                    );
                                                })
                                            )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            textAlign: "end",
                        }}
                    >
                        <Button
                            id={"device-analytics-search-btn"}
                            onClick={() => {
                                handleSubmit();
                            }}
                            style={primaryButton({ marginLeft: "auto" })}
                            key="filrer"
                        >
                            Search
                        </Button>
                    </Row>
                    <hr />
                    <Row>
                        <Col span={8}></Col>
                        <Col span={8} offset={8}>
                            <Button
                                style={{
                                    float: "right",
                                    margin: "0 0 10px 5px",
                                }}
                                onClick={clearSearch}
                            >
                                Clear
                            </Button>

                            <Input
                                style={{ float: "right", width: "40%" }}
                                placeholder="Search .."
                                value={store.searchQuery}
                                onChange={(event) => {
                                    const query = event.target.value;
                                    props.filter(query);
                                }}
                            ></Input>
                        </Col>
                    </Row>
                </Form>
            </div>
            <br></br>
            <div style={contentBox()}>
                <BluetoothDeviceTable
                    InvestigationID={props.investigationID}
                    data={props.targets}
                    entities={props.entities}
                />
            </div>
        </div>
    )
}

export default Form.create({})(BluetoothDeviceManagement);
