import {
    ArrowDownOutlined,
    EnvironmentFilled,
    EnvironmentOutlined,
    ExclamationCircleOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleFilled,
    InfoCircleOutlined, LockOutlined, RadarChartOutlined, SettingFilled, SettingOutlined, UnlockOutlined
} from '@ant-design/icons';
import {Drawer, Collapse, List, Switch, Row, Col, Divider, Tooltip, Tree, Badge, message} from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import DeviceDetails from "../DeviceDetails";
import DropDownConfig from '../DropDownConfig';
import "./LivePanel.scss";
import { contentBox } from '../../styles'
import { ArrowLeftOutlined, BorderOuterOutlined } from '@ant-design/icons';
import PermissionStore from "../../stores/permission.store";
import React, { useContext, useEffect, useState } from "react";
import {ArrowDownCircle, ArrowDownCircleFill, InfoCircleFill, RecordCircleFill} from 'react-bootstrap-icons';
import FlickDeviceStore from "../../stores/flickDeviceStore";

const { Panel } = Collapse;

function PermCheck() {
    const permissionStore = useContext(PermissionStore);

    return !permissionStore.can("configuration.preset");
}

function deviceCheck(item) {
    return (item.device_type_name === "Redback 1 - GPS" || item.device_type_name === "Redback 3 - GPS" || item.device_type_name === "WhiteTail")
}

function flickCheck(item) {
    return (item.device_type_name === "Huntsman");
}


function sortInvestigations(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
}

function sortTargets(a, b) {
    const convertSelectedKeysToLowerCase = (a) => {
        return (a.alias || a.name).toLowerCase();
    };

    if (convertSelectedKeysToLowerCase(a) < convertSelectedKeysToLowerCase(b)) {
        return -1;
    }
    if (convertSelectedKeysToLowerCase(a) > convertSelectedKeysToLowerCase(b)) {
        return 1;
    }
    return 0;
}
const toggle = (configchange, tochange) => {
    for (let val in configchange) {
        configchange[val] = 'null';
    }
    configchange[tochange] = 'pending';
    return configchange;
}

function segregateDeviceClassesSortAndCombine(targets) {
    const blueDevices = targets
        .filter((target) => target.class === "BLUE")
        .sort(sortTargets);

    const redDevices = targets
        .filter((target) => target.class === "RED")
        .sort(sortTargets);

    return [...redDevices, ...blueDevices];
}

const configClass = {
    liveMode: 'null',
    monitoring: 'null',
    logging: 'null',
    default: 'accepted',
    prevous: 'null',
}

export default React.memo(
    observer(function ({
        records,
        PreselectedTargets,
        onTargetSelect,
        currentlyBoundedTargets,
        currentlyHiddenTargets,
        onTargetDetails,
        selectedTargets,
        onTargetShowHideToggle,
        onTargetLock,
        onPanelChange,
        openPanels,
        onClickShowPoints,
        TargetsClickedForPoints,
        speedUnitString,
        showDrawer,
        onClose,
        visible,
        setPreConf,
        geofenceSpawn,
        setGeoFenceOpen,
        setCellTowerData,
    }) {

        const flickStore = useContext(FlickDeviceStore);

        function isTargetCurrentlyBounded(target) {
            const isBound =
                currentlyBoundedTargets.filter((t) => {
                    return t.device_id === target.device_id;
                }).length > 0;

            return isBound;
        }
        function entityCount(cloneCapture) {
            if (cloneCapture === undefined || cloneCapture === null) {
                return 0;
            }
            if (cloneCapture.length === 0) {
                return 0;
            }
            return cloneCapture.length - 1;
        }

        function determinateUnAssignedDeviceCount(cloneCapture) {
            if (cloneCapture === undefined || cloneCapture === null) {
                return 0;
            }
            let index = cloneCapture.findIndex((item) => {
                if (item.entityId === null) return true;
                return false;
            });
            const unassignedDevices = cloneCapture[index];
            if (unassignedDevices === undefined) {
                return 0;
            }
            return unassignedDevices?.children?.length;
        }

        function isTargetCurrentlyClickedForPoints(target) {
            const isBound =
                TargetsClickedForPoints.filter((t) => {
                    return t === target.target_id;
                }).length > 0;
            return isBound;
        }

        function isTargetCurrentlySelected(target) {
            const isSelected =
                selectedTargets.filter((t) => {
                    return t.device_id === target.device_id;
                }).length > 0;

            return isSelected;
        }

        function isTargetCurrentlySelectedPRECO(target) {
            const isSelected =
                PreselectedTargets.filter((t) => {
                    return t.device_id === target.device_id;
                }).length > 0;

            return isSelected;
        }

        function investigationHidden(investigation) {
            if (currentlyHiddenTargets.length === 0) {
                return false;
            }
            investigation.target.forEach((target) => {
                if (isTargetCurrentlyHidden(target)) {
                    return true;
                }
            });
            return false;
        }

        function isTargetCurrentlyHidden(target) {
            const isHidden =
                currentlyHiddenTargets.filter((t) => {
                    return t.device_id === target.device_id;
                }).length > 0;

            return isHidden;
        }

        const _showDrawer = () => {
            showDrawer();
        };
        const _onClose = () => {
            onClose();
        };

        function iscelltowerset(item) {
            if (item.lastCellPoint && (item.lastCellPoint.CELL_TIME > item.position_lastCommsTimestamp)) {
                return true;
            }
            return false;
        }
        function colourfind(item) {
            let colour = "grey";

            if (item.device_type_name === "ATRAX-GPS" || item.device_type_name === "ATRAX-GPS3") {
                colour = "green"
            }
            return colour;
        }

        if (!visible) {
            return null
        }
        return (
            <div>
                <Drawer
                    title={
                        <div>
                            <h4>Live <Switch
                                checkedChildren="Local Time"
                                unCheckedChildren="UTC Time"
                                defaultChecked={localStorage.getItem("utcTime") === "false"}
                                onChange={(e) => {
                                    if (e) {
                                        localStorage.setItem("utcTime", "false")
                                    } else {
                                        localStorage.setItem("utcTime", "true")
                                    }
                                }}
                            />
                                <Tooltip title="Geofence Management">
                                    <BorderOuterOutlined
                                        style={{ float: 'right', fontSize: '20px', color: '#000000a6' }}
                                        onClick={() => {
                                            setGeoFenceOpen(true);
                                        }}
                                    />
                                </Tooltip>
                            </h4>
                        </div>}
                    defaultActiveKey={["trips"]}
                    style={{
                        boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                      0 12.5px 10px rgba(0, 0, 0, 0.06),
                      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                      0 100px 80px rgba(0, 0, 0, 0.12)`,
                        width: "25%",
                        marginLeft: "4rem"
                    }}
                    placement="left"
                    onClose={_onClose}
                    visible={visible}
                    mask={false}

                    bodyStyle={{ background: '#f8f4f4' }}
                    headerStyle={{ background: '#f8f4f4' }}
                    onChange={onPanelChange}
                    closeIcon={
                        < ArrowLeftOutlined />
                    }
                >
                    <div
                        style={contentBox({ padding: "0.5rem" })}
                    >
                        <Collapse
                            defaultActiveKey={['1']}
                            onChange={onPanelChange}
                            ghost
                        >
                            {records.sort(sortInvestigations).map((record) => {
                                const targets = [
                                    ...record.target,
                                    ...(typeof record.investigator !== "undefined"
                                        ? record.investigator
                                        : []),
                                ];
                                return (
                                    <Panel
                                        id={record.id.toString()}

                                        style={{
                                            maxHeight: "90vh",
                                            overflow: "auto",
                                        }}
                                        header={
                                                record.name
                                        }
                                        key={record.id}
                                    >
                                        <List
                                            style={{
                                                maxHeight: "90vh",
                                                overflow: "auto",
                                            }}
                                            dataSource={segregateDeviceClassesSortAndCombine(
                                                toJS(targets)
                                            )}
                                            renderItem={(item) => {

                                                const actions = [
                                                    <Tooltip title="Device Details">
                                                    {isTargetCurrentlySelected(item) ? (<InfoCircleFilled
                                                        id={`info-${item.device_id}`}
                                                        key={`info-${item.device_id}`}
                                                        style={{ color: '#0066CC' }}
                                                        onClick={() => {
                                                            return onTargetDetails(item);
                                                        }}
                                                    />) : (
                                                        <InfoCircleOutlined
                                                            id={`info-${item.device_id}`}
                                                            key={`info-${item.device_id}`}
                                                            style={{ color: '#0066CC' }}
                                                            onClick={() => {
                                                                return onTargetDetails(item);
                                                            }}
                                                        />
                                                    )}
                                                </Tooltip>,
                                                    <Tooltip title="Show/ Hide Marker">
                                                        {isTargetCurrentlyHidden(item) ? (<EyeInvisibleOutlined
                                                            id={`info-${item.device_id}`}
                                                            key={`info-${item.device_id}`}
                                                            style={{ color: '#0066CC' }}
                                                            onClick={() => {
                                                                // if target is locked and hidden, unlock it
                                                                if (isTargetCurrentlyBounded(item)) {
                                                                    onTargetLock(item);
                                                                }
                                                                return onTargetShowHideToggle(item);
                                                            }}
                                                        />) : (
                                                            <EyeOutlined
                                                                id={`info-${item.device_id}`}
                                                                key={`info-${item.device_id}`}
                                                                style={{ color: '#0066CC' }}
                                                                onClick={() => {
                                                                    // if target is locked and hidden, unlock it
                                                                    if (isTargetCurrentlyBounded(item)) {
                                                                        onTargetLock(item);
                                                                    }
                                                                    return onTargetShowHideToggle(item);
                                                                }}
                                                            />
                                                        )}
                                                    </Tooltip>,
                                                    <Tooltip title="Follow/ Lock Marker">
                                                        {isTargetCurrentlyBounded(item) ? (<LockOutlined
                                                            id={`info-${item.device_id}`}
                                                            key={`info-${item.device_id}`}
                                                            style={{  color: 'orange'}}
                                                            onClick={() => {
                                                                if (isTargetCurrentlyHidden(item)) {
                                                                    onTargetShowHideToggle(item);
                                                                }
                                                                return onTargetLock(item);
                                                            }}
                                                        />) : (
                                                            <UnlockOutlined
                                                                id={`info-${item.device_id}`}
                                                                key={`info-${item.device_id}`}
                                                                style={{  color: 'orange' }}
                                                                onClick={() => {
                                                                    if (isTargetCurrentlyHidden(item)) {
                                                                        onTargetShowHideToggle(item);
                                                                    }
                                                                    return onTargetLock(item);
                                                                }}
                                                            />
                                                        )}
                                                    </Tooltip>,
                                                    <Tooltip title="Show Last Ten Points">
                                                        {isTargetCurrentlyClickedForPoints(item) ? (<EnvironmentFilled
                                                            id={`info-${item.device_id}`}
                                                            key={`info-${item.device_id}`}
                                                            style={{  color: 'red'}}
                                                            onClick={() => {
                                                                return onClickShowPoints(item);
                                                            }}
                                                        />) : (
                                                            <EnvironmentOutlined
                                                                id={`info-${item.device_id}`}
                                                                key={`info-${item.device_id}`}
                                                                style={{  color: 'red' }}
                                                                onClick={() => {
                                                                    return onClickShowPoints(item);
                                                                }}
                                                            />
                                                        )}
                                                    </Tooltip>,
                                                ];
                                                if (iscelltowerset(item)) {
                                                    actions.push(
                                                        <Tooltip title="Cell Tower">
                                                            <RadarChartOutlined
                                                                id={`info-${item.device_id}`}
                                                                key={`info-${item.device_id}`}
                                                                style={{  color: 'gray'}}
                                                                onClick={() => {
                                                                    return setCellTowerData(item);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )
                                                }
                                                if (PermCheck() && deviceCheck(item)) {
                                                    actions.push(
                                                        <Tooltip title="Preset Configs">
                                                            <SettingOutlined
                                                                id={`info-${item.device_id}`}
                                                                key={`info-${item.device_id}`}
                                                                style={{  color: 'gray' }}
                                                                onClick={() => {
                                                                    if (item.device_type_name === "Redback 1 - GPS" || item.device_type_name === "Redback 3 - GPS" || item.device_type_name === "WhiteTail") {
                                                                        return setPreConf(item);
                                                                    }
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )
                                                }
                                                if (PermCheck() && flickCheck(item)) {
                                                    flickStore.SetDeviceId(item.device_id);
                                                    actions.push(
                                                        <Tooltip title={flickStore.ToolTipText}>
                                                            <ArrowDownCircleFill
                                                                id={`info-${item.device_id}`}
                                                                key={`info-${item.device_id}`}
                                                                style={{color: flickStore.ButtonColour}}
                                                                onClick={() => {
                                                                    flickStore.Drop(item.device_id);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )
                                                }

                                                return (
                                                    <React.Fragment>
                                                        <List.Item
                                                            id={`main-${item.device_id}`}
                                                            key={`main-${item.device_id}`}
                                                            actions={actions}
                                                        >
                                                            <div
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    return onTargetSelect(item);
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        color:
                                                                            "#2980b9",
                                                                    }}
                                                                >
                                                                    <p>
                                                                        <RecordCircleFill
                                                                            color={item.colour}
                                                                            style={{
                                                                                marginRight: 10,
                                                                                marginBottom: 4,
                                                                            }}
                                                                        />

                                                                        <Badge
                                                                            count={determinateUnAssignedDeviceCount(item.cloneCapture)}
                                                                            size="small"
                                                                            color="orange"
                                                                            offset={[28, 5]}
                                                                            title='UnAssigned Devices Seen'
                                                                            showZero={false}
                                                                        >
                                                                            <Badge
                                                                                count={entityCount(item?.cloneCapture)}
                                                                                size="small"
                                                                                color="grey"
                                                                                offset={[8, 5]}
                                                                                title='Entities Seen'
                                                                                showZero={false}
                                                                            >
                                                                                {item.alias || item.name}
                                                                                {"   "}
                                                                            </Badge>
                                                                        </Badge>
                                                                    </p>
                                                                </div>
                                                                {item.latitude === null && item.longitude === null && (
                                                                    <React.Fragment>
                                                                        <ExclamationCircleOutlined style={{ color: "red" }} />{" "}
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </List.Item>
                                                        {isTargetCurrentlySelected(item) && (
                                                            <DeviceDetails
                                                                item={item}
                                                                list={true}
                                                                speed_unit={record.speed_unit}>

                                                            </DeviceDetails>
                                                        )}
                                                        {isTargetCurrentlySelectedPRECO(item) && (
                                                            <DropDownConfig
                                                                thisConfig={item.config}
                                                                item={item}
                                                                list={true}
                                                                speed_unit={record.speed_unit}
                                                                toggle={toggle}
                                                            >
                                                            </DropDownConfig>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            }}
                                        ></List>
                                    </Panel>
                                );
                            })}
                        </Collapse>
                    </div>
                </Drawer >
            </div >
        );
    })
);
