import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, message, Drawer, Switch, Checkbox, TimePicker, Divider } from 'antd'
import Icon, { ArrowLeftOutlined, StopOutlined, BorderOutlined, DeleteOutlined } from '@ant-design/icons'
import { getTimeZones } from "../../util/CommonUtils";
import { contentBox, primaryButton, secondaryButton } from '../../styles';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import moment from 'moment';

export default function GeofenceCreate({ opened, onCancel, onSubmit, data, name, targets, shapeSelect, type, users, alwaysActive, setAlwaysActive }) {

    const Circlesvg = () => (
        <svg viewBox="64 64 896 896" focusable="false" xmlns="http://www.w3.org/2000/svg" width={'30px'} height={'30px'}>
            <path fill={type === "circle" ? '#0066CC' : "black"} d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
        </svg>
    )

    //typescript interpolation pulled directly from antd docs
    const CircleIcon = (props) => (<Icon component={Circlesvg} {...props} />);

    const [form] = Form.useForm()
    const options = [
        { label: 'Sunday', value: 0 },
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
    ]

    const [fromTime, setFromTime] = useState(0);

    useEffect(() => {
        if (data._days) {
            if (data._days.length > 0) {
                setAlwaysActive(false)
            }
        }
    }, [data])

    useEffect(() => {
        form.resetFields()
    }, [opened])

    // form.resetFields()

    if (!opened) {
        return null
    }

    const getTime = (offset = 0) => {
        let times = []
        for (let i = offset; i < 24; i++) {
            times.push({ value: i, label: i })
        }
        return times
    }

    return (
        <Drawer
            title={data.name ? data.name : `New Geofence: ${name}`}
            visible={opened}
            style={{
                boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                      0 12.5px 10px rgba(0, 0, 0, 0.06),
                      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                      0 100px 80px rgba(0, 0, 0, 0.12)`,

                marginBottom: "2px",
                width: "35%",
                marginLeft: "4rem"
            }}
            placement="left"
            onClose={() => {
                onCancel();
            }}
            bodyStyle={{ background: '#f8f4f4' }}
            headerStyle={{ background: '#f8f4f4' }}
            closeIcon={
                <ArrowLeftOutlined />
            }
        >
            <Form
                form={form}
                onFinish={() => {
                    form.validateFields().then((values) => {
                        if (type === "rectangle" || type === "circle") {
                            onSubmit(values);
                        } else {
                            message.error("Please select a shape for the geofence!");
                        }
                    }).catch((info) => {
                        console.log('Validate Failed:', info);
                        if (info.errorFields = []) {
                            if (type === "rectangle" || type === "circle") {
                            } else {
                                message.error("Please select a shape for the geofence!");
                            }
                        }
                    })
                }}
            >
                <Form.Item
                    label="Border shape"
                >
                <div>
                    {/* <Button type="link" style={{ color: (type === "circle" ? '#0066CC' : "black") }} onClick={() => shapeSelect('circle')} icon={<CircleIcon/>}></Button> */}
                    <Button
                        style={{
                            border: '1px solid #2980b9',
                            // top: '-5px',
                            width: '40px',
                            height: '40px',
                        }}
                        onClick={() => shapeSelect('circle')}
                    >
                        <CircleIcon
                            style={{
                                marginTop: '-5px',
                                marginLeft: '-10.5px',
                                fontSize: '30px',
                                color: (type === "circle" ? '#0066CC' : "black")
                            }}
                        />
                    </Button>
                    <Button
                        style={{
                            border: '1px solid #2980b9',
                            width: '40px',
                            height: '40px',
                            marginLeft: '10px'
                        }}
                        onClick={() => shapeSelect('rectangle')}
                    >
                        <BorderOutlined
                            style={{
                                marginLeft: '-10.5px',
                                marginTop: '-5px',
                                fontSize: '30px',
                                color: (type === "rectangle" ? '#0066CC' : "black")
                            }}
                        />
                    </Button>
                    <DeleteOutlined
                        style={{
                            fontSize: '30px',
                            color: 'red',
                            marginLeft: "150px"
                        }}
                        onClick={() => shapeSelect('cancel')}
                    />
                </div>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input a name for the geofence!' }]}
                    initialValue={data.name}
                >
                    <Input
                    />
                </Form.Item>
                <Form.Item
                    label="Target"
                    name="target"
                    rules={[{ required: true, message: 'Please select a target for the geofence!' }]}
                    initialValue={data.target_id}
                >
                    <Select
                        mode="multiple"
                        showSearch
                    >
                        {
                            targets.map((target) => {
                                return (
                                    <Select.Option value={target.id}>{target.name}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Timezone"
                    name="timezone"
                    rules={[{ required: true, message: 'Please select a timezone for the geofence!' }]}
                    initialValue={data.timezone}
                >
                    <Select
                        showSearch
                    >
                        {getTimeZones().map((timezone) => (
                            <Select.Option key={timezone.value}>{timezone.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Always Active"
                    name="alwaysActive"
                // initialValue={data.always_active.length > 0 ? true : false}
                >
                    <Switch
                        defaultChecked={!data.activeDays}
                        checked={alwaysActive}
                        onChange={(checked) => {
                            setAlwaysActive(checked)
                        }}
                    />
                </Form.Item>{
                    !alwaysActive && (
                        <div style={contentBox()}>
                            <Form.Item
                                label="Active days"
                                name="activeDays"
                                initialValue={data._days}
                                rules={[{ required: true, message: 'Please select active days for the geofence!' }]}
                            >
                                <Checkbox.Group options={options} defaultValue={data._days} style={{ "marginRight": 0, "display": "flex", "flexDirection": "column" }} />
                            </Form.Item>
                            <Form.Item
                                label="Active Duration from"
                                name="activeDurationFrom"
                                rules={[{ required: true, message: 'Please select active duration for the geofence!' }]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setFromTime(value + 1)
                                    }}
                                >
                                    {getTime().map((time) => (
                                        <Select.Option key={time.value}>{time.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Active Duration To"
                                name="activeDurationTo"
                                rules={[{ required: true, message: 'Please select active duration for the geofence!' }]}
                            >
                                <Select>
                                    {getTime(fromTime).map((time) => (
                                        <Select.Option key={time.value}>{time.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    )
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit"
                        style={primaryButton()}
                    >
                        Submit
                    </Button>
                    <Button
                        style={secondaryButton({ marginLeft: 8 })}
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}