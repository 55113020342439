import { StarTwoTone, StarFilled, ProfileOutlined, PushpinOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal, Select, Tooltip, Form, Divider } from "antd";
import React, { useState } from "react";

import API from '../../services/api';
import { macToMAC } from '../../util/CommonUtils';
import { secondaryButton } from '../../styles';

/* Device Edit Form
 *  device: {
 *    type: Either "device" or "accessPoint"
 *    alias: Alias of the device
 *    address: Geo-coded address string
 *    id: ID in the database
 *    mac: MAC address, either hex string or number
 *    metadata: All extraneous metadata
 *    ssid: SSID of the device if applicable
 *
 *    Only applicable for device type "device"
 *    accessPoint: {
 *      id: access point number
 *      latlng: LatLng literal
 *      mac: MAC address of access point
 *      metadata: Extraneous metadata
 *    }
 *  }
 *
 */

const DeviceEditForm = ({
    onSubmit,
    onCancel,
    opened,
    device,
    mode,
    type,
    bluetooth,
    targets,
    entityadd,
}) => {
    const [form] = Form.useForm();
    const [favourite, setFavourite] = useState(false);

    const strings = {
        "device": "Device",
        "accessPoint": "Access Point",
    }

    // Submission of form data
    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {

                values.id = device.id;
                values.type = device.type;
                onSubmit({ values, mode: mode });
            })
            .catch((error) => {
                console.log(device);
                console.log(error);
            });
    };

    // Reset form and close modal
    const cancel = () => {
        form.resetFields();
        onCancel();
    };

    // Mark current device as favourite
    const favouriteClick = () => {
        const id = (device.type === "accessPoint" ? device.id : device.accessPoint.id);
        let data = (device.type === "accessPoint" ? device?.metadata : device.accessPoint?.metadata) || "{}";
        data = JSON.parse(data);
        data.favourited = !(data?.favourited || false);
        data = JSON.stringify(data);

        API.accessPoint.updateAccessMeta(id, data)
            .then((res) => {
                if (res) {
                    message.info("Successfully Favourited");
                    if (device.type === "accessPoint") {
                        device.metadata = data;
                    } else {
                        device.accessPoint.metadata = data;
                    }
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const isFavourite = (override = device) => {
        const metadata = JSON.parse(override?.metadata || "{}");
        return (metadata?.favourited || false);
    };
    if (!device) {
        return null;
    }
    return (
        <Modal
            title={(
                <React.Fragment>
                    {mode === "add" ? "Add Entity" : `Edit ${strings[type]}`}
                </React.Fragment>
            )}
            onCancel={cancel}
            visible={opened}
            confirmLoading={false}
            footer={
                <div style={{ display: "flex" }}>
                    <Button style={{ flexGrow: 1 }} htmlType={"button"} onClick={cancel} >Cancel</Button>
                    <Button style={{ flexGrow: 1 }} type={"primary"} htmlType={"submit"} onClick={handleSubmit} >Apply</Button>
                </div>}
        >
            <Form
                form={form}
                layout={"vertical"}
                initialValues={{ entityName: device?.entity_id, nickname: (mode === "edit" && device ? device.alias : null) }}
                onFinish={() => { console.log('Finish'); }}
                onFinishFailed={() => { console.log('FinishFailed'); }}
            >
                {(bluetooth)
                    ?
                    <div>
                        <h5>{"Bluetooth connection details"}</h5>
                        <p>{`Mac Address: ${macToMAC(device.mac)}`}</p>
                        <p>{`Device Name: ${device.device_name}`}</p>
                        <p>{`Manufacturer: ${device.manufacturer}`}</p>
                        <p>{`Device Type: ${device.class}`}</p>
                        <p>{`Address: ${device.address}` || "no address"}</p>
                    </div>
                    :
                    <div>
                        <h6 style={{ marginTop: '0.5em', display: 'inline-block' }}>{`${strings[device.type]} Details`}</h6>
                        {device.type === 'accessPoint' ?
                            <Tooltip placement="topLeft" title="Add access point to favourites" align={{ offset: [-6, 0] }}>
                                <Button
                                    icon={(isFavourite() ? <StarFilled onClick={favouriteClick} /> : <StarTwoTone twoToneColor={"#fadb14"} onClick={favouriteClick} />)}
                                    style={{ color: "#fadb14" }}
                                    type={"text"}
                                />
                            </Tooltip>
                            : null}
                        <table style={{ borderCollapse: "separate", width: "100%" }} >
                            <colgroup>
                                <col span="1" style={{ width: "40%" }} />
                                <col span="1" style={{ width: "60%" }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>Device Mac Address:</td>
                                    <td>{macToMAC(device?.mac)}</td>
                                </tr>
                                {device.type === "accessPoint" ?
                                    <tr>
                                        <td>Address:</td>
                                        <td>{device?.address || "Unknown"}</td>
                                    </tr>
                                    : null}
                                <tr>
                                    <td>Metadata:</td>
                                    <td>{device?.metadata || "None"}</td>
                                </tr>
                            </tbody>
                        </table>
                        {(device.type === "device" ?
                            <React.Fragment>
                                <h6 style={{ marginTop: '0.5em', display: 'inline-block' }}>{"Access Point Details"}</h6>
                                <Tooltip placement="topLeft" title="Add access point to favourites" align={{ offset: [-6, 0] }}>
                                    <Button
                                        icon={(isFavourite(device.accessPoint) ? <StarFilled onClick={favouriteClick} /> : <StarTwoTone twoToneColor={"#fadb14"} onClick={favouriteClick} />)}
                                        style={{ color: "#fadb14" }}
                                        type={"text"}
                                    />
                                </Tooltip>
                                <table style={{ borderCollapse: "separate", marginBottom: "0.5em", width: "100%" }} >
                                    <colgroup>
                                        <col span="1" style={{ width: "40%" }} />
                                        <col span="1" style={{ width: "60%" }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td>Access Point MAC:</td>
                                            <td>{macToMAC(device?.accessPoint?.mac) || "Unknown"}</td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td>{device?.address || "Unknown"}</td>
                                        </tr>
                                        <tr>
                                            <td>SSID:</td>
                                            <td>{device?.accessPoint?.ssid || "None"}</td>
                                        </tr>
                                        <tr>
                                            <td>Metadata:</td>
                                            <td>{device?.accessPoint?.metadata || "None"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </React.Fragment> : null)}
                        <Divider style={{ margin: "8px 0px" }} />
                    </div>
                }
                <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "flex-end", justifyContent: "space-between", columnGap: "8px" }} >
                    <Form.Item
                        name="entityName"
                        label="Entity"
                        style={{ flexGrow: '1' }}
                    >

                        <Select
                            placeholder={<React.Fragment><PushpinOutlined />&nbsp;Entity</React.Fragment>}
                            showSearch
                            filterOption={false}
                            optionFilterProp="children"
                            style={{ width: "100%" }}
                            // filterOption={(input, option) =>
                            //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            // }
                            // options={targets.map((atarget) => {
                            //     return {
                            //         label: atarget.alias,
                            //         value: atarget.id,
                            //     };
                            // })}
                            onChange={(value) => { console.log(value) }}
                        >
                            {
                                targets.map((atarget) => {
                                    return (
                                        <Select.Option
                                            key={atarget.id}
                                            value={atarget.id}
                                        >
                                            {atarget.alias}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>


                    </Form.Item>
                    <Button htmlType={"button"} type={"primary"} onClick={entityadd} >
                        New Entity
                    </Button>
                </div>
                {(bluetooth)
                    ?
                    []
                    :
                    <Form.Item name="nickname" label="Alias" >
                        <Input
                            prefix={<ProfileOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            type="text"
                            placeholder="Alias"
                        />
                    </Form.Item>}
            </Form>
        </Modal>
    );
}

DeviceEditForm.defaultProps = {
    targets: [],
    type: "device"
};

export default DeviceEditForm;
