import React, { useEffect, useState } from "react";
import AppLayout from "../AppLayout";
import AnalysisMultiMap from "../../components/AnalysisMultiMap";
import AnalysisMultiForm from "../../components/AnalysisMultiForm";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { primaryButton } from "../../styles";

function MultiTargetDisplay() {

    const [showStopsMap, setShowStopsMap] = useState(false);
    const [visible, setVisible] = useState(true);
    const [hover, setHover] = useState(false);
    const [buttonMargin, setButtonMargin] = useState("32%");
    const [buttonOpacity, setButtonOpacity] = useState(1);
    const [margin, setMargin] = useState("33%");
    const [hour, setHour] = useState([[0], [23]]);
    const [day, setDay] = useState(0);
    const [points, setPoints] = useState([]);
    const [visiblePoints, setVisiblePoints] = useState([[], [], [], []]);
    const [speedUnitString, setSpeedUnitString] = useState("KPH");

    useEffect(() => {
        setVisiblePoints([[], [], [], []])
        let temp = [[], [], [], []]
        const lower = day + (hour[0] * 60 * 60) + (0 * 60);
        const upper = day + (hour[1] * 60 * 60) + (59 * 60) + 59;

        points.forEach((sequence, index) => {
            sequence.points.forEach(point => {
                if (point.device_utc_date_time >= lower && point.device_utc_date_time <= upper) {
                    temp[index].push(point)
                }
            })
        })

        setVisiblePoints(temp);
    }, [hour, day, points]);

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);
        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    const showDrawer = () => {
        setButtonOpacity('1.0');
        setButtonMargin('32%');
        setVisible(true);
        setMargin("33%");

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin('0px');
    };

    const unSetHover = () => {
        setHover(false);
    };

    return (
        <AppLayout showNavigation hideMargins>
            {showStopsMap === false && (
                <div
                    style={{
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20%",
                    }}
                >
                    <Spin></Spin>
                </div>
            )}

            {showStopsMap === true && (
                <div>
                    <div style={{
                        position: "fixed",
                        zIndex: 2,
                        width: "100%"
                    }}>
                        < Button type="primary" onClick={visible ? onClose : showDrawer}
                            style={primaryButton({
                                position: "absolute",
                                top: "40vh",
                                zIndex: 2,
                                height: "15vh",
                                marginTop: "0%",
                                left: buttonMargin,
                                marginLeft: "2px",
                                borderLeftColor: "#0066CC",
                                marginBottom: "2px",
                                opacity: buttonOpacity,
                            })}
                            onMouseEnter={() => { setHover() }}
                            onMouseLeave={() => { unSetHover() }}
                        >
                            {
                                visible
                                    ?
                                    <ArrowLeftOutlined
                                        width={25}
                                        height={25}
                                    />
                                    :
                                    <ArrowRightOutlined
                                        width={25}
                                        height={25}
                                    />
                            }
                        </Button>
                    </div>
                    <AnalysisMultiForm
                        setSpeedUnitString={setSpeedUnitString}
                        visible={visible}
                        onClose={onClose}
                        setHour={setHour}
                        setDay={setDay}
                        setPoints={setPoints}
                        hour={hour}
                        points={points}
                    />
                </div>
            )}

            {showStopsMap === true && (
                <div style={{ marginLeft: margin }} className="stopsPage">
                    <AnalysisMultiMap
                        visiblePoints={visiblePoints}
                        speedUnitString={speedUnitString}
                    />
                </div>
            )}
        </AppLayout>
    )
}

export default MultiTargetDisplay;