import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Row, Select, Space, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import PermissionStore from "../../stores/permission.store";
import Layout from "../AppLayout";
import { toJS } from "mobx";
import moment from 'moment';
import { contentBox, primaryButton, secondaryButton } from '../../styles'
import DownloadOptionsModal from '../../components/DownloadOptionsModal'
import analysisExportStore from '../../stores/analysisExport.store';
import ExportFormModal from '../../components/ExportForm';


import AnalysisStore from "../../stores/analysis.store";
import InvestigationStore from "../../stores/investigation.store";
import TargetStore from "../../stores/target.store";


function ExportManagementPage() {

    const Option = Select.Option;

    const store = useContext(TargetStore);
    const investigationStore = useContext(InvestigationStore);
    const analysisStore = useContext(AnalysisStore);


    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const exportStore = useContext(analysisExportStore);
    useEffect(() => {
        exportStore.getExports();
        store.getAllTargets();
        investigationStore.getInvestigationsForUser();
        analysisStore.getExportList();
    }, []);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Investigation",
            dataIndex: "investigation_name",
            key: "investigation_name",
        },
        {
            title: "Target",
            dataIndex: "target_name",
            key: "target_name",
        },
        {
            title: "Start (Local)",
            dataIndex: "start",
            key: "start",
            render: (text, record) => {
                const date = new Date(record.start * 1000);
                return (
                // convert epouch time to date
                    <div>
                        {date.toDateString()}
                    </div>
                )
            }
        },
        {
            title: "End (Local)",
            dataIndex: "end",
            key: "end",
            render: (text, record) => {
                const date = new Date(record.end * 1000);
                return (
                // convert epouch time to date
                    <div>
                        {date.toDateString()}
                    </div>
                )
            }
        },
        {
            title: "Created (local)",
            dataIndex: "created",
            key: "created",
            render: (text, record) => {
                const date = new Date(record.created_at * 1000);
                return (
                    `${record.user_name} on ${date.toDateString()}`
                );
            }
        },
        {
            title: "Hash of DataSet (sha256)",
            dataIndex: "hash",
            key: "hash",
            render: (text, record) => {
                if (record.hash === null) {
                    return (
                        `No Hash made, Investigation is not locked`
                    )
                } else {
                    return (
                        <div>
                            {record.hash}
                        </div>
                    )
                }
            }
        },
        {
            title: "Download",
            dataIndex: "download",
            key: "download",
            render : (text, record) => (
                <Button
                    style={secondaryButton()}
                    onClick={() => {
                        // set the store for the export options
                        exportStore.setExportID(record.id);
                        exportStore.setExportName(record.name);
                        setIsModalOpen1(true)
                    }}
                    icon={<DownloadOutlined />}
                >
                </Button>
            )
        },
    ];
    const dlOptions = ['JSON', 'CSV', 'KML', 'PDF']

    const downloadPoints = async (options) => {
        if (exportStore.isSet()) {
            exportStore.exportDownload(dlOptions[options]);
        } else {
            message.error('Please select a time range and a target to export points');
        }
    };

    return (
        <div>
            <Layout
                showNavigation
                title={"Exports"}
                description="Create an export or manage historical exports."
            >
                <ExportFormModal
                    onInvestigationChanged={(changedInvestigationID) => {
                        store.setActiveInvestigation(changedInvestigationID);
                    }}
                    investigations={investigationStore.investigationsForUser}
                    targets={store.targetsForInvestigation}
                    opened={isModalOpen2}
                    onSubmit={async (data) => {
                        const [startDate, endDate] = data.timePeriod;
                        data.from = startDate.unix();
                        data.to = endDate.unix();
                        exportStore.updatebulk(data);
                        exportStore.saveExport(data.timezone, data.serial, data.altitude);
                        exportStore.getExports();
                        setIsModalOpen2(false);
                    }}
                    onCancel={() => {
                        setIsModalOpen2(false);
                    }}
                ></ExportFormModal>
                <DownloadOptionsModal
                    isexport={true}
                    opened={isModalOpen1}
                    onSubmit={(data) => {
                        downloadPoints(data);
                        setIsModalOpen1(false);
                    }}
                    onCancel={() => {
                        setIsModalOpen1(false);
                    }}
                    isPoints={true}
                ></DownloadOptionsModal>
                <div
                    style={contentBox({marginTop: "1px"})}
                >
                    <Button
                        style={primaryButton({marginBottom: '15px'})}
                        onClick={() => {setIsModalOpen2(true)}}
                        icon={<PlusOutlined />}
                    >
                        Add Export
                    </Button>
                    <Select
                        id={"exportSelect"}
                        style={{ width: "20%", float: "right" }}
                        placeholder="Restrict to investigation"
                        allowClear
                        onChange={(value) => {
                            exportStore.setInvestigation = value;
                        }}
                        onClear={() => {
                            exportStore.setInvestigation = null;
                        }}
                    >
                        {investigationStore.investigationsForUser.map((investigation) => (
                            <Option key={investigation.id} value={investigation.name}>
                                {investigation.name}
                            </Option>
                        ))}
                    </Select>

                    <Input
                        id={"searchDeviceBtn"}
                        style={{ float: "right", width: "20%", marginRight: "1%" }}
                        placeholder="Search .."
                        value={exportStore.searchQuery}
                        onChange={(event) => {
                            const query = event.target.value;
                            exportStore.searchQuery = query;
                        }}
                    />
                    <Table
                        columns={columns}
                        dataSource={toJS(exportStore.exportResult)}
                        pagination={{ pageSize: 10 }}
                        scroll={{ x: 1300 }}
                    />
                </div>
          </Layout>
        </div >
    );
}

export default observer(ExportManagementPage);
