import { Collapse, message, Table, Drawer, Switch, Tooltip, Modal } from "antd";
import React, { useEffect, useState, useContext } from "react";
import scrollIntoView from "scroll-into-view";
import {convertSpeed, getGMTDateTime} from "../../util/CommonUtils";
import { contentBox, primaryButton } from "../../styles";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import AnalysisExportsStore from "../../stores/analysisExport.store";
import permissionStore from "../../stores/permission.store";


const { Panel } = Collapse;

function PointPanel({
    activate,
    points,
    totalPointsCount,
    onPageChange,
    children,
    onPointClick,
    currentlySelectedPoint,
    currentState,
    currentIndex,
    redirectToANewPage,
    redirectToPageOnStepForward,
    redirectToPageOnStepBackward,
    pointsReplayOnPageChange,
    pointsReplayOnPageChangeFromLastPoint,
    pointsPauseOnPlaying,
    speedUnitString,
    showDrawer,
    onClose,
    onExpand,
    visible,
    onUTCChange,
    downloadModelOpenFunction
}) {
    const exportStore = useContext(AnalysisExportsStore);
    const permissionStoreContext = useContext(permissionStore);
    const downloadPoints = async () => {
        if (exportStore.isSet()) {
           exportStore.verifyAndLog('csv')
        } else {
            message.error('Please select a time range and a target to export points');
        }
    };

    const downLoadmodalOpen = () => {
        if (exportStore.isSet()) {
            downloadModelOpenFunction();
         } else {
            message.error('Please select a time range and a target to export points');
         }
    }

    const exportStyle = {
        float: "right",
        cursor: "pointer",
        fontSize: "20px",
        marginTop: "-5px",
    };
    if (permissionStoreContext.can("analysis.export")) {
        exportStyle.visibility = "hidden";
    }
    const isCurrentlySelectedTrip = (trip) => {
    //
    //
        return currentlySelectedPoint && trip.id === currentlySelectedPoint.id;
    };
    useEffect(() => {
        if (currentlySelectedPoint) {
            scrollIntoView(".selected-point", {
                align: {
                    top: 0,
                },
            });
        }
    }, [currentlySelectedPoint]);
    useEffect(() => {
        if (
            currentlySelectedPoint &&
      Object.keys(currentlySelectedPoint).length === 0
        ) {
        } else if (currentlySelectedPoint) {
            const x = document.getElementsByClassName(
                `ant-table-row ${currentlySelectedPoint.id}_class ant-table-row-level-0`
            )[0];

            if (x) {
                x.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [currentlySelectedPoint]);
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (pagination) => {
        setCurrentPage(pagination.current);
        const offset = (pagination.current - 1) * 50;
        onPageChange(offset);
    };
    const handlePageChangeOnRedirection = (pagination) => {
        setCurrentPage(pagination);
        const offset = (pagination - 1) * 50;
        onPageChange(offset);

        setTimeout(() => {
            pointsReplayOnPageChange();
        }, 2000);
    };
    useEffect(() => {
        if (redirectToANewPage === true) {
            handlePageChangeOnRedirection(currentPage + 1);
        }

    // eslint-disable-next-line
  }, [redirectToANewPage]);
    const handlePageChangeOnRedirectionBackwards = (pagination) => {
        setCurrentPage(pagination);
        const offset = (pagination - 1) * 50;
        onPageChange(offset);

        setTimeout(() => {
            pointsReplayOnPageChangeFromLastPoint();
        }, 2000);
    };
    useEffect(() => {
        if (redirectToPageOnStepBackward === true) {
            if (currentPage > 1) {
                handlePageChangeOnRedirectionBackwards(currentPage - 1);
            }
            message.info("No Page to go backward !");
        }
    // eslint-disable-next-line
  }, [redirectToPageOnStepBackward]);
    useEffect(() => {
        if (
            redirectToPageOnStepBackward === true &&
      currentIndex === 49 &&
      currentState === "playing"
        ) {
            pointsPauseOnPlaying();
        }
    // eslint-disable-next-line
  }, [currentState, currentIndex, redirectToPageOnStepBackward]);
    useEffect(() => {
        if (
            redirectToPageOnStepForward === true &&
      redirectToANewPage === true &&
      currentState === "playing" &&
      currentIndex === 0
        ) {
            pointsPauseOnPlaying();
        }
    // eslint-disable-next-line
  }, [
        currentState,
        currentIndex,
        redirectToANewPage,
        redirectToPageOnStepForward,
    ]);
    const columns = [
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            render: (text) => {
                return `${text || "N/A"}`;
            },
        },
        {
            title: "Date",
            dataIndex: "device_utc_date_time",
            key: "device_utc_date_time",
            render: (text, record) => {
                return getGMTDateTime(record.device_utc_date_time, false);
            },
        },
        {
            title: "Latitude",
            dataIndex: "latitude",
            key: "latitude",
            render: (text) => {
                return `${text || "N/A"}`;
            },
        },
        {
            title: "Longitude",
            dataIndex: "longitude",
            key: "longitude",
            render: (text) => {
                return `${text || "N/A"}`;
            },
        },
        {
            title: "Speed",
            dataIndex: "speed",
            key: "speed",
            render: (text, row) => {
                if (row.record_type === "WIFI" || row.record_type === "CELLTOWER") {
                    if (row.in_trip && row.in_trip === 1) {
                        return "In trip";
                    } else {
                        return "Stopped";
                    }
                } else {
                    return  convertSpeed(speedUnitString, row.speed) || 0`${speedUnitString}`;
                }
            },
            visible: false,
        },
        {
            title: "Heading",
            dataIndex: "heading",
            key: "heading",
            render: (text) => {
                return `${text || "0"} ˚`;
            },
        },
    ];
    const _showDrawer = () => {
        showDrawer();
    };
    const _onClose = () => {
        onClose();
    };
    if (!visible) {
        return null
    }
    return (
        <div>
            <Drawer
                title={
                    <div>
                        <h4>Points Analysis <Switch
                        style={{ marginLeft: "10px"}}
                            checkedChildren="Local Time"
                            unCheckedChildren="UTC Time"
                            defaultChecked={localStorage.getItem("utcTime") === "false"}
                            onChange = {(e) => {
                                if (e) {
                                    localStorage.setItem("utcTime", "false")
                                } else {
                                    localStorage.setItem("utcTime", "true")
                                }
                                onUTCChange()
                            }}
                        />
                            <Tooltip title="export current points">
                                <DownloadOutlined
                                    style={exportStyle}
                                    onClick={() => {
                                        downLoadmodalOpen();
                                        //downloadPoints()
                                    }}
                                />
                            </Tooltip>
                        </h4>

                    </div>}
                defaultActiveKey={["trips"]}
                style={{
                    boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                          0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                          0 12.5px 10px rgba(0, 0, 0, 0.06),
                          0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                          0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                          0 100px 80px rgba(0, 0, 0, 0.12)`,

                    marginBottom: "2px",
                    width: "35%",
                    marginLeft: "4rem"
                }}
                placement="left"
                onClose={_onClose}
                visible={visible}
                mask={false}
                bodyStyle={{ background: '#f8f4f4' }}
                headerStyle={{ background: '#f8f4f4' }}
                closeIcon={
                    <ArrowLeftOutlined />
                }
            >
                {children}
            </Drawer>
        </div>
    );
}

export default React.memo(PointPanel);
