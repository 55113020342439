import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    MarkerClusterer,
    InfoBox,
    Circle,
} from "@react-google-maps/api";
// https://stackoverflow.com/questions/46591519/react-google-maps-heatmaplayer-undefined
import getPublicURL from "../../utilities/public-url.utility";
import { Button, Table, Tooltip, Divider } from "antd";
import { macToMAC, mapDefault } from "../../util/CommonUtils";
import { CloseOutlined, ToolTwoTone, StarTwoTone } from "@ant-design/icons";
import "./index.css";
import TimeAgo from "react-timeago";
import EntityDisplayStore from "../../stores/entityDisplay.store";
import {observer} from "mobx-react-lite";
import wifiNetworkStore from "../../stores/wifiNetwork.store";


function WifiNetWorkMap({
    currentlyHiddenAccessPoints,
    data,
    selectedTarget,
    updateAccessPoint,
    accessPointsDevices,
    dragList }) {

    let boxDevices = [];

    const google = window.google;
    const EntityStore = useContext(EntityDisplayStore);
    const wifiStore = useContext(wifiNetworkStore)
    const [located, setLocated] = useState(false);
    const [box, setBox] = useState(null);
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;
        if (located === false) {
            map.panTo({
                lat: crd.latitude,
                lng: crd.longitude,
            });
            setLocated(true);
        }
    }

    const fail = (error) => {
        console.log(`Error: ${error.code}`);

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    navigator.geolocation.getCurrentPosition(success, fail, options);

    if (accessPointsDevices) {
        if (accessPointsDevices.length > 0) {
            boxDevices = accessPointsDevices;
        }
    }
    //(data);
    if (data === null) {
        data = []
    }
    const columns = [
        {title: "Entity", dataIndex: "entityAlias", key: "entityAlias"},
        {title: "Device (Entity)", dataIndex: "alias", key: "alias"},
        {title: "MAC (Device)", dataIndex: "mac_address", key: "mac_address", render: (text) => {
            return macToMAC(text);
        }
        },
        {title: 'Last Seen', dataIndex: 'last_seen', key: 'last_seen', render : (text, record) => {
            return (<TimeAgo date={parseInt(text, 10) * 1000 } />);
        }},
        // { title: "Capture", dataIndex: "capture_type", key: "capture_type", render : (text, record) => {
        //     switch (text) {
        //         case 'CLONE_AND_SNIFF':
        //             return (
        //                 <div>
        //                     <Tooltip title="WiFI Clone Scan">
        //                         <img src={`${getPublicURL()}/clone.png`} width={'20px'} height={'20px'}></img>
        //                     </Tooltip>
        //                     <Divider type="vertical" />
        //                     <Tooltip title="WiFi Sniff Scann">
        //                         <img src={`${getPublicURL()}/sniff.png`} width={'20px'} height={'20px'}></img>
        //                     </Tooltip>
        //                 </div>
        //             )
        //         case 'WIFI_SNIFF_SCAN':
        //             return (
        //                 <div>
        //                     <Tooltip title="WiFi Sniff Scann">
        //                         <img src={`${getPublicURL()}/sniff.png`} width={'20px'} height={'20px'}></img>
        //                     </Tooltip>
        //                 </div>
        //             )
        //         case 'WIFI_CLONE_SCAN':
        //             return (
        //                 <div>
        //                     <Tooltip title="WiFi Clone Scan">
        //                         <img src={`${getPublicURL()}/clone.png`} width={'20px'} height={'20px'}></img>
        //                     </Tooltip>
        //                 </div>
        //             )
        //         default:
        //             return null;
        //     }
        // }},
    ]

    useEffect(() => {
        if (accessPointsDevices) {
            if (accessPointsDevices.length > 0) {
                boxDevices = accessPointsDevices;
            }
        }
    }, [accessPointsDevices]);

    useEffect(() => {
        if (map && selectedTarget) {
            if (selectedTarget.latitude !== null && selectedTarget.longitude !== null) {
                map.panTo({
                    lat: selectedTarget.latitude_adjust || selectedTarget.latitude,
                    lng: selectedTarget.longitude_adjust || selectedTarget.longitude,
                })
            }
        }
    }, [selectedTarget]);
    const [map, setMap] = useState();

    //aliasing edit feature
    const [colour, setColour] = React.useState("");
    const [listItem, setListItem] = React.useState(249);
    const onclick = (record, type="device", accessPoint=null) => {
        EntityStore.setDeviceAndOpen(record.id);
    };

    const mouseIn = (record) => {
        setColour("green");
        setListItem(record.id);
    }

    const mouseOut = () => {
        setColour("");
        setListItem();
    }

    // let fanTargetsArray = [];
    //
    // if (clonedTargets.length > 0) {
    //     clonedTargets.map((clonedTarget) => {
    //         // Get adjusted latlng if specified
    //         const lat = clonedTarget.latitude_adjust ?? clonedTarget.latitude;
    //         const lng = clonedTarget.longitude_adjust ?? clonedTarget.lng;
    //
    //         // Check if location has already been processed
    //         const index = fanTargetsArray.findIndex((element) => (element.lat === lat && element.lng === lng));
    //         if (index < 0) {
    //             // Mark new location to insert a target
    //             fanTargetsArray.push({
    //                 lat: lat,
    //                 lng: lng,
    //                 arr: [clonedTarget],
    //             })
    //         } else {
    //             // Add new target to existing target for processing in fan array
    //             fanTargetsArray[index].arr.push(clonedTarget)
    //         }
    //     });
    // }
    //
    // // Create a fan mapping for targets existing in the same location
    // fanTargetsArray.map((fanTarget) => {
    //
    //     if (fanTarget.arr.length > 1) {
    //         const targetCount = fanTarget.arr.length;
    //         const segments = 360.0 / targetCount;
    //         // Calculate new lat/lng for displaying the device in an aesthetic format
    //         for (let i = 0; i < targetCount; i++) {
    //             const lat = fanTarget.lat + -0.0001000 * Math.cos((segments * i) / 180 * Math.PI);  //x
    //             const lng = fanTarget.lng + -0.0001000 * Math.sin((segments * i) / 180 * Math.PI);  //y
    //             fanTarget.arr[i].latitude_adjust = lat;
    //             fanTarget.arr[i].longitude_adjust = lng;
    //         }
    //     }
    // });

    function cloneClick(accesspoint) {
        wifiStore.addApToSelected(accesspoint)
    }

    function isDraggable(accessPoint) {
        if (accessPoint && dragList) {
            return dragList.findIndex(point => point.id === accessPoint.id) >= 0;
        }
        return false;
    }

    function visabiltyCheck(accessPoint) {
        if (accessPoint && currentlyHiddenAccessPoints) {
            let index = currentlyHiddenAccessPoints.findIndex(point => point.id === accessPoint.id);
            if (index >= 0) {
                return false;
            }
        }
        return true;
    }

    // on drag returns functions so calling stringify resolves the function
    function drag(obj, device) {
        let value = JSON.stringify(obj.latLng);
        let newval = JSON.parse(value);
        updateAccessPoint(device.id, newval.lat, newval.lng);
    }

    return (
        <GoogleMap
            // googleMapURL={googleMapURL}
            zoom={15}
            defaultCenter={mapDefault()}
            yesIWantToUseGoogleMapApiInternals
            options={{ gestureHandling: "auto" }}
            onIdle={() => {

                if (map.getCenter()) {
                    localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                }
            }}
            onLoad={(map) => { setMap(map) }}
            mapContainerStyle={{
                height: "100vh",
                width: "100%"
            }}
        >
            {data.map((target) => {
                return ((target.latitude_adjust && target.longitude_adjust) || (target.latitude && target.longitude)) &&
                    <div>
                        <Marker
                            // clusterer={clusterer}
                            position={{
                                lat: target.latitude_adjust || target.latitude,
                                lng: target.longitude_adjust || target.longitude,
                            }}
                            onClick={() => { cloneClick(target) }}
                            draggable={isDraggable(target)}
                            onDragEnd={(t) => { drag(t, target) }}
                            icon={{
                                url: `${getPublicURL()}/icons8-wifi-64.png`,
                                scaledSize: new google.maps.Size(35, 35),
                                anchor: new google.maps.Point(17.5, 17.5)
                            }}
                            visible={visabiltyCheck(target)}
                            onMouseOver={() => { setBox(target) }}
                            onMouseOut={() => { setBox(null) }}
                        >
                            {box === target &&
                                <InfoWindow
                                    position={{
                                        lat: target.latitude_adjust || target.latitude,
                                        lng: target.longitude_adjust || target.longitude,
                                    }}
                                    visible={box === target.id}
                                    style={{ backgroundColor: "white" }}
                                >
                                    <React.Fragment>
                                        <p>{target.ssid || "No ssid"}</p>
                                        <p>{macToMAC(target.macAddress) || macToMAC(target.mac_address.toString(16))}</p>
                                        <p>{`Connected Devices: ${target.devices.length}`}</p>
                                    </React.Fragment>
                                </InfoWindow>
                            }
                        </Marker>
                    </div>
            })}
            {/*Info boxes for accesspoints*/}
            {
                boxDevices.map((device) => {
                    if (device.macAddress === undefined) {
                        device.macAddress = device.mac_address.toString(16);
                    }
                    return (
                        <div
                            key={device.id}
                            onMouseEnter={() => map.setOptions({ scrollwheel: false, disableDoubleClickZoom: true })}
                            onMouseLeave={() => map.setOptions({ scrollwheel: true, disableDoubleClickZoom: false })}
                        >
                            <InfoBox
                                position={{
                                    lat: device.latitude_adjust || device.latitude,
                                    lng: device.longitude_adjust || device.longitude,
                                }}
                                options={{
                                    enableEventPropagation: false,
                                    pixelOffset: new google.maps.Size(20, 32),
                                    boxStyle: {
                                        borderRadius: '8px',
                                        width: '600px',
                                        boxShadow: '0px 0px 20px 10px #00000044'
                                    }
                                }}
                                onCloseClick={() => {
                                    closelick(device)
                                }}
                            >
                                <div
                                    className={'ap-infobox'}
                                    style={{
                                        backgroundColor: `#ffffff`,
                                        opacity: 0.95,
                                        padding: '12px',
                                    }}>
                                    <div><h3>Access Point</h3></div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <div><strong>{device.alias}</strong><sub>2.4G</sub></div>
                                        <div style={{
                                            height: '1.5em',
                                            width: '1px',
                                            backgroundColor: '#7a7a7a',
                                            margin: '0px 8px'
                                        }}/>
                                        <div>{macToMAC(device.macAddress)}</div>
                                        <div style={{marginLeft: 'auto'}}>
                                            <Button
                                                type={"text"}
                                                icon={<StarTwoTone twoToneColor={"#FFE15D"}/>}
                                                shape={"circle"}
                                                size={"medium"}
                                                onClick={() => {
                                                    onclick(device, "accessPoint")
                                                }}
                                            />
                                            <Button
                                                type={"text"}
                                                icon={<CloseOutlined/>}
                                                shape={"circle"}
                                                size={"medium"}
                                                onClick={() => {
                                                    cloneClick(device)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div style={{fontSize: '16px'}}><strong>Found Devices</strong></div>
                                    <Table
                                        columns={columns}
                                        dataSource={device.devices}
                                        size={"small"}
                                        bordered={true}
                                        pagination={{position: ["none", "none"]}}
                                        scroll={{y: 234}}
                                        onRow={(record) => {
                                            return {
                                                onDoubleClick: () => {
                                                    onclick(record, "device", device);
                                                },
                                                style: {
                                                    padding: '0',
                                                    margin: '0',
                                                    fontSize: '12px',
                                                    backgroundColor: "#ffffff",
                                                },
                                            }
                                        }}

                                    />
                                </div>
                            </InfoBox>
                            <Circle
                                center={{
                                    lat: device.latitude_adjust || device.latitude,
                                    lng: device.longitude_adjust || device.longitude,
                                }}
                                radius={6}
                                options={{
                                    strokeColor: "rgb(15, 142, 232)",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: "rgb(15, 142, 232)",
                                    fillOpacity: 0.1,
                                    clickable: false,
                                    draggable: false,
                                    editable: false,
                                    visible: true,
                                    zIndex: 1,
                                    pixelOffset: new google.maps.Size(100, 100),
                                }}
                            />
                        </div>
                )
                })
            }
        </GoogleMap >
    );
}
export default observer(WifiNetWorkMap);
