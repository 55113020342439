import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CalendarFilled, CalendarOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Modal, Select, Col, Row, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DeviceStore from "../../stores/device.store";
import moment from 'moment';
import { primaryButton, secondaryButton } from "../../styles";
export default function RecoveryModeDialog({
    children: Component,
    device,
    title
}) {
    const [isVisible, setIsVisible] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [recoveryTime, setRecoveryTime] = useState(0);
    const deviceStore = useContext(DeviceStore);
    const [pickingTime, setPickingTime] = useState(false);

    const MAXIMUM_VALUE = Math.round(
        (new Date("January 1, 2030 23:59:59").valueOf() - Date.now()) / 1000
    );
    const { Option } = Select;
    const recoveryModeOptions = [
        { time: 300, name: "Cancel Live Mode" },
        { time: 3600, name: "1 hour" },
        { time: 7200, name: "2 hours" },
        { time: 14400, name: "4 hours" },
        { time: 28800, name: "8 hours" },
        { time: 43200, name: "12 hours" },
        { time: 86400, name: "24 hours" },
        { time: 604800, name: "1 week" },
        {
            time: 242405016,
            name: "Never"
        }
    ];
    const recoveryModeHuntsmanOptions = [
        { value: false, name: "Off" },
        { value: true, name: "On" },
    ];

    const onChildClick = () => {
        setIsVisible(true);
    };

    const ComponentWithHandler = React.cloneElement(Component, {
        onClick: onChildClick
    });
    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };
    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    const currentHour = moment().hour();
    const currentMinute = moment().minute();
    // when date is changed time is still diabled
    const disabledDateTime = () => (
        {
            disabledHours: () => range(0, 0),
            disabledMinutes: () => range(0, 0),
        }
    );
    return (
        <React.Fragment>
            <Modal
                title={title}
                visible={isVisible}
                onOk={() => {
                    if (recoveryTime <= 0) {
                        setIsAlertVisible(true);
                    } else {
                        deviceStore.sendRecoveryModeDetails(device, recoveryTime, device.device_type);
                        deviceStore.getAllDevices();
                        setIsVisible(false);
                        setIsAlertVisible(false);
                        setRecoveryTime(0);
                    }
                }}
                okButtonProps={{style: primaryButton({ width: "47%", right: "2%", borderRadius: "5px", })}}
                cancelButtonProps={{style: secondaryButton({ width: "47%", right: "3%", borderRadius: "5px", })}}
                onCancel={() => {
                    setIsVisible(false);
                    setIsAlertVisible(false);
                    setRecoveryTime(0);
                }}
            >
                {isAlertVisible ? (
                    <Alert
                        message={device.model === 'HUNTSMAN' ? "Live Mode option must be selected" : "Recovery Time should be greater than zero"}
                        description=""
                        type="error"
                        showIcon
                    />
                ) : null}
                <Row>
                    <Col span={20}>
                        {device.model === 'HUNTSMAN' ? (
                            <Form.Item label="Live Mode Enabled">
                                <Select
                                    onSelect={value => {
                                        setRecoveryTime(value);
                                        setIsAlertVisible(false);
                                    }}
                                    allowClear="true"
                                    mode="single"
                                    style={{ width: "100%" }}
                                    placeholder="Enabled / Disabled"
                                >
                                    {recoveryModeHuntsmanOptions.map(recoveryOption => (
                                        <Option key={recoveryOption.value}>{recoveryOption.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            <Form.Item label="Live Mode Expiry Time">
                                <Select
                                    disabled={pickingTime}
                                    onSelect={time => {
                                        setRecoveryTime(time);
                                        setIsAlertVisible(false);
                                    }}
                                    allowClear="true"
                                    mode="single"
                                    style={{ width: "100%" }}
                                    placeholder="Select Live Mode Time"
                                // defaultValue={recoveryTime}
                                >
                                    {recoveryModeOptions.map(recoveryTime => (
                                        <Option key={recoveryTime.time}>{recoveryTime.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Col>
                    <Col span={4}>
                        <Tooltip
                            placement="topLeft"
                            color='grey'
                            title={"Select Exact Time"}
                            style={{
                                borderRadius: "20px",
                                backgroundColor: "grey",
                                color: "black",
                            }}
                        >
                            <Button
                                style={{
                                    marginTop: "32.5%",
                                    display: device.model === 'HUNTSMAN' ? "none" : "block",
                                }}
                                onClick={() => {
                                    setPickingTime(!pickingTime);
                                }}
                            >
                                <CalendarOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <div
                    style={{
                        display: pickingTime ? 'block' : 'none',
                    }}
                >
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        disabledDate={disabledDate}
                        disabledTime={disabledDateTime}
                        showTime={{
                            defaultValue: moment('00:00:00', 'HH:mm:ss'),
                        }}
                        showNow={false}
                        onOk={value => {
                            // console.log(value.unix() - moment().unix());
                            setRecoveryTime(value.unix() - moment().unix());
                        }}
                    />
                </div>
            </Modal>
            {ComponentWithHandler}
        </React.Fragment>
    );
}
