import BaseAPI from "./utilities/request.lib";
const queryString = require("query-string");

const HTTP = BaseAPI();

const ClonedTripCheck = async (targetID, start, finish) => {

    try {
    //const offset = 60 * 30;
        const response = await HTTP.get(`/wifi_device/${targetID}/capture/device?start=${start}&end=${finish}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.clonedCapture;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.resolve([]);
    }

};

const historyDevice = async (id) => {

    try {
        const response = await HTTP.get(`/wifi_device/history/${id}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.history;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getMapDetails = async(id) => {
    try {
        const response = await HTTP.get(`/wifi_device/map/${id}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.history;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

const getDeviceLogDateRange = async ({investigation_id, entity_id}) => {
    let timezone = 0;
    if (localStorage.getItem("utcTime") === "false") {
        timezone = new Date().getTimezoneOffset();
        timezone = -timezone * 60;
    }
    try {
        const response = await HTTP.get(
            `device-log/date-range/entity/?${queryString.stringify({
                investigation_id,
                entity_id,
                timezone
            })}`
        );
        const data = response.data.data.deviceLogDateRange;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

export default {
    ClonedTripCheck,
    historyDevice,
    getMapDetails,
    getDeviceLogDateRange
};
