import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const searchByEntities = async (entityId, investigation, to, from) => {
    try {
        const response = await HTTP.get(`/entity/entityTripsOLD/?id=${entityId}&investigation_id=${investigation}&to=${to}&from=${from}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.trips;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
}

export default searchByEntities;