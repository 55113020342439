import { message } from "antd";
import { Parser } from "json2csv";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";


export class LicenseStore {
    constructor() {
        this.unallocatedLicenses = [];
        this.api = API;
        this.parser = new Parser();
        makeAutoObservable(this);
    }

    async allocateLicense(license_id, device_id) {
        try {
            const obj = {
                license_id: license_id,
                device_id: device_id
            }
            await this.api.license.allocateLicenseToDevice(obj);
            message.success(`License has been Allocated to the device`);
        } catch (exception) {
            message.error(`License could not be allocated.`);
        }
    }

    async getAllUnallocatedLicenses() {
        const response = await this.api.license.getAllUnAllocatedLicenses();
        this.unallocatedLicenses = response;
    }


}

export const store = new LicenseStore();
export default createContext(new LicenseStore());
