import { message } from "antd";
import { Parser } from "json2csv";
import moment from "moment";
import BaseAPI from "./utilities/request.lib";

var fileDownload = require("js-file-download");

const queryString = require("query-string");

const HTTP = BaseAPI();

/**
 * Get all analytics data
 * @param serial
 * @param from
 * @param to
 * @param offset
 * @param keyword
 * @param limit
 * @returns {Promise<*[]>}
 */
const getAllAnalytics = async (serial, from, to, offset, keyword, limit = null) => {
    try {
        let paramObject = {
            serial,
            from: moment(from).startOf("day").unix(),
            to: moment(to).endOf("day").unix(),
            search: keyword,
            offset: offset,
        }
        if(limit){
            paramObject.limit = 1;
        }
        const response = await HTTP.get(
            `/device-analytics/?${queryString.stringify(paramObject)}`
        );
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};



/**
 * Get all huntsman analytics data
 * @param serial
 * @param from
 * @param to
 * @param limit
 * @param lastId
 * @returns {Promise<*[]>}
 */
const getAllHuntsmanAnalytics = async (serial, from, to, limit, lastId = null) => {
    try {
        let paramObject = {
            serial,
            from: moment(from).startOf("day").unix(),
            to: moment(to).endOf("day").unix(),
            limit: limit,
        }

        if(lastId){
            paramObject.lastId = lastId;
        }

        const response = await HTTP.get(
            `/device-analytics/latest/?${queryString.stringify(paramObject)}`
        );
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

/**
 * Download analytics data to a csv file
 * @param data
 * @returns {Promise<void>}
 */
const downloadAnalyticsDump = async (data) => {
    const parser = new Parser();
    if (data.length > 0) {
        const csv = parser.parse(data);
        message.success(`Exported dump to CSV.`);
        fileDownload(csv, `export-${Date.now()}.csv`);
    } else {
        message.error(`No data Available`);
    }
};

const getDeviceLogs = async (serial, from, too) => {
    try {
        const response = await HTTP.get(`/device-analytics/${serial}?from=${from * 1000}&to=${too * 1000}`);
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getHistory = async (serial) => {
    try {
        const response = await HTTP.get(`/device-analytics/${serial}/history`);
        const data = response.data.data;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getAllAnalytics,
    downloadAnalyticsDump,
    getAllHuntsmanAnalytics,
    getDeviceLogs,
    getHistory,
};
