import React, { useEffect, useState } from "react";
import {
    GoogleMap,
    Rectangle,
    Circle,
    Marker, InfoWindow, DrawingManager,
} from "@react-google-maps/api";
import DeviceDetails from "../DeviceDetails";
import { getTargetIcon, mapDefault } from "../../util/CommonUtils";

// https://github.com/google-map-react/google-map-react/issues/102
// https://stackoverflow.com/questions/2325670/draw-polygon-using-mouse-on-google-maps

const GeofenceMap = (function ({
    defaultCenter,
    currentQueryLocation,
    selectedTargetLocationFromLive,
    onPolygonComplete,
    hideTools = false,
    type,
    activeShape,
    setActiveShape,
    rectangleBounds,
    setRectangleBounds,
    center,
    setCenter,
    radius,
    setRadius,
}) {
    const [map, setMap] = useState();
    const [drawingManager, setDrawingManager] = useState();

    const [isOpen, setIsOpen] = useState(false);

    const [located, setLocated] = useState(false);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }


    const success = (pos) => {
        const crd = pos.coords;

        if (located === false) {
            map.panTo({
                lat: crd.latitude,
                lng: crd.longitude,
            });
            setLocated(true);
        }
    }

    const fail = (error) => {
        console.log(`Error: ${error.code}`);

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    navigator.geolocation.getCurrentPosition(success, fail, options);

    const _onPolygonComplete = (polygon) => {
        const coordinates = [];

        for (var i = 0; i < polygon.getPath().getLength(); i++) {
            const splitArray = polygon.getPath().getAt(i).toUrlValue(6).split(",");

            let latLag = [parseFloat(splitArray[1]), parseFloat(splitArray[0])];
            coordinates.push(latLag);
        }

        // Make sure that the last record is the same as the first record for the polygon to work.
        coordinates.push(coordinates[0]);

        return onPolygonComplete({
            type: "Polygon",
            coordinates: [coordinates],
        });
    };

    const rectangleDraw = (e) => {
        e.setMap(null);
        setActiveShape(type);
        setRectangleBounds({
            north: e.bounds.getNorthEast().lat(),
            south: e.bounds.getSouthWest().lat(),
            east: e.bounds.getNorthEast().lng(),
            west: e.bounds.getSouthWest().lng(),
        });
    }

    const circleDraw = (e) => {
        e.setMap(null);
        setActiveShape(type);
        setCenter(e.center)
        setRadius(e.radius)
    }

    const closeInfoWindow = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        if (currentQueryLocation) {
            map.panTo(currentQueryLocation);
        }
    }, [currentQueryLocation]);

    useEffect(() => {
        if (drawingManager) {
            drawingManager.setDrawingMode(null);
            switch (type) {
                case "circle":
                    drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.CIRCLE);
                    break;
                case "cancel":
                    drawingManager.setDrawingMode(null);
                    setActiveShape(null);
                    break;
                case "rectangle":
                    drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.RECTANGLE);
                    break;
                default:
                    break;
            }
        }
    }, [type]);

    useEffect(() => {
        if (drawingManager) {
            if (activeShape) {
                drawingManager.setDrawingMode(null);
            }
        }
    }, [activeShape]);

    useEffect(() => {
        if (map) {
            if (activeShape === "rectangle") {
                map.fitBounds(rectangleBounds);
            } else {
                if (radius !== 0) {
                    try {
                        map.fitBounds({ north: center.lat() + (radius / 111000), south: center.lat() - (radius / 111000), east: center.lng() + (radius / 111000), west: center.lng() - (radius / 111000) });
                    } catch (error) {
                        map.fitBounds({ north: center.lat + (radius / 111000), south: center.lat - (radius / 111000), east: center.lng + (radius / 111000), west: center.lng - (radius / 111000) });
                    }
                }
            }
        }
    }, [radius, center, rectangleBounds]);

    return (
        <React.Fragment>
            <GoogleMap
                zoom={15}
                defaultCenter={mapDefault()}

                onIdle={() => {

                    if (map.getCenter()) {
                        localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                    }
                }}
                mapContainerStyle={{
                    height: "100%",
                    width: "100%",
                }}
                onLoad={(map) => { setMap(map) }}
            >
                {!hideTools && (
                    <div style={{ display: 'none' }}>
                        <DrawingManager
                            onLoad={(drawingManager) => { setDrawingManager(drawingManager) }}
                            onPolygonComplete={_onPolygonComplete}
                            onRectangleComplete={rectangleDraw}
                            onCircleComplete={circleDraw}
                            options={{
                                drawingControl: false,
                            }}
                            style={{ display: "none" }}
                        />
                    </div>
                )}

                {currentQueryLocation && (
                    <Marker
                        // animation={window.google.maps.Animation.BOUNCE}
                        position={{
                            lat: currentQueryLocation.lat,
                            lng: currentQueryLocation.lng,
                        }}
                    >

                    </Marker>
                )}

                {
                    selectedTargetLocationFromLive && (
                        <Marker
                            // animation={window.google.maps.Animation.BOUNCE}
                            position={{
                                lat: Number(selectedTargetLocationFromLive.latitude),
                                lng: Number(selectedTargetLocationFromLive.longitude),
                            }}
                            clickable={true}

                            options={{
                                icon: {
                                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getTargetIcon(selectedTargetLocationFromLive.colour)),
                                    scaledSize: new window.google.maps.Size(25, 25)
                                }
                            }}
                            cursor="pointer"
                            onClick={() => {
                                setIsOpen(true)
                            }}
                        >

                            {
                                isOpen && (
                                    <InfoWindow onCloseClick={closeInfoWindow}>
                                        <DeviceDetails
                                            isTestLiveMap={false}
                                            color={"#ffffff"}
                                            item={selectedTargetLocationFromLive}
                                        />
                                    </InfoWindow>
                                )
                            }

                        </Marker>
                    )
                }

                {
                    activeShape === 'rectangle' && (
                        <Rectangle
                            bounds={
                                rectangleBounds
                            }
                        />
                    )
                }
                {
                    activeShape === 'circle' && (
                        <Circle
                            center={center}
                            radius={radius}
                        />
                    )
                }

            </GoogleMap>
        </React.Fragment>
    );
});

export default GeofenceMap;