import {makeAutoObservable} from "mobx";
import {createContext} from "react";
// Services
import API from "../services/api";
import {message} from "antd";

export class NotificationStore {

    constructor() {
        this.settings = [];
        this.notificationPreferences = [];
        this.receivedAdminBulletin = [];
        this.receivedAdminBulletinList = [];
        this.allAdminBulletins = [];
        this.dashboardAdminBulletins = [];
        this.lightsensorNot = [];
        this.deviceType = null;

        this.StartMovement = [];
        this.StopMovement = [];
        this.GeoIN = [];
        this.GeoOUT = [];
        this.battery = [];
        this.tamper = [];
        this.warrants = [];
        this.geoFenceDatas = [];

        this.CloneCapture = [];

        this.breakwire1 = [];
        this.breakwire2 = [];
        this.lightsensor = [];
        this.breakwire1Falling = [];
        this.breakwire2Falling = [];
        this.lightNotDetected = [];

        this.status0 = [];
        this.status1 = [];
        this.status2 = [];
        this.status3 = [];
        this.status4 = [];
        this.status5 = [];
        this.status6 = [];

        this.tableStructure = [];
        this.deviceStructure = [];

        this.api = API;
        makeAutoObservable(this);
    }

    setDeviceType(type) {
        this.deviceType = type;
    }

    setSocketData(data) {
        this.receivedAdminBulletin = []
        this.receivedAdminBulletin.push(data)
        this.receivedAdminBulletinList.push(data);
    }

    async getNotificationSettings() {
        this.notificationPreferences = await this.api.notification.getNotificationSettings();
        // this.notificationPreferences = {
        //     sms: true,
        //     email: true,
        //     web: false
        // }
    }

    async saveNotification(settings) {
        try {
            await API.notification.updateNotificationSettings(settings);
            await this.getNotificationSettings();
            message.info("Your notifications have been successfully updated.");
        } catch (e) {
            message.error(`${e.response.data.message}`);
        }
    }

    setReceivedAdminBulletin() {
        this.receivedAdminBulletin = [];
    }

    setReceivedAdminBulletinList() {
        this.receivedAdminBulletinList = [];
    }

    async getAllAdminBulletins() {
        const response = await this.api.adminBulletin.getAdminBulletin();
        this.allAdminBulletins = response.adminBulletin;
    }

    async getDashboardAdminBulletins() {
        const response = await this.api.adminBulletin.getDashboardAdminBulletin();
        const newArrayOfObj = response.adminBulletin.map(({
            created_at: timestamp,
            name: sender,
            ...rest
        }) => ({
            timestamp,
            sender,
            ...rest
        }));
        this.dashboardAdminBulletins = newArrayOfObj.map((x) => {
            x.sender = `Admin Bulletin - ${x.subject} (${x.sender})`
            return x
        });
    }


    // ============ NEW NOTIFICATION ============

    get deviceStructureCOMP() {
        return this.deviceStructure
    }

    get tableStructureCOMP() {
        return this.tableStructure

    }
    updateTableStrcuture(children) {
        //this.tableStructure = null;
        this.tableStructeSetup();
        this.deviceStructureSetup();
        this.tableStructure.push({
            alert_name: "Geo Fence IN", Users: this.GeoIN, children: children.filter((fence) => {
                if (fence.type === "in") {
                    return true
                }
                return false
            })
        });
        this.tableStructure.push(
            {
                alert_name: "Geo Fence OUT", Users: this.GeoOUT, children: children.filter((fence) => {
                    if (fence.type === "out") {
                        return true
                    }
                    return false
                })
            }
        );
    }

    deviceStructureSetup() {
        switch (this.deviceType) {
            case "CCAT":
                this.deviceStructure = [
                    { alert_name: "Break Wire 1 Alarming", Users: this.breakwire1 },
                    { alert_name: "Break Wire 2 Alarming", Users: this.breakwire2 },
                    { alert_name: "Break Wire 1 Not Alarming", Users: this.breakwire1Falling },
                    { alert_name: "Break Wire 2 Not Alarming", Users: this.breakwire2Falling },
                    { alert_name: "Light Detected", Users: this.lightsensor },
                    { alert_name: "Light Not Detected", Users: this.lightsensorNot },
                ]
                break;
            case "ATS_JSON":
                this.deviceStructure = [
                    { alert_name: "Status0", Users: this.status0 },
                    { alert_name: "Status1", Users: this.status1 },
                    { alert_name: "Status2", Users: this.status2 },
                    { alert_name: "Status3", Users: this.status3 },
                    { alert_name: "Status4", Users: this.status4 },
                    { alert_name: "Status5", Users: this.status5 },
                    { alert_name: "Status6", Users: this.status6 },
                ];
                break;
        }
    }

    tableStructeSetup() {
        this.tableStructure = [
            { alert_name: "Start Movement", Users: this.StartMovement },
            { alert_name: "Stop Movement", Users: this.StopMovement },
            { alert_name: "Battery Low", Users: this.battery },
            { alert_name: "Tamper Alert", Users: this.tamper },
            { alert_name: "Warrant Alert", Users: this.warrants },

        ];
    }

    async getNotificationStreams(target_id) {
        this.tableStructure = [];
        await this.api.notificationStreams.getSubscriptionStreamsByTarget(target_id).then((response) => {
            console.log(response);
            this.StartMovement = response.Startmovement || [];
            this.StopMovement = response.Stopmovement || [];
            this.GeoIN = response.GeoFenceIN || [];
            this.GeoOUT = response.GeoFenceOUT || [];
            this.battery = response.BatteryWarning || [];
            this.tamper = response.TamperAlert || [];
            this.warrants = response.WarrantAlert || [];
            this.CloneCapture = response.CloneCapture || [];
            this.breakwire1 = response["BreakWire1 Alarming"] || [];
            this.breakwire2 = response["BreakWire2 Alarming"] || [];
            this.breakwire1Falling = response["BreakWire1 Not Alarming"] || [];
            this.breakwire2Falling = response["BreakWire2 Not Alarming"] || [];
            this.lightsensor = response.LightSensor || [];
            this.lightsensorNot = response["LightNot Detected"] || [];
            this.status0 = response.Status0 || [];
            this.status1 = response.Status1 || [];
            this.status2 = response.Status2 || [];
            this.status3 = response.Status3 || [];
            this.status4 = response.Status4 || [];
            this.status5 = response.Status5 || [];
            this.status6 = response.Status6 || [];
        });
        await this.api.geofence.getBytargetId(target_id).then((children) => {
            this.geoFenceDatas = children;
            // this.updateTableStrcuture(children);
            this.tableStructeSetup();
            this.deviceStructureSetup();
            this.tableStructure.push({
                alert_name: "Geo Fence IN", Users: this.GeoIN, children: children.filter((fence) => {
                    if (fence.type === "in") {
                        return true
                    }
                    return false
                })
            });
            this.tableStructure.push(
                {
                    alert_name: "Geo Fence OUT", Users: this.GeoOUT, children: children.filter((fence) => {
                        if (fence.type === "out") {
                            return true
                        }
                        return false
                    })
                }
            );
        });
    }

    async updatestream(target_id, investigation_id) {
        const streams = [
            { outType: "Start movement", userArray: this.StartMovement },
            { outType: "Stop movement", userArray: this.StopMovement },
            { outType: "GeoFence IN", userArray: this.GeoIN },
            { outType: "GeoFence OUT", userArray: this.GeoOUT },
            { outType: "Battery Warning", userArray: this.battery },
            { outType: "Tamper Alert", userArray: this.tamper },
            { outType: "Warrant Alert", userArray: this.warrants },
            { outType: "Break Wire1 Alarming", userArray: this.breakwire1 },
            { outType: "Break Wire2 Alarming", userArray: this.breakwire2 },
            { outType: "Break Wire1 Not Alarming", userArray: this.breakwire1Falling },
            { outType: "Break Wire2 Not Alarming", userArray: this.breakwire2Falling },
            { outType: "Light Detected", userArray: this.lightsensor },
            { outType: "Light Not Detected", userArray: this.lightsensorNot },
            { outType: "Clone Capture", userArray: this.CloneCapture },
            { outType: "Status0", userArray: this.status0 },
            { outType: "Status1", userArray: this.status1 },
            { outType: "Status2", userArray: this.status2 },
            { outType: "Status3", userArray: this.status3 },
            { outType: "Status4", userArray: this.status4 },
            { outType: "Status5", userArray: this.status5 },
            { outType: "Status6", userArray: this.status6 },
        ];
        streams.forEach((stream) => {
            console.log(stream);
            const outArray = stream.userArray?.map((x) => x.userID);
            this.api.notificationStreams.updateStreams({
                type: stream.outType,
                target_id: target_id,
                investigation_id: investigation_id,
                user_ids: outArray
            }).then((response) => {
                console.log(response);
            });
        });
        this.geoFenceDatas.forEach((fence) => {
            if (!fence.Users) {
                fence.Users = [];
            }
            const outArray = fence.Users.map((x) => x.userID);
            this.api.notificationStreams.updateStreams({
                type: fence.alert_name,
                target_id: target_id,
                investigation_id: investigation_id,
                user_ids: outArray
            }).then((response) => {
                console.log(response);
            });
        });
    }
}

export default createContext(new NotificationStore());
