import { observer } from "mobx-react-lite";
import AppLayout from "../AppLayout";
import { useState, useEffect, useContext } from "react";
import { Spin, Button} from "antd";
import { primaryButton } from "../../styles";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import FencePanel from "../../components/AnaylsisFencePanel";
import AnalysisFenceMap from "../../components/AnalysisFenceMap";
// /home/marshall/Atrax/ATRAX-Frontend/src/components/AnaylsisFencePanel
function AnalysisArea(props){
    const [showStopsMap, setShowStopsMap] = useState(false);
    const [visible, setVisible] = useState(true);
    const [buttonMargin, setButtonMargin] = useState("32%");
    const [buttonOpacity, setButtonOpacity] = useState(1);
    const [margin, setMargin] = useState("33%");

    useEffect(() => {

        const timer = setInterval(() => {
            if (window.google.maps.SymbolPath) {
                setShowStopsMap(true);
            }
        }, 20);

        if (showStopsMap) {
            clearInterval(timer);
        }
    }, []);

    const showDrawer = () => {
        setButtonOpacity('1.0');
        setButtonMargin('32%');
        setVisible(true);
        setMargin("33%");

    };
    const onClose = () => {
        setVisible(false);
        setMargin('0px');
        setButtonMargin('0px');
    };

    const setHover = () => {
        setButtonOpacity('0.7');
        // setButtonMargin('-5px');
    }

    const unSetHover = () => {
        setButtonOpacity('1.0');
        // setButtonMargin('-3px');
    }

    return (
        <div>
            <AppLayout showNavigation hideMargins>
                {showStopsMap === false && (
                    <div
                        style={{
                            textAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20%",
                        }}
                    >
                        <Spin></Spin>
                    </div>
                )}

                {showStopsMap === true && (
                    <div>
                        <div style={{
                            position: "fixed",
                            zIndex: 2,
                            width: "100%"
                        }}>
                            < Button type="primary" onClick={visible ? onClose : showDrawer}
                                style={primaryButton({
                                    position: "absolute",
                                    top: "40vh",
                                    zIndex: 2,
                                    height: "15vh",
                                    marginTop: "0%",
                                    left: buttonMargin,
                                    marginLeft: "2px",
                                    borderLeftColor: "#0066CC",
                                    marginBottom: "2px",
                                    opacity: buttonOpacity,
                                })}
                                onMouseEnter={() => { setHover() }}
                                onMouseLeave={() => { unSetHover() }}
                            >
                                {
                                    visible
                                        ?
                                        <ArrowLeftOutlined
                                            width={25}
                                            height={25}
                                        />
                                        :
                                        <ArrowRightOutlined
                                            width={25}
                                            height={25}
                                        />
                                }
                            </Button>
                            <FencePanel
                                visible={visible}
                                onClose={onClose}
                            />
                        </div>
                    </div>
                )}
                {showStopsMap === true && (
                    <div style={{ marginLeft: margin }} >
                        <AnalysisFenceMap />
                    </div>
                )}
            </AppLayout>
        </div>
    )
}

export default observer(AnalysisArea);