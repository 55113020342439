import { action, makeAutoObservable, observable, makeObservable } from "mobx";
import { createContext } from "react";
import API from "../services/api";

export class AlertStore {
    constructor() {
        this.alerts = [];
        this.alertTypes = [];
        makeAutoObservable(this);
        this.api = API;
    }

    async getAlerts() {
        const response = await this.api.alerts.getAllAlerts();
        this.alerts = response;
    }

    async getAlertTypes() {
        const response = await this.api.alerts.getAlertTypes();
        this.alertTypes = response;
    }

    async createAlert(object) {
        const response = await this.api.alerts.createAlert(object);
        return response;
    }
    async updateAlert(id, object) {
        console.log(id, object)
        const response = await this.api.alerts.updateAlert(id, object);
        return response;
    }
    async deleteAlert(id) {
        const response = await this.api.alerts.deleteAlert(id);
        return response;
    }
}

export default createContext(new AlertStore());
