import { toJS } from "mobx";
import { useContext, useEffect, useState } from "react";
import API from "../services/api";
import UserStore from "../stores/user.store";

const useInvestigationForUser = () => {
    const [investigationsForUser, setInvestigationsForUser] = useState([]);
    const [targetsForInvestigation, setTargetsForInvestigation] = useState([]);
    const [usersForInvestigation, setUsersForInvestigation] = useState([]);
    const [selectedInvestigation, setSelectedInvestigation] = useState(undefined);
    const [targetNameMappingList, setTargetNameMappingList] = useState({});
    const [selectedTarget, setSelectedTarget] = useState(undefined);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedTargetName, setSelectedTargetName] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const userStore = useContext(UserStore);

    // Load Investigations for User on mount
    useEffect(() => {
        setIsLoading(true);
        API.investigation.getInvestigationsForUser().then((investigations) => {
            setIsLoading(false);
            setInvestigationsForUser(investigations);
        });
    }, [selectedInvestigation]);

    // Load investigations based on selectedInvestigation change
    useEffect(() => {
        setIsLoading(true);
        if (selectedInvestigation) {
            API.target
                .getAllTargetsByInvestigation(selectedInvestigation)
                .then((targetsForInvestigation) => {
                    setIsLoading(false);
                    //let targetArr = []
                    let targetObj = {}
                    for (let target of targetsForInvestigation) {
                        for (const [key, value] of Object.entries(target)) {
                            if(key === 'id'){
                                targetObj[value] = target.name
                            }
                        }
                    }
                    setTargetNameMappingList(targetObj)
                    setTargetsForInvestigation(targetsForInvestigation);
                });
        }
    }, [selectedInvestigation]);

    // Load Users based on Selected Investigation
    useEffect(() => {
        setIsLoading(true);
        API.investigation
            .getAllUsersForSelectedInvestigation(selectedInvestigation)
            .then((usersForInvestigation) => {
                setIsLoading(false);

                if (
                    usersForInvestigation &&
          usersForInvestigation.length > 0 &&
          usersForInvestigation[0].user_id &&
          usersForInvestigation[0].user_id.length > 0
                ) {
                    const filtered = userStore.users.filter((user) =>
                        usersForInvestigation[0].user_id.includes(user.id)
                    );
                    setUsersForInvestigation(toJS(filtered));
                }
            });
    // eslint-disable-next-line
  }, [selectedInvestigation]);

    return [
        {
            isLoading,
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            selectedTargetName,
            usersForInvestigation,
            selectedUser,
            targetNameMappingList
        },
        setSelectedInvestigation,
        setSelectedTarget,
        setSelectedTargetName,
        setSelectedUser,
    ];
};

export default useInvestigationForUser;
