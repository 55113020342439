import {
  EditOutlined,
  HomeOutlined,
  KeyOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Button, Input } from "antd";
import React from "react";
import "./RequestAccessForm.scss";

const { TextArea } = Input;

class RequestAccessForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);

      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="access-form">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Client Code">
            {getFieldDecorator("clientCode", {
              rules: [
                { required: true, message: "Your client code is required." }
              ]
            })(
              <Input
                prefix={
                  <HomeOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Your client code"
              />
            )}
          </Form.Item>
          <Form.Item label="Full Name">
            {getFieldDecorator("fullName", {
              rules: [{ required: true, message: "Your name is required." }]
            })(
              <Input
                prefix={
                  <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Your full name"
              />
            )}
          </Form.Item>
          <Form.Item label="Email Address">
            {getFieldDecorator("emailAddress", {
              rules: [
                { required: true, message: "An email address is required." }
              ]
            })(
              <Input
                prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Your email address"
              />
            )}
          </Form.Item>
          <Form.Item label="Mobile Number">
            {getFieldDecorator("mobileNumber", {
              rules: [
                { required: true, message: "Your mobile number is required." }
              ]
            })(
              <Input
                prefix={
                  <PhoneOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Your mobile number"
              />
            )}
          </Form.Item>
          <Form.Item label="Alias">
            {getFieldDecorator("alias", {
              rules: [{ required: true, message: "An alias is required." }]
            })(
              <Input
                prefix={
                  <LinkOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Your alias"
              />
            )}
          </Form.Item>
          <Form.Item label="Notes">
            {getFieldDecorator("notes", {})(
              <TextArea
                autosize={{ minRows: 3, maxRows: 5 }}
                prefix={
                  <EditOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Explain why you require access."
              />
            )}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="access-form-button"
          >
            Continue
          </Button>
          <Button
            type="link"
            block
            onClick={() => {
              this.props.onBack();
            }}
          >
            Go back
          </Button>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(RequestAccessForm);
