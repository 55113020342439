import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Modal, Select } from "antd";
import React from "react";
import TimeAgo from "timeago-react";
import StatusDot from "../StatusDot";
import moment from "moment";
import { secondaryButton, primaryButton } from '../../styles';

const { Option } = Select;

class TargetForm extends React.Component {
    state = {
        loading: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                values.assign_at = values.assign_at ? values.assign_at.unix() : "";
                values.id = this.props.data.id;
                if (this.props.mode === "add") {
                    values.investigation_id = this.props.investigationId;
                }
                this.props.onSubmit({ values, mode: this.props.mode });
                this.props.form.resetFields();
            }
        });
    };

    onCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, mode } = this.props;

        const colors = [
            { name: 'Red', value: '#C0392B' },
            { name: 'Blue', value: '#0000FF' },
            { name: 'Purple', value: '#6a0dad' },
            { name: 'Green', value: '#008000' },
            { name: 'Orange', value: '#FFA500' }
        ]

        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Modal
                    title={mode === "add" ? `Add Target To Investigation: ${this.props.investigation}` : "Edit Target"}
                    visible={this.props.opened}
                    onOk={this.onUserSubmit}
                    confirmLoading={false}
                    onCancel={() => {
                        this.props.form.resetFields();
                        this.props.onCancel();
                    }}
                    footer={[
                        <Button style={secondaryButton()} key="back" onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <Button
                            loading={this.state.loading}
                            onClick={this.handleSubmit}
                            key="submit"
                            style={primaryButton()}
                        >
                            {mode === "add" ? "Add Target" : "Edit Target"}
                        </Button>,
                    ]}
                >
                    {mode === "edit" &&
                        <Form.Item label="Investigation">
                            {getFieldDecorator("investigation_id", {
                                rules: [
                                    { required: true, message: "Please select an investigation!" },
                                ],
                                initialValue:
                                    mode === "edit" && data ? data.investigation_id : null,
                            })(
                                <Select
                                    showSearch
                                    allowClear="true"
                                    style={{ width: "100%" }}
                                    placeholder="Investigation"
                                >
                                    {this.props.investigations.map((investigation) => (
                                        <Option key={investigation.id} value={investigation.id}>
                                            {investigation.name}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    }

                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            rules: [{ required: true, message: "Please input a name!" }],
                            initialValue: mode === "edit" && data ? data.name : "",
                        })(
                            <Input
                                prefix={
                                    <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                type="text"
                                placeholder="Name"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Device">
                        {getFieldDecorator("device_id", {
                            initialValue:
                                mode === "edit" && data && data.device_id ? data.device_id : "",
                        })(
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Device"
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[{id: "",
                                        name: "No Device",} , ...this.props.devices].map((device) => ({
                                    value: device.id,
                                    label: device.name,
                                    disabled: !device.unassigned && device.id !== "",
                                }))
                                }
                            >

                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="Assigned Date">
                        {getFieldDecorator("assign_at", {
                            rules: [{ required: false }],
                            initialValue: mode === "edit" && data && data.assign_at ? moment(moment(data.assign_at * 1000).format("YYYY-MM-DD HH:mm:ss")) : null,
                        })(
                            <DatePicker showTime={true} style={{ width: "100%" }} format={"YYYY-MM-DD HH:mm:ss"} />
                        )}
                    </Form.Item>
                    {!data.device_id && (
                        <span>Target does not have a device assigned yet.</span>
                    )}
                    <Form.Item label="Colour">
                        {getFieldDecorator("colour", {
                            rules: [
                                { required: true, message: "Please select a colour!" },
                            ],
                            initialValue:
                                mode === "edit" && data ? data.colour : null,
                        })(
                            <Select
                                showSearch
                                allowClear="true"
                                style={{ width: "100%" }}
                                placeholder="Colour"
                            >
                                {colors.map((color) => (
                                    <Option key={color.value} value={color.value}>
                                        {color.name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Warrants"
                    >
                        {getFieldDecorator("warrant", {
                            rules: [{ required: false }],
                            initialValue: mode === "edit" && data && data.warrant ? data.warrant : [],
                        })(
                            <Select
                                mode='multiple'
                                style={{ width: "100%" }}
                            >
                                {
                                    this.props.warrants.map((warrant) => (
                                        <Option key={warrant.id} value={warrant.id}>
                                            {warrant.warrantNoRef}
                                        </Option>
                                    ))
                                }
                            </Select>
                        )}
                    </Form.Item>
                </Modal>
            </Form>
        );
    }
}

export default Form.create({})(TargetForm);
