import { GoogleMap, Marker, InfoWindow, HeatmapLayer, DrawingManager, Rectangle, Polyline } from "@react-google-maps/api";
import React, { useState, useEffect, useContext } from "react";
import { mapDefault, convertSpeed, getGMTDateTime } from "../../util/CommonUtils";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import analysisFenceLookUpStore from "../../stores/AnalysisFence.store";

function AnalysisFenceMap(props) {
    const [investigationsForUser, selectedInvestigation] = useInvestigationForUser();
    const AnalysisFenceLookUpStore = useContext(analysisFenceLookUpStore);
    const [map, setMap] = useState(null);
    const [located, setLocated] = useState(false);
    const [displayed, setDisplayed] = useState([]);
    const [speedUnitString, setSpeedUnitString] = useState("KPH");
    const [drawingManager, setDrawingManager] = useState();
    const [rectangleBounds, setRectangleBounds] = useState();
    const hideTools = true;
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const setActiveShape = (type) => {
        console.log("type", type)
    };

    const success = (pos) => {
        const crd = pos.coords;
        if (located === false) {
            if (map) {
                map.panTo({
                    lat: crd.latitude,
                    lng: crd.longitude,
                });
                setLocated(true);
            }
        }
    }

    const fail = (error) => {
        console.log(`Error: ${error.code}`);

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    const rectangleDraw = (e) => {
        e.setMap(null);
        setRectangleBounds({
            north: e.bounds.getNorthEast().lat(),
            south: e.bounds.getSouthWest().lat(),
            east: e.bounds.getNorthEast().lng(),
            west: e.bounds.getSouthWest().lng(),
        });
        AnalysisFenceLookUpStore.setFenceBounds({
            north: e.bounds.getNorthEast().lat(),
            south: e.bounds.getSouthWest().lat(),
            east: e.bounds.getNorthEast().lng(),
            west: e.bounds.getSouthWest().lng(),
        });
        drawingManager.setDrawingMode(null);
    }

    useEffect(() => {
        if (drawingManager) {
            console.log("action", AnalysisFenceLookUpStore.action)
            switch (AnalysisFenceLookUpStore.action) {
                case 'Clear':
                    setRectangleBounds(null);
                    drawingManager.setDrawingMode(null);
                    AnalysisFenceLookUpStore.setFenceBounds(null);
                    AnalysisFenceLookUpStore.selectedEvent = null;
                    AnalysisFenceLookUpStore.clearTablePoints();
                    break;
                case "Draw":
                    console.log("draw")
                    drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.RECTANGLE);
                    break;
                default:
                    drawingManager.setDrawingMode(null);
                    break;
            }
        }
        console.log("action", AnalysisFenceLookUpStore.action)
    }, [AnalysisFenceLookUpStore.action]);

    navigator.geolocation.getCurrentPosition(success, fail, options);

    useEffect(() => {
        if (map) {
            if (rectangleBounds) {
                map.fitBounds(rectangleBounds);
            }
        }
    }, [rectangleBounds]);

    return (
        <GoogleMap
            defaultCenter={
                mapDefault()
            }
            yesIWantToUseGoogleMapApiInternals
            mapContainerStyle={{
                height: "100vh",
                width: "100%"
            }
            }
            onIdle={() => {

                if (map.getCenter()) {
                    localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                }
            }}
            zoom={15}
            onLoad={map => setMap(map)}
        >
            {true && (
                    <div>
                        <DrawingManager
                            onLoad={(DrawingManager) => { setDrawingManager(DrawingManager) }}
                            onRectangleComplete={rectangleDraw}
                            options={{
                                drawingControl: false,
                            }}
                        />
                    </div>
            )}


            <HeatmapLayer
                data={AnalysisFenceLookUpStore.displayHistory.map((point, index) => {
                    return (
                        {
                            location: new google.maps.LatLng(point.latitude, point.longitude),
                            weight: point.speed
                        }
                    )
                })}
                options={{
                    radius: 20,
                    opacity: 0.6,
                    dissipating: true,
                    gradient: [
                        'rgba(0, 255, 255, 0)',
                        'rgba(0, 255, 255, 1)',
                        'rgba(0, 191, 255, 1)',
                        'rgba(0, 127, 255, 1)',
                        'rgba(0, 63, 255, 1)',
                        'rgba(0, 0, 255, 1)',
                        'rgba(0, 0, 223, 1)',
                        'rgba(0, 0, 191, 1)',
                        'rgba(0, 0, 159, 1)',
                        'rgba(0, 0, 127, 1)',
                        'rgba(63, 0, 91, 1)',
                        'rgba(127, 0, 63, 1)',
                        'rgba(191, 0, 31, 1)',
                        'rgba(255, 0, 0, 1)'
                    ]
                }}
            />

            {
                AnalysisFenceLookUpStore.selectedEvent && (
                    AnalysisFenceLookUpStore.generatePoint()
                )
            }
            {
                AnalysisFenceLookUpStore.tableEvents && (
                    AnalysisFenceLookUpStore.GenerateTablePoints()
                )
            }
            {
                rectangleBounds && (
                    <Rectangle
                        bounds={
                            rectangleBounds
                        }
                    />
                )
            }

        </GoogleMap>
    );
}

export default observer(AnalysisFenceMap);