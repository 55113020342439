import { message } from 'antd';

import { action, computed, observable, makeAutoObservable } from 'mobx';
import { createContext } from 'react';

import { Parser } from "json2csv";

const parser = new Parser();
const fileDownload = require("js-file-download");


const uniqBy = require("lodash.uniqby");

import API from '../services/api';
import moment from 'moment';

export class AnalysisExportsStore {
    constructor() {
        this.api = API;
        this.startTime = null;
        this.endTime = null;
        this.target_id = null;
        this.investigation_id = null;

        // used for export page where we have id's stored
        this.exportID = null;
        this.exportName = null;
        this.exports = [];
        this.searchQuery = "";
        this.setInvestigation = null;
        makeAutoObservable(this);
    }

    updateStartTime(startTime) {
        this.startTime = startTime;
    }

    updateEndTime(endTime) {
        this.endTime = endTime;
    }

    updateTargetId(target_id) {
        this.target_id = target_id;
    }

    updateInvestigationId(investigation_id) {
        this.investigation_id = investigation_id;
    }

    isSet() {
        if (this.exportID !== null) {
            return true;
        }
        return this.startTime !== null && this.endTime !== null && this.target_id !== null && this.investigation_id !== null && this.startTime !== 0 && this.endTime !== 0;
    }

    downloadFile(data, fileName, fileType) {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            data
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = fileName;
        link.click();
    }

    convertToGeoJSON(points) {
        const geoJSON = {
            type: "FeatureCollection",
            features: []
        };

        points.forEach((point) => {
            geoJSON.features.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [point.longitude, point.latitude]
                },
                properties: {
                    name: point.target_name,
                    description: point.description,
                    timestamp: point.device_utc_date_time,
                    altitude: point.altitude,
                    accuracy: point.accuracy,
                    speed: point.speed,
                    bearing: point.heading
                }
            });
        });

        return geoJSON;
    }

    GeoJSONToKML(geoJSON) {
        // function for converting geojson into a kml string for download
        const header = `<?xml version="1.0" encoding="UTF-8"?>\n`;
        let kml = `<kml xmlns="http://www.opengis.net/kml/2.2">\n`;
        kml += `<Document>\n`;
        kml += `<name>Exported Points</name>\n`;
    //     kml += `<gx:CascadingStyle kml:id="__managed_style_1A62B2882D25FB931C6E">\n
	// 	<Style>\n
	// 		<IconStyle>\n
	// 			<Icon>\n
	// 				<href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>\n
	// 			</Icon>\n
	// 			<hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>\n
	// 		</IconStyle>\n
	// 		<LabelStyle>\n
	// 		</LabelStyle>\n
	// 		<LineStyle>\n
	// 		</LineStyle>\n
	// 		<PolyStyle>\n
	// 		</PolyStyle>\n
	// 		<BalloonStyle>\n
	// 		</BalloonStyle>\n
	// 	</Style>\n
	// </gx:CascadingStyle>\n
    // 	<gx:CascadingStyle kml:id="__managed_style_2C51756D9325FB931C6E">\n
	// 	<Style>\n
	// 		<IconStyle>\n
	// 			<scale>1.2</scale>\n
	// 			<Icon>\n
	// 				<href>https://earth.google.com/earth/rpc/cc/icon?color=1976d2&amp;id=2000&amp;scale=4</href>\n
	// 			</Icon>\n
	// 			<hotSpot x="64" y="128" xunits="pixels" yunits="insetPixels"/>\n
	// 		</IconStyle>\n
	// 		<LabelStyle>\n
	// 		</LabelStyle>\n
	// 		<LineStyle>\n
	// 			<width>1.5</width>\n
	// 		</LineStyle>\n
	// 		<PolyStyle>\n
	// 		</PolyStyle>\n
	// 		<BalloonStyle>\n
	// 		</BalloonStyle>\n
	// 	</Style>\n
	// </gx:CascadingStyle>\n
    // 	<StyleMap id="__managed_style_0F244175FB25FB931C6E">\n
	// 	<Pair>\n
	// 		<key>normal</key>\n
	// 		<styleUrl>#__managed_style_1A62B2882D25FB931C6E</styleUrl>\n
	// 	</Pair>\n
	// 	<Pair>\n
	// 		<key>highlight</key>\n
	// 		<styleUrl>#__managed_style_2C51756D9325FB931C6E</styleUrl>\n
	// 	</Pair>\n
	// </StyleMap>\n`
        geoJSON.features.forEach((feature, index) => {
            kml += `<Placemark>\n`;
            kml += `<name>  </name>\n`;
            kml += `<description>${feature.properties.name}\n${moment(feature.properties.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}\n${feature.geometry.coordinates[0]},${feature.geometry.coordinates[1]}\n${feature.properties.speed}Km/h\n${feature.properties.bearing}°</description>\n`;
            // kml += `<styleUrl>#__managed_style_0F244175FB25FB931C6E</styleUrl>\n`;
            kml += `<Point>\n`;
            kml += `<coordinates>${feature.geometry.coordinates[0]},${feature.geometry.coordinates[1]}</coordinates>\n`;
            kml += `</Point>\n`;
            kml += `</Placemark>\n`;
        });
        kml += `</Document>\n`;
        kml += `</kml>\n`;
        return header + kml;
    }
    // csv
    // downloadFile({
    // let headers = ["Id,Name,Surname,Age"];
    // let usersCsv = usersData.users.reduce((acc, user) => {
    //     const { id, name, surname, age } = user;
    //     acc.push([id, name, surname, age].join(","));
    //     return acc;
    // }, []);
    //     data: [...headers, ...usersCsv].join("\n"),
    //     fileName: "users.csv",
    //     fileType: "text/csv",
    //   });

    // json
    // downloadFile({
    //     data: JSON.stringify(usersData.users),
    //     fileName: "users.json",
    //     fileType: "text/json",
    //   });

    // kml format
    //     <?xml version = "1.0" encoding = "UTF-8"?>
    //     <kml xmlns="http://www.opengis.net/kml/2.2">
    //         <Placemark>
    //             <name>Simple placemark</name>
    //             <description>Attached to the ground. Intelligently places itself
    //                 at the height of the underlying terrain.</description>
    //             <Point>
    //                 <coordinates>-122.0822035425683,37.42228990140251,0</coordinates>
    //             </Point>
    //         </Placemark>
    //     </kml>

    // api call for verifying and logging
    async verifyAndLog(exportType) {
        if (this.isSet()) {
            this.api.exportsPageApi.exportPoints({
                investigation_id: this.investigation_id,
                target_id: this.target_id,
                startTime: this.startTime,
                endTime: this.endTime,
                export_type: exportType
            }).then((res) => {
                if (res.error) {
                    message.error(res.error);
                } else {
                    message.success('Export request sent');
                    switch (exportType) {
                        case 'CSV': {
                            const csv = parser.parse(res.data.attribute.points);
                            message.success(`Exported dump to CSV.`);
                            // downloads as blob but just saves teh file extension
                            fileDownload(csv, `export-points-${Date.now()}.csv`);
                            break;
                        }
                        case 'JSON': {
                            message.success(`Exported dump to JSON.`);
                            this.downloadFile(
                                JSON.stringify(res.data.attribute.points),
                                `export-points-${Date.now()}.${exportType.toLowerCase()}`,
                                `text/${exportType.toLowerCase()}`
                            );
                            break;
                        }
                        case 'KML': {
                            message.success(`Exported dump to KML.`);
                            const geoJSONPoints = this.convertToGeoJSON(res.data.attribute.points);
                            const kml = this.GeoJSONToKML(geoJSONPoints);
                            this.downloadFile(
                                kml,
                                `export-points-${Date.now()}.${exportType.toLowerCase()}`,
                                `text/${exportType.toLowerCase()}`
                            );
                            break;
                        }
                        case 'PDF':
                        default:
                            message.error('Download Option not supported');
                            break;
                    }
                }
            });
        } else {
            message.error('Export request not sent');
        }
    }
    setExportID(exportID) {
        this.exportID = exportID;
    }

    // export page functions
    getExports() {
        this.api.exportsPageApi.getExportList().then((res) => {
            if (res.error) {
                message.error(res.error);
            } else {
                this.exports = res.data.attribute;
            }
        });
    }

    updatebulk(data) {
        this.startTime = data.from;
        this.endTime = data.to;
        this.target_id = data.target_id;
        this.investigation_id = data.investigation_id;
        this.name = data.name;
    }

    setExportName(name) {
        this.exportName = name;
    }

    saveExport(timeZone, deviceSerial, altitude) {
        const exportTosend = {
            investigation_id: this.investigation_id,
            target_id: this.target_id,
            start: this.startTime,
            end: this.endTime,
            timeZone: timeZone,
            altitude: altitude,
            deviceSerial: deviceSerial,
            name: this.name,
        }
        if(deviceSerial) {
            exportTosend.deviceSerial = 1;
        } else {
            exportTosend.deviceSerial = 0;
        }

        if(altitude) {
            exportTosend.altitude = 1;
        } else {
            exportTosend.altitude = 0;
        }

        this.api.exportsPageApi.saveExport(exportTosend).then((res) => {
            if (res.error) {
                message.error(res.error);
            } else {
                message.success('Export request sent');
                exportTosend.id = res.data.attribute.exportID;
                exportTosend.created = 'just now';
                this.exports.push(exportTosend);
            }
        });
    }

    exportDownload(exportType) {
        this.api.exportsPageApi.getExportDownload(this.exportID).then((res) => {
            if (res.error) {
                message.error(res.error);
            } else {
                const downloadedData = res.data.attribute.data;
                const hash = res.data.attribute.hash;
                message.success('Export request sent');
                switch (exportType) {
                    case 'CSV': {
                        const csv = parser.parse(downloadedData);
                        message.success(`Exported dump to CSV.`);
                        // downloads as blob but just saves teh file extension
                        console.log(csv);
                        console.log(hash);
                        fileDownload(csv, `${this.exportName}.csv`);
                        fileDownload(hash, `${this.exportName}_hash.txt`);
                        break;
                    }
                    case 'JSON': {
                        message.success(`Exported dump to JSON.`);
                        this.downloadFile(
                            JSON.stringify(downloadedData),
                            `${this.exportName}.${exportType.toLowerCase()}`,
                            `text/${exportType.toLowerCase()}`
                        );
                        break;
                    }
                    case 'KML': {
                        message.success(`Exported dump to KML.`);
                        const geoJSONPoints = this.convertToGeoJSON(downloadedData);
                        const kml = this.GeoJSONToKML(geoJSONPoints);
                        this.downloadFile(
                            kml,
                            `${this.exportName}.${exportType.toLowerCase()}`,
                            `text/${exportType.toLowerCase()}`
                        );
                        break;
                    }
                    case 'PDF':
                    default:
                        message.error('Download Option not supported');
                        break;
                }
            }
        });
    }

    get exportResult() {
        let finArray = this.exports;
        if (this.setInvestigation) {
            finArray = this.exports.filter((exportItem) => exportItem.investigation_name === this.setInvestigation);
        }
        if (this.searchQuery) {
            const exportResult = this.exports.filter((exportItem) => exportItem.name.includes(this.searchQuery));
            finArray = exportResult;
        }
        return finArray;
    }
}

export default createContext(new AnalysisExportsStore());
