import React, { useState, useContext } from "react";
import { Table, message, Button } from "antd";
import { macToMAC } from "../../util/CommonUtils";
import API from "../../services/api";
import DeviceEditForm from "../DeviceEditForm";
import { toJS } from "mobx";
import EntityAddForm from "../../components/EntityAddForm";
import investigationStore from '../../stores/investigation.store';
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";

const Column = { Table }

export default function (props) {

    const investigationstore = useContext(investigationStore);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});

    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [mode1, setMode1] = useState("add");
    const [existingData1, setExistingData1] = useState({});
    const [entityList, setEntityList] = useState([]);

    const [
        {
            targetsForInvestigation,
            investigationsForUser,
            selectedInvestigation,
            selectedTarget,
            entitiesForInvestigation,
        },
        setSelectedInvestigation,
        setSelectedTarget
    ] = useInvestigationForUser();

    let devices = props.data

    async function entityCreate(values) {
        console.log(values)
        await API.entity.createEntity(values.InvestigationID, values.nickname, values.notes).then((res) => {
            if (res.status === 200) {
                console.log("success")
            }
        })
    }

    const upDateEntlist = () => {
        API.entity.GetAllEntities(selectedInvestigation).then((res) => {
            setEntityList(res);
        })
    }

    async function updateDevice(values) {
        console.log(values);
        await API.bluetooth.update(values.id, values.name).then((res) => {
            if (res.status === 200) {
                message.info("Updated");
            }
        })
    }

    const entityadd = () => {
        setMode1("add");
        setExistingData1();
        setIsModalOpen1(true);
    }

    const editbtn = (record) => {
        setMode("edit");
        let reformat = {};
        API.entity.GetAllEntities(props.InvestigationID).then((res) => {
            reformat.alias = record.alias;
            reformat.device_name = record.device_name;
            reformat.manufacturer = record.manufacture_name;
            reformat.class = record.class_of_device;
            reformat.type = record.type;
            reformat.id = record.id;
            reformat.mac = record.mac_address;
            reformat.device_id = record.device_id;
            reformat.accessPointLatLng = {
                lat: record.latitude_adjust || record.Latitude,
                lng: record.longitude_adjust || record.Longitude,
            };
            // reformat.accessPointData = record.accessPointData;
            // reformat.apID = record.apID;

            console.log(res);

            const geocoder = new window.google.maps.Geocoder();
            geocoder
                .geocode({ location: reformat.accessPointLatLng })
                .then((response) => {
                    console.log(response)
                    reformat.address = response.results[0].formatted_address;
                    reformat.entity_id = record.entity_id;
                    setExistingData(reformat);
                    setIsModalOpen(true);

                })
                .catch(() => {
                    reformat.address = "Geocoder lookup failed";
                    reformat.entity_id = record.entity_id;
                    setExistingData(reformat);
                    setIsModalOpen(true);
                })
        })
    }

    if (devices) {
        console.log(devices)
        return (

            <div>
                <div>
                    <Table
                        dataSource={devices}
                        size="middle"
                        rowKey="id"
                        pagination={true}
                    >
                        <Column
                            title="id"
                            dataIndex="id"
                            key="id"
                        />
                        <Column
                            title="Entity"
                            dataIndex="entity_alias"
                            key="entity_alias"
                        />
                        {/* <Column
                            title="Alias"
                            dataIndex="alias"
                            key="alias"
                        /> */}
                        <Column
                            title="mac_address"
                            dataIndex="mac_address"
                            key="mac_address"
                            render={item => macToMAC(item)}
                        />
                        {/* <Column
                        title="metadata"
                        dataIndex="metadata"
                        key="metadata"
                    /> */}

                        <Column
                            title="Device Name"
                            dataIndex="device_name"
                            key="device_name"
                        />
                        <Column
                            title="Last Seen"
                            dataIndex="last_seen"
                            key="Last_seen"
                        />
                        <Column
                            title="Last Location"
                            dataIndex="location"
                            key="location"
                        />
                        <Column
                            title=""
                            key="action"
                            render={(text, record) => (
                                <span>
                                    <span>
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                editbtn(record);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    </span>
                                </span>
                            )}
                        />
                    </Table>
                </div>
                <DeviceEditForm
                    opened={isModalOpen}
                    mode={mode}
                    device={existingData || null}
                    targets={props.entities}
                    bluetooth={true}
                    entityadd={entityadd}
                    onSubmit={(data) => {
                        console.log(data)
                        const { values, mode } = data;
                        updateDevice(values);
                        if (mode === "edit") {

                            setIsModalOpen(false);
                            setExistingData({});

                        } else {
                            console.log(`not edit module view form: ${JSON.stringify(values)}`);
                        }
                    }}
                    onCancel={() => {
                        setIsModalOpen(false);
                        setExistingData({});
                    }}
                />
                <EntityAddForm
                    selectedInvestigation={selectedInvestigation}
                    investigations={toJS(
                        investigationstore.investigationsForUser)}
                    opened={isModalOpen1}
                    mode={mode1}
                    passthrough={[]}
                    device={existingData1 || null}
                    onSubmit={(data) => {
                        const { values, mode } = data;
                        upDateEntlist();
                        entityCreate(values);
                        if (mode === "add") {
                            setIsModalOpen1(false);
                            setExistingData1({});
                        }
                    }}
                    onCancel={() => {
                        setIsModalOpen1(false);
                        setExistingData1({});
                    }}
                />
            </div>
        )
    }
}
