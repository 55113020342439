import { message } from 'antd';

import { action, computed, observable, makeAutoObservable } from 'mobx';
import { createContext} from 'react';

import API from '../services/api';

export class FlickDeviceStore {

    constructor() {
        this.api = API;
        this.DeviceId = -1;
        this.Display = false;
        this.FlickStatus = -1;
        this.FlickToolTipText = "Device is still magnetised"
        makeAutoObservable(this);
    }

    SetDeviceId(DeviceId) {
        this.DeviceId = DeviceId;
    }

    get ToolTipText() {
        if (this.FlickStatus === 0) {
            return "Device is magnetised";
        } else if (this.FlickStatus === 1) {
            return "Device is not magnetised";
        } else {
            return "Device is still magnetised";
        }
    }

    Drop(id) {
        this.SetDeviceId(id);
        this.Display = true;
    }

    DropTheDevice() {
        this.api.device.FlickDevice(this.DeviceId).then((res) => {
            this.Display = false;
            message.success('Command has been sent');
        }).catch((err) => {
            this.Display = false;
            message.error('Failed to Send Command');
        });
    }

    get ButtonColour() {
        if (this.FlickStatus === 0) {
            return "red";
        } else if (this.FlickStatus === 1) {
            return "green";
        } else {
            return "red";
        }
    }

    async getFlickStatus(DeviceId) {
        this.FlickStatus = 0;
        // this.api.flick.getFlickStatus(DeviceId).then((res) => {
        //     this.FlickStatus = res;
        //
        // });
    }

}

export default createContext(new FlickDeviceStore());
