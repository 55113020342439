import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const createNickName = async ({
    clonedMac,
    accessPointID,
    targetID,
    nickname,
    notes
}) => {
    try {
        const response = await HTTP.post("/nickNameClone/nicknameClonedCreate", {
            clonedMac,
            accessPointID,
            targetID,
            nickname,
            notes
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data =
            response.data.data.target[response.data.data.target.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateNickName = async ( id, nickname, notes) => {
    console.log(`update: ${id}, ${nickname}, ${notes}`);
    try {
        const response = await HTTP.put(`/nickNameClone/nickNameEdit`, {
            id,
            nickname,
            notes
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteNickName = async id => {
    try {
        const response = await HTTP.delete(`/nickNameClone/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllNickNamesByTarget = async (id) => {
    try {
        const response = await HTTP.get(`/nickNameClone/${id}`);
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        const data = response.data.data.NickNameCloned;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getNickNameByID = async (id) => {
    try {
        const response = await HTTP.get(`nickNameClone/alias/${id}`);
        const data = response.data.data.NickNameCloned;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception)
    }
}

export default {
    createNickName,
    getNickNameByID,
    updateNickName,
    deleteNickName,
    getAllNickNamesByTarget
};
