import { LockOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Modal, Select } from "antd";
import React from "react";
import "./index.scss"
import API from '../../services/api';

const { Option } = Select;
const { TextArea } = Input;

class EntityEditForm extends React.Component {
    state = {
        loading: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                values.id = this.props.data.id;
                this.props.onSubmit({ values, mode: this.props.mode });
                this.props.form.resetFields();
            }
        });
    };

    addnotifications = () => {
        console.log('notifications');
    }

    onCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, mode } = this.props;
        let extraData = {};
        const colors = [
            { name: 'Red', value: '#C0392B' },
            { name: 'Blue', value: '#0000FF' },
            { name: 'Purple', value: '#6a0dad' },
            { name: 'Green', value: '#008000' },
            { name: 'Orange', value: '#FFA500' }
        ]
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Modal
                    bodyStyle={{ borderRadius: "5px", margin: "5px", }}
                    title={mode === "add" ? "Add Entity" : "Edit Entity"}
                    visible={this.props.opened}
                    onOk={this.onUserSubmit}
                    confirmLoading={false}
                    className="modalStyle"
                    onCancel={() => {
                        this.props.form.resetFields();
                        this.props.onCancel();
                    }}
                    footer={[
                        <Button
                            style={{ width: "47%", right: "3%", borderRadius: "5px", }}
                            key="back"
                            onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <Button
                            style={{ width: "47%", right: "2%", backgroundColor: "#0066CC", color: "white", borderRadius: "5px", }}
                            loading={this.state.loading}
                            onClick={this.handleSubmit}
                            key="submit"
                        >
                            {mode === "add" ? "Add Entity" : "Save Changes"}
                        </Button>,
                    ]}
                >
                    {/* <Button
                        style={{ width: "47%", right: "2%", backgroundColor: "green", color: "white", borderRadius: "5px", }}
                        disabled={() => { if (mode !== 'add') { return true } }}
                        onClick={this.addnotifications}
                    >
                        Add to Notifications
                    </Button> */}
                    <Form.Item label="Entity Name">
                        {getFieldDecorator("nickname", {
                            rules: [{ required: true, message: "Please input a name!" }],
                            initialValue: mode === "edit" && data ? data.alias : "",
                        })(
                            <Input
                                prefix={
                                    <UserSwitchOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                type="text"
                                placeholder="nickname"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Notes">
                        {getFieldDecorator("notes", {
                            rules: [{ required: true, message: "Enter Notes" }],
                            initialValue: mode === "edit" && data ? data.notes : "",
                        })(
                            <TextArea
                                rows={4}
                                height={200}
                                placeholder="notes"
                            />
                        )}
                    </Form.Item>
                </Modal>
            </Form>
        );
    }
}

export default Form.create({})(EntityEditForm);
