import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Button, DatePicker, Radio, Select, Switch} from "antd";
import React from "react";
import {getQuickSearchTimeRange} from "../../util/CommonUtils";
import { contentBox, primaryButton } from "../../styles";


const { Option } = Select;

class AnalysisStopsForm extends React.Component {
  state = {
    loading: false,
      timeRange: [],
      isQuickSearch: false,
      pickerDate: []
  };

  handleSubmit = e => {
    // e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
          if (this.state.isQuickSearch) {
              values.timeRange = this.state.timeRange
          }
        this.props.handleSubmit(values);
      } else {
        // alert(JSON.stringify(error, null, 2));
      }
    });
  };

    onChangeQuickSearch = (e) => {
        let value = e.target.value;
        let timeRange = getQuickSearchTimeRange(value)
        this.setState({timeRange: timeRange})
    }

    onChangeDate = (e) => {
        this.setState({pickerDate: e ? e : []})
    }

    highlightAvailableDates = (current) => {
        const style = {};
        if(this.props.dateRange.includes(current.startOf('day').format("YYYY-MM-DD").toString())){
            style.border = '1px solid black';
            style.backgroundColor = "#80808075";
            style.color = "black"
        }
        return (
            <div className="ant-calendar-date" style={style}>
                {current.date()}
            </div>
        );
    }

    toggleSwitchQuickSearch = () => {
        let status = this.state.isQuickSearch;
        this.setState({ isQuickSearch: !status });
        if (this.state.isQuickSearch) {
            this.setState({timeRange: []})
        }
    };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}
      style={contentBox()}>
        <Form.Item>
          {getFieldDecorator("investigation_id", {
            onChange: this.props.onInvestigationSelect,
            initialValue: this.props.selectedInvestigation ? this.props.selectedInvestigation : [],
            rules: [
              { required: true, message: "Please select an investigation!" }
            ]
          })(
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Investigation"
              filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase()) }
            >
              {this.props.investigations.map(investigation => (
                <Option id={investigation.id} key={investigation.id} label={investigation.name}>{investigation.name}</Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator("target_id", {
            onChange: this.props.onTargetSelect,
            initialValue: this.props.selectedTarget ? this.props.selectedTarget : [],
            rules: [
              { required: true, message: "Please select an investigation!" }
            ]
          })(
            <Select showSearch style={{ width: "100%" }} placeholder="Target">
              {this.props.targets.map(target => (
                <Option id={target.id} key={target.id}>{target.name}</Option>
              ))}
            </Select>
          )}
        </Form.Item>

          {
              !this.state.isQuickSearch && (
                  <Form.Item>
                      {getFieldDecorator("timeRange", {
                          onChange: this.props.onDateRangeSelect,
                          initialValue: this.props.selectedDateRange || null,
                          rules: [
                              {
                                  required: true,
                                  message: "Please select a time-range!"
                              }
                          ]
                      })(<DatePicker.RangePicker
                          style={{ width: "100%" }}
                          onChange={this.onChangeDate}
                          disabled={this.props.selectedTarget === undefined}
                          dateRender={this.highlightAvailableDates}
                      />)}
                  </Form.Item>
              )
          }


          <Form.Item>
              <label style={{
                  marginRight: '10px'
              }}>Quick Search :</label>
              <Switch size="small"
                      checked={this.state.isQuickSearch}
                      onChange={() => this.toggleSwitchQuickSearch()}/> <br/>
              {
                  this.state.isQuickSearch && (
                      <Radio.Group onChange={this.onChangeQuickSearch}  disabled={this.props.selectedTarget === undefined}>
                          <Radio value={1}>12 hours</Radio>
                          <Radio value={2}>24 hours</Radio>
                          <Radio value={3}>48 hours</Radio>
                          <Radio value={4}>72 hours</Radio>
                          <Radio value={5}>One week</Radio>
                      </Radio.Group>
                  )
              }
          </Form.Item>

        <Form.Item>
          {getFieldDecorator("intervalSelector", {
            onChange: this.props.onIntervalSelect,
            initialValue: this.props.interval
          })(
            <Select showSearch style={{ width: "100%" }} placeholder="Interval">
              <Option key={"no-filter"}>No Filter</Option>
              <Option key={300}>5 Min</Option>
              <Option key={900}>15 Min</Option>
              <Option key={3600}>1 Hour</Option>
              <Option key={14400}>4 Hours</Option>
              <Option key={43200}>12 Hour</Option>
              <Option key={86400}>24 Hours</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button
              id={"stopformsubmitbtn"}
            style={primaryButton({ width: "100%" })}
            onClick={() => {
              this.handleSubmit();
            }}
          >
            Search
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({})(AnalysisStopsForm);
