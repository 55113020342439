import { Button, Result } from "antd";
import React from "react";

export default function SessionExpire(props) {
    return (
        <Result
            status="403"
            title="Your session has expired"
            subTitle="Login again to continue."
            extra={
                <Button
                    type="primary"
                    onClick={() => {
                        window.location = "/login";
                    }}
                >
                    Back Home
                </Button>
            }
        />
    );
}
