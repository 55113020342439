import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Checkbox, Row, Col, Button, Modal, Tabs } from "antd";
import React from "react";
import { secondaryButton, primaryButton } from '../../styles';

const { TextArea } = Input;
const { TabPane } = Tabs;

class UserPermissionForm extends React.Component {
  state = {
    loading: false,
    permissionArray: []
  };

  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values);
      // if (!err) {
      //   values.id = this.props.data.id;
      //   this.props.onSubmit(values);
      //   this.props.form.resetFields();
      // }
    });
  };

  createTabPane = (permissionList) => {
    // category
    // route
    // permissions = {
    //   "name": [
    //     ItemOBJ
    //   ]
    // }
    if (this.props.data.permission === undefined) {
      return null;
    }
    let permissions = {};
    permissionList.forEach((item, index) => {
      if (permissions[item.category] === undefined) {
        permissions[item.category] = [];
      }
      const isSet = this.props.data.permission.some((perm) => perm.id === item.id);
      item.userSet = isSet;
      console.log(isSet);
      permissions[item.category].push(item);
    });
    console.log(this.props.permissionList); // this is the master list
    console.log(this.props.data); // this is what permissions the role has
    this.state.permissionArray = this.props.data.permission;
    return (
        //console.log(itemP);
        <Tabs
          tabPosition={"bottom"}
          defaultActiveKey="1"
          type="mode"
          style={{ width: "100%" }}
          >
          {Object.keys(permissions).map((key, index) => {
            if (key === "Messaging" || key === "SOS") {
              return(null)
            }
            return (
                <TabPane
                  tab={key}
                  key={index + 1}
                >
                  {permissions[key].map((item, index) => {
                    if (item.category === 'Messaging' || item.category === 'SOS') {
                      return (null)
                    }
                    return (
                      <Row key={index}>
                        <Col span={24} key={item.id} id={"perm_"+item.id}>
                          <Checkbox
                            value={item.id}
                            checked={item.userSet}
                            //defaultChecked={item.userSet}
                          >{item.name}</Checkbox>
                        </Col>
                      </Row>
                    );
                  })}
                </TabPane>
            );
          }
          )}
        </Tabs>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data, mode } = this.props;

    console.log(this.props.permissionList);

    return (
      <Form onSubmit={this.handleSubmit} className="access-form">
        <Modal
          title={mode === "add" ? "Add User Role" : "Edit User Role"}
          visible={this.props.opened}
          confirmLoading={false}
          onCancel={() => {
            this.props.form.resetFields();
            this.props.onCancel();
          }}
          footer={[
            <Button style={secondaryButton()} key="back" onClick={this.onCancel}>
              Cancel
            </Button>,
            <Button
              loading={this.state.loading}
              onClick={this.handleSubmit}
              key="submit"
              style={primaryButton()}
            >
              {mode === "add" ? "Add User Role" : "Edit User Role"}
            </Button>
          ]}
        >
          <Form.Item label="Role Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message:
                    "Please input a role name less than or equal to 100 characters!"
                }
              ],
              initialValue: mode === "edit" && data ? data.name : ""
            })(
              <Input
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="User Role Name"
              />
            )}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message:
                    "Please input a description less than or equal to 100 characters!"
                }
              ],
              initialValue: mode === "edit" && data ? data.description : ""
            })(
              <TextArea
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="Description"
              />
            )}
          </Form.Item>

          <Form.Item label="Permissions" id={"permission_ant-row ant-form-item"}>
            {getFieldDecorator("permission", {
              rules: [
                {
                  required: true,
                  message: "Please select at least one from permission list!"
                }
              ],
              initialValue:
                mode === "edit" && data && data.permission
                  ? data.permission.map(x => x.id)
                  : []
            })(
              <Checkbox.Group style={{ width: "100%" }} id={"permissions_checkbox"}
                onChange={(checkedValues) => {
                  console.log(checkedValues);
                  console.log(this.props.permissionList);
                  // this.props.form.setFieldsValue({
                  //   permission: checkedValues
                  // });
                }}
                defaultValue={() => {
                  if (this.props.data.permission === undefined) {
                    return [];
                  }
                  return this.state.permissionArray;
                }}
              >
                <Row id={"permissions_checkbox_row"}>
                  {this.createTabPane(this.props.permissionList)}
                </Row>
              </Checkbox.Group>
            )}
          </Form.Item>
        </Modal>
      </Form>
    );
  }
}

export default Form.create({})(UserPermissionForm);
