import React, { useContext, useEffect, useRef, useState } from "react";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    Circle,
} from "@react-google-maps/api";
// https://stackoverflow.com/questions/46591519/react-google-maps-heatmaplayer-undefined
import MapStyles from "../../map-styles";
import TargetStore from "../../stores/target.store";
import getPublicURL from "../../utilities/public-url.utility";
// https://blog.alexdevero.com/custom-styled-google-map-react/
// https://tomchentw.github.io/react-google-maps/#installation
import { convertSpeed, getGMTDateTime, mapDefault } from "../../util/CommonUtils";
import "./index.css";


const SnapshotMap = ((
    { data,
        selectedPoint,
        panBy,
        selectedTarget,
        currentPosition,
        error,
        MarkerPlots,
        markerdisplay,
        speedUnitString,
        searchChange,
        mapPan,
        status,
    }) => {
    const google = window.google;
    const [map, setMap] = useState();
    const [circlePoint, setCirclePoint] = useState(null);
    // select trip and pan
    if (!Array.isArray(selectedTarget)) {
        let hold = selectedTarget;
        selectedTarget = [];
        selectedTarget.push(hold);
    }

    const [located, setLocated] = useState(false);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;
        if (located === false) {
            if (map) {
                map.panTo({
                    lat: crd.latitude,
                    lng: crd.longitude,
                });
                setLocated(true);
            }
        }
    }

    const fail = (error) => {
        console.log(`Error: ${error.code}`);

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    navigator.geolocation.getCurrentPosition(success, fail, options);


    /*
  const coordinates = data.map((point) => {
    return new window.google.maps.LatLng(point.latitude, point.longitude);
  });
  */
    const arrowtrans = {
        0: `${getPublicURL()}/arrow_rotates/arrow_up.png`,
        45: `${getPublicURL()}/arrow_rotates/arrow_up_right.png`,
        90: `${getPublicURL()}/arrow_rotates/arrow_right.png`,
        135: `${getPublicURL()}/arrow_rotates/arrow_down_right.png`,
        180: `${getPublicURL()}/arrow_rotates/arrow_down.png`,
        225: `${getPublicURL()}/arrow_rotates/arrow_down_left.png`,
        270: `${getPublicURL()}/arrow_rotates/arrow_left.png`,
        315: `${getPublicURL()}/arrow_rotates/arrow_up_left.png`
    }

    function arrowFloor(num) {
        if (num >= 337 || num < 22) {
            return 0;
        } else if (num >= 22 && num < 67) {
            return 45
        } else if (num >= 67 && num < 112) {
            return 90;
        } else if (num >= 112 && num < 157) {
            return 135
        } else if (num >= 157 && num < 202) {
            return 180;
        } else if (num >= 202 && num < 247) {
            return 225
        } else if (num >= 247 && num < 292) {
            return 270
        } else {
            return 315;
        }
    }

    function getArrow(heading) {
        let convert = arrowFloor(heading);
        return arrowtrans[convert]
    }

    // cleaning and filtering data
    // device_utc_date_time
    let sortedData = [];
    let bounds = new window.google.maps.LatLngBounds();
    if (data.length > 0) {
        data.forEach(element => {
            let index = sortedData.findIndex((item) => item.device_utc_date_time === element.device_utc_date_time);
            if (index < 0) {
                sortedData.push(element);
                bounds.extend({ lat: element.latitude, lng: element.longitude });
            }
        });
    }
    useEffect(() => {
        if (map) {
            map.fitBounds(bounds);
        }
    }, [searchChange]);

    useEffect(() => {
        if (map) {
            if (mapPan && status === "playing") {
                if (data.length > 0) {
                    map.panTo({
                        lat: data[data.length - 1].latitude,
                        lng: data[data.length - 1].longitude,
                    });
                    // set circle around point
                    setCirclePoint({
                        lat: data[data.length - 1].latitude,
                        lng: data[data.length - 1].longitude,
                    });
                }
            } else if (mapPan && status === "paused") {
                if (data.length > 0) {
                    // set circle around point
                    map.panTo({
                        lat: data[data.length - 1].latitude,
                        lng: data[data.length - 1].longitude,
                    });
                    setCirclePoint({
                        lat: data[data.length - 1].latitude,
                        lng: data[data.length - 1].longitude,
                    });
                }
            } else if (mapPan && status === "stopped" && data.length > 0) {
                const bounds = new window.google.maps.LatLngBounds();
                data.forEach((point) => {
                    const packet = {
                        lat: point.latitude,
                        lng: point.longitude,
                    };
                    bounds.extend(packet);
                });
                map.fitBounds(bounds);
                // remove circle point
                setCirclePoint(null);
            }
        }
    }, [data])


    const vischeck = (props) => {

        const savedindex = MarkerPlots.findIndex(item => item.id === props.id);
        if (savedindex >= 0) {
            return (true);
        }
        return false;
    }

    const markerselect = (props) => {
        markerdisplay(props);
    }

    return (

        <GoogleMap
            // googleMapURL={googleMapURL}
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={15}
            defaultCenter={mapDefault()}

            onIdle={() => {

                if (map.getCenter()) {
                    localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                }
            }}
            zoom={15}
            onLoad={(map) => { setMap(map) }}
            mapContainerStyle={{
                height: "100vh",
                width: "100%"
            }}
        >

            {/*Standard Markers layer*/}
            {sortedData.map((target) => {
                if (target) {
                    if (target.latitude && target.longitude) {

                        let icon = {}

                        if (target.record_type === 'CELLTOWER') {
                            icon = {
                                url: `${getPublicURL()}/broadcast-pin.svg`,
                                scaledSize: new google.maps.Size(20, 20),
                                fillColor: "#71FC27",
                                fillOpacity: 1,
                                strokeWeight: 1,
                                strokeColor: "#01290D",
                                scale: 4,
                                anchor: new window.google.maps.Point(14, 14)
                            };
                        } else if (target.speed < 1) {
                            icon = {
                                url: `${getPublicURL()}/arrow_rotates/arrow_round.png`,
                                scaledSize: new google.maps.Size(20, 20),
                                rotation: 45,
                                anchor: new window.google.maps.Point(10, 10),
                            }
                        } else {
                            icon = {
                                url: getArrow(target.heading),
                                scaledSize: new google.maps.Size(20, 20),
                                rotation: 45,
                                anchor: new window.google.maps.Point(10, 10),
                            }
                        }
                        if (target.record_type === 'CELLTOWER') {
                        }
                        return (
                            <div>
                                <Marker
                                    style={{ transform: `rotate(${45}deg)` }}
                                    key={target.id}
                                    tracksViewChanges={false}
                                    position={{
                                        lat: target.latitude,
                                        lng: target.longitude,
                                    }}
                                    optimized={true}
                                    icon={icon}
                                    //icon={markerType}
                                    onClick={() => markerselect(target)}
                                >
                                    {
                                        vischeck(target)
                                            ?
                                            <InfoWindow
                                                onCloseClick={() => markerselect(target)}
                                                position={{
                                                    lat: target.latitude,
                                                    lng: target.longitude,
                                                }}
                                            >
                                                <div>
                                                    <p>Marker Latitude: {target.latitude}</p>
                                                    <p>Marker Longitude: {target.longitude}</p>
                                                    <p>Time: {getGMTDateTime(target.device_utc_date_time)}</p>
                                                    <p>Speed: {convertSpeed(speedUnitString, target.speed)}</p>
                                                </div>
                                            </InfoWindow>
                                            :
                                            <div></div>
                                    }
                                </Marker>
                                <Circle
                                    visible={(target.record_type === 'CELLTOWER' || vischeck(target) || false)}
                                    options={{
                                        strokeColor: (target.record_type === 'CELLTOWER' ? "#7faedc" : "#3aa202"),
                                        fillColor: (target.record_type === 'CELLTOWER' ? "#A7C7E7" : "#4cd403"),
                                        fillOpacity: 0.02
                                    }}
                                    center={{ lat: Number(target.latitude) || 0.0, lng: Number(target.longitude) || 0.0 }}
                                    radius={target.gps_accuracy}
                                />
                            </div>
                        )
                    }
                }
            })}

            {circlePoint && (
                <Circle
                options={{
                    strokeColor: "#7faedc",
                    fillColor: "#A7C7E7",
                    fillOpacity: 0.6
                }}
                center={{ lat: Number(circlePoint.lat) || 0.0, lng: Number(circlePoint.lng) || 0.0 }}
                radius={40}
            />
            )}
        </GoogleMap>
    );
});

export default SnapshotMap;
