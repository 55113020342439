import {
    CloseCircleOutlined,
    DesktopOutlined,
    EyeOutlined,
    HeatMapOutlined,
    LogoutOutlined,
    NotificationOutlined,
    TeamOutlined,
    ToolOutlined,
    UserOutlined,
    SettingOutlined,
    FolderOpenOutlined,
    QuestionCircleOutlined,
    SearchOutlined,
    FilePdfOutlined,
    WifiOutlined,
    BellOutlined,
} from '@ant-design/icons';

import {
    Button,
    Collapse,
    Layout,
    List,
    Menu,
    Modal,
    PageHeader,
    notification,
    Badge,
    Col,
    Row,
    Drawer,
} from "antd";
import { motion } from "framer-motion";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import useSound from "use-sound";
import SOSMap from "../../components/SOSMap";
import AppStore from "../../stores/app.store";
import AuthStore from "../../stores/auth.store";
import PermissionStore from "../../stores/permission.store";
import SOSStore from "../../stores/sos.store";
import NotificationStore from "../../stores/notification.store";
import getPublicURL from "../../utilities/public-url.utility";
import "./Layout.scss";
import API from '../../services/api';
import packageJson from "../../../package.json";
import socketHandlerStore from '../../stores/socketHandler.store';

import { videoLinks, videoText } from '../../util/consts';
import NotificationDrawer from '../../components/NotificationDrawer';
import EntityModal from "../../components/EntityModal";
import EntityCreationModal from "../../components/EntityModal/entityCreationModal";
import FlickModal from "../../components/FlickConfirmationModal/FlickModal";
import WifiDeviceHistory from "../../components/EntityModal/WifiDeviceHistory";

const { Panel } = Collapse;

const pageVariants = {
    initial: {
        opacity: 0,
        y: -10,
    },
    in: {
        opacity: 1,
        y: 0,
    },
    out: {
        opacity: 0,
        y: -10,
    },
};

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const iconsStyles = { fontSize: "1.6em", marginLeft: "-0.14em", color: "#3ce8e2" };


const SiderAndNavigation = ({
    title,
    description,
    extra,
    hideMargins,
    onCollapse,
    collapsed,
    location,
    history,
    children,
    dashboardPage,
    permissions,
    receivedNotifications,
    onClickClose
}) => {

    const address = location.pathname.match(/\/\w+/g);
    let useAddress = location.pathname;
    if (address !== null && address.length > 0) {
        useAddress = address[0];
    }
    const selectedKey = [useAddress, location.pathname];
    const socketHandler = useContext(socketHandlerStore);
    const [current, setCurrent] = useState(selectedKey);
    const onClick = (e) => {
        const add = location.pathname.match(/\/\w+/g);
        let useAddress = location.pathname;
        if (address !== null && address.length > 0) {
            useAddress = address[0];
        }
        const selectedKey = [useAddress, e.key];
        setCurrent(selectedKey);
    }

    const DownloadPDF = (name) => {
        API.user.manual(name);
    }


    const wifiMenu = ((permissions && permissions.wifi.accessPoint) ? {

        key: "/Wi-Fi",
        id: "Wi-Fi-submenu",
        icon: <WifiOutlined style={{ ...iconsStyles }} />,
        label: "Wi-Fi",
        children: [
            {
                key: "/wifi/wifiNetwork",
                label: <Link to="/wifi/wifiNetwork">Network</Link>
            },
            {
                key: "/wifi/entityManagment",
                label: <Link to="/wifi/entityManagment">Entity Management</Link>
            },
            {
                key: "/wifi/wifiDevices"
                , label: <Link to="/wifi/wifiDevices">WiFi Devices</Link>
            }
        ]
    } : {});

    const entityManagmentMenu = ((permissions && permissions.entity.page) ? {
        key: "/tools/entityManagment",
        label: <Link to="/tools/entityManagment">Entity Management</Link>
    } : {});

    const adminMenu = ((permissions && permissions.admin.view) ? {
        key: "/admin",
        id: "admin-submenu",
        icon: <SettingOutlined style={{ ...iconsStyles }} />,
        label: "Admin",
        children: [
            {
                key: "/admin/users",
                label: <Link to="/admin/users">Users</Link>
            },
            {
                key: "/admin/licenses",
                label: <Link to="/admin/licenses">Licenses</Link>
            },
            {
                key: "/messages/bulletins",
                label: <Link to="/messages/bulletins">Bulletins<Badge style={{ marginLeft: "10px" }} count={receivedNotifications} /></Link>
            },
            {
                key: "/admin/UserAudit",
                label: <Link to="/admin/UserAudit">User Audits</Link>
            }
        ]
    } : {});

    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [manualModalOpen, setManualModalOpen] = useState(false);
    const VonClickCancel = () => {
        setVideoModalOpen(false);
    }

    const VonClickOkay = () => {
        setVideoModalOpen(false);
    }

    const MonClickCancel = () => {
        setManualModalOpen(false);
    }

    const MonClickOkay = () => {
        setManualModalOpen(false);
    }

    const forceSelectedKey = (key) => {

    }

    return (
        <Layout hasSider style={{ minHeight: "100vh" }}>
            <NotificationDrawer/>
            <EntityModal />
            <EntityCreationModal />
            <FlickModal />
            <WifiDeviceHistory />
            <Modal
                title="Video tutorial"
                visible={videoModalOpen}
                onOk={VonClickOkay}
                onCancel={VonClickCancel}
                okText="Yes"
                cancelText="No"
                style={{
                    width: "80%",
                    height: "80%",
                }}
                footer={null}
            >
                <p>{videoText[location.pathname]}</p>
                <iframe src={videoLinks[location.pathname]} title={videoText[location.pathname]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Modal>
            <Sider
                //collapsible
                defaultCollapsed={true}
                collapsed={collapsed}
                onCollapse={onCollapse}
                collapsedWidth="4rem"
            >
                {/* <div className="logo-skeleton" /> */}
                <div className="logo" >
                    {collapsed ? (
                            <img
                                id="logo"
                                src={`${getPublicURL()}/atrax_logo_inverted.png`}
                                alt="logo"
                                style={{ marginLeft: "-0.14em" }}
                            ></img>
                    ) : (
                        <img
                            id="logo"
                            src={`${getPublicURL()}/atrax_logo_with_text.png`}
                            alt="logo"
                            style={{ marginLeft: "-0.3em" }}
                        ></img>
                    )}
                </div>
                <Menu
                    id={"navbar"}
                    theme="dark"
                    selectedKeys={current}
                    multiple={true}
                    mode="inline"
                    onClick={({ item, key }) => {
                        if (key === "/notifications") {
                            socketHandler.openDrawer();
                        }
                        if (key === "/logout") {
                            localStorage.removeItem("token");
                            localStorage.removeItem("profile");
                            localStorage.removeItem("role");
                            localStorage.removeItem("googleMapsKey");
                            window.location.replace("/login");
                        }
                        console.log("key", key);
                        if (key === "/help") {
                            // set thing to show modal
                            setVideoModalOpen(true);
                        }

                        if (key === "/manuals") {
                            // set thing to show modal
                            setManualModalOpen(true);
                        }
                        onClick(key);
                    }}
                    items={[
                        {
                            key: "/live",
                            icon: <EyeOutlined style={{ ...iconsStyles }} />,
                            label: <Link to="/live">Live</Link>
                        },
                        {
                            key: "/analysis",
                            id: "analysis-submenu",
                            icon: <HeatMapOutlined style={{ ...iconsStyles }} />,
                            label: "Analysis",
                            children: [
                                {
                                    key: "/analysis/points",
                                    label: <Link to="/analysis/points">Points</Link>
                                },
                                {
                                    key: "/analysis/stops",
                                    label: <Link to="/analysis/stops">Stops</Link>
                                },
                                {
                                    key: "/analysis/trips",
                                    label: <Link to="/analysis/trips">Trips</Link>
                                },
                                {
                                    key: "/analysis/multiTargetDisplay",
                                    label: <Link to="/analysis/multiTargetDisplay">Multi-Target</Link>
                                },
                                {
                                    key: "/Analysis/Fence",
                                    label: <Link to="/Analysis/Fence">Area</Link>
                                },
                                {
                                    key: "/analysis/export",
                                    label: <Link to="/analysis/export">Export</Link>
                                }]
                        },
                        wifiMenu,
                        {
                            key: "/tools",
                            id: "tools-submenu",
                            icon: <ToolOutlined style={{ ...iconsStyles }} />,
                            label: "Tools",
                            children: [
                                {
                                    key: "/tools/devices",
                                    label: <Link to="/tools/devices">Devices</Link>
                                },
                                {
                                    key: "/tools/investigations",
                                    label: <Link to="/tools/investigations">Investigations</Link>
                                },
                                {
                                    key: "/tools/geofences",
                                    label: <Link to="/tools/geofences">Geofences</Link>
                                },
                                {
                                    key: "/tools/DeviceLogs",
                                    label: <Link to="/tools/DeviceLogs">Communication Logs</Link>
                                },
                            ]
                        },
                        adminMenu,
                        {
                            key: "/profile",
                            icon: <UserOutlined style={{ ...iconsStyles, marginBottom: "10%" }} />,
                            label: <Link to="/profile">Profile</Link>
                        },
                        {
                            key: "/logout",
                            icon: <LogoutOutlined style={{ ...iconsStyles, marginBottom: "10%" }} />,
                            label: "Logout"
                        },
                        {
                            key: "/notifications",
                            icon: (<Badge count={socketHandler.webNotificationArray.length} offset={[5, 30]}>
                                <BellOutlined style={{ ...iconsStyles, marginBottom: "15%" }} />
                            </Badge>)
                            ,
                            label: "Notifications",
                            onClick: () => {
                                socketHandler.openDrawer();
                            }
                        },
                    ]}>
                </Menu>
                {/* <Menu
                    theme="dark"
                    style={{
                        position: "absolute",
                        bottom: 80,
                        width: "100%",
                        background: "transparent",
                        "ant-menu-item-selected": "black",
                    }}
                    items={[
                        {
                          //key: "/help",
                          icon: <QuestionCircleOutlined
                          style={{ ...iconsStyles, color: 'yellow' }} onClick={()=> {
                            setVideoModalOpen(true);
                          }}/>,
                          label: "Page tutorial",
                        },
                    ]}
                >
                </Menu> */}
            </Sider>


            <Layout
                style={{ background: "whitesmoke" }}
            >

                {/* <Header style={{ background: "#fff", padding: 0 }} /> */}
                {title || description ? (
                    <PageHeader

                        title={title}
                        subTitle={description}
                        // extra={extra}
                    />
                ) : (
                    <React.Fragment />
                )}

                <Content
                    style={(function () {
                        if (!hideMargins && dashboardPage && dashboardPage === true) {
                            return {
                                // margin: "0 16px",
                                // position: "fixed",
                                top: "63px",
                                bottom: 0,
                            };
                        } else if (!hideMargins && !dashboardPage) {
                            return { margin: "0 16px" };
                        } else {
                            return {};
                        }
                    })()}
                >
                    <div
                        style={
                            !hideMargins
                                ? { padding: 24, minHeight: 360 }
                                : {}
                        }
                    >

                        {children}
                    </div>
                </Content>
                {!hideMargins && (
                    <Footer style={{ textAlign: "center", background: 'whitesmoke' }}>
                        ATRAX © {`${new Date().getFullYear()} (${packageJson.version})`}
                    </Footer>
                )}
            </Layout>

        </Layout >
    )
};

const BaseLayout = (props) => {
    const store = useContext(AuthStore);
    const appStore = useContext(AppStore);
    const permissionStore = useContext(PermissionStore);
    const sosStore = useContext(SOSStore);
    const notificationStore = useContext(NotificationStore);


    const [play] = useSound(
        `${getPublicURL()}/sounds/sms-alert-3-daniel_simon.mp3`
    );

    const onCollapse = () => {
        appStore.toggleNavigation(!appStore.navigationCollapsed);
    };

    const [sosCollapsekey, setSOSCollapseKey] = useState(0);
    const [sosCollapsed, setSOSCollapsed] = useState(false);
    const [sosOverlayModalVisibility, setSosOverlayModalVisible] = useState(
        false
    );
    const [sosObj, setSOSObj] = useState({});

    useEffect(() => {
        if (localStorage.getItem("token")) {
            permissionStore.getPermissionByRoleIdTest(
                JSON.parse(localStorage.getItem("role"))
            );
        }
        // eslint-disable-next-line
    }, []);

    store.setAsAuthenticated();

    useEffect(() => {
        setInterval(() => {
            if (
                !sosStore.isMuted &&
                sosStore.receivedSOS !== null &&
                sosStore.ongoingSOS.length > 0
            ) {
                play();
            }
        }, 3000);

        // eslint-disable-next-line
    }, [play]);

    // console.log("receivedAdminBulletin Start", toJS(notificationStore.receivedAdminBulletin))

    useEffect(() => {
        // console.log("receivedAdminBulletin", toJS(notificationStore.receivedAdminBulletin))
        if (toJS(notificationStore.receivedAdminBulletin).length > 0) {
            notification.info({
                message: toJS(notificationStore.receivedAdminBulletin)[0].subject,
                description:
                    toJS(notificationStore.receivedAdminBulletin)[0].message,
                onClick() {
                    props.history.push('/messages/notifications')
                    notificationStore.setReceivedAdminBulletinList();
                }
            });
            notificationStore.setReceivedAdminBulletin();
        }
        // eslint-disable-next-line
    }, [toJS(notificationStore.receivedAdminBulletin)])
    // position:absolute; z-index:1; top:0 left:0;
    return (
        <React.Fragment>
            {props.showNavigation ? (
                <SiderAndNavigation
                    onCollapse={onCollapse}
                    collapsed={appStore.navigationCollapsed}
                    permissions={
                        !!localStorage.getItem("token") && {
                            wifi: {
                                accessPoint: !permissionStore.can("wifiDevice.view"),
                            },
                            entity: {
                                page: !permissionStore.can("Entity.view"),
                            },
                            messaging: {
                                chat: !permissionStore.can("messaging.chat"),
                                gallery: !permissionStore.can("messaging.gallery"),
                                search: !permissionStore.can("messaging.search"),
                            },
                            admin: {
                                view: !permissionStore.can("target.list"),
                            }
                        }
                    }
                    receivedNotifications={toJS(notificationStore.receivedAdminBulletinList).length}
                    {...props}
                >

                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                    >
                        {props.children}
                    </motion.div>
                </SiderAndNavigation>

            ) : (
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                >
                    {props.children}
                </motion.div>
            )}
        </React.Fragment>
    );
};
export default withRouter(observer(BaseLayout));
