import { UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Modal, Select, Radio } from "antd";
import React, { useEffect, useState } from "react";
import API from '../../services/api';
import { primaryButton, secondaryButton } from '../../styles';

const { Option } = Select;

class DownloadOptionModal extends React.Component {
    state = {
        value: 0,
        loading: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                this.props.onSubmit(this.state.value);
            }
        });
    };

    onCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    pointsRadio = () => {
        if (this.props.isPoints) {
            return (
                <Radio.Group onChange={this.onChange} value={this.state.value}>
                    <Radio value={0}>JSON</Radio>
                    <Radio value={1}>CSV</Radio>
                    <Radio value={2}>KML</Radio>
                </Radio.Group>
            )
        } else {
            return (
                <Radio.Group onChange={this.onChange} value={this.state.value}>
                    <Radio value={0}>JSON</Radio>
                    <Radio value={1}>CSV</Radio>
                    <Radio value={2}>KML</Radio>
                    <Radio value={3}>PDF</Radio>
                </Radio.Group>
            )
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Modal
                    title={"Download Options"}
                    visible={this.props.opened}
                    onOk={this.onUserSubmit}
                    confirmLoading={false}
                    onCancel={() => {
                        this.props.form.resetFields();
                        this.props.onCancel();
                    }}
                    footer={[
                        <Button
                            style={secondaryButton({ width: "47%", right: "3%", borderRadius: "5px", })}
                            key="back"
                            onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <Button
                            style={primaryButton({ width: "47%", right: "2%", borderRadius: "5px", })}
                            loading={this.state.loading}
                            onClick={this.handleSubmit}
                            key="submit"
                        >
                            Download
                        </Button>,
                    ]}

                    okButtonProps={{ style: primaryButton() }}
                    cancelButtonProps={{ style: secondaryButton() }}
                >


                    <Form.Item>
                        {this.pointsRadio()}
                    </Form.Item>
                    {!this.props.isexport  && false ? (
                        <Button
                            style={primaryButton({ width: "47%", right: "2%", borderRadius: "5px", })}
                        >
                            Add to Export
                        </Button>
                    ) : null}

                    <p>* CSV Download will also Generate a hash download if Investigation is "Locked"</p>
                </Modal>
            </Form>
        );
    }
}

export default Form.create({})(DownloadOptionModal);
