import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Modal, Row, Select, Space, Table, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import PermissionStore from "../../stores/permission.store";
import Layout from "../AppLayout";
import { toJS } from "mobx";
import moment from 'moment';
import { contentBox, primaryButton, secondaryButton } from '../../styles'
import ExportHistory from '../../components/exportHistory';

import UserStore from '../../stores/user.store';
import UserAudits from '../../components/UserAudits';
import DeviceLogView from '../../components/DeviceLogView';

const { TabPane } = Tabs;

function DeviceLogPage() {

    const userStore = useContext(UserStore);

    useEffect(async () => {
        await userStore.getUsers();
    }, []);


    return (
        <Layout
            showNavigation
            title={"Communication Logs"}
            description="Communications logs from all device connections"
        >
            <DeviceLogView></DeviceLogView>


        </Layout>
    );
}

export default observer(DeviceLogPage);
