import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Input, Modal, Select } from "antd";
import moment from "moment";
import React from "react";
import { HuePicker } from "react-color";
import { secondaryButton, primaryButton } from '../../styles';
import './InvestigationForm.scss'

class InvestigationForm extends React.Component {
  state = {
    loading: false,
    background: "#fff",
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        values.colour = this.state.background;
        values.id = this.props.data.id;
        console.log("Values", values)
        this.props.onSubmit({ values, mode: this.props.mode });
        this.props.form.resetFields();
      }
    });
  };

  onCancel = () => {
    this.props.form.resetFields();
    this.props.onCancel();
  };

  handleChangeComplete = (color) => {
    const { data, mode } = this.props;
    if (mode === "edit" && data) {
      data.colour = null
    }
    this.setState({ background: color.hex });
    console.log(color.hex)
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { data, mode } = this.props;

    const SPEED_UNIT = [
      { name: 'MPH', id: 1 },
      { name: 'KPH', id: 2 }
    ];

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Modal
          title={mode === "add" ? "Add Investigation" : "Edit Investigation"}
          visible={this.props.opened}
          onOk={this.onUserSubmit}
          confirmLoading={false}
          onCancel={() => {
            this.props.onCancel();
            this.props.form.resetFields();
          }}
          footer={[
            <Button style={secondaryButton()} key="back" onClick={this.onCancel}>
              Cancel
            </Button>,
            <Button
              loading={this.state.loading}
              onClick={this.handleSubmit}
              key="submit"
              style={primaryButton()}
            >
              {mode === "add" ? "Add Investigation" : "Edit Investigation"}
            </Button>,
          ]}
        >
          <Form.Item label="Name">
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please type in a name for the investigation!",
                },
              ],
              initialValue: mode === "edit" && data ? data.name : "",
            })(
              <Input
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="Investigation Name"
              />
            )}
          </Form.Item>

          <Form.Item label="Description">
            {getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  message: "Please enter a description for the investigation.",
                },
              ],
              initialValue: mode === "edit" && data ? data.description : "",
            })(
              <Input
                prefix={
                  <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="text"
                placeholder="Description"
              />
            )}
          </Form.Item>

          <Form.Item label="Start Date">
            {getFieldDecorator("start_datetime", {
              rules: [
                {
                  required: true,
                  message: "Please select a start date for the investigation.",
                },
              ],
              initialValue:
                mode === "edit" && data
                  ? moment(
                    moment(data.start_datetime * 1000).format("MMM DD, YYYY")
                  )
                  : moment(moment().format("MMM DD, YYYY")),
            })(<DatePicker style={{ width: "100%" }} />)}
          </Form.Item>

          <Form.Item label="Case Officer">
            {getFieldDecorator("caseOfficer", {
              rules: [
                {
                  required: true,
                  message: "Please select a case officer for the investigation."
                }
              ],
              initialValue: mode === "edit" && data.caseOfficer
                ? data.caseOfficer
                : [],
            })(
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear="true"
                style={{ width: "100%" }}
                placeholder="Select Users for the Investigation"
              >
                {this.props.allUsers.map((user) => (
                  user.last_login_at !== null &&
                  <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Speed Unit">
            {getFieldDecorator("speed_unit", {
              rules: [
                {
                  required: true,
                  message: "Please select a speed unit."
                }
              ],
              initialValue: mode === "edit" && data.speed_unit
                ? [data.speed_unit]
                : [],
            })(
              <Select
                // showSearch
                allowClear="true"
                //  mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select Speed Unit for the Investigation"
              >
                {SPEED_UNIT.map((unit) => (
                  <Select.Option key={unit.name} value={unit.name}>{unit.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Color">
            {getFieldDecorator("colour", {
              rules: [
                {
                  required: true,
                  message: "Please select a color."
                }
              ],
              initialValue: mode === "edit" && data ? data.colour : ""
            })(
              <HuePicker
                width={"100%"}
                color={mode === "edit" && data.colour ? data.colour : this.state.background}
                onChangeComplete={this.handleChangeComplete}
              />
            )
            }
          </Form.Item>
          <Form.Item >
            <div style={{ backgroundColor: mode === "edit" && data.colour ? data.colour : this.state.background, width: "100%", height: "10px" }}><h6 style={{ opacity: '0' }}>.</h6></div>
          </Form.Item>
        </Modal>
      </Form>
    );
  }
}

export default Form.create({})(InvestigationForm);
