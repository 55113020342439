import { Icon as LegacyIcon } from '@ant-design/compatible';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Collapse, List, Tooltip } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import LivePinMarker from "../../components/LivePinMarker";
// import PlacesSearchBox from "../../components/PlacesSearchBox";
import PlacesSearchBoxHooks from "../../components/PlacesSearchBoxHooks";
import { contentBox } from '../../styles';

const { Panel } = Collapse;

// const Marker =
//     styled.div`
//     position: absolute;
//     border-radius: 50%;
//     border: 5px solid ${props => (props.color ? props.color : 'black')};
//     width: 17px;
//     height: 17px;
//     background-color: white;
//         :after {
//           position: absolute;
//         content: '';
//         width: 0px;
//         height: 0px;
//         bottom: -22px;
//         left: -3.7px;
//         border: 8px solid transparent;
//         border-top: 14px solid  ${props => (props.color ? props.color : 'black')};
//     }`;


export default React.memo(
    observer(function ({
        onPlacesChanged,
        livePins,
        currentlyHiddenPins,
        onLivePinShowHideToggle,
        onLivePinSelect,
        currentPosition,
        currentQueryLocation,
        store,
        onSelectInvestigation,
        onRemovePin,
    }) {
        function isLivePinCurrentlyHidden(pin) {
            const isHidden =
                currentlyHiddenPins.filter((t) => {
                    return t.id === pin.id;
                }).length > 0;

            return isHidden;
        }

        return (
            <div style={{ backgroundColor: "#f8f4fa" }}>

                <Collapse
                    // expandIcon={() => {return(<ArrowLeftOutlined/>)}}
                    expandIconPosition="start"
                    ghost
                    style={{

                        boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                      0 12.5px 10px rgba(0, 0, 0, 0.06),
                      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                      0 100px 80px rgba(0, 0, 0, 0.12)`,
                    }}
                >

                    <Panel
                        style={{
                            backgroundColor: "#f8f4fa",
                            color: "#f8f4fa",
                            padding: "5px",
                        }}
                        header={<div><LivePinMarker colour='#0066CC'/><p style={{marginLeft: "25px", marginTop: "-15px"}}>POI</p></div>}
                    >
                        {/* <div style={{ backgroundColor: "#f8f4fa" }}> */}
                        <div
                            style={contentBox({ padding: "1.01rem" })}
                        >

                            <PlacesSearchBoxHooks
                                style={{
                                    width: "100%",
                                }}
                                onPlacesChanged={(places) => onPlacesChanged(places)}
                                placeHolder={"Search for places"}

                            />
                        </div>

                        {/* <br /> */}

                        <Collapse
                            ghost
                            style={contentBox({
                                maxHeight: "90vh",
                                overflow: "auto",
                                padding: "0.5rem",
                            })}>
                            {toJS(livePins).map((item) => {
                                return (
                                    <Panel key={item.id} header={
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            {item.name }
                                            <div style={{ marginRight: '15px', right: "0px", marginLeft: "15px" }} onClick={() => onSelectInvestigation(item)}><LivePinMarker colour={item.colour} isMarker={false} /></div>
                                        </div>
                                    }>

                                        <List
                                            style={{
                                                maxHeight: "90vh",
                                                overflow: "auto",
                                            }}
                                            dataSource={item.pins}
                                            renderItem={(record) => {
                                                return (
                                                    <React.Fragment>
                                                        <List.Item
                                                            id={`main-${record.id}`}
                                                            key=""
                                                            actions={[
                                                                <Tooltip title="Show/Hide Pin">
                                                                    <LegacyIcon
                                                                        id={`show-pin-${record.id}`}
                                                                        type={
                                                                            isLivePinCurrentlyHidden(record)
                                                                                ? "eye-invisible"
                                                                                : "eye"
                                                                        }
                                                                        theme={
                                                                            isLivePinCurrentlyHidden(record) ? "filled" : ""
                                                                        }
                                                                        onClick={() => {
                                                                            return onLivePinShowHideToggle(record);
                                                                        }}
                                                                    />
                                                                </Tooltip>,
                                                                <Tooltip title="Remove Pin">
                                                                    <DeleteOutlined
                                                                        id={`remove-pin-${record.id}`}
                                                                        onClick={() => {
                                                                            onRemovePin(record.id)
                                                                        }} />
                                                                </Tooltip>,
                                                            ]}
                                                        >
                                                            <div
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    return onLivePinSelect(record);
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        color: "#c0392b"
                                                                    }}>
                                                                    {record.name}

                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    </React.Fragment>
                                                );
                                            }}
                                        />
                                    </Panel>
                                );
                            })}
                        </Collapse>
                        {/* </div > */}
                    </Panel>
                </Collapse>
            </div>
        );
    })
);
