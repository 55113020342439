import BaseAPI from "./utilities/request.lib";
import moment from "moment";
import {message} from "antd";
import {Parser} from "json2csv";

const fileDownload = require("js-file-download");

const queryString = require("query-string");

const HTTP = BaseAPI();

/**
 * Filter all sos from investigation id and the time range
 * @param investigation
 * @param from
 * @param to
 * @returns {Promise<unknown>}
 */
const getAllSOS = async (investigation, from, to) => {
    return
}

/**
 * Get Uses SOS
 * @returns {Promise<unknown>}
 */
const getUsersSOS = async () => {
    return
}

/**
 * Cancel SOS
 * @param sosId
 * @returns {Promise<unknown>}
 */
const cancelSOS = async (sosId) => {
    return
}

/**
 * Download sos data to a csv file
 * @param data
 * @returns {Promise<void>}
 */
const downloadSOSDump = async (data) => {
    return

}

export default {
    getAllSOS,
    downloadSOSDump,
    getUsersSOS,
    cancelSOS
};
