import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

export class RoleStore {
    constructor() {
        this.api = API;
        this.roles = [];
        this.searchQuery = "";
        makeAutoObservable(this);
    }

    async getAllRoles() {
        const response = await this.api.role.viewAllRoles();
        this.roles = response;
    }

    async createUserRole({ name, description, permissions }) {
        try {
            const response = await this.api.role.createUserRole({
                name,
                description
            });
            await this.mapRoleAndPermission({
                role_id: response.id,
                permission: permissions
            });
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async updateUseRole({ name, description, permissions, id }) {

        // check if default role
        const roleIndex = this.roles.findIndex(role => role.id === id);
        const defaultRole = this.roles[roleIndex].defaultRole;
        const roleName = this.roles[roleIndex].name;
        if (defaultRole) {
            // check if name is the name because if so itll need to be modified
            if (roleName === name) {
                name = `${name} (modified)`;
                message.info(`New Role name has been set to: ${name}`);
            }
            message.info(`Default role can not be modified new role made for ${name}`);
            await this.createUserRole({ name, description, permissions });
        } else {
            try {
                await this.api.role.updateUseRole({
                    name,
                    description,
                    id
                });
                await this.mapRoleAndPermission({
                    role_id: id,
                    permission: permissions
                });
            } catch (exception) {
                message.error(`${exception.response.data.message}`);
            }
        }
    }

    async deleteRole(record) {
        try {
            await this.api.role.deleteRole(record.id);
            message.success({
                message: "User Role Deleted",
                description: `Deleted User Role for ${record.name}.`
            });
            this.getAllRoles();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async mapRoleAndPermission({ role_id, permission }) {
        try {
            await this.api.role.mapRoleAndPermission({
                role_id,
                permission
            });
            this.getAllRoles();
            message.success(`User role has been created or updated with permission.`);
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }
}

export default createContext(new RoleStore());
