import { MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from "antd";
import React from "react";
import "./ForgotPasswordForm.scss";

class ForgotPasswordForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
        
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="password-form">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Email Address">
            {getFieldDecorator("emailAddress", {
              rules: [
                { required: true, message: "Your email address is required." }
              ]
            })(
              <Input
                prefix={
                  <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Email Address"
              />
            )}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="password-form-button"
          >
            Continue
          </Button>
          <Button
            type="link"
            block
            onClick={() => {
              this.props.onBack();
            }}
          >
            Go back
          </Button>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(ForgotPasswordForm);
