import {Col, message, Row, Switch} from "antd";
import { observer } from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import ProfileCounterTable from "../../components/ProfileCounterTable";
import Layout from "../AppLayout";
import "./ProfileCounterPage.scss";
import API from "../../services/api";

function ProfileCounterPage({ match }) {

    const [deviceDetails, setDeviceDetails] = useState(null);
    const [isFederation, setIsFederation] = useState(false);
    const [federation, setFederation] = useState(null);

    useEffect(() => {
        async function getDeviceSerial() {
            await API.device.getDeviceDetail({id: match.params.id}).then((res) => {
                if (res.length > 0){
                    setDeviceDetails(res[0]);
                }
            });
        }

        async function getFederation() {
            await API.federation.getFederation(match.params.id).then((res) => {
                if(res.federation.length > 0) {
                    setIsFederation(true);
                    setFederation(res.federation[0])
                }else{
                    setIsFederation(false)
                }
            })
        }

        getDeviceSerial();
        getFederation();
        // eslint-disable-next-line
    }, []);

    const onChange = (checked) => {
        setIsFederation(checked)
        if (checked) {
            addFederation();
        } else {
            removeFederation();
        }
        console.log(`switch to ${checked}`);
    }

    const addFederation = () => {
        API.federation.create({
            device_id: match.params.id
        }).then((res) => {
            if(res.federation.length > 0) {
                setFederation(res.federation[0])
            }
            message.success('Federation was successfully added');
        }).catch((err) => {
            message.error(`${err.response.data.message}`);
        })
    }

    const removeFederation = () => {
        API.federation.remove(federation.id).then((res) => {
            message.success('Federation was successfully removed');
        }).catch((err) => {
            message.error(`${err.response.data.message}`);
        })
    }

    return (
        <div className="ProfileCounterPage">
            <Layout showNavigation title={deviceDetails ? `Device Serial: ${deviceDetails.serial}` : ''} description="">
                <Row>
                    <Col span={24}>
                        <div className='federation-section'>
                            <h6>Federation Intrepid</h6>
                            <Switch checked={isFederation} onChange={onChange}/>
                        </div>

                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col span={8} >
                        <ProfileCounterTable deviceId={match.params.id} />
                    </Col>
                </Row>
            </Layout>
        </div>
    );
}

export default observer(ProfileCounterPage);
