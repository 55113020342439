import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const GeTAllDevices = async (investigation) => {
    try {
    //const offset = 60 * 30;
        const response = await HTTP.get(`/wifi_device/${investigation}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.devices;
        let len = data.length;

        for (let i = 0; i < len; i += 1) {
            // timestamp adjust
            let time = new Date(0);
            time.setUTCSeconds(data[i].last_seen);
            data[i].last_seen = time.toString();

            // lastlocation geocode
            let latlng = {
                lat: data[i].latitude,
                lng: data[i].longitude
            }

            data[i].location = JSON.stringify(latlng);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }

};

const update = async (id, alias, entityID) => {
    console.log(id, alias, entityID)
    try {
        const response = await HTTP.post(`/wifi_device/update/${id}?alias=${alias}&entity_id=${entityID}`);
    console.log("RESPONSE")
        console.log(response);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error)
    }
}

const getCaptures = async (id) => {
    if (id && id === -1) return Promise.resolve([]);
    try {
        const response = await HTTP.get(`/wifi_device/events/${id}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data.data.history;
        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject(error)
    }
}

const ReverseLookUp = async (mac) => {
    try {
        const response = await HTTP.get(`/wifi_device/ReversesMac/${mac}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        const data = response.data
        return Promise.resolve(data);
    } catch (error) {
        return Promise.reject(error)
    }
}

const getDetails = async (id) => {
    try {
        const response = await HTTP.get(`/wifi_device/details/${id}`);
        if (response.data.code === 'RECORD_NOT_FOUND') {
            return Promise.resolve([]);
        }
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error)
    }
}

export default {
    GeTAllDevices,
    update,
    getCaptures,
    getDetails,
    ReverseLookUp
};
