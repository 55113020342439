import React, { useContext, useEffect, useState } from "react";
import { Button, Input } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import useInterval from "use-interval";
import DeviceFormModal from "../../components/DeviceForm";
import DeviceTable from "../../components/DeviceTable";
import API from "../../services/api";
import DeviceStore from "../../stores/device.store";
import PermissionStore from "../../stores/permission.store";
import Layout from "../AppLayout";
import {primaryButton, secondaryButton, contentBox } from '../../styles'

import "./DeviceManagementPage.scss";

function DeviceManagementPage(props) {
    const store = useContext(DeviceStore);
    const permissionStore = useContext(PermissionStore);

    const AdvancedPerms = permissionStore.can('config.advanced');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allLicenses, setAllLicenses] = useState([]);
    const [unAllocatedLicenses, setUnAllocatedLicenses] = useState([]);
    const [deviceData, setDeviceData] = useState([]);

    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await permissionStore.getPermissionByRoleId(role);
            permissionStore.assignedPermission = permission;
        };

        store.getAllDevices();
        store.getDeviceTypes();
        fetchRolePermission();
    }, [store, permissionStore]);

    useEffect(() => {
        API.license.getAllLicenses().then((points) => {
            setAllLicenses(points);
        });
    }, []);

    useEffect(() => {
        API.license.getAllUnAllocatedLicenses().then((unAllocatedLicenses) => {
            const valid = []
            for(let i = 0; i < unAllocatedLicenses.dlMapping.length; i++) {
                if (unAllocatedLicenses.dlMapping[i].expiry_date > Date.now() / 1000) {
                    valid.push(unAllocatedLicenses.dlMapping[i])
                }
            }
            setUnAllocatedLicenses({dlMapping: valid});
        });
        API.device.getAllDevices().then((deviceData) => {
            setDeviceData(deviceData);
        });
    }, []);

    const clearSearch = () => {
        store.searchQuery = "";
    };

    useInterval(() => {
        store.getAllDevices();
    }, 15000);

    const onDeleteRecord = (record) => {
        store.deleteDevice(record);
        store.getAllDevices();
    };

    const onLicenseDeallocation = () => {
        // store.getAllDevices();

        setTimeout(function () {
            API.license.getAllUnAllocatedLicenses().then((unAllocatedLicenses) => {
                setUnAllocatedLicenses(unAllocatedLicenses);
            });
        }, 2000);
    };

    return (
        <div className="DeviceManagementPage">
            <DeviceFormModal
                deviceTypes={store.deviceTypes}
                opened={isModalOpen}
                AdvancedPerms={AdvancedPerms}
                unallocatedLicensesData={unAllocatedLicenses}
                onSubmit={(data) => {
                    store.createDevice(data);
                    setIsModalOpen(false);
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
            ></DeviceFormModal>
            <Layout
                showNavigation
                title="Device Administration"
                description="Manage devices in the ATRAX system"
            >
                <div
                    style={contentBox({marginTop: "0"})}>
                    <Button
                            icon={<PlusOutlined />}
                            type='primary'
                            style={primaryButton({marginBottom: '15px'})}

                            disabled={permissionStore.can("device.create")}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                    >
                        Add Device
                        </Button>

                    <Input
                        id={"searchDeviceBtn"}
                        style={{ float: "right", width: "40%" }}
                        placeholder="Search .."
                        value={store.searchQuery}
                        onChange={(event) => {
                            const query = event.target.value;
                            store.searchQuery = query;
                        }}
                        allowClear
                    />
                    <div className="user-table">
                        <DeviceTable
                            canDelete={permissionStore.can("device.delete")}
                            router={props.history}
                            onDelete={onDeleteRecord}
                            data={
                                !permissionStore.can("device.list") ? (store.results) : []
                            }
                            unallocatedLicenses={unAllocatedLicenses}
                            // unallocatedLicenses={data}
                            onDeviceClick={(record) => {
                                console.log(record);
                            }}
                            allLicenses={allLicenses}
                            onLicenseDeallocationClick={onLicenseDeallocation}
                            deviceRecoveryPermission={!permissionStore.can("config.dmRecovery")}
                            lite={false}
                        ></DeviceTable>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default observer(DeviceManagementPage);
