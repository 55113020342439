import BaseAPI from "./utilities/request.lib";
import {Parser} from "json2csv";
import {message} from "antd";

var fileDownload = require("js-file-download");

const HTTP = BaseAPI();

const getAllLicenses = async () => {
    try {
        const response = await HTTP.get("/dl-mapping");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data.dlMapping;
        if (response.data.code === "RECORD_NOT_FOUND") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getAllUnAllocatedLicenses = async () => {
    try {
        const response = await HTTP.get("/dl-mapping/?unassign=1");
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.data;
        if (response.data.code === "EMPTY_RESULT") {
            return Promise.resolve([]);
        }
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const allocateLicenseToDevice = async ({ license_id, device_id }) => {
    try {
        const response = await HTTP.post("/dl-mapping/assign", {
            license_id,
            device_id,
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const exportLicense = async (data) => {
    const parser = new Parser();
    if (data.length > 0) {
        const csv = parser.parse(data);
        message.success(`Exported dump to CSV.`);
        fileDownload(csv, `license-export-${Date.now()}.csv`);
    } else {
        message.error(`No data Available`);
    }
}


export default {
    getAllLicenses,
    getAllUnAllocatedLicenses,
    allocateLicenseToDevice,
    exportLicense
};
