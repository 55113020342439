import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from "antd";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import TargetFormModal from "../../components/TargetForm";
import TargetTable from "../../components/TargetTable";
import DeviceStore from "../../stores/device.store";
import InvestigationStore from "../../stores/investigation.store";
import PermissionStore from "../../stores/permission.store";
import TargetStore from "../../stores/target.store";
import Layout from "../AppLayout";
import "./InvestigationManagementPage.scss";
import { secondaryButton, contentBox } from '../../styles';

function TargetManagementPage() {
    const store = useContext(TargetStore);
    const deviceStore = useContext(DeviceStore);
    const investigationStore = useContext(InvestigationStore);
    const permissionStore = useContext(PermissionStore);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchRolePermission = async () => {
            const role = JSON.parse(localStorage.getItem("role"));
            const permission = await permissionStore.getPermissionByRoleId(role);
            permissionStore.assignedPermission = permission;
        };

        store.getAllTargets();
        deviceStore.getAllUnassignedDevices();
        investigationStore.getInvestigations();
        fetchRolePermission();
    }, [store, deviceStore, investigationStore, permissionStore]);

    const onDeleteRecord = (record) => {
        store.deleteTarget(record);
        store.getAllTargets();
    };

    const [mode, setMode] = useState("add");
    const [existingData, setExistingData] = useState({});

    const onEditRecord = (record) => {
        setMode("edit");
        setExistingData(record);
        setIsModalOpen(true);
    };

    const clearSearch = () => {
        store.searchQuery = "";
    };

    return (
        <div className="TargetManagementPage">
            <TargetFormModal
                investigations={investigationStore.investigations}
                opened={isModalOpen}
                mode={mode}
                data={existingData || null}
                devices={toJS(deviceStore.unassignedDevices)}
                onSubmit={(data) => {
                    const { values, mode } = data;
                    if (mode === "edit") {
                        store.updateTarget(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    } else {
                        store.createTarget(values);
                        setIsModalOpen(false);
                        setExistingData({});
                    }
                    deviceStore.getAllUnassignedDevices();
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                    setExistingData({});
                    deviceStore.getAllUnassignedDevices();
                }}
            ></TargetFormModal>
            <Layout
                showNavigation
                title="Target Administration"
                description="Manage targets and assign devices"
            >
                <div
                    style={contentBox()}
                >
                    <Button
                        style={secondaryButton()}
                        id={"addATargetBtn"}
                        disabled={permissionStore.can("target.create")}
                        onClick={() => {
                            deviceStore.getAllUnassignedDevices();
                            setMode("add");
                            setIsModalOpen(true);
                        }}
                    >
                        <PlusOutlined></PlusOutlined>
                        Add Target
                    </Button>
                    <Button
                        style={secondaryButton({
                            float: "right",
                            margin: "0 0 10px 5px",
                        })}
                        onClick={clearSearch}
                    >
                        Clear
                    </Button>

                    <Input
                        style={{ float: "right", width: "40%" }}
                        placeholder="Search .."
                        value={store.searchQuery}
                        onChange={(event) => {
                            const query = event.target.value;
                            store.searchQuery = query;
                        }}
                    ></Input>
                    <div className="target-table">
                        <TargetTable
                            canEdit={permissionStore.can("target.update")}
                            canDelete={permissionStore.can("target.delete")}
                            onEditRecord={(e) => {onEditRecord(e)}}
                            onDelete={(e) => {onDeleteRecord(e)}}
                            data={
                                !permissionStore.can("target.list") ? toJS(store.results) : []
                            }
                        ></TargetTable>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default observer(TargetManagementPage);
