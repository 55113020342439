import { CaretLeftOutlined, CaretRightOutlined, PauseOutlined, StopOutlined, ClockCircleOutlined, EnvironmentOutlined, CompassOutlined, CarOutlined } from '@ant-design/icons';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Collapse, DatePicker, Select, Switch, Row, Col, Radio, Tooltip, Slider, Form, Space } from "antd";
import React, {useContext} from "react";
import moment from "moment";
import { secondaryButton, primaryButton, contentBox } from '../../styles';
import { isThisSecond } from 'date-fns';
import { formatDate, getGMTDateTime } from '../../util/CommonUtils';
import AnalysisSiteStore from "../../stores/analysisSuper.store";

const { Option } = Select;

const { RangePicker } = DatePicker;

function AnalysisPointsForm({
    handlePointsReplayClick,
    handlePointsReplayStop,
    handlePointsReplayPauseClick,
    handlePointsReplayTransmissionSpeedCHange,
    handleForward,
    handleBackward,
    handleMapPanCheck,
    onStreetView,
    handleSlide,
    dateRange,
    isQuickSearch,
    timeRange,
    handleSubmit,
    onInvestigationSelect,
    selectedInvestigation,
    investigations,
    onTargetSelect,
    selectedTarget,
    targets,
    onDateRangeSelect,
    selectedDateRange,
    snapShotViewDuplicate,
    selectedInterval,
    onChangeDate,
    toggleSwitchQuickSearch,
    onChangeQuickSearch,
    quickSearchType,
    pickerDate,
    pointsCopy,
    currentState,
    displayFrom,
    displayTo,
    points,
    currentPoint,
    currentSpeed,
    mapPan,
}) {

    const [loading, setLoading] = React.useState(false);
    const [timeIncrements, setTimeIncrements] = React.useState(1);
    const investigationSiteStore = useContext(AnalysisSiteStore)
    const [form] = Form.useForm();

    const handleSubmit2 = (e) => {
        form.setFieldsValue({
            slider: [0, Number.MAX_SAFE_INTEGER]
        })
        if (e) {
            if (isQuickSearch) {
                e.timeRange = timeRange
            }
            setTimeIncrements(Math.ceil(Math.abs((e.timeRange[0] - e.timeRange[1]) / 1800000)));
            handleSubmit(e);
        } else {
            console.log("Error")
        }
    };

    const handlePointsReplay = (replay) => {
        handlePointsReplayClick(replay);
    };

    const handlePointsReplayStop2 = () => {
        handlePointsReplayStop();
    };

    const handlePointsReplayPause = (replay) => {
        handlePointsReplayPauseClick(replay);
    };

    const handleSpeedTransmissionChange = (value) => {
        handlePointsReplayTransmissionSpeedCHange(value);
    };

    const handleStepForward = () => {
        handleForward();
    };

    const handleStepback = () => {
        handleBackward();
    };

    const onChange = (e) => {
        handleMapPanCheck(e);
    };

    const slide = (e) => {
        handleSlide(e);
    }

    const  highlightAvailableDates = (current) => {
        const style = {};
        if (dateRange.includes(current.startOf('day').format("YYYY-MM-DD").toString())) {
            style.border = '1px solid black';
            style.backgroundColor = "#80808075";
            style.color = "black"
        }
        return (
            <div className="ant-picker-cell ant-picker-cell-in-view" style={style}>
                {current.date()}
            </div>
        );
    }
    return (
        <React.Fragment>
            <div style={contentBox()}>
                <Form onFinish={handleSubmit2} form={form}>
                    <Form.Item
                        name={"investigation_id"}
                        initialValue={selectedInvestigation ? selectedInvestigation : []}
                        rules={[
                            {
                                required: true,
                                message: "Please select an investigation!",
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Investigation"
                            onChange={onInvestigationSelect}
                            filterSort={(a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase()) }
                            options={investigations.map((investigation) => ({
                                value: investigation.id,
                                label: investigation.name,
                                }
                            ))}
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="target_id"
                        initialValue={selectedTarget ? selectedTarget : []}
                        rules={[
                            {
                                required: true,
                                message: "Please select a target!",
                            },
                        ]}
                    >
                        <Select
                            showSearch style={{ width: "100%" }}
                            placeholder="Target"
                            onChange={onTargetSelect}
                        >
                            {targets.map((target) => (
                                <Option id={target.id} key={target.id}>{target.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {
                        !(isQuickSearch) && (
                            <Form.Item
                                name="timeRange"
                                onChange={onDateRangeSelect}
                                initialValue={selectedDateRange ? selectedDateRange :
                                    (snapShotViewDuplicate && snapShotViewDuplicate === false) === false ?
                                        selectedInterval ? [moment(selectedInterval[0]), moment(selectedInterval[1])] : ['em']
                                        : []}
                                rules={[
                                    {
                                        required: false,
                                        message: "Please select a time-range!",
                                    },
                                ]}
                            >
                                <RangePicker timePicker={true} showTime={{
                                    hideDisabledOptions: true,
                                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                                }}
                                onChange={onChangeDate}
                                disabled={selectedTarget === undefined}
                                dateRender={highlightAvailableDates}
                                style={{ width: "100%" }} />
                            </Form.Item>
                        )
                    }

                    <Form.Item>
                        <label style={{
                            marginRight: '10px'
                        }}>Quick Search :</label>
                        <Switch size="small"
                            checked={isQuickSearch}
                            onChange={() => toggleSwitchQuickSearch()} /> <br />
                        {
                            isQuickSearch && (
                                <Radio.Group
                                    onChange={onChangeQuickSearch}
                                    value={isQuickSearch ? quickSearchType : ''}
                                    disabled={selectedTarget === undefined}>
                                    <Radio value={1}>12 hours</Radio>
                                    <Radio value={2}>24 hours</Radio>
                                    <Radio value={3}>48 hours</Radio>
                                    <Radio value={4}>72 hours</Radio>
                                    <Radio value={5}>One week</Radio>
                                </Radio.Group>
                            )
                        }
                    </Form.Item>

                    <Form.Item>
                        <Button
                            disabled={pickerDate.length === 0 && timeRange.length === 0}
                            id={"pointsformsubmitbtn"}
                            style={primaryButton({ width: "100%" })}
                            htmlType="submit"
                        // onClick={() => {
                        //     form.setFieldsValue({
                        //         slider: [0, Number.MAX_SAFE_INTEGER]
                        //     })
                        // }}
                        >
                            Search
                        </Button>
                    </Form.Item>
                    <hr />
                    <Form.Item
                        name={"slider"}
                    >
                        <Slider
                            defaultValue={[0, Number.MAX_SAFE_INTEGER]}
                            range
                            max={timeIncrements}
                            TooltipVisible={false}
                            tipFormatter={null}
                            onAfterChange={(e) => slide(e)}
                            onChange={(e) => slide(e)}
                            disabled={(!pointsCopy.length) > 0 || currentState !== "stopped"}
                        />
                    </Form.Item>
                    {displayFrom > 0 && (
                        <p>Selected Time: {formatDate(displayFrom, "HH:mm:ss")} - {formatDate(displayTo, "HH:mm:ss")}</p>
                    )}
                </Form>
            </div>

            {points.length > 0 && (currentState === "playing" || currentState === "paused") && (
                <div style={contentBox()}>
                    <Row>
                        <Col span={12}>
                            <ClockCircleOutlined style={{ color: '#0066CC' }} />{'  :  '}{getGMTDateTime(points[points.length - 1].device_utc_date_time)}
                        </Col>
                        <Col span={12}>
                            <EnvironmentOutlined style={{ color: '#0066CC' }} />{'  :  '}{points[points.length - 1].latitude}, {points[0].longitude}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <CompassOutlined style={{ color: '#0066CC' }} />{'  :  '}{points[points.length - 1].heading}{`°`}
                        </Col>
                        <Col span={12}>
                            <CarOutlined style={{ color: '#0066CC' }} /> {'  :  '}{`${points[points.length - 1].speed} KMH / ${Math.floor(points[points.length - 1].speed * 0.621371)} MPH`}
                        </Col>
                    </Row>
                </div>
            )}
            <div style={contentBox()}>
                <Form.Item>
                    <div>
                        <Button
                            style={secondaryButton({ width: "48%" })}
                            id={"pointsplaypausebtn"}
                            onClick={() => {
                                if (
                                    currentState === "paused" ||
                                    currentState === "stopped"
                                ) {
                                    handlePointsReplay(true);
                                } else {
                                    handlePointsReplayPause(true);
                                }
                            }}
                            disabled={!points.length > 0}
                        >
                            {currentState === "paused" ||
                                currentState === "stopped" ? (
                                <React.Fragment>
                                    <CaretRightOutlined /> Play
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <PauseOutlined /> Pause
                                </React.Fragment>
                            )}
                        </Button>
                        <Button
                            id={"pointsstopbtn"}
                            disabled={currentState === "stopped" || currentPoint}
                            style={secondaryButton({ width: "48%", right: "-4%" })}
                            onClick={() => {
                                handlePointsReplayStop2();
                            }}
                        >
                            <StopOutlined /> Stop
                        </Button>{" "}
                    </div>
                    <div>
                        <Button
                            id={"pointsstepbackbtn"}
                            disabled={currentState === "stopped" || currentState === "playing"}
                            style={secondaryButton({ width: "48%", marginTop: "2%" })}
                            onClick={() => {
                                handleStepback();
                            }}
                        >
                            <CaretLeftOutlined /> Step Back
                        </Button>
                        <Button
                            id={"pointsstepforwardbtn"}
                            disabled={currentState === "stopped" || currentState === "playing"}
                            style={secondaryButton({ width: "48%", right: "-4%", marginTop: "2%" })}
                            onClick={() => {
                                handleStepForward();
                            }}
                        >
                            <CaretRightOutlined /> Step Forward
                        </Button>
                    </div>
                </Form.Item>
                <hr />
                <Form
                    layout="inline"
                    style={{
                        paddingTop: "10px",
                    }}
                >
                    <Form.Item
                        label={
                            "Playback Speed"
                        }
                        style={{ width: "48%" }}
                    >
                        <Select
                            defaultValue={"Normal"}
                            value={currentSpeed}
                            style={{ width: "120px" }}
                            onChange={handleSpeedTransmissionChange}

                        >
                            <Option value={500}>Very Fast</Option>
                            <Option value={1000}>Fast</Option>
                            <Option value={1500}>Normal</Option>
                            <Option value={2000}>Slow</Option>
                            <Option value={4000}>Very Slow</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                <Tooltip title="Follow Marker" />
                                Follow Marker
                            </span>
                        }>
                        <Switch
                            defaultChecked
                            checked={mapPan}
                            onChange={onChange}
                        ></Switch>
                    </Form.Item>
                </Form>
            </div >

        </React.Fragment >
    );
}

export default (AnalysisPointsForm);
