import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import {createContext, useEffect} from "react";
import API from "../services/api";
import {macToMAC} from "../util/CommonUtils";
import moment from "moment/moment";

export class AnalysisTripsStore{
    wifiDeviceId = -1;
    wifiDeviceObj = null;
    entityID = -1;
    entityObject = null;
    entityModalOpen = false;

    entityName = null;

    entityCreationModalOpen = false;
    entityCreationState = 0;

    investigationId = -1;

    entityList = [];
    wifiAlias = null;

    constructor() {
        this.api = API;
        makeAutoObservable(this);
    }

    onCloseEntityModal() {
        this.entityModalOpen = false;
        this.wifiAlias = null;
        this.entityID = -1;
        this.entityObject = null;
        this.entityName = null;
        this.wifiDeviceId = -1;
        this.wifiDeviceObj = null;
        this.investigationId = -1;
    }

    entityDropDown() {
        if (!this.entityList || this.entityList.length === 0) return [];
        return this.entityList.map((entity) => {
            return {
                label: entity.alias,
                value: entity.id,
            };
        });
    }

    getEntities() {
        this.api.entity.GetAllEntities(this.investigationId).then((res) => {
            this.entityList = res;
        });
    }

    get entities(){
        const returnList = [];
        this.entityList.forEach((entity) => {
            returnList.push({
                label: entity.alias,
                value: entity.id,
            });
        });
        console.log(returnList);
        return returnList;
    }


    setInvestigationId(id) {
        console.log("Setting investigation id to: ", id);
        this.investigationId = id;
        this.getEntities();
    }

    setEntityCreateState(value) {
        this.entityCreationState = value;
    }

    setEntityCreationModalOpen(value) {
        this.entityCreationModalOpen = value;
    }

    setEntityModalOpen(value) {
        this.entityModalOpen = value;
    }

    setWifiDeviceId(id) {
        this.wifiDeviceId = id;
        this.getWifiDeviceDetails();
    }

    setDeviceAndOpen(id) {
        this.setWifiDeviceId(id);
        this.setEntityModalOpen(true);
    }

    getWifiDeviceDetails(){
        this.api.wifiDevice.getDetails(this.wifiDeviceId).then(res => {
            this.wifiDeviceObj = res.data.data
            if (res.data.data.entity_id != null && res.data.data.entity_id > 0) {
                this.entityID = this.wifiDeviceObj.entity_id;
                this.wifiAlias = this.wifiDeviceObj.alias
                this.getEntityDetails();
            }
        })
    }

    get doesEntityExist() {
        // return false;
       return this.entityID !== -1;
    }

    setWifiDeviceAlias(value) {
        console.log("Setting alias to: ", value);
        this.wifiAlias = value;
    }

    processEntityCreation(alias) {
        console.log("Creating entity with alias: ", alias);
        this.api.entity.createEntity(this.investigationId, alias, "").then((res) => {
            console.log(res)
            this.entityCreationState = 1;
            this.entityID = res.data.data.insertId;
            this.entityName = alias;
            this.entityCreationModalOpen = false;
            this.processAssignment(this.entityID);
        });
    }
    processEntityAssignment(entityId) {
        console.log("Assigning entity with id: ", entityId);
        this.api.wifiDevice.update(this.wifiDeviceId, this.wifiAlias, entityId).then((res) => {
            this.entityID = entityId;
            message.success("Wifi device updated successfully");
            let alias = "name";
            this.entityList.forEach((entity) => {
                if (entity.id === entityId) {
                    alias = entity.alias;
                }
            });
            this.entityName = alias;
            this.entityCreationModalOpen = false;
            this.entityCreationState = 0;
            this.getEntityDetails();
        });
    }

    processAssignment(entityId) {
        this.api.wifiDevice.update(this.wifiDeviceId, this.wifiAlias, entityId).then((res) => {
            this.entityID = entityId;
            message.success("Wifi device updated successfully");
            this.entityCreationModalOpen = false;
            this.entityCreationState = 0;
            this.getEntityDetails();
        });
    }

    saveWifiConnectionChanges() {
        console.log("Updating wifi device with id: ", this.wifiDeviceId, " and entity id: ", this.entityID);
        this.api.wifiDevice.update(this.wifiDeviceId, this.wifiAlias, this.entityID).then((res) => {
            console.log(res)
            message.success("Wifi device updated successfully");
            this.wifiDeviceObj = null;
            this.wifiDeviceId = -1;
            this.entityID = -1;
            this.entityObject = null;
            this.entityName = null;
            this.wifiAlias = null;
            this.entityModalOpen = false;
        });
    }

    getEntityDetails(){
        this.api.entity.getEntityDetails(this.entityID).then(res => {
            this.entityObject = res.data.data;
            this.entityName = this.entityObject.alias;
        })
    }
}

export default createContext(new AnalysisTripsStore());
