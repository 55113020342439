import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import API from "../services/api";
import { Link, withRouter } from "react-router-dom";
import { ArrowRightOutlined, ExclamationCircleOutlined, LoginOutlined, LogoutOutlined, QuestionCircleOutlined, BookOutlined, ExperimentOutlined } from "@ant-design/icons";
export class socketHandlerStore {
    constructor () {
        this.handle = null;
        this.webNotificationArray = [];
        this.drawerOpen = false;
        this.livePanToTarget = null;
        makeAutoObservable(this)
    }
    notificationIconDictionary(notificationName) {
        switch (notificationName) {
            case "Start movement":
                return <ArrowRightOutlined />;
            case "Stop movement":
                return <ExclamationCircleOutlined />;
            case "GeoFence IN":
                return <LoginOutlined />;
            case "GeoFence OUT":
                return <LogoutOutlined />;
            case "Battery Warning":
                return <QuestionCircleOutlined />;
            case "Warrant Alert":
                return <BookOutlined />;
            case "Clone Scan":
                return <ExperimentOutlined />;
            case "Sniff Scan":
                return <ExperimentOutlined />;
            default:
                return <QuestionCircleOutlined />;
        }
    }

    buttonToLivePage(notification)  {
        window.location.replace("/live");
        // <Link to="/live" />
        // this will need to set target id to pan too
        this.livePanToTarget = notification?.targetId;
        this.drawerOpen = false;
    }

    setSocketHandler(handle) {
        this.handle = handle;
    }

    openDrawer() {
        this.drawerOpen = true;
    }

    closeDrawer() {
        this.drawerOpen = false;
    }

    sendSocketMessage(stream, msg) {
        if (this.handle) {
            this.handle.emit(stream, msg, ( response ) => {console.log(response)});
        }
    }

    markNotificationAsRead(notification) {
        this.webNotificationArray.splice(this.webNotificationArray.indexOf(notification), 1);
        const socketMsg = {
            action: 'clearNotification',
            notification: notification,
        }
        this.handle.emit('notification_action', socketMsg, ( response ) => {console.log(response)});
    }

    receiveNotification(notification) {
        try {
            const obj = JSON.parse(notification);
            this.webNotificationArray.push(obj);
        } catch (e) {
            console.log(e);
        }
    }

    clearNotifications() {
        this.webNotificationArray = [];
        const socketMsg = {
            action: 'clearAllNotifications',
            notification: null,
        }
        this.handle.emit('notification_action', socketMsg, ( response ) => {console.log(response)});
    }

    formatNotification(notification) {
        const obj = JSON.parse(notification);
        if (obj.notificationMessage) {
            return obj.notificationMessage;
        }
    }

}

export default createContext(new socketHandlerStore());