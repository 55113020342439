import {Table} from "antd";
import React from "react";


export default function ({
    filteredStops,
    stopColumns,
    onStopClick,
    currentlySelectedStop,
    isLoading
}) {
    const isCurrentlySelectedStop = (stop) => {
        return currentlySelectedStop && stop.id === currentlySelectedStop.id;
    };
    return (
        <Table
            loading={isLoading}
            dataSource={filteredStops}
            pagination={false}
            style={{ height: "100%", }}
            scroll={{ y: 445 }}
            columns={stopColumns}
            rowKey={(record) => `${record.id}`}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        return onStopClick(record)
                    },
                    style: isCurrentlySelectedStop(record)
                        ? { backgroundColor: "#faf3dc" }
                        : {},
                };
            }}
        />
    );
}
