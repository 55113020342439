import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Input, Popover, Select } from "antd";
import React, {useState} from "react";


function LivePinAddForm(props) {

    const { Option } = Select;

    const types = [
        { value: 'persistent', name: "Persistent" },
        { value: 'temporary', name: "Temporary" }
    ];

    const [isTemporary, setIsTemporary] = useState(false);

    const handleSubmit = (e) => {
        //e.preventDefault();
        props.form.validateFields((error, values) => {
            if (!error) {
                props.addLivePin(values.name, values.type);
            } else {
                // alert(JSON.stringify(error, null, 2));
            }
        });
    };

    const handleChange = (value) => {
        if(value === 'temporary'){
            setIsTemporary(true)
        }else{
            setIsTemporary(false)
        }
    }


    const {getFieldDecorator} = props.form;
    return (
        <React.Fragment>
            <Popover
                content={
                    <div style={{
                        minWidth: "280px"
                    }}>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        {getFieldDecorator("name", {
                                            // onChange: props.onDateRangeSelect,
                                            initialValue: null,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please insert a name",
                                                },
                                                {
                                                    max: 20,
                                                    message: "Name must be less than 20 characters",
                                                },
                                            ],
                                        })(
                                            <Input
                                                maxLength={20}
                                                type="text"
                                                placeholder="Name"
                                                style={{width: "100%"}}
                                            />)}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator("type", {
                                            // onChange: props.onDateRangeSelect,
                                            initialValue: 'persistent',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please select a type",
                                                },
                                            ],
                                        })(
                                            <Select
                                                mode="single"
                                                style={{ width: "100%" }}
                                                placeholder="Type"
                                                onChange={handleChange}
                                            >
                                                {types.map(value => (
                                                    <Option key={value.value}>{value.name}</Option>
                                                ))}


                                            </Select>)}
                                        {isTemporary && (
                                            <p style={{
                                                margin: '0'
                                            }}>Temporary pins will expire in 4 hours.</p>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{
                                textAlign: 'end',
                                borderTop: '1px solid #e8e8e8',
                                paddingTop: '12px'
                            }}>
                                <Button
                                    id={"device-analytics-search-btn"}
                                    onClick={() => {
                                        props.onChangeOpenPopOver()
                                        props.livePinStore.pinToAdd = []
                                    }
                                        //props.setCurrentQueryLocation({});
                                    }
                                    style={{marginLeft: "auto"}}
                                >
                                        Close
                                </Button>

                                <Button
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    //disabled={props.analytics && props.analytics.length === 0}
                                    style={{marginLeft: "10px"}}
                                    type="primary">
                                        Save
                                </Button>
                            </Row>
                        </Form>
                    </div>
                }
                title={`Name the pin for ${props.investigation_name}`}
                trigger="click"
                visible={props.popOverVisible}
            >
            </Popover>


        </React.Fragment>
    );
}

export default Form.create({})(LivePinAddForm);
