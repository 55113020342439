import BaseAPI from "./utilities/request.lib";

const HTTP = BaseAPI();

const createGeofence = async ({
    name,
    target_id,
    fence,
    timezone,
    medium,
    expired_at,
    apply_geofence_datetime,
    _days,
    _from,
    _to
}) => {
    try {
        const response = await HTTP.post("/geo-fence", {
            name,
            target_id,
            fence: JSON.stringify(fence),
            timezone,
            medium,
            apply_geofence_datetime,
            expired_at: expired_at,
            _days,
            _from,
            _to
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
      response.data.data.geoFence[response.data.data.geoFence.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const updateGeofence = async ({
    id,
    name,
    target_id,
    timezone,
    medium,
    apply_geofence_datetime,
    fence,
    _days,
    _from,
    _to
}) => {
    try {
        const response = await HTTP.put(`/geo-fence/${id}`, {
            name,
            target_id,
            timezone,
            medium,
            apply_geofence_datetime,
            fence: JSON.stringify(fence),
            _days,
            _from,
            _to
        });
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data =
      response.data.data.geoFence[response.data.data.geoFence.length - 1];
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const deleteGeofence = async (id) => {
    try {
        const response = await HTTP.delete(`/geo-fence/${id}`);
        // Data comes under the data.user key, and comes as an array for some reason
        // and it usually contains one item.
        const data = response.data.code === "SUCCESS";
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getGeofences = async () => {
    try {
        const response = await HTTP.get(
            `/geo-fence/user-assigned-investigations/all`
        );

        const data = response.data.data.investigation;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

const getBytargetId = async (target_id) => {
    try {
        const response = await HTTP.get(`/geo-fence/get-geofencesByTargetId/${target_id}`);
        const data = response.data.data;
        return Promise.resolve(data);
    } catch (exception) {
        return Promise.reject(exception);
    }
};

export default {
    getBytargetId,
    createGeofence,
    deleteGeofence,
    getGeofences,
    updateGeofence,
};
