// import { notification } from "antd";
import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
// Services
import API from "../services/api";

// DEBUG
window.API = API;

export class GeofenceStore {
    constructor() {
        this.geofences = [];
        this.activeGeofence = null;
        this.geoFencesByInvestigation = [];
        this.api = API;
        makeAutoObservable(this);
    }

    setActiveGeofence(geofence) {
        this.activeGeofence = geofence;
    }

    async saveGeofence(geofence) {
        try {
            await this.api.geofence.createGeofence(geofence);
            console.log("Geofence", geofence)
            message.success(`Geofence  has been created.`);
            this.getAllGeofences();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async getAllGeofences() {
        const response = await this.api.geofence.getGeofences();

        this.geoFencesByInvestigation = response;

        this.geofences = response;
    }

    async deleteGeofence(geofence) {
        try {
            await this.api.geofence.deleteGeofence(geofence.id);
            message.success(`Geofence '${geofence.name}' has been deleted.`);
            this.getAllGeofences();
            this.setActiveGeofence(null)
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }

    async updateGeofence({
        id,
        name,
        trigger_type,
        target_id,
        user_id,
        timezone,
        medium,
        apply_geofence_datetime,
        _days,
        _from,
        _to
    }) {
        try {
            await this.api.geofence.updateGeofence({
                id,
                name,
                trigger_type,
                target_id,
                user_id,
                timezone,
                medium,
                apply_geofence_datetime,
                _days,
                _from,
                _to
            });
            message.success(`Geofence  has been updated.`);
            this.getAllGeofences();
        } catch (exception) {
            message.error(`${exception.response.data.message}`);
        }
    }
}

export default createContext(new GeofenceStore());
