import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import userAuditStore from "../../stores/userAudit.store";
import UserStore from "../../stores/user.store";
import { contentBox, primaryButton } from "../../styles";
import { Row, Col, Select, Table, Divider, DatePicker, Button, Input } from "antd";
import ReactJson from 'react-json-view'
import { toJS } from "mobx";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import InvestigationStore from "../../stores/investigation.store";

const { RangePicker } = DatePicker;

const AUDIT_ACTIONS = {
    MODULES: {
        INVESTIGATION: 'INVESTIGATION',
        TARGET: 'TARGET',
        DEVICE: 'DEVICE',
        USER: 'USER',
        TEMPLATE: 'TEMPLATE',
        GEO_FENCE: 'GEO_FENCE',
        ALERT: 'ALERT',
    },

    ACTIONS: {
        CREATE: 'CREATE',
        SUPER_USER_CREATE: 'SUPER_USER_CREATE',
        DELETE: 'DELETE',
        UPDATE: 'UPDATE',
        PROFILE_UPDATE: 'PROFILE_UPDATE',
        PASSWORD_UPDATE: 'PASSWORD_UPDATE',
        LICENSE_ALLOCATION: 'LICENSE_ALLOCATION',
        LICENSE_DEALLOCATION: 'LICENSE_DEALLOCATION',
        DEVICE_CONFIGURATION: 'DEVICE_CONFIGURATION',

        FORGET_PASSWORD: 'FORGET_PASSWORD',

        APP_LOGIN: 'APP_LOGIN',

        APP_LOGIN_QR: 'APP_LOGIN_QR',
        APP_LOGIN_TOKEN: 'APP_LOGIN_TOKEN',
        LOGIN: 'LOGIN',
        SIGNUP: 'SIGNUP',
        CREATEWARRENT: 'CREATEWARRENT',
        UPDATEWARRENT: 'UPDATEWARRENT',
    },
}

function UserAudit (props) {
    const historyStore = useContext(userAuditStore);
    const userStore = useContext(UserStore);
    const investigationStore = useContext(InvestigationStore);

    useEffect(async () => {
        await historyStore.UpdateHistory();
        await userStore.getUsers();
        await investigationStore.getInvestigations();
    }, []);

    const columns = [
        {
            title: "Module",
            dataIndex: "module_name",
            key: "module_name",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
        },
        {
            title: "User",
            dataIndex: "created_by",
            key: "created_by",
        },
        {
            title: "timestamp",
            dataIndex: "created_at",
            key: "created_at",
            render: (text, record) => {
                const date = new Date(record.created_at * 1000);
                return (
                    <div>
                        {date.toLocaleString()}
                    </div>
                )
            }
        }
    ];

    return (
        <div>
            <div
                style={contentBox({marginTop: "1px"})}
            >

                Module:
                <Divider type="vertical" />
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Any Module"
                    allowClear
                    onChange={(value) => {
                        historyStore.updateModule(value);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={Object.keys(AUDIT_ACTIONS.MODULES).map((module) => {
                        return {
                            label: module,
                            value: module,
                        }
                    })}
                >
                </Select>

                <Divider type="vertical" />
                Action:
                <Divider type="vertical" />

                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Any Action"
                    allowClear
                    onChange={(value) => {
                        historyStore.updateAction(value);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={Object.keys(AUDIT_ACTIONS.ACTIONS).map((action) => {
                        return {
                            label: action,
                            value: action,
                        }
                    })}
                >
                </Select>


                <Divider type="vertical" />
                User:
                <Divider type="vertical" />

                <Select
                    showSearch
                    style={{ width: 100 }}
                    placeholder="Any User"
                    allowClear
                    onChange={(value) => {
                        historyStore.updateUser(value);
                    }}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={userStore.users.map((user) => {
                        return {
                            label: user.name,
                            value: user.name,
                        }
                    })}
                >
                </Select>

                <Divider type="vertical" />
                TimeRange (UTC):
                <Divider type="vertical" />
                <RangePicker
                    timePicker={true}
                    showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                    }}
                    allowClear={true}
                    onChange={(value) => {
                        if (value == null) {
                            historyStore.updateTimeRange([null, null])
                            return;
                        }
                        const start = value[0].unix();
                        const end = value[1].unix();
                        historyStore.updateTimeRange([start, end])
                    }}
                    style={{ width: 400 }} />

                <Divider type="vertical" />
                <Divider type="vertical" />
                <Input
                    placeholder="Search"
                    allowClear
                    onChange={(e) => {
                        historyStore.updateFilterText(e.target.value);
                    }}
                    style={{ width: 300 }}
                />

            </div>
            <div>
                <Row>
                    <Col span={11}
                        style={contentBox( {background: "#262626"})}
                    >
                        {historyStore.ResultSelectedEvent &&

                            <ReactJson src={toJS(historyStore.ResultSelectedEvent)} theme={"monokai"} />

                        }
                    </Col>
                    <Col span={1} />
                    <Col span={12}
                        style={contentBox()}
                    >
                        <Table
                            columns={columns}
                            dataSource={toJS(historyStore.displayHistory)}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { historyStore.selectEvent(record) }, // click row
                                    style: { background: historyStore.selectedEvent?.id == record.id ? "#00ddff" : "" }
                                };
                            }}
                            pagination={{
                                position: ['topRight', 'bottomRight'],
                                defaultPageSize: 10,
                                pageSizeOptions: [10, 20, 50, 100],
                            }}
                            footer={() => {
                                return (
                                    <div>
                                        <span style={{ marginRight: 10 }}>
                                            {`Total: ${historyStore.displayHistory.length}`}
                                        </span>
                                        <Button
                                            style={primaryButton()}
                                            onClick={() => {
                                                historyStore.downloadHistory();
                                            }}
                                            icon={<DownloadOutlined />}
                                        >

                                        </Button>
                                    </div>
                                )
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default observer(UserAudit);