import { Badge, Table, Tooltip, Space, Tag } from "antd";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
    RightOutlined,
    UpOutlined
  } from '@ant-design/icons';
import React, { useContext, useState, useEffect } from "react";
import { customSorter, formatDate } from "../../util/CommonUtils";
import DeviceExpansion from "../DeviceExpansion"
import "./DeviceTable.scss";
import permissionStore from "../../stores/permission.store";
import API from "../../services/api";
import LiveModeIndicator from "./liveModeIndicator";
import ConfigTag from "./configtag";
import deviceStore from "../../stores/device.store";

const { Column } = Table;

export default function DisplayTable({
    onDelete,
    data,
    canDelete,
    unallocatedLicenses,
    allLicenses,
    onLicenseDeallocationClick,
    deviceRecoveryPermission,
    lite,
    onDeviceClick,
    currentlySelectedDevice
}) {

    const permissionstore = useContext(permissionStore);
    const BasicPerms = permissionstore.can('config.basic');
    const AdvancedPerms = permissionstore.can('config.advanced');
    const PresetPerms = permissionstore.can('configuration.preset');
    const store = useContext(deviceStore);

    const onLicenseDeallocation = () => {
        onLicenseDeallocationClick();
    };

    const onLicenseAllocation = () => {
        onLicenseDeallocationClick();
    };

    const sortDirections = ["ascend", "descend"];

    const [isFederated, setIsFederated] = useState(false);
    const [expandedData, setExpandedData] = useState([]);
    const [expandedContent, setExpandedContent] = useState([]);

    const [redbackGpsSchema, setRedbackGpsSchema] = useState(null);
    const [redbackGps3Schema, setRedbackGps3Schema] = useState(null);

    const [g62Schema, setg62Schema] = useState(null);
    const [Oyster3chema, setOyster3Schema] = useState(null);
    const [Oyster2chema, setOyster2Schema] = useState(null);
    const [bolt2Schema, setBolt2Schema] = useState(null);

    const [redbackWifiSchema, setRedbackWifiSchema] = useState(null);
    const [huntsmanGpsSchema, setHuntsmanGpsSchema] = useState(null);
    const [ccatSchema, setCcatSchema] = useState(null);
    const [atsSchema, setAtsSchema] = useState(null);

    const onExpand = (expanded, record) => {
        const expandedRows = [];
        const expandedCon = [];

        if (expanded) {
            expandedRows.push(record.id)
            expandedCon.push(record)
        }
        setExpandedData(expandedRows)
        setExpandedContent(expandedCon)
    }

    const createDisplayVoltage = (record) => {

        let batteryText = "";
        let commtext = "No Connection";
        if (record.battery_info) {
            if (typeof record.battery_info === 'string') {
                if (record.battery_info[0] === '{') {
                    record.battery_info = JSON.parse(record.battery_info);
                    if (record.battery_info.internalVoltage) {
                        batteryText = "@" + record.battery_info.internalVoltage + "V";
                    }
                }else if (record.battery_info[0] === '&') {
                    // this will be a percentage
                    const tempstring = record.battery_info.split(' ');
                    if (tempstring.length > 1) {
                        batteryText = "@" + tempstring[1];
                    }
                }
            }
        }
        if (record.lastCommsTimestamp !== null) {
            commtext = formatDate(record.lastCommsTimestamp, 'HH:mm:ss');
        }
        return commtext + batteryText;
    }

    const iscurrentlySelectedDevice = (trip) => {
        return currentlySelectedDevice && trip.id === currentlySelectedDevice.id;
    };

    // Fetch configuration schemas for all device types
    useEffect(() => {
        API.device.getDeviceConfigurationSchema('dm', 'gps')
            .then((schema) => setRedbackGpsSchema(schema))
            .catch((err) => console.log(err));

        API.device.getDeviceConfigurationSchema('dm', 'gps3')
            .then((schema) => setRedbackGps3Schema(schema))
            .catch((err) => console.log(err));

        API.device.getDeviceConfigurationSchema('dm', 'g62')
            .then((schema) => setg62Schema(schema))
            .catch((err) => console.log(err));
        API.device.getDeviceConfigurationSchema('dm', 'bolt2')
            .then((schema) => setBolt2Schema(schema))
            .catch((err) => console.log(err));
        API.device.getDeviceConfigurationSchema('dm', 'Oyster3')
            .then((schema) => setOyster3Schema(schema))
            .catch((err) => console.log(err));
        API.device.getDeviceConfigurationSchema('dm', 'Oyster2')
            .then((schema) => setOyster2Schema(schema))
            .catch((err) => console.log(err));

        API.device.getDeviceConfigurationSchema('dm', 'wifi')
            .then((schema) => setRedbackWifiSchema(schema))
            .catch((err) => console.log(err));

        API.device.getDeviceConfigurationSchema('hunt', 'hunt')
            .then((schema) => setHuntsmanGpsSchema(schema))
            .catch((err) => console.log(err));

        API.device.getDeviceConfigurationSchema('ccat', 'CCAT')
            .then((schema) => setCcatSchema(schema))
            .catch((err) => console.log(err));
        API.device.getDeviceConfigurationSchema('ats', 'tag')
            .then((schema) => setAtsSchema(schema))
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        console.log('init')
        console.log(atsSchema);
    }, [atsSchema]);

    const StatusCheck = (status) => {
        switch (status) {
            case '0':
                return <Tooltip title={"Waitng for device to connect"}><Tag icon={<ClockCircleOutlined Spin />} color="processing" style={{minWidth: "10vh"}}> Waiting</Tag></Tooltip>
            case '1':
                return <Tooltip title={"No Target Set"}><Tag icon={<CloseCircleOutlined />} color="error" style={{minWidth: "10vh"}}> No Target</Tag></Tooltip>
            case '2':
                return <Tooltip title={"No Licence Set"}><Tag icon={<CloseCircleOutlined />} color="error" style={{minWidth: "10vh"}}> No licence</Tag></Tooltip>
            case '3':
                return <Tooltip title={"No Licence or Target Set"}><Tag icon={<ExclamationCircleOutlined />} color="error" style={{minWidth: "10vh"}}> Error</Tag></Tooltip>
            case '4':
                return <Tooltip title={"Device has conencted"}><Tag icon={<CheckCircleOutlined />} color="success" style={{minWidth: "10vh"}}> Registered</Tag></Tooltip>
            default:
                return <Tag icon={<MinusCircleOutlined />} color="default" style={{minWidth: "10vh"}}> unknown</Tag>
        }
    }
    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            sorter: (a, b) => customSorter(a.serial, b.serial),
            sortDirections: sortDirections,
            render: (text, record) => {
                return (
                    <div>
                        <p>
                            {StatusCheck(record.status)}
                            {record.serial}
                        </p>
                    </div>
                )
            }
        },
        {
            title: 'Target',
            dataIndex: 'target_name',
            key: 'target_name',
            sorter: (a, b) => customSorter(a.target_name, b.target_name),
            sortDirections: sortDirections,
        },
        {
            title: 'Investigation',
            dataIndex: 'investigation_name',
            key: 'investigation_name',
            sorter: (a, b) => customSorter(a.investigation_name, b.investigation_name),
            sortDirections: sortDirections,
            render: (text, record) => {
                if (record.investigation_colour === "#fff") {
                    record.investigation_colour = "default"
                }
                return (
                    <div>
                        {/* <Tag
                        color={record.investigation_colour}
                        style={{textColor : "#000", minWidth: "15vh"}}
                        > */}
                            {record.investigation_name}
                        {/* </Tag> */}
                    </div>
                )
            }
        },
        {
            title: 'Model',
            dataIndex: 'name',
            key: 'name',
            filters:
                store.deviceTypes.map((deviceType) => {
                    return {
                        text: deviceType.name,
                        value: deviceType.name
                    }
                }),

            onFilter: (value, record) => record.name.indexOf(value) === 0,
            filterSearch: true,
        },
        {
            title: 'Configuration Setting',
            key: 'recovery_settings',
            // render={(_, record) => <LiveModeIndicator record={record} />}
            render: (_, record) => {
                return (
                    <div>
                        <ConfigTag record={record} />
                    </div>
                )
            }
        },
        {
            title: 'Last Seen Snapshot',
            key: 'lastCommsTimestamp',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.lastCommsTimestamp - b.lastCommsTimestamp,
            sortDirections: sortDirections,
            dataIndex: 'lastCommsTimestamp',
            render: (_, record) => {
                createDisplayVoltage(record);
                return (
                    <React.Fragment>
                        {createDisplayVoltage(record)}
                    </React.Fragment>
                )
            }
        },
    ];

    return (
        <Table
            dataSource={data}
            size="middle"
            rowKey="id"
            // scroll={{ y: 468 }}
            pagination={{ pageSize: 10, hideOnSinglePage: true}}
            expandedRowKeys={expandedData}
            onRow={(record) => {
                if (true) {
                    return {
                        onClick: () => onDeviceClick(record),
                        style: iscurrentlySelectedDevice(record)
                            ? { backgroundColor: "#faf3dc", cursor: 'pointer', marginLeft: '2%' }
                            : {cursor: 'pointer', marginLeft: '2%'},
                    };
                }
            }}
            expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                        <UpOutlined onClick={e => onExpand(record, e)} />
                    ) : (
                        <RightOutlined onClick={e => onExpand(record, e)} />
                    )}
            onExpand={onExpand}
            expandedRowRender={(record) => {
                let schema = null;
                console.log((record))
                switch (record.device_code) {
                    case 'ATRAXGPS':
                        schema = redbackGpsSchema;
                        break;
                    case 'ATRAXGPS3':
                        schema = redbackGps3Schema;
                        break;
                    case 'ATRAXWIFI':
                        schema = redbackWifiSchema;
                        break;
                    case 'HUNT':
                        schema = huntsmanGpsSchema;
                        break;
                    case 'CCAT':
                        schema = ccatSchema;
                        break;
                    case 'ATS_JSON':
                        console.log("ATS");
                        schema = atsSchema;
                        console.log(atsSchema);
                        break;
                    case 'G62':
                        schema = g62Schema;
                        break;
                    case 'OYSTER3':
                        schema = Oyster3chema;
                        break;
                    case 'OYSTER2':
                        schema = Oyster2chema;
                        break;
                    case 'BOLT2':
                        schema = bolt2Schema;
                        break;
                    default:
                        schema = null;
                        break;
                }
                return (<DeviceExpansion
                    lite={lite}
                    isFederated={isFederated}
                    setIsFederated={setIsFederated}
                    BasicPerms={BasicPerms}
                    AdvancedPerms={AdvancedPerms}
                    PresetPerms={PresetPerms}
                    deviceRecoveryPermission={deviceRecoveryPermission}
                    expandedContent={expandedContent}
                    onDelete={onDelete}
                    canDelete={canDelete}
                    unallocatedLicenses={unallocatedLicenses}
                    onLicenseAllocation={onLicenseAllocation}
                    allLicenses={allLicenses}
                    onLicenseDeallocation={onLicenseDeallocation}
                    configurationSchema={schema}
                />
                )}}
            expandRowByClick
            columns={columns}
        >

        </Table>
    );
}
