import {Button, Modal, Table, Row, Col} from "antd";
import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useState} from "react";
import Layout from "../AppLayout";
import {toJS} from "mobx";
import NotificationStore from "../../stores/notification.store";
import {formatDate} from "../../util/CommonUtils";

function NotificationsPage() {

    const notificationStore = useContext(NotificationStore);
    const [message, setMessage] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        notificationStore.getAllAdminBulletins();
        notificationStore.setReceivedAdminBulletinList();
        // eslint-disable-next-line
    }, [notificationStore])

    const onModalCancelClicked = () => {
        setOpenPopup(false);
    };


    const columns = [
        {
            title: 'Notified By',
            dataIndex: 'user',
            key: 'user',
            width: '70'
        },
        {
            title: 'Client Name',
            dataIndex: 'client',
            key: 'client',
            width: '70'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '40'
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            width: '90'
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button
                        id={record.id}
                        onClick={() => {
                            setMessage(record)
                            setOpenPopup(true)
                        }}>View</Button>
                )
            }
        },
    ];

    const dataSource = [];

    toJS(notificationStore.allAdminBulletins).forEach((item) => {
        dataSource.push({
            key: item.id,
            date: formatDate(item.created_at, 'hh:mm:ss A'),
            subject: item.subject,
            user: item.name,
            message: item.message,
            client: item.client_name
        })
    })

    return (
        <div className="notification_page">
            <Layout
                showNavigation
                title="Bulletins Management"
                description="Manage bulletin settings"
                extra={[]}
            >
                <div className="sos-table">
                    <Table dataSource={dataSource} columns={columns}/>
                </div>
                {message && (
                    <Modal
                        title={message.subject}
                        visible={openPopup}
                        footer={null}
                        onCancel={() => onModalCancelClicked()}
                    >
                        <Row style={{
                            paddingBottom: '20px'
                        }}>
                            <Col span={8}>Subject</Col>
                            <Col span={16}>{message.subject}</Col>
                        </Row>
                        <Row>
                            <Col span={8}>Message</Col>
                            <Col span={16}>{message.message}</Col>
                        </Row>
                    </Modal>
                )}

            </Layout>
        </div>
    );
}

export default observer(NotificationsPage);
