import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Row, Table, Tag } from "antd";
import _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import AlertForm from "../../components/AlertForm";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import useInvestigationForUser from "../../hooks/useInvestigationsForUser";
import AlertStore from "../../stores/alert.store";
import InvestigationStore from "../../stores/investigation.store";
import TargetStore from "../../stores/target.store";
import UserStore from "../../stores/user.store";
import Layout from "../AppLayout";
import "./styles.scss";
import { formatDate } from "../../util/CommonUtils";
import { contentBox, primaryButton, secondaryButton } from '../../styles';

function AlertAdminPage() {
    const [showModal, setShowModal] = useState(false);
    const [mode, setMode] = useState("add");
    const [currentData, setCurrentData] = useState(false);
    const alertStore = useContext(AlertStore);
    const targetStore = useContext(TargetStore);
    const userStore = useContext(UserStore);
    const investigationStore = useContext(InvestigationStore);
    const [
        { isLoading, targetsForInvestigation, investigationsForUser },
        setSelectedInvestigation,
        setSelectedTarget,
    ] = useInvestigationForUser();

    useEffect(() => {
        investigationStore.getInvestigations();
        targetStore.getAllTargets();
        userStore.getUsers();
        alertStore.getAlerts();
        alertStore.getAlertTypes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: "Alert Name",
            dataIndex: "alert_name",
            key: "alert_name",
            width: "70",
        },
        {
            title: "Investigation",
            dataIndex: "investigation_id",
            key: "investigation_id",
            width: "40",
            render: (text, record) => {
                const investigations = _.keyBy(
                    toJS(investigationStore.investigations),
                    "id"
                );
                return (
                    Object.keys(investigations).length && (
                        <p>{investigations[text].name}</p>
                    )
                );
            },
        },
        {
            title: "Target",
            dataIndex: "target_id",
            key: "target_id",
            width: "90",
            render: (text, record) => {
                const targets = _.keyBy(toJS(targetStore.targets), "id");

                return Object.keys(targets).length && <p>{targets[text].name}</p>;
            },
        },
        {
            title: "Created At",
            dataIndex: "created_by",
            key: "created_by",
            width: "70",
            render: (text, record) => {
                return text && <p>{formatDate(text, 'hh:mm:ss A')}</p>;
            },
        },
        {
            title: "Created By",
            dataIndex: "created_at",
            key: "created_at",
            width: "70",
            render: (text, record) => {
                const users = _.keyBy(toJS(userStore.users), "id");

                return Object.keys(users).length && <p>{users[text].name}</p>;
            },
        },
        {
            title: "Alert Type",
            dataIndex: "medium",
            width: "60",
            render: (text, record) => {
                const alertTypes = _.keyBy(toJS(alertStore.alertTypes), "id");
                return (
                    record &&
                    record.alert_type_id.map((alert) => {
                        return (
                            Object.keys(alertTypes).length && (
                                <Tag>{alertTypes[alert].label_name}</Tag>
                            )
                        );
                    })
                );
            },
        },
        {
            title: "",
            key: "action",
            render: (text, record) => {
                return (
                    <div>
                        <Button
                            id={record.key}
                            onClick={() => {
                                setCurrentData(record);
                                setMode("edit");
                                setShowModal((value) => !value);
                            }}
                        >
                            Edit
                        </Button>{" "}
                        <DeleteConfirmationDialog
                            title={`Are you sure you want to delete this alert?`}
                            content={
                                "Deletion of alert will permanently delete alert and not be recoverable."
                            }
                            onClickCancel={() => { }}
                            onClickOkay={async () => {
                                await alertStore.deleteAlert(record.id);
                                await alertStore.getAlerts();
                                message.success("Your alert has been deleted.");
                            }}
                        >
                            <Button id={record.key}>Delete</Button>
                        </DeleteConfirmationDialog>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="alert-admin-page">
            <AlertForm
                loading={isLoading}
                onSubmit={async ({ values, mode }) => {
                    if (mode === "add") {
                        await alertStore.createAlert(values);
                        setShowModal((value) => !value);
                        await alertStore.getAlerts();
                        message.success("Your new alert has been created.");
                    } else {
                        await alertStore.updateAlert(values.id, values);
                        setShowModal((value) => !value);
                        await alertStore.getAlerts();
                        message.success("Your alert has been updated.");
                    }
                }}
                alertTypes={alertStore ? toJS(alertStore.alertTypes) : []}
                users={userStore ? toJS(userStore.users) : []}
                onCancel={async () => {
                    setShowModal((value) => !value);
                    await alertStore.getAlerts();
                    setCurrentData(null);
                    setMode("add");
                }}
                opened={showModal}
                mode={mode}
                data={currentData}
                investigations={
                    investigationsForUser ? toJS(investigationsForUser) : []
                }
                targets={targetsForInvestigation ? toJS(targetsForInvestigation) : []}
                onInvestigationSelect={(investigation) => {
                    setSelectedInvestigation(investigation);
                }}
                onTargetSelect={(target) => {
                    setSelectedTarget(target);
                }}
            ></AlertForm>
            <Layout
                showNavigation
                title="Alerts geofence"
                description="ALERTS Configure motion alerts for ATRAX devices and manage retransmission of SMS Alerts from 3rd Party Devices"
            >
                <Row></Row>
                <div className="sos-table"
                    style={contentBox()}
                >
                    <Button
                        onClick={() => {
                            setShowModal((value) => !value);
                        }}
                    >
                        <PlusOutlined></PlusOutlined>Configure New Alert
                    </Button>
                    <Table
                        dataSource={alertStore && alertStore.alerts}
                        columns={columns}
                    />
                </div>
            </Layout>
        </div>
    );
}

export default observer(AlertAdminPage);
