import React, { useState, useEffect, useContext } from "react";
import { Upload, Modal, Select, Form, Button, message } from "antd";
import targetApi from "../../services/api/target.api";
import DeviceStore from "../../stores/device.store";
import { observer } from "mobx-react-lite";
import { UploadOutlined } from "@ant-design/icons";

function UploadDataModal(props) {
  const { visible, onCancel, onOk, uploadData, investigation } = props;

  const deviceStore = useContext(DeviceStore);

  const { Option } = Select;

  const [file, setFile] = useState(null);
  const [device, setDevice] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    deviceStore.getAllDevices();
  }, []);

  useEffect(() => {
    if (file) {
      const formDate = new FormData();
      formDate.append("file", file);
      targetApi.uploadFile(formDate, uploadData, investigation, device).then((res) => {
        if (res.code === "SUCCESS") {
          message.success("File uploaded successfully");
        } else {
          message.error(`File upload failed - ${res.response.data.attribute}`);
        }
      })
    }
  }, [file]);

  return (
    <Modal
      title="Upload Data"
      visible={visible}
      footer={null}
      onCancel={() => {
        form.resetFields();
        setDevice(null);
        setFile(null);
        onCancel();
      }}
      onOk={() => {
        form.resetFields();
        setDevice(null);
        setFile(null);
        onOk();
      }}
    >
      <Form
        form={form}
      >
        <Form.Item
          label="Device"
          name="device"
          rules={[
            {
              required: true,
              message: "Please select a device"
            }
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Device"
            showSearch
            filterOption={(input, option) => 
              (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(value) => {
              setDevice(value);
            }}
            label="Device"
          >
            {
              deviceStore.results.map((device) => (
                <Option
                  value={device.id}
                  key={`${device.serial} - ${device.name}`}
                  id={device.id}
                >
                  <p key={device.id}>{device.serial} - {device.name}</p>
                </Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          disabled={!device}
          name="file"
        >
          <Upload
            disabled={!device}
            style={{ width: "100%" }}
            accept=".csv"
            beforeUpload={file => {
              setFile(file);
              return false;
            }}
            onChange={info => {
              if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
              }
              if (info.file.status === "done") {
                console.log(`${info.file.name} file uploaded successfully`);
              } else if (info.file.status === "error") {
                console.log(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <Button
              icon={<UploadOutlined />}
              disabled={!device}
            >
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default observer(UploadDataModal);