import Axios from "axios";
// import AxiosOffline from "axios-offline";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import { message } from "antd";

// const getEndpointBasedOnEnvironment = async () => {
//   const hostname = window && window.location && window.location.hostname;

//   if (hostname === "development.v2.atrax.io") {
//     return "https://api.development.v2.atrax.io/v1/";
//   } else if (
//     hostname === "staging.v2.atrax.io" ||
//     hostname === "staging.atrax.io"
//   ) {
//     return "https://api.staging.v2.atrax.io/v1/";
//   } else {
//     const serverLocation = await (await fetch("/portal/config.json")).json();
//     localStorage.setItem("serverLocation", serverLocation.serverLocation);
//     return serverLocation.serverLocation;
//   }
// };

function fetchConfig() {
    return new Promise((resolve, reject) => {
        fetch(`/portal/config.json`)
            .then((response) => response.json())
            .then((config) => {
                resolve(config);
            })
            .catch((exception) => {
                reject(exception);
            });
    });
}

export default function () {
    const token = localStorage.getItem("token");
    let serverLocation = localStorage.getItem("serverLocation");
    if (!serverLocation) {
        serverLocation = window.location.origin + "/api/v1/";
    }
    const development = false;
    if (development) {
        serverLocation = 'http://localhost:3000/v1/'

    }
    const axiosInstance = Axios.create({
        baseURL: serverLocation,
        timeout: 1000000,
        responseType: "json",
        headers: {
            "x-hello": "I am the one who knocks.",
            Authorization: token ? `Bearer ${token}` : "",
        },
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            //if (!localStorage.getItem('serverLocation') || !localStorage.getItem("messagingServerLocation")) {
            return fetchConfig().then((configTest) => {
                localStorage.setItem("baseURL", configTest.baseURL);
                localStorage.setItem("serverLocation", window.location.origin + "/api/v1/");
                localStorage.setItem(
                    "messagingServerLocation",
                    configTest.messagingServerLocation
                );
                config["baseURL"] = window.location.origin + "/api/v1/";
                if (development) {
                    config["baseURL"] = serverLocation;
                }

                return Promise.resolve(config);
            });
            // }
            // eslint-disable-next-line
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                //
            }

            let originalRequest = response;

            if (
                response &&
                response.url === `/portal/config.json` &&
                !response.baseURL
            ) {
                originalRequest["baseURL"] = window.location.origin + "/api/v1/";
                // localStorage.setItem("serverLocation", response.data.serverLocation);
                localStorage.setItem("serverLocation", window.location.origin + "/api/v1/");
            }

            return response;
        },
        (error) => {
            if (
                error.response &&
                error.response.status === 401
            ) {
                localStorage.clear();
                if (error.response.data.code === "NEW_LOG_IN") {
                    window.location.replace("/login-detected");
                } else if (
                    error.response.data &&
                    error.response.data.code &&
                    error.response.data.code === "INVALID_OR_EXPIRED_TOKEN"
                ) {
                    window.location.replace("/login-expire");
                    originalRequest["baseURL"] = window.location.origin + "/api/v1/";
                    // localStorage.setItem("serverLocation", response.data.serverLocation);
                    localStorage.setItem("serverLocation", window.location.origin + "/api/v1/");
                    // set the valid store here
                } else {
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(error);
            }
        }
    );
    loadProgressBar(undefined, axiosInstance);
    return axiosInstance;
}
