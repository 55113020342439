import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
    Circle,
    GoogleMap,
    InfoWindow,
    LoadScript,
    Marker,
    Polyline
} from "@react-google-maps/api";
import { macToMAC, mapDefault } from "../../util/CommonUtils";
import getPublicURL from "../../utilities/public-url.utility";
import MapStyles from "../../map-styles";
import "./index.css";

const EntityMap = (({
    accessPoints,
    selectedTarget,
    latlng,
    type,
    trip,
}) => {
    let modAccessPoint = [];
    if (accessPoints && accessPoints.length > 0) {
        accessPoints.forEach(element => {
            if (element.metadata) {
                let ssid = JSON.parse(element.metadata);
                element.ssid = ssid.ssid;
                modAccessPoint.push(element);
            } else {
                modAccessPoint.push(element);
            }
        });
    }
    const google = window.google;

    console.log(latlng);

    const [map, setMap] = useState(null);
    const [located, setLocated] = useState(false);

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const success = (pos) => {
        const crd = pos.coords;


        if (located === false) {
            map.panTo({
                lat: crd.latitude,
                lng: crd.longitude,
            });
            setLocated(true);
        }
    }

    const fail = (error) => {

        if (located === false) {
            map.panTo(mapDefault())
            setLocated(true);
        }
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, fail, options);
    }, [map]);

    let location = null;

    useEffect(() => {
        if (selectedTarget) {
            if (selectedTarget.lat && selectedTarget.lng) {
                location = {
                    lat: selectedTarget.lat,
                    lng: selectedTarget.lng,
                }
                if (map) {
                    map.panTo(location);
                }
            }
        }
    }, [selectedTarget]);

    useEffect(() => {
        if (latlng) {
            if (latlng?.lat && latlng?.lng) {
                if (map) {
                    map.panTo(latlng);
                }
            }
        }
    }, [latlng, map]);

    let center = { lat: 0, lng: 0 };
    if (selectedTarget) {
        if (selectedTarget.lat && selectedTarget.lng) {
            center = {
                lat: selectedTarget.lat,
                lng: selectedTarget.lng,
            }
        }
    }

    const latlngTracks = [];
    // converting trips into google maps latlng format
    if (trip) {
        trip.forEach(point => {
            latlngTracks.push({
                lat: point.latitude,
                lng: point.longitude
            })
        });
    }

    return (
        // <LoadScript >
        <Fragment>
            <GoogleMap
                yesIWantToUseGoogleMapApiInternals
                mapContainerStyle={{
                    height: "75vh",
                    width: "40vw",
                    boxShadow: `0 0 15px #fff
    0 0 15px #fff
                    `,
                }}
                onIdle={() => {

                    if (map.getCenter()) {
                        localStorage.setItem("savedMap", JSON.stringify({ lat: map.getCenter().lat(), lng: map.getCenter().lng() }))
                    }
                }}
                zoom={15}
                onLoad={map => setMap(map)}
            >
                {latlng && latlng.lat && latlng.lng &&
                <div>
                    <Marker
                        position={{
                            lat: latlng.lat,
                            lng: latlng.lng,
                        }}
                        icon={{
                            url: `${getPublicURL()}/${type === "WIFI_SNIFF_SCAN" ? "sniff.png" : "clone.png"}`,
                            scaledSize: new google.maps.Size(35, 35),
                        }}
                    />
                    <Circle
                        position={{
                            lat: latlng.lat,
                            lng: latlng.lng,
                        }}
                    />
                    </div>
                }
                {modAccessPoint.map((point) => {
                    return (
                        <div key={point.mac_address}>

                            <Marker
                                position={{
                                    lat: point.latitude_adjust || point.latitude,
                                    lng: point.longitude_adjust || point.longitude,
                                }}
                                icon={{
                                    url: `${getPublicURL()}/icons8-wifi-64.png`,
                                    scaledSize: new google.maps.Size(35, 35),

                                }}
                                zIndex={99}
                            >
                                <InfoWindow
                                    style={{ backgroundColor: "white"}}
                                    position={{
                                        lat: point.latitude_adjust || point.latitude,
                                        lng: point.longitude_adjust || point.longitude,
                                    }}
                                    zIndex={1}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            padding: "5px",
                                        }}
                                    >
                                        <p>{point.alias || point.ssid}</p>
                                        <p>{macToMAC(point.mac_address)}</p>
                                    </div>
                                </InfoWindow>
                            </Marker>
                        </div>
                    )
                })}
                <Polyline
                    path={latlngTracks}
                    geodesic={true}
                    zIndex={3}
                    options={{
                        strokeColor: "#03B83C",
                        strokeOpacity: 0.75,
                        strokeWeight: 3,
                    }}
                />
                {
                    trip &&
                    trip.map((target, index) => {
                        if (target.latitude && target.longitude) {

                            let markerType;
                            if (index === 0) {
                                markerType = {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    fillColor: "#27B8FC",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "#01290D",
                                    rotation: target.heading,
                                    scale: 5,
                                    anchor: new window.google.maps.Point(0, 0)
                                };
                            } else if (index === trip.length - 1) {
                                markerType = {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    fillColor: "#FC2771",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "#01290D",
                                    rotation: target.heading,
                                    scale: 5,
                                    anchor: new window.google.maps.Point(0, 0)
                                };
                            } else if (target.speed < 1) {
                                markerType = {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    fillColor: "#71FC27",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "#01290D",
                                    rotation: target.heading,
                                    scale: 5,
                                    anchor: new window.google.maps.Point(0, 0)
                                };
                            } else {
                                markerType = {
                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    fillColor: "#71FC27",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "#01290D",
                                    rotation: target.heading,
                                    scale: 4,
                                    anchor: new window.google.maps.Point(0, 3)
                                };
                            }

                            return (
                                <Marker
                                    position={{
                                        lat: target.latitude,
                                        lng: target.longitude,
                                    }}
                                    icon={markerType}

                                    zIndex={4}
                                >

                                </Marker>
                            )
                        }
                    })
                }
            </GoogleMap>
        </Fragment>
        // </LoadScript>
    );
});

export default EntityMap;
