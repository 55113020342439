import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Checkbox, Row, Col } from "antd";
import React from "react";
import { secondaryButton, primaryButton } from '../../styles';

const { TextArea } = Input;

class TemplateForm extends React.Component {
    state = {
        loading: false
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (!error) {
                values.id = this.props.data.id;
                this.props.onSubmit({ values, mode: this.props.mode });
                this.props.form.resetFields();
            }
        });
    };

    onCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { data, mode } = this.props;

        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <Modal
                    title={mode === "add" ? "Add Template" : "Edit Template"}
                    visible={this.props.opened}
                    onOk={this.onUserSubmit}
                    confirmLoading={false}
                    onCancel={() => {
                        this.props.form.resetFields();
                        this.props.onCancel();
                    }}
                    footer={[
                        <Button style={secondaryButton()} key="back" onClick={this.onCancel}>
                            Cancel
                        </Button>,
                        <Button
                            loading={this.state.loading}
                            onClick={this.handleSubmit}
                            key="submit"
                            style={primaryButton()}
                        >
                            {mode === "add" ? "Add Template" : "Edit Template"}
                        </Button>
                    ]}
                >
                    <Form.Item label="Name">
                        {getFieldDecorator("name", {
                            rules: [{ required: true, message: "Please input a name!" }],
                            initialValue: mode === "edit" && data ? data.name : ""
                        })(
                            <Input
                                prefix={
                                    <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                type="text"
                                placeholder="Name"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Description">
                        {getFieldDecorator("description", {
                            rules: [{}],
                            initialValue: mode === "edit" && data ? data.description : ""
                        })(
                            <TextArea
                                prefix={
                                    <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                type="text"
                                placeholder="Description (Optional)"
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Fields">
                        {getFieldDecorator("field_id", {
                            rules: [
                                {
                                    required: true,
                                    message: "Please select at least one from permission list!"
                                }
                            ],
                            initialValue:
                                mode === "edit" && data && data.field_id
                                    ? data.field_id
                                    : []
                        })(
                            <Checkbox.Group style={{ width: "100%" }}>
                                <Row>
                                    {this.props.fields.map(field => {
                                        return (
                                            <Col span={24} key={field.id}>
                                                {field.description ?
                                                    <Checkbox value={field.id}>
                                                        {field.name}
                                                        {"            "}({field.description})
                                                    </Checkbox>
                                                    :
                                                    <Checkbox value={field.id}>
                                                        {field.name}
                                                    </Checkbox>
                                                }
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                </Modal>
            </Form>
        );
    }
}

export default Form.create({})(TemplateForm);
