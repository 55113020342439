import { Button, Divider, Table } from "antd";
import React from "react";
import DeleteConfirmationModal from "../DeleteConfirmationDialog";
import "./UserRoleTable.scss";

const { Column } = Table;

export default function roleTable({ data, onDelete, onEditRecord, canEdit, canDelete }) {
    const _onEditRecord = record => {
        onEditRecord(record);
    };

    const _onDeleteRecord = record => {
        onDelete(record);
    };

    return (
        <Table
            dataSource={data}
            rowKey="id"
            size="middle"
            pagination={{ pageSize: 9 }}
        >
            <Column title="Name" dataIndex="name" key="name" />
            <Column title="Description" key="description" dataIndex="description" />

            <Column
                title=""
                key="action"
                render={(text, record) => (
                    <span>
                        <span>
                            <Button
                                disabled={canEdit}
                                type="link"
                                onClick={() => {
                                    _onEditRecord(record);
                                }}
                            >
                                Edit
                            </Button>
                        </span>

                        <span>
                            <Divider type="vertical" />
                            <DeleteConfirmationModal
                                title={`Are you sure you want to delete '${record.name}'?`}
                                content={
                                    "Deletion of user role will permanently remove all of his/her data from the system and will deny the user from authenticating with the ATRAX system."
                                }
                                onClickCancel={() => { }}
                                onClickOkay={() => {
                                    _onDeleteRecord(record);
                                }}
                            >
                                <Button type="link" size="small" disabled={canDelete}>
                                    Delete
                                </Button>
                            </DeleteConfirmationModal>
                            {/* <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  _onDeleteRecord(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" size="small">
                  Delete
                </Button>
              </Popconfirm> */}
                        </span>
                    </span>
                )}
            />
        </Table>
    );
}
