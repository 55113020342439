import React, { useEffect, useContext, useState } from "react";
import { Form, Input, Button, Select, DatePicker, Row, Col, message, Modal, Tag, Table, Checkbox, Tabs } from "antd";
import AlertStore from "../../stores/alert.store";
import UserStore from "../../stores/user.store";
import entityNotificationStore from "../../stores/entityNotification.store";
import _, { set } from "lodash";
import { toJS, observable } from "mobx";
import { observer } from "mobx-react-lite";

const { TabPane } = Tabs;

function EntityNotificationModal({ opened, onCancel, onSubmit, data, name, }) {

    const alertStore = useContext(AlertStore);
    const userStore = useContext(UserStore);
    const notificationStreams = useContext(entityNotificationStore);

    const [deviceName, setDeviceName] = useState(null);

    useEffect(() => {
        userStore.getUsers()
        notificationStreams.tableStructeSetup();
    }, [data])

    const columns = [
        {
            title: "Notification Stream",
            dataIndex: "alert_name",
            key: "alert_name",
            width: "20%",
        },
        {
            title: "Subscribed Users",
            dataIndex: "Users",
            key: "Users",
            width: "80%",
            render: (text, record) => {
                if (record && record.Users) {
                    return (
                        <div>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Subscribe Users"
                                defaultValue={record.Users.map(user => {
                                    return {
                                        label: user.name,
                                        value: user.userID,
                                    }
                                })}
                                onChange={(value) => {
                                    setNotificationStreams(value, record.alert_name);
                                }}
                                options={userStore.usersInInvestigation(data.investigation_id).map(user => {
                                    return {
                                        label: user.name,
                                        value: user.id,
                                    }
                                })}
                            />
                        </div>
                    )
                }
                return (
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Subscribe Users"
                        onChange={(value) => {
                            setNotificationStreams(value, record.alert_name);
                        }}
                        options={userStore.usersInInvestigation(data.investigation_id).map(user => {
                            return {
                                label: user.name,
                                value: user.id,
                            }
                        })}
                    />
                )
            }
        },
    ];

    const setNotificationStreams = (value, mode) => {
        // work on stream to update
        // value is array of user ideas we'll need their names as well annoyingly
        // get user names from id
        let users = [];
        userStore.usersInInvestigation(data.investigation_id).map((user) => {
            if (value.includes(user.id)) {
                users.push({
                    userID: user.id,
                    name: user.name,
                });
            }
        })
        console.log(users)
        switch (mode) {
            case "Clone Scan":
                notificationStreams.CloneScan = users;
                break;
            case "Sniff Scan":
                notificationStreams.SniffScan = users;
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Modal
                title={`Notifications: ${name}`}
                visible={opened}
                onOk={() => {
                    notificationStreams.updatestream(data.id, data.investigation_id);
                    onCancel();
                }}
                onCancel={() => {
                    //notificationStreams.clearNotificationStreams();
                    onCancel()
                }}
                width={"70%"}
            >
                <Tabs defaultActiveKey="1" onChange={() => { }}>
                    <TabPane tab="Entity Notifications" key="1">
                        <Table
                            columns={columns}
                            size="middle"
                            rowKey="alert_name"
                            dataSource={toJS(notificationStreams.tableStructureCOMP)}
                            scroll={{
                                y: 600,
                            }}
                        />
                    </TabPane>
                </Tabs>
            </Modal>

        </div>
    )
}

export default observer(EntityNotificationModal);