import {Badge, Table} from "antd";
import {observer} from "mobx-react-lite";
import React from "react";
import StatusDot from "../StatusDot";
import {getBatteryInfo, getGMTDateTime, macToMAC} from "../../util/CommonUtils";
import './DeviceDetails.scss'

import { toJS } from "mobx";

// const STATUSES_COLOR = {
//   1: "#f39c12",
//   2: "#27ae60",
//   3: "#c0392b",
// };

// eslint-disable-next-line
const STATUS_LABEL = {
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
};

// eslint-disable-next-line
function isDateBeforeToday(date) {
    return new Date(date) < new Date(new Date());
}

function getSSID (metadata){
    console.log(metadata);
    let ssid = JSON.parse(metadata);
    return(ssid.ssid);
}



function convertToTime(timestamp) {
    let date = new Date(0);
    date.setUTCSeconds(timestamp);
    let hours = date.getHours();
    let minute = date.getMinutes();
    return (`${hours}:${minute}`);
}

const columns = [
    {title: "Entity", dataIndex: "alias", key: "alias"},
    {title: "Device", dataIndex: "wifi_device_name", key: "wifi_device_name"},
    {title: "Device Mac Address", dataIndex:"mac_address", key: "mac_address", render: item => macToMAC(item)},
    {title: "Device Mac Address", dataIndex:"mac_address", key: "mac_address", render: item => macToMAC(item)},
    {title: "Time Seen", dataIndex:"timestamp", key:"timestamp", render: item => convertToTime(item)},
]

function cloneCaptureData(flag, data) {
    console.log();
    if (flag) {
        if (data) {
            return (
                <div>
                    <p>
                        {'WiFi Cloned Devices: '}
                    </p>
                    <Table
                     size={"small"}
                    //style={{maxWidth: "70%"}}
                        dataSource={toJS(data)}
                        columns={columns}
                    />
                </div>
            )
        } else {
            return (
                <p>{`No Clone Capture Infomation`}</p>
            )
        }
    }
    else {
        return (null);
    }
}

const DeviceDetailsCloneCapture = observer(({item, style, isTestLiveMap, speed_unit, list, color, entityEdit}) => {
    console.log(item.cloneCapture);
    console.log(list);
    if (!item.cloneCapture){
        return null
    }
    return (
        <React.Fragment>
            <div
                style={{
                            ...style,
                            backgroundColor: color ? color : "white",
                            borderRadius: "5px"
                           // padding: "3px",

                        }
                }
                className={isTestLiveMap ? 'live-map-info-window' : ''}
            >
                {item.cloneCapture.map((device) => {
                      return (
                        <p
                          onClick={() => { entityEdit(item, device)}}
                          style={{
                            cursor: "pointer",
                          }}
                        ><u
                          style={{
                            color: "#0000FF",
                            paddingLeft: "1em",
                          }}
                        >{`${device.alias|| ""}  ${device.wifi_device_name}`}</u></p>
                      )
                    })}
                <br />
            </div>
        </React.Fragment>
    );
});

export default DeviceDetailsCloneCapture;
