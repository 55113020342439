import { message } from "antd";
import { action, observable, makeAutoObservable } from "mobx";
import { createContext } from "react";
import API from "../services/api";

export class AppStore {
    constructor() {
        this.navigationCollapsed = 1;
        this.api = API;
        makeAutoObservable(this);
    }

    clearHouse() {
        this.navigationCollapsed = 3;
    }

    setval(valid) {
        this.navigationCollapsed = valid;
    }

    // 3 states 1 = loading, 2 = auth, 3 = no auth for loading

    async checkValidUser() {
        // if this check is already set it to true so we dont do it each time
        if (this.navigationCollapsed === 2) {
            return 2;
        }
        const isAuthenticated = localStorage.getItem("token");
        if (!isAuthenticated) {
            return 3;
        }
        // no token set
        //token set but not valid
        // can probably do lazy api call here and catch the 401
        // use /user/profile/
        const investigationList = await this.api.dashboard.getAllDashboardSettings();
        if (investigationList) {
            this.navigationCollapsed = 2;
            return 2;
        }
        localStorage.clear();
        localStorage.setItem("serverLocation", window.location.origin + "/api/v1/");
        this.navigationCollapsed = 3;
    }
}

export default createContext(new AppStore());
